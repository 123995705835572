import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { debounce } from 'lodash';

import { i18, PAYMENT_SYSTEMS, trb } from '../../../../Utilities';
import { AutoComplete, FormField } from '../../../../shared';
import { PMCActions } from '../../../../Redux';
import { Number } from '../../../../Components';
import { useCallback } from 'react';
import { BICS_SET_1 } from '../../../../constants';

interface ITemplate {
  PayeeName: string;
  PayeeAccountNumber: string;
  Description: string;
  Amount: number;
  IsPurposeSelected: boolean;
  PaymentType: number;
}

interface IProps {
  paymentType: 'international' | 'new';
}

export const FieldPayee = ({ paymentType }: IProps) => {
  const dispatch: any = useDispatch();
  const { MALR } = useSelector((state: any) => state.UACReducers);
  const { historicalPayments, sendHistoricalPayments } = useSelector((state: any) => state.PMCReducers);
  const { setFieldValue } = useFormikContext();

  const handleSearchPayments = useCallback(
    debounce((value: string) => {
      const ActiveAccount = MALR?.ManagedAccounts != null ? MALR.ManagedAccounts.find((e: any) => e.IsActive === true) : null;

      if (value && value.length > 4 && ActiveAccount?.ID) {
        dispatch(PMCActions.getHistoricalPayments({ ClientId: ActiveAccount.ID, SearchText: value }));
      }
    }, 500),
    []
  );

  const handleSelectPayment = async (template?: ITemplate) => {
    if (!template) {
      return null;
    }

    await setFieldValue('Payment.Recipient', template.PayeeName);
    await setFieldValue('Payment.BeneficiaryAccountNumber', template.PayeeAccountNumber);

    if (paymentType === 'new') {
      await setFieldValue('Payment.IsPurposeSelected', true);
      await setFieldValue('Payment.Purpose', template.Description);
      const res = await dispatch(PMCActions.postCheckIBANMember(template.PayeeAccountNumber?.replaceAll(' ', '')));

      if (res?.payload?.IsValid) {
        const isInstantPayment = (!BICS_SET_1.includes(res.payload.Bic?.BIC) && res?.payload?.Participant?.SepaInst) ?? false;

        if (isInstantPayment) {
          await setFieldValue('Payment.PaymentSystem', PAYMENT_SYSTEMS.INST);
        }
      }
    }
  };

  const renderOptions = (templates: Array<ITemplate> = []) => {
    const paymentTypes = paymentType === 'international' ? [7] : [2, 6];

    return templates
      .filter((e) => paymentTypes.includes(e.PaymentType))
      .map((item) => ({
        label: (
          <div className="d-sm-inline-flex justify-content-between w-100">
            <div>
              <div className="eb_fs_15 eb_fw_500">{item.PayeeName}</div>
              <div className="text-black-50 eb_fs_14">{item.PayeeAccountNumber}</div>
              <div className="text-black-50 eb_fs_13">{item.Description}</div>
            </div>
            <div className="eb_fs_16 eb_fw_500">
              <Number value={item.Amount} />
            </div>
          </div>
        ),
        value: item.PayeeName,
        ...item,
      }));
  };

  return (
    <div>
      <FormField
        name="Payment.Recipient"
        label={trb(i18.Labels.PayeeTitle)}
      >
        <AutoComplete
          loading={sendHistoricalPayments}
          options={renderOptions(historicalPayments)}
          handleSearch={handleSearchPayments}
          handleSelect={async (value: string) => {
            const selectedObj = renderOptions(historicalPayments).find((item) => item.PayeeName === value);

            await handleSelectPayment(selectedObj);
          }}
          suffixIcon={null}
        />
      </FormField>
    </div>
  );
};
