import { LeftOutlined } from '@ant-design/icons';
import { i18, trb } from 'src/Utilities';
import { Button } from 'src/shared';

interface IProps {
  handleBackButtonClick: () => void;
  disabled?: boolean;
}

export const BackButton = ({ handleBackButtonClick, disabled = false }: IProps) => {
  return (
    <div className="back-button">
      <Button
        className="back-button-link"
        type="link"
        icon={<LeftOutlined />}
        onClick={handleBackButtonClick}
        disabled={disabled}
      >
        {trb(i18.Labels.StepBack)}
      </Button>
    </div>
  );
};

