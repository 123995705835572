import { routerPath, history } from 'src/Config';
import { checkJsonProp } from 'src/Utilities';

export const serverErrorsMiddleware = (_store) => (next) => (action) => {
  const { type } = action,
    matches = /(.*)_(ERROR)/.exec(type);

  let result = next(action);

  if (!matches) {
    return result;
  }

  if (checkJsonProp(result, 'error.response.status') === 401) {
    localStorage.removeItem('SavedIBAN');
    localStorage.removeItem('SavedCurrency');
    sessionStorage.clear();
    history.push(routerPath.login);
    location.reload();
    return;
  }

  return result;
};
