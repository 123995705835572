export const DepositsTermsLT = `<div class="terms_wrapper">

    <p><strong>Pagrindinė informacija apie indėlio draudimą</strong></p>
    <table class="terms_table">
      <tbody>
        <tr>
          <td>UAB SME Bank laikomi indėliai yra draudžiami</td>
          <td>viešoji įstaiga „Indėlių ir investicijų draudimas“</td>
        </tr>
        <tr>
          <td>Apsaugos riba</td>
          <td>
            iki 100 000 eurų vienam indėlininkui už visus vienoje kredito įstaigoje laikomus indėlius<sup>1</sup>
          </td>
        </tr>
        <tr>
          <td>Jei turite daugiau indėlių toje pačioje kredito įstaigoje</td>
          <td>
            visi jūsų indėliai, laikomi toje pačioje kredito įstaigoje, susumuojami ir bendrai sumai taikoma 100 000 eurų apsaugos riba<sup>1</sup>
          </td>
        </tr>
        <tr>
          <td>Jei turite bendrą sąskaitą su kitu (kitais) asmeniu (asmenimis)</td>
          <td>
            100 000 eurų riba atskirai taikoma kiekvienam indėlininkui<sup>2</sup>
          </td>
        </tr>
        <tr>
          <td>Indėlių draudimo išmokos išmokėjimo terminas, jeigu kredito įstaiga nevykdo įsipareigojimų</td>
          <td>
            7 darbo dienas<sup>3</sup> <em>&nbsp;</em>
          </td>
        </tr>
        <tr>
          <td>Indėlių draudimo išmokos išmokėjimo valiuta</td>
          <td>eurai</td>
        </tr>
        <tr>
          <td>Kontaktiniai duomenys</td>
          <td>
            viešoji įstaiga „Indėlių̨ ir investicijų̨ draudimas“, Savanorių pr. 5, LT-03116, Vilnius, tel. (8 5) 213 5657, faks. (8 5) 213 5546, el. paštas
            <a href="mailto:idf@idf.lt">idf@idf.lt</a>
          </td>
        </tr>
        <tr>
          <td>Daugiau informacijos</td>
          <td>
            <a target="_blank" rel="noopener" href="http://www.iidraudimas.lt/">www.iidraudimas.lt</a>
          </td>
        </tr>
      </tbody>
    </table>
    <br />
    <p>
      <strong>Pastabos:</strong>
    </p>
    <p>
      <sup>1 </sup> Jeigu indėlis negrąžinamas dėl to, kad kredito įstaiga nepajėgi įvykdyti savo finansinių įsipareigojimų, indėlininkams draudimo įmonė išmoka indėlių draudimo 
      išmokas. Didžiausia indėlių, laikomų vienoje kredito įstaigoje, draudimo išmokos suma vienam indėlininkui yra 100 000 eurų. Nustatant indėlių draudimo išmokos dydį, visi 
      toje pačioje kredito įstaigoje laikomi indėliai yra susumuojami. Pavyzdžiui, jeigu indėlininkas turi taupomąją sąskaitą, kurioje yra 90 000 eurų, ir einamąją sąskaitą, kurioje 
      yra 20 000 eurų, jam bus išmokama tik 100 000 eurų draudimo išmoka.
    </p>
    <p>
      Tam tikrais atvejais (kai į indėlininko sąskaitą ne anksčiau kaip prieš 6 mėnesius iki indėlių draudžiamojo įvykio dienos pervesti pinigai už parduotą indėlininkui nuosavybės 
      teise priklausiusį gyvenamąjį būstą; indėlininko paveldėti pinigai pagal testamentą arba pagal įstatymą; indėlininko kaip naudos gavėjo pagal gyvybės draudimo sutartį ar jos 
      esmę atitinkančią sutartį mirties atveju gauti pinigai; indėlininko kaip kompensacija arba išmoka įstatymų nustatytais atvejais dėl kito asmens žūties atliekant darbo ar tarnybines 
      pareigas gauti pinigai; išmokos ar kompensacijos už smurtiniais nusikaltimais padarytą žalą) indėliai apsaugomi didesne kaip 100 000 eurų suma.
    </p>
    <p>
      Daugiau informacijos galima rasti interneto svetainėje adresu: <a target="_blank" rel="noopener" href="http://www.iidraudimas.lt/">www.iidraudimas.lt</a>.
    </p>
    <p>
      <sup>2 </sup>Jeigu turite bendrą sąskaitą su kitu (kitais) asmeniu (asmenimis), 100 000 eurų apsaugos riba atskirai taikoma kiekvienam indėlio bendraturčiui.
    </p>
    <p>
      <sup>3 </sup>Draudimo išmokų išmokėjimas.
    </p>
    <p>
    Atsakinga už indėlių draudimo sistemą yra draudimo įmonė (viešoji įstaiga „Indėlių̨ ir investicijų̨ draudimas“, Savanorių pr. 5, LT-03116, Vilnius, tel. (8 5) 213 5657, faks. (8 5) 213 5546, 
    el. paštas: <a href="mailto:idf@idf.lt">idf@idf.lt</a>), interneto svetainė: <a target="_blank" rel="noopener" href="http://www.iidraudimas.lt/">www.iidraudimas.lt</a>. Ji grąžins 
    jūsų indėlius (iki 100 000 eurų) ne vėliau kaip per:
    </p>
    <p>• 10 darbo dienų nuo 2021 m. sausio 1 d. iki 2023 m. gruodžio 31 d.;</p>
    <p>• 7 darbo dienas nuo 2024 m. sausio 1 d.</p>
    <p>
      Jei iki 2023 m. gruodžio 31 d. indėlių draudimo išmokos neišmokamos per 7 darbo dienas nuo indėlių draudžiamojo įvykio dienos, nuo 2016 m. gegužės 16 d. indėlininko prašymu jam per
      5 darbo dienas nuo prašymo gavimo dienos išmokama minimaliosios mėnesinės algos dydžio, bet ne didesnė negu indėlininko turimų tinkamų drausti indėlių suma, indėlių draudimo išmokos 
      dalis (avansinė indėlių draudimo išmoka).
    </p>
    <p>
      Jeigu per šiuos terminus draudimo išmoka jums nebuvo išmokėta, turėtumėte susisiekti su draudimo įmone. Daugiau informacijos galima rasti interneto svetainėje 
      adresu: <a target="_blank" rel="noopener" href="http://www.iidraudimas.lt/">www.iidraudimas.lt</a>.
    </p>
    <p>
      <strong>Kita svarbi informacija:</strong>
    </p>
    <p>Iš principo visi mažmeninių indėlininkų ir įmonių indėliai yra apdrausti draudimo įmonėje.</p>
    <p>
      Tam tikriems indėliams taikomos išimtys nurodytos draudimo įmonės interneto svetainėje
      <a target="_blank" rel="noopener" href="http://www.iidraudimas.lt">www.iidraudimas.lt</a>. Jūsų paprašyta kredito įstaiga taip pat informuos jus, ar tam tikri produktai yra apdrausti. Jeigu indėliai apdrausti, kredito įstaiga tai patvirtina ir sąskaitos išraše.
    </p>

    <p>
      <strong>PAGRINDINĖ INFORMACIJA APIE ATVEJUS, KAI INDĖLIAI NĖRA DRAUDŽIAMI IR KAI YRA TAIKOMI INDĖLIŲ DRAUDIMO IŠMOKŲ MOKĖJIMO APRIBOJIMAI</strong>
    </p>

    <table class="terms_table">
      <tbody>
        <tr>
          <td>UAB SME Bank laikomi indėliai yra draudžiami</td>
          <td>viešoji įstaiga „Indėlių ir investicijų draudimas“</td>
        </tr>
        <tr>
          <td valign="top">Atvejai, kai indėliai nėra draudžiami</td>
          <td>
            Informuojama, kad pagal Lietuvos Respublikos indėlių ir įsipareigojimų investuotojams draudimo įstatymo 3 straipsnio 2 dalį indėlių draudimo objektas nėra (nurodytų subjektų 
            indėliai nėra indėlių draudimo objektas, neatsižvelgiant į tai, pagal kurios valstybės teisės aktus šie subjektai yra įsteigti):
            <br/>
            <em>1.1. kitų kredito įstaigų indėliai, laikomi savo sąskaita ir savo vardu;</em>
            <br/>
            <em>1.2. nuosavos lėšos;</em>
            <br/>
            <em>1.3. indėliai, dėl kurių priimtas apkaltinamasis nuosprendis baudžiamojoje byloje dėl pinigų plovimo;</em>
            <br/>
            <em>1.4. finansų įstaigų indėliai;</em>
            <br/>
            <em>1.5. valdymo įmonių indėliai;</em>
            <br/>
            <em>1.6. finansų maklerio įmonių indėliai;</em>
            <br/>
            <em>1.7. indėliai, kurių savininkų tapatybė nenustatyta (anoniminėse ir koduotose sąskaitose laikomi indėliai);1.8. draudimo įmonių ir perdraudimo įmonių, kitose valstybėse narėse ir trečiosiose valstybėse įsteigtų draudimo įmonių ir perdraudimo įmonių indėliai;</em>
            <br/>
            <em>1.9. kolektyvinio investavimo subjektų indėliai;</em>
            <br/>
            <em>1.10. pensijų fondų indėliai;</em>
            <br/>
            <em>1.11. valstybės ir savivaldybių institucijų ir įstaigų, kaip jos apibrėžtos Lietuvos Respublikos valstybės tarnybos įstatyme, indėliai, išskyrus tokių institucijų ir įstaigų depozitinėse sąskaitose laikomas kitiems asmenims priklausančias lėšas;</em>
            <br/>
            <em>1.12. Lietuvos banko indėliai;</em>
            <br/>
            <em>1.13. paties indėlių draudimo sistemos dalyvio išleisti skolos vertybiniai popieriai ir įsipareigojimai, susiję su jo akceptais ir paprastaisiais vekseliais;</em>
            <br/>
            <em>1.14. elektroniniai pinigai ir iš elektroninių pinigų turėtojų mainams į elektroninius pinigus gautos lėšos.</em>
          </td>
        </tr>
        <tr>
          <td valign="top">Atvejai, kai yra taikomi indėlių draudimo išmokų mokėjimo apribojimai</td>
          <td>
            Informuojama, kad pagal Indėlių ir įsipareigojimų investuotojams draudimo įstatymo 8 straipsnį indėlių draudimo išmokos nemokamos:
            <br/>
            <em>2.1. už indėlius, lėšas, pinigus, vertybinius popierius ir įsipareigojimus, kurie pagal Indėlių ir įsipareigojimų investuotojams draudimo įstatymo 3 straipsnio 2 dalį nėra indėlių draudimo objektas;</em>
            <br/>
            <em>2.2. indėlininkams už indėlius sąskaitoje, kurioje per pastaruosius 24 mėnesius iki indėlių draudžiamojo įvykio dienos nėra atlikta jokių su indėliu susijusių operacijų ir laikomo indėlio suma yra mažesnė kaip 10 eurų;</em>
            <br/>
            <em>2.3. indėlininkams ar kitiems asmenims, kurie turi reikalavimo teisę į indėlių draudimo išmoką ir kuriems dėl jų laikomų kaip indėlių pinigų įsigijimo teisėtumo priimtas apkaltinamasis nuosprendis baudžiamojoje byloje dėl pinigų plovimo ir (ar) teroristų finansavimo.</em>
          </td>
        </tr>
        <tr>
          <td valign="top">Atvejai, kai indėlių draudimo išmokos išmokėjimas atidedamas</td>
          <td>
            Informuojama, kad pagal Indėlių ir įsipareigojimų investuotojams draudimo įstatymo 7 straipsnio 4 dalį indėlių draudimo išmokos išmokėjimas atidedamas, kai:
            <br/>
            <em>3.1. trūksta duomenų, kuriais pagrindžiama besikreipiančio asmens teisė į indėlių draudimo išmoką, arba dėl indėlio vyksta teisminis ginčas, – iki bus pateikti duomenys, kuriais pagrindžiama teisė gauti indėlių draudimo išmoką, arba įsiteisės galutinis teismo sprendimas, kuriuo išsprendžiamas ginčas dėl indėlio;</em>
            <br/>
            <em>3.2. įstatymų nustatytais atvejais ir tvarka indėlininkui apribota teisė disponuoti indėliu, – iki apribojimų panaikinimo;</em>
            <br/>
            <em>3.3. per pastaruosius 24 mėnesius iki indėlių draudžiamojo įvykio dienos nėra atlikta jokių su indėliu susijusių operacijų, – iki 3 mėnesių nuo indėlių draudžiamojo įvykio dienos;</em>
            <br/>
            <em>3.4. draudimo įmonė iš indėlių draudimo sistemos dalyvio gauna duomenis apie indėlių draudimo išmokos sumą, kuri bus didesnė kaip 100 000 eurų dėl Indėlių ir įsipareigojimų investuotojams draudimo įstatymo 4 straipsnio 2 dalyje nurodytų priežasčių, sumai, viršijančiai 100 000 eurų, – iki bus išsiaiškintos Indėlių ir įsipareigojimų investuotojams draudimo įstatymo 4 straipsnio 2 dalyje nurodytos sumos, bet ne ilgiau kaip 3 mėnesiams nuo indėlių draudžiamojo įvykio dienos;</em>
            <br/>
            <em>3.5. tinkamas drausti indėlis laikomas bendroje ar depozitinėje sąskaitoje, – iki bus išsiaiškintos konkrečiam asmeniui bendroje ar depozitinėje sąskaitoje priklausančios sumos, bet ne ilgiau kaip 3 mėnesiams nuo indėlių draudžiamojo įvykio dienos;</em>
            <br/>
            <em>3.6. indėlių draudimo išmokos mokamos Lietuvos Respublikoje įsteigtos kredito įstaigos priimančiojoje valstybėje narėje įsteigto filialo indėlininkams pagal Indėlių ir įsipareigojimų investuotojams draudimo įstatymo 7 straipsnio 7 dalį, – iki priimančiosios valstybės narės indėlių draudimo sistemos administratorius patvirtins, kad jis yra pasiruošęs mokėti indėlių draudimo išmokas;</em>
            <br/>
            <em>3.7. indėlių draudimo išmokos mokamos kitoje valstybėje narėje buveinę turinčios kredito įstaigos Lietuvos Respublikoje įsteigto filialo indėlininkams pagal Indėlių ir įsipareigojimų investuotojams draudimo įstatymo 7 straipsnio 8 dalį, – iki buveinės valstybės narės indėlių draudimo sistemos, kurios dalyvė yra kredito įstaiga, įsteigusi filialą Lietuvos Respublikoje, administratorius pateiks draudimo įmonei duomenis, reikalingus draudimo išmokoms išmokėti, ir perves draudimo išmokoms išmokėti reikiamą lėšų sumą;</em>
            <br/>
            <em>3.8. indėlininkui ar kitam asmeniui, kurie turi reikalavimo teisę į indėlių draudimo išmoką, dėl jų laikomų kaip indėlių pinigų įsigijimo teisėtumo pareikštas kaltinimas dėl pinigų plovimo ir (ar) teroristų finansavimo, – iki galutinio teismo sprendimo įsiteisėjimo dienos.</em>
          </td>
        </tr>
        <tr>
          <td>Kontaktiniai duomenys</td>
          <td>
            viešoji įstaiga „Indėlių̨ ir investicijų̨ draudimas“, Savanorių pr. 5, LT-03116, Vilnius, tel. (8 5) 213 5657, faks. (8 5) 213 5546, el. paštas
            <a href="mailto:idf@idf.lt">idf@idf.lt</a>
          </td>
        </tr>
        <tr>
          <td>Daugiau informacijos</td>
          <td>
            <a target="_blank" rel="noopener" href="http://www.iidraudimas.lt">
              www.iidraudimas.lt
            </a>
          </td>
        </tr>
      </tbody> 
    </table>
  </div>
`;

export const DepositsTermsEN = `<div class="terms_wrapper">
      <div>
        <div>
          <p><strong>Main Information on Deposit Insurance</strong></p>
          <table class='terms_table'>
            <tbody>
              <tr>
                <td>Deposits held by UAB SME Bank are insured</td>
                <td>Public Institution Deposit and Investment Insurance</td>
              </tr>
              <tr>
                <td>Insurance limit</td>
                <td>
                  up to EUR 100 000 for a single depositor for all the deposits held in a single credit institution<sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>If you have more deposits in the same credit institution</td>
                <td>
                  all of your deposits held in the same credit institution are totalised and an insurance limit of EUR 100 000 is applied to the total amount<sup>1</sup>
                </td>
              </tr>
              <tr>
                <td>If you have a joint account with other person (other persons)</td>
                <td>
                  the insurance limit of EUR 100 000 is applied individually to each depositor<sup>2</sup>
                </td>
              </tr>
              <tr>
                <td>The period for paying the insurance compensation for deposits if the credit union fails to fulfil its liabilities</td>
                <td>
                  7 working days<sup>3</sup>
                </td>
              </tr>
              <tr>
                <td>The currency of the payment of the insurance compensation for deposits</td>
                <td>euros</td>
              </tr>
              <tr>
                <td>Contact information</td>
                <td>
                  Public Institution Deposit and Investment Insurance, address: Savanoriu av. 5, LT-03116, Vilnius, tel.: (+370 5) 213 5657, fax: (+370 5) 213 5546, email: <a href="mailto:idf@idf.lt">idf@idf.lt</a>
                </td>
              </tr>
              <tr>
                <td>Further information</td>
                <td>
                  <a target="_blank" rel="noopener" href="https://www.iidraudimas.lt/en/">www.iidraudimas.lt</a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <p>
            <strong>Remarks:</strong>
          </p>
          <p>
            <sup>1 </sup>If a deposit is not compensated as a result of UAB SME Bank’s inability to fulfil its financial obligations, deposit insurance compensations to depositors shall be paid by insurance company. Maximum amount of insurance compensation of deposits with UAB SME Bank per single depositor is EUR 100 000. The amount of deposit insurance compensation is determined by summing up all the deposits held with UAB SME Bank. For instance, if a depositor has a savings account with relevant account balance of EUR 90 000 and a current account with relevant account balance of EUR 20 000, the amount that will be disbursed to such depositor as insurance compensation will be only EUR 100 000.
          </p>
          <p>
            In some specific cases (funds from sales of residential property owned by a depositor have been transferred to the depositor’s account no more than 6 months before the occurrence of the deposit insured event; funds inherited by a depositor as legatee or successor; funds received by a depositor as a beneficiary under a life insurance contract or under any other contract that conforms to its essence in the event of death; funds received by a depositor as a compensation or benefit in the cases and under the conditions provided for by law due to fatality of another person when performing his/her professional or official duties; benefits or compensations for damage rendered by violent crimes) deposit coverage is higher than EUR 100 000.
          </p>
          <p>
            More detailed information is available at the following website: <a target="_blank" rel="noopener" href="https://www.iidraudimas.lt/en/">www.iidraudimas.lt</a>.
          </p>
          <p>
            <sup>2 </sup>If you have a joint account with another (other) person (persons), the insurance coverage limit of EUR 100 000 applies to each joint owner of the deposit.
          </p>
          <p>
            <sup>3 </sup>Insurance compensation disbursement.
          </p>
          <p>
            Institution responsible for the deposit insurance scheme is insurance company (Public Institution Deposit and Investment Insurance, address: Savanoriu av. 5, LT-03116, Vilnius, tel.: 8 5 2135657, fax: 8 5 2135546, email: <a href="mailto:idf@idf.lt">idf@idf.lt</a>), website address:
            <a target="_blank" rel="noopener" href="https://www.iidraudimas.lt/en/">www.iidraudimas.lt</a>.
            It will compensate your deposits (up to EUR 100,000) no later than within:
          </p>
          <p>• 10 working days from 1 January 2021 until 31 December 2023;</p>
          <p>• 7 working days from 1 January 2024.</p>
          <p></p>
          <p>
            If insurance compensations for deposits are not paid in 7 working days from the day of the insured event of deposits, a compensation share (advance insurance compensation for deposits) equal to the minimum monthly wage but not larger than the amount of the depositor’s insurable deposits shall be paid in 5 working days after the receipt of the request as of 16 May 2016.
          </p>
          <p>
            If within said periods no insurance compensation was disbursed to you, you should contact insurance company. More detailed information is available at the following website: <a target="_blank" rel="noopener" href="https://www.iidraudimas.lt/en/">www.iidraudimas.lt</a>.
          </p>
          <p>
            <strong>Other important information</strong>
          </p>
          <p>
            In principle, all deposits of retail depositors and corporate depositors are insured by insurance company. Exceptions applied to certain deposits are provided at the website of insurance company: <a target="_blank" rel="noopener" href="https://www.iidraudimas.lt/en/">www.iidraudimas.lt</a>. UAB SME Bank, at your request, will also inform you whether a product is or is not covered by insurance. If deposits are insured, UAB SME Bank confirms this in statement of account. 
          </p>

          <p><strong>MAIN INFORMATION ON CASES WHERE DEPOSITS ARE NOT INSURED AND WHERE RESTRICTIONS TO THE PAYMENT OF INSURANCE COMPENSATION FOR DEPOSITS APPLY</strong></p>
          <table class='terms_table'>
            <tbody>
              <tr>
                <td>
                  Deposits held by UAB SME Bank are insured
                </td>
                <td>
                  Public Institution Deposit and Investment Insurance
                </td>
              </tr>
              <tr>
                <td valign="top">
                  Cases where deposits are not insured
                </td>
                <td>
                  Please be informed that according to Article 3(2) of the Republic of Lithuania Law on Insurance of Deposits and Liabilities to Investors, deposit insurance shall not cover (the deposits of the specified entities shall not be the object of deposit insurance, regardless of the legislation of the country under which these entities are incorporated):
                  <br />
                  <em>1.1. deposits of other credit institutions, held on own account and in own name;</em>
                  <br />
                  <em>1.2. own funds;</em>
                  <br />
                  <em>1.3. deposits which have resulted in a conviction in a criminal case concerning money laundering;</em>
                  <br />
                  <em>1.4. deposits of financial institutions;</em>
                  <br />
                  <em>1.5. deposits of management companies;</em>
                  <br />
                  <em>1.6. deposits of financial brokerage firms;</em>
                  <br />
                  <em>1.7. deposits which have no identified owner (deposits held in anonymous and coded accounts);</em>
                  <br />
                  <em>1.8. deposits of insurance and reinsurance companies, as well as deposits of insurance and reinsurance companies established in other Member States and third countries;</em>
                  <br />
                  <em>1.9. deposits of collective investment entities;</em>
                  <br />
                  <em>1.10. deposits of pension funds;</em>
                  <br />
                  <em>1.11. deposits of state and municipal institutions and bodies as defined in the Republic of Lithuania Law on the State Service, except for funds belonging to other persons held in deposit accounts of such institutions and bodies;</em>
                  <br />
                  <em>1.12. deposits of the Bank of Lithuania;</em>
                  <br />
                  <em>1.13. debt securities and liabilities issued by the participant of the deposit insurance system himself, related to his acceptances and promissory notes;</em>
                  <br />
                  <em>1.14. electronic money and funds received by electronic money institutions from electronic money holders in exchange for electronic money.</em>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  Cases where restrictions to the payment of insurance compensation for deposits apply
                </td>
                <td>
                  It is hereby informed that according to Article 8 of the Republic of Lithuania Law on Insurance of Deposits and Liabilities to Investors, no deposit insurance benefits are paid:
                  <br />
                  <em>2.1. for any deposits, funds, monies, securities and/or liabilities which according to Article 3, Part 2 of the Republic of Lithuania Law on Insurance of Deposits and Liabilities to Investors are not the object of deposit insurance;</em>
                  <br />
                  <em>2.2. to depositors for deposits in an account, in which no transactions related to a deposit have been executed over 24 months before the date of the respective deposit insured event and the amount of the held deposit is smaller than EUR 10;</em>
                  <br />
                  <em>2.3. to depositors or other persons, which have the right of claim to the deposit insurance benefit and in respect of which, by reason of the lawfulness of acquisition of funds kept by them in the form of deposits, a judgment of conviction has been passed in a criminal case concerning money laundering and (or) terrorist financing.</em>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  Cases where the payment of insurance compensation for deposits is deferred
                </td>
                <td>
                  Please be informed that according to Article 7(4) of the Law on Insurance of Deposits and Liabilities to Investors, the payment of insurance compensation for deposits shall be deferred, when:
                  <br />
                  <em>3.1. there is a lack of data justifying the right of the requestor to the insurance compensation for deposits or if the deposit is the subject of a legal dispute; until the data justifying the right to the insurance compensation for deposits is provided or until the final decision of the court is enforced resolving the deposit dispute;</em>
                  <br />
                  <em>3.2. the depositor’s right to access the deposit is limited in the cases and under the conditions provided for by laws; until these limitations are lifted;</em>
                  <br />
                  <em>3.3. no deposit-related transactions have taken place in 24 months preceding the date of the deposit insurance event; up to 3 months after the date of the deposit insurance event;</em>
                  <br />
                  <em>3.4. the insurance company receives information from the participant of the deposit insurance system in regard to the amount of insurance compensation for deposits, which will exceed EUR 100 000 due to reasons indicated in Article 4(2) of the Law on Insurance of Deposits and Liabilities to Investors, and for the amount exceeding EUR 100 000; until the amounts indicated in Article 4(2) of the Law on Insurance of Deposits and Liabilities to Investors are clarified, but no later than 3 months after the date of the deposit insurance event;</em>
                  <br />
                  <em>3.5. the eligible deposit is held in a joint or deposit account; until the amounts belonging to a specific person in a joint or deposit account are clarified, but no later than 3 months after the date of the deposit insurance event;</em>
                  <br />
                  <em>3.6. the payment of insurance compensation for deposits is made to the depositors of a branch established in a host Member State by a credit institution established in the Republic of Lithuania under Article 7(7) of the Law on Insurance of Deposits and Liabilities to Investors; until the administrator of deposit insurance system of a host Member State confirms that they are prepared to pay insurance compensation for deposits;</em>
                  <br />
                  <em>3.7. the payment of insurance compensation for deposits is made to the depositors of a branch established in the Republic of Lithuania by a credit institution having its registered office in another Member State under Article 7(8) of the Law on Insurance of Deposits and Liabilities to Investors; until the administrator of deposit insurance system of a Member State of the registered office, where the credit institution that has established a branch in the Republic of Lithuania is a participant of that system, provides the information to the insurance company that is required for the payment of insurance compensation for deposits and transfers the amount of funds required for the payment of insurance compensation for deposits;</em>
                  <br />
                  <em>3.8. a depositor or other person who has the right of claim to an insurance compensation for deposit is charged with money laundering and/or terrorist financing in relation to the legality of the acquisition of the money held as a deposit; until the date of becoming effective of the final decision of the court.</em>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  Contact information
                </td>
                <td>
                  Public Institution Deposit and Investment Insurance, address: Savanoriu av. 5, LT-03116, Vilnius, tel.: (+370 5) 213 5657, fax: (+370 5) 213 5546, email: <a href="mailto:idf@idf.lt">idf@idf.lt</a>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  Further information
                </td>
                <td>
                  <a target="_blank" rel="noopener" href="https://www.iidraudimas.lt/en/">www.iidraudimas.lt</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
`;
