import axios from 'axios';
import { apiElkus, appRoot } from './paths';

export const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const axiosLanguage = function (language) {
  requestEBankAnonymous.defaults.headers.common['Accept-Language'] = language ? language : localStorage.getItem('language');
  requestEBank.defaults.headers.common['Accept-Language'] = language ? language : localStorage.getItem('language');
};

export const axiosSetDefaultHeaderParams = (version) => {
  requestEBank.defaults.headers.common['X-Version-Control'] = version;
  requestEBankJSON.defaults.headers.common['X-Version-Control'] = version;
  requestEBankFile.defaults.headers.common['X-Version-Control'] = version;
};

export const responseTypes = {
  arrayBuffer: 'arraybuffer',
};

export const requestEBankAnonymous = axios.create({
  withCredentials: true,
  baseURL: apiElkus,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: '*/*',
  },
});

export const requestEBankAnonymousRoot = axios.create({
  withCredentials: false,
  baseURL: appRoot,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: '*/*',
  },
});

export const requestEBank = axios.create({
  baseURL: apiElkus,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: '*/*',
  },
});

export const requestEBankJSON = axios.create({
  baseURL: apiElkus,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
  },
});

export const requestEBankFile = axios.create({
  baseURL: apiElkus,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data; charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
  },
});
