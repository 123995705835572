import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import createDecorator from 'final-form-calculate';
import { DownloadOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';

import { blobToFileDownload, CenturyGothic, CenturyGothicBold, dateFormat, formatCurrentDate, formatDate, getIDFLink, i18, logError, translationGroups, trb } from 'src/Utilities';
import { Notification, Number, Print } from 'src/Components';
import { AMCActions, AMCConstants, CMCActions, CMCConstants, DMCActions, DMCConstants, PrintActions } from 'src/Redux';
import { InvoiceFilter } from './';

import moment from 'moment';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const InvoiceFilterContainer = (props) => {
  const dispatch = useDispatch();
  const { ADR, sendADR, COMPANY } = useSelector((state) => state.AMCReducers);
  const { MALR } = useSelector((state) => state.UACReducers);
  const { language } = useSelector((state) => state.languageReducer);

  const [loadingAction, setLoadingAction] = useState('');
  const [modalScheduleVisible, setModalScheduleVisible] = useState(false);

  const PERIOD_SELECTED_DATES = 7;

  const [decorator] = useState([
    createDecorator(
      {
        field: 'FilterBeginDate',
        updates: (value, field, values) => {
          if (value && typeof value == 'string') {
            values.FilterID = PERIOD_SELECTED_DATES;
          }
          return {};
        },
      },
      {
        field: 'FilterEndDate',
        updates: (value, field, values) => {
          if (value && typeof value == 'string') {
            values.FilterID = PERIOD_SELECTED_DATES;
          }
          return {};
        },
      },
      {
        field: 'FilterID',
        updates: (value, field, values) => {
          if (value) {
            if (value !== PERIOD_SELECTED_DATES) {
              values.FilterBeginDate = moment(new Date()).format(dateFormat.date);
              values.FilterEndDate = moment(new Date()).format(dateFormat.date);
            }
            if (value === 2) {
              values.FilterBeginDate = moment(new Date()).subtract(7, 'days').format(dateFormat.date);
              values.FilterEndDate = moment(new Date()).format(dateFormat.date);
            }
            if (value === 3) {
              values.FilterBeginDate = moment(new Date()).subtract(30, 'days').format(dateFormat.date);
              values.FilterEndDate = moment(new Date()).format(dateFormat.date);
            }
            if (value === 4) {
              values.FilterBeginDate = moment(new Date()).date(1).format(dateFormat.date);
              values.FilterEndDate = moment(new Date()).format(dateFormat.date);
            }
            if (value === 6) {
              values.FilterBeginDate = moment(new Date()).subtract(1, 'month').date(1).format(dateFormat.date);
              values.FilterEndDate = moment(new Date()).subtract(1, 'month').date(moment(values.FilterBeginDate).daysInMonth()).format(dateFormat.date);
            }
          }
          return {};
        },
      }
    ),
  ]);
  const [showExcel, setShowExcel] = useState(true);
  const [filter, setFilter] = useState({
    FilterID: 2,
    AccountID: props.accountId,
    FilterBeginDate: moment(new Date()).subtract(7, 'days'),
    FilterEndDate: moment(new Date()),
  });

  useEffect(() => {
    var accountId = ADR?.AccoutWithDetails?.AccountID ?? props.accountId;
    if (sendADR) {
      accountId = null;
    }
    setFilter((prevState) => {
      return {
        ...prevState,
        AccountID: accountId,
      };
    });
  }, [ADR]);

  const submit = async (values) => {
    if (!values?.FilterID) values.FilterID = PERIOD_SELECTED_DATES;

    setLoadingAction('submit');
    setFilter(values);

    await dispatch(AMCActions.postADR(values));
    setLoadingAction('');
    setShowExcel(values.FilterID !== 1);
  };

  const download = async (values) => {
    if (!values?.FilterID) values.FilterID = PERIOD_SELECTED_DATES;

    setLoadingAction('downloadingPDF');
    setFilter(values);

    try {
      const response = await dispatch(DMCActions.postADPDF(values, language));
      if (response?.type === DMCConstants.POST_DMC_ADPDF_SUCCESS) {
        const nameFile = `${ADR?.AccountWithDetails?.AccountNumber?.replace(/\s/g, '')}_${formatDate(filter.FilterBeginDate)?.replace?.(/-/g, '')}-${formatDate(
          filter.FilterEndDate
        ).replace(/-/g, '')}.pdf`;
        blobToFileDownload(nameFile, response.payload, 'application/pdf');
      }
    } catch (error) {
      logError(error);
    } finally {
      setLoadingAction('');
    }
  };

  const print = async () => {
    setLoadingAction('printing');

    const response = await dispatch(AMCActions.postADRPrint(filter));
    if (response.type === AMCConstants.POST_ADR_PRINT_SUCCESS) {
      const invoices = response?.payload?.AccountWithDetails?.AccountDetails;
      const ActiveAccount = MALR?.ManagedAccounts != null ? MALR.ManagedAccounts.find((x) => x.IsActive === true) : null;
      const IDF = ActiveAccount?.IDF ? ActiveAccount.IDF : false;

      if (!invoices) {
        Notification({
          type: 'warning',
          message: i18.NotificationTitles.ListEmpty,
          description: i18.NotificationMessages.InvoiceListEmpty,
        });

        return null;
      }

      dispatch(
        PrintActions.setContent(
          <Print
            type="Invoice"
            data={{ IDF, ...response.payload }}
          />
        )
      );
    }
    setLoadingAction('');
  };

  const downloadExcel = async (filter) => {
    setLoadingAction('downloadingExcel');

    const response = await dispatch(AMCActions.postADRPrint(filter));
    if (response.type === AMCConstants.POST_ADR_PRINT_SUCCESS) {
      const ADR = response.payload;
      const managingAccount = MALR.managingAccount ? MALR.managingAccount : MALR.ManagedAccounts.find((x) => x.IsActive === true);
      const AccountWithDetails = ADR?.AccountWithDetails;
      const AccountDetails = AccountWithDetails?.AccountDetails;

      const AccountStatementItems = AccountDetails.map((item) => {
        return {
          Date: item.Date,
          Description: `${item?.Pay_Rec} — ${item?.Description}`,
          Amount: item?.Income?.[0]?.Text ? `+${item?.Income?.[0]?.Text}` : `-${item?.Expediture?.[0]?.Text}`,
          Fee: item?.PaymentFee?.[0]?.Text ? `-${item?.PaymentFee?.[0]?.Text}` : '',
          UniquePaymentCode: item.UniquePaymentCode,
        };
      });

      const AccountStatementFooterArray = ['InitialBalance', 'TotalIncome', 'TotalExpenditures', 'FinalBalance', 'CreditLimit', 'BlockedAmount', 'Reserved'];
      const AccountStatementFooterItems = AccountStatementFooterArray.map((item) => {
        return {
          Caption: trb(translationGroups.AccountDetails + item),
          Value: AccountWithDetails?.[item]?.[0]?.Text,
          BottomBorder: item === 'CreditLimit',
        };
      });

      const data = {
        FilterID: filter.FilterID ?? PERIOD_SELECTED_DATES,
        AccountID: filter.AccountID,
        AccountNumber: ADR?.AccountWithDetails?.AccountNumber,
        PrintDate: formatDate(formatCurrentDate(), dateFormat().dateTime),
        PrintInterval: getPrintDates(filter),
        ClientName: managingAccount?.Name,
        ClientCode: managingAccount?.ClientCode,
        ClientAddress: managingAccount?.ClientAddress,
        AccountStatementItems,
        AccountStatementFooterItems,
        InsurText: notesInfo(),
      };

      const resp = await dispatch(AMCActions.postACCSXLSX(data, language));
      if (resp.type === AMCConstants.POST_AMC_ACCSXLSX_SUCCESS) {
        const nameFile = `${ADR?.AccountWithDetails?.AccountNumber?.replace(/\s/g, '')}_${formatDate(filter.FilterBeginDate)?.replace?.(/-/g, '')}-${formatDate(
          filter.FilterEndDate
        ).replace(/-/g, '')}.xlsx`;
        blobToFileDownload(nameFile, resp.payload, 'application/xlsx');
        setLoadingAction('');
      }
    }
  };

  const downloadXML = async (filter) => {
    setLoadingAction('downloadingXML');
    const date = {
      dateFrom: filter.FilterBeginDate,
      dateTo: filter.FilterEndDate,
    };
    const data = {
      AccountID: filter.AccountID,
      DateFrom: moment(date.dateFrom).format('YYYY-MM-DD'),
      DateTo: moment(date.dateTo).format('YYYY-MM-DD'),
    };

    const response = await dispatch(CMCActions.postGetAccountBalanceXml(data));
    if (response.type === CMCConstants.POST_CMC_GET_ACCOUNT_BALANCE_XML_SUCCESS) {
      const nameFile = `${ADR?.AccountWithDetails?.AccountNumber?.replace(/\s/g, '')}_${formatDate(filter.FilterBeginDate)?.replace?.(/-/g, '')}-${formatDate(
        filter.FilterEndDate
      ).replace(/-/g, '')}.xml`;
      blobToFileDownload(nameFile, response.payload, 'application/xml');
    }
    setLoadingAction('');
  };

  const getPrintDates = (filter) => {
    return `${formatDate(filter.FilterBeginDate)} - ${formatDate(filter.FilterEndDate)}`;
  };

  const notesInfo = () => {
    const typeCodesSee = [131, 132, 22, 23, 24];
    const typeCode = ADR?.AccountWithDetails?.AccountTypeCode ? parseInt(ADR?.AccountWithDetails?.AccountTypeCode) : null;
    const ActiveAccount = MALR?.ManagedAccounts != null ? MALR.ManagedAccounts.find((x) => x.IsActive === true) : null;
    const IDF = ActiveAccount?.IDF ? ActiveAccount.IDF : false;
    if (!typeCodesSee.includes(typeCode)) {
      return '';
    }
    if (IDF) {
      return `${trb(i18.Labels.Note)} ${trb(i18.Labels.BankText)} ${COMPANY?.[getIDFLink(language)]}`;
    }
    return null;
  };

  const showModalSchedule = () => {
    setModalScheduleVisible(true);
  };

  const scheduleDownload = () => {
    const scheduleData = ADR?.AccountWithDetails?.Data?.scheduleItems,
      title = `${trb(i18.ModalTitles.CreditLoanShedule)} ${ADR?.AccountWithDetails?.AccountNumber}`,
      date = formatDate(formatCurrentDate(), dateFormat().dateTime);

    const doc = new jsPDF(),
      body = scheduleData.map((item) => {
        return [
          item.dateDisplay,
          item.loanAmount.toFixed(2).replace('.', ','),
          item.interestAmount.toFixed(2).replace('.', ','),
          item.installment.toFixed(2).replace('.', ','),
          item.loanResidual.toFixed(2).replace('.', ','),
        ];
      });

    doc.addFileToVFS('CenturyGothic.ttf', CenturyGothic);
    doc.addFileToVFS('CenturyGothicBold.ttf', CenturyGothicBold);
    doc.addFont('CenturyGothic.ttf', 'CenturyGothic', 'normal');
    doc.addFont('CenturyGothicBold.ttf', 'CenturyGothicBold', 'bold');
    doc.setFont('CenturyGothic');

    const imgUrl = import.meta.env.VITE_LOGO.replace(/\.svg/gi, '_pdf.png');

    try {
      doc.addImage(`${import.meta.env.VITE_ROOT}images/logos/${imgUrl}`, 'PNG', 7, 7, 42, 10);
    } catch (err) {
      console.log(err);
    }

    let companyHeader = [];
    if (COMPANY?.OrgName)
      companyHeader.push([
        {
          content: COMPANY.OrgName,
          colSpan: 2,
          styles: { halign: 'left' },
        },
      ]);
    if (COMPANY?.OrgCode)
      companyHeader.push([
        {
          content: trb(i18.PrintLabels.CompanyCode),
          styles: { cellWidth: 20 },
        },
        { content: COMPANY.OrgCode },
      ]);
    if (COMPANY?.OrgAddress) companyHeader.push([{ content: trb(i18.PrintLabels.CompanyAddress) }, { content: COMPANY.OrgAddress }]);
    if (COMPANY?.VATCode) companyHeader.push([{ content: trb(i18.PrintLabels.VatCode), styles: { cellWidth: 20 } }, { content: COMPANY.VATCode }]);
    if (COMPANY?.OrgEMail)
      companyHeader.push([
        {
          content: trb(i18.PrintLabels.CompanyEmail),
          styles: { cellWidth: 20 },
        },
        { content: COMPANY.OrgEMail },
      ]);

    doc.autoTable({
      theme: 'plain',
      styles: {
        font: 'CenturyGothic',
        fontSize: 7,
        cellPadding: 0.5,
        overflow: 'hidden',
      },
      columns: [{ dataKey: 'label' }, { dataKey: 'value' }],
      columnStyles: {
        label: {
          font: 'CenturyGothicBold',
          fontStyle: 'bold',
          halign: 'left',
        },
      },
      startY: 20,
      margin: { left: 7, right: 130 },
      body: companyHeader,
    });

    doc.autoTable({
      theme: 'plain',
      styles: {
        font: 'CenturyGothic',
        fontSize: 7,
        cellPadding: 0.5,
        overflow: 'hidden',
      },
      columns: [{ dataKey: 'label' }, { dataKey: 'value' }],
      columnStyles: {
        label: {
          font: 'CenturyGothicBold',
          fontStyle: 'bold',
          halign: 'left',
        },
      },
      startY: 20,
      margin: { left: 108, right: 7 },
      body: [
        [
          {
            content: trb(i18.PrintLabels.FormattedDateAndTime),
            styles: { cellWidth: 25 },
          },
          { content: date },
        ],
        [{ content: trb(i18.PrintLabels.Client), styles: { cellWidth: 25 } }, { content: ADR.ClientName }],
        [
          {
            content: trb(i18.PrintLabels.ClientCode),
            styles: { cellWidth: 25 },
          },
          { content: ADR.ClientCode },
        ],
        [
          {
            content: trb(i18.PrintLabels.ClientAddress),
            styles: { cellWidth: 25 },
          },
          { content: ADR.ClientAddress },
        ],
      ],
    });

    doc.autoTable({
      theme: 'plain',
      styles: {
        font: 'CenturyGothic',
        lineWidth: 0,
        fontSize: 9,
        cellPadding: 0,
      },
      startY: doc.autoTable.previous.finalY + 10,
      margin: { left: 7, right: 7 },
      headStyles: {
        font: 'CenturyGothicBold',
        lineWidth: 0,
        fontSize: 10,
      },
      head: [[{ content: title }]],
    });

    doc.autoTable({
      theme: 'plain',
      styles: {
        lineColor: [210, 210, 210],
        lineWidth: 0.1,
        font: 'CenturyGothic',
        fontSize: 8.5,
        cellPadding: 1,
      },
      headStyles: {
        font: 'CenturyGothicBold',
        fontSize: 9.5,
        textColor: [110, 110, 110],
      },
      startY: doc.autoTable.previous.finalY + 3,
      margin: { left: 7, right: 7 },
      head: [
        [
          { content: trb(i18.PrintLabels.DateDisplay) },
          { content: trb(i18.PrintLabels.LoanAmount) },
          { content: trb(i18.PrintLabels.InterestAmount) },
          { content: trb(i18.PrintLabels.Installment) },
          { content: trb(i18.PrintLabels.LoanResidual) },
        ],
      ],
      body: body,
    });

    doc.save(`${title.replace(/\//gi, '_')}.pdf`);
    //blobToFileDownload(`${title}.pdf`, new Blob([ doc.output() ], { type : 'application/pdf'}), 'application/pdf');
  };

  const schedulePrint = () => {
    const scheduleData = ADR?.AccountWithDetails?.Data?.scheduleItems,
      title = `${trb(i18.ModalTitles.CreditLoanShedule)} ${ADR?.AccountWithDetails?.AccountNumber}`,
      ActiveAccount = MALR?.ManagedAccounts != null ? MALR.ManagedAccounts.find((x) => x.IsActive === true) : null,
      IDF = ActiveAccount?.IDF ?? false;

    dispatch(
      PrintActions.setContent(
        <Print
          type="LoanSchedule"
          data={{ IDF, ADR, title, scheduleData }}
        />
      )
    );
  };

  const modalSchedule = () => {
    const scheduleData = ADR?.AccountWithDetails?.Data?.scheduleItems;

    return (
      <Modal
        width={700}
        zIndex={1021}
        centered
        style={{ top: 160 }}
        styles={{
          body: {
            padding: 0,
          },
        }}
        open={modalScheduleVisible}
        title={
          <div
            className="eb_fw_600"
            style={{ whiteSpace: 'normal', paddingRight: 20 }}
          >
            {trb(i18.ModalTitles.CreditLoanShedule)} {ADR?.AccountWithDetails?.AccountNumber}
          </div>
        }
        onCancel={() => setModalScheduleVisible(false)}
        footer={[
          <Button.Group key="footer">
            <Button
              id="InvoiceFilterDownloadPDFButton"
              type="default"
              onClick={scheduleDownload}
            >
              <DownloadOutlined />
              {trb(i18.Buttons.DownloadPDF)}
            </Button>
            <Button
              id="InvoiceFilterPrintButton"
              type="default"
              onClick={schedulePrint}
            >
              <PrinterOutlined />
              {trb(i18.Buttons.Print)}
            </Button>
          </Button.Group>,
        ]}
      >
        {scheduleData && (
          <>
            <div className="eb_table px-0 pb-3 p-md-3">
              <div className="row eb_table_head d-none d-md-flex">
                <div className="col-6 col-md-3">{trb(i18.Labels.DateDisplay)}</div>
                <div className="col-6 col-md-2">{trb(i18.Labels.LoanAmount)}</div>
                <div className="col-6 col-md-2">{trb(i18.Labels.InterestAmount)}</div>
                <div className="col-6 col-md-2">{trb(i18.Labels.Installment)}</div>
                <div className="col-12 col-md-3 text-right">{trb(i18.Labels.LoanResidual)}</div>
              </div>
              {scheduleData.map((item, index) => (
                <div
                  className="row eb_table_row"
                  key={index}
                >
                  <div className="col-6 col-md-3 pt-2 py-1 d-md-none text-black-50">{trb(i18.Labels.DateDisplay)}</div>
                  <div className="col-6 col-md-3 pt-2 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70">{item.dateDisplay}</div>
                  <div className="col-6 col-md-2 py-1 d-md-none text-black-50">{trb(i18.Labels.LoanAmount)}</div>
                  <div className="col-6 col-md-2 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70">
                    <Number value={item.loanAmount} />
                  </div>
                  <div className="col-6 col-md-2 py-1 d-md-none text-black-50">{trb(i18.Labels.InterestAmount)}</div>
                  <div className="col-6 col-md-2 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70">
                    <Number value={item.interestAmount} />
                  </div>
                  <div className="col-6 col-md-2 py-1 d-md-none text-black-50">{trb(i18.Labels.Installment)}</div>
                  <div className="col-6 col-md-2 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70">
                    <Number value={item.installment} />
                  </div>
                  <div className="col-6 col-md-3 pb-2 py-1 d-md-none text-black-50">{trb(i18.Labels.LoanResidual)}</div>
                  <div className="col-6 col-md-3 pb-2 py-1 py-md-2 text-right eb_fw_600 text-black-70">
                    <Number value={item.loanResidual} />
                  </div>
                </div>
              ))}
            </div>
            <div>* {trb(i18.Labels.LoanScheduleNote)}</div>
          </>
        )}
      </Modal>
    );
  };

  const accounts = ADR?.Accounts ?? [];

  const sortAccountsArray = [1, 11, 24, 23, 132, 131, 4, 3, 5, 6, 22];
  let tempTypeCode = null;
  const InitialAccounts =
    accounts &&
    accounts
      .sort((a, b) => sortAccountsArray.indexOf(parseInt(a.AccountTypeCode)) - sortAccountsArray.indexOf(parseInt(b.AccountTypeCode)))
      .map((item) => {
        if (tempTypeCode !== item.AccountTypeCode) {
          const label = {
            label: trb(translationGroups.AccountTypeCode + item.AccountTypeCode),
            value: item.AccountTypeCode,
            disabled: true,
            color: item.AccountTypeCode,
            AmountTxt: item.AmountTxt,
          };

          const data = {
            label: `${item.AccountTypeCode === '4' ? item.AgreementNumber : item.AccountNumber} (${item.AmountTxt})`,
            value: item.AccountID,
            color: item.AccountTypeCode,
            AmountTxt: item.AmountTxt,
          };

          tempTypeCode = item.AccountTypeCode;

          return [label, data];
        } else {
          return {
            label: `${item.AccountTypeCode === '4' ? item.AgreementNumber : item.AccountNumber} (${item.AmountTxt})`,
            value: item.AccountID,
            color: item.AccountTypeCode,
            AmountTxt: item.AmountTxt,
          };
        }
      })
      .reduce(function (flat, current) {
        return flat.concat(current);
      }, []);

  const needShow = InitialAccounts.length > 0 || false,
    needShowSchedule = ADR?.AccountWithDetails?.Data?.scheduleItems?.length > 0;

  return (
    <>
      <InvoiceFilter
        submit={submit}
        download={download}
        print={print}
        downloadExcel={downloadExcel}
        showExcel={showExcel && needShow}
        showPDF={needShow}
        showPrint={needShow}
        showSchedule={needShowSchedule}
        showModalSchedule={showModalSchedule}
        downloadXML={downloadXML}
        accounts={InitialAccounts}
        account={ADR?.AccountWithDetails}
        initialData={filter}
        decorator={decorator}
        loadingAction={loadingAction}
      />
      {modalSchedule()}
    </>
  );
};
