import PropTypes from 'prop-types';
import { CURRENCIES, currencyFormat, i18, minAmount, required } from 'src/Utilities';
import { FormFieldType } from 'src/Components';

export const OwnAccountsForm = ({ accountOverdraftInfo, InitialAccounts, checkAcountId, initialAccount, DestinationAccount, checkDestinationAcountId }) => {
  const checkOverdraftValue = (value, values) => {
    values.Payment.OverdraftValue = `${accountOverdraftInfo?.totalAvailable ? currencyFormat(accountOverdraftInfo.totalAvailable) : 0} ${CURRENCIES.EUR}`;
  };

  return {
    parts: [
      {
        title: i18.Labels.OwnAccountsPayer,
        fields: [
          {
            name: 'Payment.PaymentType',
            component: FormFieldType.input,
            value: 4,
            className: 'd-none',
          },
          {
            title: i18.Labels.FromAccount,
            name: 'Payment.InitialAccountID',
            component: FormFieldType.select,
            options: InitialAccounts,
            optionsColor: 'color',
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            validateCompose: true,
            validate: [required, checkAcountId],
            className: 'mobile',
          },
          {
            title: i18.Labels.OverdraftAvailableAmount,
            name: 'Payment.OverdraftValue',
            validate: checkOverdraftValue,
            component: FormFieldType.input,
            disabled: true,
            className: initialAccount && accountOverdraftInfo?.totalAvailable ? '' : 'd-none',
          },
        ],
      },
      {
        title: i18.Labels.OwnAccountsPayee,
        fields: [
          {
            title: i18.Labels.ToAccount,
            name: 'Payment.DestinationAccountID',
            component: FormFieldType.select,
            options: DestinationAccount.filter((itm) => itm.value !== initialAccount),
            optionsColor: 'color',
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            validateCompose: true,
            validate: [required, checkDestinationAcountId],
            className: 'mobile',
          },
          {
            title: i18.Labels.Amount,
            name: 'Payment.AmountStr',
            component: FormFieldType.number,
            hightlight: true,
            validateCompose: true,
            validate: [required, minAmount(0.01, 'MinNumberValue{min}')],
          },
        ],
      },
    ],
  };
};

OwnAccountsForm.propTypes = {
  data: PropTypes.object.isRequired,
};
