import { MessagingConstants } from './constants'

const initialState = {
  sendMessagesCountInitial: true
}

export const MessagingReducers = function(state = initialState, action) {
  switch (action.type) {
    case MessagingConstants.POST_MESSAGING_CREATE_REQUEST:
      return {
        ...state,
        sendCreate: true,
        Create: null
      }
    case MessagingConstants.POST_MESSAGING_CREATE_SUCCESS:
      return {
        ...state,
        sendCreate: false,
        Create: action.payload
      }
    case MessagingConstants.POST_MESSAGING_CREATE_ERROR:
      return {
        ...state,
        sendCreate: false,
        errors: action.error
      }
    case MessagingConstants.RESET_MESSAGING_CREATE:
      return {
        ...state,

        Create: null
      }

    case MessagingConstants.POST_MESSAGING_RESPONSE_REQUEST:
      return {
        ...state,
        sendResponse: true,
        Response: null
      }
    case MessagingConstants.POST_MESSAGING_RESPONSE_SUCCESS:
      return {
        ...state,
        sendResponse: false,
        Response: action.payload
      }
    case MessagingConstants.POST_MESSAGING_RESPONSE_ERROR:
      return {
        ...state,
        sendResponse: false,
        errors: action.error
      }
    case MessagingConstants.GET_MESSAGING_CHATS_REQUEST:
      return {
        ...state,
        sendChats: true,
        Chats: null
      }
    case MessagingConstants.GET_MESSAGING_CHATS_SUCCESS:
      return {
        ...state,
        sendChats: false,
        Chats: action.payload
      }
    case MessagingConstants.GET_MESSAGING_CHATS_ERROR:
      return {
        ...state,
        sendChats: false,
        errors: action.error
      }
    case MessagingConstants.GET_MESSAGING_CHAT_MESSAGES_REQUEST:
      return {
        ...state,
        sendMessages: true
        //Messages: null,
      }
    case MessagingConstants.GET_MESSAGING_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        sendMessages: false,
        Messages: action.payload
      }
    case MessagingConstants.GET_MESSAGING_CHAT_MESSAGES_ERROR:
      return {
        ...state,
        sendMessages: false,
        errors: action.error
      }

    case MessagingConstants.GET_MESSAGING_NEW_MESSAGES_COUNT_REQUEST:
      return {
        ...state,
        sendMessagesCount: true,
        MessagesCount: state.MessagesCount
      }
    case MessagingConstants.GET_MESSAGING_NEW_MESSAGES_COUNT_SUCCESS:
      return {
        ...state,
        sendMessagesCount: false,
        sendMessagesCountInitial: false,
        MessagesCount: action.payload
      }
    case MessagingConstants.GET_MESSAGING_NEW_MESSAGES_COUNT_ERROR:
      return {
        ...state,
        sendMessagesCount: false,
        errors: action.error
      }
    case MessagingConstants.DELETE_MESSAGING_MESSAGE_REQUEST:
      return {
        ...state,
        sendDeleteMessage: true,
        DeleteMessage: null
      }
    case MessagingConstants.DELETE_MESSAGING_MESSAGE_SUCCESS:
      return {
        ...state,
        sendDeleteMessage: false,
        DeleteMessage: action.payload
      }
    case MessagingConstants.DELETE_MESSAGING_MESSAGE_ERROR:
      return {
        ...state,
        sendDeleteMessage: false,
        errors: action.error
      }
    case MessagingConstants.GET_MESSAGING_BROADCAST_LIST_REQUEST:
      return {
        ...state,
        sendBroadCastList: true,
        BroadCastList: null
      }
    case MessagingConstants.GET_MESSAGING_BROADCAST_LIST_SUCCESS:
      return {
        ...state,
        sendBroadCastList: false,
        BroadCastList: action.payload
      }
    case MessagingConstants.GET_MESSAGING_BROADCAST_LIST_ERROR:
      return {
        ...state,
        sendBroadCastList: false,
        errors: action.error
      }
    case MessagingConstants.GET_MESSAGING_BROADCAST_MESSAGE_REQUEST:
      return {
        ...state,
        sendBroadCastMessage: true,
        BroadCastMessage: null
      }
    case MessagingConstants.GET_MESSAGING_BROADCAST_MESSAGE_SUCCESS:
      return {
        ...state,
        sendBroadCastMessage: false,
        BroadCastMessage: action.payload
      }
    case MessagingConstants.GET_MESSAGING_BROADCAST_MESSAGE_ERROR:
      return {
        ...state,
        sendBroadCastMessage: false,
        errors: action.error
      }
    case MessagingConstants.DELETE_MESSAGING_BROADCAST_MESSAGE_REQUEST:
      return {
        ...state,
        sendDeleteBroadCastMessage: true,
        DeleteBroadCastMessage: null
      }
    case MessagingConstants.DELETE_MESSAGING_BROADCAST_MESSAGE_SUCCESS:
      return {
        ...state,
        sendDeleteBroadCastMessage: false,
        DeleteBroadCastList: action.payload
      }
    case MessagingConstants.DELETE_MESSAGING_BROADCAST_MESSAGE_ERROR:
      return {
        ...state,
        sendDeleteBroadCastMessage: false,
        errors: action.error
      }
    default:
      return state
  }
}
