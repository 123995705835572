import React from 'react';
import {Checkbox} from 'antd';

import {trb} from '../../../Utilities';
import { formFieldId } from 'src/utils';

export const CheckboxOnlyFormField = (props) => {
    const label = props.label,
        disabled = props.disabled,
        translate = props.translate,
        className = props.className,
        checkedNumber = props.checkedNumber;

    return <Checkbox
        id={formFieldId(props.input.name, 'CheckboxOnly')}
        disabled={disabled}
        checked={checkedNumber ? props.input.value === 1 : !!props.input.value}
        className={className}
        onChange={(e)=>{props?.input?.onChange?.(e.target.checked); props?.onChange?.(e.target.checked);}}
    >
        {translate ? trb(translate) : label}
    </Checkbox>;
};
