import { FormField, Select } from 'src/shared';
import { i18, sortClasificatorMap, trb } from 'src/Utilities';
import { useSelector } from 'react-redux';

export const FieldRecipientBankCountry = () => {
  const { language } = useSelector((state: any) => state.languageReducer);
  const { countriesList } = useSelector((state: any) => state.AMCReducers);
  const { CISO } = useSelector(
    (state: any) => state?.translationReducer?.translations?.find((el: any) => el.culture === language && el.context === 'BBS.Classificators')?.Groups ?? []
  );

  return (
    <FormField
      name="Payment.RecipientBankCountry"
      label={trb(i18.Labels.RecipientBankCountry)}
    >
      <Select
        options={sortClasificatorMap(
          countriesList.map(({ ISO }: { ISO: string }) => ({ label: CISO?.[ISO] ?? ISO, value: ISO })),
          language
        )}
      />
    </FormField>
  );
};
