export const PAYER_INFO_RESET = {
  PayerType: '',
  PayerAddress: '',
  PayerCountryCode: '',
  PayerAddressChk: 'false',
  PayerName: '',
  PayerIdentTypeCode: '',
  PayerIdentTypeValue: '',
  PayerAgreementIdentTypeCode: ''
}

export const INIT_PAYER_INFO_RESET = {
  InitPayerType: '',
  InitPayerName: '',
  InitPayerIdentTypeValue: '',
  InitPayerAddressChk: 'false',
  InitPayerAddress: '',
  InitPayerCountryCode: '',
  InitPayerIdentTypeCode: ''
}

export const PAYEE_INFO_RESET = {
  PayeeType: '',
  PayeeIdentTypeCode: '',
  PayeeAddressChk: 'false',
  PayeeCountryCode: '',
  PayeeAddress: '',
  PayeeIdentTypeValue: '',
  PayeeCode: '',
  PayeeName: ''
}
export const FINAL_PAYEE_INFO_RESET = {
  FinalPayeeType: '',
  FinalPayeeName: '',
  FinalPayeeIdentTypeValue: '',
  FinalPayeeAddress: '',
  FinalPayeeAddressChk: 'false',
  FinalPayeeCountryCode: '',
  FinalPayeeIdentTypeCode: ''
}
