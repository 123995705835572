import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AMCActions, BeneficiariesActions, MenuActions, MessagingActions, PMCActions, UACActions } from 'src/Redux';
import { UserSelect } from '../index';
import { checkJsonProp, useGetWindowSize } from 'src/Utilities';

export const UserSelectContainer = () => {
  const windowSize = useGetWindowSize();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { MALR } = useSelector((state) => state.UACReducers);

  const [managingAccount, setManagingAccount] = useState(null);

  useEffect(() => {
    if (!MALR) {
      dispatch(UACActions.getMALR());
    }

    return () => {
      // TODO IMPORTANT WHY THIS IS DELETED
      // dispatch(UACActions.deleteMALR());
    };
  }, []);

  const selectManagingAccount = (id, name) => {
    setManagingAccount(name);

    dispatch(BeneficiariesActions.setBeneficiariesList(null));
    dispatch(UACActions.getMACR({ ManagedAccountID: id })).then(async () => {
      await dispatch(AMCActions.clearAMCReducers());
      await dispatch(AMCActions.getCompany());
      await dispatch(PMCActions.postPTR());
      await dispatch(UACActions.getMALR());
      await dispatch(MessagingActions.getNewMessagesCount());
    });

    if (windowSize.mobileCheck) {
      dispatch(MenuActions.toggle());
    }

    navigate('/', {});
  };

  if (!MALR) {
    return null;
  }

  const activeUser = MALR.ManagedAccounts.find((x) => x.IsActive === true);

  let userName;

  if (activeUser) {
    userName = checkJsonProp(activeUser, 'Name');
  } else if (MALR.ManagedAccounts.length > 0) {
    userName = checkJsonProp(MALR.ManagedAccounts, '0.Name');
  }

  return (
    <UserSelect
      data={MALR}
      managingAccount={managingAccount ?? userName}
      selectManagingAccount={selectManagingAccount}
    />
  );
};
