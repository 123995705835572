export const blobToFileDownload = (name, byte, type) => {
    const blob = new Blob([byte], {type: type});

    const blobURL = window.URL.createObjectURL(blob),
        tempLink = document.createElement('a');

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, name);
    }
    else {
        tempLink.style.display = 'none';
        tempLink.href = blobURL;
        tempLink.setAttribute('download', name);

        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();
        document.body.removeChild(tempLink);
        window.URL.revokeObjectURL(blobURL);
    }
};
