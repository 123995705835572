import React from 'react';
import {MessageResult} from "../../../../../Components/MessageResult";
import {i18} from "../../../../../Utilities";
import {CloseCircleOutlined} from "@ant-design/icons";

export const GroupPaymentSuccess = (props) => {
    return <MessageResult
        status="success"
        title={i18.MessageTitles.PaymentSuccess}
        subTitle={i18.Messages.PaymentSuccess}
        buttons={[
            {
                title: i18.Buttons.Close,
                func: props.close,
                props: {
                    type: "primary",
                    icon: <CloseCircleOutlined />
                }
            }
        ]}
    />
};