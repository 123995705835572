import React from 'react';
import PropTypes from 'prop-types';

import { EBTableExpandRow } from './Components';

export const EBTableExpand = ({ data, headers, mapData, expended, itemClick, ...otherProps }) => {
  const handleClick = (item) => {
    if (itemClick) {
      itemClick(item);
    }

    if (otherProps.click && typeof otherProps.click === 'function') {
      otherProps.click();
    }
  };

  return (
    <>
      {data?.length > 0 ? (
        <div
          className="eb_table mb-3 py-md-3 px-0"
          style={{
            maxWidth: 1200,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div className={'row eb_table_head d-none d-lg-flex ' + (expended ? 'eb_table_head_expand' : '')}>
            {headers.map((item, index) => (
              <div
                className={item.className}
                key={index}
              >
                {item.title}
              </div>
            ))}
          </div>
          {data.map((item, index) => (
            <EBTableExpandRow
              click={() => handleClick(item)}
              buttons={otherProps.buttons}
              key={index}
              item={item}
              headers={headers}
              mapData={mapData}
              expended={expended}
              component={otherProps.component}
            >
              {otherProps.children}
            </EBTableExpandRow>
          ))}
        </div>
      ) : null}
    </>
  );
};

EBTableExpand.propTypes = {
  data: PropTypes.array.isRequired,
  mapData: PropTypes.arrayOf(
    PropTypes.shape({
      headerClassName: PropTypes.string,
    })
  ),
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
  expended: PropTypes.bool,
};
