import { checkJsonProp } from './';
import { TranslationContexts, store } from 'src/Redux';
import { LANGUAGES_LOCALES } from 'src/Utilities';

function getClassificatorsFn() {
  const state = store.getState();
  const { translations } = state.translationReducer;
  const { language } = state.languageReducer;

  return checkJsonProp(
    translations.find((e) => e.context === TranslationContexts.classificators && e.culture === language),
    'Groups'
  );
}

export const getClassificators = () => {
  return getClassificatorsFn();
};

export const classifikatorsMap = (obj) =>
  obj
    ? Object.entries(obj).map((item) => {
        return { label: item[1], value: item[0] };
      })
    : [];
export const sortClasificatorMap = (array, language) => {
  return array?.sort((a, b) => {
    return a.label?.toLowerCase().localeCompare(b.label?.toLowerCase(), LANGUAGES_LOCALES[language], { ignorePunctuation: true });
  });
};
