import React from 'react';

import { LanguageSelect } from 'src/Components';

export const LoginHeader = () => {
  return (
    <div
      className="bbs_login_header"
      id="header"
    >
      <div className="bbs_login_header_wrapper row">
        <div className="bbs_login_logo col text-left pl-0">
          <img
            src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGIN_LOGO}`}
            alt={import.meta.env.VITE_TITLE}
          />
        </div>
        <div className="col-12 text-right pr-0">
          <LanguageSelect className="ml-5 login-language-select" />
        </div>
      </div>
    </div>
  );
};
