import React from 'react';

import {
    LeftOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    RightOutlined,
    SaveOutlined,
} from '@ant-design/icons';

import {Button, Alert} from "antd";
import {Form, Field} from "react-final-form";
import {FieldArray} from 'react-final-form-arrays'
import arrayMutators from 'final-form-arrays'

import {FormFieldType, FormField} from "../../../../../Components/FormField";
import {i18, trb} from "../../../../../Utilities";

const ButtonGroup = Button.Group;
const Condition = ({ when, is, children, alwaysShow = null}) => (
    <>{when !== undefined ? (
        <Field name={when} subscription={{ value: true }}>
            {({ input: { value } }) => (
              is.length !== undefined
              ? is.indexOf(value) !== -1
                  ? children
                  : alwaysShow
              : is == value
                  ? children
                  : alwaysShow
            )}
        </Field>
    ) : (
        <>{children}</>
    )}
    </>
)

var Permissons;

const FormFieldFill = (item, itemIndex, values, arrIndex = null) => {
    let addonBeforeField;
    if (item.addonBefore && Object.keys(item.addonBefore)) {
        addonBeforeField = <FormField
            className={item.addonBefore.className}
            title={item.addonBefore.title}
            label={item.addonBefore.label}
            name={arrIndex !== null ? item.addonBefore.name.replace('$index', arrIndex) : item.addonBefore.name}
            component={item.addonBefore.component}
            disabled={Permissons ? item.addonBefore.disabled : true}
            maxLength={item.addonBefore.maxLength}
            validate={Permissons ? item.addonBefore.validate : null}
        />
    }

    return <FormField
        key={itemIndex}
        className={arrIndex == 0 && item.disabled && Permissons ? 'duplicate-disabled ' + item.className : item.className}
        classNameTitle={item.classNameTitle}
        classNameField={item.classNameField}
        classNameError={item.classNameError}
        checkedNumber={true && item.checkedNumber != false}
        title={item.title}
        label={item.label}
        translate={item.translate}
        name={arrIndex !== null ? item.name.replace('$index', arrIndex) : item.name}
        component={item.component}
        componentOptions={item.componentOptions}
        formatNumber={item.formatNumber}
        addonBefore={item.addonBefore && addonBeforeField ? addonBeforeField : item.addonBeforeText}
        inline={true && !item.noInline}
        options={item.optionsFunction ? item.optionsFunction(item.optionsFunctionParams, values, item.options) : item.options ? item.options : []}
        optionsLabel={item.optionsLabel}
        optionsValue={item.optionsValue}
        optionsDisabled={item.optionsDisabled}
        validate={Permissons ? item.validate : null}
        disabled={Permissons ? arrIndex > 0 && item.duplicateEnabled ? false : item.disabled : true}
        maxLength={item.maxLength}
        maxDate={item.maxDate}
        minDate={item.minDate}
    />
}

export const KYCFormSwitch = (props) => {
    let approveSubmit = false;
    Permissons = props.permissons;

    return (
        <div className="form">
            <Form
                onSubmit={props.submit ? props.submit : props.nextStep}
                initialValues={props.initialData}
                decorators={Permissons ? props.decorators : null}
                mutators={{
                    ...arrayMutators
                }}
                render={({ handleSubmit, form: {
                mutators: { _push, _pop }
                }, values }) => {
                    return (
                        <form id="KYCForm" className="mb-3" onSubmit={handleSubmit}>
                            {props.title && <div className="col-12 form-part-title m-4">{trb(props.title)}</div>}
                            {props.mapData && props.mapData.map((row, rowIndex) =>
                                <div className="row" key={rowIndex}>
                                    <Condition when={row.showWhen} is={row.showIs}>
                                        {row.messageBefore && <div className="col-12 mb-3">
                                            <Alert message={trb(row.messageBefore)} type='info' showIcon />
                                        </div>}
                                        {row.title && <div className={"col-12 form-part-title m-4"}>{trb(row.title)}</div>}
                                        {row.noteFirst && <div className={"form-part-description col-12 mb-3"}>
                                            * {trb(row.noteFirst)}<br/>
                                            {row.noteSecond && "** " + trb(row.noteSecond)}
                                        </div>}
                                    </Condition>
                                    {row.canDuplicate ? (
                                        <FieldArray name={row.duplicateName}>
                                            {({ fields }) => fields.map((arrName, arrIndex) => (
                                                <React.Fragment key={arrIndex}>
                                                    <Condition when={row.showWhen ? row.showWhen.replace('$index', arrIndex) : row.showWhen} is={row.showIs}
                                                        alwaysShow={row.elements && row.elements.filter((item)=>item.alwaysShow).map((item, itemIndex) => FormFieldFill(item, itemIndex, values, arrIndex))}>
                                                        {row.elements && row.elements.map((item, itemIndex) => FormFieldFill(item, itemIndex, values, arrIndex))}
                                                        {row.duplicateInside && Permissons && <div className="mb-3 col-12">
                                                            <ButtonGroup>
                                                                {(row.maxRows === undefined || arrIndex < row.maxRows) && <Button
                                                                    id="KYCFormConditionDuplicateButton"
                                                                    type="link"
                                                                    size="small"
                                                                    disabled={row.maxRows && fields.length > row.maxRows}
                                                                    onClick={row.duplicateDefault ? () => fields.insert(arrIndex + 1, JSON.parse(JSON.stringify(row.duplicateDefault).replace('$index', arrIndex + 2))) : () => fields.insert(arrIndex + 1, {})}
                                                                ><PlusCircleOutlined />{trb(i18.Buttons.DuplicateBlocks)}</Button>}
                                                                {arrIndex > 0 && <Button
                                                                    id="KYCFormConditionRemoveButton"
                                                                    type="link"
                                                                    size="small"
                                                                    className="eb_kyc_button_remove"
                                                                    onClick={() => fields.remove(arrIndex)}
                                                                ><MinusCircleOutlined />{trb(i18.Buttons.RemoveBlocks)}</Button>}
                                                            </ButtonGroup>
                                                        </div>}
                                                    </Condition>
                                                    {!row.duplicateInside && Permissons && <div className="mb-3 col-12">
                                                        <ButtonGroup>
                                                            {(row.maxRows === undefined || arrIndex < row.maxRows) && <Button
                                                                id="KYCFormDuplicateButton"
                                                                type="link"
                                                                size="small"
                                                                disabled={row.maxRows && fields.length > row.maxRows}
                                                                onClick={row.duplicateDefault ? () => fields.insert(arrIndex + 1, JSON.parse(JSON.stringify(row.duplicateDefault).replace('$index', arrIndex + 2))) : () => fields.insert(arrIndex + 1, {})}
                                                            ><PlusCircleOutlined />{trb(i18.Buttons.DuplicateBlocks)}</Button>}
                                                            {arrIndex > 0 && <Button
                                                                id="KYCFormRemoveButton"
                                                                type="link"
                                                                size="small"
                                                                className="eb_kyc_button_remove"
                                                                onClick={() => fields.remove(arrIndex)}
                                                            ><MinusCircleOutlined />{trb(i18.Buttons.RemoveBlocks)}</Button>}
                                                        </ButtonGroup>
                                                    </div>}
                                                </React.Fragment>
                                            ))}
                                        </FieldArray>
                                      ):(
                                      <Condition when={row.showWhen} is={row.showIs}
                                          alwaysShow={row.elements.filter((item)=>item.alwaysShow).map((item, itemIndex) => FormFieldFill(item, itemIndex, values))}>
                                          {row.elements && row.elements.map((item, itemIndex) => FormFieldFill(item, itemIndex, values))}
                                      </Condition>
                                    )}
                                </div>
                            )}
                            {props.submit && <div className="row my-4 px-3">
                                <div className="col-12 ant-alert ant-alert-warning ant-alert-with-description">
                                    <FormField
                                        name="Approve"
                                        component={FormFieldType.checkboxOnly}
                                        label={trb(props.approveSubmitText)}
                                        inline={true}
                                        onChange={()=>{approveSubmit = !approveSubmit}}
                                    />
                                </div>
                            </div>}
                            <div className="row">
                                <div className="my-3 col-12 text-right">
                                    <ButtonGroup>
                                        {props.prevStep && <Button
                                            id="KYCFormPrevStepButton"
                                            onClick={props.prevStep}
                                        ><LeftOutlined />{trb(i18.Buttons.PrevStep)}</Button>}
                                        {props.nextStep && <Button
                                            id="KYCFormNextStepButton"
                                            htmlType="submit"
                                            type="primary"
                                        ><RightOutlined />{trb(i18.Buttons.NextStep)}</Button>}
                                        {props.submit && <Button
                                            id="KYCFormSubmitButton"
                                            htmlType="submit"
                                            type="primary"
                                            disabled={!approveSubmit || !Permissons}
                                        ><SaveOutlined />{trb(i18.Buttons.Submit)}</Button>}
                                    </ButtonGroup>
                                </div>
                            </div>
                        </form>
                    );
                }}
            />
        </div>
    );
};