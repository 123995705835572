import React from 'react';
import { currencyTextValue, i18, trb } from 'src/Utilities';
import { Button, Space } from 'antd';
import PropTypes from 'prop-types';

export const PaymentUploadTable = ({ data, buttons }) => {
  return (
    <>
      <h3 className="eb_content_subtitle">{trb(i18.Labels.Summary)}</h3>
      <div className="eb_table mb-3 w-100">
        <div className="row eb_table_row ">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.UploadDateTime)}</div>
            <div className="eb_fw_600">{data?.UploadDateTime}</div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.FileName)}</div>
            <div
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              className="eb_fw_600"
            >
              {data?.UploadFileName}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.FileId)}</div>
            <div
              style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              className="eb_fw_600"
            >
              {data?.FileHeaderId}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.PaymentCount)}</div>
            <div className="eb_fw_600">{data?.TotalPayments}</div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.PaymentSum)}</div>
            <div className="eb_fw_600">{currencyTextValue(data?.TotalAmount, data?.Currency)}</div>
          </div>
        </div>
      </div>
      <h3 className="eb_content_subtitle">{trb(i18.Labels.Payments)}</h3>
      <div className="eb_table mb-3 w-100 eb_table_wrapper_bottom_lined">
        <div className="row eb_table_head">
          <div className="col-md-3">{trb(i18.Labels.Payer)}</div>
          <div className="col-md-3 ">{trb(i18.Labels.PayeePaymentUploading)}</div>
          <div className="col-md-4 ">{trb(i18.Labels.DestinationsCode)}</div>
          <div className="col-md-2 text-right">{trb(i18.Labels.Amount)}</div>
        </div>
        {data?.PaymentsList?.map((item) => (
          <div
            className="row eb_table_row "
            key={item.PaymentID}
          >
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="">{item.DebtorName}</div>
              <div className="">{item.DebtorIBAN?.replace(/.{4}(?=.)/g, '$& ')}</div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-3">
              <div className="">{item.CreditorName}</div>
              <div className="">{item.CreditorIBAN?.replace(/.{4}(?=.)/g, '$& ')}</div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-4">
              <div className="">{item.PaymentPurpose}</div>
              <div className="">{item.UniqPayCode}</div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-2 text-lg-right">
              <div className="">{currencyTextValue(item?.Amount, item?.Currency)}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-end">
        <div className="d-none d-sm-block align-self-center">
          <Space>
            {buttons?.map((item, index) => (
              <Button
                id={`PaymentUploadTable${item.title}ButtonMD`}
                size="large"
                key={index}
                onClick={item.func}
                {...item.props}
              >
                {trb(item.title)}
              </Button>
            ))}
          </Space>
        </div>
      </div>
      <div className="text-right d-block d-sm-none">
        {buttons?.map((item, index) => (
          <Button
            id={`PaymentUploadTable${item.title}ButtonSM`}
            className="mb-2"
            key={index}
            onClick={item.func}
            block
            {...item.props}
          >
            {trb(item.title)}
          </Button>
        ))}
      </div>
    </>
  );
};

PaymentUploadTable.propTypes = {
  data: PropTypes.shape({
    PaymentsList: PropTypes.arrayOf(
      PropTypes.shape({
        DebtorName: PropTypes.string,
        DebtorIBAN: PropTypes.string,
        CreditorName: PropTypes.string,
        CreditorIBAN: PropTypes.string,
        PaymentPurpose: PropTypes.string,
        UniqPayCode: PropTypes.string,
        AmountFormatted: PropTypes.string,
      })
    ),
    UploadDateTime: PropTypes.string,
    UploadFileName: PropTypes.string,
    TotalPayments: PropTypes.number,
    TotalAmountFormatted: PropTypes.string,
  }),
  buttons: PropTypes.array,
};
