import * as Sentry from '@sentry/browser'

export const logError = error => {
  console.log(error)
  Sentry.captureException(error)
}

export const throwError = (response, functionName = 'unknown') => {
  throw new Error(
    `${functionName} - ${
      response ? JSON.stringify(response) : 'response is undefined'
    }`
  )
}
