import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';
import { i18, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { Button } from 'src/shared';

export const BeneficiariesNewHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="mb-3">
      <Button
        className="pl-0 back-button-link"
        type="link"
        icon={<LeftOutlined />}
        onClick={() => navigate(`${routerPath.payments}/${routerPath.beneficiaries}`)}
      >
        {trb(i18.Labels.Back)}
      </Button>
    </div>
  );
};
