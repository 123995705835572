import { Alert, Typography } from 'src/shared';
import { IconAlert, IconInfo, IconSuccess, IconWarning } from 'src/Assets/svg';

interface IProps {
  message?: string;
  type: 'info' | 'error' | 'success' | 'warning';
  alertClassName?: string;
  description?: React.ReactNode;
}

export const AlertNotification = ({ description, type, alertClassName, message }: IProps) => {
  const { Paragraph } = Typography;

  const style = {
    info: 'info_message',
    error: 'error_message',
    success: 'success_message',
    warning: 'warning_message',
  };
  const icons = {
    info: <IconInfo size={24} />,
    error: <IconAlert size={24} />,
    success: <IconSuccess size={24} />,
    warning: <IconWarning size={24} />,
  };
  return (
    <Alert
      message={message ? <Paragraph className={`mb-0 ${style[type]} ${!message ? 'mt-0' : ''}`}>{message}</Paragraph> : null}
      type={type}
      showIcon
      icon={<div className="align-self-start mr-0 pr-3">{icons[type]}</div>}
      className={alertClassName}
      description={description}
    />
  );
};
