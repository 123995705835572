export const IconLetter = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.55469 7.69805C4.55469 8.1944 4.80286 8.60803 5.21649 8.93893L10.18 12.3307C11.3382 13.0752 12.7445 13.0752 13.9027 12.3307L18.8662 9.02166C19.1971 8.60803 19.4453 8.1944 19.4453 7.69805C19.4453 6.87079 18.7835 6.20898 17.9563 6.20898H6.04375C5.21649 6.20898 4.55469 6.87079 4.55469 7.69805Z"
        stroke="#717171"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.55469 7.86328V16.1359C4.55469 17.0458 5.29922 17.7904 6.2092 17.7904H17.7908C18.7008 17.7904 19.4453 17.0458 19.4453 16.1359V7.86328"
        stroke="#717171"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.03906 17.305L10.0804 12.2637"
        stroke="#717171"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.9531 12.2969L18.9597 17.3034"
        stroke="#717171"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
