import React, {Component} from 'react';
import {connect} from 'react-redux';

import {OMCActions} from "../../../Redux";
import {OnGoingOperations} from "./OnGoingOperations";

class OnGoingOperationsContainer extends Component {

    componentDidMount() {
        const {dispatch} = this.props;

        dispatch(OMCActions.postROL());
    }

    render() {
        const {ROL} = this.props;

        if (!ROL) {
            return null;
        }

        return <OnGoingOperations data={ROL}/>
    }
}

function mapStateToProps(state) {
    const {ROL} = state.OMCReducers;

    return {
        ROL
    };
}


const connectedOnGoingOperationsContainer = connect(mapStateToProps)(OnGoingOperationsContainer);
export {connectedOnGoingOperationsContainer as OnGoingOperationsContainer};