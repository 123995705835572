/* eslint-disable @typescript-eslint/no-explicit-any */
import { BeneficiariesServices } from './services';
import { BeneficiariesConstants } from './constants';
import { IBeneficiariesItem, ICreateNewMandateResponse, INewMandate, ISignDeleteMandatePayload, ISignMandatePayload, ISignMandateResponse } from './interfaces';

export const BeneficiariesActions = {
  getBeneficiariesList,
  setBeneficiariesList,
  postCreateNewMandate,
  postSignMandate,
  setSignMandateInfo,
  setSignDeleteMandateInfo,
  getCheckStatusMandate,
  deleteMandate,
  postSignDeleteMandate,
  getCheckDeleteStatusMandate,
};

function getBeneficiariesList() {
  return (dispatch: any) => {
    dispatch(request());

    return BeneficiariesServices.getBeneficiariesList().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: BeneficiariesConstants.GET_BENEFICIARIES_LIST_REQUEST };
  }

  function success(data: Array<IBeneficiariesItem>) {
    return { type: BeneficiariesConstants.GET_BENEFICIARIES_LIST_SUCCESS, payload: data?.filter((el) => el.IsActual) };
  }

  function failure(error: any) {
    return { type: BeneficiariesConstants.GET_BENEFICIARIES_LIST_ERROR, error };
  }
}

function setBeneficiariesList(payload: Array<IBeneficiariesItem> | null, beneficiaryAdded: boolean | null = null, beneficiaryDeleted: boolean | null = null) {
  return (dispatch: any) => {
    dispatch({ type: BeneficiariesConstants.SET_BENEFICIARIES_LIST, payload: { data: payload, beneficiaryAdded, beneficiaryDeleted } });
  };
}

function postCreateNewMandate(data: INewMandate) {
  return (dispatch: any) => {
    dispatch(request());

    return BeneficiariesServices.postCreateNewMandate(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: BeneficiariesConstants.POST_CREATE_NEW_MANDATE_REQUEST };
  }

  function success(payload: ICreateNewMandateResponse) {
    return { type: BeneficiariesConstants.POST_CREATE_NEW_MANDATE_SUCCESS, payload };
  }

  function failure(error: any) {
    return { type: BeneficiariesConstants.POST_CREATE_NEW_MANDATE_ERROR, error };
  }
}

function postSignMandate(data: ISignMandatePayload) {
  return (dispatch: any) => {
    dispatch(request());

    return BeneficiariesServices.postSignMandate(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: BeneficiariesConstants.POST_SIGN_MANDATE_REQUEST };
  }

  function success(payload: ICreateNewMandateResponse) {
    return { type: BeneficiariesConstants.POST_SIGN_MANDATE_SUCCESS, payload };
  }

  function failure(error: any) {
    return { type: BeneficiariesConstants.POST_SIGN_MANDATE_ERROR, error };
  }
}

function setSignMandateInfo(payload: ISignMandateResponse | null) {
  return (dispatch: any) => {
    dispatch({ type: BeneficiariesConstants.SET_SIGN_MANDATE_INFO, payload: payload });
  };
}

function getCheckStatusMandate(productId: string) {
  return (dispatch: any) => {
    dispatch(request());

    return BeneficiariesServices.getCheckStatusMandate(productId).then(
      () => dispatch(success()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: BeneficiariesConstants.GET_CHECK_STATUS_REQUEST };
  }

  function success() {
    return { type: BeneficiariesConstants.GET_CHECK_STATUS_SUCCESS };
  }

  function failure(error: any) {
    return { type: BeneficiariesConstants.GET_CHECK_STATUS_ERROR, error };
  }
}

function setSignDeleteMandateInfo(payload: ISignMandateResponse | null) {
  return (dispatch: any) => {
    dispatch({ type: BeneficiariesConstants.SET_SIGN_DELETE_MANDATE_INFO, payload: payload });
  };
}

function postSignDeleteMandate(data: ISignDeleteMandatePayload) {
  return (dispatch: any) => {
    dispatch(request());

    return BeneficiariesServices.postSignDeleteMandate(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: BeneficiariesConstants.POST_SIGN_DELETE_MANDATE_REQUEST };
  }

  function success(payload: ICreateNewMandateResponse) {
    return { type: BeneficiariesConstants.POST_SIGN_DELETE_MANDATE_SUCCESS, payload };
  }

  function failure(error: any) {
    return { type: BeneficiariesConstants.POST_SIGN_DELETE_MANDATE_ERROR, error };
  }
}

function deleteMandate(data: string[]) {
  return (dispatch: any) => {
    dispatch(request());

    return BeneficiariesServices.deleteMandate(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: BeneficiariesConstants.DELETE_MANDATE_REQUEST };
  }

  function success(payload: ICreateNewMandateResponse) {
    return { type: BeneficiariesConstants.DELETE_MANDATE_SUCCESS, payload };
  }

  function failure(error: any) {
    return { type: BeneficiariesConstants.DELETE_MANDATE_ERROR, error };
  }
}

function getCheckDeleteStatusMandate(productId: string) {
  return (dispatch: any) => {
    dispatch(request());

    return BeneficiariesServices.getCheckDeleteStatusMandate(productId).then(
      () => dispatch(success()),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: BeneficiariesConstants.GET_CHECK_DELETE_STATUS_REQUEST };
  }

  function success() {
    return { type: BeneficiariesConstants.GET_CHECK_DELETE_STATUS_SUCCESS };
  }

  function failure(error: any) {
    return { type: BeneficiariesConstants.GET_CHECK_DELETE_STATUS_ERROR, error };
  }
}

