import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { BeneficiariesActions, BeneficiariesConstants } from 'src/Redux';
import { i18, trb } from 'src/Utilities';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/Config';
import { ContentLoader } from 'src/Components';

export const BeneficiariesNewCheckStatus = ({ productId }: { productId: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const timerRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    checkStatus();
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const checkStatus = async () => {
    const resp = await dispatch(BeneficiariesActions.getCheckStatusMandate(productId) as any);
    if (resp.type === BeneficiariesConstants.GET_CHECK_STATUS_SUCCESS) {
      dispatch(BeneficiariesActions.setBeneficiariesList(null, true) as any);
      backToList();
    } else if (!resp.error) {
      timerRef.current = setTimeout(() => {
        checkStatus();
      }, 1000);
    } else {
      // todo error??
    }
  };

  const backToList = () => {
    navigate(`${routerPath.payments}/${routerPath.beneficiaries}`);
  };

  return <ContentLoader title={trb(i18.Labels.CheckBeneficiariesNewSignStatus)} />;
};
