import { useField } from 'formik';
import classNames from 'classnames';
import { formFieldId } from 'src/utils';

interface IProps {
  name: string;
  label?: string;
  focused?: boolean;
  labelClassNames?: string;
}

export const FormFieldLabel = ({ name, label, focused, labelClassNames }: IProps) => {
  const [field, _meta, _helpers] = useField(name);

  const className = classNames(
    'field_label',
    {
      field_label_floating: (field.value !== undefined && field.value !== '' && field.value !== null) || focused,
    },
    labelClassNames
  );

  if (!label) {
    return null;
  }

  return (
    <div
      id={formFieldId(name, 'Label')}
      className={className}
    >
      {label}
    </div>
  );
};
