import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {checkJsonExist, checkJsonProp, getIDFLink, i18, translationGroups, trb} from '../../../Utilities';
import {ContentNoData, ContentLoader, Number} from '../../';
import {PrintHeader} from "../PrintHeader";

export const PrintInvoice = (props) => {
    const { language } = useSelector((state) => state.languageReducer);
    if (props.sending) {
        return <ContentLoader/>;
    }

    if (!props.data) {
        return <ContentNoData id="PrintInvoice"/>;
    }

    if (!props.COMPANY) {
        return null;
    }

    const statement = checkJsonProp(props.data, 'AccountWithDetails.AccountDetails');

    const AccountTypeCode = checkJsonProp(props.data, 'AccountWithDetails.AccountTypeCode');
    const AccountTypeCodeArray1 = ["0", "5", "6"];
    const AccountTypeCodeArray2 = ["131", "132"];
    // const AccountTypeCodeArray3 = ["131", "132", "23", "1", "4"];

    const IDFTypeCodesSee = [131, 132, 22, 23, 24, 3, 4];
    const IDFTypeCode = parseInt(AccountTypeCode);


    return <div className="eb_print_wrapper">
        <PrintHeader
            data={props.data}
            COMPANY={props.COMPANY}
        />
        <table className="eb_print_header w-100 mb-1">
            <tbody>
            <tr>
                <td className="text-center eb_fw_600 eb_fs_16">{trb(i18.Labels.Statement)}</td>
            </tr>
            </tbody>
        </table>
        <table className="eb_print_table">
            <thead>
            <tr>
                <th>{trb(i18.Labels.Date)}</th>
                <th>{trb(i18.Labels.PayerPayeePurpose)}</th>
                <th className="text-right">{trb(i18.Labels.ExpenditureRevenue)}</th>
            </tr>
            </thead>
            <tbody>
            {statement.map((item, index) =>
                <tr key={index}>
                    <td className="text-nowrap">{item.Date}</td>
                    <td className="word-brake">
                        <div><b>{item.Pay_Rec}</b> {item.Description}</div>
                    </td>
                    <td className="text-right text-nowrap">
                        {checkJsonProp(item, 'Income.0.Text') ? '+' + item.Income[0].Text : null}
                        {checkJsonExist(item, 'Income.0.Text') && (checkJsonExist(item, 'Expediture.0.Text') || checkJsonExist(item, 'PaymentFee.0.Text')) && <br/>}
                        {checkJsonProp(item, 'Expediture.0.Text') ? '-' + item.Expediture[0].Text : null}
                        {checkJsonExist(item, 'Expediture.0.Text') && checkJsonExist(item, 'PaymentFee.0.Text') && <br/>}
                        {checkJsonProp(item, 'PaymentFee.0.Text') ? '-' + item.PaymentFee[0].Text : null}
                    </td>
                </tr>
            )}
            </tbody>
        </table>
        <table className="eb_print_header eb_fs_13">
            <tbody>
            {!AccountTypeCodeArray1.includes(AccountTypeCode) &&
            <>
                <tr>
                    <td className="text-right" width="100%">{trb(translationGroups.AccountDetails + 'InitialBalance')}</td>
                    <td className="text-right text-nowrap"><Number value={checkJsonProp(props.data, 'AccountWithDetails.InitialBalance.0.Text')}/> {checkJsonProp(props.data, 'AccountWithDetails.InitialBalance.0.Currency')}</td>
                </tr>
                <tr>
                    <td className="text-right" width="100%">{trb(translationGroups.AccountDetails + 'TotalIncome')}</td>
                    <td className="text-right text-nowrap"><Number value={checkJsonProp(props.data, 'AccountWithDetails.TotalIncome.0.Text')}/> {checkJsonProp(props.data, 'AccountWithDetails.TotalIncome.0.Currency')}</td>
                </tr>
                <tr>
                    <td className="text-right" width="100%">{trb(translationGroups.AccountDetails + 'TotalExpenditures')}</td>
                    <td className="text-right text-nowrap"><Number value={checkJsonProp(props.data, 'AccountWithDetails.TotalExpenditures.0.Text')}/> {checkJsonProp(props.data, 'AccountWithDetails.TotalExpenditures.0.Currency')}</td>
                </tr>
                <tr>
                    <td className="text-right" width="100%">{trb(translationGroups.AccountDetails + 'FinalBalance')}</td>
                    <td className="text-right text-nowrap"><Number value={checkJsonProp(props.data, 'AccountWithDetails.FinalBalance.0.Text')}/> {checkJsonProp(props.data, 'AccountWithDetails.FinalBalance.0.Currency')}</td>
                </tr>
            </>
            }
            {AccountTypeCodeArray2.includes(AccountTypeCode) &&
            <>
                <tr>
                    <td className="text-right" width="100%">{trb(translationGroups.AccountDetails + 'CreditLimit')}</td>
                    <td className="text-right text-nowrap"><Number value={checkJsonProp(props.data, 'AccountWithDetails.CreditLimit.0.Text')}/> {checkJsonProp(props.data, 'AccountWithDetails.CreditLimit.0.Currency')}</td>
                </tr>
            </>
            }
            {!AccountTypeCodeArray1.includes(AccountTypeCode) &&
                <>
                      <tr>
                          <td className="text-right" width="100%">{trb(translationGroups.AccountDetails + 'BlockedAmount')}</td>
                          <td className="text-right text-nowrap"><Number value={checkJsonProp(props.data, 'AccountWithDetails.BlockedAmount.0.Text')}/> {checkJsonProp(props.data, 'AccountWithDetails.BlockedAmount.0.Currency')}</td>
                      </tr>
                  </>
            }
            {!AccountTypeCodeArray1.includes(AccountTypeCode) &&
            <tr>
                <td className="text-right" width="100%">{trb(translationGroups.AccountDetails + 'Reserved')}</td>
                <td className="text-right text-nowrap"><Number value={checkJsonProp(props.data, 'AccountWithDetails.Reserved.0.Text')}/> {checkJsonProp(props.data, 'AccountWithDetails.Reserved.0.Currency')}</td>
            </tr>
            }
            </tbody>
        </table>
        {IDFTypeCodesSee.includes(IDFTypeCode) && checkJsonProp(props, 'data.IDF') === true &&
        <>
            {trb(i18.Labels.Note)} {trb(i18.Labels.BankText)} {checkJsonProp(props, `COMPANY.${getIDFLink(language)}`)}
        </>
        }
    </div>
};

PrintInvoice.propTypes = {
    sending: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired
};
