import { Layout } from 'antd';
import { useSelector } from 'react-redux';
import { PageLoader } from 'src/Components/PageLoader';
import { LoginFooter } from './Footer';
import { LoginHeader } from './Header';
import { CheckVersion } from 'src/Components';
import { LoginPageContent } from './Content';
import { useEffect, useState } from 'react';

const { Header, Footer, Content } = Layout;

export const LoginPage = () => {
  const VersionReducer = useSelector((state: any) => state.VersionReducer);
  const { translations } = useSelector((state: any) => state.translationReducer);
  const [OKVersions, setOKVersions] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  useEffect(() => {
    if (VersionReducer.version === window.VERSION) {
      setTimeout(() => setOKVersions(true));
    }
  }, [VersionReducer?.version]);

  if (!OKVersions) {
    return (
      <>
        <PageLoader />
        <CheckVersion />;
      </>
    );
  }

  if (!translations || translations.length < 2) {
    return <PageLoader />;
  }

  return (
    <div className="w-100 login_wrapper">
      <Layout>
        <Header
          className="px-0"
          style={{ height: 96 }}
        >
          <LoginHeader />
        </Header>
        <Content className="d-flex">
          <LoginPageContent />
        </Content>
        <Footer className="p-0">
          <LoginFooter />
        </Footer>
      </Layout>
    </div>
  );
};
