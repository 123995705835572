import { checkFirmCode, i18, replaceAll, translationGroups, trb, NAME_VALIDATOR, validateRegexPasses } from 'src/Utilities';

export const NewPaymentValidation = (values, props) => {
  const errorFields = {};
  const maxSelectedPurpose = 140;
  const maxNotSelectedPurpose = 35;
  const minSelectedPurpose = 5;

  const getMaximumPaymentAmount = () => {
    if (props.contractDesc2?.accountOverdraftInfo) {
      const { totalAvailable } = props.contractDesc2.accountOverdraftInfo;
      return totalAvailable || 0;
    } else {
      const InitialAccounts = props?.InitialAccounts;
      if (InitialAccounts?.length) {
        const acc = InitialAccounts.find((x) => x.value === values.Payment.InitialAccountID);
        return acc?.amountValue || 0;
      }
    }
  };
  if (values.Payment) {
    if (values.Payment.IsPurposeSelected === 'true') {
      if (values?.Payment?.Purpose?.length > maxSelectedPurpose) {
        errorFields.Purpose = trb(i18.Validations.InputIsTooLong) + ':' + trb(i18.Validations.MaxInputIs) + ' ' + maxSelectedPurpose;
      }
      if (values?.Payment?.Purpose?.length < minSelectedPurpose) {
        errorFields.Purpose = trb(i18.Validations.ToShortMin5);
      }
    } else {
      if (values?.Payment?.Purpose?.length > maxNotSelectedPurpose) {
        errorFields.Purpose = trb(i18.Validations.InputIsTooLong) + ':' + trb(i18.Validations.MaxInputIs) + ' ' + maxNotSelectedPurpose;
      }
    }
    if (isFixedIban(values.Payment.BeneficiaryAccountNumber) && values.Payment.IsPurposeSelected === 'true') {
      errorFields.IsPurposeSelected = trb(i18.Validations.MustBeCodeSelected);
    }

    if (values.Payment.AmountStr > 0 && values.Payment.InitialAccountID) {
      const maxPaymentAmount = getMaximumPaymentAmount();
      if (maxPaymentAmount < values.Payment.AmountStr) {
        errorFields.AmountStr = trb(translationGroups.ErrorCode + 'PMC00004');
      }
    }

    if (values.Payment.CustomersCode && values.Payment.CustomersCode.length > maxNotSelectedPurpose) {
      errorFields.CustomersCode = trb(i18.Validations.InputIsTooLong) + ':' + trb(i18.Validations.MaxInputIs) + ' ' + maxNotSelectedPurpose;
      errorFields.CustomersCode = trb(i18.Validations.InputIsTooLong) + ':' + trb(i18.Validations.MaxInputIs) + ' ' + maxNotSelectedPurpose;
    } else if (values.Payment.CustomersCode) {
      errorFields.CustomersCode = validateRegexPasses(NAME_VALIDATOR, trb(i18.PaymensValidations.InvalidPaymentCodeCodeError))(values.Payment.CustomersCode);
    }
    if (values.Payment.IsAdditionalDataSelected) {
      // if (values.Payment.PayerType === 'p' &&
      //   values.Payment.PayerIdentTypeCode === 'NIDN' &&
      //   values.Payment.PayerIdentTypeValue &&
      //   !checkPersonCode(values.Payment.PayerIdentTypeValue)) {
      //   errorFields.PayerIdentTypeValue = trb(i18.Validations.BadPersonalCode)
      // }
      //
      // if (values.Payment.InitPayerType === 'p' &&
      //   values.Payment.InitPayerIdentTypeCode === 'NIDN' &&
      //   values.Payment.InitPayerIdentTypeValue &&
      //   !checkPersonCode(values.Payment.InitPayerIdentTypeValue)) {
      //   errorFields.InitPayerIdentTypeValue = trb(i18.Validations.BadPersonalCode)
      // }
      // if (values.Payment.PayeeType === 'p' &&
      //   values.Payment.PayeeIdentTypeCode === 'NIDN' &&
      //   values.Payment.PayeeIdentTypeValue &&
      //   !checkPersonCode(values.Payment.PayeeIdentTypeValue)) {
      //   errorFields.PayeeIdentTypeValue = trb(i18.Validations.BadPersonalCode)
      // }
      // if (values.Payment.FinalPayeeType === 'p' &&
      //   values.Payment.FinalPayeeIdentTypeCode === 'NIDN' &&
      //   values.Payment.FinalPayeeIdentTypeValue &&
      //   !checkPersonCode(values.Payment.FinalPayeeIdentTypeValue)) {
      //   errorFields.FinalPayeeIdentTypeValue = trb(i18.Validations.BadPersonalCode)
      // }

      if (
        values.Payment.PayerType === 'o' &&
        values.Payment.PayerIdentTypeCode === 'COID' &&
        values.Payment.PayerIdentTypeValue &&
        !checkFirmCode(values.Payment.PayerIdentTypeValue)
      ) {
        errorFields.PayerIdentTypeValue = trb(i18.Validations.BadCompanyCode);
      }

      if (
        values.Payment.InitPayerType === 'o' &&
        values.Payment.InitPayerIdentTypeCode === 'COID' &&
        values.Payment.InitPayerIdentTypeValue &&
        !checkFirmCode(values.Payment.InitPayerIdentTypeValue)
      ) {
        errorFields.InitPayerIdentTypeValue = trb(i18.Validations.BadCompanyCode);
      }
      if (
        values.Payment.PayeeType === 'o' &&
        values.Payment.PayeeIdentTypeCode === 'COID' &&
        values.Payment.PayeeIdentTypeValue &&
        !checkFirmCode(values.Payment.PayeeIdentTypeValue)
      ) {
        errorFields.PayeeIdentTypeValue = trb(i18.Validations.BadCompanyCode);
      }
      if (
        values.Payment.FinalPayeeType === 'o' &&
        values.Payment.FinalPayeeIdentTypeCode === 'COID' &&
        values.Payment.FinalPayeeIdentTypeValue &&
        !checkFirmCode(values.Payment.FinalPayeeIdentTypeValue)
      ) {
        errorFields.FinalPayeeIdentTypeValue = trb(i18.Validations.BadCompanyCode);
      }
    }

    const desc2 = props?.contractDesc2?.ADR?.ContractDesc2;
    if (!errorFields.InitialAccountID && desc2) {
      const delayAmount = desc2.find((el) => el.Caption === 'delayTotal')?.ValueDecimal;
      if (delayAmount) {
        errorFields.InitialAccountID = trb(translationGroups.ErrorDescriptions + 'CanNotUsedOverdraft');
      }
    }
  }

  return {
    Payment: {
      ...errorFields,
    },
  };
};

export const isFixedIban = (value) => fixIBANCodeList.includes(replaceAll(value, ' ', ''));

export const fixIBANCodeList = [
  'LT787290000000130151',
  'LT057044060007887175',
  'LT327180000000141038',
  'LT744010051001324763',
  'LT122140030002680220',
  'LT247300010112394300',
  'LT427230000000120025',
  'LT584010042403495020',
  'LT337044060007740589',
  'LT167230000000691047',
  'LT777300010129002656',
  'LT027180300000690001',
  'LT617290000000691020',
  'LT462140030002632951',
  'LT374010042400369573',
  'LT247300010088955705',
  'LT057230000000120012',
];
