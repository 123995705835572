import {requestEBankJSON, aPIPaths, handleResponse, methods} from '../../Config';

export const OMCServices = {
    postROL,
    postODL,
    postODLPaging,
    postUOLR,
    postDUOR,
    postPSUOR,
    postSUORSMS,
    postSUOR,
};

function postROL(data = null) {
    return requestEBankJSON(aPIPaths.post_OMC_ROL, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postODL(data = null) {
    return requestEBankJSON(aPIPaths.post_OMC_ODL, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}
function postODLPaging(data) {
    return requestEBankJSON(aPIPaths.postODLPaging, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postUOLR() {
    return requestEBankJSON(aPIPaths.post_OMC_UOLR, {
        method: methods.post
    }).then(handleResponse);
}

function postDUOR(data = null) {
    return requestEBankJSON(aPIPaths.post_OMC_DUOR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postPSUOR(data = null) {
    return requestEBankJSON(aPIPaths.post_OMC_PSUOR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postSUORSMS(data = null) {
    return requestEBankJSON(aPIPaths.post_OMC_SUORSMS, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postSUOR(data = null) {
    return requestEBankJSON(aPIPaths.post_OMC_SUOR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}
