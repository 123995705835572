import React from 'react';
import { Form } from 'react-final-form';
import { DownOutlined, DownloadOutlined, RightOutlined, ScheduleOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, ConfigProvider, Row } from 'antd';

import { Container, FormField, FormFieldType } from 'src/Components';
import { accountsColorObject, checkJsonProp, CustomIcon, formatCurrentDate, i18, translationGroups, trb } from 'src/Utilities';
import { Summary } from './Summary';

const ButtonGroup = Button.Group;

export const InvoiceFilter = (props) => {
  if (!props?.accounts.length) {
    return null;
  }
  const period = [
    {
      label: i18.Period.LastSevenDaysOperations,
      value: 2,
    },
    {
      label: i18.Period.LastThirtyDaysOperations,
      value: 3,
    },
    {
      label: i18.Period.FromBeginningCurrentMonth,
      value: 4,
    },
    {
      label: i18.Period.LastMonth,
      value: 6,
    },
    {
      label: '',
      value: 7,
      style: 'd-none',
    },
  ];

  // const needShow = props.showPDF || props.showExcel || props.showPrint || false;
  const summary = [
      {
        text: trb(i18.Labels.InvoiceInitialBalance), //'Pradinis likutis',
        name: 'InitialBalance.0.Text',
        classNameTitle: 'text-left',
        classNameField: 'eb_fw_300',
      },
      {
        text: trb(i18.Labels.InvoiceTotalRevenue), //'Viso įplaukų',
        name: 'TotalIncome.0.Text',
        classNameTitle: 'text-left',
        classNameField: 'eb_fw_300',
      },
      {
        text: trb(i18.Labels.InvoiceTotalCost), //'Viso išlaidų',
        name: 'TotalExpenditures.0.Text',
        classNameTitle: 'text-left',
        classNameField: 'eb_fw_300',
      },
      {
        text: trb(i18.Labels.InvoiceFinalBalance), //'Galutinis likutis',
        name: 'FinalBalance.0.Text',
        classNameTitle: 'text-left',
        classNameField: 'eb_fw_300',
      },
      {
        text: trb(i18.Labels.InvoiceBlocked), //'Blokuota',
        name: 'BlockedAmount.0.Text',
        classNameTitle: 'text-left',
        classNameField: 'eb_fw_300',
      },
      {
        text: trb(i18.Labels.InvoiceReserved), //'Rezervuota',
        name: 'Reserved.0.Text',
        classNameTitle: 'text-left',
        classNameField: 'eb_fw_300',
      },
      {
        text: trb(i18.Labels.DisposableBalance), //'Disponuojamas',
        name: 'DisposableBalance.0.Text',
        classNameTitle: 'text-left',
        classNameField: 'eb_fw_300',
      },
    ],
    { account } = props;

  return (
    <Container
      id="InvoiceFilter"
      className="eb_content_card eb_invoice_filter"
      cardClassName="pb-0"
      wide
    >
      <Form
        onSubmit={() => null}
        initialValues={props.initialData}
        decorators={props.decorator}
        render={({ handleSubmit, values, valid }) => {
          const choosenAccount = props.accounts && props.accounts.find((item) => account?.AccountID === item?.value);
          return (
            <form
              id="InvoiceFilterForm"
              className="w-100 "
              onSubmit={handleSubmit}
            >
              <div className="row invoice-account-container-row mx-0">
                <div className="col-12 invoice-account-container mb-3 pl-0">
                  <div className="d-md-none">
                    <span className="eb_fs_24 text-black mb-1">
                      {checkJsonProp(account, 'AccountTypeCode') && (
                        <Avatar
                          size={17}
                          style={{
                            backgroundColor: accountsColorObject[checkJsonProp(account, 'AccountTypeCode')],
                            marginRight: 10,
                            marginBottom: 2,
                          }}
                        />
                      )}
                      {checkJsonProp(choosenAccount, 'AmountTxt')}
                    </span>
                    <br />
                    <span className="eb_fs_14 text-gray-basic">{account?.AccountTypeCode === '4' ? account?.AgreementNumber : account?.AccountNumber ?? ''}</span>
                    {account && (
                      <Summary
                        className="mt-3"
                        data={account}
                        headers={summary}
                      />
                    )}
                  </div>

                  <div className="form-group mb-4">
                    <div className="d-flex vertical-align-center">
                      <div>
                        <Avatar
                          size={17}
                          style={{
                            backgroundColor: accountsColorObject[choosenAccount?.color] ?? accountsColorObject[1],
                          }}
                        />
                      </div>
                      <div>
                        <span
                          className="eb_content_subtitle ml-2 p-0 mb-0"
                          id="InvoiceFilterFormAccountID"
                        >
                          {choosenAccount?.label}
                        </span>
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className="form-group mb-4">
                    <FormField
                      title={i18.Labels.Account}
                      name="AccountID"
                      component={FormFieldType.select}
                      options={props.accounts}
                      optionsLabel="label"
                      optionsValue="value"
                      optionsDisabled="disabled"
                      optionsColor="color"
                      validate={props.submitting ? null : required}
                      disabled={true} //{props.submitting}
                      inline={false}
                      className="disabled-ghost m--4"
                      classNameTitle="d-none"
                      classNameField="col-12 mx--3"
                    />
                  </div> 
                  */}
                </div>
                <div className="row d-flex justify-content-between mb-2 mx-0 px-md-0 px-2">
                  <Row
                    align="middle"
                    className="pr-0"
                  >
                    <Col
                      xxl={16}
                      xl={12}
                      lg={24}
                    >
                      <div className="form-group align-self-center">
                        <ConfigProvider wave={{ disabled: true }}>
                          <FormField
                            title={
                              <DownOutlined
                                style={{ color: '#b2b2b2' }}
                                className="mr-3 ml-1"
                              />
                            }
                            name="FilterID"
                            component={FormFieldType.radio}
                            disabled={props.loadingAction}
                            options={period}
                            translate={translationGroups.Period}
                            optionsLabel="label"
                            optionsValue="value"
                            classNameField="col-12 col-md-10 pl-md-0 mr-md-0 period-filter pr-0 pr-md-3 "
                            classNameTitle="d-none d-md-flex col-md-auto pr-0 pl-0"
                            // classNameField="col-12 pl-0 ml-0"
                            classNameItem="text-nowrap pr-2 pl-0"
                            styleTitle={{
                              top: 10,
                              left: 0,
                              zIndex: 7,
                            }}
                            autosize
                            //inline
                            small
                            titleWithoutTranslate
                          />
                        </ConfigProvider>
                      </div>
                    </Col>
                    <Col
                      xxl={8}
                      xl={12}
                      lg={24}
                      className="mt-md-0 mt-3"
                    >
                      <div className="row mt-1 mx-0">
                        <div className="col-5 col-lg-6 px-0 pr-1">
                          <FormField
                            name="FilterBeginDate"
                            component={FormFieldType.date}
                            classNameField="p-0 pl-0"
                            className="mx-0"
                            disabled={props.loadingAction}
                            maxDate={values.FilterEndDate ? values.FilterEndDate : formatCurrentDate()}
                            small
                          />
                        </div>
                        <div className="col-5 col-lg-6 px-0 pl-1">
                          <FormField
                            name="FilterEndDate"
                            classNameField="p-0 "
                            className="mx-0"
                            component={FormFieldType.date}
                            disabled={props.loadingAction || !values.FilterBeginDate}
                            maxDate={formatCurrentDate()}
                            minDate={values.FilterBeginDate ? values.FilterBeginDate : null}
                            small
                          />
                        </div>

                        <div className="col-2 px-0 pl-3 d-flex align-items-center">
                          <div className="d-flex d-md-none row ">
                            <ButtonGroup className="rounded-button static-color">
                              <Button
                                id="InvoiceFilterSubmitButtonMD"
                                type="primary"
                                onClick={() => (valid ? props.submit(values) : null)}
                                loading={props.loadingAction === 'submit'}
                                disabled={props.loadingAction}
                                className="invoice_search_mobile"
                                icon={<RightOutlined />}
                              />
                            </ButtonGroup>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="d-block d-md-none row mx-0">
                <div className="col-12 d-flex mt-4 px-0">
                  {props.showPDF && (
                    <Button
                      id="InvoiceFilterDownloadPDFMD"
                      type="default"
                      onClick={() => (valid ? props.download(values) : null)}
                      loading={props.loadingAction === 'downloadingPDF'}
                      disabled={props.loadingAction}
                      className="mr-1"
                    >
                      PDF
                    </Button>
                  )}
                  {props.showExcel && (
                    <>
                      <Button
                        id="InvoiceFilterDownloadXLSMD"
                        type="default"
                        onClick={() => (valid ? props.downloadExcel(values) : null)}
                        loading={props.loadingAction === 'downloadingExcel'}
                        disabled={props.loadingAction}
                        className="mx-1"
                      >
                        XLS
                      </Button>
                      <Button
                        id="InvoiceFilterDownloadXMLMD"
                        type="default"
                        onClick={() => (valid ? props.downloadXML(values) : null)}
                        loading={props.loadingAction === 'downloadingXML'}
                        disabled={props.loadingAction}
                        className="mx-1"
                      >
                        XML
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className="d-none d-md-block row">
                <div className="col-12 text-right mb-3">
                  <ButtonGroup className="rounded-button">
                    <Button
                      id="InvoiceFilterSubmitButtonLG"
                      size="large"
                      type="primary"
                      onClick={() => (valid ? props.submit(values) : null)}
                      loading={props.loadingAction === 'submit'}
                      disabled={props.loadingAction}
                    >
                      <RightOutlined />
                    </Button>
                  </ButtonGroup>
                </div>
                <div className="col-12 d-flex justify-content-between rounded-button">
                  <div>
                    <DownloadOutlined
                      id="InvoiceFilterDownloadLG"
                      className="mr-1"
                    />
                    {props.showSchedule && (
                      <Button
                        id="InvoiceFilterSchedule"
                        size="large"
                        onClick={props.showModalSchedule}
                      >
                        <ScheduleOutlined />
                      </Button>
                    )}
                    {props.showPDF && (
                      <Button
                        id="InvoiceFilterDownloadPDFLG"
                        size="large"
                        onClick={() => (valid ? props.download(values) : null)}
                        loading={props.loadingAction === 'downloadingPDF'}
                        disabled={props.loadingAction}
                        className="mx-1"
                      >
                        PDF
                      </Button>
                    )}
                    {props.showExcel && (
                      <>
                        <Button
                          id="InvoiceFilterDownloadXLSLG"
                          size="large"
                          onClick={() => (valid ? props.downloadExcel(values) : null)}
                          loading={props.loadingAction === 'downloadingExcel'}
                          disabled={props.loadingAction}
                          className="mx-1"
                        >
                          XLS
                        </Button>
                        <Button
                          id="InvoiceFilterDownloadXMLLG"
                          size="large"
                          onClick={() => (valid ? props.downloadXML(values) : null)}
                          loading={props.loadingAction === 'downloadingXML'}
                          disabled={props.loadingAction}
                          className="mx-1"
                        >
                          XML
                        </Button>
                      </>
                    )}
                  </div>
                  {props.showPrint && (
                    <ButtonGroup className="rounded-button">
                      <Button
                        id="InvoiceFilterSubmitButtonPrint"
                        size="large"
                        onClick={() => props.print()}
                        loading={props.loadingAction === 'printing'}
                        disabled={props.loadingAction}
                      >
                        <CustomIcon
                          type="custom"
                          icon="print"
                        />
                      </Button>
                    </ButtonGroup>
                  )}
                </div>
              </div>
            </form>
          );
        }}
      />
    </Container>
  );
};
