import React, { Component } from 'react';
import { connect } from 'react-redux';

import { CMCActions, DepositActions } from 'src/Redux';
import { ContentLoader, ContentNoData, FormDecoratorSwitch } from 'src/Components';
import { DepositFill } from './DepositsFill';
import { DepositsTermsLT, DepositsTermsEN } from './DepositsTerms';

class DepositsFillContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: {
        ProductTypeID: 4,
        AutoResume: 'WithInterest',
        Confirm: false,
        InitialAccountID: null,
        SubProductID: null,
        CurrencyID: 2,
        Amount: null,
        Interest: 0,
      },
      decorator: FormDecoratorSwitch({
        name: 'deposits',
        getCIR: this.getCIR,
        getPDR: this.getPDR,
      }),
    };
  }

  submit = async (values) => {
    if (this.state.errors?.length) {
      return;
    }
    const { dispatch, setFormValues } = this.props;
    this.setState({ submitting: true });
    this.setState({ errors: null });

    const response = await dispatch(DepositActions.postDepositOffer(values));

    if (response?.payload?.Response?.IsOk) {
      if (setFormValues) {
        setFormValues(values);
      }
    } else if (response?.error?.response?.data?.errorCode) {
      this.setState({ errors: [{ ErrorCode: response.error.response.data.errorCode }] });
    } else {
      this.setState({ errors: [] });
    }

    this.setState({ submitting: false });
  };

  componentDidMount() {
    const { dispatch, sendCIPTR, formValues } = this.props;

    dispatch(DepositActions.resetDepositOffer());

    if (formValues) {
      this.setState({ ...formValues });
    }

    if (!sendCIPTR && this.state.data.ProductTypeID) {
      dispatch(CMCActions.postCIPTR({ ProductTypeID: this.state.data.ProductTypeID }));
    }
  }

  getPDR = async (values) => {
    const { dispatch } = this.props;

    if (values.SubProductID && values.SubProductID !== this.state.SubProductID) {
      if (values.SubProductID === 39 || values.SubProductID === 40) {
        values.AutoResume = 'WithoutInterest';
      }
      this.setState({ data: values, errors: null });
      const response = await dispatch(CMCActions.postPDR(values));
      if (response?.error?.response?.data?.errorCode) {
        this.setState({ errors: [{ ErrorCode: response?.error?.response?.data?.errorCode }] });
      }
    }
  };

  getCIR = async (values) => {
    const { dispatch } = this.props;

    if (
      values.ProductTypeID === this.state.ProductTypeID &&
      values.SubProductID === this.state.SubProductID &&
      values.Period === this.state.Period &&
      values.CurrencyID === this.state.CurrencyID &&
      values.InitialAccountID === this.state.InitialAccountID
    ) {
      return;
    }

    if (!values.ProductTypeID || !values.SubProductID || !values.Period || !values.CurrencyID || !values.InitialAccountID) {
      return;
    }

    this.setState({
      data: values,
      errors: null,
    });

    const response = await dispatch(CMCActions.postCIR(values));
    if (response.type === 'POST_CMC_CIR_SUCCESS') {
      const { InterestText } = response.payload;
      if (InterestText) {
        return { InterestText };
      }
    } else if (response?.error?.response?.data?.errorCode) {
      this.setState({ errors: [{ ErrorCode: response.error.response.data.errorCode }] });
    }
    return null;
  };
  render() {
    const { CIPTR, sendCIPTR, PDR, CIR, depositOffer } = this.props;

    if (sendCIPTR) {
      return <ContentLoader />;
    }

    if (!CIPTR) {
      return <ContentNoData id="DepositsFill" />;
    }

    return (
      <DepositFill
        submit={this.submit}
        submitting={this.state.submitting}
        data={this.state.data}
        errors={this.state.errors}
        decorator={this.state.decorator}
        CIPTR={CIPTR}
        PDR={PDR}
        CIR={CIR}
        depositOffer={depositOffer}
        DepositsTerms={this.props.language === 'en-GB' ? DepositsTermsEN : DepositsTermsLT}
      />
    );
  }
}

function mapStateToProps(state) {
  const { CIPTR, sendCIPTR, PDR, CIR } = state.CMCReducers;
  const { depositOffer } = state.DepositReducers;
  const { COMPANY } = state.AMCReducers;
  const { language } = state.languageReducer;
  const { translations } = state.translationReducer;
  return {
    CIPTR,
    sendCIPTR,
    PDR,
    CIR,
    COMPANY,
    depositOffer,
    language,
    translations,
  };
}

const connectedDepositsFillContainer = connect(mapStateToProps)(DepositsFillContainer);
export { connectedDepositsFillContainer as DepositsFillContainer };
