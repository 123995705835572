import {kYCServices} from './services';
import {KYCConstants} from './constants';

export const KYCActions = {
    getKYC,
    postKYC,
};

function getKYC() {
    return dispatch => {
        dispatch(request());

        return kYCServices.getKYC()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: KYCConstants.GET_KYC_REQUEST};
    }

    function success(data) {
        return {type: KYCConstants.GET_KYC_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: KYCConstants.GET_KYC_ERROR, error}
    }
}

function postKYC(data) {
    return dispatch => {
        dispatch(request());

        return kYCServices.postKYC(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: KYCConstants.POST_KYC_REQUEST};
    }

    function success(data) {
        return {type: KYCConstants.POST_KYC_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: KYCConstants.POST_KYC_ERROR, error}
    }
}
