import { useEffect } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';

import { CURRENCIES, LANGUAGES_LOCALES, i18, trb } from 'src/Utilities';
import { checkIsFirstTwoLetters, scrollToTop } from 'src/utils';
import { fetchAMLInternationalPaymentCurrency, PMCActions } from 'src/Redux';
import { Button, Checkbox, FormField, FormFieldGroup, Input, InputNumber, ScrollToFieldError, Select } from 'src/shared';
import { FieldPayee, IPaymentError, PaymentError } from '../components';
import { IPaymentInternationalValues } from './interfaces';
import { validationPaymentInternational } from './validation';
import { FieldPayeeAccount, FieldTemplate, FieldInitialAccount } from './components';
import { paymentInternationalValues } from './initialValues';

interface IProps {
  data: IPaymentInternationalValues;
  submit: (values: IPaymentInternationalValues, actions: FormikHelpers<IPaymentInternationalValues>) => void;
  handleBackClick?: () => void;
  errorsPVR?: Array<IPaymentError>;
}

export const ViewPaymentsInternational = ({ data, submit, handleBackClick, errorsPVR }: IProps) => {
  const dispatch: (dispatch: any) => Promise<any> = useDispatch();
  const { sendPVR, forexPayments } = useSelector((state: any) => state.PMCReducers);
  const { language } = useSelector((state: any) => state.languageReducer);
  const { CISO } = useSelector(
    (state: any) => state?.translationReducer?.translations?.find((el: any) => el.culture === language && el.context === 'BBS.Classificators')?.Groups ?? []
  );

  useEffect(() => {
    scrollToTop();
    /* duplicate call
    if (!forexPayments && !forexPaymentsSend) {
      dispatch(PMCActions.getForexPayments());
    } */
  }, []);

  const handleValidationIBAN = async (value: string, setFieldError: (name: string, error: string | undefined) => void) => {
    const formattedValue = value.replaceAll(' ', '');

    if (checkIsFirstTwoLetters(formattedValue) && value) {
      const resValid = await dispatch(PMCActions.checkIbanValid({ iban: value }));

      if (!resValid?.payload?.ibanValid) {
        setFieldError('Payment.BeneficiaryAccountNumber', i18.PaymensValidations.InvalidIBAN);

        return false;
      } else {
        setFieldError('Payment.BeneficiaryAccountNumber', undefined);

        return true;
      }
    } else {
      setFieldError('Payment.BeneficiaryAccountNumber', undefined);

      return true;
    }
  };

  const handleSubmit = async (values: IPaymentInternationalValues, actions: FormikHelpers<IPaymentInternationalValues>) => {
    const isValid = await handleValidationIBAN(values.Payment.BeneficiaryAccountNumber, actions.setFieldError);

    if (isValid) {
      if (isTemplate) {
        await submit(
          {
            ...values,
            Payment: {
              ...values.Payment,
              BeneficiaryAccountNumber: values.Payment?.BeneficiaryAccountNumber?.replaceAll(' ', ''),
            },
          },
          actions
        );
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const res = await dispatch(fetchAMLInternationalPaymentCurrency(values.Payment.Currency));

      if (res?.payload) {
        await submit(
          {
            ...values,
            Payment: {
              ...values.Payment,
              BeneficiaryAccountNumber: values.Payment?.BeneficiaryAccountNumber?.replaceAll(' ', ''),
            },
          },
          actions
        );
      }
    }
  };
  const isTemplate = data?.Payment.PaymentFormType === 'template';
  const gridStyle = isTemplate ? '' : 'col-md-6';

  if (!forexPayments) {
    return null;
  }
  if (data?.Payment?.RecipientBankCountry && !forexPayments?.Countries?.find(({ ISO }: { ISO: string }) => ISO === data?.Payment?.RecipientBankCountry)) {
    data.Payment.RecipientBankCountry = '';
  }

  return (
    <Formik
      validationSchema={validationPaymentInternational}
      initialValues={{ ...paymentInternationalValues, ...data }}
      onSubmit={async (values, formikHelpers) => await handleSubmit(values, formikHelpers)}
    >
      {({ values, setFieldValue, isSubmitting }) => (
        <Form
          noValidate={true}
          autoComplete="off"
        >
          <ScrollToFieldError />
          <PaymentError errors={errorsPVR} />
          <div className="row">
            {!isTemplate && (
              <div className={`col-12 ${gridStyle} form-part`}>
                <div className="form-part-title ">{trb(i18.PaymentParts.Payer)}</div>
                <FieldTemplate />
                <FieldInitialAccount overdraft={true} />
                {values.Payment.OverdraftValue !== null && (
                  <FormField
                    name="Payment.OverdraftValue"
                    label={trb(i18.Labels.OverdraftAvailableAmount)}
                    disabled={true}
                  >
                    <InputNumber
                      suffix={` ${CURRENCIES.EUR}`}
                      allowNegative={true}
                    />
                  </FormField>
                )}
              </div>
            )}
            <div className={`col-12 ${gridStyle} form-part`}>
              <div className="form-part-title">{trb(isTemplate ? i18.Labels.TemplateInternational : i18.PaymentParts.Payee)}</div>
              {isTemplate && (
                <FormField
                  name="Payment.TemplateName"
                  label={trb(i18.Labels.PaymentTemplateName)}
                >
                  <Input />
                </FormField>
              )}
              <FieldPayee paymentType="international" />
              <FieldPayeeAccount />
              {!isTemplate && (
                <FormFieldGroup>
                  <FormField
                    wrapperClassNames="w-50"
                    name="Payment.Amount"
                    label={trb(i18.Labels.Amount)}
                  >
                    <InputNumber />
                  </FormField>
                  <FormField
                    wrapperClassNames="w-50"
                    name="Payment.Currency"
                    label={trb(i18.Labels.Currency)}
                    disabled={true}
                  >
                    <Input />
                  </FormField>
                </FormFieldGroup>
              )}
              <FormField
                name="Payment.Purpose"
                label={trb(i18.Labels.Purpose)}
              >
                <Input />
              </FormField>
              <FormField
                name="Payment.BeneficiaryAddress"
                label={trb(i18.Labels.BeneficiaryAddress)}
              >
                <Input />
              </FormField>
              <FormField
                name="Payment.RecipientBankCountry"
                label={trb(i18.Labels.BeneficiaryCountryCode)}
              >
                <Select
                  options={
                    (forexPayments?.Countries as Array<{ ISO: string }>)
                      ?.map(({ ISO }) => {
                        return { label: CISO?.[ISO] ?? ISO, value: ISO };
                      })
                      .sort((a, b) => {
                        return a.label
                          ?.toLowerCase()
                          .localeCompare(b.label?.toLowerCase(), LANGUAGES_LOCALES[language as keyof typeof LANGUAGES_LOCALES], { ignorePunctuation: true });
                      }) ?? []
                  }
                />
              </FormField>
              <FormField
                name="Payment.BeneficiaryCity"
                label={trb(i18.Labels.BeneficiaryCity)}
              >
                <Input />
              </FormField>
              <FormField
                name="Payment.BeneficiaryPostCode"
                label={trb(i18.Labels.BeneficiaryPostcode)}
              >
                <Input />
              </FormField>
              <FormField
                name="Payment.CorrespondentBankBic"
                label={trb(i18.Labels.CorrespondentBankBicInternational)}
              >
                <Input />
              </FormField>
            </div>
          </div>
          {!isTemplate && (
            <div className="row">
              <div className="col-6" />
              <div className="col-6 d-flex justify-content-end">
                <Checkbox
                  name="Payment.SaveTemplate"
                  handleChange={(value) => (value ? null : setFieldValue('Payment.TemplateName', ''))}
                >
                  {trb(i18.Labels.SavePaymentTemplate)}
                </Checkbox>
                {values.Payment.SaveTemplate ? (
                  <FormField
                    name="Payment.TemplateName"
                    label={trb(i18.Labels.PaymentTemplateName)}
                  >
                    <Input />
                  </FormField>
                ) : null}
              </div>
            </div>
          )}
          <div className="text-end">
            {isTemplate ? (
              <>
                <Button
                  type="default"
                  size="large"
                  htmlType="button"
                  onClick={handleBackClick}
                  loading={sendPVR}
                >
                  <RollbackOutlined />
                  {trb(i18.Buttons.Cancel)}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={sendPVR || isSubmitting}
                  disabled={sendPVR || isSubmitting}
                >
                  <SaveOutlined />
                  {trb(i18.Buttons.SubmitTemplates)}
                </Button>
              </>
            ) : (
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={sendPVR || isSubmitting}
                disabled={sendPVR || isSubmitting}
              >
                {trb(i18.Buttons.Preview)}
              </Button>
            )}
          </div>

          {/* <pre>{JSON.stringify(values, undefined, 4)}</pre> */}
        </Form>
      )}
    </Formik>
  );
};
