export const IconCircle = ({ size = 6, color = '#666666' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 4 4"
      fill="none"
    >
      <circle
        cx="1.90332"
        cy="2"
        r="1.5"
        fill={color}
      />
    </svg>
  );
};
