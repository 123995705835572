import React, { Component } from 'react';
import { connect } from 'react-redux';

import { FormField, FormFieldType } from '../../FormField';
import { i18, numberFormat, required } from '../../../Utilities';
import { FeeActions } from '../../../Redux';

class FeeModule extends Component {
  componentDidUpdate(prevProps) {
    const { dispatch, feeModuleId } = this.props;
    if (feeModuleId) {
      if (prevProps.feeModuleId !== feeModuleId) {
        this.props.values.feePaymentData = [];
        this.props.values.Payment.AmountStr = 0;
        this.props.values.Payment.InitPayerIdentTypeValue = undefined;
        this.props.values.feePayment = undefined;
        dispatch(FeeActions.getFeePayment(feeModuleId));
      }
    } else {
      this.props.values.Payment.InitPayerIdentTypeValue = undefined;
      this.props.values.Payment.Purpose = null;
      this.props.values.Payment.BeneficiaryAccountNumber = undefined;
      this.props.values.Payment.PayeeBank = undefined;
      this.props.values.Payment.Recipient = undefined;
      this.props.values.Payment.FeeCode = undefined;
      // this.props.values.Payment.AmountStr = undefined;
      this.props.values.feePaymentData = undefined;
      this.props.values.feePayment = undefined;
      this.props.values.initiate = undefined;
      this.props.values.note = undefined;
    }
  }

  numberFormatting(id) {
    switch (id) {
      case 'A':
        return { ...numberFormat().currency, allowNegative: false };
      case 'TA':
        return { ...numberFormat().coefficient4decimal, allowNegative: false };
      case 'S':
        return numberFormat().number;
      default:
        return { ...numberFormat().number, allowNegative: false };
    }
  }

  render() {
    const { feeModuleId, feePayment, values, form } = this.props;

    if (!feeModuleId || !feePayment) {
      return null;
    }

    const feeModuleObj = JSON.parse(feePayment.feeModule);

    const rowsSingle = feeModuleObj.rowsSingle,
      headers = feeModuleObj.headers,
      rows = feeModuleObj.rows;

    values.Payment = {
      //AmountStr: this.props.values.Payment.AmountStr,
      ...this.props.values.Payment,
      FeeProvider: feeModuleId,
      InitialAccountID: this.props.values.Payment.InitialAccountID,
      BeneficiaryAccountNumber: feePayment.payeeAccount,
      PayeeBank: feePayment.payeeBank,
      Recipient: feePayment.providerTitle,
      FeeCode: feePayment.code,
    };
    values.feePayment = {
      ...values.feePayment,
      feeModule: feeModuleObj,
      accountNumber: feePayment.payeeAccount,
      accountProvider: feePayment.payeeBank,
      code: feePayment.code,
      date: Date(),
      provider: feePayment.providerTitle,
      requiredFeeCode: feePayment.requiredFeeCode,
      requiredFeeDate: feePayment.requiredFeeDate,
      requiredPayerCode: feePayment.requiredPayerCode,
    };
    values.note = feePayment.note;

    form.change('initiate', true);
    return (
      <div className="form-part fee_form_part big-input">
        {feePayment.requiredPayerCode && (
          <FormField
            title={i18.Labels.IdentificationCode}
            inline={true}
            name={'Payment.CustomersCode'}
            disabled={this.props.disabled}
            component={FormFieldType.input}
            validate={required}
            onBlur={() => this.props.feePaymentHistory(this.props.values)}
          />
        )}
        {rowsSingle &&
          rowsSingle.map((item, index) => (
            <React.Fragment key={index}>
              {item &&
                item.fields.map(
                  (fieldItem, fieldIndex) =>
                    fieldItem.selected && (
                      <FormField
                        key={fieldIndex}
                        title={item.fields[0].title}
                        titleWithoutTranslate={true}
                        inline={true}
                        name={'feePaymentData.' + index + '.' + fieldItem.id}
                        disabled={this.props.disabled}
                        component={fieldItem.component === 'text' ? FormFieldType.input : FormFieldType.number}
                        formatNumber={numberFormat()[fieldItem.component]}
                        validate={fieldItem.required ? required : null}
                      />
                    )
                )}
            </React.Fragment>
          ))}
        {rows && rows.length > 0 && (
          <div className="row fee-header-wrapper d-none d-lg-flex">
            <div className="col-3" />
            <div className="col-9 pl-lg-0">
              <div className="d-none d-lg-flex form-fee-row">
                {headers.map((item, index) =>
                  item.title !== '' ? (
                    <div
                      className="form-fee-header col"
                      key={index}
                    >
                      {item.title}
                    </div>
                  ) : null
                )}
              </div>
            </div>
          </div>
        )}
        {rows &&
          rows.map((item, index) => (
            <div
              className="form-field-wrapper row align-items-center"
              key={index}
            >
              <div className="form-field-title col-12 col-lg-3 text-left text-lg-right">{item.fields[0].title}</div>
              <div className="col-12 col-lg-9 pl-lg-0 text-center">
                <div className="row no-gutters">
                  {item.fields.map((fieldItem, fieldIndex) => {
                    return (
                      fieldItem.selected && (
                        <div
                          className="col-12 col-lg px-lg-0 big-input"
                          key={fieldIndex}
                        >
                          <div
                            className="d-flex d-lg-block"
                            key={fieldIndex}
                          >
                            <div className="col-4 d-lg-none pr-3 text-right text-lg-center">{fieldItem.title}</div>
                            <FormField
                              onlyComponent={true}
                              name={'feePaymentData.' + index + '.' + fieldItem.id}
                              disabled={this.props.disabled || fieldItem.disabled}
                              component={FormFieldType.number}
                              formatNumber={this.numberFormatting(fieldItem.id)}
                              validate={fieldItem.required ? required : null}
                            />
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sendFeePayment, feePayment } = state.FeeReducers;

  return {
    sendFeePayment,
    feePayment,
  };
}

const connectedFeeModule = connect(mapStateToProps)(FeeModule);
export { connectedFeeModule as FeeModule };
