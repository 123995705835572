import React from 'react';

import { ILoginForm, ILoginTypes } from 'src/Containers';
import { CONFIRMATION_BY_TYPE } from './constants';
import { trb } from 'src/Utilities';

import { ConfirmationBox } from '../Components';

interface IProps {
  data: ILoginForm;
  setData: React.Dispatch<React.SetStateAction<ILoginForm>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const LoginConfirmationSwitcher = ({ data, setStep }: IProps) => {
  const { LoginType } = data;

  const { title, subTitle, confirmationInfoText } = (CONFIRMATION_BY_TYPE as any)[LoginType as ILoginTypes] ?? {};

  if (!title) {
    return null;
  }

  return (
    <ConfirmationBox
      data={data}
      setStep={setStep}
      infoText={trb(confirmationInfoText)}
      title={title}
      subTitle={subTitle}
    />
  );
};
