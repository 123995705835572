import {
    checkJsonExist,
    checkJsonProp,
    i18,
    ignitisElectricCodeIsValid, ignitisGasCodeIsValid,
    translationGroups,
    trb,
} from '../../../Utilities';

export const FeePaymentValidation = (values, props) => {
//    const errorFields = [];
    const errorFields2 = {Payment: {}};

    if (values.Payment.CustomersCode && values.Payment.CustomersCode.length > 35) {
        errorFields2.Payment.CustomersCode = trb(i18.Validations.InputIsTooLong) + ':' + trb(i18.Validations.MaxInputIs) + ' ' + 35;
    }

    if (values.feePayment && values.feePayment.code && values.Payment && values.Payment.CustomersCode) {
        const {code} = values.feePayment;
        if (code === 103500 || code === 203000) {// elektra
            if (!ignitisElectricCodeIsValid(values.Payment.CustomersCode))
                return {
                    Payment: {CustomersCode: trb(i18.Validations.identificationCodeValueNotValid)}
                }
        }
        if (code === 10001) {//dujos
            if (!ignitisGasCodeIsValid(values.Payment.CustomersCode)) {
                return {
                    Payment: {CustomersCode: trb(i18.Validations.identificationCodeValueNotValid)}
                }
            }
        }
    }
    if (values.feePaymentData) {
        const errorFields = new Array(values.feePaymentData.length);
        values.feePaymentData.map((item, index) => {
            const fields = checkJsonProp(values, 'feePayment.feeModule.rows.' + index + '.fields');
            if (fields !== null) {
                const Nfield = fields.find(x => x.id === "N");
                const Ifield = fields.find(x => x.id === "I");
                if (checkJsonProp(Nfield, 'selected') === true && checkJsonProp(Ifield, 'selected') === true) {
                    if (checkJsonExist(item, 'N') && !checkJsonExist(item, 'I')) {
                        errorFields.splice(index, 0, {I: trb(i18.Validations.CountersRequiredTo)});
                    } else if (!checkJsonExist(item, 'N') && checkJsonExist(item, 'I')) {
                        errorFields.splice(index, 0, {N: trb(i18.Validations.CountersRequiredFrom)});
                    } else if (checkJsonExist(item, 'N') && checkJsonExist(item, 'I') && item.N > item.I) {
                        errorFields.splice(index, 0, {
                            N: trb(i18.Validations.CountersValuesNotValid),
                            I: trb(i18.Validations.CountersValuesNotValid)
                        });
                    } else {
                        //errorFields.push({undefined});
                    }
                }
            }

            return null;
        });
        if (values.Payment && values.Payment.AmountStr > 0 && values.Payment.InitialAccountID) {
            const InitialAccounts = checkJsonProp(props, 'InitialAccounts');
            if (InitialAccounts && InitialAccounts.length > 0) {
                const acc = InitialAccounts.find(x => x.value === values.Payment.InitialAccountID);
                if (acc.amountValue < values.Payment.AmountStr) {
                    errorFields2.Payment.AmountStr = trb(translationGroups.ErrorCode + 'PMC00004');
                }
            }
        }
        if (values.Payment && values.Payment.AmountStr <= 0) {
            errorFields2.Payment.AmountStr = trb(i18.Validations.FieldRequired);
        }
        return {
            ...errorFields2,
            feePaymentData: [
                ...errorFields
            ]
        };
    }
};