import {aPIPaths, handleResponse, methods, requestEBank, requestEBankJSON, responseTypes} from '../../Config';

export const CMCServices = {
    postCIPTR,
    postPDR,
    postCIR,
    postVCR,
    postSCR,
    postDPDF,
    postGetAccountBalanceXml,
};

function postCIPTR(data = null) {
    return requestEBankJSON(aPIPaths.post_CMC_CIPTR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postPDR(data = null) {
    return requestEBank(aPIPaths.post_CMC_PDR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postCIR(data = null) {
    return requestEBank(aPIPaths.post_CMC_CIR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postVCR(data = null) {
    return requestEBank(aPIPaths.post_CMC_VCR, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postSCR(data = null) {
    return requestEBank(aPIPaths.post_CMC_SCR, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postDPDF(data = null) {
    return requestEBankJSON(aPIPaths.post_CMC_DPDF, {
        method: methods.post,
        data: data,
        responseType: responseTypes.arrayBuffer,
    }).then(handleResponse);
}

function postGetAccountBalanceXml(data = null) {
    return requestEBankJSON(aPIPaths.post_CMC_GetAccountBalanceXml, {
        method: methods.post,
        data: data,
        responseType: responseTypes.arrayBuffer,
    }).then(handleResponse);
}