import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Upload } from 'antd';

import { CustomIcon, i18, numberFormat, SIGN_CANCEL_TYPES, translationGroups, trb } from 'src/Utilities';
import { PaymentEditTable, PaymentUploadTable } from './index';
import { Container, ContentLoader, Notification, Number, OperationApproval } from 'src/Components';

import { BulkFileActions, OMCActions, PMCActions } from 'src/Redux';
import { AlertNotification } from 'src/shared';
import classNames from 'classnames';
import { PaymentError } from 'src/Containers/Payments/components';

export const PaymentUploading = () => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const [warnings, setWarnings] = useState(null);
  const [AllowToSave, setAllowToSave] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [approve, setApprove] = useState(false);
  const [fileGUID, setFileGUID] = useState(null);
  const [isSignLater, setSignLater] = useState(false);
  const [paymentErorrs, setPaytmentErrors] = useState(null);

  const { sendSUOR, sendSUORSMS } = useSelector((state) => state.OMCReducers);
  const { sendSUPR, UPR, send, sendUPR, errors, PUPR, sendPUPR, SUPR } = useSelector((state) => state.PMCReducers);
  const { language } = useSelector((state) => state.languageReducer);
  const { loading, FilesList, error } = useSelector((state) => state.BulkFileReducer);
  const { AuthType } = useSelector((state) => state.UACReducers?.ATR);

  const File = FilesList.find((item) => item.FileGuid === fileGUID);

  useEffect(
    () => () => {
      dispatch(PMCActions.clearUPR());
      dispatch(PMCActions.clearPUPR());
      dispatch(PMCActions.clearSUPR());
    },
    []
  );

  useEffect(() => {
    if (UPR && sendUPR === false && send === false) {
      if (UPR?.IsSuccessfull) {
        if (UPR?.ErrorCaptions?.[0]?.ErrorCode) {
          setWarnings(UPR?.ErrorCaptions);
        }
        setAllowToSave(UPR?.AllowToSave);
        setPaytmentErrors(null);
      } else {
        Notification({
          type: 'error',
          message: i18.NotificationTitles.FileUploadError,
          noTranslateDescription: true,
          description: <span dangerouslySetInnerHTML={{ __html: trb(i18.NotificationMessages.FileUploadError) }}></span>,
        });
      }
    }
  }, [UPR]);

  useEffect(() => {
    if (PUPR && sendPUPR === false && send === false) {
      if (PUPR?.IsSuccessfull) {
        Notification({
          type: 'success',
          message: i18.NotificationTitles.SavedSuccess,
          description: i18.NotificationMessages.SavedSuccess,
        });
        if (!fileGUID) {
          dispatch(PMCActions.clearPUPR());
          dispatch(PMCActions.clearSUPR());
          dispatch(PMCActions.clearUPR());
        }
      } else {
        Notification({
          type: 'error',
          message: i18.NotificationTitles.SavedError,
          description: i18.NotificationMessages.SavedError,
        });
      }
    }
  }, [PUPR]);

  const handleUpload = (info) => {
    const formData = new FormData();
    formData.append('file', info.file);
    dispatch(PMCActions.postUPR(formData));
  };

  useEffect(() => {
    if (SUPR && sendSUPR === false && send === false) {
      if (SUPR?.IsSuccessfull) {
        setAllowToSave(SUPR?.AllowToSave);
        const paymentList = SUPR?.PaymentsList,
          errorInPaymentList =
            paymentList && paymentList.length
              ? paymentList.reduce(
                  (summStr, rowItem, rowIndex) => {
                    return rowItem.Errors && rowItem.Errors.length ? (
                      <>
                        {summStr}
                        {rowItem.Errors.reduce(
                          (errorStr, item) => {
                            return (
                              <>
                                {errorStr}
                                <div>
                                  {rowIndex}: {item}
                                </div>
                              </>
                            );
                          },
                          <></>
                        )}
                      </>
                    ) : (
                      summStr
                    );
                  },
                  <>{trb(i18.NotificationMessages.UploadPaymentErrorInPaymentList)}</>
                )
              : null,
          errorInPaymentListCount =
            paymentList && paymentList.length
              ? paymentList.filter((rowItem) => {
                  return rowItem.Errors && rowItem.Errors.length > 0;
                }).length
              : 0;

        if (errorInPaymentListCount) {
          Notification({
            type: 'error',
            message: i18.NotificationTitles.UpdateError,
            description: errorInPaymentList,
            noTranslateDescription: true,
          });
        } else {
          Notification({
            type: 'success',
            message: i18.NotificationTitles.UpdateSuccess,
            description: i18.NotificationMessages.UpdateSuccess,
          });

          if (SUPR?.ErrorCaptions?.[0]?.ErrorCode) {
            setWarnings(SUPR?.ErrorCaptions);
          }
        }
      } else {
        Notification({
          type: 'error',
          message: i18.NotificationTitles.UpdateError,
          description: i18.NotificationMessages.UpdateError,
        });
      }
    }
  }, [SUPR]);

  const isFileSaved = fileGUID && FilesList.findIndex((item) => item.FileGuid === fileGUID) !== -1;

  const UpdateFile = (values) => {
    dispatch(PMCActions.postSUPR(values));
  };

  const Save = () => {
    setSignLater(true);
    const UploadFileID = UPR?.UploadFileID;
    dispatch(PMCActions.postPUPR({ FileID: UploadFileID.toString() }));
  };

  const handlePreview = async () => {
    setSignLater(false);
    const UploadFileID = UPR?.UploadFileID;
    setFileGUID(UploadFileID);
    if (!isFileSaved) {
      await dispatch(PMCActions.postPUPR({ FileID: UploadFileID.toString() }));
    }

    const { payload } = await dispatch(BulkFileActions.getBulkPaymentsPreview(UploadFileID));

    if (Array.isArray(payload?.ErrorCaptions) && payload.ErrorCaptions?.length) {
      setShowConfirm(false);
      setFileGUID(null);
      setPaytmentErrors(payload.ErrorCaptions);
    } else {
      setShowConfirm(true);
      setPaytmentErrors(null);
    }
  };

  const cancelApprove = (type, errorCode, notificationType = 'warning') => {
    dispatch(BulkFileActions.cancelLoading());
    setApprove(false);

    let message;

    switch (type) {
      case SIGN_CANCEL_TYPES.TIMEOUT:
        message = 'PaymentCanceledTimeout';
        break;
      case SIGN_CANCEL_TYPES.PMC10000:
        return null;
      case SIGN_CANCEL_TYPES.MANUAL:
        message = 'PaymentCanceledManual';
        break;
      case SIGN_CANCEL_TYPES.REJECTED:
        message = 'PaymentCanceledRejected';
        break;
      case SIGN_CANCEL_TYPES.ERROR_CODE:
        message = 'PaymentErrorCode';
        break;
      case SIGN_CANCEL_TYPES.VALIDATION:
        message = 'PaymentErrorValidation';
        break;
      default:
        message = errorCode?.replace('.', '');
        break;
    }

    Notification({
      type: notificationType,
      message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.Canceled,
      description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.Canceled,
    });
  };

  const clickApproveModal = async () => {
    const res = await dispatch(BulkFileActions.postBulkPaymentsSign({ FileGuid: fileGUID, Language: language }));

    if (res.payload?.IsSuccessfull) {
      setApprove(true);
    } else {
      if (res.payload?.ErrorCode === SIGN_CANCEL_TYPES.PMC10000) {
        cancelApprove(SIGN_CANCEL_TYPES.PMC10000, null, null);
      } else if (Array.isArray(res.payload?.PaymentErrors) && res.payload?.PaymentErrors?.length) {
        cancelApprove(SIGN_CANCEL_TYPES.VALIDATION, null, 'warning');
      } else {
        cancelApprove(SIGN_CANCEL_TYPES.REJECTED, null, 'warning');
      }

      setShowConfirm(false);
    }
  };

  const successApprove = () => {
    setApprove(false);
    setShowConfirm(false);
    dispatch(BulkFileActions.removeFromList(fileGUID));
    setFileGUID(null);
    dispatch(PMCActions.clearPUPR());
    dispatch(PMCActions.clearSUPR());
    dispatch(PMCActions.clearUPR());
    Notification({
      type: 'success',
      message: i18.NotificationTitles.SuccessApprove,
      description: i18.NotificationMessages.SuccessApprove,
    });
  };

  const submitSmsApprove = async (values) => {
    const response = await dispatch(OMCActions.postSUORSMS({ RQ_ID: values.RQ_ID, SMS_Code: values.SMS_Code }));
    const IsSuccessfull = response?.payload?.IsSuccessfull;

    if (!IsSuccessfull) {
      cancelApprove(SIGN_CANCEL_TYPES.ERROR_CODE, null, 'error');
    } else {
      successApprove();
    }

    return false;
  };

  const submitMobileApprove = async (values) => {
    const RQ_ID = File?.iSignToken;
    const response = await dispatch(OMCActions.postSUOR(values ?? { iSignToken: RQ_ID }));
    const AuthType = response?.payload?.AuthType;
    const IsSuccessfull = response?.payload?.IsSuccessfull;
    const ErrorCode = response?.payload?.ErrorCode;

    if (AuthType === null && IsSuccessfull === false) {
      return true;
    }

    if (AuthType === 'false') {
      if (ErrorCode === 'loginError.canceled') {
        cancelApprove(SIGN_CANCEL_TYPES.REJECTED, ErrorCode);
      } else {
        cancelApprove(null, ErrorCode);
      }
    } else if (IsSuccessfull === true) {
      successApprove();
    }

    return false;
  };

  const props = {
    accept: '.xml',
    multiple: false,
    disabled: send || sendUPR,
    // showUploadList: false,
    onRemove: () => {
      setFile(null);
    },
    beforeUpload: (fileParam) => {
      setFile(fileParam);
      return false;
    },
    onChange: (info) => handleUpload(info),
    file,
  };

  const data = SUPR ?? UPR ?? PUPR;

  if (sendSUPR) {
    return <ContentLoader />;
  }

  return (
    <div>
      <div className="eb_content_subtitle">{trb(i18.ContentMenu.PaymentUploading)}</div>
      <Container cardClassName={classNames('payment-uploading-card', { 'pb-0': data })}>
        {(!data || !AllowToSave) && (
          <div className="w-100">
            <Upload.Dragger
              {...props}
              className="mb-5 pb-5"
              showUploadList={false}
            >
              <div className="eb_content_subtitle text-center mx-1 eb_fw_300 text-primary">{trb(i18.Labels.SelectFile)}</div>
              <p className="ant-upload-drag-icon text-primary">
                {send ? (
                  <LoadingOutlined />
                ) : (
                  <CustomIcon
                    type="custom"
                    icon="plusIcon"
                  />
                )}
              </p>
            </Upload.Dragger>
            {/*<div className="eb_content_subtitle text-center mb-5 mx-1 text-gray-basic">{trb(i18.Labels.Or)}</div>*/}
            {/*<Upload {...props} className="eb_custom_upload">*/}
            {/*    <div className="eb_content_subtitle text-center mx-1 eb_fw_300">{trb(i18.Labels.SelectFile)}</div>*/}
            {/*    <Button disabled={this.state.data} type="primary" size="large" shape="circle" style={{padding: 15}}>*/}
            {/*        {this.state.submitting ? <LoadingOutlined /> : <CustomIcon type='custom' size={26} icon='plusIcon' />}*/}
            {/*    </Button>*/}
            {/*</Upload>*/}
          </div>
        )}
        {data && AllowToSave === true && (
          <>
            {warnings && !isFileSaved && (
              <>
                {warnings.map((item, index) => (
                  <AlertNotification
                    key={index}
                    message={trb(translationGroups.ErrorCode + item.ErrorCode)}
                    type="warning"
                    alertClassName="py-3 mb-3"
                  />
                ))}
              </>
            )}
            <PaymentError errors={paymentErorrs} />
            {error === SIGN_CANCEL_TYPES.PMC10000 && (
              <div className="mb-4">
                <AlertNotification
                  className="w-100"
                  message=""
                  description={trb(translationGroups.ErrorCode + error)}
                  type="info"
                />
              </div>
            )}
            <PaymentUploadTable
              data={data}
              buttons={[
                {
                  title: i18.Buttons.SafeOperation,
                  func: Save,
                  props: {
                    disabled: send,
                    loading: isSignLater && send,
                  },
                },
                {
                  title: !isFileSaved ? i18.Buttons.ApproveUploadedPayments : i18.Buttons.Approve,
                  func: handlePreview,
                  props: {
                    type: 'primary',
                    disabled: send,
                    loading: (!isSignLater && send) || loading,
                  },
                },
              ].filter((item) => (item.title === i18.Buttons.SafeOperation ? !isFileSaved : true))}
            />
          </>
        )}
        {data && AllowToSave === false && (
          <>
            {warnings && !isFileSaved && (
              <AlertNotification
                message={trb(i18.ErrorTitles.Warning)}
                type="warning"
                alertClassName="py-3 mb-3"
                description={
                  <ul className="mb-0">
                    {warnings.map((item, index) => (
                      // <li key={index}>{item.ErrorCaption}</li>
                      <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                    ))}
                  </ul>
                }
              />
            )}
            {errors && (
              <AlertNotification
                message={trb(i18.ErrorTitles.Warning)}
                type="error"
                alertClassName="py-3 mb-3"
                description={
                  <ul className="mb-0">
                    {errors?.map((item, index) => (
                      // <li key={index}>{item.ErrorCaption}</li>
                      <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                    ))}
                  </ul>
                }
              />
            )}
            <PaymentEditTable
              data={data}
              submit={UpdateFile}
              buttons={[
                {
                  title: i18.Buttons.UpdateFile,
                  // func: this.Save,
                  props: {
                    type: 'primary',
                    htmlType: 'submit',
                    disabled: send,
                    loading: send,
                  },
                },
              ]}
            />
          </>
        )}
        {showConfirm && (
          <Modal
            width={700}
            centered
            style={{ top: 0 }}
            styles={{
              body: {
                padding: 0,
                paddingBottom: 0,
              },
            }}
            open={showConfirm}
            title={<div className="eb_fw_600">{trb(i18.Labels.PaymentSubmitModal)}</div>}
            onCancel={() => {
              if (sendSUOR || sendSUORSMS) {
                return;
              }
              setShowConfirm(false);
              setApprove(false);
              dispatch(BulkFileActions.cancelLoading());
            }}
            closable
            footer={[
              <Button.Group key="footer">
                <Button
                  id="PaymentUploadingSubmitButton"
                  type="primary"
                  onClick={clickApproveModal}
                  disabled={loading || approve}
                >
                  {trb(i18.Buttons.Approve)}
                </Button>
                <Button
                  id="PaymentUploadingCancelButton"
                  type="default"
                  style={{ marginLeft: 0 }}
                  disabled={loading || approve}
                  onClick={() => {
                    if (sendSUOR || sendSUORSMS) {
                      return;
                    }
                    setShowConfirm(false);
                    setApprove(false);
                    dispatch(BulkFileActions.cancelLoading());
                  }}
                >
                  {trb(i18.Buttons.Cancel)}
                </Button>
              </Button.Group>,
            ]}
          >
            <div className="px-0 pb-3 p-md-3">
              {File?.Report?.map(({ Amount, Commission, CommissionCurrency, Currency, Quantity }, index) => (
                <div
                  className="row"
                  key={index}
                >
                  <div className="col-3 col-md-3 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">{trb(i18.Labels.PaymentsCount)}</div>
                  <div className="col-3 col-md-1 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                    <Number
                      value={Quantity}
                      format={numberFormat().number}
                    />
                  </div>
                  <div className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                    {trb(i18.Labels.PaymentsTotalAmount)}
                    <span className="ext-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                      <Number value={Amount} />
                      {` ${Currency}`}
                    </span>
                  </div>

                  <div className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                    {trb(i18.Labels.PaymentsTotalCommFee)}
                    <span className="py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                      <Number value={Commission} />
                      {` ${CommissionCurrency}`}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            {loading && !approve && <ContentLoader />}
            {approve && (
              <div className="pb-3">
                <OperationApproval
                  RQ_ID={File?.iSignToken ?? File?.SMSToken}
                  AuthType={AuthType}
                  code={File?.ControlCode}
                  cancelApprove={cancelApprove}
                  successApprove={successApprove}
                  submitSmsApprove={submitSmsApprove}
                  submitMobileApprove={submitMobileApprove}
                  approveCount={File?.PaymentsCount}
                  errorCode={error}
                  loading={sendSUOR || sendSUORSMS}
                />
              </div>
            )}
          </Modal>
        )}
      </Container>
    </div>
  );
};
