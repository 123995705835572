export const MessagingConstants = {
  POST_MESSAGING_CREATE_REQUEST: 'POST_MESSAGING_CREATE_REQUEST',
  POST_MESSAGING_CREATE_SUCCESS: 'POST_MESSAGING_CREATE_SUCCESS',
  POST_MESSAGING_CREATE_ERROR: 'POST_MESSAGING_CREATE_ERROR',
  RESET_MESSAGING_CREATE: 'RESET_MESSAGING_CREATE',

  POST_MESSAGING_RESPONSE_REQUEST: 'POST_MESSAGING_RESPONSE_REQUEST',
  POST_MESSAGING_RESPONSE_SUCCESS: 'POST_MESSAGING_RESPONSE_SUCCESS',
  POST_MESSAGING_RESPONSE_ERROR: 'POST_MESSAGING_RESPONSE_ERROR',

  GET_MESSAGING_CHATS_REQUEST: 'GET_MESSAGING_CHATS_REQUEST',
  GET_MESSAGING_CHATS_SUCCESS: 'GET_MESSAGING_CHATS_SUCCESS',
  GET_MESSAGING_CHATS_ERROR: 'GET_MESSAGING_CHATS_ERROR',

  GET_MESSAGING_CHAT_MESSAGES_REQUEST: 'GET_MESSAGING_CHAT_MESSAGES_REQUEST',
  GET_MESSAGING_CHAT_MESSAGES_SUCCESS: 'GET_MESSAGING_CHAT_MESSAGES_SUCCESS',
  GET_MESSAGING_CHAT_MESSAGES_ERROR: 'GET_MESSAGING_CHAT_MESSAGES_ERROR',

  GET_MESSAGING_NEW_MESSAGES_COUNT_REQUEST:
    'GET_MESSAGING_NEW_MESSAGES_COUNT_REQUEST',
  GET_MESSAGING_NEW_MESSAGES_COUNT_SUCCESS:
    'GET_MESSAGING_NEW_MESSAGES_COUNT_SUCCESS',
  GET_MESSAGING_NEW_MESSAGES_COUNT_ERROR:
    'GET_MESSAGING_NEW_MESSAGES_COUNT_ERROR',

  DELETE_MESSAGING_MESSAGE_REQUEST: 'DELETE_MESSAGING_MESSAGE_REQUEST',
  DELETE_MESSAGING_MESSAGE_SUCCESS: 'DELETE_MESSAGING_MESSAGE_SUCCESS',
  DELETE_MESSAGING_MESSAGE_ERROR: 'DELETE_MESSAGING_MESSAGE_ERROR',

  GET_MESSAGING_BROADCAST_LIST_REQUEST: 'GET_MESSAGING_BROADCAST_LIST_REQUEST',
  GET_MESSAGING_BROADCAST_LIST_SUCCESS: 'GET_MESSAGING_BROADCAST_LIST_SUCCESS',
  GET_MESSAGING_BROADCAST_LIST_ERROR: 'GET_MESSAGING_BROADCAST_LIST_ERROR',

  GET_MESSAGING_BROADCAST_MESSAGE_REQUEST:
    'GET_MESSAGING_BROADCAST_MESSAGE_REQUEST',
  GET_MESSAGING_BROADCAST_MESSAGE_SUCCESS:
    'GET_MESSAGING_BROADCAST_MESSAGE_SUCCESS',
  GET_MESSAGING_BROADCAST_MESSAGE_ERROR:
    'GET_MESSAGING_BROADCAST_MESSAGE_ERROR',

  DELETE_MESSAGING_BROADCAST_MESSAGE_REQUEST:
    'DELETE_MESSAGING_BROADCAST_MESSAGE_REQUEST',
  DELETE_MESSAGING_BROADCAST_MESSAGE_SUCCESS:
    'DELETE_MESSAGING_BROADCAST_MESSAGE_SUCCESS',
  DELETE_MESSAGING_BROADCAST_MESSAGE_ERROR:
    'DELETE_MESSAGING_BROADCAST_MESSAGE_ERROR'
}
