import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Table } from 'antd';

import { MessagingActions } from '../../../../Redux';
import { ContentLoader, ContentNoData } from '../../../../Components';
import { checkJsonProp, formatDate, getClassificators, useGetWindowSize, i18, trb } from 'src/Utilities';
import { ChatHeader, ChatMessages, NewChatMessage } from './Components';
import { Badge } from 'src/shared';

export const InboxContainer = () => {
  const windowSize = useGetWindowSize();
  const { Chats, sendChats, Messages } = useSelector((state) => state.MessagingReducers);
  const [chatId, setChatId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MessagingActions.getChats());
    dispatch(MessagingActions.getNewMessagesCount());
  }, []);

  const clickChat = async (e, { ChatId }) => {
    e.stopPropagation();

    await dispatch(MessagingActions.getChatMessages(ChatId));
    setChatId(ChatId);
  };

  const onClose = () => {
    setChatId(null);
    dispatch(MessagingActions.getChats());
    dispatch(MessagingActions.getNewMessagesCount());
  };

  const submitNewMessage = async (values) => {
    const data = {
      Message: values.message,
      ChatId: Messages.ChatId,
    };
    await dispatch(MessagingActions.postResponse(data));
    await dispatch(MessagingActions.getChatMessages(Messages.ChatId));
    values.message = '';
  };

  const deleteMessage = async (e) => {
    e.stopPropagation();
    await dispatch(MessagingActions.deleteMessage({ messageId: chatId }));
    await dispatch(MessagingActions.getChatMessages(Messages.ChatId));
  };

  if (sendChats) {
    return <ContentLoader />;
  }
  if (!Chats) {
    return <ContentNoData id="InboxChats" />;
  }

  const MSGL = getClassificators() ? getClassificators().MSGL : {};
  const keysMSGL = MSGL ? Object.keys(MSGL) : null;
  const checkMSGL = (subject) => (keysMSGL ? keysMSGL.find((item) => item === subject) !== undefined : false);

  const columns = [
    {
      title: trb(i18.Chat.LastMessage),
      dataIndex: 'LastMessage',
      render: (el) => <span className="text-nowrap">{formatDate(el)}</span>,
      width: 200,
    },
    {
      title: trb(i18.Chat.Subject),
      dataIndex: 'Subject',
      render: (subject) => (checkMSGL(subject) ? MSGL[subject] : subject),
    },
    {
      title: trb(i18.Chat.NewMessage),
      dataIndex: 'NewMessages',
      render: (el) => <Badge count={el} />,
    },
  ];

  const mobileColumns = [
    {
      title: trb(i18.Chat.Subject),
      dataIndex: 'Subject',
      render: (subject, item) => (
        <>
          <div className="d-flex w-100 justify-content-between">
            <div>
              <span className="text-nowrap text-gray-basic">{formatDate(item.LastMessage)}</span>
              <br />
              <span>{checkMSGL(subject) ? MSGL[subject] : subject}</span>
            </div>
            <div className={item.NewMessages ? 'widget d-flex align-items-center ' : 'd-none'}>
              <Badge
                count={item.NewMessages}
                className="pb-2"
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        id="InboxMessagesTable"
        dataSource={Chats}
        columns={windowSize.mobileCheck ? mobileColumns : columns}
        showHeader={!windowSize.mobileCheck}
        pagination={false}
        className="w-100 mobile-inbox-container"
        onRow={(item) => {
          return {
            onClick: (e) => {
              clickChat(e, item);
            },
          };
        }}
        rowKey="ChatId"
      />
      <Drawer
        width={1000}
        onClose={onClose}
        open={chatId !== null}
      >
        <div className="bbs_tab_content_inner" id="MessagesDrawer">
          <ChatHeader
            data={Messages}
            Subject={checkMSGL(checkJsonProp(Messages, 'Subject')) ? MSGL[Messages.Subject] : checkJsonProp(Messages, 'Subject')}
          />
          <ChatMessages data={Messages} />
          <NewChatMessage
            submit={submitNewMessage}
            closeChat={onClose}
            deleteMessage={deleteMessage}
          />
        </div>
      </Drawer>
    </>
  );
};
