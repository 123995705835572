import React from 'react';
import {Input} from 'antd';

import { formFieldId } from 'src/utils';

export const InputOnlyFormField = (props) => {
    const size = props.size,
        disabled = props.disabled;

    const meta = props.meta;

    return <>
              <Input
                {...props.input}
                autoComplete="off"
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck="off"
                size={size}
                disabled={disabled}
                name={props.input.name}
                value={props.input.value}
                onChange={props.input.onChange}
                type={props.inputType}
                autoFocus={props.autoFocus}
                addonBefore={props.addonBefore}
                suffix={props.suffix}
                maxLength={props.maxLength}
                onBlur={props.input.onBlur}
                id={formFieldId(props.input.name, 'InputOnly')}
            />
            <div style={{top: 33, zIndex: 1, direction: 'rtl'}} className={'position-absolute form-field-error col-12 pr-1'} id={formFieldId(props.input.name, 'InputOnlyError')} >{meta.touched && (meta.error || meta.submitError)}</div>
         </>
};
