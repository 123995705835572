import PropTypes from 'prop-types';

import {DepositDecorator, FeePaymentDecorator, NewPaymentDecorator} from "./FormDecorators";
import {Payment} from "src/Utilities";

export const FormDecoratorSwitch = (props) => {
    if (!props.name) {
        return null;
    }

    let currentDecorator = null;

    switch (props.name) {
        case 'deposits':
            currentDecorator = DepositDecorator(props);
            break;
        case Payment.NEW:
            currentDecorator = NewPaymentDecorator(props);
            break;
        case Payment.FEE:
            currentDecorator = FeePaymentDecorator(props);
            break;
        default:
            return null;
    }

    return currentDecorator;
};

FormDecoratorSwitch.propTypes = {
    name: PropTypes.string.isRequired
};
