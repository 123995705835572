import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import { Alert, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import { FormField, FormFieldType } from 'src/Components';
import { checkJsonProp, i18, required, translationGroups, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { GroupPaymentSpy } from './GroupPaymentSpy';
import { useNavigate } from 'react-router-dom';

const ButtonGroup = Button.Group;

export const GroupPaymentFill = (props) => {
  const navigate = useNavigate();

  const paymentGroups = checkJsonProp(props, 'data.PaymentGroups'),
    payerAccounts = checkJsonProp(props, 'data.InitialAccounts');

  let payerAccountsRemaped = [],
    tempTypeCode = null;

  payerAccounts &&
    payerAccounts.map((item) => {
      if (tempTypeCode !== item.AccountTypeNameToView) {
        const label = {
          label: trb(translationGroups.AccountTypeCode + item.AccountTypeNameToView),
          value: item.AccountTypeCode,
          amountValue: checkJsonProp(item, 'Amounts.0.Value'),
          disabled: true,
        };

        const data = {
          label: item.AccountNumber + ' (' + checkJsonProp(item, 'Amounts.0.Text') + ')',
          value: item.AccountID,
          amountValue: checkJsonProp(item, 'Amounts.0.Value'),
        };

        tempTypeCode = item.AccountTypeNameToView;

        payerAccountsRemaped.push(label, data);
      } else {
        const data = {
          label: item.AccountNumber + ' (' + checkJsonProp(item, 'Amounts.0.Text') + ')',
          value: item.AccountID,
          amountValue: checkJsonProp(item, 'Amounts.0.Value'),
        };

        payerAccountsRemaped.push(data);
      }
    });

  const editPaymentGroup = (id) => {
    navigate(routerPath.paymentGroups, {
      state: {
        groupId: id,
      },
    });
  };

  return (
    <Form
      onSubmit={props.submit}
      initialValues={props.initialValues}
      render={({ handleSubmit, values }) => {
        return (
          <form
            id="GroupPaymentFillForm"
            className="mb-3 row"
            onSubmit={handleSubmit}
          >
            <div className="col-12 no-padding">
              <FormField
                title={i18.Labels.PaymentGroups}
                name="PaymentGroupId"
                component={FormFieldType.select}
                options={paymentGroups ? paymentGroups : []}
                optionsLabel="Name"
                optionsValue="ID"
                validate={required}
                disabled={props.submitting}
              />
              {values.PaymentGroupId && (
                <span
                  className="position-absolute"
                  style={{ top: 0, right: -40 }}
                >
                  <Button
                    id="GroupPaymentFillEditButton"
                    className="eb_fs_18"
                    onClick={() => editPaymentGroup(values.PaymentGroupId)}
                    ghost
                    style={{ border: 'none' }}
                  >
                    <EditOutlined />
                  </Button>
                </span>
              )}
            </div>
            <div className="col-12 no-padding">
              <FormField
                title={i18.Labels.FromAccount}
                name="AccountID"
                component={FormFieldType.select}
                options={payerAccountsRemaped}
                optionsLabel="label"
                optionsValue="value"
                optionsDisabled="disabled"
                validate={required}
                disabled={props.submitting}
                className="mobile"
              />
            </div>
            <FormSpy
              groups={paymentGroups ? paymentGroups : []}
              groupId={values.PaymentGroupId}
              component={GroupPaymentSpy}
              disabled={props.submitting}
            />
            <div className="mb-3 text-right col-12">
              <ButtonGroup>
                {props.buttons.map((item, index) => (
                  <Button
                    id={`GroupPaymentFill${item.title}Button`}
                    key={index}
                    onClick={item.func}
                    disabled={!values?.GroupPayments?.length}
                    {...item.props}
                  >
                    {trb(item.title)}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
            {props.errors && (
              <Alert
                className="mb-3 form-alert"
                message={trb(i18.ErrorTitles.PaymentErrors)}
                description={
                  <ul>
                    {props.errors.map((item, index) => (
                      // <li key={index}>{item.ErrorCaption}</li>
                      <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                    ))}
                  </ul>
                }
                type="error"
              />
            )}
          </form>
        );
      }}
    />
  );
};

GroupPaymentFill.propTypes = {
  data: PropTypes.object.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
