import { useSelector } from 'react-redux';
import { IconEmailStroked, IconMobileStroked } from 'src/Assets/svg';
import { i18, trb } from 'src/Utilities';
import { Button } from 'src/shared';

export const Contacts = () => {
  const { COMPANY } = useSelector((state: any) => state.AMCReducers);

  return (
    <div>
      <div className="col-12 header ">{trb(i18.Labels.NeedHelpContactUs)}</div>
      <div className="col-12 w-100 pr-4 py-2 pl-4">
        <Button
          id="ebBannersPhoneNumberButton"
          type="link"
          href={`tel: ${COMPANY?.PhoneNumber}`}
          icon={<IconEmailStroked />}
          className="px-0 info py-1 h-auto"
        >
          {COMPANY?.PhoneNumber}
        </Button>
        <Button
          id="ebBannersEMailButton"
          type="link"
          href={`mailto: ${COMPANY?.OrgEMail}`}
          className="px-0 info py-1 h-auto"
          icon={<IconMobileStroked />}
        >
          {COMPANY?.OrgEMail}
        </Button>
      </div>
    </div>
  );
};

