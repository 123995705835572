import LoginPageImg from '../images/LoginPageImg.png';

export const LoginImage = ({ size = 372 }) => {
  return (
    <svg
      width={(size * 350) / 372}
      height={size}
      viewBox="0 0 350 372"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M176.886 371.87C272.355 371.87 349.748 294.477 349.748 199.008C349.748 103.54 272.355 26.1469 176.886 26.1469C81.4178 26.1469 4.02515 103.54 4.02515 199.008C4.02515 294.477 81.4178 371.87 176.886 371.87Z"
        fill="white"
      />
      <path
        d="M18.4581 147.226H85.3029C94.916 147.226 102.709 139.434 102.709 129.822C102.709 120.21 94.916 112.418 85.3029 112.418H18.4581C8.84492 112.418 1.05195 120.21 1.05195 129.822C1.05195 139.434 8.84492 147.226 18.4581 147.226Z"
        stroke="#1A1A1A"
        strokeWidth="1.16532"
        strokeMiterlimit="10"
      />
      <path
        d="M84.8809 142.772C92.0339 142.772 97.8325 136.973 97.8325 129.82C97.8325 122.667 92.0339 116.868 84.8809 116.868C77.7279 116.868 71.9292 122.667 71.9292 129.82C71.9292 136.973 77.7279 142.772 84.8809 142.772Z"
        fill="#7154D6"
      />

      <path
        d="M44.3649 235.826C55.6958 235.826 64.8814 226.641 64.8814 215.31C64.8814 203.979 55.6958 194.793 44.3649 194.793C33.0339 194.793 23.8484 203.979 23.8484 215.31C23.8484 226.641 33.0339 235.826 44.3649 235.826Z"
        fill="#7154D6"
      />
      <path
        d="M85.3029 69.4386H18.4581C8.84499 69.4386 1.052 77.2308 1.052 86.843C1.052 96.4551 8.84499 104.247 18.4581 104.247H85.3029C94.9161 104.247 102.709 96.4551 102.709 86.843C102.709 77.2308 94.9161 69.4386 85.3029 69.4386Z"
        stroke="#1A1A1A"
        strokeWidth="1.16532"
        strokeMiterlimit="10"
      />
      <path
        d="M18.8804 99.7929C26.0334 99.7929 31.832 93.9943 31.832 86.8413C31.832 79.6883 26.0334 73.8896 18.8804 73.8896C11.7274 73.8896 5.92871 79.6883 5.92871 86.8413C5.92871 93.9943 11.7274 99.7929 18.8804 99.7929Z"
        stroke="#1A1A1A"
        strokeWidth="1.16532"
        strokeMiterlimit="10"
      />
      <path
        d="M301.076 130.13C317.83 130.13 331.413 116.548 331.413 99.7931C331.413 83.0386 317.83 69.4564 301.076 69.4564C284.321 69.4564 270.739 83.0386 270.739 99.7931C270.739 116.548 284.321 130.13 301.076 130.13Z"
        fill="#7154D6"
      />
      <path
        d="M279.799 265.333C304.892 265.333 325.234 244.991 325.234 219.898C325.234 194.805 304.892 174.463 279.799 174.463C254.707 174.463 234.365 194.805 234.365 219.898C234.365 244.991 254.707 265.333 279.799 265.333Z"
        stroke="#1A1A1A"
        strokeWidth="1.16532"
        strokeMiterlimit="10"
      />
      <path
        d="M272.491 238.437L250.043 221.15C248.671 220.095 248.418 218.128 249.473 216.759C250.529 215.387 252.496 215.134 253.864 216.189L272.298 230.386L305.618 202.108C306.938 200.989 308.915 201.151 310.034 202.47C311.153 203.79 310.991 205.767 309.672 206.886L272.488 238.444L272.491 238.437Z"
        stroke="#1A1A1A"
        strokeWidth="1.16532"
        strokeMiterlimit="10"
      />
      <path
        d="M306.28 265.329C311.224 265.329 315.231 261.321 315.231 256.378C315.231 251.434 311.224 247.426 306.28 247.426C301.336 247.426 297.329 251.434 297.329 256.378C297.329 261.321 301.336 265.329 306.28 265.329Z"
        fill="#7154D6"
      />

      <mask
        id="mask0_3_11233"
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="346"
        height="372"
      >
        <path
          d="M349.748 199.008C349.748 294.476 272.354 371.869 176.886 371.869C81.4185 371.869 4.02515 294.476 4.02515 199.008C4.02515 169.006 49.6287 242.49 63.0764 217.899C86.5485 174.977 89.7363 41.3961 138.774 30.3621C151.036 27.6035 154.048 0.130951 194.581 0.130951C235.114 0.130951 221.93 32.3535 237.901 37.2197C308.743 58.8163 349.755 121.098 349.755 199.005L349.748 199.008Z"
          fill="white"
        />
      </mask>

      <g mask="url(#mask0_3_11233)">
        <mask
          id="mask1_3_11233"
          maskUnits="userSpaceOnUse"
          x="-110"
          y="10"
          width="583"
          height="389"
        >
          <path
            d="M472.713 -11.7896H-109.654V376.454H472.713V-11.7896Z"
            fill="white"
          />
        </mask>

        <g mask="url(#mask1_3_11233)">
          <rect
            x="61.8557"
            y="8.78723"
            width="270.236"
            height="373.188"
            fill="url(#pattern0)"
          />
        </g>
      </g>

      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use
            xlinkHref="#image0_3_11233"
            transform="scale(0.000364431 0.000264131)"
          />
        </pattern>

        <image
          id="image0_3_11233"
          transform="translate(0,0)"
          width="2744"
          height="3786"
          xlinkHref={LoginPageImg}
        />
      </defs>
    </svg>
  );
};
