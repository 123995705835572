import {UTLConstants} from './constants';

const initialState = {}

export const UTLReducers = function (state = initialState, action) {
    switch (action.type) {
        case UTLConstants.POST_UTL_GET_LABELS_REQUEST:
            return {
                ...state,
                send: true,
                ARL: null,
            };
        case UTLConstants.POST_UTL_GET_LABELS_SUCCESS:
            return {
                ...state,
                send: false,
                ARL: action.payload,
            };
        case UTLConstants.POST_UTL_GET_LABELS_ERROR:
            return {
                ...state,
                send: false,
                errors: action.error
            };

        default:
            return state;
    }
};