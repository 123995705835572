import moment from "moment";

export const secondsToMinutes = (s) => {
    let date = new Date(s);
    date.getMinutes();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    return minutes + ':' + (seconds > 9 ? seconds : "0" + seconds);
};

export const secondsToMinuteString = (timeInSeconds) => {
    const duration =  moment.duration(timeInSeconds, "seconds");
    const timeLeft = moment.utc(duration.as('milliseconds')).format('mm:ss');
    return timeLeft;
};