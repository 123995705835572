import { externalPath } from 'src/Config';
import { i18, trb } from 'src/Utilities';
import { Typography } from 'src/shared';
import { IconRedirect } from '../../../Assets/svg';

const { Text } = Typography;
export const CookiesSettings = () => {
  const handleCookiesSettingsClick = () => {
    const language = localStorage.getItem('language');

    window.open(externalPath[language === 'en-GB' ? 'smeBankPrivacyPolicyEn' : 'smeBankPrivacyPolicy']);
  };
  return (
    <Text
      className="cursor-pointer login_footer_text"
      onClick={handleCookiesSettingsClick}
      id="cookiesSettingsButton"
    >
      {trb(i18.Labels.CookiesSettings, { yyyy: new Date().getFullYear() })}
      <span className="ml-1">
        <IconRedirect size={20} />
      </span>
    </Text>
  );
};
