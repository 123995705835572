import { BeneficiariesConstants } from './constants';

const initialState = {};

export const BeneficiariesReducers = function (state = initialState, action: any) {
  switch (action.type) {
    case BeneficiariesConstants.GET_BENEFICIARIES_LIST_REQUEST:
      return {
        ...state,
        loadingBeneficiariesList: true,
        beneficiariesList: null,
      };
    case BeneficiariesConstants.GET_BENEFICIARIES_LIST_SUCCESS:
      return {
        ...state,
        loadingBeneficiariesList: false,
        beneficiariesList: action.payload ?? [],
      };
    case BeneficiariesConstants.GET_BENEFICIARIES_LIST_ERROR:
      return {
        ...state,
        loadingBeneficiariesList: false,
        errors: action.error,
      };
    case BeneficiariesConstants.SET_BENEFICIARIES_LIST:
      return {
        ...state,
        beneficiariesList: action.payload.data,
        beneficiaryAdded: action.payload.beneficiaryAdded,
        beneficiaryDeleted: action.payload.beneficiaryDeleted,
      };
    case BeneficiariesConstants.POST_SIGN_MANDATE_REQUEST:
      return {
        ...state,
        signMandateInfo: null,
      };
    case BeneficiariesConstants.POST_SIGN_MANDATE_SUCCESS:
      return {
        ...state,
        signMandateInfo: action.payload,
      };
    case BeneficiariesConstants.POST_SIGN_MANDATE_ERROR:
      return {
        ...state,
        errors: action.error,
      };
    case BeneficiariesConstants.SET_SIGN_MANDATE_INFO:
      return {
        ...state,
        signMandateInfo: action.payload,
      };

    case BeneficiariesConstants.POST_SIGN_DELETE_MANDATE_REQUEST:
      return {
        ...state,
        signDeleteMandateInfo: null,
      };
    case BeneficiariesConstants.POST_SIGN_DELETE_MANDATE_SUCCESS:
      return {
        ...state,
        signDeleteMandateInfo: action.payload,
      };
    case BeneficiariesConstants.POST_SIGN_DELETE_MANDATE_ERROR:
      return {
        ...state,
        errors: action.error,
      };
    case BeneficiariesConstants.SET_SIGN_DELETE_MANDATE_INFO:
      return {
        ...state,
        signDeleteMandateInfo: action.payload,
      };

    default:
      return state;
  }
};

