import { PMConstants } from './constants';

const initialState = {
  countryCurrenciesResult: null,
  sendPTRInitial: true,
  PTR: {
    PaymentTemplates: [],
    SEPAPaymentTypes: [],
    InitialAccounts: [],
    PaymentGroups: [],
    DestinationAccounts: [],
  },
  checkedIbans: {},
};

export const PMCReducers = function (state = initialState, action) {
  switch (action.type) {
    case PMConstants.POST_PMC_PTR_REQUEST:
      return {
        ...state,
        send: true,
        sendPTR: true,
        PTR: state.PTR,
      };
    case PMConstants.POST_PMC_PTR_SUCCESS:
      return {
        ...state,
        send: false,
        sendPTR: false,
        sendPTRInitial: false,
        PTR: action.payload,
      };
    case PMConstants.POST_PMC_PTR_ERROR:
      return {
        ...state,
        send: false,
        sendPTR: false,
        sendPTRInitial: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_PVR_REQUEST:
      return {
        ...state,
        send: true,
        sendPVR: true,
        PVR: null,
      };
    case PMConstants.POST_PMC_PVR_SUCCESS:
      return {
        ...state,
        send: false,
        sendPVR: false,
        PVR: action.payload,
      };
    case PMConstants.POST_PMC_PVR_ERROR:
      return {
        ...state,
        send: false,
        sendPVR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_APR_REQUEST:
      return {
        ...state,
        send: true,
        sendAPR: true,
        APR: null,
      };
    case PMConstants.POST_PMC_APR_SUCCESS:
      return {
        ...state,
        send: false,
        sendAPR: false,
        APR: action.payload,
      };
    case PMConstants.POST_PMC_APR_ERROR:
      return {
        ...state,
        send: false,
        sendAPR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_APSMSR_REQUEST:
      return {
        ...state,
        send: true,
        sendAPSMSR: true,
        APSMSR: null,
      };
    case PMConstants.POST_PMC_APSMSR_SUCCESS:
      return {
        ...state,
        send: false,
        sendAPSMSR: false,
        APSMSR: action.payload,
      };
    case PMConstants.POST_PMC_APSMSR_ERROR:
      return {
        ...state,
        send: false,
        sendAPSMSR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_APESCR_REQUEST:
      return {
        ...state,
        //send: true,
        sendAPESCR: true,
        APESCR: null,
      };
    case PMConstants.POST_PMC_APESCR_SUCCESS:
      return {
        ...state,
        //send: false,
        sendAPESCR: false,
        APESCR: action.payload,
      };
    case PMConstants.POST_PMC_APESCR_ERROR:
      return {
        ...state,
        //send: false,
        sendAPESCR: false,
        errors: action.error,
      };
    case PMConstants.GET_PMC_PAY_PERSON_TYPES_REQUEST:
      return {
        ...state,
        send: true,
        sendPayPersonTypes: true,
        PayPersonTypes: null,
      };
    case PMConstants.GET_PMC_PAY_PERSON_TYPES_SUCCESS:
      return {
        ...state,
        send: false,
        sendPayPersonTypes: false,
        PayPersonTypes: action.payload,
      };
    case PMConstants.GET_PMC_PAY_PERSON_TYPES_ERROR:
      return {
        ...state,
        send: false,
        sendPayPersonTypes: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_TTG_REQUEST:
      return {
        ...state,
        send: true,
        sendTTG: true,
        TTG: null,
      };
    case PMConstants.POST_PMC_TTG_SUCCESS:
      return {
        ...state,
        send: false,
        sendTTG: false,
        TTG: action.payload,
      };
    case PMConstants.POST_PMC_TTG_ERROR:
      return {
        ...state,
        send: false,
        sendTTG: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_DTR_REQUEST:
      return {
        ...state,
        send: true,
        sendDTR: true,
        DTR: null,
      };
    case PMConstants.POST_PMC_DTR_SUCCESS:
      return {
        ...state,
        send: false,
        sendDTR: false,
        DTR: action.payload,
      };
    case PMConstants.POST_PMC_DTR_ERROR:
      return {
        ...state,
        send: false,
        sendDTR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_DGR_REQUEST:
      return {
        ...state,
        send: true,
        sendDGR: true,
        DGR: null,
      };
    case PMConstants.POST_PMC_DGR_SUCCESS:
      return {
        ...state,
        send: false,
        sendDGR: false,
        DGR: action.payload,
      };
    case PMConstants.POST_PMC_DGR_ERROR:
      return {
        ...state,
        send: false,
        sendDGR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_GGDR_REQUEST:
      return {
        ...state,
        send: true,
        sendGGDR: true,
        GGDR: null,
      };
    case PMConstants.POST_PMC_GGDR_SUCCESS:
      return {
        ...state,
        send: false,
        sendGGDR: false,
        GGDR: action.payload,
      };
    case PMConstants.POST_PMC_GGDR_ERROR:
      return {
        ...state,
        send: false,
        sendGGDR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_SGPVR_REQUEST:
      return {
        ...state,
        send: true,
        sendSGPVR: true,
        SGPVR: null,
      };
    case PMConstants.POST_PMC_SGPVR_SUCCESS:
      return {
        ...state,
        send: false,
        sendSGPVR: false,
        SGPVR: action.payload,
      };
    case PMConstants.POST_PMC_SGPVR_ERROR:
      return {
        ...state,
        send: false,
        sendSGPVR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_SDPVR_REQUEST:
      return {
        ...state,
        send: true,
        sendSDPVR: true,
        SDPVR: null,
      };
    case PMConstants.POST_PMC_SDPVR_SUCCESS:
      return {
        ...state,
        send: false,
        sendSDPVR: false,
        SDPVR: action.payload,
      };
    case PMConstants.POST_PMC_SDPVR_ERROR:
      return {
        ...state,
        send: false,
        sendSDPVR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_SPR_REQUEST:
      return {
        ...state,
        send: true,
        sendSPR: true,
        SPR: null,
      };
    case PMConstants.POST_PMC_SPR_SUCCESS:
      return {
        ...state,
        send: false,
        sendSPR: false,
        SPR: action.payload,
      };
    case PMConstants.POST_PMC_SPR_ERROR:
      return {
        ...state,
        send: false,
        sendSPR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_UTR_REQUEST:
      return {
        ...state,
        send: true,
        sendUTR: true,
        UTR: null,
      };
    case PMConstants.POST_PMC_UTR_SUCCESS:
      return {
        ...state,
        send: false,
        sendUTR: false,
        UTR: action.payload,
      };
    case PMConstants.POST_PMC_UTR_ERROR:
      return {
        ...state,
        send: false,
        sendUTR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_CGPSGNR_REQUEST:
      return {
        ...state,
        send: true,
        sendCGPSGNR: true,
        CGPSGNR: null,
      };
    case PMConstants.POST_PMC_CGPSGNR_SUCCESS:
      return {
        ...state,
        send: false,
        sendCGPSGNR: false,
        CGPSGNR: action.payload,
      };
    case PMConstants.POST_PMC_CGPSGNR_ERROR:
      return {
        ...state,
        send: false,
        sendCGPSGNR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_UPR_REQUEST:
      return {
        ...state,
        send: true,
        sendUPR: true,
        UPR: null,
      };
    case PMConstants.CLEAR_PMC_UPR_DATA:
      return {
        ...state,
        send: false,
        sendUPR: false,
        UPR: null,
      };
    case PMConstants.POST_PMC_UPR_SUCCESS:
      return {
        ...state,
        send: false,
        sendUPR: false,
        UPR: action.payload,
      };
    case PMConstants.POST_PMC_UPR_ERROR:
      return {
        ...state,
        send: false,
        sendUPR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_PUPR_REQUEST:
      return {
        ...state,
        send: true,
        sendPUPR: true,
        PUPR: null,
      };
    case PMConstants.CLEAR_PMC_PUPR_DATA:
      return {
        ...state,
        send: false,
        sendPUPR: false,
        PUPR: null,
      };
    case PMConstants.POST_PMC_PUPR_SUCCESS:
      return {
        ...state,
        send: false,
        sendPUPR: false,
        PUPR: action.payload,
      };
    case PMConstants.POST_PMC_PUPR_ERROR:
      return {
        ...state,
        send: false,
        sendPUPR: false,
        errors: action.error,
      };
    case PMConstants.POST_PMC_RTFGR_REQUEST:
      return {
        ...state,
        send: true,
        sendRTFGR: true,
        RTFGR: null,
      };
    case PMConstants.POST_PMC_RTFGR_SUCCESS:
      return {
        ...state,
        send: false,
        sendRTFGR: false,
        RTFGR: action.payload,
      };
    case PMConstants.POST_PMC_RTFGR_ERROR:
      return {
        ...state,
        send: false,
        sendRTFGR: false,
        errors: action.error,
      };
    case PMConstants.CLEAR_PMC_SUPR_DATA:
      return {
        ...state,
        send: false,
        sendSUPR: false,
        SUPR: null,
      };
    case PMConstants.POST_PMC_SUPR_REQUEST:
      return {
        ...state,
        send: true,
        sendSUPR: true,
        SUPR: null,
      };
    case PMConstants.POST_PMC_SUPR_SUCCESS:
      return {
        ...state,
        send: false,
        sendSUPR: false,
        SUPR: action.payload,
      };
    case PMConstants.POST_PMC_SUPR_ERROR:
      return {
        ...state,
        send: false,
        sendSUPR: false,
        errors: action.error,
      };
    case PMConstants.GET_HISTORICAL_PAYMENTS_REQUEST:
      return {
        ...state,
        send: true,
        sendHistoricalPayments: true,
      };
    case PMConstants.GET_HISTORICAL_PAYMENTS_SUCCESS:
      return {
        ...state,
        send: false,
        sendHistoricalPayments: false,
        historicalPayments: action.payload,
      };
    case PMConstants.GET_HISTORICAL_PAYMENTS_ERROR:
      return {
        ...state,
        send: false,
        sendHistoricalPayments: false,
        errors: action.error,
      };
    case PMConstants.GET_HISTORICAL_PAYMENT_BY_ID_REQUEST:
      return {
        ...state,
        send: true,
        sendHistoricalPayment: true,
        historicalPayment: null,
      };
    case PMConstants.GET_HISTORICAL_PAYMENT_BY_ID_SUCCESS:
      return {
        ...state,
        send: false,
        sendHistoricalPayment: false,
        historicalPayment: action.payload,
      };
    case PMConstants.GET_HISTORICAL_PAYMENT_BY_ID_ERROR:
      return {
        ...state,
        send: false,
        sendHistoricalPayment: false,
        errors: action.error,
      };
    case PMConstants.POST_CHECK_IBAN_MEMBER_REQUEST:
      return {
        ...state,
        send: true,
        sendCheckIBAN: true,
        checkIBANResult: null,
      };
    case PMConstants.POST_CHECK_IBAN_MEMBER_SUCCESS:
      return {
        ...state,
        send: false,
        sendCheckIBAN: false,
        checkIBANResult: action.payload,
      };
    case PMConstants.POST_CHECK_IBAN_MEMBER_ERROR:
      return {
        ...state,
        send: false,
        sendCheckIBAN: false,
        errors: action.error,
      };
    case PMConstants.CLEAR_CHECK_IBAN_MEMBER:
      return {
        ...state,
        send: false,
        sendCheckIBAN: false,
        checkIBANResult: null,
      };
    case PMConstants.CLEAR_HISTORICAL_PAYMENTS:
      return {
        ...state,
        send: true,
        sendHistoricalPayments: false,
        historicalPayments: [],
      };
    case PMConstants.CLEAR_PVR:
      return {
        ...state,
        send: false,
        sendPVR: false,
        PVR: null,
      };
    case PMConstants.POST_FX_RATE_REQUEST:
      return {
        ...state,
        send: true,
        sendGetFxRate: true,
        checkFxRateResult: null,
      };
    case PMConstants.POST_FX_RATE_SUCCESS:
      return {
        ...state,
        send: false,
        sendGetFxRate: false,
        checkFxRateResult: action.payload,
      };
    case PMConstants.POST_FX_RATE_ERROR:
      return {
        ...state,
        send: false,
        sendGetFxRate: false,
        errors: action.error,
      };
    case PMConstants.GET_COUNTRY_CURRENCIES_REQUEST:
      return {
        ...state,
        send: true,
        sendCountryCurrencies: true,
        countryCurrenciesResult: null,
      };
    case PMConstants.GET_COUNTRY_CURRENCIES_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const transformData = (items) => {
        const unique = [...new Set(items.map((item) => item.Country))]; // [ 'A', 'B']
        return unique.map((itm) => {
          return {
            Country: itm,
            Currencies: items.filter((el) => el.Country === itm).map((el) => el.Currency),
            RoutingCodeType: items.find((el) => el.Country === itm)?.RoutingCodeType,
          };
        });
      };
      return {
        ...state,
        send: false,
        sendCountryCurrencies: false,
        countryCurrenciesResult: transformData(action.payload),
      };
    case PMConstants.GET_COUNTRY_CURRENCIES_ERROR:
      return {
        ...state,
        send: false,
        sendCountryCurrencies: false,
        errors: action.error,
      };
    case PMConstants.GET_PAYMENT_PURPOSE_CODE_REQUEST:
      return {
        ...state,
        send: true,
        sendPaymentPurposeCode: true,
        paymentPurposeCode: null,
      };
    case PMConstants.GET_PAYMENT_PURPOSE_CODE_SUCCESS:
      return {
        ...state,
        send: false,
        sendPaymentPurposeCode: false,
        paymentPurposeCode: action.payload,
      };
    case PMConstants.GET_PAYMENT_PURPOSE_CODE_ERROR:
      return {
        ...state,
        send: false,
        errors: action.error,
      };

    case PMConstants.GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_REQUEST:
      return {
        ...state,
        send: true,
        sendBeneficiaryIdentificationCodeTypes: true,
        beneficiaryIdentificationCodeTypes: null,
        beneficiaryIdentificationCodeTypesErrors: null,
      };
    case PMConstants.GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_SUCCESS:
      return {
        ...state,
        send: false,
        sendBeneficiaryIdentificationCodeTypes: false,
        beneficiaryIdentificationCodeTypes: action.payload,
      };
    case PMConstants.GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_ERROR:
      return {
        ...state,
        send: false,
        sendBeneficiaryIdentificationCodeTypes: false,
        beneficiaryIdentificationCodeTypesErrors: action.error,
        beneficiaryIdentificationCodeTypesErrorsCnt: Number(state.beneficiaryIdentificationCodeTypesErrorsCnt ?? 0) + 1,
      };
    case PMConstants.SET_TARGET2_DATA:
      return {
        ...state,
        target2Data: action.payload,
      };
    case PMConstants.GET_IBAN_LENGTH_REQUEST:
      return {
        ...state,
        send: true,
        checkIBANLength: 'loading',
      };
    case PMConstants.GET_IBAN_LENGTH_SUCCESS:
      return {
        ...state,
        send: false,
        checkIBANLength: action.payload,
      };
    case PMConstants.GET_IBAN_LENGTH_ERROR:
      return {
        ...state,
        send: false,
        checkIBANLength: null,
        errors: action.error,
      };
    case PMConstants.GET_CHECK_IBAN_VALID_REQUEST:
      return {
        ...state,
        send: true,
        checkIBANValid: 'loading',
      };
    case PMConstants.GET_CHECK_IBAN_VALID_SUCCESS:
      return {
        ...state,
        send: false,
        checkIBANValid: action.payload,
        checkedIbans: { ...state.checkedIbans, [action.payload.ibanData.iban]: action.payload.ibanValid },
      };
    case PMConstants.GET_CHECK_IBAN_VALID_ERROR:
      return {
        ...state,
        send: false,
        checkIBANValid: null,
        errors: action.error,
      };
    case PMConstants.SET_IBAN_VALID_VALUE:
      return {
        ...state,
        checkIBANValid: action.payload,
      };
    case PMConstants.GET_FOREX_PAYMENTS_REQUEST:
      return {
        ...state,
        forexPaymentsSend: true,
        forexPayments: null,
      };
    case PMConstants.GET_FOREX_PAYMENTS_SUCCESS:
      return {
        ...state,
        forexPaymentsSend: false,
        forexPayments: action.payload,
      };
    case PMConstants.GET_FOREX_PAYMENTS_ERROR:
      return {
        ...state,
        forexPaymentsSend: false,
        errors: action.error,
      };

    default:
      return state;
  }
};
