import React from 'react';
import { useNavigate } from 'react-router-dom';

import { routerPath } from 'src/Config';
import { PageLoader } from 'src/Components';

export const IdentificationError = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    navigate(routerPath.login, {
      state: {
        step: 1,
        isIdentificationError: true,
      },
    });
  }, []);

  return <PageLoader />;
};
