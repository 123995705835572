import React from 'react';

import { dateFormat, ToJavaScriptDate2 } from '../../../../../Utilities';
import { FilePdfOutlined } from '@ant-design/icons';

class ChatMessages extends React.Component {
  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView(true);
    }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  renderMessage = (item, index) => {
    return (
      <div
        className="bbs_chat_message_container"
        key={index}
        ref={(el) => {
          this.messagesEnd = el;
        }}
      >
        <div className={item.IsEmployeeMessage ? 'bbs_chat_message bbs_chat_employee' : 'bbs_chat_message bbs_chat_client'}>
          <div>
            {item.IsEmployeeMessage && (
              <div className="bbs_chat_logo">
                <img
                  src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGO_COLLAPSED.replace('color', 'white')}`}
                  id={'logo'}
                  alt={import.meta.env.VITE_TITLE}
                />
              </div>
            )}
            <div className="d-flex justify-content-between">
              <div className="bbs_chat_time text-right">{ToJavaScriptDate2(item.Date, dateFormat().dateTime)}</div>
            </div>
            <div
              className="bbs_chat_text"
              dangerouslySetInnerHTML={{ __html: item.Message }}
            />
            <div className="d-flex justify-content-between align-items-end">
              <div className="bbs_chat_attachement text-left">
                {item.Attachment && item.Attachment.Id && (
                  <a
                    className="btn btn-link btn-sm p-0 text-left"
                    href={`/a/Messaging/Attachment/${item.Attachment.Id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    download
                  >
                    <FilePdfOutlined className="mr-2" />
                    {item.Attachment.FileName}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }
    return (
      <div className="bbs_chat_message_wrapper">
        {data.Messages && data.Messages.map((item, index) => this.renderMessage(item, index))}
        {data.Message && this.renderMessage(data, 0)}
      </div>
    );
  }
}

export { ChatMessages };
