import { useFormikContext } from 'formik';

import { i18, translationGroups, trb } from '../../../../../Utilities';
import { FormField, Input, ToggleButton } from 'src/shared';
import { PAYER_PAYEE_TYPES } from 'src/constants';
import { IPaymentNewValues } from '../../interfaces';
import { FieldIdentificationType } from '../FieldIdentificationType';

export const PayeeInformation = () => {
  const { values }: { values: IPaymentNewValues } = useFormikContext();

  return (
    <div>
      <FormField
        name="Payment.PayeeType"
        label={trb(i18.Labels.PayeeType)}
        floating={false}
      >
        <ToggleButton
          options={PAYER_PAYEE_TYPES.map((item) => ({
            value: item.value,
            label: trb(translationGroups.PayerPayeeTypes + item.label),
          }))}
        />
      </FormField>
      {values.Payment.PayeeType ? (
        <>
          <FieldIdentificationType
            name="Payment.PayeeIdentTypeCode"
            type={values.Payment.PayeeType}
            label="PayeeIdentificationType"
          />
          <FormField
            name="Payment.PayeeIdentTypeValue"
            label={trb(i18.Labels.PayeeIdentificationCode)}
          >
            <Input />
          </FormField>

          <FormField
            name="Payment.PayeeAddress"
            label={trb(i18.Labels.PayeeAddress)}
          >
            <Input />
          </FormField>
        </>
      ) : null}
    </div>
  );
};
