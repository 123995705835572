import { Col, Row } from 'antd';

import { TermsOfService, Copyright, CookiesSettings } from '.';
import { IconCircle } from 'src/Assets/svg';

export const LoginFooter = () => {
  return (
    <Row
      justify="center"
      align="middle"
      className="login_footer"
      id="footer"
    >
      <Col
        md={12}
        xs={24}
        className="login_footer_left py-3 d-flex justify-content-center"
      >
        <Copyright />
      </Col>
      <Col
        md={12}
        xs={24}
        className="login_footer_right d-flex justify-content-center"
      >
        <TermsOfService />
        <div className="mx-3">
          <IconCircle size={6} />
        </div>
        <CookiesSettings />
      </Col>
    </Row>
  );
};
