import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { i18, trb } from 'src/Utilities';
import { Typography } from 'src/shared';
import { ILoginForm } from 'src/Containers';
import { UACActions, UACConstants } from 'src/Redux';
import { routerPath } from 'src/Config';

import { WaitingResponseLoader, BackButton } from './';

const { Text } = Typography;

interface IProps {
  data: ILoginForm;
  infoText: string;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  title: string;
  subTitle: string;
}

export const ConfirmationBox = ({ data, infoText, setStep, title, subTitle }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { language } = useSelector((state: any) => state.languageReducer);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | undefined>();
  const [id, setId] = useState<NodeJS.Timeout | undefined>();

  const CheckLogin = async () => {
    const result = await dispatch(
      (UACActions as any).postCheckLogin({
        Language: language,
        LoginCode: data.LoginCode,
        LoginSystem: data.LoginType,
        LoginToken: data.LoginSession,
      })
    );

    if (result?.error?.data?.LoginStatusCode || result?.payload?.LoginStatusCode) {
      setStep((p) => p - 1);
    } else if (result && result.type === UACConstants.POST_UAC_CHECK_LOGIN_SUCCESS) {
      if (result.payload.IsAuth) {
        navigate(routerPath.index);
      } else {
        const newTimeoutId = setTimeout(() => {
          CheckLogin();
        }, 1000);

        setTimeoutId(newTimeoutId);
      }
    }
  };

  useEffect(() => {
    const id = setTimeout(() => {
      CheckLogin();
    }, 1000);

    setId(id);

    return () => {
      clearTimeout(id);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleBack = () => {
    setStep((p) => p - 1);
    clearTimeout(id);
    clearTimeout(timeoutId);
  };

  return (
    <>
      <BackButton handleBackButtonClick={handleBack} />
      <div>
        <Text className="login_title">{trb(title)}</Text>
      </div>
      <div>
        <Text className="login_subTitle">{trb(subTitle)}</Text>
      </div>
      <div className="login_form_wrapper">
        <div className="confirmation_wrapper">
          <div>
            <Text className="confirmation_title d-flex justify-content-center">{trb(i18.Labels.AuthenticationCode)}</Text>
          </div>
          <div>
            <Text className="confirmation_code d-flex justify-content-center mb-0">{data.LoginControlCode}</Text>
          </div>
        </div>
        <div className="d-flex justify-content-center mb-5">
          <Text className="login_infoText">{infoText}</Text>
        </div>
      </div>
      <WaitingResponseLoader />
    </>
  );
};
