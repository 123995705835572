import React from 'react';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import { PaymentGroupEdit, PaymentGroupList } from './';
import { PMCActions } from 'src/Redux';
import { history, routerPath } from 'src/Config';
import { arraySearch, checkJsonProp, i18, trb, withRouter } from 'src/Utilities';
import { ContentNoData, ContentLoader, Notification, Container } from 'src/Components';

class PaymentGroups extends React.Component {
  constructor(props) {
    super(props);
    const groupId = checkJsonProp(props, 'location.state.groupId');

    this.state = {
      paymentGroups: null,
      edit: !!groupId,
      groupId: groupId ? groupId : null,
    };

    this.inputFilter = this.inputFilter.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.clickEdit = this.clickEdit.bind(this);
    this.clickExecute = this.clickExecute.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  refreshList() {
    this.props.dispatch(PMCActions.postPTR()).then(() => {
      this.setState({
        paymentGroups: this.props.PTR.PaymentGroups,
      });
    });
  }

  inputFilter = (value) => {
    if (value) {
      const keys = ['Name'];

      this.setState({
        paymentGroups: arraySearch(this.props.PTR.PaymentGroups, keys, value),
      });
    } else {
      this.setState({
        paymentGroups: this.props.PTR.PaymentGroups,
      });
    }
  };

  clickDelete = (id) => {
    const data = {
      ID: id,
    };

    this.props.dispatch(PMCActions.postDGR(data)).then((response) => {
      if (checkJsonProp(response, 'payload.IsSuccessfull')) {
        this.props.dispatch(PMCActions.postPTR()).then((response) => {
          this.setState({
            paymentTemplates: checkJsonProp(response, 'payload.PaymentTemplates'),
          });
        });
        Notification({
          type: 'success',
          message: i18.NotificationTitles.DeleteSuccess,
          description: i18.NotificationMessages.DeleteSuccess,
        });
      } else {
        Notification({
          type: 'error',
          message: i18.NotificationTitles.DeleteError,
          description: i18.NotificationMessages.DeleteError,
        });
      }
    });
  };

  clickEdit = (id) => {
    this.setState({ edit: true, groupId: id });
  };

  clickExecute = (id) => {
    // FIXME: Switch to navigate
    history.push({
      pathname: routerPath.paymentGroup,
      state: {
        groupId: id,
      },
    });
  };

  clickExecuteOne = (templateId) => {
    // FIXME: Switch to navigate
    history.push({
      pathname: routerPath.payments + '/new-payment',
      state: {
        templateId: templateId,
      },
    });
  };

  DeleteFromGroup = (TemplateID, GroupID) => {
    const { dispatch } = this.props;
    const data = {
      TemplateID: TemplateID.toString(),
      GroupID: GroupID.toString(),
    };

    dispatch(PMCActions.postRTFGR(data)).then((response) => {
      if (checkJsonProp(response, 'payload.IsSuccessfull')) {
        Notification({
          type: 'success',
          message: i18.NotificationTitles.DeleteSuccess,
          description: i18.NotificationMessages.DeleteSuccess,
        });
        dispatch(PMCActions.postPTR());
      } else {
        Notification({
          type: 'error',
          message: i18.NotificationTitles.DeleteError,
          description: i18.NotificationMessages.DeleteError,
        });
      }
    });
  };

  clickBackToGroupList = () => {
    this.setState({
      edit: false,
    });

    // FIXME: Switch to navigate
    history.push({
      pathname: routerPath.paymentGroups,
      state: {
        groupId: null,
      },
    });
    this.refreshList();
  };

  render() {
    const { sendPTR } = this.props,
      { paymentGroups } = this.state;

    if (sendPTR) {
      return <ContentLoader />;
    }

    if (!paymentGroups || !paymentGroups.length) {
      return <ContentNoData id="PaymentGroups" />;
    }

    return (
      <>
        <div className="eb_content_subtitle">{trb(i18.ContentMenu.PaymentGroups)}</div>
        <Container>
          {!this.state.edit ? (
            <>
              <div className="mb-3 row w-100">
                <div className="col-12 col-sm-6 col-md-6 offset-md-6 col-sm-12 pr-md-0">
                  <Input
                    id="PaymentGroupsSearchInput"
                    placeholder={trb(i18.PlaceHolders.PaymentGroupSearch)}
                    suffix={<SearchOutlined />}
                    onChange={(e) => this.inputFilter(e.target.value)}
                    disabled={sendPTR}
                  />
                </div>
              </div>
              <PaymentGroupList
                data={paymentGroups ? paymentGroups : []}
                clickDelete={this.clickDelete}
                clickEdit={this.clickEdit}
                clickExecute={this.clickExecute}
              />
            </>
          ) : (
            <PaymentGroupEdit
              PTR={this.props.PTR}
              groupId={this.state.groupId}
              clickDelete={this.DeleteFromGroup}
              clickExecute={this.clickExecuteOne}
              clickExecuteGroup={this.clickExecute}
              clickBackToGroupList={this.clickBackToGroupList}
            />
          )}
        </Container>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { sendPTR, PTR } = state.PMCReducers;
  return {
    sendPTR,
    PTR,
  };
}

const connectedPaymentGroups = withRouter(connect(mapStateToProps)(PaymentGroups));
export { connectedPaymentGroups as PaymentGroups };
