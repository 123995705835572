import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';

import { getToken, IFRAME_USER, iframeGet, JWEActions, requestEBank, requestEBankFile, requestEBankJSON, UACActions } from 'src/Redux';
import { checkDevEnv, Logout } from 'src/Utilities';

const REFRESH_TOKEN_INTERVAL = import.meta.env.VITE_CHECK_TOKEN_TIME;
const MIN_REFRESH_TIME = import.meta.env.VITE_REFRESH_TOKEN_LEFT_TIME;

export const RefreshToken = () => {
  const dispatch = useDispatch();
  const [intervalId, setIntervalID] = useState(-1);
  
  const UACReducers = useSelector((state) => state.UACReducers);
  const IframeStorageReducer = useSelector((state) => state.IframeStorageReducer);
  const JWEReducer = useSelector((state) => state.JWEReducer);
  const { SMSAR } = UACReducers;
  
  const ref = useRef(null);
  
  useImperativeHandle(ref, () => ({
    onTimeoutStorageCheck: storageCheck,
    onTimeoutCheckActivity: checkToken,
  }));
  
  const refreshToken = async ({ RefreshToken }) => {
    try {
      await dispatch(UACActions.getRFT(RefreshToken));
    } catch (error) {
      console.error('Error refreshing token:', error);
      clearInterval(intervalId);
      await Logout();
    }
  };
  
  const checkToken = async () => {
    const user = IframeStorageReducer?.user;
    
    if (!user) {
      return null;
    }
    
    const { Bearer } = user;
    
    if (!Bearer) {
      return;
    }
    
    const BearerJson = jwtDecode(Bearer);
    const { exp, UserCode } = BearerJson;
    const dateExp = new Date(exp * 1000);
    const lastTokenTime = dateExp.getTime() - new Date().getTime();
    Sentry.setUser({ username: UserCode });
    
    if (lastTokenTime <= MIN_REFRESH_TIME) {
      refreshToken(user);
    }
  };
  
  const storageCheck = () => {
    if (!IframeStorageReducer?.user) {
      dispatch(iframeGet({ key: IFRAME_USER }));
      setTimeout(() => ref?.current?.onTimeoutStorageCheck(), 500);
    }
    
    const { user } = IframeStorageReducer;
    const token = getToken(user);
    
    if (token) {
      if (requestEBank) {
        requestEBank.defaults.headers.common.Authorization = token;
        requestEBankJSON.defaults.headers.common.Authorization = token;
        requestEBankFile.defaults.headers.common.Authorization = token;
      }
      
      if (!JWEReducer.loading && user.Jwe && !JWEReducer.jweVerified && SMSAR?.Jwe !== user.Jwe && JWEReducer.isRemote === null) {
        if (checkDevEnv) {
          const data = {
            user,
            JWEReducer,
            SMSAR,
          };
          Sentry.captureMessage('JWEActions.postVerifyJWE', data);
        }
        dispatch(JWEActions.postVerifyJWE(user));
      }
    }
  };
  
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      checkToken();
    }
  };
  
  useEffect(() => {
    setIntervalID(setInterval(() => ref?.current?.onTimeoutCheckActivity(), REFRESH_TOKEN_INTERVAL));
    
    storageCheck();
    
    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  
  return null;
};
