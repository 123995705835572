import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { routerPath } from 'src/Config';
import { MessagingActions } from 'src/Redux';
import { IconLetter } from 'src/Assets/svg/Letter';
import { Badge } from 'src/shared';

export const UserMessages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { MessagesCount, sendMessagesCountInitial } = useSelector((state) => state.MessagingReducers);

  useEffect(() => {
    dispatch(MessagingActions.getNewMessagesCount());
  }, []);

  const gotoMessage = () => {
    if (MessagesCount.CountBroadCast > 0) {
      navigate(`${routerPath.messages}/${routerPath.messagesBroadCast}`); //
    } else if (MessagesCount.CountChat > 0) {
      navigate(`${routerPath.messages}/${routerPath.messagesInbox}`);
    } else {
      navigate(`${routerPath.messages}/${routerPath.messagesNew}`);
    }
  };

  if (sendMessagesCountInitial) {
    return null;
  }

  return (
    <div className="eb_header_messages_wrapper">
      <Badge
        id="GoToMessageButton"
        offset={[-10]}
        count={MessagesCount?.CountChat + MessagesCount?.CountBroadCast}
        color="#EF3B3B"
      >
        <div
          className="eb_header_messages"
          onClick={gotoMessage}
        >
          <IconLetter size={32} />
        </div>
      </Badge>
    </div>
  );
};
