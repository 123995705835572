import React from 'react';
import { Alert } from 'antd';
import { connect } from 'react-redux';
import { KYCActions } from '../../../../Redux/Services';

import { checkJsonExist, checkJsonProp, i18, trb, dateFormat, formatCurrentDate, formatDate, Roboto, RobotoBold } from '../../../../Utilities';
import { ContentLoader, ContentNoData } from '../../../../Components';

import { SettingsKYCRenewPerson, SettingsKYCRenewCompany } from './';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

const classifikatorsMap = (obj) =>
  obj
    ? Object.entries(obj).map((item) => {
        return { label: item[1], value: item[0] };
      })
    : null;

class SettingsKYCRenewContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 0,
    };

    this.createAndSendPdf = this.createAndSendPdf.bind(this);
  }

  createAndSendPdf = () => {
    const { KYC } = this.props,
      date = formatDate(formatCurrentDate(), dateFormat().dateTime),
      KYCtype = checkJsonProp(KYC, 'data.clientProfile.0.clientProfileType'),
      CISO = checkJsonProp(KYC, 'classifikators.data.CISO'),
      ADRT = checkJsonProp(KYC, 'classifikators.data.ADRT'),
      DIST = checkJsonProp(KYC, 'classifikators.data.DIST'),
      PHON = checkJsonProp(KYC, 'classifikators.data.PHON'),
      MAILL = checkJsonProp(KYC, 'classifikators.data.MAILL'),
      DOCS = checkJsonProp(KYC, 'classifikators.data.DOCS');

    let pdfMap = {};
    switch (KYCtype) {
      case 'CPROF1':
        pdfMap.headerStr = 'JUNGTINĖ CENTRINĖ KREDITO UNIJA';
        pdfMap.subHeaderStr = 'FIZINIO ASMENS ANKETA';
        pdfMap.noteStr =
          'ANKETA SUDARYTA ATSIŽVELGIANTĮ LIETUVOS RESPUBLIKOS PINIGŲ PLOVIMO IR TERORISTŲ FINANSAVIMO PREVENCIJOSĮSTATYMO BEI KITŲ TEISĖS AKTŲ NUOSTATAS, KURIOMISSIEKIAMA' +
          'UŽKIRSTI KELIĄ PINIGŲ PLOVIMUI IR (AR)TERORISTŲ FINANSAVIMUI, PARENGTA VADOVAUJANTISTARPTAUTINIA Tekstai, media IS BANKINĖS VEIKLOSSTANDARTAISIR PRINCIPU „PAŽINK' +
          'SAVO KLIENTĄ“.';
        pdfMap.clientName = checkJsonProp(KYC, 'data.clientProfile.0.data.clientName');
        pdfMap.clientSurname = checkJsonProp(KYC, 'data.clientProfile.0.data.clientTitle');
        pdfMap.clientId = checkJsonProp(KYC, 'data.clientProfile.0.data.clientId');
        pdfMap.clientBirthDate = checkJsonProp(KYC, 'data.clientProfile.0.data.clientBirthDate');
        pdfMap.clientRegCountry = CISO[checkJsonProp(KYC, 'data.clientProfile.0.data.clientRegCountry')];
        pdfMap.clientAddress = checkJsonProp(KYC, 'data.clientAddress');
        pdfMap.clientPhone = checkJsonProp(KYC, 'data.clientPhone');
        pdfMap.clientMail = checkJsonProp(KYC, 'data.clientMail');
        pdfMap.clientDocs = checkJsonProp(KYC, 'data.clientDocs.0');
        break;
      case 'CPROF7':
        pdfMap.subHeaderStr = 'TEISINIS ASMENS ANKETA';
        break;
      default:
        pdfMap.subHeaderStr = 'FIZINIO ASMENS ANKETA';
    }
    pdfMap.data = formatDate(formatCurrentDate());

    if (KYCtype == 'CPROF1') {
      const doc = new jsPDF();
      doc.addFileToVFS('Roboto.ttf', Roboto);
      doc.addFileToVFS('RobotoBold.ttf', RobotoBold);
      doc.addFont('Roboto.ttf', 'Roboto', 'normal');
      doc.addFont('RobotoBold.ttf', 'RobotoBold', 'bold');

      doc.autoTable({
        theme: 'plain',
        styles: {
          font: 'RobotoBold',
          fontStyle: 'bold',
          halign: 'center',
          lineWidth: 0,
          cellPadding: 2,
        },
        startY: doc.autoTable.previous.finalY + 10,
        margin: { left: 7, right: 7 },
        headStyles: {
          fontSize: 12,
        },
        bodyStyle: {
          fontSize: 10,
        },
        head: [[{ content: pdfMap.headerStr }]],
        body: [[{ content: pdfMap.subHeaderStr }]],
      });

      doc.autoTable({
        theme: 'plain',
        styles: {
          font: 'RobotoBold',
          fontStyle: 'bold',
          halign: 'center',
          lineColor: [210, 210, 210],
          lineWidth: 0.1,
          fontSize: 7,
          cellPadding: 1.5,
        },
        startY: doc.autoTable.previous.finalY + 2,
        body: [[{ content: date }]],
      });

      doc.autoTable({
        theme: 'plain',
        styles: {
          font: 'Roboto',
          fontSize: 6.5,
          cellPadding: 0,
        },
        rowPageBreak: 'auto',
        startY: doc.autoTable.previous.finalY + 3,
        body: [[{ content: pdfMap.noteStr }]],
      });

      jsPDF.autoTableSetDefaults({
        theme: 'plain',
        columnStyles: {
          0: {
            font: 'RobotoBold',
            fontStyle: 'bold',
          },
          2: {
            cellWidth: 0,
            lineWidth: 0.05,
            cellPadding: 0,
          },
          3: {
            font: 'RobotoBold',
            fontStyle: 'bold',
          },
          5: {
            cellWidth: 0,
            lineWidth: 0.05,
            cellPadding: 0,
          },
          6: {
            font: 'RobotoBold',
            fontStyle: 'bold',
          },
          8: {
            cellWidth: 0,
            lineWidth: 0.05,
            cellPadding: 0,
          },
          9: {
            font: 'RobotoBold',
            fontStyle: 'bold',
          },
          11: {
            cellWidth: 0,
            lineWidth: 0.05,
            cellPadding: 0,
          },
          12: {
            font: 'RobotoBold',
            fontStyle: 'bold',
          },
        },
        tableLineWidth: 0.1,
        tableLineColor: [210, 210, 210],
        styles: {
          font: 'Roboto',
          lineWidth: 0.1,
          fontSize: 7,
          cellPadding: 1.5,
        },
      });

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        tableLineWidth: 0,
        styles: {
          cellPadding: 0,
          minCellHeight: 8,
          valign: 'bottom',
        },
        body: [[{ content: 'Informacija apie klientą' }]],
      });

      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 2,
        body: [
          [
            { content: 'Vardas', styles: { cellWidth: 12 } },
            { content: pdfMap.clientName },
            { content: '' },
            { content: 'Pavardė', styles: { cellWidth: 14 } },
            { content: pdfMap.clientSurname },
          ],
        ],
      });

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        body: [
          [
            { content: 'Asmens kodas (gim. data - užsieniečiui)', styles: { cellWidth: 50 } },
            { content: pdfMap.clientId, styles: { cellWidth: 33.9 } },
            { content: '' },
            { content: 'Gimimo data', styles: { cellWidth: 18 } },
            { content: pdfMap.clientBirthDate },
          ],
        ],
      });

      pdfMap.clientAddress.forEach((rowAddress) => {
        doc.autoTable({
          startY: doc.autoTable.previous.finalY,
          body: [[{ content: 'Pilietybė/ Registracijos valstybė' }]],
        });
        doc.autoTable({
          startY: doc.autoTable.previous.finalY,
          body: [
            [
              { content: 'Adreso tipas', styles: { cellWidth: 18 } },
              { content: ADRT[rowAddress.addressType] },
              { content: '' },
              { content: 'Valstybė', styles: { cellWidth: 14 } },
              { content: CISO[rowAddress.data.addressCountry] },
              { content: '' },
              { content: 'Savivaldybė', styles: { cellWidth: 16 } },
              { content: DIST[rowAddress.data.addressMunicipality] },
            ],
          ],
        });
        doc.autoTable({
          startY: doc.autoTable.previous.finalY,
          body: [
            [
              { content: 'Miestas/Kaimas', styles: { cellWidth: 22 } },
              { content: rowAddress.data.addressCity },
              { content: '' },
              { content: 'Gatvė', styles: { cellWidth: 10 } },
              { content: rowAddress.data.addressHouse },
              { content: '' },
              { content: 'Namo', styles: { cellWidth: 10 } },
              { content: rowAddress.data.addressHouse },
              { content: '' },
              { content: 'Buro', styles: { cellWidth: 10 } },
              { content: rowAddress.data.addressFlat },
              { content: '' },
              { content: 'Pašto kodas', styles: { cellWidth: 20 } },
              { content: rowAddress.data.addressPostCode },
            ],
          ],
        });
      });

      pdfMap.clientPhone.forEach((rowPhone) => {
        doc.autoTable({
          startY: doc.autoTable.previous.finalY,
          body: [
            [
              { content: 'Telefono numerio tipas', styles: { cellWidth: 30 } },
              { content: PHON[rowPhone.clientPhoneType] },
              { content: '' },
              { content: 'Telefono numeris (+3706XXXXXXX)', styles: { cellWidth: 44 } },
              { content: rowPhone.data.clientPhoneNo },
            ],
          ],
        });
      });

      pdfMap.clientMail.forEach((rowMail) => {
        doc.autoTable({
          startY: doc.autoTable.previous.finalY,
          body: [
            [
              { content: 'Elektroninio pašto tipas', styles: { cellWidth: 30 } },
              { content: MAILL[rowMail.clientEmailType] },
              { content: '' },
              { content: 'Elektroninio pašto adresa', styles: { cellWidth: 34 } },
              { content: rowMail.data.clientEmail },
            ],
          ],
        });
      });

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        tableLineWidth: 0,
        styles: {
          cellPadding: 0,
          minCellHeight: 8,
          valign: 'bottom',
        },
        body: [[{ content: 'Asmens tapatybę patvirtinantis dokumentas' }]],
      });

      doc.autoTable({
        startY: doc.autoTable.previous.finalY + 2,
        body: [
          [
            { content: 'Dokumento tipas', styles: { cellWidth: 22 } },
            { content: DOCS[pdfMap.clientDocs.clientDocsType] },
            { content: '' },
            { content: 'Dokumento numeris', styles: { cellWidth: 26 } },
            { content: pdfMap.clientDocs.data.clientDocNo },
            { content: '' },
            { content: 'Dokumento išdavimo šalis', styles: { cellWidth: 34 } },
            { content: CISO[pdfMap.clientDocs.data.clientDocCountry] },
          ],
        ],
      });

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        body: [
          [
            { content: 'Dokumento išdavimo vieta', styles: { cellWidth: 34 } },
            { content: pdfMap.clientDocs.data.clientDocIssuePlace },
            { content: '' },
            { content: 'Dokumento išdavimo data', styles: { cellWidth: 34 } },
            { content: pdfMap.clientDocs.data.clientDocIssueDate },
            { content: '' },
            { content: 'Dokumento galiojimo data', styles: { cellWidth: 34 } },
            { content: pdfMap.clientDocs.data.clientDocValidDate },
          ],
        ],
      });

      /*{ table: {
                widths: ['auto', '*'], body: [
                    [{ text: 'Gimimo liudijimo informacija', border: [true, true, false, true]},
                     { text: 'informacija123', border: [false, true, true, true], bold: false}]
              ]}, style: 'table'},
            { table: {
                widths: [120, '*', 120, '*'], body: [
                    [{ text: 'Gimimo liudijimo Nr.', border: [true, true, false, true]},
                     { text: 'Nr123', border: [false, true, true, true], bold: false},
                     { text: 'Gimimo liudijimo išdavimo data', border: [true, true, false, true]},
                     { text: 'data123', border: [false, true, true, true], bold: false}]
              ]},style: 'table'},*/

      doc.autoTable({
        startY: doc.autoTable.previous.finalY,
        columnStyles: {
          0: {
            valign: 'middle',
            font: 'RobotoBold',
            fontStyle: 'bold',
            cellWidth: 180 / 2,
          },
        },
        styles: {
          minCellHeight: 14,
          lineWidth: 0.1,
        },
        rowPageBreak: 'auto',
        body: [[{ content: 'UNIJA (ATSTOVO PAREIGOS, VARDAS IR PAVARDĖ, PARAŠAS, ANTSPAUDAS)' }, { content: 'ASMUO (VARDAS IR PAVARDĖ, PARAŠAS)' }]],
      });

      //doc.save("KYC.pdf");
      const blob = new Blob([doc.output('blob')], { type: 'application/pdf' }),
        form = new FormData();
      form.append('model', JSON.stringify({ subject: 'KYC', message: 'KYC pdf' }));
      form.append('file', blob, 'KYC.pdf');
      //dispatch(MessagingActions.postCreate(form));
    }
  };

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(KYCActions.getKYC());
  }

  prevStep = () => {
    this.setState((prevState) => ({
      currentStep: prevState.currentStep - 1,
    }));
  };

  nextStep = (e) => {
    const { KYC } = this.props;
    Object.assign(KYC.data, e);
    this.setState((prevState) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  submit = (e) => {
    const { dispatch, KYC } = this.props;
    Object.assign(KYC.data, e);
    const KYCtype = checkJsonProp(KYC, 'data.clientProfile.0.clientProfileType');

    if (checkJsonExist(KYC, 'data.clientProfile.0.data.сlientIdv')) delete KYC.data.clientProfile[0].data.сlientIdv;
    if (checkJsonExist(KYC, 'data.clientProfile.0.data.ClientEmployment')) delete KYC.data.clientProfile[0].data.ClientEmployment;
    if (checkJsonExist(KYC, 'data.clientPoliticsPosition.0.data.ClientPoliticsPosList')) delete KYC.data.clientPoliticsPosition[0].data.ClientPoliticsPosList;
    if (checkJsonExist(KYC, 'data.clientPoliticsPosition.0.clientPoliticsPosList')) delete KYC.data.clientPoliticsPosition[0].clientPoliticsPosList;
    if (checkJsonExist(KYC, 'data.clientPlanCashTurn.0.clientPlanTurn')) delete KYC.data.clientPlanCashTurn[0].clientPlanTurn;
    if (checkJsonExist(KYC, 'data.clientEmpComp.0.enabled')) delete KYC.data.clientEmpComp[0].enabled;
    if (checkJsonExist(KYC, 'data.clientPcComp.0.enabled')) delete KYC.data.clientPcComp[0].enabled;

    if (checkJsonExist(KYC, 'data.Approve')) delete KYC.data.Approve;
    if (checkJsonExist(KYC, 'data.clientPoliciticsValid')) delete KYC.data.clientPoliciticsValid;
    if (checkJsonExist(KYC, 'data.clientEmploymentValid')) delete KYC.data.clientEmploymentValid;
    if (checkJsonExist(KYC, 'data.clientIncome.0.clientIncomeValid')) delete KYC.data.clientIncome[0].clientIncomeValid;

    if (checkJsonExist(KYC, 'data.clientProfile.0.data.clientTaxPayerLtBool')) delete KYC.data.clientProfile[0].data.clientTaxPayerLtBool;
    if (checkJsonExist(KYC, 'data.clientProfile.0.data.clientTaxPayerLtStr')) delete KYC.data.clientProfile[0].data.clientTaxPayerLtStr;
    if (checkJsonExist(KYC, 'data.clientRep.0.data.clientRepPhoneNoPrefix')) delete KYC.data.clientRep[0].data.clientRepPhoneNoPrefix;
    if (checkJsonExist(KYC, 'data.clientRep.0.data.clientRepPhoneNoStr')) delete KYC.data.clientRep[0].data.clientRepPhoneNoStr;
    if (checkJsonExist(KYC, 'data.clientCeo.0.data.clientCeoPhonePrefix')) delete KYC.data.clientCeo[0].data.clientCeoPhonePrefix;
    if (checkJsonProp(KYC, 'data.clientRep.0.data.clientRepPhoneNo') == 0 || checkJsonProp(KYC, 'data.clientRep.0.data.clientRepPhoneNo') == '')
      delete KYC.data.clientRep[0].data.clientRepPhoneNo;
    if (checkJsonExist(KYC, 'data.clientCeo.0.data.clientCeoPhoneStr')) delete KYC.data.clientCeo[0].data.clientCeoPhoneStr;
    if (checkJsonProp(KYC, 'data.clientPhone'))
      KYC.data.clientPhone.forEach((item) => {
        if (checkJsonExist(item, 'data.clientPhoneNoPrefix')) delete item.data.clientPhoneNoPrefix;
        if (checkJsonExist(item, 'data.clientPhoneNoStr')) delete item.data.clientPhoneNoStr;
      });
    if (checkJsonProp(KYC, 'data.clientBusPartner'))
      KYC.data.clientBusPartner = KYC.data.clientBusPartner.filter((item) => {
        if (checkJsonExist(item, 'allNeed')) delete item.allNeed;
        return !checkJsonProp(item, 'data') || Object.keys(checkJsonProp(item, 'data')).length == 0 ? false : true;
      });
    if (checkJsonProp(KYC, 'data.clientAccountBank'))
      KYC.data.clientAccountBank = KYC.data.clientAccountBank.map((item) => {
        if (checkJsonExist(item, 'allNeed')) delete item.allNeed;
        return item;
      });

    if (checkJsonProp(KYC, 'data.clientProfile.0.data.clientPoliticsMember') == 0) {
      delete KYC.data.clientPolitics;
      delete KYC.data.clientPoliticsPosition;
    }
    if (checkJsonExist(KYC, 'data.clientPolitics'))
      KYC.data.clientPolitics.forEach((item) => {
        if (checkJsonProp(item, 'clientPoliticsList') == 'PEPLIST1') {
          if (KYCtype == 'CPROF1') item.data = { clientPoliticsName: '', clientPoliticsCountry: '', clientPoliticsLink: '' };
          if (KYCtype == 'CPROF7') KYC.data.clientPolitics = [];
        }
      });
    if (checkJsonProp(KYC, 'data.clientAccountBank'))
      KYC.data.clientAccountBank = KYC.data.clientAccountBank.filter((item) => {
        return !checkJsonProp(item, 'data') || Object.keys(checkJsonProp(item, 'data')).length === 0 ? false : true;
      });

    KYC.data = JSON.parse(
      JSON.stringify(KYC.data, (key, value) => {
        if (value === 0 || value === false) return '0';
        if (value === 1 || value === true) return '1';
        if (typeof value === 'number') return value.toString();
        if (value === null) return '';
        return value;
      })
    );

    if (checkJsonProp(KYC, 'data.clientProfile.0.data.clientTaxPayerOther') == 0 && checkJsonExist(KYC, 'data.clientTaxPayer')) KYC.data.clientTaxPayer = [];
    if (checkJsonProp(KYC, 'data.clientProfile.0.data.whoOpenAccount') == 1 && checkJsonExist(KYC, 'data.clientRep')) KYC.data.clientRep = [];
    if (checkJsonProp(KYC, 'data.clientRep.0.data') && Object.keys(checkJsonProp(KYC, 'data.clientRep.0.data')).length === 0) KYC.data.clientRep = [];
    if (checkJsonProp(KYC, 'data.clientProfile.0.data.clientIdv') != 1 && checkJsonExist(KYC, 'data.clientPcComp')) delete KYC.data.clientPcComp;
    if (checkJsonProp(KYC, 'data.clientProfile.0.data.clientEmployee') != 1 && checkJsonExist(KYC, 'data.clientEmpComp')) delete KYC.data.clientEmpComp;
    if (checkJsonProp(KYC, 'data.clientProfile.0.data.clientCivilServant') != 1 && checkJsonExist(KYC, 'data.clientProfile.0.data.otherActivity'))
      delete KYC.data.clientProfile[0].data.otherActivity;
    if (
      checkJsonProp(KYC, 'data.clientEmpComp') &&
      checkJsonProp(KYC, 'data.clientEmpComp').length == 1 &&
      Object.keys(checkJsonProp(KYC, 'data.clientEmpComp.0.data')).length == 0
    )
      KYC.data.clientEmpComp = [];
    if (checkJsonProp(KYC, 'data.clientPcComp') && checkJsonProp(KYC, 'data.clientPcComp').length == 1 && Object.keys(checkJsonProp(KYC, 'data.clientPcComp.0.data')).length == 0)
      KYC.data.clientPcComp = [];

    dispatch(KYCActions.postKYC(KYC.data));
  };

  render() {
    const { KYC, sendKYC, MALR, sendMALR } = this.props;

    if (sendKYC === true && (KYC === undefined || KYC === null)) return <ContentLoader />;
    if (KYC === undefined || KYC === null || checkJsonProp(KYC, 'data') == null) return <ContentNoData id="KYCRenew" />;
    if (!MALR || sendMALR) {
      return <ContentLoader />;
    }
    /* if (checkJsonProp(KYC, 'ErrorCode') || checkJsonProp(KYC, 'ErrorMessage')) {
      return <pre>{JSON.stringify(KYC, undefined, 4)}</pre>;
    } */
    if (checkJsonProp(KYC, 'IsSuccessfull')) {
      const message =
        checkJsonProp(KYC, 'Status') == 'WaitingForApproval'
          ? trb(i18.NotificationMessages.KycReniewSuccsessfullyAndWaitingFoAppruving)
          : checkJsonProp(KYC, 'Status') == 'Approved'
          ? trb(i18.NotificationMessages.KycReniewSuccsessfullyAndValidTill) + ' ' + formatDate(checkJsonProp(KYC, 'ValidTill'))
          : trb(i18.NotificationTitles.KycSuccess);

      return (
        <Alert
          className="mb-3"
          message={trb(i18.NotificationTitles.KycSuccess)}
          description={message}
          type="success"
          showIcon
        />
      );
    }

    const ActiveAccount = checkJsonProp(MALR, 'ManagedAccounts') != null ? MALR.ManagedAccounts.find((x) => x.IsActive === true) : null;

    if (!ActiveAccount) {
      return <ContentNoData id="KYCRenewActiveAccount" />;
    }
    if (
      (checkJsonProp(ActiveAccount, 'Type') == 'b' && !checkJsonProp(ActiveAccount, 'IsAdmin')) ||
      (checkJsonProp(ActiveAccount, 'Type') == 'p' && checkJsonProp(ActiveAccount, 'ID') != checkJsonProp(MALR, 'ID'))
    ) {
      return (
        <Alert
          className="mb-3"
          message={trb(i18.NotificationTitles.KycUpdateYouDontHavePermission)}
          description={trb(i18.NotificationMessages.KycUpdateYouDontHavePermission)}
          type="info"
          showIcon
        />
      );
    }

    const CISO = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.CISO')),
      DOCS = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.DOCS')),
      DIST = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.DIST')),
      PHON = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.PHON')),
      PEN = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.PEN')),
      PEPLIST = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.PEPLIST')),
      PEPLINK = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.PEPLINK')),
      EMPC = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.EMPC')),
      PCC = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.PCC')),
      CBB = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.CBB')),
      ADRT = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.ADRT')),
      CBBSN = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.CBBSN')),
      CBREGZ = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.CBREGZ')),
      CBREG = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.CBREG')),
      CBP = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.CBP')),
      TAXC = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.TAXC')),
      ACC = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.ACC')),
      BENEF = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.BENEF')),
      MAILL = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.MAILL')),
      CEOC = checkJsonProp(KYC, 'classifikators.data.CEOC'),
      REPC = checkJsonProp(KYC, 'classifikators.data.REPC'),
      PLTUR = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.PLTUR')),
      PLCASH = classifikatorsMap(checkJsonProp(KYC, 'classifikators.data.PLCASH')),
      PLTURPerson = PLTUR.filter((item) => /R[1,3,5,6]$/.test(item.value)),
      PLTURCompany = PLTUR.filter((item) => /R([2,5,7,8]|10|11)$/.test(item.value)),
      PLCASHPerson = PLCASH.filter((item) => /H[1,3,4,5]$/.test(item.value)),
      PLCASHCompany = PLCASH.filter((item) => /H[2,4,6,7]$/.test(item.value)),
      KYCtype = checkJsonProp(KYC, 'data.clientProfile.0.clientProfileType'),
      Permissons = true, //checkJsonProp(MALR, 'ManagedAccounts') && checkJsonProp(MALR, 'ManagedAccounts').length && !checkJsonProp(checkJsonProp(MALR, 'ManagedAccounts').filter((item) => item.ClientCode == ClientId), '0.IsAdmin');
      classifikators = {
        CISO: CISO,
        DOCS: DOCS,
        DIST: DIST,
        PHON: PHON,
        PEN: PEN,
        PEPLIST: PEPLIST,
        PEPLINK: PEPLINK,
        EMPC: EMPC,
        PCC: PCC,
        CBB: CBB,
        ADRT: ADRT,
        CBBSN: CBBSN,
        CBREGZ: CBREGZ,
        CBREG: CBREG,
        CBP: CBP,
        TAXC: TAXC,
        ACC: ACC,
        BENEF: BENEF,
        MAILL: MAILL,
        CEOC: CEOC,
        REPC: REPC,
        PLCASH: KYCtype == 'CPROF1' ? PLCASHPerson : KYCtype == 'CPROF7' ? PLCASHCompany : PLCASH,
        PLTUR: KYCtype == 'CPROF1' ? PLTURPerson : KYCtype == 'CPROF7' ? PLTURCompany : PLTUR,
      };

    switch (KYCtype) {
      case 'CPROF1':
        return (
          <SettingsKYCRenewPerson
            permissons={Permissons}
            classifikators={classifikators}
            data={KYC.data}
            currentStep={this.state.currentStep}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            submit={this.submit}
          />
        );
      case 'CPROF7':
        return (
          <SettingsKYCRenewCompany
            permissons={Permissons}
            classifikators={classifikators}
            data={KYC.data}
            currentStep={this.state.currentStep}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            submit={this.submit}
          />
        );
      default:
        return (
          <SettingsKYCRenewPerson
            permissons={Permissons}
            classifikators={classifikators}
            data={KYC.data}
            currentStep={this.state.currentStep}
            prevStep={this.prevStep}
            nextStep={this.nextStep}
            submit={this.submit}
          />
        );
    }
  }
}

function mapStateToProps(state) {
  const { KYC, sendKYC } = state.KYCReducers,
    { MALR, sendMALR } = state.UACReducers;

  return {
    KYC,
    sendKYC,
    MALR,
    sendMALR,
  };
}

const connectedSettingsKYCRenewContainer = connect(mapStateToProps)(SettingsKYCRenewContainer);
export { connectedSettingsKYCRenewContainer as SettingsKYCRenewContainer };
