import createDecorator from "final-form-calculate";

export const NewPaymentDecorator = () => {
    return [
        createDecorator(
            {
                field: 'Payment.Template',
                updates: () => {
                    return {};
                }
            }
        )
    ];
};