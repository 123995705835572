import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessagingActions } from 'src/Redux/Services/Messaging';
import { getClassificators, i18, objectToArray, required, requiredDraftJSField, trb } from 'src/Utilities';
import { Notification } from 'src/Components/Notification';
import { routerPath } from 'src/Config';
import { stateToHTML } from 'draft-js-export-html';
import { Field, Form } from 'react-final-form';
import { EditorState, convertToRaw } from 'draft-js';
import { ChatInput, FormField, FormFieldType } from 'src/Components';
import { Button } from 'antd';

export const NewContainer = ({ onTabChange }) => {
  const [initialValues] = React.useState({
    subject: null,
    message: EditorState.createEmpty(),
  });
  const dispatch = useDispatch();
  const { Create } = useSelector((state) => state.MessagingReducers);

  useEffect(() => {
    if (Create) {
      const { IsSuccessfull } = Create;
      Notification({
        type: IsSuccessfull ? 'success' : 'error',
        message: i18.NotificationTitles[IsSuccessfull ? 'ChatCreatedSuccessful' : 'ChatCreatedError'],
        description: i18.NotificationMessages[IsSuccessfull ? 'ChatCreatedSuccessful' : 'ChatCreatedError'],
      });

      dispatch(MessagingActions.resetMessageCreate());
      onTabChange(routerPath.messagesInbox);
    }
  }, [Create]);

  const submit = async (values) => {
    const data = {
      ...values,
      message: stateToHTML(values.message.getCurrentContent()),
    };

    const form = new FormData();
    form.append('model', JSON.stringify(data));

    await dispatch(MessagingActions.postCreate(form));
  };

  const MSGL = getClassificators() ? getClassificators().MSGL : {};

  return (
    <div className="form w-100">
      <Form
        onSubmit={submit}
        initialValues={initialValues}
        render={({ handleSubmit, submitting }) => {
          return (
            <form
              className="mb-3 row"
              onSubmit={handleSubmit}
              id="NewMessageForm"
            >
              <div className="form-part col-12">
                <FormField
                  title={i18.Chat.MessageSubject}
                  name="subject"
                  component={FormFieldType.select}
                  options={objectToArray(MSGL)}
                  optionsValue="value"
                  optionsLabel="label"
                  validate={required}
                />
              </div>
              <div className="form-part col-12">
                <Field
                  name="message"
                  validate={requiredDraftJSField}
                >
                  {({ input, meta }) => (
                    <ChatInput
                      editorState={input.value}
                      setEditorState={(state) => input.onChange(state)}
                      meta={meta}
                      name="message"
                    />
                  )}
                </Field>
              </div>
              <div className="col-12">
                <div className="mb-3 text-right d-none d-sm-block">
                  <Button
                    id="NewMessageSubmitButtonLG"
                    type="primary"
                    htmlType="submit"
                    loading={submitting}
                  >
                    {trb(i18.Buttons.NewMessageApprove)}
                  </Button>
                </div>
                <div className="mb-3 text-right d-block d-sm-none">
                  <Button
                    id="NewMessageSubmitButtonSM"
                    className="mb-2"
                    type="primary"
                    htmlType="submit"
                    block
                    loading={submitting}
                  >
                    {trb(i18.Buttons.NewMessageApprove)}
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
