import {MonitoringConstants} from "./constants";

const initialState = {}

export const MonitoringReducers = function (state = initialState, action) {
    switch (action.type) {
        case MonitoringConstants.GET_SESSION_LOGS_REQUEST:
            return {
                ...state,
                sendLogs: true,
                sessionLogs: null,
            };
        case  MonitoringConstants.GET_SESSION_LOGS_SUCCESS:
            return {
                ...state,
                sendLogs: false,
                sessionLogs: action.payload,
            };
        case MonitoringConstants.GET_SESSION_LOGS_ERROR:
            return {
                ...state,
                sendLogs: false,
                errors: action.error,
            };
        default:
            return state;
    }
};