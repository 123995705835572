import React from 'react';
import { Card } from 'antd';
import classNames from 'classnames';

export const Container = (props) => (
  <Card
    id={props.id ?? ''}
    className={classNames('eb_content_card', { eb_content_card_gradient: props.gradient, eb_content_card_wide: props.wide, w75: props.w75 }, props.cardClassName)}
  >
    {props.children}
  </Card>
);
