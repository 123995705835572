import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';

import { i18, trb } from 'src/Utilities';
import { ContentNoData, FormField, FormFieldType } from 'src/Components';
import { PaymentTemplateAssign } from './';

const ButtonGroup = Button.Group;

export const PaymentTemplateList = (props) => {
  if (!props.data) {
    return <ContentNoData id="PaymentTemplateList" />;
  }

  const templates = props.data;

  return (
    <>
      <Form
        onSubmit={props.submit}
        initialValues={{
          IsNewGroup: true,
        }}
        render={({ handleSubmit, values }) => {
          return (
            <form
              id="PaymentTemplateListForm"
              className="mb-3 w-100"
              onSubmit={handleSubmit}
            >
              <PaymentTemplateAssign
                values={values}
                groups={props.groups}
                submitting={props.submitting}
              />
              {templates?.length > 0 && (
                <div className="eb_table_wrapper w-100 eb_table_wrapper_bottom_lined">
                  <div
                    className="eb_table w-100"
                    id="PaymentTemplateListTable"
                  >
                    <div className="row eb_table_head d-none d-md-flex">
                      <div className="col-4">{trb(i18.Labels.Title)}</div>
                      <div className="col-5">{trb(i18.Labels.PaymentTemplateDescription)}</div>
                      <div className="col-3">{trb(i18.Labels.Actions)}</div>
                    </div>
                    {templates.map((item, index) => (
                      <div
                        className="row eb_table_row"
                        key={index}
                        id={`PaymentTemplate${index}`}
                      >
                        <div className="col-12 col-md-4">
                          <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Title)}</div>
                          <div className="d-flex">
                            <div>
                              <FormField
                                name={'templates.' + index + '.id'}
                                component={FormFieldType.input}
                                defaultValue={item.TemplateID}
                                disabled={true}
                                className="d-none"
                              />
                            </div>
                            <div>
                              <div
                                className="eb_fw_600"
                                style={{ fontSize: 14 }}
                              >
                                {item.TemplateName}
                              </div>
                              <div>
                                {/*  TODO: Deploy comment */}
                                {/*<div>*/}
                                {/*  {item.PaymentGroups.map((item, index) => {*/}
                                {/*    const { Name } = item //atkomentuoti vertimus kai back sius label vietoj teksto*/}
                                {/*    return (*/}
                                {/*      <Tag*/}
                                {/*        key={index}*/}
                                {/*        className="cursor-pointer"*/}
                                {/*        color={textColors.primary}*/}
                                {/*        onClick={() =>*/}
                                {/*          props.clickExecuteGroup(item.ID)*/}
                                {/*        }>*/}
                                {/*        {Name}*/}
                                {/*        /!*{trb(i18.Labels[Name])}*!/*/}
                                {/*      </Tag>*/}
                                {/*    )*/}
                                {/*  })}*/}
                                {/*</div>*/}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="d-block d-md-none text-black-50">{trb(i18.Labels.PaymentTemplateDescription)}</div>
                          <div>
                            <span className="text-black-50">{trb(i18.Labels.PayeeTitle)}:</span> {item.Payment.Recipient}
                          </div>
                          <div>
                            <span className="text-black-50">{trb(i18.Labels.PayeeAccount)}:</span> {item.Payment.BeneficiaryAccountNumber}
                          </div>
                          <div>
                            <span className="text-black-50">{trb(i18.Labels.PaymentTemplateListPurpose)}:</span> {item.Payment.Purpose ? item.Payment.Purpose : item.Payment.ReferenceNo}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 text-left text-md-right">
                          <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Actions)}</div>
                          <ButtonGroup className="hover-action rounded-button">
                            <Popconfirm
                              id={`PaymentTemplate${index}PopConfirm`}
                              placement="bottomRight"
                              title={trb(i18.PopConfirms.DoYouWantDeletePaymentTemplate)}
                              onConfirm={() => props.clickDelete(item.TemplateID)}
                              okText={trb(i18.Buttons.Yes)}
                              cancelText={trb(i18.Buttons.Cancel)}
                            >
                              <Tooltip
                                placement="topRight"
                                title={trb(i18.Tooltips.PaymentTemplateDelete)}
                              >
                                <Button id={`PaymentTemplate${index}DeleteButton`}>
                                  <DeleteOutlined />
                                </Button>
                              </Tooltip>
                            </Popconfirm>
                            <Tooltip
                              placement="topRight"
                              title={trb(i18.Tooltips.PaymentTemplateEdit)}
                            >
                              <Button
                                id={`PaymentTemplate${index}EditButton`}
                                onClick={() => props.clickEdit(item.TemplateID)}
                              >
                                <EditOutlined />
                              </Button>
                            </Tooltip>
                            <Tooltip
                              placement="topRight"
                              title={trb(i18.Tooltips.PaymentTemplateUse)}
                            >
                              <Button
                                id={`PaymentTemplate${index}UseButton`}
                                onClick={() => props.clickExecute(item.TemplateID, item.PaymentType)}
                              >
                                <CheckOutlined />
                              </Button>
                            </Tooltip>
                          </ButtonGroup>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </form>
          );
        }}
      />
      {!templates?.length && <ContentNoData id="NoTemplates" />}
    </>
  );
};

PaymentTemplateList.propTypes = {
  data: PropTypes.array,
  clickDelete: PropTypes.func.isRequired,
  clickEdit: PropTypes.func.isRequired,
  clickExecute: PropTypes.func.isRequired,
};
