import {PrintConstants} from './';

export const PrintActions = {
    show,
    hide,
    setContent,
};

function show() {
    return dispatch => {
        dispatch({type: PrintConstants.PRINT_SHOW});
    };
}

function hide() {
    return dispatch => {
        dispatch({type: PrintConstants.PRINT_HIDE});
    };
}

function setContent(content) {
    return dispatch => {
        dispatch({type: PrintConstants.PRINT_SET_CONTENT, payload: content});
    };
}