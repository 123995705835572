import React from 'react';
import { Button } from 'antd';

import { dateFormat, ToJavaScriptDate } from '../../../../../Utilities';

const ButtonGroup = Button.Group;
class BroadCastMessage extends React.Component {
  scrollToBottom = () => {
    if (this.messagesEnd) {
      this.messagesEnd.scrollIntoView({ block: 'start' });
    }
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  renderMessage = (item, index) => {
    const { data, buttons } = this.props;
    return (
      <div
        className="bbs_chat_message_container"
        key={index}
      >
        <div className={item.IsEmployeeMessage ? 'bbs_chat_message bbs_chat_employee' : 'bbs_chat_message bbs_chat_client'}>
          {item.IsEmployeeMessage && (
            <div className="bbs_chat_logo">
              <img
                src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGO_COLLAPSED.replace('color', 'white')}`}
                id={'logo'}
                alt={import.meta.env.VITE_TITLE}
              />
            </div>
          )}
          <div className="d-flex justify-content-between">
            <div className="bbs_chat_sender">{item.IsEmployeeMessage ? item.UserName : data.ClientName}</div>
          </div>
          <div
            className="bbs_chat_text"
            dangerouslySetInnerHTML={{ __html: item.Message }}
          />
          <div className="bbs_chat_time text-right">{ToJavaScriptDate(item.Date, dateFormat().dateTime)}</div>
          {buttons && (
            <ButtonGroup>
              {buttons &&
                item.CanDelete &&
                buttons.map((itemButton, index) => (
                  <Button
                    id={`BroadCastMessage${itemButton.title}Button`}
                    key={index}
                    onClick={(e) => itemButton.func && itemButton.func(e, item)}
                    {...itemButton.props}
                  ></Button>
                ))}
            </ButtonGroup>
          )}
        </div>
      </div>
    );
  };

  render() {
    const { data } = this.props;

    if (!data) {
      return null;
    }
    return (
      <div className="bbs_chat_message_wrapper">
        {data.Messages && data.Messages.map((item, index) => this.renderMessage(item, index))}
        {data.Message && this.renderMessage(data, 0)}
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            this.messagesEnd = el;
          }}
        ></div>
      </div>
    );
  }
}

export { BroadCastMessage };
