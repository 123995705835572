export const IconSuccess = ({ size = 26, color = '#32BF33', bgColor = '#FFF' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
    >
      <rect
        x="1"
        y="0.866455"
        width="24"
        height="24"
        rx="6"
        fill={bgColor}
      />
      <rect
        x="1"
        y="0.866455"
        width="24"
        height="24"
        rx="6"
        stroke={color}
        strokeWidth="1.5"
      />
      <rect
        x="1"
        y="0.866455"
        width="24"
        height="24"
        rx="6"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="1.5"
      />
      <path
        d="M9.47754 13.151L11.6454 15.3189L11.6314 15.3049L16.5225 10.4138"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.47754 13.151L11.6454 15.3189L11.6314 15.3049L16.5225 10.4138"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
