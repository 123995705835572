import React from 'react';
import DOMPurify from 'dompurify';

export const HTMLFormField = (props) => {
  return (
    <div
      id={`FormField${props.name}Wrapper`}
      className={props.className ? 'form-field-html ' + props.className : 'form-field-html'}
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.html, { ADD_ATTR: ['target'] }) }}
    />
  );
};
