export const IconAlert = ({ size = 26, color = '#B61A36', bgColor = '#FFF' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="24"
        height="24"
        rx="6"
        fill={bgColor}
      />
      <rect
        x="1"
        y="1"
        width="24"
        height="24"
        rx="6"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M13.0025 17.9059C13.1682 17.9059 13.3026 17.7715 13.3014 17.6058C13.3014 17.4401 13.167 17.3057 13.0013 17.3057C12.8356 17.3057 12.7012 17.4401 12.7012 17.6058C12.7012 17.7715 12.8356 17.9059 13.0025 17.9059"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0008 13V7.25684"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
