import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';

import { LanguageActions } from '../../Redux';

import flagLT from './Flags/lt.png';
import flagGB from './Flags/gb.png';

const { Option } = Select;

const LanguageSelect = (params) => {
  const { withoutLabels, language, className } = params,
    showLabel = window.innerWidth > 426, // || withoutLabels,
    languageSelect = [
      {
        value: 'lt-LT',
        label: (
          <>
            <img
              style={{ paddingBottom: '3px' }}
              src={flagLT}
              alt="lt-LT"
            />
            {showLabel && ' Lietuvių'}
          </>
        ),
      },
      {
        value: 'en-GB',
        label: (
          <>
            <img
              style={{ paddingBottom: '3px' }}
              src={flagGB}
              alt="en-GB"
            />
            {showLabel && ' English'}
          </>
        ),
      },
    ],
    languageChange = (evt) => {
      params.dispatch(LanguageActions.setLanguage(evt));
    },
    smallClass = withoutLabels ? 'bbs_language_small' : null;

  return (
    <Select
      id="LanguageSelect"
      value={language}
      style={{
        width: !showLabel ? 60 : 110,
      }}
      variant="borderless"
      className={`bbs_language ${className ? className : ''} ${smallClass ? smallClass : ''}`}
      onChange={languageChange}
    >
      {languageSelect.map((item, index) => (
        <Option
          id={`LanguageSelectOption${item.value}`}
          style={{ paddingLeft: 20 }}
          className="bbs_language"
          value={item.value}
          key={index}
        >
          {item.label}
        </Option>
      ))}
    </Select>
  );
};

function mapStateToProps(state) {
  const { language } = state.languageReducer;

  return {
    language,
  };
}

const connectedLanguage = connect(mapStateToProps)(LanguageSelect);
export { connectedLanguage as LanguageSelect };
