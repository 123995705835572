export const FormFieldWrapperClasses = (className, inline, disabled = false, active = false, small = false, hightlight = false) => {
  const baseClassName = inline ? 'form-field-wrapper row mb-2 align-items-center form-field-wrapper-inline ' : 'form-field-wrapper row ';
  return (
    baseClassName +
    (className ? className : '') +
    (disabled ? ' form-field-wrapper-disabled' : '') +
    ' big-input' +
    (active ? ' big-input-empty' : '') +
    (small ? ' small-input' : '') +
    (hightlight ? ' hightlight-input' : '')
  );
};
