import React from 'react';
import { connect } from 'react-redux';
import { BIC, checkJsonProp, CURRENCIES, SORT_CODE } from 'src/Utilities';
import { withRouter } from 'src/Utilities/withRouter';

import { PMCActions, PMConstants } from 'src/Redux';
import { paymentSystemType } from 'src/Components';

const { EUR } = CURRENCIES;
class NewPaymentSpy extends React.Component {
  constructor(props) {
    super(props);
  }

  renderAfterChangeTemplate = () => {
    const { data, templates } = this.props,
      currentTemplate = this.props?.values?.Payment?.Template ?? this.props?.templateId;
    if (currentTemplate && !!templates?.length) {
      this.props.router.navigate(this.props.router.location.pathname, { state: { templateId: currentTemplate } });
      const currentTemplateData = checkJsonProp(
          templates.find((e) => e.TemplateID === currentTemplate),
          'Payment'
        ),
        payerTypes = ['PayerType', 'InitPayerType', 'PayeeType', 'FinalPayeeType'];
      if (currentTemplateData === null) {
        this.lastDataRender = {
          Payment: {
            PaymentType: this.props?.values?.Payment?.PaymentType,
            PaymentSystem: this.props?.values?.Payment?.PaymentSystem || 'SepaSct',
            IsAdditionalDataSelected: false,
            IsPurposeSelected: `${!!this.props.values.Payment.IsPurposeSelected}`,
            Currency: EUR,
            ...data.Payment,
          },
        };

        this.props.form.reset(this.lastDataRender);
        return null;
      }
      for (let i = 0; i < payerTypes.length; i++) {
        const type = checkJsonProp(currentTemplateData, payerTypes[i]);
        currentTemplateData[payerTypes[i]] = type === 'u' || type === null ? '' : type;
      }
      if (checkJsonProp(data.Payment, 'Template')) {
        this.lastDataRender = {
          Payment: {
            ...data.Payment,
            ...currentTemplateData,
            IsSEPAPaymentTypeSelected: currentTemplateData.IsSEPAPaymentTypeSelected.toString(),
            PaymentSystem: currentTemplateData?.PaymentSystem || 'SepaSct',
            Template: currentTemplate,
            IsPurposeSelected: `${currentTemplateData.IsPurposeSelected}`,
            Purpose:
              `${currentTemplateData.IsPurposeSelected}` === 'false' && (currentTemplateData.ReferenceNo !== '' || currentTemplateData.ReferenceNo !== null)
                ? currentTemplateData.ReferenceNo
                : currentTemplateData.Purpose,
            Currency: EUR,
          },
          TemplateID: currentTemplate,
        };

        this.props.form.pauseValidation();
        for (let key in this.lastDataRender.Payment) {
          this.props.form.change('Payment.' + key, this.lastDataRender.Payment[key]);
        }

        this.props.form.reset(this.lastDataRender);

        this.props.form.resumeValidation();
        return null;
      }

      this.lastDataRender = {
        Payment: {
          IsSEPAPaymentTypeSelected: currentTemplateData.IsSEPAPaymentTypeSelected.toString(),
          ...currentTemplateData,

          IsAdditionalDataSelected:
            currentTemplateData.PayerType !== '' || currentTemplateData.InitPayerType !== '' || currentTemplateData.PayeeType !== '' || currentTemplateData.FinalPayeeType !== '',
          Template: currentTemplate,
          IsPurposeSelected: `${currentTemplateData.IsPurposeSelected}`,
          Purpose:
            `${currentTemplateData.IsPurposeSelected}` === 'false' && (currentTemplateData.ReferenceNo !== '' || currentTemplateData.ReferenceNo !== null)
              ? currentTemplateData.ReferenceNo
              : currentTemplateData.Purpose,
          Currency: EUR,
          BeneficiaryAccountNumber: currentTemplateData.BeneficiaryAccountNumber.replace(/ /gi, ''),
          SaveTemplate: false,
          TemplateName: null,
        },
      };

      this.props.form.change('Payment.PaymentSystem', 'SepaSct');

      this.rerenderBeneficiaryCountryCode = this.lastDataRender?.Payment?.BeneficiaryCountryCode;
      this.RecipientBankCountry = this.lastDataRender?.Payment?.RecipientBankCountry;
      this.props.form.change('Payment.BeneficiaryCountryCode', this.lastDataRender?.Payment?.BeneficiaryCountryCode);
      this.BeneficiaryCountryCode = this.lastDataRender?.Payment?.BeneficiaryCountryCode;
      this.props.form.pauseValidation();
      for (let key in this.lastDataRender.Payment) {
        this.props.form.change('Payment.' + key, this.lastDataRender.Payment[key]);
      }
      // TODO: This seems very unsafe, needs extensive testing to make sure its right
      //this.props.form.reset(this.lastDataRender)
      this.props.form.resumeValidation();

      return null;
    }
    this.lastDataRender = {
      Payment: {
        PaymentType: this.props?.values?.Payment?.PaymentType,
        IsAdditionalDataSelected: false,
        IsPurposeSelected: true,
        ...data.Payment,
        Template: currentTemplate,
      },
    };

    this.props.form.reset(this.lastDataRender);

    return null;
  };

  renderAfterChangeSearch = () => {
    const { dispatch, historicalPayments } = this.props;

    if (this.fullTextSearchFlag && (this.props.values.Payment.BeneficiaryAccountNumber || this.props.values.Payment.Recipient)) {
      const PaymentId =
        historicalPayments && historicalPayments.length
          ? checkJsonProp(
              historicalPayments.find((item) => {
                return checkJsonProp(item, this.fullTextSearchFlag.to) === checkJsonProp(this.props.values.Payment, this.fullTextSearchFlag.from);
              }),
              'PaymentId'
            )
          : null;
      if (PaymentId) {
        //this.PaymentId = PaymentId;
        dispatch(PMCActions.getHistoricalPaymentByID({ PaymentId })).then((response) => {
          if (response.type === PMConstants.GET_HISTORICAL_PAYMENT_BY_ID_SUCCESS) {
            const { payload } = response,
              newValues = {
                PaymentType: this.props?.values?.Payment?.PaymentType,
                AmountStr: payload.Amount,
                //PayeeName: payload.PayeeName,
                Recipient: payload.PayeeName,
                BeneficiaryAccountNumber: payload.PayeeAccountNumber,
                Purpose: payload.Description,
                IsAdditionalDataSelected: false,
                PaymentSystem: payload.PaymentSystem.toLowerCase() === 'sepasct' ? 'SepaSct' : payload.PaymentSystem,
                InitialAccountID: payload.AccountId,
                IsPurposeSelected: `${!!payload.IsPurposeSelected}`,
                Currency: EUR,
              };
            this.lastDataRender = {
              Payment: {
                ...newValues,
              },
            };
            this.props.form.reset(this.lastDataRender);
            dispatch(PMCActions.clearHistoricalPayments());
          }
        });
      }
    }

    // return null
  };

  renderAfterChangeCheckIBAN = () => {
    const { data } = this.props;

    let reset = this.lastDataRender
        ? Object.assign({}, this.lastDataRender)
        : {
            Payment: {
              PaymentType: 2,
              IsAdditionalDataSelected: false,
              IsPurposeSelected: 'true',
              SaveTemplate: false,
              PaymentSystem: data?.Payment?.PaymentSystem ?? paymentSystemType[0].value,
            },
          },
      iban = localStorage.getItem('SavedIBAN'),
      { MALR } = this.props,
      ActiveAccount = MALR.ManagedAccounts.find((item) => item.IsActive);

    if (iban?.toLowerCase().indexOf('gb') === 0 || iban?.toLowerCase().indexOf('ie') === 0) {
      reset = {
        Payment: {
          ...this.props.initialValues.Payment,
          ...reset.Payment,
          IsAdditionalDataSelected: true,
          PayerType: 'p',
          PayerAddress: checkJsonProp(ActiveAccount, 'ClientAddress'),
        },
      };
    }

    if (this.props.checkIBANResult?.Bic) {
      if (this.rerenderCurrencyRoutingCode !== SORT_CODE) {
        reset.Payment.BIC = this.props.checkIBANResult?.Bic?.BIC;
      }
      reset.Payment.RecipientBankCountry = this.rerenderBeneficiaryCountryCode || this.props.checkIBANResult?.Country?.Alpha2;

      this.props.values.Payment.RecipientBankCountry = this.rerenderBeneficiaryCountryCode || this.props.checkIBANResult?.Country?.Alpha2;
      if (this.rerenderCurrencyRoutingCode !== SORT_CODE) {
        this.props.values.Payment.BIC = this.props.checkIBANResult?.Bic?.BIC;
      }
      this.RecipientBankCountry = this.props.checkIBANResult?.Country?.Alpha2;
      if (this.rerenderCurrencyRoutingCode !== SORT_CODE) {
        this.BIC = this.props.checkIBANResult?.Bic?.BIC;
      }
    }

    if (this.props.checkIBANResult?.Bic?.BankName) {
      this.props.form.change('Payment.BankName', this.props.checkIBANResult?.Bic?.BankName);
    }

    if (this.props.checkIBANResult?.Bic?.BIC === 'USDFLT22XXX') {
      this.props.form.change('Payment.PaymentSystem', 'SepaSct');
    } else {
      this.props.form.change('Payment.PaymentSystem', this.props.checkIBANResult?.Participant?.SepaInst ? 'INST' : 'SepaSct');
    }

    this.waiting = true;

    this.lastDataRender = null;
    this.props.form.pauseValidation();
    this.props.form.reset({ Payment: { ...data?.Payment, ...reset.Payment } });
    this.props.form.change('Payment.RecipientBankCountry', this.RecipientBankCountry);
    this.props.form.change('Payment.BeneficiaryCountryCode', this.BeneficiaryCountryCode ?? data?.Payment?.BeneficiaryCountryCode);
    this.props.form.resumeValidation();

    setTimeout(() => {
      this.waiting = false;
    }, 100);

    return null;
  };

  renderReset = () => {
    const { dispatch } = this.props;

    Promise.all([dispatch(PMCActions.clearCheckIBANMember()), dispatch(PMCActions.clearHistoricalPayments()), dispatch(PMCActions.clearPVR())]).then(() => {
      localStorage.removeItem('SavedIBAN');
      localStorage.removeItem('SavedCurrency');
      this.lastDataRender = null;
      if (typeof this.props.clearErrors === 'function') this.props.clearErrors();
      this.props.form.pauseValidation();
      this.props.form.reset();
      this.props.form.resumeValidation();
    });

    return null;
  };

  renderErrorInst = () => {
    this.errorInstFire = false;
    this.lastDataRender = null;
    this.props.form.pauseValidation();
    this.props.form.reset({
      Payment: {
        ...this.props.values.Payment,
        PaymentSystem: 'SepaSct',
      },
    });
    this.props.form.resumeValidation();

    return null;
  };

  componentDidMount = () => {
    this.newRender = true;
  };

  renderAmountChange = () => {
    const { dispatch } = this.props;
    const amountFrom = this.rerenderAmountFrom;
    const amountTo = this.rerenderAmountTo;
    const fromCurr = EUR;
    const toCurr = this.props.values.Payment?.Currency?.indexOf('_') !== -1 ? this.props.values.Payment.Currency?.split('_')[1] || EUR : this.props.values.Payment.Currency;

    const payload = {
      From: fromCurr,
      To: toCurr,
      AccountID: this.props.values.Payment.InitialAccountID,
    };
    if (amountFrom) {
      payload.FromAmount = +amountFrom.replace(',', '.');
    }
    if (amountTo) {
      payload.ToAmount = +amountTo.replace(',', '.');
    }
    if ((!payload.FromAmount && !payload.ToAmount) || payload.ToAmount === EUR) {
      return;
    }
    const NumberToFixedValue = (value, precision = 2) => value.toFixed(precision).replace('.', ',');
    Promise.all([dispatch(PMCActions.postGetFxRate(payload))]).then((responses) => {
      const fixRates = responses.find((itm) => itm.type === PMConstants.POST_FX_RATE_SUCCESS);
      if (fixRates) {
        const oo = {
          ...this.props.values.Payment,
          AmountSell: NumberToFixedValue(+fixRates.payload.FromAmount, 2),
          AmountBuy: NumberToFixedValue(+fixRates.payload.ToAmount, 2),
          FixRateSell: `${fixRates.payload?.Debug?.SellCurrency} ${NumberToFixedValue(+fixRates.payload?.Debug?.Margin, 4)}`,
          FixRateBuy: `${fixRates.payload?.Debug?.BuyCurrency} ${NumberToFixedValue(+fixRates.payload?.Debug?.CoreFxRate, 4)}`,
          FixRate: `${NumberToFixedValue(+fixRates.payload?.Debug?.Margin, 4)} / ${NumberToFixedValue(+fixRates.payload?.Debug?.CoreFxRate, 4)}`,
          AmountStr: fixRates.payload.FromAmount,
          Commission: +fixRates.payload.Commission,
          BIC: this.props.checkIBANResult?.Bic?.BIC || '',
          RecipientBankCountry: this.rerenderBeneficiaryCountryCode || this.props.checkIBANResult?.Country?.Alpha2 || '',
          DontUpdate: true,
        };
        this.props.values.Payment = oo;
      }
    });

    return null;
  };

  renderAfterCurrencyChange = () => {
    const oo = {
      ...this.props.values.Payment,
      DontUpdate: true,
    };
    if (this.nextCurrency === EUR) {
      oo.BeneficiaryCountryCode = this.rerenderBeneficiaryCountryCode || this.BeneficiaryCountryCode || '';
      this.props.values.Payment.BeneficiaryCountryCode = this.rerenderBeneficiaryCountryCode || this.BeneficiaryCountryCode || '';
    }
    this.props.values.Payment = oo;

    this.props.form.pauseValidation();

    if (this.nextCurrency === EUR) {
      this.props.form.reset();
    } else {
      this.props.form.reset({
        Payment: {
          BeneficiaryCountryCode: this.rerenderBeneficiaryCountryCode || this.BeneficiaryCountryCode || '',
          BIC: this.rerenderCurrencyRoutingCode === SORT_CODE ? null : this.BIC || '',
        },
      });
    }
    this.props.form.resumeValidation();
  };

  renderResetIbanValues = () => {
    this.fromTemplate = false;
    this.props.form.pauseValidation();
    this.props.form.change('Payment.BankName', '');
    this.props.form.change('Payment.BIC', '');
    this.props.form.change('Payment.RecipientBankCountry', '');
    this.props.form.change('Payment.PaymentSystem', 'SepaSct');
    this.props.form.resumeValidation();
  };

  shouldComponentUpdate(nextProps) {
    const timeStamp = new Date();
    this.rerenderSource = null;
    this.lastRenderTime = timeStamp.getTime();

    if (this.waiting) return false;

    this.fullTextSearchFlag =
      nextProps?.values?.Payment?.Template === this.props?.values?.Payment?.Template
        ? nextProps.values.Payment?.Recipient !== this.props.values.Payment?.Recipient && nextProps.values.Payment?.Recipient
          ? { from: 'Recipient', to: 'PayeeName' }
          : nextProps.values.Payment?.BeneficiaryAccountNumber !== this.props.values.Payment?.BeneficiaryAccountNumber && nextProps.values.Payment?.BeneficiaryAccountNumber
          ? { from: 'BeneficiaryAccountNumber', to: 'PayeeAccountNumber' }
          : false
        : false;

    if (
      ((nextProps.values.Payment?.Template !== this.props.values.Payment?.Template && nextProps.values.Payment?.Template) ||
        (nextProps.values.Payment?.Template && Object.keys(this.props.visited).length === 0)) &&
      !this.fullTextSearchFlag &&
      !this.prevFullTextSearchFlag &&
      this.prevRerenderSource !== 'FromTemplate'
    ) {
      this.prevFullTextSearchFlag = this.fullTextSearchFlag;
      this.rerenderSource = 'Template';
    }
    if (nextProps.templateId && Object.keys(this.props.visited).length === 0 && !this.fullTextSearchFlag && !this.prevFullTextSearchFlag) this.rerenderSource = 'FromTemplate';

    if (this.fullTextSearchFlag) this.rerenderSource = 'Search';

    if (this.props.sendCheckIBAN !== nextProps.sendCheckIBAN && nextProps.checkIBANResult) {
      this.rerenderSource = 'CheckIBAN';
    }

    if (checkJsonProp(this.props.PVR, 'ErrorCode') === 'ChangePaymentSystem' && this.errorInstFire) {
      this.rerenderSource = 'ErrorInst';
      this.errorInstFire = false;
    }
    if (!this.props.PVR) {
      this.errorInstFire = true;
    }

    if (
      this.props.checkIBANResult &&
      !this.newRender &&
      ((this.props.values.Payment.BeneficiaryAccountNumber !== nextProps.values.Payment.BeneficiaryAccountNumber && !nextProps.values.Payment.BeneficiaryAccountNumber) ||
        (this.props.values.Payment.Recipient !== nextProps.values.Payment.Recipient && !nextProps.values.Payment.Recipient) ||
        (this.props.values.Payment.Template !== nextProps.values.Payment.Template && !nextProps.values.Payment.Template))
    )
      this.rerenderSource = 'Reset';

    if (this.newRender && this.rerenderSource !== 'FromTemplate' && this.prevRerenderSource !== 'FromTemplate') {
      this.newRender = false;
      this.rerenderSource = this.rerenderSource ? this.rerenderSource : 'Template';
    }

    if (
      nextProps.values.Payment?.AmountBuy !== this.props.values.Payment?.AmountBuy ||
      nextProps.values.Payment?.AmountSell !== this.props.values.Payment?.AmountSell ||
      nextProps.values.Payment?.Currency !== this.props.values.Payment?.Currency
    ) {
      const countryCurrenciesResult = this.props.countryCurrenciesResult;
      this.rerenderCurrencyRoutingCode = countryCurrenciesResult?.find((el) => el.Currencies.includes(nextProps.values.Payment.Currency))?.RoutingCodeType;
      this.rerenderBeneficiaryCountryCode =
        nextProps.values.Payment.Currency !== EUR ? countryCurrenciesResult?.find((el) => el.Currencies.includes(nextProps.values.Payment.Currency))?.Country : null;

      if (this.props.values.Payment?.DontUpdate || (!nextProps.values.Payment?.AmountBuy && !nextProps.values.Payment?.AmountSell)) {
        if (this.prevRerenderSource !== 'Template' && this.prevRerenderSource !== 'Search') {
          this.nextCurrency = nextProps.values.Payment.Currency;
          this.rerenderSource = 'CurrencyChanged';
          localStorage.setItem('SavedCurrency', this.nextCurrency);
        } else {
          this.prevRerenderSource = null;
        }
      } else {
        const amountFrom =
          nextProps.values.Payment.AmountSell !== this.props.values.Payment.AmountSell
            ? nextProps.values.Payment.AmountSell
            : nextProps.values.Payment.Currency !== this.props.values.Payment.Currency && this.LastChangedAmountFrom
            ? this.props.values.Payment.AmountSell
            : null;
        const amountTo =
          nextProps.values.Payment.AmountBuy !== this.props.values.Payment.AmountBuy
            ? nextProps.values.Payment.AmountBuy
            : nextProps.values.Payment.Currency !== this.props.values.Payment.Currency && !this.LastChangedAmountFrom
            ? this.props.values.Payment.AmountBuy
            : null;

        this.rerenderAmountFrom = amountFrom;
        this.rerenderAmountTo = amountTo;
        this.LastChangedAmountFrom = +amountFrom > 0;

        this.rerenderSource = 'NewAmount';
      }
    }

    if (
      nextProps.values.Payment?.BeneficiaryAccountNumber !== this.props.values.Payment?.BeneficiaryAccountNumber &&
      this.props.checkIBANResult &&
      this.prevRerenderSource !== 'Template' &&
      !this.fromTemplate
    ) {
      this.rerenderSource = 'ResetIbanResults';
    }

    this.prevRerenderSource = this.rerenderSource;
    return this.rerenderSource;
  }

  render() {
    switch (this.rerenderSource) {
      case 'FromTemplate':
        return null;
      case 'Template':
        this.renderAfterChangeTemplate();
        return null;
      case 'Search':
        this.renderAfterChangeSearch();
        return null;
      case 'CheckIBAN':
        // eslint-disable-next-line no-case-declarations
        const checkTime = () => {
          const timeStamp = new Date();
          if (timeStamp.getTime() - this.lastRenderTime > 100) {
            this.renderAfterChangeCheckIBAN();
          } else setTimeout(checkTime, 10);
        };
        setTimeout(checkTime, 10);
        return null;
      case 'Reset':
        this.renderReset();
        return null;
      case 'ErrorInst':
        setTimeout(this.renderErrorInst, 100);
        return null;
      case 'NewAmount':
        clearTimeout(this.callTO);
        this.callTO = setTimeout(this.renderAmountChange, 1000);
        return null;
      case 'CurrencyChanged':
        setTimeout(this.renderAfterCurrencyChange, 300);
        return null;
      case 'ResetIbanResults':
        this.renderResetIbanValues();
        return null;
      default:
        return null;
    }
  }
}

function mapStateToProps(state) {
  const { sendPMC, historicalPayments, historicalPayment, checkIBANResult, sendCheckIBAN, countryCurrenciesResult } = state.PMCReducers,
    { MALR } = state.UACReducers;

  return {
    sendPMC,
    historicalPayments,
    historicalPayment,
    checkIBANResult,
    sendCheckIBAN,
    MALR,
    countryCurrenciesResult,
  };
}

const connectedNewPaymentSpy = connect(mapStateToProps)(withRouter(NewPaymentSpy));
export { connectedNewPaymentSpy as NewPaymentSpy };
