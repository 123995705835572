import { Checkbox, FormField } from 'src/shared';
import { i18, trb } from '../../../../../Utilities';

export const FieldConfirmTarget2 = () => {
  return (
    <FormField
      name="Payment.ConfirmTarget2"
      floating={false}
      wrapperClassNames="radiolike-checkbox"
    >
      <Checkbox>
        <div dangerouslySetInnerHTML={{ __html: trb(i18.Labels.AgreeWithTarget2PaymentRates) }} />
      </Checkbox>
    </FormField>
  );
};
