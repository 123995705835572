import { useEffect } from 'react';
import { routerPath } from 'src/Config';
import { UACActions, UACConstants } from 'src/Redux';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageLoader } from 'src/Components';

export const WaitingRoom = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const LoginCode = pathname?.split('/').pop();
  const LoginToken = window.localStorage.getItem('loginToken');
  const LoginSystem = 'BioFace';
  const Language = useSelector((state) => state.languageReducer?.language);

  const doLogin = async () => {
    const result = await dispatch(
      UACActions.postCheckLogin({
        Language,
        LoginCode,
        LoginSystem,
        LoginToken,
      })
    );

    if (result && result.type === UACConstants.POST_UAC_CHECK_LOGIN_SUCCESS) {
      if (result.payload.LoginSystem === 'Sms') {
        navigate(routerPath.verification, {
          state: {
            LoginCode: LoginCode,
            LoginToken: result.payload.RefreshToken,
            LoginAuthCode: null,
            LoginSystem: result.payload.LoginSystem,
            Language: Language,
            LoginStatusCode: result.payload.LoginStatusCode,
          },
        });
      } else {
        navigate(routerPath.index);
      }
    } else {
      navigate(routerPath.login);
    }
  };

  useEffect(() => {
    doLogin();
  }, []);

  return <PageLoader />;
};
