import { FormField, Input } from 'src/shared';
import { i18, trb } from '../../../../../Utilities';

export const FieldBeneficiaryPostCode = () => {
  return (
    <FormField
      name="Payment.BeneficiaryPostCode"
      label={trb(i18.Labels.BeneficiaryPostcode)}
    >
      <Input />
    </FormField>
  );
};
