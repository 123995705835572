import { Avatar, FormField, Select } from 'src/shared';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNode } from 'react';
import { useFormikContext } from 'formik';

import { AMCActions } from 'src/Redux';
import { CURRENCIES, accountsColorObject, currencyFormat, i18, trb } from 'src/Utilities';

interface IProps {
  overdraft?: boolean;
}

interface IOptionItem {
  label: ReactNode;
  value: string;
  amounts: {
    Value: number;
    Currency: string;
  }[];
  RelatedProductNumber?: string;
  CurrencyName?: string;
  AccountNumber?: string;
}

export const FieldPayerAccount = ({ overdraft }: IProps) => {
  const dispatch: any = useDispatch();
  const { PTR } = useSelector((state: any) => state.PMCReducers);

  const { setFieldValue } = useFormikContext();

  const setMaxAvailableSum = async (accountId: string, option: IOptionItem) => {
    await setFieldValue('Payment.AmountAvailable', option?.amounts[0]?.Value);
    await setFieldValue('Payment.Currency', option?.amounts[0]?.Currency);

    if (option?.amounts[0]?.Currency !== CURRENCIES.EUR) {
      await setFieldValue('Payment.OverdraftValue', null);
    } else {
      const res = await dispatch(AMCActions.getAccountOverdraft(accountId));

      if (res?.payload && res?.payload?.totalAvailable !== null) {
        await setFieldValue('Payment.OverdraftValue', res.payload.totalAvailable);
        await setFieldValue('Payment.AmountAvailable', res.payload.totalAvailable);
      } else {
        await setFieldValue('Payment.OverdraftValue', null);
      }
    }
  };

  return (
    <FormField
      name="Payment.InitialAccountID"
      label={trb(i18.Labels.FromAccount)}
    >
      <Select
        options={PTR?.InitialAccounts?.map((item: any) => ({
          label: (
            <div className="composedDropdownLabel">
              <div className="object-fit-contain">
                <Avatar
                  size={17}
                  style={{
                    backgroundColor: accountsColorObject[(item?.color ?? 1) as keyof typeof accountsColorObject],
                    marginBottom: 0,
                  }}
                />
              </div>
              <div className="mt-1 composedDropdownLabelText">
                {`${item.AccountNumber} (${item.Amounts?.[0]?.Text})${
                  item.RelatedProductNumber ? ` ${item.RelatedProductNumber} (${currencyFormat(item.RelatedProductValue)} ${item.CurrencyName})` : ''
                }`}
              </div>
            </div>
          ),
          value: item.AccountID,
          amounts: item.Amounts,
          labelText: `${item.AccountNumber} (${item.Amounts?.[0]?.Text})${
            item.RelatedProductNumber ? ` ${item.RelatedProductNumber} (${currencyFormat(item.RelatedProductValue)} ${item.CurrencyName})` : ''
          }`,
          AccountNumber: item.AccountNumber,
        }))}
        handleChange={async (value, option: IOptionItem) => {
          await setFieldValue('Payment.ValidationPayerIBAN', option?.AccountNumber?.replaceAll(' ', ''));
          overdraft ? await setMaxAvailableSum(value, option) : null;
        }}
      />
    </FormField>
  );
};
