import React from 'react';
import { connect } from 'react-redux';

import { PMCActions } from '../../../../../Redux';
import { i18, required, trb } from '../../../../../Utilities';
import { ContentLoader, FormField, FormFieldType } from '../../../../../Components';

class GroupPaymentSpy extends React.Component {
  componentDidMount() {
    this.props.dispatch(PMCActions.postGGDR({ ID: this.props.values.PaymentGroupId }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.values.PaymentGroupId !== prevProps.values.PaymentGroupId) {
      this.props.dispatch(PMCActions.postGGDR({ ID: this.props.values.PaymentGroupId }));
      delete this.props.values.GroupPayments;
      this.props.form.reset({ ...this.props.values });
    }
  }

  render() {
    const { sendGGDR, GGDR } = this.props;

    if (!this.props.values.PaymentGroupId) {
      return null;
    }

    if (sendGGDR) {
      return <ContentLoader />;
    }

    if (!GGDR || GGDR.PayTmpltGroupDataList.length < 1) {
      return null;
    }

    return (
      <div className="eb_table my-3 w-100">
        <div className="row eb_table_head d-none d-md-flex">
          <div className="col-4 pl-0 pl-md-5">{trb(i18.Labels.Title)}</div>
          <div className="col-5 pl-0 pl-md-5">{trb(i18.Labels.Purpose)}</div>
          <div className="col-3 pl-0 pl-md-5 text-left">{trb(i18.Labels.Amount)}</div>
        </div>
        {GGDR.PayTmpltGroupDataList.map((item, index) => (
          <div
            className="row eb_table_row"
            key={index}
          >
            <div className="col-12 col-md-4 eb_fs_14 pt-3 pl-0 pl-md-5">
              <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Title)}</div>
              <div className="eb_fw_400">{item.ReceiverName}</div>
              <div className="text-gray-basic pt-1">{item.ReceiverAccount}</div>
              <FormField
                name={'GroupPayments.' + index + '.ID'}
                component={FormFieldType.input}
                defaultValue={item.ID.toString()}
                className="d-none"
                disabled={this.props.disabled}
              />
            </div>
            <div className="col-12 col-md-5">
              <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Purpose)}</div>
              <FormField
                name={'GroupPayments.' + index + '.Description'}
                component={FormFieldType.input}
                defaultValue={item.DescriptionPayCode}
                validate={required}
                className="mb-0"
                disabled={this.props.disabled}
              />
            </div>
            <div className="col-12 col-md-3">
              <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Amount)}</div>
              <FormField
                name={'GroupPayments.' + index + '.Amount'}
                component={FormFieldType.number}
                defaultValue={item.Amount}
                validate={required}
                className="mb-0"
                disabled={this.props.disabled}
                hightlight
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { sendGGDR, GGDR } = state.PMCReducers;

  return {
    sendGGDR,
    GGDR,
  };
}

const connectedGroupPaymentSpy = connect(mapStateToProps)(GroupPaymentSpy);
export { connectedGroupPaymentSpy as GroupPaymentSpy };
