import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Collapse } from 'antd';
import { useLocation } from 'react-router-dom';

import { i18, trb } from 'src/Utilities';
import { MenuActions } from 'src/Redux';
import { AccountsContainer } from '../Accounts';
import { InvoiceFilterContainer, InvoiceListContainer } from './Components';

export const InvoiceContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [AccountID, setAccountId] = useState(location.state?.accountId);

  useEffect(() => {
    dispatch(MenuActions.setTitle(trb(i18.Container.InvoiceContainer)));
  }, []);

  useEffect(() => {
    if (location.state?.accountId && AccountID !== location.state?.accountId) {
      setAccountId(location.state?.accountId);
    }
  }, [location.state?.accountId]);

  return (
    <>
      {AccountID ? (
        <>
          <div className="mb-5">
            <InvoiceFilterContainer accountId={AccountID} />
          </div>
          <InvoiceListContainer />
        </>
      ) : (
        <Collapse
          className="eb_accounts_panel eb_collapse"
          bordered={false}
          defaultActiveKey={1}
          expandIcon={() => null}
          expandIconPosition="end"
          items={[
            {
              key: 1,
              children: (
                <>
                  <h3 className="eb_content_subtitle">{trb(i18.Container.InvoiceChooseAccount)}</h3>
                  <AccountsContainer hideTitle={true} />
                </>
              ),
            },
          ]}
        />
      )}
    </>
  );
};
