export const IconWarning = ({ size = 26, color = '#FAB114', bgColor = '#FFF' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
    >
      <rect
        x="1"
        y="0.866455"
        width="24"
        height="24"
        rx="6"
        fill={bgColor}
      />
      <rect
        x="1"
        y="0.866455"
        width="24"
        height="24"
        rx="6"
        stroke={color}
        strokeWidth="1.5"
      />
      <rect
        x="1"
        y="0.866455"
        width="24"
        height="24"
        rx="6"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="1.5"
      />
      <path
        d="M12.9029 17.6113C13.0686 17.6113 13.203 17.4768 13.2018 17.3111C13.2018 17.1455 13.0674 17.011 12.9017 17.011C12.736 17.011 12.6016 17.1455 12.6016 17.3111C12.6016 17.4768 12.736 17.6113 12.9029 17.6113"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9029 17.6113C13.0686 17.6113 13.203 17.4768 13.2018 17.3111C13.2018 17.1455 13.0674 17.011 12.9017 17.011C12.736 17.011 12.6016 17.1455 12.6016 17.3111C12.6016 17.4768 12.736 17.6113 12.9029 17.6113"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9012 12.9299V7.18677"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9012 12.9299V7.18677"
        stroke="black"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
