import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';

import { MenuActions } from 'src/Redux';
import { routerPath } from 'src/Config';
import { PartMenu, UserSelectContainer } from './index';
import { CustomIcon, useGetWindowSize } from 'src/Utilities';

const { Sider } = Layout;

export const PartSwapMenu = () => {
  const dispatch = useDispatch();
  const windowSize = useGetWindowSize();
  const navigate = useNavigate();
  const { menuCollapsed, contentMenu, contentMenuPath } = useSelector((state) => state.MenuReducer);

  const { language } = useSelector((state) => state.languageReducer);

  const getLogo = () => {
    return `${import.meta.env.VITE_ROOT}images/logos/${menuCollapsed ? import.meta.env.VITE_LOGO : import.meta.env.VITE_LOGO_COLLAPSED}`;
  };

  const [logo, setLogo] = useState(getLogo());

  const toggle = () => {
    dispatch(MenuActions.toggle());
  };

  useEffect(() => {
    const delay = menuCollapsed ? 0 : 300;
    setTimeout(() => {
      setLogo(getLogo());
    }, delay);
  }, [menuCollapsed]);

  return (
    <>
      <Sider
        className={`menu-sider ${menuCollapsed ? 'menu-collapsed' : null}`}
        width={265}
        style={{
          height: menuCollapsed && windowSize.mobileCheck ? windowSize.height - 50 : 'auto',
        }}
        collapsedWidth={85}
        theme="light"
        collapsed={windowSize.mobileCheck ? null : !menuCollapsed}
      >
        <div className="d-flex flex-column h-100 justify-content-between">
          <div>
            <div className="eb_menu_logo">
              <img
                src={logo}
                id={'logo'}
                alt={import.meta.env.VITE_TITLE}
                onClick={() => navigate(routerPath.index)}
                className="cursor-pointer"
              />
            </div>
            <div className="eb_menu_mobile d-flex d-md-none">
              <UserSelectContainer />
              <Button
                id="SidebarMobileSettingsButton"
                className="text-click"
                onClick={() => {
                  toggle();
                  navigate(routerPath.settings);
                }}
                type="link"
              >
                <CustomIcon
                  type="custom"
                  icon="settings"
                />
              </Button>
            </div>
            <div className="eb_menu_btn_collapse_wrapper">
              <Button
                id="SidebarCollapseButton"
                type="link"
                onClick={toggle}
                className={`eb_menu_btn_collapse text-left width-40 ${menuCollapsed ? 'pr-20' : 'pl-20'}`}
                icon={
                  <CustomIcon
                    size={12}
                    type="custom"
                    icon={'caretLeft'}
                    className={!menuCollapsed && 'rotate-180'}
                  />
                }
              />
            </div>
            <PartMenu
              toggleMenu={toggle}
              language={language}
              menuCollapsed={menuCollapsed}
              contentMenu={contentMenu}
              contentMenuPath={contentMenuPath}
            />
          </div>
        </div>
      </Sider>
    </>
  );
};
