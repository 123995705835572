import React from 'react';
import { Editor } from 'draft-js';

export const ChatInput = ({ editorState, setEditorState, name = '', meta = {} }) => {
  return (
    <div className="RichEditor-root">
      <div
        id={`ChatInput${name}`}
        style={{ borderRadius: 30, backgroundColor: '#f5f5f5', border: 'none' }}
        className="RichEditor-editor"
      >
        <Editor
          editorState={editorState}
          onChange={setEditorState}
        />
      </div>
      <div className="form-field-error">{meta?.touched && (meta?.error || meta?.submitError)}</div>
    </div>
  );
};
