/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { i18, translationGroups, trb } from 'src/Utilities';
import { Button, FormFieldError, Typography } from 'src/shared';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import * as Sentry from '@sentry/browser';

import { ILoginCountries, ILoginForm, ILoginTypes, LOGIN_TYPES, loginTypesImages } from 'src/Containers';
import { LOGIN_TYPES_BY_COUNTRY } from 'src/constants';
import { UACActions, UACConstants } from 'src/Redux';
import { Loader } from 'src/Components';
import { history } from 'src/Config';
import { BackButton } from '../Components';
import { guidGenerator } from 'src/utils';
import { useLocation, useNavigate } from 'react-router-dom';

const { Text } = Typography;

interface IProps {
  data: ILoginForm;
  setData: React.Dispatch<React.SetStateAction<ILoginForm>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

export const LoginType = ({ setData, data, setStep }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { language } = useSelector((state: any) => state.languageReducer);
  const { SMSR, errors } = useSelector((state: any) => state.UACReducers);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const sendSentryMesasgeOnLogin = (guid: string) => {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      scope.setContext('values', {
        ...data,
        initialGuid: guid,
      });
      Sentry.captureMessage('Login');
    });
  };

  const handleButtonClick = async (type: ILoginTypes) => {
    setIsLoading(true);

    navigate(location.pathname, {
      replace: true,
      state: { ...location.state, isIdentificationError: false, customIdentificationError: null },
    });

    const guid: string = guidGenerator();
    sendSentryMesasgeOnLogin(guid);
    const { LoginCode, LoginCountry, LoginIdentity, LoginRememberMe } = data;

    const LANGUAGES = {
      NL: 'nl-NL',
      FI: 'fi-FI',
    };

    const response = data.isFullLogin
      ? await dispatch(
          (UACActions as any).postFullLogin({
            LoginCode,
            LoginCountry,
            LoginIdentity,
            LoginSystem: type,
            Language: (LANGUAGES as any)[LoginCountry ?? ''] ?? language,
            RememberMe: LoginRememberMe,
            initialGuid: guid,
          })
        )
      : await dispatch((UACActions as any).postSimpleLogin({ Language: language, LoginCode, LoginSystem: type, LoginCountry, initialGuid: guid }));

    if ([LOGIN_TYPES.IDIN, LOGIN_TYPES.FTN, LOGIN_TYPES.MOBILEESIGN, LOGIN_TYPES.BIOFACE].includes(type)) {
      if (response.payload?.LoginSession && response.payload?.RedirectUrl) {
        if (LOGIN_TYPES.BIOFACE) {
          window.localStorage.setItem('loginToken', response.payload?.LoginSession);
        }

        const exp = moment().utc().add(5, 'minutes').format('dd, DD MMM YYYY HH:mm:ss UTC');
        document.cookie = `login_session_guid=${response.payload.LoginSession}; expires=${exp}; secure`;
        document.cookie = `login_code=${LoginCode}; expires=${exp}; secure`;

        history.push(response.payload.RedirectUrl); // + (response.payload.RedirectUrl.indexOf('?') === -1 ? '?' : '')
      } else {
        setIsLoading(false);
      }
    } else if (response?.type === UACConstants.POST_UAC_FULLLOGIN_SUCCESS || response?.type === UACConstants.POST_UAC_SIMPLELOGIN_SUCCESS) {
      if (!response.payload.LoginStatusCode) {
        setData((p) => ({
          ...p,
          LoginType: type,
          LoginControlCode: response.payload.LoginControlCode,
          LoginSession: response.payload.LoginSession,
        }));
        setStep((p) => p + 1);
      }

      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const setup = LOGIN_TYPES_BY_COUNTRY[data.LoginCountry as ILoginCountries];

  const getItemValue = (item: any, attribute: string) => {
    return typeof item === 'object' ? item[attribute] : item;
  };

  return isLoading ? (
    <div className="d-flex justify-content-center align-items-center h-100">
      <Loader />
    </div>
  ) : (
    <>
      <BackButton
        handleBackButtonClick={() => {
          navigate(location.pathname, {
            replace: true,
            state: { ...location.state, step: 0, isIdentificationError: false, customIdentificationError: null },
          });
          setStep((p) => p - 1);
        }}
      />
      <div>
        <Text className="login_title">{trb(i18.Labels.LoginToYourAccount)}</Text>
      </div>
      <div>
        <Text className="login_subTitle">{trb(i18.Labels.LoginTypeSelectionNotOurClientYet)}</Text>
      </div>
      <div className="login_form_wrapper">
        {setup.map((item: any, i) => (
          <div
            className="mb-3"
            key={i}
          >
            <Button
              block
              className="login_type_button"
              type="default"
              id={`loginType${getItemValue(item, 'loginType')}Button`}
              onClick={() => handleButtonClick(getItemValue(item, 'loginType') as ILoginTypes)}
            >
              <div className="d-flex justify-content-between w-100">
                <div>{React.createElement((loginTypesImages as any)[getItemValue(item, item?.icon ? 'icon' : 'loginType')])}</div>
                <div className="d-flex align-items-center">
                  <Text className="login_buttonText">{trb((i18.Labels as any)[getItemValue(item, 'label')])}</Text>
                </div>
              </div>
            </Button>
          </div>
        ))}
        {location.state?.isIdentificationError && (
          <div className="field_wrapper">
            <FormFieldError error={location.state?.customIdentificationError ? trb(location.state?.customIdentificationError) : trb(i18.LoginErrors.Canceled)} />
          </div>
        )}
        {(SMSR?.LoginStatusCode || errors?.response?.data?.LoginStatusCode || errors?.data?.LoginStatusCode) && (
          <div className="field_wrapper">
            <FormFieldError error={trb(translationGroups.LoginErrors + (SMSR?.LoginStatusCode || errors?.response?.data?.LoginStatusCode || errors?.data?.LoginStatusCode))} />
          </div>
        )}
      </div>
    </>
  );
};
