export const PAGINATION_DEFAULTS = {
    DASHBOARD_OPERATIONS_ONGOING: {
        DEFAULT: 20,
        SIZES: ['20', '50', '100', '500']
    },
    OPERATIONS : {
        DEFAULT: 20,
        SIZES: ['20', '50', '100', '500']
    }
}
