import { Checkbox as CheckboxANTD, CheckboxProps } from 'antd';
import { useField, useFormikContext } from 'formik';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';

interface IProps extends CheckboxProps {
  name?: string;
  handleChange?: (value: boolean) => void;
  customChecks?: boolean;
}

export const Checkbox = ({ name = '', handleChange, customChecks, ...restProps }: IProps) => {
  const { isSubmitting } = useFormikContext();
  const [field, _meta, helpers] = useField(name);

  return (
    <div
      className="field_wrapper"
      id={name}
    >
      <CheckboxANTD
        {...restProps}
        checked={field.value}
        disabled={isSubmitting}
        onChange={async (e) => {
          await helpers.setValue(e.target.checked);

          if (typeof handleChange === 'function') {
            handleChange(e.target.checked);
          }
        }}
      >
        {customChecks && <span>{field.value ? <MinusCircleFilled /> : <PlusCircleFilled />}</span>}
        <span>{restProps.children}</span>
      </CheckboxANTD>
    </div>
  );
};
