import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {
    i18,
    numberFormat,
    required,
    requiredAny,
    requiredAll,
    requiredNotUndefined,
    checkJsonProp,
    checkJsonExist
} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCIncome = (props) => {

    let ClientAccountBank = checkJsonProp(props.data, 'clientAccountBank') && checkJsonProp(props.data, 'clientAccountBank').length
        ? checkJsonProp(props.data, 'clientAccountBank')
        : [{clientAccountBankType: "ACC1", data:{}}],
        ClientPlanTurn = checkJsonProp(props.data, 'clientPlanTurn') ? checkJsonProp(props.data, 'clientPlanTurn') : [{clientPlanTurn: "PEN1", data: {}}],
        ClientPlanCashTurn = checkJsonProp(props.data, 'clientPlanCashTurn') ? checkJsonProp(props.data, 'clientPlanCashTurn') : [{clientPlanCashTurn: "PEN1", data: {}}];

    ClientPlanTurn[0].clientPlanTurn = checkJsonProp(ClientPlanTurn, '0.clientPlanTurn') ? checkJsonProp(ClientPlanTurn, '0.clientPlanTurn') : "PEN1";
    ClientPlanCashTurn[0].clientPlanCashTurn = checkJsonProp(ClientPlanCashTurn, '0.clientPlanCashTurn') ? checkJsonProp(ClientPlanCashTurn, '0.clientPlanCashTurn') : "PEN1";

    ClientAccountBank = ClientAccountBank.map((item) => {
        item.allNeed = (item.data && item.data.clientAccountBankName && item.data.clientAccountBankCountry)
                       || (!checkJsonProp(item, 'data.clientAccountBankName')
                       && !checkJsonProp(item, 'data.clientAccountBankCountry'))
                          ? true : false;
        return item;
    });
    const ClientIncome = checkJsonProp(props.data, 'clientIncome'),
          ClientProfile = checkJsonProp(props.data, 'clientProfile'),
          initialData = {
              clientIncome: ClientIncome,
              clientProfile: ClientProfile,
              clientPlanTurn: ClientPlanTurn,
              clientPlanCashTurn: ClientPlanCashTurn,
              clientAccountBank: ClientAccountBank
          };

    const mapData = [
        {
            messageBefore: i18.NotificationMessages.KYCIncome,
            title: i18.Labels.ClientIncomeSource,
            elements: [{
                translate: i18.Labels.ClientIncomeSalary,
                name: 'clientIncome.0.data.clientIncomeSalary',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientIncomeRe,
                name: 'clientIncome.0.data.clientIncomeRe',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientIncomeIdv,
                name: 'clientIncome.0.data.clientIncomeIdv',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientIncomeStip,
                name: 'clientIncome.0.data.clientIncomeStip',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientIncomeStock,
                name: 'clientIncome.0.data.clientIncomeStock',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientIncomeBenef,
                name: 'clientIncome.0.data.clientIncomeBenef',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
            },{
                translate: i18.Labels.ClientIncomeSav,
                name: 'clientIncome.0.data.clientIncomeSav',
                component: FormFieldType.checkboxOnly,
                className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
            },{
                title: i18.Labels.ClientIncomeOtherT,
                name: 'clientIncome.0.data.clientIncomeOtherT',
                component: FormFieldType.input,
                className: 'col-12 col-md-6',
            },{
                name: 'clientIncome.0.clientIncomeValid',
                component: FormFieldType.checkbox,
                className: 'col-12 only-error text-center m-2',
                validate: requiredAny,
            }]
        },{
            title: i18.Labels.IncomePerMonth,
            elements: [{
                title: i18.Labels.ClientMonthIncome,
                name: 'clientProfile.0.data.clientMonthIncome',
                component: FormFieldType.number,
                className: 'col-12',
                numberFormat: numberFormat().currency,
                validate: required,
            }]
        },{
            title: i18.Labels.ClientPlanTurn,
            elements: [{
                name: 'clientPlanTurn.0.data.clientPlanTurnover',
                component: FormFieldType.radio,
                options: props.classifikators.PLTUR,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-10 offset-1',
                validate: requiredNotUndefined,
                noInline: true,
            }]
        },{
            title: i18.Labels.ClientPlanCashTurn,
            elements: [{
                name: 'clientPlanCashTurn.0.data.clientPlanCashTurnover',
                component: FormFieldType.radio,
                options: props.classifikators.PLCASH,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-10 offset-1',
                validate: requiredNotUndefined,
                noInline: true,
            }]
        },{
            title: i18.Labels.clientAccountBank,
            canDuplicate: true,
            duplicateDefault: {clientAccountBankType: "ACC$index", allNeed: true, data:{}},
            duplicateName: 'clientAccountBank',
            maxRows: props.classifikators.ACC.length - 1,
            elements: [{
                title: i18.Labels.clientAccountBankName,
                name: 'clientAccountBank.$index.clientAccountBankType',
                component: FormFieldType.select,
                options: props.classifikators.ACC,
                optionsLabel: 'label',
                optionsValue: 'value',
                optionsDisabled: 'disabled',
                disabled: true,
                className: 'col-12 d-none',
            },{
                title: i18.Labels.clientAccountBankName,
                name: 'clientAccountBank.$index.data.clientAccountBankName',
                component: FormFieldType.input,
                className: 'col-12',
            },{
                title: i18.Labels.clientAccountBankCountry,
                name: 'clientAccountBank.$index.data.clientAccountBankCountry',
                component: FormFieldType.selectSearch,
                options: props.classifikators.CISO,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-12',
            },{
                name: 'clientAccountBank.$index.allNeed',
                component: FormFieldType.checkbox,
                checkedNumber: false,
                className: 'col-12 text-center m-2 only-error ',
                validate: requiredAll,
            }]
        }
    ];

    const decorators = [
        createDecorator({
                field: /clientAccountBank\.*.*/,
                updates: (value, name, allValues, prevValues) => {
                    if (allValues && prevValues && allValues.length < prevValues.length) return {};
                    if (value === undefined) return {};

                    const arr = name.split('.'),
                          index = arr.length ? parseInt(arr[1]) : null,
                          another = (checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankName`)
                                    && checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankCountry`))
                                    || (!checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankName`)
                                    && !checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankCountry`));

                    return index > -1 && name.indexOf('allNeed') === -1 ? {
                        [`clientAccountBank.${index}.allNeed`]: another ? true : false,
                    } : {}
                }
            },{
                field: 'clientAccountBank',
                updates: (value, name, allValues, prevValues) => {
                    const diff = allValues.clientAccountBank.filter((newItem)=>{
                        return prevValues.clientAccountBank != undefined && prevValues.clientAccountBank.some((prevItem) => {
                            return Object.is(newItem, prevItem);
                        }) ? false : true;
                    })
                    const index = value && typeof value == "object" &&
                                  checkJsonExist(allValues, 'clientAccountBank') && checkJsonExist(prevValues, 'clientAccountBank') &&
                                  allValues.clientAccountBank.length != prevValues.clientAccountBank.length
                                      ? value.indexOf(diff[0])
                                      : 0;
                    if (index > 0){
                        props.classifikators.ACC.every((item) => {
                            if (item.value == value[index].clientAccountBankType){
                                if (item.disabled){
                                    let accIndex;
                                    props.classifikators.ACC.filter((item, index) => {
                                        if (!item.disabled){
                                            accIndex = index;
                                            return true;
                                        }
                                        return false;
                                    });
                                    value[index].clientAccountBankType = props.classifikators.ACC[accIndex].value;
                                    props.classifikators.ACC[accIndex].disabled = true;
                                    return false;
                                }
                                else{
                                    item.disabled = true;
                                }
                            }
                            return true;
                        });
                    }
                    return {};
                }
            },{
                field: /clientAccountBank\.*.*clientAccountBankType/,
                updates: (value, name, allValues) => {
                    props.classifikators.ACC.map((item)=>{
                        item.disabled = checkJsonProp(allValues, 'clientAccountBank').some((addItem) => {
                            return addItem.clientAccountBankType == item.value;
                        }) ? true : false;
                        item.disabled = item.disabled || item.value == value ? true : false;
                        return item;
                    })
                    return {}
                }
            },{
                field: /\.*/,
                updates: {
                    'clientIncome.0.clientIncomeValid': (value, allValues) => {
                        let incomeCheck = checkJsonProp(allValues, 'clientIncome.0.data') ? Object.entries(checkJsonProp(allValues, 'clientIncome.0.data')) : false;
                        incomeCheck = incomeCheck ? incomeCheck.some((item)=> {return item[1]}) || false : false;
                        return value || incomeCheck || false;
                    }
                }
            },
        )
    ];

    return <KYCFormSwitch permissons={props.permissons} decorators={decorators} title={i18.ContentSubTitles.Income} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>;
};