import { useDispatch, useSelector } from 'react-redux';

import { i18, sortClasificatorMap, trb } from 'src/Utilities';
import { FormField, Select } from 'src/shared';
import { useEffect } from 'react';
import { AMCActions } from 'src/Redux';

export const FieldBeneficiaryCountryCode = () => {
  const { language } = useSelector((state: any) => state.languageReducer);
  const { countriesList, countriesListLoanding } = useSelector((state: any) => state.AMCReducers);
  const { CISO } = useSelector(
    (state: any) => state?.translationReducer?.translations?.find((el: any) => el.culture === language && el.context === 'BBS.Classificators')?.Groups ?? []
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (!countriesListLoanding && countriesList.length === 0) {
      dispatch(AMCActions.getCountriesList() as any);
    }
  }, []);

  return (
    <FormField
      name="Payment.BeneficiaryCountryCode"
      label={trb(i18.Labels.BeneficiaryCountryCode)}
    >
      <Select
        options={sortClasificatorMap(
          countriesList.map(({ ISO }: { ISO: string }) => ({ label: CISO?.[ISO] ?? ISO, value: ISO })),
          language
        )}
      />
    </FormField>
  );
};
