import {CMCServices} from './services';
import {CMCConstants} from './constants';

export const CMCActions = {
    postCIPTR,
    postPDR,
    postCIR,
    postVCR,
    postSCR,
    postDPDF,
    postGetAccountBalanceXml,
};

function postCIPTR(data) {

    const json = {
        ProductTypeID: data.ProductTypeID,
    };

    return dispatch => {
        dispatch(request());

        return CMCServices.postCIPTR(json)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: CMCConstants.POST_CMC_CIPTR_REQUEST};
    }

    function success(data) {
        return {type: CMCConstants.POST_CMC_CIPTR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CMCConstants.POST_CMC_CIPTR_ERROR, error}
    }
}

function postPDR(data) {

    const json = {
        SubProductID: data.SubProductID,
    };

    return dispatch => {
        dispatch(request());

        return CMCServices.postPDR(json)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: CMCConstants.POST_CMC_PDR_REQUEST};
    }

    function success(data) {
        return {type: CMCConstants.POST_CMC_PDR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CMCConstants.POST_CMC_PDR_ERROR, error}
    }
}

function postCIR(data) {

    const json = {
        ProductTypeID: data.ProductTypeID,
        SubProductID: data.SubProductID,
        Period: data.Period,
        CurrencyID: data.CurrencyID,
        InitialAccountID: data.InitialAccountID,
    };

    return dispatch => {
        dispatch(request());

        return CMCServices.postCIR(json)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: CMCConstants.POST_CMC_CIR_REQUEST};
    }

    function success(data) {
        return {type: CMCConstants.POST_CMC_CIR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CMCConstants.POST_CMC_CIR_ERROR, error}
    }
}

function postVCR(data) {

    const json = {
        ProductTypeID: data.ProductTypeID,
        SubProductID: data.SubProductID,
        Period: data.Period,
        CurrencyID: data.CurrencyID,
        Amount: data.Amount,
        InitialAccountID: data.InitialAccountID,
        AutoResume: data.AutoResume,
    };

    return dispatch => {
        dispatch(request());

        return CMCServices.postVCR(json)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: CMCConstants.POST_CMC_VCR_REQUEST};
    }

    function success(data) {
        return {type: CMCConstants.POST_CMC_VCR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CMCConstants.POST_CMC_VCR_ERROR, error}
    }
}

function postSCR(data) {

    const json = {
        ProductTypeID: data.ProductTypeID,
        SubProductID: data.SubProductID,
        Period: data.Period,
        CurrencyID: data.CurrencyID,
        Amount: data.Amount,
        InitialAccountID: data.InitialAccountID,
        AutoResume: data.AutoResume,
    };


    return dispatch => {
        dispatch(request());

        return CMCServices.postSCR(json)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: CMCConstants.POST_CMC_SCR_REQUEST};
    }

    function success(data) {
        return {type: CMCConstants.POST_CMC_SCR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CMCConstants.POST_CMC_SCR_ERROR, error}
    }
}

function postDPDF(data) {

    const json = {
        ID: data.ID,
    };

    return dispatch => {
        dispatch(request());

        return CMCServices.postDPDF(json)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: CMCConstants.POST_CMC_DPDF_REQUEST};
    }

    function success(data) {
        return {type: CMCConstants.POST_CMC_DPDF_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CMCConstants.POST_CMC_DPDF_ERROR, error}
    }
}

function postGetAccountBalanceXml(data) {

    return dispatch => {
        dispatch(request());

        return CMCServices.postGetAccountBalanceXml(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: CMCConstants.POST_CMC_GET_ACCOUNT_BALANCE_XML_REQUEST};
    }

    function success(data) {
        return {type: CMCConstants.POST_CMC_GET_ACCOUNT_BALANCE_XML_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: CMCConstants.POST_CMC_GET_ACCOUNT_BALANCE_XML_ERROR, error}
    }
}

