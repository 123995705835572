import {checkJsonProp, i18, trb} from "../../../Utilities";

export const DepositsValidation = ({values, CIPTR}) => {
    const errorFields = {};
    if (values.InitialAccountID && values.Amount > 0) {
        const acc = checkJsonProp(CIPTR, 'InitialAccounts').find(x => x.AccountID === values.InitialAccountID);
        const Amount = checkJsonProp(acc, 'Amounts.0.Value');
        if (Amount < values.Amount) {
            errorFields.Amount = trb(i18.Validations.SumToBig);
        }
    }
    return errorFields;
};
