import { i18, trb } from 'src/Utilities';
import { Tabs } from 'antd';
import { routerPath } from 'src/Config';
import { matchPath, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Container } from 'src/Components';
import React from 'react';
import { UnsignedPaymentFileDetails, UnsignedPaymentFiles, UnsignedPayments } from 'src/Containers';
import { LeftOutlined } from '@ant-design/icons';

export const UnsignedPaymentsContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const matchUnsigned = matchPath(
    {
      path: `${routerPath.payments}/${routerPath.unsigned}`,
      exact: true,
    },
    location.pathname
  );

  const matchFiles = matchPath(
    {
      path: `${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}`,
      exact: true,
    },
    location.pathname
  );

  const matchDetails = matchPath(
    {
      path: `${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}/${routerPath.unsignedFileDetails}`,
      exact: true,
    },
    location.pathname
  );

  const items = [
    (matchUnsigned !== null || matchFiles !== null) && { label: trb(i18.ContentTitles.UnsignedPayments), key: `${routerPath.payments}/${routerPath.unsigned}` },
    (matchUnsigned !== null || matchFiles !== null) && {
      label: trb(i18.ContentTitles.UnsignedPaymentFiles),
      key: `${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}`,
    },
    matchDetails !== null && {
      label: (
        <div className="back-button-link">
          <LeftOutlined className="mr-0" />

          <div>{trb(i18.Labels.Back)}</div>
        </div>
      ),
      key: routerPath.unsignedFiles,
    },
  ];

  return (
    <Container>
      <Tabs
        id="UnsignedPaymentsContainerTabs"
        defaultActiveKey={location.pathname}
        onChange={(path) => navigate(path)}
        className="mt--3 mb-3"
        activeKey={location.pathname}
        items={items}
      />

      <Routes>
        <Route
          path={routerPath.unsignedFiles + '/*'}
          element={
            <Routes>
              <Route
                path={routerPath.unsignedFileDetails}
                element={<UnsignedPaymentFileDetails />}
              />
              <Route
                index
                element={<UnsignedPaymentFiles />}
              />
            </Routes>
          }
        />
        <Route
          index
          element={<UnsignedPayments />}
        />
      </Routes>
    </Container>
  );
};

