import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import PropTypes from 'prop-types';

import { CustomIcon, i18, trb } from 'src/Utilities';

const getItem = (label, key, icon, disabled = false, onClick, children, type = 'group') => {
  return {
    key,
    icon,
    children,
    label,
    type,
    disabled,
    onClick,
    id:key,
  };
};
export const UserSelect = (props) => {
  const { data } = props;

  const privateGroup = getItem(
    trb(i18.Labels.PrivatePersons),
    'privateGroup',
    null,
    true,
    null,
    data.ManagedAccounts &&
      data.ManagedAccounts.filter((x) => x.Type === 'p').map((item, index) =>
        getItem(item.Name, `privateGroup${index}`, null, item.IsActive, () => props.selectManagingAccount(item.ID, item.Name), null, null)
      )
  );

  const businessGroup = getItem(
    trb(i18.Labels.Companies),
    'businessGroup',
    null,
    true,
    null,
    data.ManagedAccounts &&
      data.ManagedAccounts.filter((x) => x.Type === 'b').map((item, index) =>
        getItem(item.Name, `businessGroup${index}`, null, item.IsActive, () => props.selectManagingAccount(item.ID, item.Name), null, null)
      )
  );

  const items = [privateGroup, businessGroup].filter(({ children }) => children?.length > 0);

  return (
    <div id="HeaderUserInfoWrapper" className="eb_header_user">
      <div className="d-inline d-md-none pr-3">
        <CustomIcon
          type="custom"
          icon="userSelectIcon"
        />
      </div>
      <div className="d-inline">
        <div>{data.CurrentUserFullName}</div>
        <Dropdown
          placement="bottom"
          menu={{ items }}
          trigger={['click']}
        >
          <Button id="ManagingAccountButton" type="link">
            <strong>{props.managingAccount}</strong>
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

UserSelect.propTypes = {
  data: PropTypes.shape({
    CurrentUserFullName: PropTypes.string.isRequired,
    ManagedAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        ClientAddress: PropTypes.string,
        ClientCode: PropTypes.string,
        ID: PropTypes.number.isRequired,
        IDF: PropTypes.bool,
        IsActive: PropTypes.bool.isRequired,
        Name: PropTypes.string.isRequired,
        Type: PropTypes.string.isRequired,
      })
    ),
  }),
  selectManagingAccount: PropTypes.func.isRequired,
};
