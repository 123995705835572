import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { RefreshToken } from 'src/Components';
import { TranslationsInit } from 'src/Utilities';
import { axiosSetDefaultHeaderParams, store, TranslationContexts } from 'src/Redux';
import { App } from 'src/App';

import 'src/styles/app.scss';

window.VERSION = window.localStorage.getItem('VERSION') || '';

const dsn = 'https://de490db168754275b071645a308b1f2f@o966103.ingest.sentry.io/6409628';

if (import.meta.env.VITE_NODE_ENV !== 'dev') {
  Sentry.init({
    dsn,
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    release: window.VERSION,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.01,
    replaysOnErrorSampleRate: 1.0,
  });
}

axiosSetDefaultHeaderParams(window.VERSION);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <RefreshToken />
    <TranslationsInit context={TranslationContexts.eBank} />
    <TranslationsInit context={TranslationContexts.classificators} />
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <BrowserRouter history={history}>
      <App />
    </BrowserRouter>
  </Provider>
);
