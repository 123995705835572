import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { DepositActions, PMCActions } from 'src/Redux';
import { i18, SIGN_CANCEL_TYPES, trb } from 'src/Utilities';
import { ContentLoader, Notification, OperationApproval } from 'src/Components';

export const DepositsSignContainer = ({ nextStep }) => {
  const { sendDepositOffer, depositOffer, sendConfirmDeposit, confirmDepositData } = useSelector((state) => state.DepositReducers);
  const { language } = useSelector((state) => state.languageReducer);
  const dispatch = useDispatch();
  const [isConfirmDeposit, setIsConfirmDeposit] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(DepositActions.clearPostConfirmDepositData());
    };
  }, []);

  const handleSign = async () => {
    const res = await dispatch(
      DepositActions.postConfirmDeposit({
        productId: depositOffer.Response.productId,
        Language: language,
      })
    );

    if (!res?.payload?.IsSuccessfull) {
      setIsConfirmDeposit(false);
      Notification({
        type: 'warning',
        message: i18.NotificationTitles.DepositSignCanceled,
        description: i18.NotificationMessages.DepositSignCanceled,
      });
      dispatch(DepositActions.clearPostConfirmDepositData());
    } else {
      setIsConfirmDeposit(true);
    }
  };

  const successApprove = () => {
    nextStep();
  };

  const cancelApprove = (type, errorCode, notificationType = 'warning') => {
    setIsConfirmDeposit(false);
    let message;

    switch (type) {
      case SIGN_CANCEL_TYPES.TIMEOUT:
        message = 'PaymentCanceledTimeout';
        break;
      case SIGN_CANCEL_TYPES.MANUAL:
        message = 'PaymentCanceledManual';
        break;
      case SIGN_CANCEL_TYPES.REJECTED:
        message = 'PaymentCanceledRejected';
        break;
      default:
        message = errorCode?.replace('.', '');
        break;
    }

    Notification({
      type: notificationType,
      message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.DepositSignFail,
      description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.DepositSignFail,
    });
  };

  const submitSmsApprove = async (values) => {
    const response = await dispatch(PMCActions.postAPESCR(values));
    const ErrorCode = response?.payload.ErrorCode;

    if (response?.payload?.IsSuccessfull) {
      successApprove();
    } else {
      cancelApprove(null, ErrorCode, 'error');
    }
  };

  const submitMobileApprove = async () => {
    const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: confirmDepositData.RQ_ID }));
    const AuthCheckResult = response?.payload?.AuthCheckResult;
    const IsSuccessfull = response?.payload?.IsSuccessfull;
    const ErrorCode = response?.payload?.ErrorCode;

    if (AuthCheckResult === null) {
      return true;
    }

    if (AuthCheckResult === false) {
      cancelApprove(null, ErrorCode);
    } else if (AuthCheckResult && IsSuccessfull) {
      successApprove();
    }

    return false;
  };

  return (
    <div className="w-100 mt-3">
      {sendDepositOffer && !depositOffer && <ContentLoader />}

      {!isConfirmDeposit ? (
        <>
          <embed
            height="1190"
            className="w-100"
            src={`data:application/pdf;base64,${depositOffer.Response.pdfBytes}`}
          />

          <div className="my-3 text-right d-block col-5 col-md-12 pl-4 pl-md-3">
            <Button
              id="DepositsSignButton"
              onClick={handleSign}
              type="primary"
              htmlType="button"
              disabled={sendConfirmDeposit}
              loading={sendConfirmDeposit}
            >
              {trb(i18.Buttons.Sign)}
            </Button>
          </div>
        </>
      ) : (
        <>
          <div className="text-center mb-4">{trb(i18.Labels.SignDeposit)}</div>
          <OperationApproval
            RQ_ID={confirmDepositData.RQ_ID}
            AuthType={confirmDepositData.AuthType}
            code={confirmDepositData.ControlCode}
            cancelApprove={cancelApprove}
            successApprove={successApprove}
            submitSmsApprove={submitSmsApprove}
            submitMobileApprove={submitMobileApprove}
          />
        </>
      )}
    </div>
  );
};
