import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal } from 'antd';

import { currencyTextValue, dateFormat, formatDate, i18, numberFormat, trb, CURRENCIES, SIGN_CANCEL_TYPES, translationGroups } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { BulkFileActions, OMCActions } from 'src/Redux';
import { ContentLoader, Notification, Number, OperationApproval } from 'src/Components';
import { AlertNotification } from 'src/shared';

const ButtonGroup = Button.Group;

export const UnsignedPaymentFileDetails = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [showConfirm, setShowConfirm] = useState(false);
  const [approve, setApprove] = useState(false);

  const { sendSUOR, sendSUORSMS } = useSelector((state) => state.OMCReducers);

  const { FilesList, loading, error } = useSelector((state) => state.BulkFileReducer);
  const { AuthType } = useSelector((state) => state.UACReducers?.ATR);
  const { language } = useSelector((state) => state.languageReducer);

  const File = FilesList.find((item) => item.FileGuid === params.FileGuid);

  useEffect(() => {
    dispatch(BulkFileActions.getBulkPaymentsFileDetails(params.FileGuid));
  }, []);

  const handlePreview = async () => {
    setShowConfirm(true);

    const { payload } = await dispatch(BulkFileActions.getBulkPaymentsPreview(params.FileGuid));

    if (Array.isArray(payload?.PaymentErrors) && payload.PaymentErrors?.length) {
      setShowConfirm(false);
    }
  };

  const cancelApprove = (type, errorCode, notificationType = 'warning') => {
    setApprove(false);
    dispatch(BulkFileActions.cancelLoading());

    let message;

    switch (type) {
      case SIGN_CANCEL_TYPES.TIMEOUT:
        message = 'PaymentCanceledTimeout';
        break;
      case SIGN_CANCEL_TYPES.PMC10000:
        return null;
      case SIGN_CANCEL_TYPES.MANUAL:
        message = 'PaymentCanceledManual';
        break;
      case SIGN_CANCEL_TYPES.REJECTED:
        message = 'PaymentCanceledRejected';
        break;
      case SIGN_CANCEL_TYPES.ERROR_CODE:
        message = 'PaymentErrorCode';
        break;
      case SIGN_CANCEL_TYPES.VALIDATION:
        message = 'PaymentErrorValidation';
        break;
      default:
        message = errorCode?.replace('.', '');
        break;
    }

    Notification({
      type: notificationType,
      message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.Canceled,
      description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.Canceled,
    });
  };

  const clickApproveModal = async () => {
    const res = await dispatch(
      BulkFileActions.postBulkPaymentsSign({
        FileGuid: File?.FileGuid,
        Language: language,
      })
    );

    if (res.payload?.IsSuccessfull) {
      setApprove(true);
    } else {
      if (res.payload?.ErrorCode === SIGN_CANCEL_TYPES.PMC10000) {
        cancelApprove(SIGN_CANCEL_TYPES.PMC10000, null, null);
      } else if (Array.isArray(res.payload?.PaymentErrors) && res.payload?.PaymentErrors?.length) {
        cancelApprove(SIGN_CANCEL_TYPES.VALIDATION, null, 'warning');
      } else {
        cancelApprove(SIGN_CANCEL_TYPES.REJECTED, null, 'warning');
      }

      setShowConfirm(false);
    }
  };

  const successApprove = () => {
    setApprove(false);
    setShowConfirm(false);
    dispatch(BulkFileActions.removeFromList(File?.FileGuid));
    navigate(`${routerPath.payments}/${routerPath.unsigned}/${routerPath.unsignedFiles}`, {
      state: {
        paymentSuccess: true,
      },
    });
    Notification({
      type: 'success',
      message: i18.NotificationTitles.SuccessApprove,
      description: i18.NotificationMessages.SuccessApprove,
    });
  };

  const submitSmsApprove = async (values) => {
    if (error) return;
    const RQ_ID = File?.iSignToken;

    const response = await dispatch(OMCActions.postSUORSMS(values ?? { iSignToken: RQ_ID }));
    const IsSuccessfull = response?.payload?.IsSuccessfull;

    if (!IsSuccessfull) {
      cancelApprove(SIGN_CANCEL_TYPES.ERROR_CODE, null, 'error');
    } else {
      successApprove();
    }
  };

  const submitMobileApprove = async (values) => {
    if (error) return;
    const RQ_ID = File?.iSignToken;

    const response = await dispatch(OMCActions.postSUOR(values ?? { iSignToken: RQ_ID }));
    const AuthType = response?.payload?.AuthType;
    const IsSuccessfull = response?.payload?.IsSuccessfull;
    const ErrorCode = response?.payload?.ErrorCode;

    if (AuthType === null && IsSuccessfull === false) {
      return true;
    }

    if (AuthType === 'false') {
      if (ErrorCode === 'loginError.canceled') {
        cancelApprove(SIGN_CANCEL_TYPES.REJECTED, ErrorCode);
      } else {
        cancelApprove(null, ErrorCode);
      }
    } else if (IsSuccessfull === true) {
      successApprove();
    }

    return false;
  };

  return (
    <>
      {error === SIGN_CANCEL_TYPES.PMC10000 && (
        <div className="mb-4">
          <AlertNotification
            className="w-100"
            message=""
            description={trb(translationGroups.ErrorCode + error)}
            type="info"
          />
        </div>
      )}
      <h3 className="eb_content_subtitle">{trb(i18.Labels.Summary)}</h3>
      <div className="eb_table mb-3 w-100">
        <div className="row eb_table_head">
          <div className="col-md-3 text-right" />
          <div className="col-md-3 text-right" />
          <div className="col-md-3 text-right" />
          <div className="col-md-3 text-right" />
        </div>
        <div className="row eb_table_row ">
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.UploadDateTime)}</div>
            <div className="eb_fw_600">{formatDate(File?.FileUploadDate, dateFormat().dateTimeSecond)}</div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.FileName)}</div>
            <div
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              className="eb_fw_600"
            >
              {File?.FileName}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.FileId)}</div>
            <div
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              className="eb_fw_600"
            >
              {File?.FileHeaderId}
            </div>
          </div>

          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
            <div className="">{trb(i18.Labels.PaymentCount)}</div>
            <div className="eb_fw_600">{File?.PaymentsCount}</div>
          </div>
          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8">
            <div className="">{trb(i18.Labels.PaymentSum)}</div>
            <div className="eb_fw_600">{currencyTextValue(File?.PaymentValue, CURRENCIES.EUR)}</div>
          </div>
        </div>
      </div>

      {loading && !showConfirm && <ContentLoader />}
      {!!File?.ReturnValue && <h3 className="eb_content_subtitle">{trb(i18.Labels.Payments)}</h3>}
      {!!File?.ReturnValue && (
        <div className="eb_table mb-3 w-100 eb_table_wrapper_bottom_lined">
          <div className="row eb_table_head">
            <div className="col-md-3">{trb(i18.Labels.Payer)}</div>
            <div className="col-md-3 ">{trb(i18.Labels.PayeePaymentUploading)}</div>
            <div className="col-md-4 ">{trb(i18.Labels.DestinationsCode)}</div>
            <div className="col-md-2 text-right">{trb(i18.Labels.Amount)}</div>
          </div>
          {File?.ReturnValue?.map((item) => {
            return (
              <div
                className="row eb_table_row "
                key={item.ID}
              >
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                  <div className="">{item.SenderName}</div>
                  <div className="">{item.SenderAccount}</div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-3">
                  <div className="">{item.ReceiverName}</div>
                  <div className="">{item.ReceiverAccount}</div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-4">
                  <div className="">{item.PaymentPurpose}</div>
                  <div className="">{item.UniqPayCode}</div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-2 text-lg-right">
                  <div className="">{item.Amount?.[0]?.Text}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {FilesList?.length && (
        <div className="mb-3 text-right">
          <ButtonGroup>
            <Button
              id="UnsignedPaymentFileDetailsConfirmButton"
              size="large"
              type="primary"
              onClick={handlePreview}
              disabled={loading || approve}
            >
              {trb(i18.Labels.Confirm)}
            </Button>
          </ButtonGroup>
        </div>
      )}
      {showConfirm && (
        <Modal
          width={700}
          centered
          style={{ top: 0 }}
          styles={{
            body: {
              padding: 0,
              paddingBottom: 0,
            },
          }}
          open={showConfirm}
          title={<div className="eb_fw_600">{trb(i18.Labels.PaymentSubmitModal)}</div>}
          onCancel={() => {
            if (sendSUOR || sendSUORSMS) {
              return;
            }
            setShowConfirm(false);
            setApprove(false);
            dispatch(BulkFileActions.cancelLoading());
          }}
          closable
          footer={[
            <Button.Group key="footer">
              <Button
                id="UnsignedPaymentFileDetailsSubmitButton"
                type="primary"
                onClick={clickApproveModal}
                disabled={loading || approve}
              >
                {trb(i18.Buttons.Approve)}
              </Button>
              <Button
                id="UnsignedPaymentFileDetailsCancelButton"
                type="default"
                style={{ marginLeft: 0 }}
                disabled={loading || approve}
                onClick={() => {
                  if (sendSUOR || sendSUORSMS) {
                    return;
                  }
                  setShowConfirm(false);
                  setApprove(false);
                  dispatch(BulkFileActions.cancelLoading());
                }}
              >
                {trb(i18.Buttons.Cancel)}
              </Button>
            </Button.Group>,
          ]}
        >
          <div className="px-0 pb-3 p-md-3">
            {File?.Report?.map(({ Amount, Commission, CommissionCurrency, Currency, Quantity }, index) => (
              <div
                className="row"
                key={index}
              >
                <div className="col-3 col-md-3 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">{trb(i18.Labels.PaymentsCount)}</div>
                <div className="col-3 col-md-1 py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                  <Number
                    value={Quantity}
                    format={numberFormat().number}
                  />
                </div>
                <div className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                  {trb(i18.Labels.PaymentsTotalAmount)}
                  <span className="ext-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                    <Number value={Amount} />
                    {` ${Currency}`}
                  </span>
                </div>

                <div className="col-4 col-md-4 py-1 text-black-50 my-auto d-flex justify-content-between align-items-center">
                  {trb(i18.Labels.PaymentsTotalCommFee)}
                  <span className="py-1 py-md-2 text-right eb_fw_600 text-md-left text-black-70 my-auto px-0">
                    <Number value={Commission} />
                    {` ${CommissionCurrency}`}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {loading && !approve && <ContentLoader />}
          {approve && (
            <div className="pb-3">
              <OperationApproval
                RQ_ID={File?.iSignToken ?? File?.SMSToken}
                AuthType={AuthType}
                code={File?.ControlCode}
                cancelApprove={cancelApprove}
                successApprove={successApprove}
                submitSmsApprove={submitSmsApprove}
                submitMobileApprove={submitMobileApprove}
                approveCount={File?.PaymentsCount}
                errorCode={error}
                loading={sendSUOR || sendSUORSMS}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
};
