import PropTypes from "prop-types";

export const arraySearch = function (array, keys, value) {

    if (!array || !keys || !value) {
        return [];
    }

    return array.filter(item => {
            return keys.filter(key =>
                item?.[key]?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            )?.length > 0
        }
    );
};

arraySearch.propTypes = {
    array: PropTypes.array.isRequired,
    keys: PropTypes.array.isRequired,
    value: PropTypes.string.isRequired
};