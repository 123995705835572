/* eslint-disable @typescript-eslint/no-explicit-any */
import { ConfigProvider } from 'antd';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UACActions } from 'src/Redux';
import { LoginMessage } from './LoginMessage';
import { ILoginMessages } from './interfaces';

export const LoginMessages = () => {
  const dispatch = useDispatch();
  const { loginMessages }: { loginMessages: Array<ILoginMessages> } = useSelector((state) => (state as any).UACReducers);

  useEffect(() => {
    dispatch(UACActions.getLoginMessage() as any);
  }, []);

  if (!loginMessages?.length) {
    return null;
  }
  return (
    <ConfigProvider
      theme={{
        components: {
          Alert: {
            colorErrorBg: 'rgb(254, 244, 246)',
            colorErrorBorder: 'rgba(182, 26, 54, 0.2)',
            colorInfoBg: 'rgb(248, 246, 253)',
            colorInfoBorder: 'rgb(227, 221, 247)',
            lineHeight: 1.7,
            fontSize: 14,
          },
        },
      }}
    >
      {loginMessages.map(({ Message, MsgType }, index) => (
        <LoginMessage
          key={index}
          Message={Message}
          MsgType={MsgType}
        />
      ))}
    </ConfigProvider>
  );
};
