export const IconMobileStroked = ({ size = 24, color = '#4D4D4D' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 5.43506V19.4351C18 20.5396 17.1046 21.4351 16 21.4351H8C6.89543 21.4351 6 20.5396 6 19.4351V5.43506C6 4.33049 6.89543 3.43506 8 3.43506H16C17.1046 3.43506 18 4.33049 18 5.43506Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 18.4351H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 3.43506V5.43506C14.5 5.7112 14.2761 5.93506 14 5.93506H10C9.72386 5.93506 9.5 5.7112 9.5 5.43506V3.43506"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
