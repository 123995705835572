import moment from 'moment';
import sha1 from 'sha1';
import jwt_decode from 'jwt-decode';

export const checkCookieForUser = (LoginCode, authCookie) => {
  let result = true;
  if (authCookie && authCookie.TOKENS && authCookie.TOKENS.length > 0 && authCookie.exp) {
    const { TOKENS, exp } = authCookie;
    const unixNow = moment().valueOf();
    const encodedLoginCode = sha1(LoginCode);
    if (exp * 1000 > unixNow) {
      TOKENS.forEach((token) => {
        if (token === encodedLoginCode) {
          result = false;
        }
      });
    }
  }
  return result;
};

const getTokenInfo = (authCookieValue) => {
  const decodedToken = jwt_decode(authCookieValue);
  const { TOKENS } = decodedToken;
  const parsedTOKENS = JSON.parse(TOKENS);
  const jwtData = {
    TOKENS: parsedTOKENS,
    exp: decodedToken.exp,
  };

  return jwtData;
};

export const getSimpleLoginTokens = () => {
  const authCookie = document.cookie;
  const COOKIE_NAME = 'SimpleAuth';
  const allCookiesArr = authCookie.split(';');
  let targetCookie = allCookiesArr.find((item) => item.replace(/ /g, '').startsWith(COOKIE_NAME));
  targetCookie = targetCookie ? targetCookie.replace(/ /g, '') : '';
  const cookieName = targetCookie ? targetCookie.substr(0, 11) : '';

  if (cookieName === 'SimpleAuth=') {
    const authCookieValue = targetCookie.substr(11);
    return getTokenInfo(authCookieValue) ?? '';
  }
  return null;
};
