import createDecorator from "final-form-calculate";
import {checkJsonExist} from "../../../Utilities";
import {FeeSumCalculate} from "../FormCalculate";

export const FeePaymentDecorator = () => {
    return [
        createDecorator(
            {
                field: /feePaymentData/,
                updates: (value, name, values) => {
                    const arrayNumber = name.split(".")[1];

                    const fromEl = document.getElementsByName('feePaymentData.' + arrayNumber + '.N')[0],
                        toEl = document.getElementsByName('feePaymentData.' + arrayNumber + '.I')[0],
                        diffEl = document.getElementsByName('feePaymentData.' + arrayNumber + '.S')[0],
                        tariffEl = document.getElementsByName('feePaymentData.' + arrayNumber + '.TA')[0],
                        sumEl = document.getElementsByName('feePaymentData.' + arrayNumber + '.A')[0];
                    let fromValue = checkJsonExist(values, 'feePaymentData.' + arrayNumber + '.N') ? values.feePaymentData[arrayNumber].N : null,
                        toValue = checkJsonExist(values, 'feePaymentData.' + arrayNumber + '.I') ? values.feePaymentData[arrayNumber].I : null,
                        tariffValue = checkJsonExist(values, 'feePaymentData.' + arrayNumber + '.TA') ? values.feePaymentData[arrayNumber].TA : null;

                    if ((fromEl && toEl) && (fromValue !== null && toValue !== null) && diffEl) {
                        values.feePaymentData[arrayNumber].S = toValue - fromValue;
                    } else {
                        if (checkJsonExist(values, 'feePaymentData') && values.feePaymentData[arrayNumber]) {
                            delete values.feePaymentData[arrayNumber].S;
                        }
                    }
                    if (name !== 'feePaymentData.' + arrayNumber + '.T' && name !== 'feePaymentData.' + arrayNumber + '.A' && values.feePaymentData) {
                        if ((fromEl && toEl) && (fromValue != null && toValue != null) && (tariffEl && tariffValue) && sumEl) {
                            values.feePaymentData[arrayNumber].A = parseFloat(((toValue - fromValue) * tariffValue).toFixed(2));
                        } else {
                            if (sumEl && values.feePaymentData.length > 0 && values.feePaymentData.length > arrayNumber) {
                                if (values.feePaymentData[arrayNumber].A) {
                                    values.feePaymentData[arrayNumber].A = '';
                                } else {
                                    delete values.feePaymentData[arrayNumber].A;
                                }
                            }
                        }
                        //values.Payment.AmountStr = FeeSumCalculate(values);
                        return {
                            ['Payment.AmountStr']: FeeSumCalculate(values) !== 0 ? FeeSumCalculate(values) : values.Payment.AmountStr
                        }
                    } else {
                        if (sumEl) {
                            //values.Payment.AmountStr = FeeSumCalculate(values);
                            return {
                                ['Payment.AmountStr']: FeeSumCalculate(values) !== 0 ? FeeSumCalculate(values) : values.Payment.AmountStr
                            }
                        }
                    }

                    return {}
                }
            },
        )
    ];
};
