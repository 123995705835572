export const PMConstants = {
  POST_PMC_PTR_REQUEST: 'POST_PMC_PTR_REQUEST',
  POST_PMC_PTR_SUCCESS: 'POST_PMC_PTR_SUCCESS',
  POST_PMC_PTR_ERROR: 'POST_PMC_PTR_ERROR',

  POST_PMC_PVR_REQUEST: 'POST_PMC_PVR_REQUEST',
  POST_PMC_PVR_SUCCESS: 'POST_PMC_PVR_SUCCESS',
  POST_PMC_PVR_ERROR: 'POST_PMC_PVR_ERROR',

  POST_PMC_APR_REQUEST: 'POST_PMC_APR_REQUEST',
  POST_PMC_APR_SUCCESS: 'POST_PMC_APR_SUCCESS',
  POST_PMC_APR_ERROR: 'POST_PMC_APR_ERROR',

  POST_PMC_APSMSR_REQUEST: 'POST_PMC_APSMSR_REQUEST',
  POST_PMC_APSMSR_SUCCESS: 'POST_PMC_APSMSR_SUCCESS',
  POST_PMC_APSMSR_ERROR: 'POST_PMC_APSMSR_ERROR',

  POST_PMC_APESCR_REQUEST: 'POST_PMC_APESCR_REQUEST',
  POST_PMC_APESCR_SUCCESS: 'POST_PMC_APESCR_SUCCESS',
  POST_PMC_APESCR_ERROR: 'POST_PMC_APESCR_ERROR',

  GET_PMC_PAY_PERSON_TYPES_REQUEST: 'GET_PMC_PAY_PERSON_TYPES_REQUEST',
  GET_PMC_PAY_PERSON_TYPES_SUCCESS: 'GET_PMC_PAY_PERSON_TYPES_SUCCESS',
  GET_PMC_PAY_PERSON_TYPES_ERROR: 'GET_PMC_PAY_PERSON_TYPES_ERROR',

  POST_PMC_TTG_REQUEST: 'POST_PMC_TTG_REQUEST',
  POST_PMC_TTG_SUCCESS: 'POST_PMC_TTG_SUCCESS',
  POST_PMC_TTG_ERROR: 'POST_PMC_TTG_ERROR',

  POST_PMC_DTR_REQUEST: 'POST_PMC_DTR_REQUEST',
  POST_PMC_DTR_SUCCESS: 'POST_PMC_DTR_SUCCESS',
  POST_PMC_DTR_ERROR: 'POST_PMC_DTR_ERROR',

  POST_PMC_DGR_REQUEST: 'POST_PMC_DGR_REQUEST',
  POST_PMC_DGR_SUCCESS: 'POST_PMC_DGR_SUCCESS',
  POST_PMC_DGR_ERROR: 'POST_PMC_DGR_ERROR',

  POST_PMC_GGDR_REQUEST: 'POST_PMC_GGDR_REQUEST',
  POST_PMC_GGDR_SUCCESS: 'POST_PMC_GGDR_SUCCESS',
  POST_PMC_GGDR_ERROR: 'POST_PMC_GGDR_ERROR',

  POST_PMC_SGPVR_REQUEST: 'POST_PMC_SGPVR_REQUEST',
  POST_PMC_SGPVR_SUCCESS: 'POST_PMC_SGPVR_SUCCESS',
  POST_PMC_SGPVR_ERROR: 'POST_PMC_SGPVR_ERROR',

  POST_PMC_SDPVR_REQUEST: 'POST_PMC_SDPVR_REQUEST',
  POST_PMC_SDPVR_SUCCESS: 'POST_PMC_SDPVR_SUCCESS',
  POST_PMC_SDPVR_ERROR: 'POST_PMC_SDPVR_ERROR',

  POST_PMC_SPR_REQUEST: 'POST_PMC_SPR_REQUEST',
  POST_PMC_SPR_SUCCESS: 'POST_PMC_SPR_SUCCESS',
  POST_PMC_SPR_ERROR: 'POST_PMC_SPR_ERROR',

  POST_PMC_UTR_REQUEST: 'POST_PMC_UTR_REQUEST',
  POST_PMC_UTR_SUCCESS: 'POST_PMC_UTR_SUCCESS',
  POST_PMC_UTR_ERROR: 'POST_PMC_UTR_ERROR',

  POST_PMC_CGPSGNR_REQUEST: 'POST_PMC_CGPSGNR_REQUEST',
  POST_PMC_CGPSGNR_SUCCESS: 'POST_PMC_CGPSGNR_SUCCESS',
  POST_PMC_CGPSGNR_ERROR: 'POST_PMC_CGPSGNR_ERROR',

  POST_PMC_UPR_REQUEST: 'POST_PMC_UPR_REQUEST',
  POST_PMC_UPR_SUCCESS: 'POST_PMC_UPR_SUCCESS',
  POST_PMC_UPR_ERROR: 'POST_PMC_UPR_ERROR',
  CLEAR_PMC_UPR_DATA: 'CLEAR_PMC_UPR_DATA',

  POST_PMC_PUPR_REQUEST: 'POST_PMC_PUPR_REQUEST',
  POST_PMC_PUPR_SUCCESS: 'POST_PMC_PUPR_SUCCESS',
  POST_PMC_PUPR_ERROR: 'POST_PMC_PUPR_ERROR',
  CLEAR_PMC_PUPR_DATA: 'CLEAR_PMC_PUPR_DATA',

  POST_PMC_RTFGR_REQUEST: 'POST_PMC_RTFGR_REQUEST',
  POST_PMC_RTFGR_SUCCESS: 'POST_PMC_RTFGR_SUCCESS',
  POST_PMC_RTFGR_ERROR: 'POST_PMC_RTFGR_ERROR',

  POST_PMC_SUPR_REQUEST: 'POST_PMC_SUPR_REQUEST',
  POST_PMC_SUPR_SUCCESS: 'POST_PMC_SUPR_SUCCESS',
  POST_PMC_SUPR_ERROR: 'POST_PMC_SUPR_ERROR',
  CLEAR_PMC_SUPR_DATA: 'CLEAR_PMC_SUPR_DATA',

  GET_HISTORICAL_PAYMENTS_REQUEST: 'GET_HISTORICAL_PAYMENTS_REQUEST',
  GET_HISTORICAL_PAYMENTS_SUCCESS: 'GET_HISTORICAL_PAYMENTS_SUCCESS',
  GET_HISTORICAL_PAYMENTS_ERROR: 'GET_HISTORICAL_PAYMENTS_ERROR',

  GET_HISTORICAL_PAYMENT_BY_ID_REQUEST: 'GET_HISTORICAL_PAYMENT_BY_ID_REQUEST',
  GET_HISTORICAL_PAYMENT_BY_ID_SUCCESS: 'GET_HISTORICAL_PAYMENT_BY_ID_SUCCESS',
  GET_HISTORICAL_PAYMENT_BY_ID_ERROR: 'GET_HISTORICAL_PAYMENT_BY_ID_ERROR',

  POST_CHECK_IBAN_MEMBER_REQUEST: 'POST_CHECK_IBAN_MEMBER_REQUEST',
  POST_CHECK_IBAN_MEMBER_SUCCESS: 'POST_CHECK_IBAN_MEMBER_SUCCESS',
  POST_CHECK_IBAN_MEMBER_ERROR: 'POST_CHECK_IBAN_MEMBER_ERROR',

  CLEAR_CHECK_IBAN_MEMBER: 'CLEAR_CHECK_IBAN_MEMBER',
  CLEAR_HISTORICAL_PAYMENTS: 'CLEAR_HISTORICAL_PAYMENTS',
  CLEAR_PVR: 'CLEAR_PVR',

  POST_FX_RATE_REQUEST: 'POST_FX_RATE_REQUEST',
  POST_FX_RATE_SUCCESS: 'POST_FX_RATE_SUCCESS',
  POST_FX_RATE_ERROR: 'POST_FX_RATE_ERROR',

  GET_COUNTRY_CURRENCIES_REQUEST: 'GET_COUNTRY_CURRENCIES_REQUEST',
  GET_COUNTRY_CURRENCIES_SUCCESS: 'GET_COUNTRY_CURRENCIES_SUCCESS',
  GET_COUNTRY_CURRENCIES_ERROR: 'GET_COUNTRY_CURRENCIES_ERROR',

  GET_PAYMENT_PURPOSE_CODE_REQUEST: 'GET_PAYMENT_PURPOSE_CODE_REQUEST',
  GET_PAYMENT_PURPOSE_CODE_SUCCESS: 'GET_PAYMENT_PURPOSE_CODE_SUCCESS',
  GET_PAYMENT_PURPOSE_CODE_ERROR: 'GET_PAYMENT_PURPOSE_CODE_ERROR',

  GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_REQUEST:
    'GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_REQUEST',
  GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_SUCCESS:
    'GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_SUCCESS',
  GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_ERROR:
    'GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_ERROR',

  SET_TARGET2_DATA: 'SET_TARGET2_DATA',

  GET_IBAN_LENGTH_REQUEST: 'GET_IBAN_LENGTH_REQUEST',
  GET_IBAN_LENGTH_SUCCESS: 'GET_IBAN_LENGTH_SUCCESS',
  GET_IBAN_LENGTH_ERROR: 'GET_IBAN_LENGTH_ERROR',

  GET_CHECK_IBAN_VALID_REQUEST: 'GET_CHECK_IBAN_VALID_REQUEST',
  GET_CHECK_IBAN_VALID_SUCCESS: 'GET_CHECK_IBAN_VALID_SUCCESS',
  GET_CHECK_IBAN_VALID_ERROR: 'GET_CHECK_IBAN_VALID_ERROR',

  SET_IBAN_VALID_VALUE: 'SET_IBAN_VALID_VALUE',

  GET_FOREX_PAYMENTS_REQUEST: 'GET_FOREX_PAYMENTS_REQUEST',
  GET_FOREX_PAYMENTS_SUCCESS: 'GET_FOREX_PAYMENTS_SUCCESS',
  GET_FOREX_PAYMENTS_ERROR: 'GET_FOREX_PAYMENTS_ERROR'
}
