import React, { useEffect, useState } from 'react';
import { Button, Collapse, Modal, Switch } from 'antd';
import { i18, trb } from 'src/Utilities';
import moment from 'moment';
import { useSelector } from 'react-redux';

const YES = 'yes';
const NO = 'no';

const ADVERTISEMENT = 'cookielawinfo-checkbox-advertisment';
const ANALYTICS = 'cookielawinfo-checkbox-analytics';
const FUNCTIONAL = 'cookielawinfo-checkbox-functional';
const NECESSARY = 'cookielawinfo-checkbox-necessary';
const OTHERS = 'cookielawinfo-checkbox-others';
const PERFORMANCE = 'cookielawinfo-checkbox-performance';
const VIEWED_POLICY = 'viewed_cookie_policy';

const initialCookiesMap = {
  [ADVERTISEMENT]: YES,
  [ANALYTICS]: YES,
  [FUNCTIONAL]: YES,
  [NECESSARY]: YES,
  [OTHERS]: YES,
  [PERFORMANCE]: YES,
  [PERFORMANCE]: YES,
  [VIEWED_POLICY]: YES,
};

export const CookiePolicy = () => {
  const VersionReducer = useSelector((state) => state.VersionReducer);
  const [modalVisible, setModalVisible] = useState(false);
  const [cookieUpdated, setCookieUpdated] = useState(false);
  const [policyAvailable, setPolicyAvailable] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [cookieMap, setCookieMap] = useState(initialCookiesMap);
  const checkIfAllCookiesSet = () => {
    const cookies = document.cookie?.split('; ') ?? [];
    let allSet = true;
    Object.keys(initialCookiesMap).forEach((key) => {
      if (-1 === cookies.findIndex((cookie) => cookie.startsWith(key))) {
        allSet = false;
      }
    });
    return allSet;
  };

  useEffect(() => {
    setPolicyAvailable(checkIfAllCookiesSet());
  }, [cookieUpdated]);

  const handleSave = () => {
    const exp = moment().add(1, 'month').format('dd, DD MMM YYYY HH:mm:ss UTC');
    Object.keys(cookieMap).forEach((key) => {
      document.cookie = `${key}=${cookieMap[key]}; expires=${exp}; secure`;
    });

    setCookieUpdated(!cookieUpdated);
  };

  const handleClickPopUpOpen = () => {
    setModalVisible(true);
    const exp = moment().add(1, 'month').format('dd, DD MMM YYYY HH:mm:ss UTC');
    document.cookie = `${VIEWED_POLICY}=${cookieMap[VIEWED_POLICY]}; expires=${exp}; secure`;
  };

  const handleClickAgreeModal = () => {
    handleSave();
    setModalVisible(false);
    setCookieUpdated(!cookieUpdated);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const cookiesItems = [
    {
      label: trb(i18.Labels.CookiesNecessary),
      extra: (
        <Switch
          defaultChecked
          disabled={true}
        />
      ),
      key: 1,
      children: <span dangerouslySetInnerHTML={{ __html: trb(i18.Labels.CookiesNecessaryText) }} />,
    },
    {
      label: trb(i18.Labels.CookiesFunctional),
      extra: (
        <Switch
          defaultChecked
          checked={cookieMap[FUNCTIONAL] === YES}
          onChange={(isOn) =>
            setCookieMap({
              ...cookieMap,
              [FUNCTIONAL]: isOn ? YES : NO,
            })
          }
        />
      ),
      key: 2,
      children: <span dangerouslySetInnerHTML={{ __html: trb(i18.Labels.CookiesFunctionalText) }} />,
    },
    {
      label: trb(i18.Labels.CookiesPerformance),
      extra: (
        <Switch
          defaultChecked
          checked={cookieMap[PERFORMANCE] === YES}
          onChange={(isOn) =>
            setCookieMap({
              ...cookieMap,
              [PERFORMANCE]: isOn ? YES : NO,
            })
          }
        />
      ),
      key: 3,
      children: <span dangerouslySetInnerHTML={{ __html: trb(i18.Labels.CookiesPerformanceText) }} />,
    },
    {
      label: trb(i18.Labels.CookiesAnalytics),
      extra: (
        <Switch
          defaultChecked
          checked={cookieMap[ANALYTICS] === YES}
          onChange={(isOn) =>
            setCookieMap({
              ...cookieMap,
              [ANALYTICS]: isOn ? YES : NO,
            })
          }
        />
      ),
      key: 4,
      children: <span dangerouslySetInnerHTML={{ __html: trb(i18.Labels.CookiesAnalyticsText) }} />,
    },
    {
      label: trb(i18.Labels.CookiesAdvertisement),
      extra: (
        <Switch
          defaultChecked
          checked={cookieMap[ADVERTISEMENT] === YES}
          onChange={(isOn) =>
            setCookieMap({
              ...cookieMap,
              [ADVERTISEMENT]: isOn ? YES : NO,
            })
          }
        />
      ),
      key: 5,
      children: <span dangerouslySetInnerHTML={{ __html: trb(i18.Labels.CookiesAdvertisementText) }} />,
    },
    {
      label: trb(i18.Labels.CookiesOthers),
      extra: (
        <Switch
          defaultChecked
          checked={cookieMap[OTHERS] === YES}
          onChange={(isOn) =>
            setCookieMap({
              ...cookieMap,
              [OTHERS]: isOn ? YES : NO,
            })
          }
        />
      ),
      key: 6,
      children: <span dangerouslySetInnerHTML={{ __html: trb(i18.Labels.CookiesOthersText) }} />,
    },
  ];
  if (VersionReducer.version !== window.VERSION) {
    return null;
  }

  return (
    <>
      <div
        style={policyAvailable ? { display: 'none' } : {}}
        className="cookiePolicy"
      >
        <div
          style={{ paddingLeft: 20 }}
          className="container flex_block"
        >
          <div
            className="text"
            dangerouslySetInnerHTML={{ __html: trb(i18.Labels.CookiesText) }}
          ></div>
          <div className="buttons">
            <a
              onClick={handleClickPopUpOpen}
              className="cookiePolicy__agree btn open_cookie_type_popup"
            >
              {trb(i18.Buttons.ChangeSettings)}
            </a>
            <a
              onClick={handleSave}
              className="cookiePolicy__agree btn btn_submit"
            >
              {trb(i18.Buttons.AgreeWithAll)}
            </a>
          </div>
        </div>
      </div>
      {modalVisible && (
        <Modal
          width={800}
          zIndex={999999}
          centered
          style={{ top: 0 }}
          styles={{
            body: {
              padding: 0,
              paddingBottom: 0,
            },
          }}
          open={modalVisible}
          title={<div className="main_heading">{trb(i18.Labels.CookiesSettings)}</div>}
          closable={true}
          onCancel={() => handleCloseModal()}
          className="cookies_modal"
          footer={[
            <div
              key="footer"
              className="popup_cookies text-right"
            >
              <Button
                id="CookiePolicyAgreeButton"
                type="primary"
                htmlType="submit"
                onClick={handleClickAgreeModal}
              >
                <span>{trb(i18.Buttons.Agree)}</span>
              </Button>
            </div>,
          ]}
        >
          <div className="container flex_block">
            <div className="page_text">
              {showMore ? trb(i18.Labels.PrivacyOverviewText) : trb(i18.Labels.PrivacyOverviewText).substring(0, trb(i18.Labels.PrivacyOverviewText).length / 3) + '...'}
              <a
                className="d-block mt-3 mb-3"
                onClick={() => setShowMore(!showMore)}
              >
                {trb(i18.Labels[showMore ? 'ShowLess' : 'ShowMore'])}
              </a>
            </div>
            <Collapse
              collapsible={'header'}
              items={cookiesItems}
            />
          </div>
        </Modal>
      )}
    </>
  );
};
