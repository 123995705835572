import { Card, Col, Layout, Row, Form } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LoginHeader } from '../LoginPage/Header';
import { LoginFooter } from '../LoginPage/Footer';
import { LoginImage } from '../../Assets/svg';
import { Button, InputOTP, Typography, UserBlocked } from '../../shared';
import { i18, trb } from '../../Utilities';
import { UACActions, UACConstants } from '../../Redux';
import { routerPath } from '../../Config';
import { BackButton } from '../LoginPage/Content/LoginSteps';

export const Ondato = () => {
  const dispatch = useDispatch();
  const { sendUACResendToken, resendToken } = useSelector((state: any) => state.UACReducers);
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const [isBlocked, setIsBlocked] = useState(false);
  const [resendCount, setResendCount] = useState(0);

  useEffect(() => {
    if (!location.state) {
      navigate(routerPath.login);
    }
  }, []);

  useEffect(() => {
    const worker = new Worker(`${import.meta.env.VITE_ROOT}workerCountdown.js`);

    if (window.Worker) {
      const endTime = new Date().getTime() + 90 * 1000;

      worker.postMessage({
        type: 'countdown',
        time: endTime,
      });

      worker.onmessage = (event) => {
        if (event.data.done) {
          setTimeLeft(null);
        } else {
          setTimeLeft(`${event.data.minutes.toString().padStart(2, '0')}:${event.data.seconds.toString().padStart(2, '0')}`);
        }
      };

      return () => worker.terminate();
    } else {
      console.log("Your browser doesn't support Web Workers.");
    }

    return () => worker.terminate();
  }, [resendCount]);

  const handleSubmit = async (value: string) => {
    setIsLoading(true);

    const res = await dispatch<any>(
      UACActions.postCheckLogin({
        ...location.state,
        LoginToken: resendToken?.RefreshToken ?? location.state?.LoginToken,
        LoginAuthCode: value,
      })
    );

    if (res && res.type === UACConstants.POST_UAC_CHECK_LOGIN_SUCCESS) {
      navigate(routerPath.index);
    } else {
      if (res?.error?.data?.UserBocked) {
        setIsBlocked(true);
      }

      if (res.error.data.LoginStatusCode === 'loginError.X007') {
        navigate(routerPath.login, {
          replace: true,
          state: { ...location.state, step: 1, isIdentificationError: false, customIdentificationError: null },
        });
      } else {
        form.resetFields();
        setError(trb(res.error.data.LoginStatusCode, { value: res.error.data.RemainingAttempts }));
      }
    }

    setIsLoading(false);
  };

  const handleResend = async () => {
    const res = await dispatch<any>(
      UACActions.postResendToken({
        LoginCode: location.state?.LoginCode,
        LoginToken: resendToken?.RefreshToken ?? location.state?.LoginToken,
        LoginSystem: location.state?.LoginSystem,
        Language: location.state?.Language,
      })
    );

    if (res && res.type === UACConstants.POST_UAC_RESEND_TOKEN_SUCCESS) {
      if (res.payload?.IsError) {
        navigate(routerPath.index);
      } else {
        setTimeLeft(null);
        setResendCount((prevState) => prevState + 1);
      }
    } else {
      navigate(routerPath.login, {
        replace: true,
        state: { ...location.state, step: 1, isIdentificationError: true, customIdentificationError: i18.ErrorDescriptions.CodeTimeout },
      });
    }
  };

  return (
    <div className="w-100 login_wrapper">
      <Layout>
        <Layout.Header
          className="px-0"
          style={{ height: 96 }}
        >
          <LoginHeader />
        </Layout.Header>
        <Layout.Content className="d-flex">
          <Row className="login_infoblock">
            <Col
              md={12}
              xs={24}
            >
              <Card className="d-flex w-100 h-100 justify-content-center align-items-center login_card">
                <div className="login_card_wrapper">
                  <BackButton
                    handleBackButtonClick={() => {
                      navigate(routerPath.login, {
                        replace: true,
                        state: { ...location.state, step: 1, isIdentificationError: false, customIdentificationError: null },
                      });
                    }}
                  />
                  <div className="mb-3">
                    <Typography.Text className="login_title mb-3">{trb(i18.Labels.LoginBiometricSMS)}</Typography.Text>
                  </div>
                  {isBlocked ? (
                    <UserBlocked />
                  ) : (
                    <div>
                      <div className="mb-4">
                        <Typography.Text className="login_subTitle">
                          <div dangerouslySetInnerHTML={{ __html: trb(i18.Labels.LoginBiometricSMSSend, { value: location.state?.LoginStatusCode }) }}></div>
                        </Typography.Text>
                      </div>
                      <Form form={form}>
                        <Form.Item
                          name="password"
                          className="mb-2"
                        >
                          <InputOTP
                            size="large"
                            length={4}
                            inputMode="numeric"
                            onChange={async (value) => {
                              await handleSubmit(value);
                            }}
                            value={undefined}
                            onInput={() => setError(undefined)}
                            disabled={isLoading}
                            status={error ? 'error' : undefined}
                            id="BiometricSMSCode"
                            autoFocus={true}
                          />
                        </Form.Item>
                      </Form>
                      {error && <Typography.Text type="danger">{error}</Typography.Text>}
                      <div className="mt-5">
                        {timeLeft ? (
                          <div
                            className="login-resend-timer"
                            dangerouslySetInnerHTML={{ __html: trb(i18.Labels.LoginResendTimer, { value: timeLeft }) }}
                          />
                        ) : (
                          <Button
                            className="p-0 fw-bold"
                            onClick={handleResend}
                            type="link"
                            disabled={sendUACResendToken}
                          >
                            {trb(i18.Buttons.Resend)}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </Col>
            <Col
              md={12}
              xs={24}
              className="d-none d-md-flex w-100 justify-content-center align-items-center py-5"
            >
              <LoginImage />
            </Col>
          </Row>
        </Layout.Content>
        <Layout.Footer className="p-0">
          <LoginFooter />
        </Layout.Footer>
      </Layout>
    </div>
  );
};
