import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { blobToFileDownload, i18, trb } from 'src/Utilities';
import { DepositActions, DepositConstants } from 'src/Redux';
import { routerPath } from 'src/Config';
import { DepositsPreview } from 'src/Containers';
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import { AlertNotification } from 'src/shared';

export const DepositsSuccessContainer = ({ data }) => {
  const { CIPTR } = useSelector((state) => state.CMCReducers);
  const { depositOffer } = useSelector((state) => state.DepositReducers);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const download = async () => {
    const response = await dispatch(
      DepositActions.postDepositDocument({
        productId: depositOffer?.Response?.productId,
      })
    );

    if (response.type === DepositConstants.POST_DEPOSIT_DOCUMENT_SUCCESS) {
      const title = `${trb(i18.PrintLabels.DepositPDFName)}.pdf`;
      blobToFileDownload(title, Buffer.from(response.payload.pdfBytes, 'base64'), 'application/pdf');
    }
  };

  const close = () => {
    navigate(routerPath.index);
  };

  const buttons = [
    {
      title: i18.Buttons.Download,
      func: download,
      props: {
        type: 'primary',
      },
    },
    {
      title: i18.Buttons.Close,
      func: close,
    },
  ];

  const subProducts = CIPTR?.InitialAccounts;
  const InterestPaymentTypes = CIPTR?.InterestPaymentTypes;
  const Currencies = CIPTR?.Currencies;
  let autoResumeText = null;

  switch (data.AutoResume) {
    case 'WithInterest':
      autoResumeText = trb(i18.DepositRenew.WithInterest);
      break;
    case 'WithoutInterest':
      autoResumeText = trb(i18.DepositRenew.WithoutInterest);
      break;
    case 'NotApplicable':
      autoResumeText = trb(i18.DepositRenew.NotApplicable);
      break;
    default:
      autoResumeText = trb(i18.Messages.NoData);
  }

  const data2 = {
    ProductType: trb(i18.DepositTypes.TermDeposit),
    AutoResume: autoResumeText,
    Confirm: true,
    InitialAccount: subProducts ? subProducts.find((x) => x.AccountID === data.InitialAccountID).AccountNumber : null,
    SubProduct: InterestPaymentTypes ? InterestPaymentTypes.find((x) => x.ID === data.SubProductID).Caption : null,
    Currency: Currencies ? Currencies.find((x) => (x.ID = data.CurrencyID)).Title : null,
    Amount: data.Amount,
    Interest: data.Interest,
    Period: data.Period + ' ' + trb(i18.Labels.MonthNameShort),
  };

  return (
    <>
      <AlertNotification
        message={trb(i18.MessageTitles.DepositCreateSuccess)}
        type="success"
        alertClassName="py-3 mb-3"
      />
      <DepositsPreview
        data={data2}
        buttons={buttons}
      />
    </>
  );
};
