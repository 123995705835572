import { useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, FormField, Select } from 'src/shared';
import { CURRENCIES, accountsColorObject, currencyFormat, i18, trb } from 'src/Utilities';
import { AMCActions } from 'src/Redux';

interface IProps {
  overdraft?: boolean;
}

interface IOptionItem {
  AccountNumber: string;
  AccountID: string;
  Amounts: {
    Value: number;
    Currency: string;
    Text: string;
  }[];
  RelatedProductNumber?: string;
  RelatedProductValue?: number;
  CurrencyName?: string;
  color?: string;
}

export const FieldInitialAccount = ({ overdraft }: IProps) => {
  const dispatch: any = useDispatch();
  const { setFieldValue } = useFormikContext();
  const { forexPayments } = useSelector((state: any) => state.PMCReducers);
  const { accountOverdraftInfoLoader } = useSelector((state: any) => state.AMCReducers);

  const fetchOverdraft = async (accountId: string, option: IOptionItem) => {
    await setFieldValue('Payment.AmountAvailable', option?.Amounts[0]?.Value);
    await setFieldValue('Payment.Currency', option?.Amounts[0]?.Currency);

    if (option?.Amounts[0]?.Currency !== CURRENCIES.EUR) {
      await setFieldValue('Payment.OverdraftValue', null);
    } else {
      const res = await dispatch(AMCActions.getAccountOverdraft(accountId));

      if (res?.payload && res?.payload?.totalAvailable !== null) {
        await setFieldValue('Payment.OverdraftValue', res.payload.totalAvailable);
        await setFieldValue('Payment.AmountAvailable', res.payload.totalAvailable);
      } else {
        await setFieldValue('Payment.OverdraftValue', null);
      }
    }
  };

  return (
    <div>
      <FormField
        name="Payment.InitialAccountID"
        label={trb(i18.Labels.FromAccount)}
      >
        <Select
          options={forexPayments?.InitialAccounts?.map((item: IOptionItem) => ({
            ...item,
            label: (
              <div className="composedDropdownLabel">
                <div className="object-fit-contain">
                  <Avatar
                    // size={17}
                    style={{
                      backgroundColor: accountsColorObject[(item?.color ?? 1) as keyof typeof accountsColorObject],
                      marginBottom: 0,
                    }}
                  />
                </div>
                <div className="mt-1 composedDropdownLabelText">
                  {`${item.AccountNumber} (${item.Amounts?.[0]?.Text})${
                    item.RelatedProductNumber ? ` ${item.RelatedProductNumber} (${currencyFormat(item.RelatedProductValue)} ${item.CurrencyName})` : ''
                  }`}
                </div>
              </div>
            ),
            labelText: `${item.AccountNumber} (${item.Amounts?.[0]?.Text})${
              item.RelatedProductNumber ? ` ${item.RelatedProductNumber} (${currencyFormat(item.RelatedProductValue)} ${item.CurrencyName})` : ''
            }`,
            value: item.AccountID,
          }))}
          handleChange={async (value, option: IOptionItem) => (overdraft ? await fetchOverdraft(value, option) : null)}
          loading={accountOverdraftInfoLoader}
        />
      </FormField>
    </div>
  );
};
