import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {i18, required, requiredAny, checkJsonProp} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCOtherInformation = (props) => {
    const ClientPcComp = checkJsonProp(props.data, 'clientPcComp'),
          ClientEmpComp = checkJsonProp(props.data, 'clientEmpComp');

    let ClientProfile = checkJsonProp(props.data, 'clientProfile');
    ClientProfile[0].data.clientIdv = ClientPcComp && ClientPcComp.length ? 1 : ClientProfile[0].data.clientIdv;
    ClientProfile[0].data.clientEmployee = ClientEmpComp && ClientEmpComp.length ? 1 : ClientProfile[0].data.clientEmployee;
    ClientProfile[0].data.clientCivilServant = checkJsonProp(ClientProfile, '0.data.otherActivity') && checkJsonProp(ClientProfile, '0.data.otherActivity').length ? '1' : '0'

    const initialData = {
              clientEmpComp: ClientEmpComp && ClientEmpComp.length ? ClientEmpComp : [{}],
              clientPcComp: ClientPcComp && ClientPcComp.length ? ClientPcComp : [{}],
              clientProfile: ClientProfile,
              clientEmploymentValid: false,
          },
          mapData = [
          {
              messageBefore: i18.NotificationMessages.KYCOtherInformation,
              title: i18.Labels.ClientEmployment,
              elements: [{
                  translate: i18.Labels.ClientPcComp,
                  name: 'clientProfile.0.data.clientIdv',
                  component: FormFieldType.checkboxOnly,
                  className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
              }]
          },{
              showWhen: 'clientProfile.0.data.clientIdv',
              showIs: true,
              canDuplicate: true,
              duplicateName: 'clientPcComp',
              duplicateInside: true,
              maxRows: props.classifikators.PCC.length - 1,
              elements: [{
                  title: i18.Labels.ClientPcType,
                  name: `clientPcComp.$index.clientPcType`,
                  component: FormFieldType.select,
                  options: props.classifikators.PCC,
                  optionsLabel: 'label',
                  optionsValue: 'value',
                  optionsDisabled: 'disabled',
                  className: 'col-6',
                  noInline: true,
                  validate: required,
              },{
                  title: i18.Labels.ClientPcCompBusiness,
                  name: `clientPcComp.$index.data.clientPcCompBusiness`,
                  component: FormFieldType.selectSearch,
                  options: props.classifikators.CBB,
                  optionsLabel: 'label',
                  optionsValue: 'value',
                  className: 'col-6',
                  noInline: true,
                  validate: required,
              }],
          },{
              elements: [{
                  translate: i18.Labels.ClientEmpComp,
                  name: 'clientProfile.0.data.clientEmployee',
                  component: FormFieldType.checkboxOnly,
                  className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
              }],
          },{
              showWhen: 'clientProfile.0.data.clientEmployee',
              showIs: true,
              canDuplicate: true,
              duplicateName: 'clientEmpComp',
              duplicateInside: true,
              maxRows: props.classifikators.EMPC.length - 1,
              elements: [{
                  title: i18.Labels.ClientEmpCompType,
                  name: `clientEmpComp.$index.clientEmpCompType`,
                  component: FormFieldType.select,
                  options: props.classifikators.EMPC,
                  optionsLabel: 'label',
                  optionsValue: 'value',
                  optionsDisabled: 'disabled',
                  className: 'col-6',
                  noInline: true,
                  validate: required,
              },{
                  title: i18.Labels.ClientEmpCompName,
                  name: `clientEmpComp.$index.data.clientEmpCompName`,
                  component: FormFieldType.input,
                  className: 'col-6',
                  noInline: true,
                  validate: required,
              },{
                  title: i18.Labels.ClientEmpCompCountry,
                  name: `clientEmpComp.$index.data.clientEmpCompCountry`,
                  component: FormFieldType.selectSearch,
                  options: props.classifikators.CISO,
                  optionsLabel: 'label',
                  optionsValue: 'value',
                  className: 'col-6',
                  noInline: true,
                  validate: required,
              },{
                  title: i18.Labels.ClientEmpPosition,
                  name: `clientEmpComp.$index.data.clientEmpPosition`,
                  component: FormFieldType.input,
                  className: 'col-6',
                  noInline: true,
                  validate: required,
              }],
          },{
              elements: [{
                  translate: i18.Labels.ClientCivilServant,
                  name: 'clientProfile.0.data.clientCivilServant',
                  component: FormFieldType.checkboxOnly,
                  className: 'col-12 col-md-4 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
              }],
          },{
              showWhen: 'clientProfile.0.data.clientCivilServant',
              showIs: true,
              elements: [{
                  title: i18.Labels.OtherActivity,
                  name: `clientProfile.0.data.otherActivity`,
                  component: FormFieldType.input,
                  className: 'col-6',
                  noInline: true,
                  validate: required,
              }],
          },{
              // title: ' ',
              elements: [{
                  translate: i18.Labels.ClientPension,
                  name: 'clientProfile.0.data.clientPension',
                  component: FormFieldType.checkboxOnly,
                  className: 'col-12 col-md-12 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
              },{
                  translate: i18.Labels.ClientStudent,
                  name: 'clientProfile.0.data.clientStudent',
                  component: FormFieldType.checkboxOnly,
                  className: 'col-12 col-md-12 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
              },{
                  translate: i18.Labels.ClientFarmer,
                  name: 'clientProfile.0.data.clientFarmer',
                  component: FormFieldType.checkboxOnly,
                  className: 'col-12 col-md-12 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
              },{
                  translate: i18.Labels.ClientNonEmp,
                  name: 'clientProfile.0.data.clientNonEmp',
                  component: FormFieldType.checkboxOnly,
                  className: 'col-12 col-md-12 form-field-title ml-3 pl-3 ml-md-5 pl-md-4 my-1',
              },{
                  name: 'clientEmploymentValid',
                  component: FormFieldType.checkbox,
                  checkedNumber: false,
                  className: 'col-12 text-center m-2 only-error ',
                  validate: requiredAny,
              }]
          }
      ];

      const decorators = [
          createDecorator({
              field: /clientPcComp\.*.*clientPcType/,
              updates: (value, name, allValues) => {
                  props.classifikators.PCC.map((item)=>{
                      item.disabled = checkJsonProp(allValues, 'clientPcComp').some((addItem) => {
                          return addItem.clientPcType == item.value;
                      }) ? true : false;
                      item.disabled = item.disabled || item.value == value ? true : false;
                      return item;
                  })
                  return {}
                }
          },{
              field: /clientEmpComp\.*.*clientEmpCompType/,
              updates: (value, name, allValues) => {
                  props.classifikators.EMPC.map((item)=>{
                      item.disabled = checkJsonProp(allValues, 'clientEmpComp').some((addItem) => {
                          return addItem.clientEmpCompType == item.value;
                      }) ? true : false;
                      item.disabled = item.disabled || item.value == value ? true : false;
                      return item;
                  })
                  return {}
                }
          },{
              field: /\.*/,
              updates: {
                  'clientEmploymentValid': (value, allValues) => {
                      const arrEmpComp = checkJsonProp(allValues, 'clientEmpComp') && checkJsonProp(allValues, 'clientEmpComp').length > 0 ? checkJsonProp(allValues, 'clientEmpComp').some((row) => {
                          row.data = checkJsonProp(row, 'data') ? row.data : {};
                          return row.clientEmpCompType && Object.entries(row.data).every((item) => item[1]) && Object.entries(row.data).length == 3;
                      }) : false;
                      const arrPcComp = checkJsonProp(allValues, 'clientPcComp') && checkJsonProp(allValues, 'clientPcComp').length > 0 ? checkJsonProp(allValues, 'clientPcComp').some((row) => {
                          row.data = checkJsonProp(row, 'data') ? row.data : {};
                          return row.clientPcType && Object.entries(row.data).every((item) => item[1]) && Object.entries(row.data).length == 1;
                      }) : false;

                      return (checkJsonProp(allValues, 'clientProfile.0.data.clientEmployee') == 1 && arrEmpComp)
                      || (checkJsonProp(allValues, 'clientProfile.0.data.clientIdv') == 1 && arrPcComp)
                      || (checkJsonProp(allValues, 'clientProfile.0.data.clientCivilServant') == 1 && checkJsonProp(allValues, 'clientProfile.0.data.otherActivity'))
                      || (checkJsonProp(allValues, 'clientProfile.0.data.clientCivilServant') == 1 && checkJsonProp(allValues, 'clientProfile.0.data.otherActivity'))
                      || (checkJsonProp(allValues, 'clientProfile.0.data.clientNonEmp') != '' && checkJsonProp(allValues, 'clientProfile.0.data.clientNonEmp') != 0)
                      || (checkJsonProp(allValues, 'clientProfile.0.data.clientFarmer') != '' && checkJsonProp(allValues, 'clientProfile.0.data.clientFarmer') != 0)
                      || (checkJsonProp(allValues, 'clientProfile.0.data.clientStudent') != '' && checkJsonProp(allValues, 'clientProfile.0.data.clientStudent') != 0)
                      || (checkJsonProp(allValues, 'clientProfile.0.data.clientPension') != '' && checkJsonProp(allValues, 'clientProfile.0.data.clientPension') != 0)
                      || false;
                  }
              }
          })
      ];

      return <KYCFormSwitch approveSubmitText={i18.NotificationMessages.KYCApproveSubmit} permissons={props.permissons} title={i18.ContentSubTitles.OtherInformation} decorators={decorators} mapData={mapData} initialData={initialData} submit={props.submit} prevStep={props.prevStep} />;
};
