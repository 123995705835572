import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VersionActions } from 'src/Redux';

export const CheckVersion = () => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  useImperativeHandle(ref, () => ({
    onTimeoutCheckVersion: () => checkVersion(),
  }));
  const [timeoutId, setTimeoutId] = useState(-1);
  const [callCount, setCallCount] = useState(0);

  const VersionReducer = useSelector((state) => state.VersionReducer);

  const checkVersion = () => {
    if (callCount >= 3) {
      return;
    }

    setCallCount(callCount + 1);

    dispatch(VersionActions.getRemoteVersion());

    setTimeoutId(setTimeout(() => ref?.current?.onTimeoutCheckVersion(), parseInt(import.meta.env.VITE_VERSION_INTERVAL || 300000)));
  };

  useEffect(() => {
    checkVersion();

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (VersionReducer?.version) {
      if (window.VERSION !== VersionReducer.version) {
        window.localStorage.setItem('VERSION', VersionReducer.version);
        window.location.reload();
      }
    }
  }, [VersionReducer?.version]);

  return <></>;
};
