import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import { i18, trb } from 'src/Utilities';
import { Button, Checkbox, FormField, Input, ScrollToFieldError, Select, Typography } from 'src/shared';
import { loginFormValidations } from './validations';
import { checkCookieForUser, getSimpleLoginTokens, countriesList, ILoginForm } from 'src/Containers';
import { UACConstants } from 'src/Redux';
import { externalPath } from 'src/Config';

import { SubmitButton } from '../Components';

const { Text } = Typography;
interface IProps {
  data: ILoginForm;
  setData: React.Dispatch<React.SetStateAction<ILoginForm>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
}

const RegisterPanel = () => {
  const handleSignupClick = () => {
    const language = localStorage.getItem('language');

    window.open(`${externalPath.onboard}?language=${language || 'lt-LT'}`, '_blank');
  };

  return (
    <div className="mt-4">
      <div className="d-flex justify-content-center">
        <Text className="login_subTitle">{trb(i18.Labels.NotOurClientYet)}</Text>
      </div>
      <div className="d-flex justify-content-center mt-2">
        <Button
          size="small"
          onClick={handleSignupClick}
          type="link"
          id="registerButton"
        >
          {trb(i18.Buttons.LoginPageSignUp)}
        </Button>
      </div>
    </div>
  );
};

export const LoginForm = ({ data, setData, setStep }: IProps) => {
  const dispatch = useDispatch();

  const handleSubmit = (values: ILoginForm) => {
    dispatch({ type: UACConstants.CLEAR_SMSR_INFO });
    setData((p) => ({
      ...p,
      ...values,
      LoginCode: values.LoginCode?.trim() || null,
      LoginIdentity: values.LoginIdentity?.trim() || null,
    }));
    setStep((p) => p + 1);
  };

  return (
    <>
      <div className="mb-5 fake-back-button" />
      <Text className="login_title">{trb(i18.Labels.LoginToYourAccount)}</Text>
      <div className="login_form_wrapper">
        <Formik
          validationSchema={loginFormValidations}
          initialValues={data}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form
                id="LoginForm"
                noValidate={true}
                autoComplete="off"
              >
                <ScrollToFieldError />
                <FormField
                  name="LoginCountry"
                  label={trb(i18.Labels.Country)}
                >
                  <Select
                    popupClassName="login_form_country_select_popup"
                    options={countriesList.map((item: any) => ({
                      label: trb((i18.Enums.Countries as any)[item]),
                      value: item,
                    }))}
                  />
                </FormField>
                <FormField
                  name="LoginCode"
                  label={trb(i18.Labels.LoginCode)}
                >
                  <Input
                    handleChange={async (value) => {
                      const isFullLogin = value.toString().length > 5 ? checkCookieForUser(value, getSimpleLoginTokens()) : true;

                      await setFieldValue('isFullLogin', isFullLogin);
                    }}
                  />
                </FormField>
                {values.isFullLogin !== false && (
                  <>
                    <FormField
                      name="LoginIdentity"
                      label={trb(i18.Labels.PersonalCode)}
                    >
                      <Input />
                    </FormField>
                    <div className="px-3">
                      <Checkbox name="LoginRememberMe">{trb(i18.Labels.RememberMe)}</Checkbox>
                    </div>
                  </>
                )}
                <SubmitButton />
              </Form>
            );
          }}
        </Formik>
        <RegisterPanel />
      </div>
    </>
  );
};
