import React from 'react';
import { CheckOutlined, DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tag, Tooltip } from 'antd';
import { checkJsonProp, i18, trb } from 'src/Utilities';
import { textColors } from 'src/Config';

const ButtonGroup = Button.Group;

export const PaymentGroupEdit = (props) => {
  const templates = checkJsonProp(props, 'PTR.PaymentTemplates');

  const templatesIDArrayByGroupId = templates.filter((item) => item.PaymentGroups.filter((x) => x.ID === props.groupId).length > 0).map((item) => item.TemplateID);

  const templatesArrayByGroupId = templates.filter((x) => templatesIDArrayByGroupId.includes(x.TemplateID));

  const group = checkJsonProp(props, 'PTR.PaymentGroups').find((x) => x.ID === props.groupId);

  return (
    <div className="row w-100">
      <div className="row col-12 m-0 mb-2">
        <div className="eb_content_subtitle col-6 m-0">{trb(i18.ContentMenu.Group) + ' ' + checkJsonProp(group, 'Name')}</div>
        <div className="text-right col-6 m-0">
          <Button
            onClick={props.clickBackToGroupList}
            icon={<LeftOutlined />}
          >
            {trb(i18.Labels.BackToGroupList)}
          </Button>
        </div>
      </div>
      <div className="eb_table col-12">
        <div className="row eb_table_head">
          <div className="col-4">{trb(i18.Labels.Title)}</div>
          <div className="col-5">{trb(i18.Labels.PaymentTemplateDescription)}</div>
          <div className="col-3">{trb(i18.Labels.Actions)}</div>
        </div>
        {templatesArrayByGroupId.map((item, index) => (
          <div
            className="row eb_table_row"
            key={index}
          >
            <div className="col-4">
              <div className="d-flex">
                <div>
                  <div className="mb-2 eb_fw_500">{item.TemplateName}</div>
                  <div>
                    {item.PaymentGroups.map((item, index) => (
                      <Tag
                        key={index}
                        className="cursor-pointer"
                        color={textColors.primary}
                        onClick={() => props.clickExecuteGroup(item.ID)}
                      >
                        {item.Name}
                      </Tag>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div>
                <span className="text-black-50">{trb(i18.Labels.PayeeTitle)}:</span> {item.Payment.Recipient}
              </div>
              <div>
                <span className="text-black-50">{trb(i18.Labels.PayeeAccount)}:</span> {item.Payment.BeneficiaryAccountNumber}
              </div>
              <div>
                <span className="text-black-50">{trb(i18.Labels.Purpose)}:</span> {item.Payment.Purpose}
              </div>
            </div>
            <div className="col-3 text-right">
              <ButtonGroup className="hover-action rounded-button">
                <Popconfirm
                  placement="bottomRight"
                  title={trb(i18.PopConfirms.DoYouWantDeletePaymentTemplate)}
                  onConfirm={() => props.clickDelete(item.TemplateID, props.groupId)}
                  okText={trb(i18.Buttons.Yes)}
                  cancelText={trb(i18.Buttons.Cancel)}
                >
                  <Tooltip
                    placement="topRight"
                    title={trb(i18.Tooltips.PaymentTemplateDelete)}
                  >
                    <Button id={`PaymentGroupEditPopconfirm${index}DeleteButton`}>
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
                {/*<Tooltip placement="topRight" title={trb(i18.Tooltips.PaymentTemplateEdit)}>*/}
                {/*    <Button icon="edit" onClick={() => props.clickEdit(item.TemplateID)}/>*/}
                {/*</Tooltip>*/}
                <Tooltip
                  placement="topRight"
                  title={trb(i18.Tooltips.PaymentTemplateUse)}
                >
                  <Button id={`PaymentGroupEditPopconfirm${index}UseButton`} onClick={() => props.clickExecute(item.TemplateID, item.PaymentType)}>
                    <CheckOutlined />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
