import { i18, currencyFormat } from 'src/Utilities';

export const ForexExchangePreview = (props) => {
  if (!props) {
    return null;
  }

  return {
    parts: [
      {
        title: i18.PaymentParts.Payer,
        fields: [
          {
            title: i18.Labels.FromAccount,
            value: props.forexDraftInfo.IBANAccountFrom,
          },
          {
            title: i18.Labels.ToAccount,
            value: props.forexDraftInfo.IBANAccountTo,
          },
        ],
      },
      {
        title: i18.PaymentParts.AdditionalInformation,
        fields: [
          {
            title: i18.Labels.CurrencyFrom,
            value: `${currencyFormat(props.forexDraftInfo.Calculation.AmountFrom, 2)} ${props.forexDraftInfo.Calculation.From}`,
          },
          {
            title: i18.Labels.CurrencyTo,
            value: `${currencyFormat(props.forexDraftInfo.Calculation.AmountTo, 2)} ${props.forexDraftInfo.Calculation.To}`,
          },
          {
            title: i18.Labels.FixRate,
            value: props.forexDraftInfo.Calculation.Pair,
            secondLine: currencyFormat(props.forexDraftInfo.Calculation.BankRate, 6),
          },
        ],
      },
    ],
  };
};
