export const UACConstants = {
  POST_UAC_ESAR_REQUEST: 'POST_UAC_ESAR_REQUEST',
  POST_UAC_ESAR_SUCCESS: 'POST_UAC_ESAR_SUCCESS',
  POST_UAC_ESAR_ERROR: 'POST_UAC_ESAR_ERROR',

  POST_UAC_ESARSM_REQUEST: 'POST_UAC_ESARSM_REQUEST',
  POST_UAC_ESARSM_SUCCESS: 'POST_UAC_ESARSM_SUCCESS',
  POST_UAC_ESARSM_ERROR: 'POST_UAC_ESARSM_ERROR',

  POST_UAC_SMSR_REQUEST: 'POST_UAC_SMSR_REQUEST',
  POST_UAC_SMSR_SUCCESS: 'POST_UAC_SMSR_SUCCESS',
  POST_UAC_SMSR_ERROR: 'POST_UAC_SMSR_ERROR',

  POST_UAC_FULLLOGIN_REQUEST: 'POST_UAC_FULLLOGIN_REQUEST',
  POST_UAC_FULLLOGIN_SUCCESS: 'POST_UAC_FULLLOGIN_SUCCESS',
  POST_UAC_FULLLOGIN_ERROR: 'POST_UAC_FULLLOGIN_ERROR',

  POST_UAC_SIMPLELOGIN_REQUEST: 'POST_UAC_SIMPLELOGIN_REQUEST',
  POST_UAC_SIMPLELOGIN_SUCCESS: 'POST_UAC_SIMPLELOGIN_SUCCESS',
  POST_UAC_SIMPLELOGIN_ERROR: 'POST_UAC_SIMPLELOGIN_ERROR',

  POST_UAC_CHECK_LOGIN_REQUEST: 'POST_UAC_CHECK_LOGIN_REQUEST',
  POST_UAC_CHECK_LOGIN_SUCCESS: 'POST_UAC_CHECK_LOGIN_SUCCESS',
  POST_UAC_CHECK_LOGIN_ERROR: 'POST_UAC_CHECK_LOGIN_ERROR',

  POST_UAC_RESEND_TOKEN_REQUEST: 'POST_UAC_RESEND_TOKEN_REQUEST',
  POST_UAC_RESEND_TOKEN_SUCCESS: 'POST_UAC_RESEND_TOKEN_SUCCESS',
  POST_UAC_RESEND_TOKEN_ERROR: 'POST_UAC_RESEND_TOKEN_ERROR',

  POST_UAC_SMSAR_REQUEST: 'POST_UAC_SMSAR_REQUEST',
  POST_UAC_SMSAR_SUCCESS: 'POST_UAC_SMSAR_SUCCESS',
  POST_UAC_SMSAR_ERROR: 'POST_UAC_SMSAR_ERROR',

  POST_UAC_ESACR_REQUEST: 'POST_UAC_ESACR_REQUEST',
  POST_UAC_ESACR_SUCCESS: 'POST_UAC_ESACR_SUCCESS',
  POST_UAC_ESACR_ERROR: 'POST_UAC_ESACR_ERROR',

  GET_UAC_MALR_REQUEST: 'GET_UAC_MALR_REQUEST',
  GET_UAC_MALR_SUCCESS: 'GET_UAC_MALR_SUCCESS',
  GET_UAC_MALR_ERROR: 'GET_UAC_MALR_ERROR',
  GET_UAC_MALR_UNMOUNT: 'GET_UAC_MALR_UNMOUNT',

  GET_UAC_MACR_REQUEST: 'GET_UAC_MACR_REQUEST',
  GET_UAC_MACR_SUCCESS: 'GET_UAC_MACR_SUCCESS',
  GET_UAC_MACR_ERROR: 'GET_UAC_MACR_ERROR',

  POST_UAC_ATR_REQUEST: 'POST_UAC_ATR_REQUEST',
  POST_UAC_ATR_SUCCESS: 'POST_UAC_ATR_SUCCESS',
  POST_UAC_ATR_ERROR: 'POST_UAC_ATR_ERROR',

  GET_UAC_LOGOUT_REQUEST: 'GET_UAC_LOGOUT_REQUEST',
  GET_UAC_LOGOUT_SUCCESS: 'GET_UAC_LOGOUT_SUCCESS',
  GET_UAC_LOGOUT_ERROR: 'GET_UAC_LOGOUT_ERROR',

  GET_UAC_CPRQ_REQUEST: 'GET_UAC_CPRQ_REQUEST',
  GET_UAC_CPRQ_SUCCESS: 'GET_UAC_CPRQ_SUCCESS',
  GET_UAC_CPRQ_ERROR: 'GET_UAC_CPRQ_ERROR',

  GET_UAC_GPR_REQUEST: 'GET_UAC_GPR_REQUEST',
  GET_UAC_GPR_SUCCESS: 'GET_UAC_GPR_SUCCESS',
  GET_UAC_GPR_ERROR: 'GET_UAC_GPR_ERROR',

  POST_UAC_SMSGETPWD_REQUEST: 'POST_UAC_SMSGETPWD_REQUEST',
  POST_UAC_SMSGETPWD_SUCCESS: 'POST_UAC_SMSGETPWD_SUCCESS',
  POST_UAC_SMSGETPWD_ERROR: 'POST_UAC_SMSGETPWD_ERROR',

  GET_UAC_GGDATAMY_REQUEST: 'GET_UAC_GGDATAMY_REQUEST',
  GET_UAC_GGDATAMY_SUCCESS: 'GET_UAC_GGDATAMY_SUCCESS',
  GET_UAC_GGDATAMY_ERROR: 'GET_UAC_GGDATAMY_ERROR',

  GET_UAC_RFT_REQUEST: 'GET_UAC_RFT_REQUEST',
  GET_UAC_RFT_SUCCESS: 'GET_UAC_RFT_SUCCESS',
  GET_UAC_RFT_ERROR: 'GET_UAC_RFT_ERROR',

  GET_UAC_ESARGET_REQUEST: 'GET_UAC_ESARGET_REQUEST',
  GET_UAC_ESARGET_SUCCESS: 'GET_UAC_ESARGET_SUCCESS',
  GET_UAC_ESARGET_ERROR: 'GET_UAC_ESARGET_ERROR',

  GET_LOGIN_MESSAGE_REQUEST: 'GET_LOGIN_MESSAGE_REQUEST',
  GET_LOGIN_MESSAGE_SUCCESS: 'GET_LOGIN_MESSAGE_SUCCESS',
  GET_LOGIN_MESSAGE_ERROR: 'GET_LOGIN_MESSAGE_ERROR',

  SET_IS_REMOTE_ORIGIN: 'SET_IS_REMOTE_ORIGIN',
  CLEAR_SMSR_INFO: 'CLEAR_SMSR_INFO',
};
