import { trb } from 'src/Utilities';

interface IProps {
  label: string;
  className?: string;
  id?: string;
}

export const Label = ({ label, className = '', id }: IProps) => {
  return (
    <div
      className={`d-block d-md-none text-black-50 ${className}`}
      id={id}
    >
      {trb(label)}
    </div>
  );
};
