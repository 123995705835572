export const BeneficiariesConstants = {
  GET_BENEFICIARIES_LIST_REQUEST: 'GET_BENEFICIARIES_LIST_REQUEST',
  GET_BENEFICIARIES_LIST_SUCCESS: 'GET_BENEFICIARIES_LIST_SUCCESS',
  GET_BENEFICIARIES_LIST_ERROR: 'GET_BENEFICIARIES_LIST_ERROR',

  SET_BENEFICIARIES_LIST: 'SET_BENEFICIARIES_LIST',

  POST_CREATE_NEW_MANDATE_REQUEST: 'POST_CREATE_NEW_MANDATE_REQUEST',
  POST_CREATE_NEW_MANDATE_SUCCESS: 'POST_CREATE_NEW_MANDATE_SUCCESS',
  POST_CREATE_NEW_MANDATE_ERROR: 'POST_CREATE_NEW_MANDATE_ERROR',

  POST_SIGN_MANDATE_REQUEST: 'POST_SIGN_MANDATE_REQUEST',
  POST_SIGN_MANDATE_SUCCESS: 'POST_SIGN_MANDATE_SUCCESS',
  POST_SIGN_MANDATE_ERROR: 'POST_SIGN_MANDATE_ERROR',

  SET_SIGN_MANDATE_INFO: 'SET_SIGN_MANDATE_INFO',

  GET_CHECK_STATUS_REQUEST: 'GET_CHECK_STATUS_REQUEST',
  GET_CHECK_STATUS_SUCCESS: 'GET_CHECK_STATUS_SUCCESS',
  GET_CHECK_STATUS_ERROR: 'GET_CHECK_STATUS_ERROR',

  POST_SIGN_DELETE_MANDATE_REQUEST: 'POST_SIGN_DELETE_MANDATE_REQUEST',
  POST_SIGN_DELETE_MANDATE_SUCCESS: 'POST_SIGN_DELETE_MANDATE_SUCCESS',
  POST_SIGN_DELETE_MANDATE_ERROR: 'POST_SIGN_DELETE_MANDATE_ERROR',

  SET_SIGN_DELETE_MANDATE_INFO: 'SET_SIGN_DELETE_MANDATE_INFO',

  DELETE_MANDATE_REQUEST: 'DELETE_MANDATE_REQUEST',
  DELETE_MANDATE_SUCCESS: 'DELETE_MANDATE_SUCCESS',
  DELETE_MANDATE_ERROR: 'DELETE_MANDATE_ERROR',

  GET_CHECK_DELETE_STATUS_REQUEST: 'GET_CHECK_DELETE_STATUS_REQUEST',
  GET_CHECK_DELETE_STATUS_SUCCESS: 'GET_CHECK_DELETE_STATUS_SUCCESS',
  GET_CHECK_DELETE_STATUS_ERROR: 'GET_CHECK_DELETE_STATUS_ERROR',
};

