import { i18, removeNonAlphanumericCharacters, trb } from 'src/Utilities';
import { Button } from 'src/shared';

export const SubmitButton = ({ title = '' }) => {
  return (
    <Button
      className="mt-4"
      block
      type="primary"
      size="large"
      htmlType="submit"
      id={removeNonAlphanumericCharacters(`${title || i18.Buttons.SignIn}SubmitButton`)}
    >
      {trb(title || i18.Buttons.SignIn)}
    </Button>
  );
};
