export const accountsColorObject = {
    1: '#9BE4FF',
    11: '#9BE4FF',
    24: '#9BE4FF',
    23: '#9BE4FF',
    132: '#9BE4FF',
    131: '#CABCFF',
    4: '#CABCFF',
    3: '#CABCFF',
    5: '#95FADF',
    6: '#95FADF',
    22: '#95FADF',
}