import {TranslationsService} from './service';
import {TranslationsConstant} from './constant';

export const TranslationsAction = {
    getTranslations,
    getCreateTranslation
};


function getTranslations(context, culture) {
    return dispatch => {
        dispatch(request());
        let data = sessionStorage.getItem(context + '.' + culture);
        if (data === 'undefined' || data === null) {
            return TranslationsService.getTranslations(context, culture)
                .then(
                    data => dispatch(success(data)),
                    error => dispatch(failure(error))
                );
        } else {
            return dispatch(success(JSON.parse(data)));
        }
    };

    function request() {
        return {type: TranslationsConstant.GET_TRANSLATIONS_CONTEXT_CULTURE_REQUEST};
    }

    function success(data) {
        sessionStorage.setItem(context + '.' + culture, JSON.stringify(data));
        let data2 = {
            context,
            culture,
            Groups: data.Groups,
        };

        return {type: TranslationsConstant.GET_TRANSLATIONS_CONTEXT_CULTURE_SUCCESS, payload: data2}
    }

    function failure(error) {
        return {type: TranslationsConstant.GET_TRANSLATIONS_CONTEXT_CULTURE_ERROR, error}
    }
}

function getCreateTranslation(content, culture, group, key) {
    return TranslationsService.getCreateTranslation(content, culture, group, key).then(
        data => data,
        error => error
    );
}

export const clTypes = {
    ADRT: 'ADRT.',
    ACCC: 'ACCC.',
    ACCT: 'ACCT.',
    CBB: 'CBB.',
    CBREG: 'CBREG.',
    CISO: 'CISO.',
    CLSTAT: 'CLSTAT.',
    CTIP: 'CTIP.',
    CPROF: 'CPROF.',
    CRGT: 'CRGT.',
    DAY: 'DAY.',
    DEPT: 'DEPT.',
    DEPP: 'DEPP.',
    DERT: 'DERT.',
    DIST: 'DIST.',
    DJAPROVE: 'DJAA.',
    DOCS: 'DOCS.',
    CHECKBOX: 'EntityCheckboxes.',
    MAILL: 'MAILL.',
    PDSH: 'PDSH.',
    PEP: 'PEP.',
    PER: 'PER.',
    PEPLINK: 'PEPLINK.',
    PHON: 'PHON.',
    PLCASH: 'PLCASH.',
    PLTUR: 'PLTUR.',
    PROFFER: 'PROFFER.',
    TERM: 'TERM.'
};
