import { requestEBank, aPIPaths, handleResponse, methods, requestEBankJSON, responseTypes } from 'src/Redux';

export const aMCServices = {
  getALR,
  postADR,
  postLLO,
  postCAINF,
  postBCA,
  postACCLMTS,
  getCompany,
  postACCSXLSX,
  postADROD,
  getAccountOverdraft,
  getForexCurrency,
  getForexRate,
  postConfirmForexRate,
  postForexDraft,
  getManagedUsers,
  getClientLimits,
  postClientLimits,
  getUpdatingLimitStatus,
  getForexStatus,
  getCountriesList,
};

function getALR() {
  return requestEBank(aPIPaths.get_AMC_ALR, { method: methods.post }).then(handleResponse);
}

function postLLO(data = null) {
  return requestEBankJSON(aPIPaths.post_AMC_LLO, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postADR(data = null) {
  return requestEBankJSON(aPIPaths.post_AMC_ADR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postCAINF() {
  return requestEBank(aPIPaths.post_AMC_CAINF, {
    method: methods.post,
  }).then(handleResponse);
}

function postBCA(data) {
  return requestEBankJSON(aPIPaths.post_AMC_BCA, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postACCLMTS(data) {
  return requestEBankJSON(aPIPaths.post_AMC_ACCLMTS, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function getCompany() {
  return requestEBank(aPIPaths.get_AMC_GetCompany).then(handleResponse);
}

function postACCSXLSX(data, language) {
  return requestEBankJSON(aPIPaths.post_AMC_ACCSXLSX, {
    method: methods.post,
    data: {
      ...data,
      LanguageStatement: language,
    },
    responseType: responseTypes.arrayBuffer,
  }).then(handleResponse);
}

function postADROD(data = null) {
  return requestEBankJSON(aPIPaths.post_AMC_ADROD, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function getAccountOverdraft(accountId) {
  return requestEBankJSON(aPIPaths.get_AMC_GetAccountOverdraft + accountId).then(handleResponse);
}

function getForexCurrency(currency) {
  return requestEBankJSON(aPIPaths.get_AMC_Forex_Currency + currency).then(handleResponse);
}

function getForexRate(data = null) {
  return requestEBankJSON(aPIPaths.get_AMC_Forex_Rate, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postConfirmForexRate(id, data = null) {
  return requestEBankJSON(aPIPaths.post_AMC_FOREXT_CONFIRM + id, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postForexDraft(data = null) {
  return requestEBank(aPIPaths.post_AMC_ForexDraft, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function getManagedUsers() {
  return requestEBank(aPIPaths.get_AMC_ManagedUsers).then(handleResponse);
}

function getClientLimits(userId) {
  return requestEBank(aPIPaths.get_AMC_ClientLimits(userId)).then(handleResponse);
}

function postClientLimits(data) {
  return requestEBank(aPIPaths.post_AMC_ClientLimits, { method: methods.post, data: data }).then(handleResponse);
}

function getUpdatingLimitStatus(GUID) {
  return requestEBank(aPIPaths.get_AMC_UpdatingLimitStatus(GUID)).then((resp) => {
    return handleResponse(resp, true);
  });
}

function getForexStatus(id) {
  return requestEBank(aPIPaths.get_AMC_ForexStatus(id)).then((resp) => {
    return handleResponse(resp, true);
  });
}

function getCountriesList() {
  return requestEBankJSON(aPIPaths.get_AMC_CountriesList).then(handleResponse);
}
