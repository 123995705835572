import {FeeServices} from './services';
import {FeeConstants} from './constants';

export const FeeActions = {
    getFeeList,
    getFeePayment,
    postLastFee,
};

function getFeeList() {
    return dispatch => {
        dispatch(request());

        return FeeServices.getFeeList()
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: FeeConstants.GET_FEE_LIST_REQUEST};
    }

    function success(data) {
        return {type: FeeConstants.GET_FEE_LIST_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: FeeConstants.GET_FEE_LIST_ERROR, error}
    }
}

function getFeePayment(id) {
    return dispatch => {
        dispatch(request());

        return FeeServices.getFeePayment(id)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            );
    };

    function request() {
        return {type: FeeConstants.GET_FEE_PAYMENT_REQUEST};
    }

    function success(data) {
        return {type: FeeConstants.GET_FEE_PAYMENT_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: FeeConstants.GET_FEE_PAYMENT_ERROR, error}
    }
}

function postLastFee(data) {
    return dispatch => {
        dispatch(request());

        return FeeServices.postLastFee(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: FeeConstants.POST_FEE_LAST_FEE_REQUEST};
    }

    function success(data) {
        return {type: FeeConstants.POST_FEE_LAST_FEE_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: FeeConstants.POST_FEE_LAST_FEE_ERROR, error}
    }
}