import {checkJsonProp} from "../../../Utilities";

export const payerPayeeType = [
    {
        label: 'None',
        value: '',
    }, {
        label: 'Individual',
        value: 'p',
    }, {
        label: 'Legal',
        value: 'o',
    }
];

export const payerPayeeTypeCurrent = (value) => {
    if (!value) {
        value = '';
    }
    return checkJsonProp(payerPayeeType.find(e => e.value === value), 'label');
};

export const paymentSystemType = [
    {
        label: 'SCT',
        value: 'SepaSct',
    }, {
        label: 'Inst',
        value: 'INST',
    }
];

export const paymentPaymentSystemType = (value) => {
    // if (!value) {
    //     value = '';
    // }

    return checkJsonProp(paymentSystemType.find(e => e.value === value), 'label');
};

export const paymentPurposeType = [
    {
        label: 'Purpose',
        value: 'true',
    }, {
        label: 'Code',
        value: 'false',
    }
];

export const paymentPurposeTypeCurrent = (value) => {
    // if (!value) {
    //     value = '';
    // }

    return checkJsonProp(paymentPurposeType.find(e => e.value === value), 'label');
};