import * as yup from 'yup';

yup.setLocale({
  mixed: {
    default: () => ({
      error: 'Validations.FieldRequired',
    }),
    required: () => {
      return {
        error: 'Validations.FieldRequired',
      };
    },
    oneOf: ({ resolved }: any) => {
      if (resolved?.length === 1 && resolved[0] === true) {
        return { error: 'Validations.BooleanRequired' };
      }
      return { error: 'Validations.ChooseOneOf' };
    },
  },
  string: {
    email: { error: 'Validations.Email' },
    url: { error: 'Validations.URL' },
    min: ({ min }: any) => ({ error: 'Validations.MinStringValue{min}', options: { min } }),
    max: ({ max }: any) => ({ error: 'Validations.MaxStringValue{max}', options: { max } }),
  },
  array: {
    min: { error: 'Validations.ArrayMin' },
  },
  number: {
    positive: { error: 'Validations.NumberPositive' },
    min: ({ min }: any) => ({ error: 'Validations.MinNumberValue{min}', options: { min } }),
    max: ({ max }: any) => ({ error: 'Validations.MaxNumberValue{max}', options: { max } }),
  },
  boolean: {
    isValue: { error: 'Validations.BooleanRequired' },
  },
  date: {
    min: { error: 'Validations.DateMin' },
  },
});

export { yup };
