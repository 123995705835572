import React from 'react';

export const PageLoader = () => {
  return (
    <div className="eb_page_loader">
      <div className="eb_page_loader_inner">
        <div className="eb_page_loader_logo">
          <img
            src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGO}`}
            alt={import.meta.env.VITE_TITLE}
          />
        </div>
        <div className="eb_page_loader_progress">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </div>
  );
};
