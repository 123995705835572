export const IconMessage = ({ size = 24, color = '#FFF' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="m9.29695,17.17492c-0.1481,0 -0.2928,0.0438 -0.416,0.126l-3.334,2.2226l0,-1.5986c0,-0.4142 -0.33579,-0.75 -0.75,-0.75l-1.5,0c-1.24279,0 -2.25,-1.0072 -2.25,-2.25l0,-8c0,-1.24279 1.00721,-2.25 2.25,-2.25l12,0c1.2428,0 2.25,1.00721 2.25,2.25l0,8c0,1.2428 -1.0072,2.25 -2.25,2.25l-6,0z"
        fill={color}
        strokeWidth="0"
      />
    </svg>
  );
};
