import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {
    i18,
    requiredAll,
    checkJsonProp,
    checkJsonExist
} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCAccountsInOtherBank = (props) => {

    let ClientAccountBank = checkJsonProp(props.data, 'clientAccountBank') && checkJsonProp(props.data, 'clientAccountBank').length
        ? checkJsonProp(props.data, 'clientAccountBank')
        : [{clientAccountBankType: "ACC1", data:{}}];

    ClientAccountBank = ClientAccountBank.map((item) => {
        item.allNeed = !!((item.data && item.data.clientAccountBankName && item.data.clientAccountBankCountry)
          || (!checkJsonProp(item, 'data.clientAccountBankName')
            && !checkJsonProp(item, 'data.clientAccountBankCountry')));
        return item;
    });

    const initialData = {clientAccountBank: ClientAccountBank};

    const mapData = [{
        title: i18.Labels.ClientAccountBank, //i18.Labels.ClientOtherAddress
        messageBefore: i18.NotificationMessages.KYCAccountsInOtherBank,
        canDuplicate: true,
        duplicateName: 'clientAccountBank',
        duplicateDefault: {clientAccountBankType: 'ACC$index', allNeed: true, data: {}},
        maxRows: props.classifikators.ACC.length - 1,
        elements: [{
            title: i18.Labels.ClientAccountBankName,
            name: 'clientAccountBank.$index.clientAccountBankType',
            component: FormFieldType.select,
            options: props.classifikators.ACC,
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            className: 'col-12 d-none',
        },{
            title: i18.Labels.ClientAccountBankName,
            name: 'clientAccountBank.$index.data.clientAccountBankName',
            component: FormFieldType.input,
            className: 'col-12',
        },{
            title: i18.Labels.ClientAccountBankCountry,
            name: 'clientAccountBank.$index.data.clientAccountBankCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-12',
        },{
            name: 'clientAccountBank.$index.allNeed',
            component: FormFieldType.checkbox,
            checkedNumber: false,
            className: 'col-12 text-center m-2 only-error ',
            validate: requiredAll,
        }]
    }];

    const decorators = [
        createDecorator({
            field: /clientAccountBank\.*.*/,
            updates: (value, name, allValues, prevValues) => {
                if (allValues && prevValues && allValues.length < prevValues.length) return {};
                if (value === undefined) return {};

                const arr = name.split('.'),
                      index = arr.length ? parseInt(arr[1]) : null,
                      another = (checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankName`)
                                && checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankCountry`))
                                || (!checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankName`)
                                && !checkJsonProp(allValues, `clientAccountBank.${index}.data.clientAccountBankCountry`));

                return index > -1 && name.indexOf('allNeed') === -1 ? {
                    [`clientAccountBank.${index}.allNeed`]: !!another,
                } : {}
            }
        },{
            field: 'clientAccountBank',
            updates: (value, name, allValues, prevValues) => {
                const diff = allValues.clientAccountBank.filter((newItem)=>{
                    return !(prevValues.clientAccountBank !== undefined && prevValues.clientAccountBank.some((prevItem) => {
                        return Object.is(newItem, prevItem);
                    }));
                })
                const index = value && typeof value == "object" &&
                              checkJsonExist(allValues, 'clientAccountBank') && checkJsonExist(prevValues, 'clientAccountBank') &&
                              allValues.clientAccountBank.length !== prevValues.clientAccountBank.length
                                  ? value.indexOf(diff[0])
                                  : 0;
                if (index > 0){
                    props.classifikators.ACC.every((item) => {
                        if (item.value === value[index].clientAccountBankType){
                            if (item.disabled){
                                let accIndex;
                                props.classifikators.ACC.filter((item, index) => {
                                    if (!item.disabled){
                                        accIndex = index;
                                        return true;
                                    }
                                    return false;
                                });
                                value[index].clientAccountBankType = props.classifikators.ACC[accIndex].value;
                                props.classifikators.ACC[accIndex].disabled = true;
                                return false;
                            }
                            else{
                                item.disabled = true;
                            }
                        }
                        return true;
                    });
                }
                return {};
            }
        },{
            field: /clientAccountBank\.*.*clientAccountBankType/,
            updates: (value, name, allValues) => {
                props.classifikators.ACC.map((item)=>{
                    item.disabled = !!checkJsonProp(allValues, 'clientAccountBank').some((addItem) => {
                        return addItem.clientAccountBankType === item.value;
                    });
                    item.disabled = item.disabled || item.value === value;
                    return item;
                })
                return {}
            }
        })
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.InformationAboutClient} decorators={decorators} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};
