import {LanguageConstant} from './';
import {axiosLanguage} from '../../';

let language = localStorage.getItem('language');

if (!language) {
    language = 'lt-LT';
}

const initialState = {language}

export const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case LanguageConstant.CHANGE_LANGUAGE:
            axiosLanguage(action.payload);
            localStorage.setItem('language', action.payload);
            return {
                ...state,
                send: false,
                language: action.payload,
            };
        default:
            return state;
    }
};