export const CMCConstants = {
    POST_CMC_CIR_REQUEST: 'POST_CMC_CIR_REQUEST',
    POST_CMC_CIR_SUCCESS: 'POST_CMC_CIR_SUCCESS',
    POST_CMC_CIR_ERROR: 'POST_CMC_CIR_ERROR',

    POST_CMC_PDR_REQUEST: 'POST_CMC_PDR_REQUEST',
    POST_CMC_PDR_SUCCESS: 'POST_CMC_PDR_SUCCESS',
    POST_CMC_PDR_ERROR: 'POST_CMC_PDR_ERROR',

    POST_CMC_CIPTR_REQUEST: 'POST_CMC_CIPTR_REQUEST',
    POST_CMC_CIPTR_SUCCESS: 'POST_CMC_CIPTR_SUCCESS',
    POST_CMC_CIPTR_ERROR: 'POST_CMC_CIPTR_ERROR',

    POST_CMC_VCR_REQUEST: 'POST_CMC_VCR_REQUEST',
    POST_CMC_VCR_SUCCESS: 'POST_CMC_VCR_SUCCESS',
    POST_CMC_VCR_ERROR: 'POST_CMC_VCR_ERROR',

    POST_CMC_SCR_REQUEST: 'POST_CMC_SCR_REQUEST',
    POST_CMC_SCR_SUCCESS: 'POST_CMC_SCR_SUCCESS',
    POST_CMC_SCR_ERROR: 'POST_CMC_SCR_ERROR',

    POST_CMC_DPDF_REQUEST: 'POST_CMC_DPDF_REQUEST',
    POST_CMC_DPDF_SUCCESS: 'POST_CMC_DPDF_SUCCESS',
    POST_CMC_DPDF_ERROR: 'POST_CMC_DPDF_ERROR',

    POST_CMC_GET_ACCOUNT_BALANCE_XML_REQUEST: 'POST_CMC_GET_ACCOUNT_BALANCE_XML_REQUEST',
    POST_CMC_GET_ACCOUNT_BALANCE_XML_SUCCESS: 'POST_CMC_GET_ACCOUNT_BALANCE_XML_SUCCESS',
    POST_CMC_GET_ACCOUNT_BALANCE_XML_ERROR: 'POST_CMC_GET_ACCOUNT_BALANCE_XML_ERROR',
};