import React, {Component} from 'react';
import {i18, trb, Logout} from '../../../Utilities';
import { LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import { Result, Button } from "antd";
import {history, routerPath} from '../../../Config';

export class MainNeedChangePasswordAlert extends Component {
    constructor(props){
        super(props);

        let redirectInterval = setInterval(this.redirectTimeout, 1000);

        this.state = {
            counter: 5,
            redirectInterval: redirectInterval,
        }

        this.redirectTimeout = this.redirectTimeout.bind(this);
    }

    redirectTimeout = () => {
        const {counter} = this.state;

        if (counter > 0) this.setState({counter: counter - 1});
        else {
            // FIXME: switch to navigate
            history.push(routerPath.settingsChangePassword);
            clearInterval(this.state.redirectInterval);
            this.setState({counter: 5});
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.state.redirectInterval);
        this.setState({counter: 5});
    }

    render(){
        const {counter} = this.state;

        return (
            <div className="eb_content">
                <Result
                    status="error"
                    title={trb(i18.ErrorTitles.NeedChangePassword)}
                    subTitle={trb(i18.ErrorDescriptions.NeedChangePassword)}
                    extra={[
                        <Button id="NeedChangePasswordGoButton" key="password" className="text-click" type="primary" onClick={() => /* FIXME: switch to navigate */ history.push(routerPath.settingsChangePassword)}>
                            <SettingOutlined /> {trb(i18.Buttons.GoToChangePassword)} ({counter})
                        </Button>,
                        <Button id="NeedChangePasswordLogoutButton" key="logout" className="text-click" onClick={() => Logout()}>
                            <LogoutOutlined /> {trb(i18.Buttons.Logout)}
                        </Button>
                    ]}
                />
            </div>
        );
    }
}