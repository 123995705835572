export const FormFieldType = {
  radio: 'radio',
  checkbox: 'checkbox',
  checkboxOnly: 'checkboxOnly',
  date: 'date',
  select: 'select',
  selectSearch: 'selectSearch',
  input: 'input',
  inputOnly: 'inputOnly',
  inputPassword: 'inputPassword',
  number: 'number',
  amountCurrency: 'amountCurrency',
  html: 'html',
  numberLikeRadio: 'numberLikeRadio',
  component: 'component',
};
