export const BIC = 'BIC';
export const SHA = 'SHA';
export const SORT_CODE = 'SORT CODE';

export const CURRENCIES = {
  EUR: 'EUR',
  USD: 'USD',
  KRW: 'KRW',
  CAD: 'CAD',
  CNY: 'CNY',
  AUD: 'AUD',
  INR: 'INR',
  GBP: 'GBP',
  MYR: 'MYR',
  SGD: 'SGD',
  NOK: 'NOK',
  DKK: 'DKK',
  PLN: 'PLN',
  SEK: 'SEK',
  HUF: 'HUF',
  RON: 'RON',
};

export const PaymentType = {
  SEPA: 2,
  TARGET2: 6,
  INTERNATIONAL: 7,
};

export const PAYMENT_SYSTEMS = {
  TARGET2: 'X3',
  SEPA: 'SepaSct',
  INTERNATIONAL: 'X4',
  INST: 'INST',
};

export const Payment = {
  OWN_ACCOUNTS: 'own-accounts',
  NEW: 'new-payment',
  FEE: 'fee-payment',
  INTERNATIONAL: 'international-payment',
  FOREX_EXCHANGE: 'forex-exchange',
};

export const PAYMENT_ERRORS = {
  SEPA_INST: 'SepaInstError',
  CHANGE_PAYMENT_SYSTEM: 'ChangePaymentSystem',
};

export const currenciesWithoutIBANCheck = [
  CURRENCIES.AUD, // australia
  CURRENCIES.CAD, // canada
  CURRENCIES.CNY, // china
  CURRENCIES.INR, // india
  CURRENCIES.KRW, // south korea
  CURRENCIES.MYR, // malaysia
  CURRENCIES.SGD, // singapore
  CURRENCIES.USD, // usa
];

export const SMS_TOKEN_TYPES = ['SMS', 'BIOFACE', 'EIDEASY'];

export const SIGN_CANCEL_TYPES = {
  MANUAL: 'Canceled',
  TIMEOUT: 'Timeout',
  REJECTED: 'Rejected',
  ERROR: 'Error',
  ERROR_CODE: 'ErrorCode',
  VALIDATION: 'Validation',
  PMC10000: 'PMC10000',
};

export const LANGUAGES_SHORT = {
  'lt-LT': 'LT',
  'en-GB': 'ENG',
};

export const LANGUAGES_LOCALES = {
  'lt-LT': 'lt',
  'en-GB': 'en',
};
