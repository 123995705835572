export function handleResponse(response, fullResponse) {
  if (response.status !== 200) {
    return Promise.reject(fullResponse ? response : response.statusText);
  }
  return fullResponse ? response : response.data;
}

export function handleResponseLogin(response) {
  if (response.status !== 200 && response.status !== 202 && response.status !== 401) {
    return Promise.reject(response.statusText);
  }
  return response.data;
}

export function handleErrorLogin(response) {
  return Promise.reject(response.response);
}
