import { trb } from 'src/Utilities';

interface IProps {
  error: any;
  id?: string;
}

export const FormFieldError = ({ error, id }: IProps) => {
  return <div id={id} className="field_error">{error?.error ? trb(error.error, error.options) : trb(error)}</div>;
};
