import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AMCActions, PMCActions } from 'src/Redux';

export const ForexExchangeSpy = ({ values: { Payment }, ...rest }) => {
  const dispatch = useDispatch();
  const { forexRate, fxAccountCurrency, forexCurrency } = useSelector((state) => state.AMCReducers);
  const { forexPayments } = useSelector((state) => state.PMCReducers);
  const [forexRates, setForexRates] = useState(null);
  const [resetCounter, setResetCounter] = useState(0);
  const debounceTO = 1000;
  let currencyChangedTOId = null;

  const getAccountCurrency = (accountId) => {
    return forexPayments?.InitialAccounts.find((el) => el.AccountID === accountId)?.CurrencyName;
  };

  const handleChangeRateSearch = (amount, fieldName) => {
    Payment.AmountSell = null;

    if (fieldName === 'AmountBuy') {
      const { minAmount, maxAmount } = forexCurrency?.find(({ currency }) => currency === Payment?.Currency) ?? {};
      if ((minAmount && minAmount > Number(amount)) || (maxAmount && maxAmount < Number(amount))) {
        return;
      }
    }

    dispatch(
      AMCActions.getForexRate({
        from: getAccountCurrency(Payment.FromAccount),
        to: Payment.Currency,
        amountFrom: fieldName === 'AmountBuy' ? Number(amount) : null,
        amountTo: fieldName === 'AmountSell' ? Number(amount) : null,
      })
    );
  };

  useEffect(() => {
    if (!Payment?.FromAccount) {
      dispatch(PMCActions.getForexPayments());
    }
  }, []);

  useEffect(() => {
    if (Payment?.FromAccount) {
      const accountCurency = getAccountCurrency(Payment?.FromAccount);
      if (accountCurency && fxAccountCurrency !== accountCurency) {
        dispatch(AMCActions.getForexCurrency(accountCurency));
      }
    }
  }, [Payment?.FromAccount]);

  useEffect(() => {
    if (!forexRate) return;
    setForexRates(forexRate);
  }, [forexRate]);

  useEffect(() => {
    if (!forexRates) return;
    rest.form.change('Payment.AmountBuy', forexRate?.AmountFrom);
    rest.form.change('Payment.AmountSell', forexRate?.AmountTo);
    rest.form.change('Payment.FixRate', forexRate?.BankRate);
    setResetCounter((prev) => prev + 1);
  }, [forexRates]);

  useEffect(() => {
    setForexRates(null);
  }, [resetCounter]);

  useEffect(() => {
    if (Payment?.AmountBuy && !forexRates) {
      currencyChangedTOId = setTimeout(() => {
        handleChangeRateSearch(Payment.AmountBuy, 'AmountBuy');
      }, debounceTO);
    }
    return () => clearTimeout(currencyChangedTOId);
  }, [Payment?.AmountBuy]);

  useEffect(() => {
    if (Payment?.AmountSell && !forexRates) {
      currencyChangedTOId = setTimeout(() => {
        handleChangeRateSearch(Payment.AmountSell, 'AmountSell');
      }, debounceTO);
    }
    return () => clearTimeout(currencyChangedTOId);
  }, [Payment?.AmountSell]);

  useEffect(() => {
    if (!Payment?.Currency || !Payment?.BeneficiaryAccountNumber || !Payment?.FromAccount || Payment.canBuy) {
      if (Payment.canBuy && (!Payment?.Currency || !Payment?.BeneficiaryAccountNumber || !Payment?.FromAccount)) {
        rest.form.change('Payment.canBuy', false);
      }
      return;
    }
    rest.form.change('Payment.canBuy', true);
  }, [Payment?.Currency, Payment?.BeneficiaryAccountNumber, Payment?.FromAccount]);

  useEffect(() => {
    if (Payment?.FromAccount) {
      const accountCurency = getAccountCurrency(Payment?.FromAccount);
      if (accountCurency) {
        rest.form.change('Payment.FromCurrency', accountCurency);
      }
      rest.forexTimerValues?.setTotalTime?.(null);
    }
  }, [Payment?.FromAccount]);

  useEffect(() => {
    if (Payment.Currency) {
      rest.form.change('Payment.AmountBuy', null);
      rest.form.change('Payment.AmountSell', null);
      rest.form.change('Payment.FixRate', null);
      rest.forexTimerValues?.setTotalTime?.(null);

      return;
    }
  }, [Payment?.Currency]);

  return null;
};
