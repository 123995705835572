import { useSelector } from 'react-redux';

import { FormField, Select } from 'src/shared';
import { i18, trb, translationGroups } from '../../../../../Utilities';

export const FieldSEPAPaymentType = () => {
  const { PTR } = useSelector((state: any) => state.PMCReducers);

  return (
    <>
      <div className="form-part-title">{trb(i18.PaymentParts.PaymentType)}</div>
      <FormField
        name="Payment.SEPAPaymentType"
        label={trb(i18.Labels.SEPAPaymentType)}
      >
        <Select
          options={PTR?.SEPAPaymentTypes?.map((item: any) => ({
            label: trb(translationGroups.SEPAPaymentTypes + item.Code),
            value: item.Code,
          }))}
        />
      </FormField>
    </>
  );
};
