import { ReactNode, useState } from 'react';
import { Col, Row, Steps } from 'antd';
import { CustomIcon, i18, trb } from 'src/Utilities';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'src/shared';
import { LeftOutlined } from '@ant-design/icons';
import { BeneficiariesDeleteSign } from './BeneficiariesDeleteSign';
import { BeneficiariesActions, BeneficiariesConstants } from 'src/Redux';
import { Notification } from '../../../../../Components';

export const BeneficiariesDelete = ({ onBack, onFinished, children, selected }: { selected: string[]; onBack: () => void; onFinished: () => void; children: ReactNode }) => {
  const dispatch = useDispatch();
  const [authType, setAuthType] = useState<string>('');
  const [step, setStep] = useState(0);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const { language } = useSelector((state: any) => state.languageReducer);

  const handleDelete = async () => {
    setIsDeleteLoading(true);

    const { type, payload } = await dispatch(BeneficiariesActions.deleteMandate(selected) as any);

    if (type === BeneficiariesConstants.DELETE_MANDATE_SUCCESS) {
      const res = await dispatch(BeneficiariesActions.postSignDeleteMandate({ ProductId: payload.Token, Language: language }) as any);

      setAuthType(res?.payload?.AuthType);

      if (res.payload.IsSuccess) {
        setStep(1);
      } else {
        Notification({
          type: 'warning',
          message: i18.NotificationTitles.MandateDeletedCanceled,
          description: i18.NotificationMessages.MandateDeletedCanceled,
        });
      }

      setIsDeleteLoading(false);
    }
  };

  return (
    <>
      <Steps
        direction="horizontal"
        current={step}
        className={`mb-3 eb_steps pt-3`}
        items={[
          {
            icon: (
              <div>
                <CustomIcon
                  size={40}
                  type="custom"
                  icon="depositFirstStep"
                />
              </div>
            ),
          },
          {
            icon: (
              <div>
                <CustomIcon
                  size={40}
                  type="custom"
                  icon="depositSecondStep"
                />
              </div>
            ),
          },
        ]}
      />
      {step === 1 ? (
        <BeneficiariesDeleteSign
          authType={authType}
          onFinished={onFinished}
          onCanceled={() => setStep(0)}
        />
      ) : (
        <>
          <div className="mb-3">
            <Button
              className="pl-0 back-button-link"
              type="link"
              icon={<LeftOutlined />}
              onClick={onBack}
            >
              {trb(i18.Labels.Back)}
            </Button>
          </div>
          <div className="eb_subtitle p-0 mb-2">{trb(i18.Labels.DeleteBeneficiary)}</div>
          {children}
          <Row
            gutter={[8, 0]}
            justify="end"
          >
            <Col>
              <Button
                onClick={onFinished}
                disabled={isDeleteLoading}
              >
                {trb(i18.Buttons.Cancel)}
              </Button>
            </Col>

            <Col>
              <Button
                htmlType="submit"
                type="primary"
                loading={isDeleteLoading}
                disabled={isDeleteLoading}
                onClick={() => handleDelete()}
              >
                {trb(i18.Buttons.Approve)}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
