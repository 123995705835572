import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { PMCActions, BeneficiariesActions } from 'src/Redux';
import { i18, throwError, SIGN_CANCEL_TYPES, checkJsonProp } from 'src/Utilities';
import { Notification, OperationApproval } from 'src/Components';
import { BeneficiariesDeleteCheckStatus } from './BeneficiariesDeleteCheckStatus';

interface IProps {
  onFinished: () => void;
  onCanceled: () => void;
  authType: string;
}

export const BeneficiariesDeleteSign = ({ onFinished, onCanceled, authType }: IProps) => {
  const dispatch = useDispatch();
  const [productId, setProductId] = useState('');
  const { signDeleteMandateInfo } = useSelector((state: any) => state.BeneficiariesReducers);

  const resetSignDeleteMandateInfo = () => {
    dispatch(BeneficiariesActions.setSignDeleteMandateInfo(null) as any);
  };

  useEffect(() => {
    return () => {
      resetSignDeleteMandateInfo();
    };
  }, []);

  const cancelApprove = (type: any, errorCode: string, notificationType = 'warning') => {
    let message;

    switch (type) {
      case SIGN_CANCEL_TYPES.TIMEOUT:
        message = 'PaymentCanceledTimeout';
        break;
      case SIGN_CANCEL_TYPES.MANUAL:
        message = 'PaymentCanceledManual';
        break;
      case SIGN_CANCEL_TYPES.REJECTED:
        message = 'PaymentCanceledRejected';
        break;
      default:
        message = errorCode?.replace('.', '');
        break;
    }

    Notification({
      type: notificationType,
      // @ts-ignore
      message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.DepositSignFail,
      // @ts-ignore
      description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.DepositSignFail,
    });

    onCanceled();
  };

  const successApprove = () => {
    setProductId(signDeleteMandateInfo.ProductId);
  };

  const submitMobileApprove = async () => {
    try {
      const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: signDeleteMandateInfo.Token }) as any);

      if (response?.payload) {
        if (response.payload.AuthCheckResult === null) {
          return true;
        }

        if (response.payload.AuthCheckResult === false) {
          if (response.payload.ErrorCode) {
            cancelApprove(null, response?.payload?.ErrorCode);
          } else {
            cancelApprove(null, 'BadApproveCode');
          }
        } else if (response.payload.AuthCheckResult === true && response.payload.IsSuccessfull) {
          successApprove();
        }
      } else {
        throwError(response, 'submitMobileApprove');
      }

      return false;
    } catch (error) {
      return false;
    }
  };

  const submitSmsApprove = async (values: any) => {
    await dispatch(PMCActions.postAPESCR(values) as any).then((response: any) => {
      const IsSuccessfull = checkJsonProp(response, 'payload.IsSuccessfull');
      const ErrorCode = checkJsonProp(response, 'payload.ErrorCode');

      if (IsSuccessfull) {
        successApprove();
      } else {
        cancelApprove(null, ErrorCode, 'error');
      }
    });
  };

  if (productId) {
    return (
      <BeneficiariesDeleteCheckStatus
        onFinished={onFinished}
        productId={productId}
      />
    );
  }

  return (
    <>
      {signDeleteMandateInfo?.IsSuccess && (
        <OperationApproval
          RQ_ID={signDeleteMandateInfo.Token}
          AuthType={authType}
          code={signDeleteMandateInfo.ChallengeCode}
          cancelApprove={cancelApprove}
          successApprove={successApprove}
          submitSmsApprove={submitSmsApprove}
          submitMobileApprove={submitMobileApprove}
        />
      )}
    </>
  );
};
