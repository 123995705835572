import React from 'react';
import PropTypes from 'prop-types';

import { i18, trb, checkJsonProp, dateFormat, formatCurrentDate, formatDate } from '../../../Utilities';
import { ContentLoader, ContentNoData, Number } from '../../../Components';

export const PrintLoanSchedule = (props) => {
  if (props.sending) {
    return <ContentLoader />;
  }

  if (!props.data) {
    return <ContentNoData id="PrintLoanSchedule" />;
  }

  const date = formatDate(formatCurrentDate(), dateFormat().dateTime);

  function header() {
    return (
      <>
        <table className="eb_print_header">
          <tbody>
            <tr>
              <td className="eb_print_header_icon">
                <div>
                  <img
                    src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGO}`}
                    alt={import.meta.env.VITE_TITLE}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <table className="eb_print_header_inner">
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        <b>{checkJsonProp(props.COMPANY, 'OrgName')}</b>
                      </td>
                    </tr>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.CompanyCode)}</th>
                      <td>{checkJsonProp(props.COMPANY, 'OrgCode')}</td>
                    </tr>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.CompanyAddress)}</th>
                      <td>{checkJsonProp(props.COMPANY, 'OrgAddress')}</td>
                    </tr>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.VatCode)}</th>
                      <td>{checkJsonProp(props.COMPANY, 'VATCode')}</td>
                    </tr>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.CompanyEmail)}</th>
                      <td>{checkJsonProp(props.COMPANY, 'OrgEMail')}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td>
                <table className="eb_print_header_inner">
                  <tbody>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.FormattedDateAndTime)}</th>
                      <td>{date}</td>
                    </tr>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.Client)}</th>
                      <td>{props.data.ADR.ClientName}</td>
                    </tr>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.ClientCode)}</th>
                      <td>{props.data.ADR.ClientCode}</td>
                    </tr>
                    <tr>
                      <th className="text-right">{trb(i18.PrintLabels.ClientAddress)}</th>
                      <td className="text-nowrap">{props.data.ADR.ClientAddress}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          className="eb_print_header mb-1 mt-2"
          style={{ fontSize: '1.2rem' }}
        >
          <tbody>
            <tr>
              <th colSpan={5}>{props.data.title}</th>
            </tr>
          </tbody>
        </table>
      </>
    );
  }

  return (
    <div className="eb_print_wrapper">
      {header()}
      <table
        className="eb_print_table"
        style={{ fontSize: '1rem' }}
      >
        <thead>
          <tr>
            <th className="text-black-50">
              <b>{trb(i18.PrintLabels.DateDisplay)}</b>
            </th>
            <th className="text-black-50">
              <b>{trb(i18.PrintLabels.LoanAmount)}</b>
            </th>
            <th className="text-black-50">
              <b>{trb(i18.PrintLabels.InterestAmount)}</b>
            </th>
            <th className="text-black-50">
              <b>{trb(i18.PrintLabels.Installment)}</b>
            </th>
            <th className="text-black-50">
              <b>{trb(i18.PrintLabels.LoanResidual)}</b>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data.scheduleData.map((item, index) => (
            <tr key={index}>
              <td>{item.dateDisplay}</td>
              <td>
                <Number value={item.loanAmount} />
              </td>
              <td>
                <Number value={item.interestAmount} />
              </td>
              <td>
                <Number value={item.installment} />
              </td>
              <td>
                <Number value={item.loanResidual} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

PrintLoanSchedule.propTypes = {
  sending: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
};
