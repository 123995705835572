import React from "react";
import {connect} from "react-redux";
import {checkJsonProp, i18, trb} from "../../../Utilities";
import DOMPurify from "dompurify";

const StaticMessage = (props) => {

    let text = trb(i18.Labels.unreadMessages);
    let propsName = text.substring(text.indexOf('{') + 1, text.indexOf('}'));
    text = text.replace("{" + propsName + "}", checkJsonProp(props, propsName));
    if(text === 'unreadMessages'){
        return null;
    }
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
        // set all elements owning target to target=_blank
        if ('target' in node) {
            node.setAttribute('target', '_blank');
            // prevent https://www.owasp.org/index.php/Reverse_Tabnabbing
            node.setAttribute('rel', 'noopener noreferrer');
        }
        // set non-HTML/MathML links to xlink:show=new
        if (!node.hasAttribute('target')
            && (node.hasAttribute('xlink:href')
                || node.hasAttribute('href'))) {
            node.setAttribute('xlink:show', 'new');
        }
    });
    return (
        <div>
            <div
                className="mb-3"
                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(text)}}
            />
        </div>
    );

};

function mapStateToProps(state) {
    const {COMPANY} = state.AMCReducers;
    return {
        COMPANY
    };
}

const connectedStaticMessage = connect(mapStateToProps)(StaticMessage);
export {connectedStaticMessage as StaticMessage};

