export const FeeConstants = {
    GET_FEE_LIST_REQUEST: 'GET_FEE_LIST_REQUEST',
    GET_FEE_LIST_SUCCESS: 'GET_FEE_LIST_SUCCESS',
    GET_FEE_LIST_ERROR: 'GET_FEE_LIST_ERROR',

    GET_FEE_PAYMENT_REQUEST: 'GET_FEE_PAYMENT_REQUEST',
    GET_FEE_PAYMENT_SUCCESS: 'GET_FEE_PAYMENT_SUCCESS',
    GET_FEE_PAYMENT_ERROR: 'GET_FEE_PAYMENT_ERROR',

    POST_FEE_LAST_FEE_REQUEST: 'POST_FEE_LAST_FEE_REQUEST',
    POST_FEE_LAST_FEE_SUCCESS: 'POST_FEE_LAST_FEE_SUCCESS',
    POST_FEE_LAST_FEE_ERROR: 'POST_FEE_LAST_FEE_ERROR',
};