/* eslint-disable @typescript-eslint/no-explicit-any */
import { IUserLimitsGrantLimit } from 'src/Containers';

export const transformUserLimits = (data: Array<IUserLimitsGrantLimit>) => {
  const transformed = data.map((limits) => ({
    EbankGrantId: limits.EbankGrantId,
    IsAdmin: limits.IsAdmin,
    ProfileType: limits.ProfileType,
    LimitsModelLists: limits.LimitsModelLists.map(
      ({
        AccountNumber,
        AccountId,
        Currency,
        SmsOperation,
        SmsDay,
        SmsDayUsed,
        SmsMonth,
        SmsMonthUsed,
        MSignOperation,
        MSignDay,
        MSignDayUsed,
        MSignMonth,
        MSignMonthUsed,
        Permission,
        GlobalDaySms,
        GlobalMonthSms,
        GlobalOperationSms,
        GlobalDayESign,
        GlobalMothESign,
        GlobalOperationESign,
      }) => ({
        AccountNumber,
        AccountId,
        Currency,
        Permission,
        SMSOperationLimit: SmsOperation,
        SMSOperationLimitMax: GlobalOperationSms,

        SMSDayLimit: SmsDay,
        SMSDayLimitUsed: SmsDayUsed,
        SMSDayLimitMax: GlobalDaySms,

        SMSMonthLimit: SmsMonth,
        SMSMonthLimitUsed: SmsMonthUsed,
        SMSMonthLimitMax: GlobalMonthSms,

        MSignOperationLimit: MSignOperation,
        MSignOperationLimitMax: GlobalOperationESign,

        MSignDayLimit: MSignDay,
        MSignDayLimitUsed: MSignDayUsed,
        MSignDayLimitMax: GlobalDayESign,

        MSignMonthLimit: MSignMonth,
        MSignMonthLimitUsed: MSignMonthUsed,
        MSignMonthLimitMax: GlobalMothESign,
      })
    ).reduce((reduced: any, current: any) => {
      const el: any = reduced?.find((reducedElements: any) => reducedElements.AccountId === current.AccountId);
      const {
        AccountId,
        AccountNumber,
        Permission,
        Currency,
        SMSOperationLimit,
        SMSOperationLimitMax,
        SMSDayLimit,
        SMSDayLimitUsed,
        SMSDayLimitMax,
        SMSMonthLimit,
        SMSMonthLimitUsed,
        SMSMonthLimitMax,
        MSignOperationLimit,
        MSignOperationLimitMax,
        MSignDayLimit,
        MSignDayLimitUsed,
        MSignDayLimitMax,
        MSignMonthLimit,
        MSignMonthLimitUsed,
        MSignMonthLimitMax,
      } = current;

      const limitObj = {
        Currency,
        SMSOperationLimit,
        SMSOperationLimitMax,
        SMSDayLimit,
        SMSDayLimitUsed,
        SMSDayLimitMax,
        SMSMonthLimit,
        MSignOperationLimit,
        MSignOperationLimitMax,
        MSignDayLimit,
        MSignMonthLimit,
        SMSMonthLimitUsed,
        SMSMonthLimitMax,
        MSignDayLimitUsed,
        MSignDayLimitMax,
        MSignMonthLimitUsed,
        MSignMonthLimitMax,
      };

      if (el) {
        el.Limits.push(limitObj);
      } else {
        const newItem = {
          AccountId,
          AccountNumber,
          Permission,
          Limits: [limitObj],
        };
        reduced.push(newItem);
      }
      return reduced;
    }, []),
  }));
  return transformed;
};
