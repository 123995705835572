import {aPIPaths, handleResponse, methods, requestEBankJSON} from '../../Config';

export const DepositServices = {
    postDepositTermination,
    postDepositTerminationConfirm,
    postDepositTerminationCheck,
    postDepositOffer,
    postConfirmDeposit,
    postDepositDocument
};

function postDepositTermination(data) {
    return requestEBankJSON(aPIPaths.post_depositTermination, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postDepositTerminationConfirm(data) {
    return requestEBankJSON(aPIPaths.post_depositTerminationConfirm, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postDepositTerminationCheck(data) {
    return requestEBankJSON(aPIPaths.post_depositTerminationCheck, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postDepositOffer(data) {
    return requestEBankJSON(aPIPaths.post_depositOffer, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postConfirmDeposit (data) {
    return requestEBankJSON(aPIPaths.post_confirmDeposit, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}

function postDepositDocument (data) {
    return requestEBankJSON(aPIPaths.post_depositDocument, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}
