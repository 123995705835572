export const objectToArray = (object) => {
    let options = [];

    if (!object) {
        return null;
    }

    Object.entries(object).map(([value, text]) =>
        text && options.push({'label': text, 'value': value})
    );

    return options;
};


export const arrayToArrayObject = (array) => {
    let options = [];

    if (!array) {
        return options;
    }

    array.map(item =>
        options.push({label: item, value: item})
    );

    return options;
};