import PropTypes from 'prop-types';

import { i18, translationGroups, trb, PAYMENT_SYSTEMS, getClassificators } from 'src/Utilities';
import { FormPreviewSwitch } from '../FormPreviewSwitch';
import { payerPayeeTypeCurrent, paymentPurposeTypeCurrent, paymentPaymentSystemType } from '../FormStaticOptions';

export const NewPaymentPreview = (props) => {
  if (!props?.data?.Payment) {
    return null;
  }

  const CISO = getClassificators()?.CISO || {};

  const { InitialAccount, Payment } = props.data;
  const { IsAdditionalDataSelected } = props.data?.oldData?.Payment ?? {};

  if (Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2) {
    return {
      parts: [
        {
          fields: [
            {
              title: i18.Labels.FromAccount,
              value: InitialAccount,
            },
            {
              title: i18.Labels[props?.data?.Payment?.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? 'PurposePayment' : 'PaymentPurpose'],
              value: Payment.Purpose || Payment.ReferenceNo,
            },
            {
              title: i18.Labels.Amount,
              value: Payment.AmountFormatted,
            },
            {
              title: i18.Labels.Commission,
              value: Payment.CommissionFeeFormatted,
            },
            {
              title: i18.Labels.BankTaxesPays,
              value: trb(i18.Messages.SHARecipientAndPayer),
            },
          ],
        },
        {
          fields: [
            {
              title: i18.Labels.Payee,
              value: Payment?.Recipient,
            },
            {
              title: i18.Labels.ToAccount,
              value: Payment.BeneficiaryAccountNumber,
            },
            {
              title: i18.Labels.BeneficiaryAddress,
              value: Payment.BeneficiaryAddress,
            },
            {
              title: i18.Labels.BeneficiaryCity,
              value: Payment.BeneficiaryCity,
            },
            {
              title: i18.Labels.BeneficiaryPostcode,
              value: Payment.BeneficiaryPostCode,
            },
            {
              title: i18.Labels.BeneficiaryCountryCode,
              value: CISO[Payment.BeneficiaryCountryCode],
            },
            {
              title: i18.Labels.PayeeBankName,
              value: Payment.BankName,
            },
            {
              title: i18.Labels.BIC,
              value: Payment.BIC,
            },
            {
              title: i18.Labels.RecipientBankCountry,
              value: CISO[Payment.RecipientBankCountry],
            },
            {
              title: i18.Labels.CorrespondentBankBic,
              value: Payment.CorrespondentBankBic,
            },
          ],
        },
      ],
    };
  }
  return {
    parts: [
      {
        title: i18.PaymentParts.Payee,
        fields: [
          {
            title: i18.Labels.FromAccount,
            value: InitialAccount,
          },
          {
            title: i18.Labels.PayeeTitle,
            value: Payment?.Recipient,
          },
          {
            title: i18.Labels.PayeeAccount,
            value: Payment.BeneficiaryAccountNumber,
          },
          {
            title: i18.Labels.Amount,
            value: Payment.AmountFormatted,
          },
          /* {
                title: i18.Labels.PaymentType,
                value: paymentPaymentSystemType(Payment.PaymentSystem),
                type:'translate',
                translate: translationGroups.PaymentTypeSepa,
              }, */
          {
            title: i18.Labels.Commission,
            value: Payment.CommissionFeeFormatted,
          },
          {
            title: i18.Labels.PurposeType,
            value: paymentPurposeTypeCurrent(Payment.IsPurposeSelected),
            type: 'translate',
            translate: translationGroups.PaymentPurposeTypes,
            visible: Payment.PaymentSystem !== PAYMENT_SYSTEMS.TARGET2,
          },
          {
            title: i18.Labels[Payment.IsPurposeSelected ? 'PurposePayment' : 'PurposePaymentCode'],
            value: Payment.Purpose || Payment.ReferenceNo,
          },
          {
            title: i18.Labels.PaymentUniqueCode,
            value: Payment.CustomersCode,
          },
          {
            title: i18.Labels.BankName,
            value: Payment.RecipientBankName,
          },
          {
            title: i18.Labels.BIC,
            value: Payment.BIC,
          },
          {
            title: i18.Labels.PaymentType,
            value: paymentPaymentSystemType(Payment.PaymentSystem),
            type: 'translate',
            translate: translationGroups.PaymentTypeSepa,
          },
          {
            title: i18.Labels.CorrespondentBankBic,
            value: Payment.CorrespondentBankBic,
            visible: Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2,
          },
          {
            title: i18.Labels.BankTaxesPays,
            value: trb(i18.Messages.SHARecipientAndPayer),
            visible: Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2,
          },
          {
            title: i18.Labels.BeneficiaryCountryCode,
            value: CISO[Payment.BeneficiaryCountryCode],
          },
          {
            title: i18.Labels.BeneficiaryCity,
            value: Payment.BeneficiaryCity,
          },
          {
            title: i18.Labels.BeneficiaryAddress,
            value: Payment.BeneficiaryAddress,
          },
          {
            title: i18.Labels.BeneficiaryPostcode,
            value: Payment.BeneficiaryPostCode,
          },
          {
            title: i18.Labels.BankName,
            value: Payment.BankName,
            visible: Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2,
          },
          {
            title: i18.Labels.RecipientBankCountry,
            value: CISO[Payment.RecipientBankCountry],
          },
        ],
      },
      {
        title: i18.PaymentParts.PayerInformation,
        visible: IsAdditionalDataSelected,
        fields: [
          {
            title: i18.Labels.PayerType,
            value: payerPayeeTypeCurrent(Payment.PayerType),
            type: 'translate',
            translate: translationGroups.PayerPayeeTypes,
            visible: !!Payment.PayerType,
          },
          {
            title: i18.Labels.PayerIdentificationType,
            value: Payment.PayerIdentTypeCode ? trb(translationGroups.IdentificationType + Payment.PayerIdentTypeCode) : null,
            visible: !!Payment.PayerType,
          },
          {
            title: i18.Labels.PayerIdentificationCode,
            value: Payment.PayerIdentTypeValue,
            visible: !!Payment.PayerType,
          },
          {
            title: i18.Labels.PayerAddress,
            value: Payment.PayerAddress,
            visible: !!Payment.PayerType,
          },
        ],
      },
      {
        title: i18.PaymentParts.InitialPayerInformation,
        visible: IsAdditionalDataSelected,
        fields: [
          {
            title: i18.Labels.InitPayerType,
            value: payerPayeeTypeCurrent(Payment.InitPayerType),
            type: 'translate',
            translate: translationGroups.PayerPayeeTypes,
            visible: !!Payment.InitPayerType,
          },
          {
            title: i18.Labels.InitPayerTitle,
            value: Payment.InitPayerName,
            visible: !!Payment.InitPayerType,
          },
          {
            title: i18.Labels.InitPayerIdentificationType,
            value: Payment.InitPayerIdentTypeCode ? trb(translationGroups.IdentificationType + Payment.InitPayerIdentTypeCode) : null,
            visible: !!Payment.InitPayerType,
          },
          {
            title: i18.Labels.InitPayerIdentificationCode,
            value: Payment.InitPayerIdentTypeValue,
            visible: !!Payment.InitPayerType,
          },
        ],
      },
      {
        title: i18.PaymentParts.PayeeInformation,
        visible: IsAdditionalDataSelected,
        fields: [
          {
            title: i18.Labels.PayeeType,
            value: payerPayeeTypeCurrent(Payment.PayeeType),
            type: 'translate',
            translate: translationGroups.PayerPayeeTypes,
            visible: !!Payment.PayeeType,
          },
          {
            title: i18.Labels.PayeeIdentificationType,
            value: Payment.PayeeIdentTypeCode ? trb(translationGroups.IdentificationType + Payment.PayeeIdentTypeCode) : null,
            visible: !!Payment.PayeeType,
          },
          {
            title: i18.Labels.PayeeIdentificationCode,
            value: Payment.PayeeIdentTypeValue,
            visible: !!Payment.PayeeType,
          },
          {
            title: i18.Labels.PayeeAddress,
            value: Payment.PayeeAddress,
            visible: !!Payment.PayeeType,
          },
        ],
      },
      {
        title: i18.PaymentParts.InitialPayeeInformation,
        visible: IsAdditionalDataSelected,
        fields: [
          {
            title: i18.Labels.FinalPayeeType,
            value: payerPayeeTypeCurrent(Payment.FinalPayeeType),
            type: 'translate',
            translate: translationGroups.PayerPayeeTypes,
            visible: !!Payment.FinalPayeeType,
          },
          {
            title: i18.Labels.FinalPayeeName,
            value: Payment.FinalPayeeName,
            visible: !!Payment.FinalPayeeType,
          },
          {
            title: i18.Labels.FinalPayeeIdentificationType,
            value: Payment.FinalPayeeIdentTypeCode ? trb(translationGroups.IdentificationType + Payment.FinalPayeeIdentTypeCode) : null,
            visible: !!Payment.FinalPayeeType,
          },
          {
            title: i18.Labels.FinalPayeeIdentificationCode,
            value: Payment.FinalPayeeIdentTypeValue,
            visible: !!Payment.FinalPayeeType,
          },
        ],
      },
      {
        visible: !!Payment.SEPAPaymentType,
        fields: [
          {
            title: i18.Labels.SEPAPaymentType,
            value: Payment.SEPAPaymentType,
            type: 'translate',
            translate: translationGroups.SEPAPaymentTypes,
          },
        ],
      },
    ],
  };
};

FormPreviewSwitch.propTypes = {
  data: PropTypes.object.isRequired,
};
