const FormFieldInputWrapperClassesSME = (error, submitError, touched, inline, className) => {
  if (touched && (error || submitError)) {
    return (
      'form-field-item has-error' +
      (inline
        ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
          ? ' col-12 col-md-7'
          : ' col-12 col-md-9'
        : ' col-12')
    );
  } else {
    return (
      'form-field-item' +
      (inline
        ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
          ? ' col-12 col-md-7'
          : ' col-12 col-md-9'
        : ' col-12')
    );
  }
};

export const FormFieldInputWrapperClasses = FormFieldInputWrapperClassesSME;
