import './Loader.scss';

export const Loader = () => {
  return (
    <div className="Loader_wrapper">
      <div className="Loader">
        <div className="TopLine" />
        <div className="MiddleLine" />
        <div className="Dod" />
      </div>
    </div>
  );
};
