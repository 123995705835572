import React from 'react';
import { Alert, Input } from 'antd';

import { Number } from '../../';
import { FormFieldInputWrapperClasses, FormFieldWrapperClasses } from '../';
import { numberFormat, trb } from '../../../Utilities';
import { formFieldId } from 'src/utils';

export class NumberFormField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: !this.props.input.value && this.props.input.value !== 0 && !this.props.defaultValue && this.props.defaultValue !== 0 ? true : false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      (this.props.input.value !== undefined && this.props.input.value !== null && this.props.input.value !== prevProps.input.value) ||
      (this.props.defaultValue !== undefined && this.props.defaultValue !== null && this.props.defaultValue && this.props.defaultValue !== prevProps.defaultValue)
    )
      this.setState({
        active: false,
      });
  };

  onBlur = (e) => {
    if (!this.props.input.value && this.props.input.value !== 0)
      this.setState({
        active: true,
      });
    this.props.input.onBlur(e);
  };

  onSelect = () => {
    this.setState({
      active: false,
    });
  };

  onValueChange = (values) => {
    this.props.input.onChange(values.floatValue);
  };

  render = () => {
    const {
        title,
        titleWithoutTranslate,
        className,
        classNameTitle,
        classNameField,
        classNameError,
        classNameEl,
        style,
        size,
        inline,
        onlyComponent,
        placeholder,
        disabled,
        notify,
        error,
        meta,
        hightlight,
        loading,
        allowNegative,
      } = this.props,
      formatNumber = this.props.formatNumber ? this.props.formatNumber : numberFormat().currency,
      { active } = this.state;

    if (onlyComponent) {
      return (
        <div className="form-field-item col-8 col-lg p-0">
          <Number
            istext={0}
            format={formatNumber}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="off"
            className={classNameEl ? classNameEl : 'ant-input'}
            style={style}
            size={size}
            placeholder={trb(placeholder)}
            disabled={disabled}
            name={this.props.input.name}
            value={this.props.input.value}
            loading={loading}
            onValueChange={this.onValueChange}
            customInput={Input}
            allowNegative={allowNegative ?? true}
          />
          <div className="form-field-error">{meta.touched && (meta.error || meta.submitError)}</div>
        </div>
      );
    }

    return (
      <div id={formFieldId(this.props.input.name, 'NumberWrapper')} className={FormFieldWrapperClasses(className, inline, disabled, active, false, hightlight)}>
        {title && (
          <div
            className={
              classNameTitle
                ? 'form-field-title ' + classNameTitle
                : inline
                ? (className && className.indexOf('col-12') === -1) || (className && className.indexOf('col-12') !== -1 && className.indexOf('col-md-') !== -1)
                  ? 'form-field-title col-12 col-md-5 text-left text-md-right pb-2 pb-md-0 pr-md-0'
                  : 'form-field-title col-12 col-md-3 text-left text-md-right pb-2 pb-md-0'
                : 'form-field-title col-12'
            }
          >
            {titleWithoutTranslate ? title : trb(title)}
          </div>
        )}
        <div className={classNameField ? 'form-field-item ' + classNameField : FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline, className)}>
          <Number
            id={formFieldId(this.props.input.name, 'Number')}
            istext={0}
            format={formatNumber}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="none"
            spellCheck="off"
            className={classNameEl ? classNameEl : 'ant-input'}
            style={style}
            size={size}
            placeholder={trb(placeholder)}
            disabled={disabled}
            loading={loading}
            name={this.props.input.name}
            value={this.props.input.value}
            onValueChange={this.onValueChange}
            onBlur={this.onBlur}
            onSelect={this.onSelect}
            customInput={Input}
            allowNegative={allowNegative ?? true}
          />
        </div>
        {meta.touched && (meta.error || meta.submitError) ? (
          <div id={formFieldId(this.props.input.name, 'NumberError')} className={classNameError ? 'form-field-error ' + classNameError : inline ? 'form-field-error col-12 col-md-3' : 'form-field-error col-12'}>
            {meta.touched && (meta.error || meta.submitError)}
          </div>
        ) : notify ? (
          <>
            {notify && (
              <div className={inline ? 'form-field-notify col-12 col-md-3' : 'form-field-notify col-12'}>
                <Alert message={trb(notify)} />
              </div>
            )}
          </>
        ) : error ? (
          <>
            {error && (
              <div className={inline ? 'form-field-notify col-12 col-md-3' : 'form-field-notify col-12'}>
                <Alert
                  type="error"
                  message={trb(error)}
                />
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  };
}
