import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { i18, trb } from 'src/Utilities';

export const WaitingResponseLoader = () => {
  return (
    <div className="d-flex">
      <div className="login_subTitle mr-2">{trb(i18.Labels.WaitingForResponse)}</div>
      <Spin
        indicator={
          <LoadingOutlined
            style={{ fontSize: 18 }}
            spin
          />
        }
      />
    </div>
  );
};
