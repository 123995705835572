import { aPIPaths, handleResponse, requestEBankJSON } from '../../Config';
import { INewMandate, ISignDeleteMandatePayload, ISignMandatePayload, methods } from 'src/Redux';

export const BeneficiariesServices = {
  getBeneficiariesList,
  postCreateNewMandate,
  postSignMandate,
  getCheckStatusMandate,
  getCheckDeleteStatusMandate,
  postSignDeleteMandate,
  deleteMandate,
};

function getBeneficiariesList() {
  return requestEBankJSON(aPIPaths.get_BeneficiariesList).then(handleResponse);
}

function postCreateNewMandate(data: INewMandate) {
  return requestEBankJSON(aPIPaths.post_CreateNewMandate, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postSignMandate(data: ISignMandatePayload) {
  return requestEBankJSON(aPIPaths.post_SignMandate, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function getCheckStatusMandate(productId: string) {
  return requestEBankJSON(aPIPaths.get_CheckStatusMandate(productId)).then(handleResponse);
}

function deleteMandate(data: string[]) {
  return requestEBankJSON(aPIPaths.delete_Mandate, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postSignDeleteMandate(data: ISignDeleteMandatePayload) {
  return requestEBankJSON(aPIPaths.post_SignDeleteMandate, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function getCheckDeleteStatusMandate(productId: string) {
  return requestEBankJSON(aPIPaths.get_CheckDeleteStatusMandate(productId)).then(handleResponse);
}

