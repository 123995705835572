import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';

import { MenuActions } from 'src/Redux';
import { useGetWindowSize, i18, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { Container } from 'src/Components';
import { BroadCastContainer, InboxContainer, NewContainer } from './Components';
import { FolderOutlined, MessageOutlined, RightOutlined } from '@ant-design/icons';

export const MessagesContainer = () => {
  const windowSize = useGetWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { MessagesCount } = useSelector((state) => state.MessagingReducers);

  const [menuArray, setMenuArray] = useState([
    {
      title: i18.Chat.New,
      icon: <MessageOutlined />,
      path: routerPath.messagesNew,
      active: true,
    },
    {
      title: i18.Chat.Inbox,
      icon: <FolderOutlined />,
      path: routerPath.messagesInbox,
    },
    {
      title: i18.Chat.BroadCast,
      icon: <RightOutlined />,
      path: routerPath.messagesBroadCast,
    },
  ]);

  useEffect(() => {
    const menu = MessagesCount
      ? [
          {
            title: i18.Chat.New,
            icon: <MessageOutlined />,
            path: `${routerPath.messages}/${routerPath.messagesNew}`,
            active: true,
          },
          {
            title: i18.Chat.Inbox,
            text: ' (' + MessagesCount.CountChat + ')',
            icon: <FolderOutlined />,
            path: `${routerPath.messages}/${routerPath.messagesInbox}`,
          },
          {
            title: i18.Chat.BroadCast,
            text: ' (' + MessagesCount.CountBroadCast + ')',
            icon: <RightOutlined />,
            path: `${routerPath.messages}/${routerPath.messagesBroadCast}`,
          },
        ]
      : menuArray;
    if (MessagesCount) {
      setMenuArray(menu);
    }

    dispatch(MenuActions.setContentMenu(menu));
    dispatch(MenuActions.setTitle(trb(i18.Chat.ChatContainer)));

    return () => {
      dispatch(MenuActions.setContentMenu(null));
    };
  }, []);

  useEffect(() => {
    const menu = [
      {
        title: i18.Chat.New,
        icon: <MessageOutlined />,
        path: `${routerPath.messages}/${routerPath.messagesNew}`,
        active: true,
      },
      {
        title: i18.Chat.Inbox,
        text: ` (${MessagesCount.CountChat})`,
        icon: <FolderOutlined />,
        path: `${routerPath.messages}/${routerPath.messagesInbox}`,
      },
      {
        title: i18.Chat.BroadCast,
        text: ` (${MessagesCount.CountBroadCast})`,
        icon: <RightOutlined />,
        path: `${routerPath.messages}/${routerPath.messagesBroadCast}`,
      },
    ];
    setMenuArray(menu);

    dispatch(MenuActions.setContentMenu(menu));
  }, [MessagesCount]);

  const onTabChange = (tabPath) => {
    navigate(tabPath);
  };
  const tabItems = [
    { label: trb(i18.ContentTitles.MessagesNew), key: routerPath.messagesNew },
    { label: trb(i18.ContentTitles.MessagesInbox), key: routerPath.messagesInbox },
    { label: trb(i18.ContentTitles.MessagesBroadCast), key: routerPath.messagesBroadCast },
  ];
  return (
    <Container>
      {!windowSize.mobileCheck && (
        <Tabs
          id="MessagesContainerTabs"
          onChange={onTabChange}
          activeKey={location.pathname.split('/').pop()}
          className="mt--3 mb-3"
          items={tabItems}
        />
      )}
      <Routes>
        <Route
          path={routerPath.messagesNew}
          element={<NewContainer onTabChange={onTabChange} />}
        />
        <Route
          path={routerPath.messagesInbox}
          element={<InboxContainer />}
        />
        <Route
          path={routerPath.messagesBroadCast}
          element={<BroadCastContainer />}
        />
      </Routes>
    </Container>
  );
};
