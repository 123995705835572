import lockImg from 'src/Assets/images/Lock.png';
import { i18, trb } from '../../Utilities';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routerPath } from '../../Config';
import { UACConstants } from '../../Redux';
import { useDispatch } from 'react-redux';

export const UserBlocked = () => {
  const [timeLeft, setTimeLeft] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const worker = new Worker(`${import.meta.env.VITE_ROOT}workerCountdown.js`);

    if (window.Worker) {
      const endTime = new Date().getTime() + 300 * 1000;

      worker.postMessage({
        type: 'countdown',
        time: endTime,
      });

      worker.onmessage = (event) => {
        if (event.data.done) {
          dispatch({ type: UACConstants.CLEAR_SMSR_INFO });
          navigate(routerPath.login, {
            replace: true,
            state: { ...location.state, step: 1, isIdentificationError: false, customIdentificationError: null },
          });
        } else {
          setTimeLeft(`${event.data.minutes.toString().padStart(2, '0')}:${event.data.seconds.toString().padStart(2, '0')}`);
        }
      };

      return () => worker.terminate();
    } else {
      console.log("Your browser doesn't support Web Workers.");
    }

    return () => worker.terminate();
  }, []);

  return (
    <div>
      <div className="login-blocked-icon">
        <img
          src={lockImg}
          alt="lock"
        />
      </div>
      <div className="login-blocked-text">{trb(i18.Labels.LoginBlocked)}</div>
      <div
        className="login-blocked-timer"
        dangerouslySetInnerHTML={{ __html: trb(i18.Labels.LoginBlockedTimer, { value: timeLeft }) }}
      />
    </div>
  );
};
