export const IconInfo = ({ size = 26, color = '#7154D6', bgColor = '#FFF' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
    >
      <rect
        x="1"
        y="1"
        width="24"
        height="24"
        rx="6"
        fill={bgColor}
      />
      <rect
        x="1"
        y="1"
        width="24"
        height="24"
        rx="6"
        stroke={color}
        strokeWidth="1.5"
      />
      <path
        d="M12.9994 8.19807C12.8338 8.19807 12.6993 8.33253 12.7005 8.4982C12.7005 8.66387 12.835 8.79832 13.0006 8.79832C13.1663 8.79832 13.3008 8.66387 13.3008 8.4982C13.3008 8.33253 13.1663 8.19807 12.9994 8.19807"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3001 18.0232V12.3208H12.0996"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
