import { Input, InputProps } from 'antd';
import { useField } from 'formik';
import { NumericFormat } from 'react-number-format';
import { numberFormat } from '../../Utilities';

interface IProps {
  name?: string;
  handleChange?: (value: number | undefined) => void;
  status?: 'error' | undefined;
  disabled?: boolean;
  allowNegative?: boolean;
  suffix?: string;
  decimalScale?: number;
  decimalSeparator?: string;
  thousandSeparator?: string;
  allowedDecimalSeparators?: [];
  inputProps?: InputProps;
  format?: object;
}

export const InputNumber = ({ name = '', handleChange, format, ...restProps }: IProps) => {
  const [field, _meta, helpers] = useField(name);

  return (
    <NumericFormat
      allowNegative={false}
      decimalScale={2}
      {...numberFormat().currency}
      {...format}
      {...restProps}
      value={field.value}
      onValueChange={async (values) => {
        await helpers.setValue(values.floatValue ?? null);

        if (typeof handleChange === 'function') {
          handleChange(values.floatValue);
        }
      }}
      customInput={Input}
    />
  );
};
