export const DMCConstants = {
    POST_DMC_ADPDF_REQUEST: 'POST_DMC_ADPDF_REQUEST',
    POST_DMC_ADPDF_SUCCESS: 'POST_DMC_ADPDF_SUCCESS',
    POST_DMC_ADPDF_ERROR: 'POST_DMC_ADPDF_ERROR',

    POST_DMC_SPPDF_REQUEST: 'POST_DMC_SPPDF_REQUEST',
    POST_DMC_SPPDF_SUCCESS: 'POST_DMC_SPPDF_SUCCESS',
    POST_DMC_SPPDF_ERROR: 'POST_DMC_SPPDF_ERROR',

    POST_DMC_SDR_REQUEST: 'POST_DMC_SDR_REQUEST',
    POST_DMC_SDR_SUCCESS: 'POST_DMC_SDR_SUCCESS',
    POST_DMC_SDR_ERROR: 'POST_DMC_SDR_ERROR'
};