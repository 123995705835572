import { i18, trb } from 'src/Utilities';
import { Typography } from 'src/shared';

const { Text } = Typography;

export const Copyright = () => {
  return (
    <Text
      className="login_copyright_text"
      id="copyrightText"
    >
      {trb(i18.Labels.FooterCopyright, { yyyy: new Date().getFullYear() })}
    </Text>
  );
};
