import React from 'react';
import { Radio, Alert } from 'antd';

import { trb } from '../../../Utilities';
import { FormFieldInputWrapperClasses, FormFieldWrapperClasses } from '../';
import { formFieldId } from 'src/utils';

export const RadioFormField = (props) => {
  const {
    title,
    titleWithoutTranslate,
    className,
    classNameField,
    classNameTitle,
    inline,
    disabled,
    options,
    optionsLabel,
    optionsValue,
    translate,
    notify,
    small,
    meta,
    classNameItem,
    styleTitle,
    autosize,
    valueForDefault,
  } = props;
  const checkOption = (value, input) => {
      if (!options) {
        return null;
      }

      const optionValue = options.find((e) => e[optionsValue] === value);

      if (optionValue) {
        return value;
      } else {
        input.onChange(undefined);
      }
    },
    RadioItem = Radio.Button;

  return (
    <div className={`${disabled ? 'disabled ' : ''}${FormFieldWrapperClasses(className, inline, disabled, true, small)}`}>
      {title && (
        <div
          className={
            classNameTitle
              ? 'form-field-title position-static ' + classNameTitle
              : inline
              ? 'form-field-title position-static col-12 col-md-3 text-left text-md-right pb-2 pb-md-0'
              : 'form-field-title position-static col-12 mb-2'
          }
          style={styleTitle}
        >
          {titleWithoutTranslate ? title : trb(title)}
        </div>
      )}
      <div className={classNameField ? 'form-field-item ' + classNameField : FormFieldInputWrapperClasses(meta.error, meta.submitError, meta.touched, inline, className)}>
        <Radio.Group
          onChange={props.input.onChange}
          value={checkOption(props.input.value, props.input)}
          disabled={disabled}
          optionType="button"
          buttonStyle="solid"
          defaultValue={valueForDefault}
        >
          {options &&
            options.map((item, index) => (
              <RadioItem
                id={formFieldId(props.input.name, `RadioItem${index}`)}
                className={`${classNameItem} ${item.style ?? ''}`}
                //   style={autosize ? { width: 'fit-content', minWidth: `calc(80% / ${options.length})` } : { maxWidth: `calc(100% / ${options.length})` }}
                key={index}
                disabled={item.disabled}
                value={item[optionsValue]}
                defaultChecked={valueForDefault === item[optionsValue]}
              >
                {translate ? trb(translate + item[optionsLabel]) : item[optionsLabel]}
              </RadioItem>
            ))}
        </Radio.Group>
      </div>
      {meta.touched && (meta.error || meta.submitError) ? (
        <div
          id={formFieldId(props.input.name, 'RadioError')}
          className={inline ? 'form-field-error col-12 col-md-3' : 'form-field-error col-12'}
        >
          {meta.touched && (meta.error || meta.submitError)}
        </div>
      ) : (
        <>
          {notify && (
            <div className={inline ? 'form-field-notify col-12 mt-3' : 'form-field-notify col-12'}>
              <Alert message={trb(notify)} />
            </div>
          )}
        </>
      )}
    </div>
  );
};
