import {CMCConstants} from './constants';

const initialState = {}

export const CMCReducers = function (state = initialState, action) {
    switch (action.type) {
        case CMCConstants.POST_CMC_CIPTR_REQUEST:
            return {
                ...state,
                send: true,
                sendCIPTR: true,
                CIPTR: null,
            };
        case CMCConstants.POST_CMC_CIPTR_SUCCESS:
            return {
                ...state,
                send: false,
                sendCIPTR: false,
                CIPTR: action.payload,
            };
        case CMCConstants.POST_CMC_CIPTR_ERROR:
            return {
                ...state,
                send: false,
                sendCIPTR: false,
                errors: action.error
            };
        case CMCConstants.POST_CMC_PDR_REQUEST:
            return {
                ...state,
                send: true,
                sendPDR: true,
                PDR: null,
            };
        case CMCConstants.POST_CMC_PDR_SUCCESS:
            return {
                ...state,
                send: false,
                sendPDR: false,
                PDR: action.payload,
            };
        case CMCConstants.POST_CMC_PDR_ERROR:
            return {
                ...state,
                send: false,
                sendPDR: false,
                errors: action.error
            };
        case CMCConstants.POST_CMC_CIR_REQUEST:
            return {
                ...state,
                send: true,
                sendCIR: true,
                CIR: null,
            };
        case CMCConstants.POST_CMC_CIR_SUCCESS:
            return {
                ...state,
                send: false,
                sendCIR: false,
                CIR: action.payload,
            };
        case CMCConstants.POST_CMC_CIR_ERROR:
            return {
                ...state,
                send: false,
                sendCIR: false,
                errors: action.error
            };
        case CMCConstants.POST_CMC_VCR_REQUEST:
            return {
                ...state,
                send: true,
                sendVCR: true,
                VCR: null,
            };
        case CMCConstants.POST_CMC_VCR_SUCCESS:
            return {
                ...state,
                send: false,
                sendVCR: false,
                VCR: action.payload,
            };
        case CMCConstants.POST_CMC_VCR_ERROR:
            return {
                ...state,
                send: false,
                sendVCR: false,
                errors: action.error
            };
        case CMCConstants.POST_CMC_SCR_REQUEST:
            return {
                ...state,
                send: true,
                sendSCR: true,
                SCR: null,
            };
        case CMCConstants.POST_CMC_SCR_SUCCESS:
            return {
                ...state,
                send: false,
                sendSCR: false,
                SCR: action.payload,
            };
        case CMCConstants.POST_CMC_SCR_ERROR:
            return {
                ...state,
                send: false,
                sendSCR: false,
                errors: action.error
            };
        case CMCConstants.POST_CMC_DPDF_REQUEST:
            return {
                ...state,
                send: true,
                sendDPDF: true,
                DPDF: null,
            };
        case CMCConstants.POST_CMC_DPDF_SUCCESS:
            return {
                ...state,
                send: false,
                sendDPDF: false,
                DPDF: action.payload,
            };
        case CMCConstants.POST_CMC_DPDF_ERROR:
            return {
                ...state,
                send: false,
                sendDPDF: false,
                errors: action.error
            };
        case CMCConstants.POST_CMC_GET_ACCOUNT_BALANCE_XML_REQUEST:
            return {
                ...state,
                send: true,
                sendXML: true,
                XML: null,
            };
        case CMCConstants.POST_CMC_GET_ACCOUNT_BALANCE_XML_SUCCESS:
            return {
                ...state,
                send: false,
                sendXML: false,
                XML: action.payload,
            };
        case CMCConstants.POST_CMC_GET_ACCOUNT_BALANCE_XML_ERROR:
            return {
                ...state,
                send: false,
                sendXML: false,
                errors: action.error
            };

        default:
            return state;
    }
};