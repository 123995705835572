import { FormField, Input } from 'src/shared';
import { i18, trb } from '../../../../../Utilities';

export const FieldBeneficiaryCity = () => {
  return (
    <FormField
      name="Payment.BeneficiaryCity"
      label={trb(i18.Labels.BeneficiaryCity)}
    >
      <Input />
    </FormField>
  );
};
