import {MenuConstants} from './';

export const MenuActions = {
    toggle,
    setContentMenu,
    setTitle
};

function toggle() {
    return dispatch => {
        dispatch({type: MenuConstants.MENU_TOGGLE});
    };
}

function setContentMenu(contentMenu, path){
    return dispatch => {
        dispatch({type: MenuConstants.SET_CONTENT_MENU, payload: {contentMenu, path}});
    };
}

function setTitle(title){
    return dispatch => {
        dispatch({type: MenuConstants.SET_TITLE, payload: {title}});
    };
}

