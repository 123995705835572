/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkMax, i18, requiredNotUndefined, translationGroups, trb } from 'src/Utilities';
import { ContentNoData, FormField, FormFieldType, Number } from 'src/Components';

import { ITransformedLimitsData } from './interfaces';
import { Label } from './Label';
import { LIMITS_PERMISSIONS_OPTIONS } from 'src/constants';
interface IProps {
  data: ITransformedLimitsData;
  edit: boolean;
  isAdmin: boolean;
  disabledForm: boolean;
  userId: string;
}
export const AccountsLimits = ({ data, edit, isAdmin, disabledForm, userId }: IProps) => {
  const operationTypes = ['SMS', 'MSign'];
  const limitsTypes = [
    { type: 'OperationLimit', label: 'OperationLimit' },
    { type: 'DayLimit', typeUsed: 'DayLimitUsed', label: 'OperationDayLimit' },
    { type: 'MonthLimit', typeUsed: 'MonthLimitUsed', label: 'OperationMonthLimit' },
  ];

  const formID = (id: string) => {
    return `${data.ProfileType}_${id}`;
  };
  return (
    <div
      className="form-part"
      key={userId}
    >
      {data?.Limits?.length ? (
        <>
          <div
            className="eb_table_wrapper eb_table_wrapper_bottom_lined"
            id={formID('AccountsLimitsTable')}
          >
            <div className="eb_table">
              <div
                className="row eb_table_head d-none d-md-flex text-break"
                id={formID('AccountsLimitsTableHeader')}
              >
                <div className="col-2 px-1">{trb(i18.Labels.AccountNumber)}</div>
                <div className="col-1 px-1">{trb(i18.Labels.Currency)}</div>
                <div className="col-1 px-1">{trb(i18.Labels.SignType)}</div>
                <div className="col-2 px-1 text-right">{trb(i18.Labels.OperationLimit)}</div>
                <div className="col-2 px-1 text-right">{trb(i18.Labels.OperationDayLimit)}</div>
                <div className="col-2 px-1 text-right">{trb(i18.Labels.OperationMonthLimit)}</div>
                <div className="col-2 px-1 pr-3 text-right">{trb(i18.Labels.OperationPermission)}</div>
              </div>
              {data.Limits.map((accountItem, indexRoot) =>
                accountItem.Limits.map((limitItem, limitItemIndex: number) => (
                  <div
                    className="row eb_table_row"
                    key={`${indexRoot}_${limitItemIndex}`}
                    id={formID(`AccountsLimitsTableRow_${indexRoot}`)}
                  >
                    <div
                      className="col-12 col-md-2 d-flex align-items-center text-break px-1"
                      id={formID(`AccountsLimitsTableAccountNumber_${indexRoot}`)}
                    >
                      <Label
                        label={i18.Labels.AccountNumber}
                        className="mr-1"
                        id={formID(`AccountsLimitsTableAccountNumberLabel_${indexRoot}`)}
                      />
                      <div id={formID(`AccountsLimitsTableAccountNumberValue_${indexRoot}`)}>{accountItem.AccountNumber}</div>
                    </div>
                    <div
                      className="col-12 col-md-1 d-flex align-items-center px-1"
                      id={formID(`AccountsLimitsTableCurrency_${indexRoot}`)}
                    >
                      <Label
                        label={i18.Labels.Currency}
                        className="mr-1"
                        id={formID(`AccountsLimitsTableCurrencyLabel_${indexRoot}`)}
                      />
                      <div id={formID(`AccountsLimitsTableCurrencyValue_${indexRoot}`)}>{limitItem.Currency}</div>
                    </div>
                    <div className="col-12 col-md-1 px-1 d-none d-md-inline">
                      <div className="d-flex h-100 px-0">
                        <div
                          className="eb_table px-0 align-items-space-between"
                          id={formID(`AccountsLimitsTableSignType_${indexRoot}`)}
                        >
                          <Label
                            label={i18.Labels.SignType}
                            className="mr-1"
                            id={formID(`AccountsLimitsTableSignTypeLabel_${indexRoot}`)}
                          />
                          {operationTypes.map((item, index) => (
                            <div
                              className="col-12 d-flex  text-left text-md-right h-50 px-0 align-items-center"
                              key={`${accountItem}_${index}`}
                              id={formID(`AccountsLimitsTableSignType_${indexRoot}_${index}`)}
                            >
                              {trb((i18.Labels as any)[item])}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    {limitsTypes.map((limitsTypeItem, limitsIndex) => (
                      <div
                        className="col-12 col-md-2 px-1"
                        key={`${indexRoot}_${limitsIndex}`}
                        id={formID(`AccountsLimitsTableLimitsTypes_${indexRoot}_${limitsIndex}`)}
                      >
                        {operationTypes.map((operationItem, index) => (
                          <div
                            className="col-12 text-left text-md-right px-0"
                            key={`${indexRoot}_${limitsIndex}_${index}`}
                            id={formID(`AccountsLimitsTableLimitsTypes_${indexRoot}_${limitsIndex}_${index}`)}
                          >
                            <Label
                              label={(i18.Labels as any)[`${operationItem}${limitsTypeItem.label}`]}
                              className="mr-1"
                              id={formID(`AccountsLimitsTableLimitsTypesLabel_${indexRoot}_${limitsIndex}_${index}`)}
                            />
                            {edit ? (
                              <FormField
                                validate={checkMax((limitItem as any)[`${operationItem}${limitsTypeItem.type}Max`])}
                                name={`Limits.${indexRoot}.Limits.${limitItemIndex}.${operationItem}${limitsTypeItem.type}`}
                                component={FormFieldType.number}
                                className={`mb-0 ${index + 1 !== operationTypes.length ? 'pb-2' : ''}`}
                                disabled={disabledForm}
                              />
                            ) : (
                              <>
                                <Number
                                  value={(limitItem as any)[`${operationItem}${limitsTypeItem.typeUsed ?? limitsTypeItem.type}`]}
                                  id={formID(`AccountsLimitsTableLimitsTypesUsed_${indexRoot}_${limitsIndex}_${index}`)}
                                />
                                {limitsTypeItem.typeUsed && (
                                  <span
                                    className="pl-1"
                                    id={formID(`AccountsLimitsTableLimitsTypesLimit_${indexRoot}_${limitsIndex}_${index}`)}
                                  >
                                    / <Number value={(limitItem as any)[`${operationItem}${limitsTypeItem.type}`]} />
                                  </span>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    ))}
                    <div className="col-12 col-md-2 text-left d-flex align-items-center justify-content-end operation_permission px-1 pr-3">
                      <Label
                        label={i18.Labels.OperationPermission}
                        className="mr-1"
                      />
                      {edit && !isAdmin ? (
                        <FormField
                          name={`Limits.${indexRoot}.Permission`}
                          component={FormFieldType.select}
                          options={LIMITS_PERMISSIONS_OPTIONS}
                          optionsLabel="label"
                          optionsValue="value"
                          translate={translationGroups.AccountPerrmissions}
                          validate={requiredNotUndefined}
                          disabled={disabledForm}
                        />
                      ) : (
                        <div className="w-100 text-left text-md-right">
                          {
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            <>{trb(i18.AccountPerrmissions?.[LIMITS_PERMISSIONS_OPTIONS.find((el) => el.value === accountItem.Permission)?.label]) ?? '-'}</>
                          }
                        </div>
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </>
      ) : (
        <ContentNoData id={formID('AcountsLimits')} />
      )}
    </div>
  );
};
