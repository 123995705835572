export const OMCConstants = {
  POST_OMC_ROL_REQUEST: 'POST_OMC_ROL_REQUEST',
  POST_OMC_ROL_SUCCESS: 'POST_OMC_ROL_SUCCESS',
  POST_OMC_ROL_ERROR: 'POST_OMC_ROL_ERROR',

  POST_OMC_ODL_REQUEST: 'POST_OMC_ODL_REQUEST',
  POST_OMC_ODL_SUCCESS: 'POST_OMC_ODL_SUCCESS',
  POST_OMC_ODL_ERROR: 'POST_OMC_ODL_ERROR',

  POST_OMC_ODL_PAGING_REQUEST: 'POST_OMC_ODL_PAGING_REQUEST',
  POST_OMC_ODL_PAGING_SUCCESS: 'POST_OMC_ODL_PAGING_SUCCESS',
  POST_OMC_ODL_PAGING_ERROR: 'POST_OMC_ODL_PAGING_ERROR',

  POST_OMC_UOLR_REQUEST: 'POST_OMC_UOLR_REQUEST',
  POST_OMC_UOLR_SUCCESS: 'POST_OMC_UOLR_SUCCESS',
  POST_OMC_UOLR_ERROR: 'POST_OMC_UOLR_ERROR',

  POST_OMC_DUOR_REQUEST: 'POST_OMC_DUOR_REQUEST',
  POST_OMC_DUOR_SUCCESS: 'POST_OMC_DUOR_SUCCESS',
  POST_OMC_DUOR_ERROR: 'POST_OMC_DUOR_ERROR',

  POST_OMC_PSUOR_REQUEST: 'POST_OMC_PSUOR_REQUEST',
  POST_OMC_PSUOR_SUCCESS: 'POST_OMC_PSUOR_SUCCESS',
  POST_OMC_PSUOR_ERROR: 'POST_OMC_PSUOR_ERROR',

  POST_OMC_SUORSMS_REQUEST: 'POST_OMC_SUORSMS_REQUEST',
  POST_OMC_SUORSMS_SUCCESS: 'POST_OMC_SUORSMS_SUCCESS',
  POST_OMC_SUORSMS_ERROR: 'POST_OMC_SUORSMS_ERROR',

  POST_OMC_SUOR_REQUEST: 'POST_OMC_SUOR_REQUEST',
  POST_OMC_SUOR_SUCCESS: 'POST_OMC_SUOR_SUCCESS',
  POST_OMC_SUOR_ERROR: 'POST_OMC_SUOR_ERROR',

  REMOVE_OMC_UOLR_COMPLETED: 'REMOVE_OMC_UOLR_COMPLETED',
  RESET_PSUOR: 'RESET_PSUOR',
};
