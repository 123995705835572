import Fuse from 'fuse.js';
import { useDispatch, useSelector } from 'react-redux';
import { ContentLoader, ContentNoData } from 'src/Components';
import { BeneficiariesListTable, NewBeneficiaryButton } from './components';
import { AlertNotification, Button } from 'src/shared';
import { i18, ibanFormat, trb } from 'src/Utilities';
import { useEffect, useMemo, useState } from 'react';
import { BeneficiariesActions, IBeneficiariesItem, UACActiveUser } from 'src/Redux';

import { Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { BeneficiariesDelete } from './components/BeneficiariesDelete';

export const BeneficiariesList = () => {
  const dispatch = useDispatch();

  const { loadingBeneficiariesList, beneficiaryAdded, beneficiaryDeleted } = useSelector((state: any) => state.BeneficiariesReducers);

  const IsAdmin = useSelector(UACActiveUser)?.IsAdmin;

  const [query, setQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState<Array<string>>([]);
  const [deleteStep, setDeleteStep] = useState(false);

  const { beneficiariesList: fullBeneficiariesList } = useSelector((state: any) => state.BeneficiariesReducers);

  const beneficiariesList = useMemo((): IBeneficiariesItem[] => {
    const list = fullBeneficiariesList || [];

    const fuse = new Fuse<IBeneficiariesItem>(list, {
      keys: [
        'CreditorTitle',
        'CreditorAccountNumber',
        {
          name: '_CreditorAccountNumber',
          getFn: (obj) => ibanFormat(obj['CreditorAccountNumber']),
        },
      ],
      shouldSort: false,
      threshold: 0,
      ignoreLocation: true,
    });

    const results = fuse.search<IBeneficiariesItem>(query);

    return query ? results.reduce((acc: IBeneficiariesItem[], next) => [...acc, next.item], []) : list;
  }, [query, fullBeneficiariesList]);

  useEffect(() => {
    if (!fullBeneficiariesList) {
      dispatch(BeneficiariesActions.getBeneficiariesList() as any);
    }
  }, [fullBeneficiariesList]);

  useEffect(() => {
    return () => {
      dispatch(BeneficiariesActions.setBeneficiariesList(null) as any);
    };
  }, []);

  if (loadingBeneficiariesList) {
    return <ContentLoader />;
  }

  if (deleteStep) {
    return (
      <BeneficiariesDelete
        selected={selectedItems}
        onBack={() => setDeleteStep(false)}
        onFinished={() => {
          setDeleteStep(false);
          setSelectedItems([]);
        }}
      >
        <BeneficiariesListTable
          isAdmin={false}
          selected={[]}
          list={beneficiariesList?.filter((b) => selectedItems.includes(b.IdGuid))}
          onSelectedChange={setSelectedItems}
        />
      </BeneficiariesDelete>
    );
  }

  return (
    <>
      <AlertNotification
        message={trb(i18.Messages.BeneficiariesInfoMessage)}
        type="info"
        alertClassName="mb-3"
      />
      {beneficiaryAdded && (
        <AlertNotification
          message={trb(i18.NotificationTitles.NewMandateCreated)}
          type="success"
          alertClassName="mb-3"
        />
      )}
      {beneficiaryDeleted && (
        <AlertNotification
          message={trb(i18.NotificationTitles.MandateDeleted)}
          type="success"
          alertClassName="mb-3"
        />
      )}
      <Row className="mb-5">
        <div className="col-12 col-sm-7 pr-0 text-left mb-1 form-field-wrapper big-input small-input ml-0 pl-0">
          <Input
            placeholder={trb(i18.PlaceHolders.PaymentTemplateSearch)}
            suffix={<SearchOutlined />}
            className="form-field-item"
            allowClear
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        <div className="col-12 col-sm-5 text-right mt-1 mt-sm-0 mr-0 pl-0 pr-0">
          <NewBeneficiaryButton />
        </div>
      </Row>
      {!beneficiariesList || beneficiariesList.length === 0 ? (
        <ContentNoData id="BeneficiariesListTable" />
      ) : (
        <>
          <BeneficiariesListTable
            isAdmin={IsAdmin}
            selected={selectedItems}
            list={beneficiariesList}
            onSelectedChange={setSelectedItems}
          />
          {IsAdmin && (
            <div className="text-right mb-2 w-100">
              <Button
                id="BeneficiariesDeleteButton"
                onClick={() => {
                  dispatch(BeneficiariesActions.setBeneficiariesList(fullBeneficiariesList) as any);
                  setDeleteStep(true);
                }}
                disabled={selectedItems.length <= 0}
                className="col-12 col-md-auto"
              >
                {trb(i18.Buttons.DeleteSelectedBeneficiaries)}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  );
};
