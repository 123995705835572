import React, { useEffect, useState } from 'react';
import { Container, ContentLoader, ContentNoData, Notification } from 'src/Components';
import { Steps } from 'antd';
import { PaymentPreview, PaymentUnsuccess, PaymentWaiting, ViewPaymentsNew } from 'src/Containers';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkJsonExist,
  CustomIcon,
  i18,
  logError,
  Payment,
  PaymentType,
  Sleep,
  PAYMENT_SYSTEMS,
  throwError,
  translationGroups,
  trb,
  PAYMENT_ERRORS,
  CURRENCIES,
  SIGN_CANCEL_TYPES,
} from 'src/Utilities';
import { AMCActions, FeeActions, PMCActions } from 'src/Redux';
import { useLocation, useNavigate } from 'react-router-dom';

export const PaymentNew = () => {
  const { EUR } = CURRENCIES;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { sendPTR, PTR, PVR, PayPersonTypes, sendPayPersonTypes, checkIBANResult, sendGetFxRate, target2Data, forexPayments } = useSelector((state) => state.PMCReducers);
  const { sendFeeList, feeList } = useSelector((state) => state.FeeReducers);
  const { MALR, sendMALR } = useSelector((state) => state.UACReducers);
  const { language } = useSelector((state) => state.languageReducer);
  const amcReducers = useSelector((state) => state.AMCReducers);
  const { forexDraftInfo } = amcReducers;
  // const {translations } = useSelector((state) => state.translationReducer);

  const paymentType = Payment.NEW;

  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submittingSave, setSubmittingSave] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [saveSign, setSaveSign] = useState(false);
  const [withError, setWithError] = useState(null);
  const [approve, setApprove] = useState(null);
  const [data, setData] = useState(null);
  const [RQ_ID, setRQID] = useState(null);
  const [AuthType, setAuthType] = useState(null);
  const [Code, setCode] = useState(null);

  useEffect(() => {
    const componentMounted = async () => {
      let lng = language.split('-')[0];

      await dispatch(PMCActions.getPayPersonTypes({ language: lng }));
      if (paymentType === Payment.FEE && !sendFeeList && !feeList) {
        await dispatch(FeeActions.getFeeList());
      }
      localStorage.removeItem('SavedIBAN');
      localStorage.removeItem('SavedCurrency');
      await dispatch(PMCActions.clearCheckIBANMember());
      await dispatch(PMCActions.clearHistoricalPayments());
      await dispatch(PMCActions.clearPVR());
    };
    componentMounted();
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    resetPayment();
    const paymentTypeChanged = async () => {
      // setData(null)
      setErrors(null);
      setCurrentStep(0);
      setSaveSign(false);

      localStorage.removeItem('SavedIBAN');
      localStorage.removeItem('SavedCurrency');

      await dispatch(PMCActions.clearCheckIBANMember());
      await dispatch(PMCActions.clearHistoricalPayments());
      await dispatch(PMCActions.clearPVR());
    };
    if (paymentType) {
      paymentTypeChanged();
    }
  }, [paymentType]);

  useEffect(() => {
    if (!PVR) {
      setErrors(null);
    }
  }, [PVR]);

  const AddRemoveNIUMPaymentValues = (currentValues) => {
    if (paymentType === Payment.NEW) {
      delete currentValues.Payment.AmountBuy;
      delete currentValues.Payment.AmountSell;
      delete currentValues.Payment.FixRateBuy;
      delete currentValues.Payment.FixRateSell;
      if (!currentValues?.Payment?.Currency) {
        currentValues.Payment.Currency = EUR;
      }
      if (currentValues?.Payment?.Currency?.indexOf('_') !== -1) {
        currentValues.Payment.Currency = currentValues.Payment.Currency.split('_')[1];
      }
    }
  };

  const AddRemoveTarget2PaymentValues = (currentValues) => {
    if (
      currentValues.Payment.Currency === EUR &&
      ((checkIBANResult?.IsValid &&
        checkIBANResult.Participant &&
        !checkIBANResult.Participant?.SepaInst &&
        !checkIBANResult.Participant?.SepaSCT &&
        !checkIBANResult.Participant?.SepaSDD) ||
        target2Data?.target2)
    ) {
      currentValues.Payment.PaymentSystem = PAYMENT_SYSTEMS.TARGET2;
    } else {
      // currentValues.Payment.PaymentSystem = PAYMENT_SYSTEMS.SEPA
      currentValues.Payment.PaymentType = PaymentType.SEPA;

      delete currentValues.Payment.BeneficiaryCountryCode;
      delete currentValues.Payment.BeneficiaryCity;
      delete currentValues.Payment.BeneficiaryAddress;
      delete currentValues.Payment.BeneficiaryPostCode;
    }
  };

  const edit = () => {
    setErrors(null);
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleForexSubmitPreview = async () => {
    const response = await dispatch(AMCActions.confirmForexRate(forexDraftInfo.Id));
    if (response?.payload?.Success) {
      setCurrentStep((prevStep) => prevStep + 1);
      Notification({
        type: 'success',
        message: i18.Messages.ForexExchangeIsSuccessfull,
      });
    }
  };

  const submit = async (values, actions) => {
    setErrors(null);
    setSubmitting(true);
    setData(values);

    const currentValues = {
      ...values,
      Payment: {
        ...values.Payment,
        IsPurposeSelected: values.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? true : values.Payment.IsPurposeSelected,
        Purpose: values.Payment.IsPurposeSelected || values.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? values.Payment.Purpose : '',
        ReferenceNo: values.Payment.IsPurposeSelected || values.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? '' : values.Payment.Purpose,
        AmountStr: values?.Payment?.AmountStr?.toString()?.replace('.', ',') ?? values?.Payment.Amount?.toString()?.replace('.', ',') ?? '',
        PaymentType: values.Payment.PaymentType ? values.Payment.PaymentType.toString() : null,
        Amount: Number(values?.Payment?.AmountStr?.toString()?.replace(',', '.') ?? values?.Payment.Amount) ?? 0,
        CustomersCode: values?.Payment?.CustomersCode?.trim(),
      },
    };

    delete currentValues.Payment.CheckBeneficiaryAccountNumber;
    delete currentValues.Payment.AmountAvailable;

    if (paymentType === Payment.NEW && !currentValues.Payment.IsAdditionalDataSelected) {
      delete currentValues.Payment.PayerType;
      delete currentValues.Payment.PayerName;
      delete currentValues.Payment.PayerIdentTypeValue;
      delete currentValues.Payment.PayerIdentTypeCode;
      delete currentValues.Payment.PayerAgreementIdentTypeCode;
      delete currentValues.Payment.PayerAddress;
      delete currentValues.Payment.InitPayerType;
      delete currentValues.Payment.InitPayerName;
      delete currentValues.Payment.InitPayerIdentTypeCode;
      delete currentValues.Payment.InitPayerIdentTypeValue;
      delete currentValues.Payment.PayeeType;
      delete currentValues.Payment.PayeeIdentTypeCode;
      delete currentValues.Payment.PayeeIdentTypeValue;
      delete currentValues.Payment.PayeeAddress;
      delete currentValues.Payment.FinalPayeeType;
      delete currentValues.Payment.FinalPayeeName;
      delete currentValues.Payment.FinalPayeeIdentTypeCode;
      delete currentValues.Payment.FinalPayeeIdentTypeValue;
      delete currentValues.Payment.SEPAPaymentType;
      delete currentValues.FullTextSearch;
      delete currentValues.Payment.fromHistory;
    }

    if (paymentType === Payment.NEW && !currentValues.Payment.SaveTemplate) {
      delete currentValues.Payment.TemplateName;
    }

    AddRemoveNIUMPaymentValues(currentValues);
    AddRemoveTarget2PaymentValues(currentValues);

    if (currentValues.Payment.PayerAddress && currentValues.Payment.PayerAddress !== '') {
      currentValues.Payment.PayerAddressChk = 'true';
    }
    if (currentValues.Payment.PayeeAddress && currentValues.Payment.PayeeAddress !== '') {
      currentValues.Payment.PayeeAddressChk = 'true';
    }

    try {
      const response = await dispatch(PMCActions.postPVR(currentValues));
      setSubmitting(false);
      setSubmittingSave(false);

      if (response?.payload?.IsSuccessfull) {
        setCurrentStep((prevStep) => prevStep + 1);
      } else {
        const errs = response?.payload?.ErrorCaptions;
        const globalErrors = [];
        const errorFields = {};
        const fields = {
          PMC00005: 'IsPurposeSelected',
          PMC00008: 'TemplateName',
          PMC00010: 'PayerIdentTypeValue',
          PMC00011: 'PayerAddress',
          PMC00013: 'PayeeIdentTypeValue',
          PMC00014: 'PayeeAddress',
          PMC00015: 'CustomersCode',
          PMC00016: 'Purpose',
          PMC00017: 'SEPAPaymentType',
          PMC00003: 'BeneficiaryAccountNumber',
        };
        for (let i in errs) {
          const item = errs[i];
          if (checkJsonExist(fields, item.ErrorCode)) {
            errorFields[fields?.item?.ErrorCode] = trb(translationGroups.ErrorCode + item.ErrorCode);
          } else {
            globalErrors.push(item);
          }
        }
        if (currentValues?.Payment?.PaymentSystem === PAYMENT_SYSTEMS.INST && response?.payload?.ErrorCode === PAYMENT_ERRORS.CHANGE_PAYMENT_SYSTEM) {
          currentValues.Payment.PaymentSystem = PAYMENT_SYSTEMS.SEPA;
          actions?.setFieldValue?.('Payment.PaymentSystem', PAYMENT_SYSTEMS.SEPA);
          globalErrors.push({ ErrorCode: PAYMENT_ERRORS.SEPA_INST });
        }

        setErrors(globalErrors);

        return {
          Payment: {
            ...errorFields,
          },
        };
      }
    } catch (error) {
      logError(error);
    }
  };

  const submitPreview = async () => {
    setErrors(null);
    setSubmitting(true);
    setWithError(false);

    if (data.Payment.PaymentType === 'forex-exchange-form') {
      handleForexSubmitPreview();
      return;
    }

    const currentValues = {
      ...data,
      Payment: {
        ...data.Payment,
        IsPurposeSelected: data.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? true : data.Payment.IsPurposeSelected,
        Purpose: data.Payment.IsPurposeSelected || data.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? data.Payment.Purpose : '',
        ReferenceNo: data.Payment.IsPurposeSelected || data.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? '' : data.Payment.Purpose,
        AmountStr: data?.Payment?.AmountStr?.toString()?.replace('.', ',') ?? data?.Payment.Amount?.toString()?.replace('.', ',') ?? '',
        PaymentType: data.Payment.PaymentType ? data.Payment.PaymentType.toString() : null,
        Amount: Number(data?.Payment?.AmountStr?.toString()?.replace(',', '.') ?? data?.Payment.Amount) ?? 0,
        CustomersCode: data?.Payment?.CustomersCode?.trim(),
      },
    };

    if (paymentType === Payment.NEW && !currentValues.Payment.IsAdditionalDataSelected) {
      delete currentValues.Payment.PayerType;
      delete currentValues.Payment.PayerName;
      delete currentValues.Payment.PayerIdentTypeValue;
      delete currentValues.Payment.PayerIdentTypeCode;
      delete currentValues.Payment.PayerAgreementIdentTypeCode;
      delete currentValues.Payment.PayerAddress;
      delete currentValues.Payment.InitPayerType;
      delete currentValues.Payment.InitPayerName;
      delete currentValues.Payment.InitPayerIdentTypeCode;
      delete currentValues.Payment.InitPayerIdentTypeValue;
      delete currentValues.Payment.PayeeType;
      delete currentValues.Payment.PayeeIdentTypeCode;
      delete currentValues.Payment.PayeeIdentTypeValue;
      delete currentValues.Payment.PayeeAddress;
      delete currentValues.Payment.FinalPayeeType;
      delete currentValues.Payment.FinalPayeeName;
      delete currentValues.Payment.FinalPayeeIdentTypeCode;
      delete currentValues.Payment.FinalPayeeIdentTypeValue;
      delete currentValues.Payment.SEPAPaymentType;
    }

    AddRemoveNIUMPaymentValues(currentValues);
    AddRemoveTarget2PaymentValues(currentValues);

    if (currentValues.Payment.PayerAddress && currentValues.Payment.PayerAddress !== '') {
      currentValues.Payment.PayerAddressChk = 'true';
    }
    if (currentValues.Payment.PayeeAddress && currentValues.Payment.PayeeAddress !== '') {
      currentValues.Payment.PayeeAddressChk = 'true';
    }

    const response = await dispatch(PMCActions.postAPR({ ...currentValues, Language: language }));

    console.log('response ', response);

    if (response?.payload?.IsSuccessfull) {
      if (response.payload.RQ_ID !== '') {
        setApprove(true);
        setRQID(response.payload.RQ_ID);
        setAuthType(response.payload.AuthType);
        setCode(response.payload.ControlCode);
      } else {
        setCurrentStep((prevStep) => prevStep + 1);
      }
    } else {
      if (response?.payload?.ErrorCaptions?.find((item) => item.ErrorCaption === 'loginError.6002')) {
        cancelApprove(SIGN_CANCEL_TYPES.REJECTED, null, 'warning');
      } else {
        setErrors(response?.payload?.PaymentErrors?.filter((items, index) => index === response?.payload?.PaymentErrors.findIndex((item) => item.ErrorCode === items.ErrorCode)));
      }
    }

    setSubmitting(false);
    setSubmittingSave(false);

    // TODO - check if this is needed
    /*
    if (response?.payload?.RQ_ID === '') {
      await Sleep(1000);
      await dispatch(PMCActions.postPTR());
    }
    */
  };

  const cancelApprove = (type, errorCode, notificationType = 'warning') => {
    setWithError(errorCode);
    setSubmitting(false);
    setSubmittingSave(false);
    setApprove(false);
    setRQID(null);
    setAuthType(null);
    setCode(null);

    let message;

    switch (type) {
      case SIGN_CANCEL_TYPES.TIMEOUT:
        message = 'PaymentCanceledTimeout';
        break;
      case SIGN_CANCEL_TYPES.MANUAL:
        message = 'PaymentCanceledManual';
        break;
      case SIGN_CANCEL_TYPES.REJECTED:
        message = 'PaymentCanceledRejected';
        break;
      case SIGN_CANCEL_TYPES.ERROR:
        message = errorCode?.replace('.', '');
        setCurrentStep((prevStep) => prevStep + 1);
        break;
      default:
        message = errorCode?.replace('.', '');
        break;
    }

    console.log('message', message);

    if (notificationType) {
      Notification({
        type: notificationType,
        // @ts-ignore
        message: message ? i18.NotificationTitles[message] : i18.NotificationTitles.Canceled,
        // @ts-ignore
        description: message ? i18.NotificationMessages[message] : i18.NotificationMessages.Canceled,
      });
    }
  };

  const successApprove = async () => {
    setApprove(false);
    setCurrentStep((prevStep) => prevStep + 1);
    setWithError(false);
  };

  const waitingApprove = async () => {
    setApprove(false);
    setWithError('waiting');
    setCurrentStep((prevStep) => prevStep + 1);
    Notification({
      type: 'info',
      message: i18.NotificationTitles.NeedCheckLater,
      description: i18.NotificationMessages.NeedCheckLater,
    });
  };

  const BadApproveCode = () => {
    setSubmitting(false);
    setApprove(false);
    setRQID(null);
    setAuthType(null);
    setCode(null);
    Notification({
      type: 'error',
      message: i18.NotificationTitles.BadApproveCode,
      description: i18.NotificationMessages.BadApproveCode,
    });
  };

  const download = () => {
    console.log('download');
  };

  const resetPayment = async (clearState) => {
    setData(null);
    dispatch(PMCActions.postPTR());
    setSubmitting(false);
    setSubmittingSave(false);
    setCurrentStep(0);

    setApprove(false);
    setErrors(null);
    setSaveSign(false);
    localStorage.removeItem('SavedIBAN');
    localStorage.removeItem('SavedCurrency');
    await dispatch(PMCActions.clearCheckIBANMember());
    await dispatch(PMCActions.clearHistoricalPayments());
    await dispatch(PMCActions.clearPVR());

    navigate(location.pathname, {
      state: {
        templateId: clearState ? null : location?.state?.templateId,
      },
    });
  };

  const paymentSaveToSign = () => {
    setErrors(null);
    setSubmittingSave(true);

    const amount = data.Payment.AmountStr ?? data.Payment.Amount;
    const currentValues = {
      ...data,
      Payment: {
        ...data.Payment,
        FinalPayeeAddress: '',
        FinalPayeeAddressChk: 'false',
        FinalPayeeCountryCode: '',
        AmountStr: amount.toString().replace('.', ','),
        Amount: Number(amount.toString().replace(',', '.')),
      },
      feePayment: {
        ...data.feePayment,
        feeValues: data.feePaymentData,
      },
    };

    if (paymentType === Payment.NEW && !currentValues.Payment.IsAdditionalDataSelected) {
      AddRemoveTarget2PaymentValues(currentValues);

      delete currentValues.Payment.PayerType;
      delete currentValues.Payment.PayerName;
      delete currentValues.Payment.PayerIdentTypeValue;
      delete currentValues.Payment.PayerIdentTypeCode;
      delete currentValues.Payment.PayerAgreementIdentTypeCode;
      delete currentValues.Payment.PayerAddress;
      delete currentValues.Payment.InitPayerType;
      delete currentValues.Payment.InitPayerName;
      delete currentValues.Payment.InitPayerIdentTypeCode;
      delete currentValues.Payment.InitPayerIdentTypeValue;
      delete currentValues.Payment.PayeeType;
      delete currentValues.Payment.PayeeIdentTypeCode;
      delete currentValues.Payment.PayeeIdentTypeValue;
      delete currentValues.Payment.PayeeAddress;
      delete currentValues.Payment.FinalPayeeType;
      delete currentValues.Payment.FinalPayeeName;
      delete currentValues.Payment.FinalPayeeIdentTypeCode;
      delete currentValues.Payment.FinalPayeeIdentTypeValue;
      delete currentValues.Payment.SEPAPaymentType;
    }

    if (currentValues.Payment.PayerAddress && currentValues.Payment.PayerAddress !== '') {
      currentValues.Payment.PayerAddressChk = 'true';
    }

    if (currentValues.Payment.PayeeAddress && currentValues.Payment.PayeeAddress !== '') {
      currentValues.Payment.PayeeAddressChk = 'true';
    }

    dispatch(PMCActions.postSPR(currentValues)).then((response) => {
      if (response.payload.IsSuccessfull) {
        setSubmitting(false);
        setSubmittingSave(false);
        setSaveSign(true);
      } else {
        setErrors(response?.payload?.ErrorCaptions || null);
        setSubmitting(false);
        setSubmittingSave(false);
      }
    });
  };

  const submitSmsApprove = (values) => {
    dispatch(PMCActions.postAPSMSR(values)).then((response) => {
      if (response?.payload) {
        const { IsSuccessfull, ErrorCode } = response.payload;

        if (IsSuccessfull === true) {
          if (ErrorCode) {
            cancelApprove(null, ErrorCode, 'error');
          } else {
            successApprove();
          }
        } else if (IsSuccessfull === false && ErrorCode && ErrorCode.toLowerCase() === 'waiting_to_confirm') {
          waitingApprove();
        } else {
          cancelApprove(SIGN_CANCEL_TYPES.ERROR, ErrorCode, null);
        }
      }
    });
  };

  const submitMobileApprove = async () => {
    try {
      const response = await dispatch(PMCActions.postAPESCR({ RQ_ID: RQ_ID }));

      if (response?.payload) {
        const { IsSuccessfull, ErrorCode, AuthCheckResult } = response.payload;

        if (AuthCheckResult === null) {
          return true;
        }

        if (AuthCheckResult === false) {
          if (IsSuccessfull === false && ErrorCode && ErrorCode.toLowerCase() == 'waiting_to_confirm') {
            waitingApprove();
          } else if (ErrorCode) {
            if (['loginError.canceled'].includes(ErrorCode)) {
              cancelApprove(SIGN_CANCEL_TYPES.REJECTED, ErrorCode, 'warning');
            } else {
              cancelApprove(SIGN_CANCEL_TYPES.ERROR, ErrorCode, null);
            }
          } else {
            BadApproveCode();
          }
        } else if (AuthCheckResult === true && IsSuccessfull) {
          if (ErrorCode) {
            cancelApprove(null, ErrorCode);
          } else {
            successApprove();
          }
        }
      } else {
        throwError(response, 'submitMobileApprove');
      }
      return false;
    } catch (error) {
      logError(error);
      return false;
    }
  };

  if ((sendPTR && currentStep !== 2) || sendFeeList || sendMALR || sendPayPersonTypes) {
    return <ContentLoader />;
  }

  if ((!PTR && currentStep !== 2) || !MALR) {
    return <ContentNoData id="PaymentNewPTRMALR" />;
  }

  if (paymentType === Payment.FEE && !feeList) {
    return <ContentNoData id="PaymentNewFeeList" />;
  }

  const getFormatedForexDrafrInfo = () => {
    const dt = { ...forexDraftInfo };
    if (dt.accountFromId) {
      dt.IBANAccountFrom = forexPayments?.InitialAccounts?.find((el) => el.AccountID === dt.accountFromId)?.AccountNumber;
    }
    if (dt.accountToId) {
      dt.IBANAccountTo = forexPayments?.DestinationAccounts?.find((el) => el.AccountID === dt.accountToId)?.AccountNumber;
    }
    return dt;
  };

  return (
    <>
      <div className="eb_content_subtitle">{trb(i18.ContentTitles.NewPayment)}</div>
      <Container
        wide
        gradient={currentStep === 2}
      >
        <Steps
          direction="horizontal"
          current={currentStep}
          className="mb-3 mb-md-2 eb_steps"
        >
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentFirstStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentSecondStep"
              />
            }
          />
          <Steps.Step
            icon={
              <CustomIcon
                size={50}
                type="custom"
                icon="paymentThirdStep"
              />
            }
          />
        </Steps>
        <div className="position-relative w-100">
          {currentStep === 0 && (
            <div>
              {sendGetFxRate && <ContentLoader hover />}
              <ViewPaymentsNew
                data={data}
                submit={submit}
                errorsPVR={errors}
              />
            </div>
          )}
          {currentStep === 1 && (
            <PaymentPreview
              paymentType={Payment.NEW}
              data={data}
              PTR={PTR}
              PVR={PVR}
              PayPersonTypes={PayPersonTypes}
              edit={edit}
              submit={submitting ? () => {} : submitPreview}
              submitting={submitting}
              submittingSave={submittingSave}
              approve={approve}
              AuthType={AuthType}
              RQ_ID={RQ_ID}
              Code={Code}
              cancelApprove={cancelApprove}
              successApprove={successApprove}
              submitSmsApprove={submitSmsApprove}
              submitMobileApprove={submitMobileApprove}
              paymentSaveToSign={paymentSaveToSign}
              errors={errors}
              saveSign={saveSign}
              resetPayment={resetPayment}
              forexDraftInfo={getFormatedForexDrafrInfo()}
            />
          )}
          {currentStep === 2 && (
            <>
              {withError && (
                <>
                  {withError === 'waiting' && <PaymentWaiting resetPayment={resetPayment} />}
                  {withError !== 'waiting' && (
                    <PaymentUnsuccess
                      resetPayment={resetPayment}
                      error={withError}
                    />
                  )}
                </>
              )}
              {!withError && (
                <PaymentPreview
                  paymentType={Payment.NEW}
                  data={data}
                  PTR={PTR}
                  PVR={PVR}
                  PayPersonTypes={PayPersonTypes}
                  edit={edit}
                  submit={submitting ? () => {} : submitPreview}
                  submitting={submitting}
                  success
                  download={download}
                  resetPayment={resetPayment}
                  forexDraftInfo={getFormatedForexDrafrInfo()}
                />
              )}
            </>
          )}
        </div>
      </Container>
    </>
  );
};
