import { useState } from 'react';
import { FloatButton } from 'antd';
import { FloatButtonType } from 'antd/es/float-button/interface';
import { IconArrowUp, IconClose, IconMessage } from 'src/Assets/svg';
import { Contacts } from './Contacts';

export const FloatingButtons = () => {
  const [messageButtonActive, setMessageButtonActive] = useState(false);

  return (
    <>
      <FloatButton.BackTop
        className="back-to-top-button"
        type="default"
        icon={<IconArrowUp />}
      />

      <FloatButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id="ebBannersContactsButton"
        type="primary"
        icon={messageButtonActive ? <IconClose /> : <IconMessage />}
        onClick={() => setMessageButtonActive((prev) => !prev)}
        className={messageButtonActive ? 'close-button' : 'message-button'}
      />

      {messageButtonActive && (
        <FloatButton
          type={'custom' as FloatButtonType}
          description={<Contacts />}
          shape="square"
          className="contacts"
        />
      )}
    </>
  );
};
