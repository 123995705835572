import { useEffect, useState } from 'react';
import { BeneficiariesNewForm, BeneficiariesNewSign, BeneficiariesNewHeader } from './components';
import { Steps } from 'antd';
import { CustomIcon } from 'src/Utilities';
import { useSelector } from 'react-redux';

export const BeneficiariesNew = () => {
  const { signMandateInfo } = useSelector((state: any) => state.BeneficiariesReducers);
  const [authType, setAuthType] = useState<string>('');
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState({ CreditAccount: '', CreditorTitle: '' });

  useEffect(() => {
    if (signMandateInfo?.IsSuccess) {
      setAuthType(signMandateInfo?.AuthType);
      setCurrentStep(1);
    }
  }, [signMandateInfo]);

  return (
    <>
      <Steps
        direction="horizontal"
        current={currentStep}
        className={`mb-3 eb_steps pt-3`}
        items={[
          {
            icon: (
              <div>
                <CustomIcon
                  size={40}
                  type="custom"
                  icon="depositFirstStep"
                />
              </div>
            ),
          },
          {
            icon: (
              <div>
                <CustomIcon
                  size={40}
                  type="custom"
                  icon="depositSecondStep"
                />
              </div>
            ),
          },
        ]}
      />

      {currentStep === 0 && (
        <>
          <BeneficiariesNewHeader />
          <BeneficiariesNewForm
            initialValues={formValues}
            onSubmit={setFormValues}
          />
        </>
      )}
      {currentStep === 1 && (
        <BeneficiariesNewSign
          onCancel={() => setCurrentStep(0)}
          authType={authType}
        />
      )}
    </>
  );
};
