import React from 'react';
import {FormPreviewSwitch} from "../../../Components/Forms";

export const DepositsPreview = (props) => {

    return <FormPreviewSwitch
        name="deposit"
        data={props.data}
        buttons={props.buttons}
    />;
};