import { Checkbox } from 'antd';
import { IBeneficiariesItem } from 'src/Redux';
import { i18, ibanFormat, trb } from 'src/Utilities';

export const BeneficiariesListTable = ({
  selected = [],
  onSelectedChange,
  isAdmin = false,
  list,
}: {
  selected: string[];
  onSelectedChange: (value: string[]) => void;
  isAdmin: boolean;
  list: IBeneficiariesItem[];
}) => {
  const checkAllRow = () => {
    if (list?.length === selected.length) {
      onSelectedChange([]);
    } else {
      onSelectedChange(list?.map(({ IdGuid }: { IdGuid: string }) => IdGuid));
    }
  };

  const toggleRow = (IdGuid: string) => {
    if (selected.indexOf(IdGuid) === -1) {
      onSelectedChange([...selected, IdGuid]);
    } else {
      onSelectedChange(selected.filter((guid) => guid !== IdGuid));
    }
  };

  const sortByDate = (itemA: IBeneficiariesItem, itemB: IBeneficiariesItem) => {
    const dateCreatedA = new Date(itemA.DateCreated);
    const dateCreatedB = new Date(itemB.DateCreated);

    if (dateCreatedA > dateCreatedB) {
      return -1;
    } else if (dateCreatedA == dateCreatedB) {
      return 0;
    } else {
      return 1;
    }
  };

  return (
    <>
      <div className="eb_table px-3 pb-0 eb_table_wrapper_bottom_lined">
        <div className="row eb_table_head">
          <div className="col-6">
            {isAdmin && (
              <Checkbox
                id="BeneficiariesCheckboxAll"
                className="mr-2"
                onChange={checkAllRow}
                checked={selected?.length === list?.length && !!list?.length}
              />
            )}
            <span style={{ cursor: 'default' }}>{trb(i18.Labels.CreditorTitle)}</span>
          </div>
          <div className="col-6 d-flex justify-content-start">{trb(i18.Labels.CreditorAccountNumber)}</div>
        </div>

        {list.sort(sortByDate).map((beneficiariesListItem: IBeneficiariesItem, index: number) => (
          <div
            className="row eb_table_row"
            key={index}
          >
            <div className="col-6 eb_fw_400 text-md-left text-black-70">
              {isAdmin && (
                <span>
                  <Checkbox
                    id={`BeneficiariesCheckbox${index}`}
                    className="mr-2"
                    onChange={() => toggleRow(beneficiariesListItem.IdGuid)}
                    checked={selected?.indexOf(beneficiariesListItem.IdGuid) !== -1}
                  />
                </span>
              )}
              <span>{beneficiariesListItem.CreditorTitle}</span>
            </div>
            <div className="col-6 eb_fw_400 text-md-left text-black-70">{ibanFormat(beneficiariesListItem.CreditorAccountNumber)}</div>
          </div>
        ))}
      </div>
    </>
  );
};

