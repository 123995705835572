import React from 'react';
import {Steps} from "antd";

import {i18, trb} from '../../../../Utilities';

import {
    KYCMainInformacion,
    KYCPersonDocuments,
    KYCContactInformacion,
    KYCTaxInformation,
    KYCIncome,
    KYCPepLinks,
    KYCOtherInformation
} from './Components'

const Step = Steps.Step;

export const SettingsKYCRenewPerson = (props) => {
    const CurrentStep = props.currentStep;

    return <div className="eb_kyc_content">
        <div className="eb_content_title">{trb(i18.ContentTitles.KYCRenew)}</div>
        <Steps size="small" current={CurrentStep}
               className="mb-5 d-none d-md-flex">
            <Step title={CurrentStep === 0 ? trb(i18.Steps.MainInformacion) : ''}
                  description={CurrentStep === 0 ? trb(i18.Steps.MainInformacionDescription) : ''}/>
            <Step title={CurrentStep === 1 ? trb(i18.Steps.PersonDocuments) : ''}
                  description={CurrentStep === 1 ? trb(i18.Steps.PersonDocumentsDescription) : ''}/>
            <Step title={CurrentStep === 2 ? trb(i18.Steps.ContactInformacion) : ''}
                  description={CurrentStep === 2 ? trb(i18.Steps.ContactInformacionDescription) : ''}/>
            <Step title={CurrentStep === 3 ? trb(i18.Steps.TaxInformation) : ''}
                  description={CurrentStep === 3 ? trb(i18.Steps.TaxInformationDescription) : ''}/>
            <Step title={CurrentStep === 4 ? trb(i18.Steps.Income) : ''}
                  description={CurrentStep === 4 ? trb(i18.Steps.IncomeDescription) : ''}/>
            <Step title={CurrentStep === 5 ? trb(i18.Steps.PepLinks) : ''}
                  description={CurrentStep === 5 ? trb(i18.Steps.PepLinksDescription) : ''}/>
            <Step title={CurrentStep === 6 ? trb(i18.Steps.OtherInformation) : ''}
                  description={CurrentStep === 6 ? trb(i18.Steps.OtherInformationDescription) : ''}/>
        </Steps>
        <div className="eb_kyc_container">
            {CurrentStep === 0 &&
            <KYCMainInformacion permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep}/>
            }
            {CurrentStep === 1 &&
            <KYCPersonDocuments permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>
            }
            {CurrentStep === 2 &&
            <KYCContactInformacion permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>
            }
            {CurrentStep === 3 &&
            <KYCTaxInformation permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>
            }
            {CurrentStep === 4 &&
            <KYCIncome permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>
            }
            {CurrentStep === 5 &&
            <KYCPepLinks permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>
            }
            {CurrentStep === 6 &&
            <KYCOtherInformation permissons={props.permissons} classifikators={props.classifikators} data={props.data} submit={props.submit} prevStep={props.prevStep}/>
            }
        </div>
    </div>
  }