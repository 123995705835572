import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { BeneficiariesActions, BeneficiariesConstants } from 'src/Redux';
import { i18, trb } from 'src/Utilities';
import { ContentLoader } from 'src/Components';

export const BeneficiariesDeleteCheckStatus = ({ productId, onFinished }: { productId: string; onFinished: () => void }) => {
  const dispatch = useDispatch();
  const timerRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    checkStatus();

    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const checkStatus = async () => {
    const resp = await dispatch(BeneficiariesActions.getCheckDeleteStatusMandate(productId) as any);

    if (resp.type === BeneficiariesConstants.GET_CHECK_DELETE_STATUS_SUCCESS) {
      dispatch(BeneficiariesActions.setBeneficiariesList(null, null, true) as any);
      dispatch(BeneficiariesActions.setSignDeleteMandateInfo(null) as any);

      onFinished();
    } else if (!resp.error) {
      timerRef.current = setTimeout(() => {
        checkStatus();
      }, 1000);
    }
  };

  return <ContentLoader title={trb(i18.Labels.CheckBeneficiariesDeleteSignStatus)} />;
};

