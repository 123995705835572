import React from 'react';
import PropTypes from 'prop-types';

import { checkJsonProp, i18, trb } from '../../Utilities';

export const PrintHeader = (props) => {
  const logoClass = import.meta.env.VITE_LOGO.split('.')[1];

  return (
    <table className="eb_print_header">
      <tbody>
        <tr>
          <td className={`eb_print_header_icon ${logoClass}`}>
            <div>
              <img
                src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGO}`}
                alt={import.meta.env.VITE_TITLE}
              />
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <table className="eb_print_header_inner">
              <tbody>
                <tr>
                  <td colSpan={2}>
                    <b>{checkJsonProp(props.COMPANY, 'OrgName')}</b>
                  </td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.CompanyCode)}</th>
                  <td>{checkJsonProp(props.COMPANY, 'OrgCode')}</td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.CompanyAddress)}</th>
                  <td>{checkJsonProp(props.COMPANY, 'OrgAddress')}</td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.VatCode)}</th>
                  <td>{checkJsonProp(props.COMPANY, 'VATCode')}</td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.CompanyEmail)}</th>
                  <td>{checkJsonProp(props.COMPANY, 'OrgEMail')}</td>
                </tr>
              </tbody>
            </table>
          </td>
          <td>
            <table className="eb_print_header_inner">
              <tbody>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.FormattedDateAndTime)}</th>
                  <td>{checkJsonProp(props, 'data.PrintDate')}</td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.ClientIBAN)}</th>
                  <td className="text-nowrap">{checkJsonProp(props, 'data.AccountWithDetails.AccountNumber')}</td>
                </tr>
                <tr>
                  <th>{trb(i18.PrintLabels.PrintInterval)}</th>
                  <td className="text-nowrap">{checkJsonProp(props, 'data.PrintInterval')}</td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.ClientTitle)}</th>
                  <td>{props.data.ClientName}</td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.ClientCode)}</th>
                  <td>{props.data.ClientCode}</td>
                </tr>
                <tr>
                  <th className="text-left">{trb(i18.PrintLabels.ClientAddress)}</th>
                  <td>{props.data.ClientAddress}</td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

PrintHeader.propTypes = {
  data: PropTypes.object.isRequired,
};
