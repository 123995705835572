import { i18, trb } from '../../../../../Utilities';
import { FormField, Input } from 'src/shared';

export const FieldCorrespondentBankBIC = () => {
  return (
    <FormField
      name="Payment.CorrespondentBankBic"
      label={trb(i18.Labels.CorrespondentBankBic)}
    >
      <Input />
    </FormField>
  );
};
