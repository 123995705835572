export const IconEmailStroked = ({ size = 24, color = '#4D4D4D' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6438 20.3431L20.0998 5.62308C20.3968 4.82108 19.6158 4.04108 18.8148 4.33808L4.0898 9.79808C3.1688 10.1401 3.2408 11.4661 4.1938 11.7051L11.0278 13.4221L12.7348 20.2381C12.9748 21.1921 14.3018 21.2651 14.6438 20.3431V20.3431Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.8698 4.56506L11.0298 13.4251"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
