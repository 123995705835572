import { useFormikContext } from 'formik';
import React from 'react';

const getFieldErrorNames = (formikErrors: object) => {
  const transformObjectToDotNotation = (obj: any, prefix = '', result: string[] = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];

      if (!value) {
        return;
      }

      const nextKey = prefix ? `${prefix}.${key}` : key;

      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

export const ScrollToFieldError = () => {
  const { submitCount, isValid, errors } = useFormikContext();

  React.useEffect(() => {
    if (isValid) {
      return;
    }

    const fieldErrorNames = getFieldErrorNames(errors);

    if (fieldErrorNames.length <= 0) {
      return;
    }

    const element = document.getElementById(fieldErrorNames[0]?.replace('.error', ''));

    if (!element) {
      return;
    }

    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [submitCount]);

  return null;
};
