import {KYCConstants} from './constants';

const initialState = {}

export const KYCReducers = function (state = initialState, action) {
    switch (action.type) {
        case KYCConstants.GET_KYC_REQUEST:
        case KYCConstants.POST_KYC_REQUEST:
            return {
                ...state,
                send: true,
                sendKYC: true,
                KYC: null,
            };
        case KYCConstants.GET_KYC_SUCCESS:
        case KYCConstants.POST_KYC_SUCCESS:
            return {
                ...state,
                send: false,
                sendKYC: false,
                KYC: action.payload,
            };
        case KYCConstants.GET_KYC_ERROR:
        case KYCConstants.POST_KYC_ERROR:
            return {
                ...state,
                send: false,
                sendKYC: false,
                errors: action.error
            };
        default:
            return state;
    }
};
