import { Typography } from 'antd';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export const Amount = ({ typography, suffix, ...restProps }) => {
    return (
        <Typography.Text
            strong={true}
            {...typography}
        >
            <NumericFormat
                decimalScale={2}
                fixedDecimalScale
                {...restProps}
                displayType="text"
                decimalSeparator=","
                thousandSeparator=" "
                suffix={suffix ? ' ' + suffix : undefined}
            />
        </Typography.Text>
    );
};
