import { IconRedirect } from 'src/Assets/svg';
import { externalPath } from 'src/Config';
import { i18, trb } from 'src/Utilities';
import { Typography } from 'src/shared';

const { Text } = Typography;

export const TermsOfService = () => {
  const handleTermsOfServiceClick = () => {
    const language = localStorage.getItem('language');

    window.open(externalPath[language === 'en-GB' ? 'smeBankGeneralAgreementsEn' : 'smeBankGeneralAgreements']);
  };

  return (
    <Text
      className="cursor-pointer login_footer_text"
      onClick={handleTermsOfServiceClick}
      id="termsOfServiceButton"
    >
      {trb(i18.Labels.TermsOfService)}
      <span className="ml-1">
        <IconRedirect size={20} />
      </span>
    </Text>
  );
};
