import { OMCServices } from './services';
import { OMCConstants } from './constants';

export const OMCActions = {
  postROL,
  postODL,
  postODLPaging,
  postUOLR,
  postDUOR,
  postPSUOR,
  postSUORSMS,
  postSUOR,
  resetPSUOR,
  removeUOLRCompleted,
};

function postROL() {
  return (dispatch) => {
    dispatch(request());

    return OMCServices.postROL().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_ROL_REQUEST };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_ROL_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_ROL_ERROR, error };
  }
}

function postODL(data) {
  const json = {
    Days: data.Days,
  };
  return (dispatch) => {
    dispatch(request());

    return OMCServices.postODL(json).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_ODL_REQUEST };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_ODL_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_ODL_ERROR, error };
  }
}

function postODLPaging(data) {
  return (dispatch) => {
    dispatch(request(data));

    return OMCServices.postODLPaging(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_ODL_PAGING_REQUEST, payload: data };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_ODL_PAGING_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_ODL_PAGING_ERROR, error };
  }
}

function postUOLR(data) {
  return (dispatch) => {
    dispatch(request());

    return OMCServices.postUOLR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_UOLR_REQUEST };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_UOLR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_UOLR_ERROR, error };
  }
}

function postDUOR(data) {
  return (dispatch) => {
    dispatch(request());

    return OMCServices.postDUOR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_DUOR_REQUEST };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_DUOR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_DUOR_ERROR, error };
  }
}

function postPSUOR(data) {
  return (dispatch) => {
    dispatch(request());

    return OMCServices.postPSUOR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_PSUOR_REQUEST };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_PSUOR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_PSUOR_ERROR, error };
  }
}

function postSUORSMS(data) {
  return (dispatch) => {
    dispatch(request());

    return OMCServices.postSUORSMS(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_SUORSMS_REQUEST };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_SUORSMS_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_SUORSMS_ERROR, error };
  }
}

function postSUOR(data) {
  return (dispatch) => {
    dispatch(request());

    return OMCServices.postSUOR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: OMCConstants.POST_OMC_SUOR_REQUEST };
  }

  function success(data) {
    return { type: OMCConstants.POST_OMC_SUOR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: OMCConstants.POST_OMC_SUOR_ERROR, error };
  }
}

function removeUOLRCompleted(ids) {
  return (dispatch) => {
    dispatch(removeUOLRCompleted(ids));
  };

  function removeUOLRCompleted() {
    return {
      type: OMCConstants.REMOVE_OMC_UOLR_COMPLETED,
      payload: ids,
    };
  }
}

function resetPSUOR() {
  return (dispatch) => {
    dispatch({ type: OMCConstants.RESET_PSUOR });
  };
}
