export const PAYER_PAYEE_TYPES = [
  {
    label: 'None',
    value: '',
    type: '',
  },
  {
    label: 'Individual',
    value: 'p',
    type: 'p',
  },
  {
    label: 'Legal',
    value: 'o',
    type: 'b',
  },
];
