import { useSelector } from 'react-redux';

import { FormField, Select } from 'src/shared';
import { i18, trb, translationGroups } from '../../../../../Utilities';

interface IProps {
  name: string;
  type?: string;
  label?: string;
}
export const FieldIdentificationType = ({ name, type, label }: IProps) => {
  const { PayPersonTypes } = useSelector((state: any) => state.PMCReducers);

  return (
    <FormField
      name={name}
      // @ts-ignore
      label={trb(i18.Labels[label ?? 'IdentificationType'])}
    >
      <Select
        options={PayPersonTypes.filter((el: any) => (!type || el.Type === type) && el.Code !== 'OTHR').map((item: any) => ({
          label: trb(translationGroups.IdentificationType + item.Code),
          value: item.Code,
        }))}
      />
    </FormField>
  );
};
