import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Drawer, Table } from 'antd';

import { ContentLoader, ContentNoData } from 'src/Components';
import { checkJsonProp, dateFormat, formatDate, getClassificators, i18, ToJavaScriptDate2, trb } from 'src/Utilities';
import { ChatHeader, ChatMessages } from '../Inbox/Components';
import { MessagingActions } from 'src/Redux';
import { DeleteOutlined } from '@ant-design/icons';

class BroadCastContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { chatOpened: false, reset: false };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(MessagingActions.getBroadCastList());
  }

  convertDate(value) {
    return ToJavaScriptDate2(value, dateFormat().dateTime);
  }

  clickChat = async (e, item) => {
    e.stopPropagation();
    const { dispatch } = this.props;

    await dispatch(MessagingActions.getBroadCastMessage(item.MessageId));
    this.setState({ chatOpened: true });
  };

  deleteMessage = async (e, item) => {
    e.stopPropagation();
    const { dispatch } = this.props;
    await dispatch(MessagingActions.deleteBroadCastMessage(item.MessageId));
    await dispatch(MessagingActions.getBroadCastList());
    await dispatch(MessagingActions.getNewMessagesCount());
    this.setState({ chatOpened: false });
  };

  onClose = () => {
    this.setState({
      chatOpened: false,
    });
    const { dispatch } = this.props;
    dispatch(MessagingActions.getBroadCastList());
    dispatch(MessagingActions.getNewMessagesCount());
  };

  render() {
    const { BroadCastList, sendBroadCastList, BroadCastMessage } = this.props;
    if (sendBroadCastList) {
      return <ContentLoader />;
    }
    if (!BroadCastList || !BroadCastList.BroadCastMessages || BroadCastList.BroadCastMessages.lenght === 0) {
      return <ContentNoData id="BroadCastList" />;
    }

    const MSGL = getClassificators() ? getClassificators().MSGL : {},
      keysMSGL = Object.keys(MSGL),
      checkMSGL = (subject) => (keysMSGL.find((item) => item == subject) !== undefined ? true : false);

    const columns = [
      {
        title: trb(i18.Chat.DateCreated),
        dataIndex: 'DateCreated',
        render: (el) => <span className="text-nowrap">{formatDate(el)}</span>,
        width: 200,
      },
      {
        title: trb(i18.Chat.Subject),
        dataIndex: 'Subject',
        render: (subject) => (checkMSGL(subject) ? MSGL[subject] : subject),
      },
    ];

    const buttonDeleteMessage = [
      {
        title: i18.Buttons.Submit,
        func: this.deleteMessage,
        props: {
          icon: <DeleteOutlined />,
          type: 'primary',
          htmlType: 'submit',
        },
      },
    ];

    return (
      <>
        <Table
          id="BroadCastMessagesTable"
          dataSource={BroadCastList.BroadCastMessages}
          columns={columns}
          showHeader
          pagination={false}
          className="w-100"
          onRow={(item) => {
            return {
              onClick: (e) => {
                this.clickChat(e, item);
              },
            };
          }}
          rowKey="MessageId"
        />
        <Drawer
          width={1000}
          onClose={this.onClose}
          open={this.state.chatOpened}
        >
          <div className="bbs_tab_content_inner" id="BroadCastMessagesDrawer">
            <ChatHeader
              data={BroadCastMessage}
              Subject={checkMSGL(checkJsonProp(BroadCastMessage, 'Subject')) ? MSGL[BroadCastMessage.Subject] : checkJsonProp(BroadCastMessage, 'Subject')}
            />
            <ChatMessages
              data={BroadCastMessage}
              buttons={buttonDeleteMessage}
            />
          </div>
        </Drawer>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { BroadCastList, sendBroadCastList, BroadCastMessage, sendBroadCastMessage } = state.MessagingReducers;
  return {
    BroadCastList,
    sendBroadCastList,
    BroadCastMessage,
    sendBroadCastMessage,
  };
}

const connectedBroadCastContainer = connect(mapStateToProps)(BroadCastContainer);
export { connectedBroadCastContainer as BroadCastContainer };
