import React from 'react';
import { Empty } from 'antd';

export const ContentNoData = ({id}) => {
  return (
    <div id={`${id}NoData`} className="eb_content_no_data">
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </div>
  );
};
