export const IconRedirect = ({ size = 16, color = '#666666' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={(size * 17) / 16}
      height={size}
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M13.07 6.00001V3.33334H10.4033"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.07007 7.33334L13.0701 3.33334"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7367 8.66666V9.99999C13.7367 11.8409 12.2444 13.3333 10.4034 13.3333H6.4034C4.56245 13.3333 3.07007 11.8409 3.07007 9.99999V5.99999C3.07007 4.15904 4.56245 2.66666 6.4034 2.66666H7.73673"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
