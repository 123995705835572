/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';

import { i18, trb } from 'src/Utilities';
import { FormField, FormFieldType } from 'src/Components';
import { AMCActions } from 'src/Redux';

export const UserSelect = () => {
  const dispatch = useDispatch();

  const { managedUsers } = useSelector((state: any) => state.AMCReducers);
  const { MALR } = useSelector((state: any) => state.UACReducers);

  useEffect(() => {
    if (managedUsers && MALR) {
      getClientLimits(MALR.ID);
    }
  }, [managedUsers, MALR]);

  const getClientLimits = (userId: string) => {
    setTimeout(() => {
      const isMyself = MALR.ID === userId;
      dispatch((AMCActions as any).getClientLimits(userId, isMyself));
    }, 200);
  };

  return (
    <Form
      onSubmit={() => {}}
      initialValues={{
        userId: MALR.ID,
      }}
      render={() => (
        <form className="mb-3 w-100">
          <FormField
            name="userId"
            title={trb(i18.Labels.SelectUser)}
            titleWithoutTranslate={true}
            component={FormFieldType.select}
            options={managedUsers}
            optionsLabel="ClientName"
            optionsValue="ClientId"
            helperOnChange={getClientLimits}
          />
        </form>
      )}
    />
  );
};
