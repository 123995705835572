import React from 'react';

import { PMCActions, PMConstants, store } from 'src/Redux';
import { CURRENCIES, checkJsonProp, currenciesWithoutIBANCheck, i18, numberFormat, trb } from './';
import { isValidIBAN } from 'ibantools';
import { Number } from 'src/Components';
import { translationGroups } from 'src/Utilities';
import { IBAN_NOT_VALID, IBAN_TOO_SHORT, REQUIRED, WRONG_IBAN_LENGHT } from './errorsConstants';
import { convertToRaw } from 'draft-js';

const regMail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
const regPhonePrefix = /^\+\d+$/;
const regPhoneNumber = /^\d+$/;
const regAllLettersAndNumbers = /^[\w]*$/;
const regCAPLettersAndNumbers = /^[A-Z0-9]*$/;
const firstTwoNumbers = /^[0-9]{1,2}$/;
export const firstTwoLetters = /^[A-Za-z]{1,2}$/;
export const NAME_VALIDATOR = /^(?=[^ :/-])[A-Za-z0-9 /–?:().,‘+]*[^ :/-]$/;

const { EUR, GBP, NOK, DKK, PLN, SEK, HUF, RON } = CURRENCIES;
const checkAcountNumberNotValid = (value, currency) => {
  const GBPAcountNumberValidation = /^[0-9A-Z]{1,50}$/;
  const EURAcountNumberValidation = /^[A-Z]{2}[0-9]{2}([0-9]|[A-Z])+([0-9A-Z]+){10,31}$/;
  const NOKAcountNumberValidation = /^[0-9]{7}$|^NO[0-9]{2}[0-9]{4}[0-9]{6}[0-9]{1}$/;
  const DKKAcountNumberValidation = /^[0-9]{4,10}$|^DK[0-9]{2}[0-9]{4}[0-9]{10}$/;
  const SEKAcountNumberValidation = /^\\d{1,15}$|^SE[0-9]{2}[0-9]{3}[0-9]{17}$/;
  const PLNHUFRONAcountNumberValidation = /^[A-Za-z0-9]{1,50}$/;

  switch (currency) {
    case GBP:
      return validateRegex(GBPAcountNumberValidation, trb(i18.PaymensValidations.GBPAcountNumberValidationError))(value);
    case EUR:
      return validateRegex(EURAcountNumberValidation, trb(i18.PaymensValidations.EURAcountNumberValidationError))(value);
    case NOK:
      return validateRegex(NOKAcountNumberValidation, trb(i18.PaymensValidations.NOKAcountNumberValidationError))(value);
    case DKK:
      return validateRegex(DKKAcountNumberValidation, trb(i18.PaymensValidations.DKKAcountNumberValidationError))(value);
    case SEK:
      return validateRegex(SEKAcountNumberValidation, trb(i18.PaymensValidations.SEKAcountNumberValidationError))(value);
    case PLN:
    case HUF:
    case RON:
      return validateRegex(PLNHUFRONAcountNumberValidation, trb(i18.PaymensValidations.PLNHUFRONAcountNumberValidationError))(value);
    default:
      return null;
  }
};
export const checkIBAN = (props) => (value, values) => {
  if (values.Payment.Currency && currenciesWithoutIBANCheck.includes(values.Payment.Currency)) {
    return;
  }
  if (!value) return <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldRequired)}</div>;

  const { dispatch, checkIBANResult, sendCheckIBAN, target2Data, checkIBANLength, checkIBANValid } = props,
    errorNode = <div className="bbs_form_error_text text-error">{trb(i18.Validations.IBANNotValid)}</div>;
  value = value.replace(/\s+/g, '');

  const { Currency } = values.Payment;

  /* if (
      checkIBANResult?.IBAN &&
      checkIBANResult.IBAN !== values?.Payment?.BeneficiaryAccountNumber
    ) {
      dispatch(PMCActions.resetIbanMemberValues())
      delete values.Payment.BankName
      delete values.Payment.BeneficiaryCountryCode
      delete values.Payment.BIC
    } */

  const checkSMEIBAN = async () => {
    if (
      value?.length >= 2 &&
      (checkIBANLength?.ibanData?.value || value.slice(0, 2) !== checkIBANLength?.ibanData?.value) &&
      (!checkIBANLength || (checkIBANLength !== 'loading' && checkIBANLength.ibanData?.value !== value.slice(0, 2))) &&
      firstTwoLetters.test(value.slice(0, 2))
    ) {
      dispatch(PMCActions.setIbanValidValue({ ibanValid: false }));

      await dispatch(
        PMCActions.getLengthIBAN({
          iban: `${value.slice(0, 2)}`,
          value: value.slice(0, 2),
        })
      );
    } else if (value?.length >= 2 && firstTwoNumbers.test(value.slice(0, 2))) {
      if (value !== target2Data?.value || !target2Data || !target2Data?.value) {
        dispatch(
          PMCActions.setTarget2Payment({
            currency: EUR,
            target2: true,
            value: value,
          })
        );
        dispatch(PMCActions.setIbanValidValue({ ibanValid: true }));
      }
      return;
    } else if (value?.length < 2 && target2Data) {
      dispatch(PMCActions.setTarget2Payment(null));
    }

    if (value?.length === checkIBANLength?.ibanLenght && (!checkIBANValid || (checkIBANValid !== 'loading' && checkIBANValid.ibanData?.value !== value))) {
      await dispatch(PMCActions.checkIbanValid({ iban: value, value: value }));
    }

    const acountNumberError = checkAcountNumberNotValid(value, Currency);
    if (acountNumberError) {
      return acountNumberError;
    }
  };
  checkSMEIBAN();

  if (Currency === EUR && value?.length >= 2 && firstTwoNumbers.test(value.slice(0, 2)) && value.length > 35) {
    return checkMaxLength(35)(value);
  }

  if (checkIBANValid?.ibanValid !== true) {
    return errorNode;
  }

  const SavedIBAN = localStorage.getItem('SavedIBAN');

  if (isValidIBAN(value)) {
    if (target2Data) {
      dispatch(PMCActions.setTarget2Payment(null));
    }

    let newCheckIBANResult = checkIBANResult;

    if (!sendCheckIBAN && SavedIBAN !== value) {
      localStorage.setItem('SavedIBAN', value);

      dispatch(PMCActions.postCheckIBANMember(value)).then((response) => {
        if (response.type === PMConstants.POST_CHECK_IBAN_MEMBER_SUCCESS) {
          newCheckIBANResult = response.payload;
        } else return errorNode;

        if (checkJsonProp(newCheckIBANResult, 'IsValid')) {
          return undefined;
        } else return errorNode;
      });
    }
    if (checkJsonProp(newCheckIBANResult, 'IsValid')) {
      return undefined;
    } else return errorNode;
  } else {
    if (checkIBANResult) {
      localStorage.removeItem('SavedIBAN');
      dispatch(PMCActions.clearCheckIBANMember());
    }
    if (values.Payment.Currency === EUR && target2Data) {
      if (value !== target2Data?.value || !target2Data) {
        dispatch(
          PMCActions.setTarget2Payment({
            currency: EUR,
            target2: true,
            value: value,
          })
        );
      }
      return undefined;
    }
    //
    return errorNode;
  }
};

export const composeValidatorsJSON = (validators, form, values) => (value) => validators.reduce((error, validator) => error || validator(value, form, values), undefined);
export const composeValidators =
  (...validators) =>
  (value) =>
    validators.reduce((error, validator) => error || validator(value), undefined);
export const required = (value) => (value || !isNaN(value) ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldRequired)}</div>);
export const requiredDraftJSField = (value) => {
  if (value) {
    const { blocks } = convertToRaw(value.getCurrentContent());
    const valueString = blocks?.map((block) => (!block.text.trim() && '\n') || block.text).join('\n');
    return valueString && valueString.replace(/\n/gi, '') ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldRequired)}</div>;
  }
  return undefined;
};
export const requiredAny = (value) => (value ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldsRequired)}</div>);
export const requiredAll = (value) => (value ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.AllFieldsRequired)}</div>);
export const requiredAndApprove = (value) => (value ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldRequiredAndApprove)}</div>);
export const requiredNotUndefined = (value) => (value !== undefined ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldRequired)}</div>);
export const requiredNotNull = (value) => (value !== null ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldRequired)}</div>);
export const requiredApproveCode = (value) => (value ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldRequiredApproveCode)}</div>);

export const checkEmail = (value) => (regMail.test(value) ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldEmail)}</div>);
export const checkPhonePrefix = (value) => (regPhonePrefix.test(value) ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldPhonePrefix)}</div>);
export const checkPhoneNumber = (value) => (regPhoneNumber.test(value) ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldPhoneNumber)}</div>);

export const checkAllLettersAndNumbers = (value) =>
  regAllLettersAndNumbers.test(value) ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldOnlyLettersAndNumbers)}</div>;

export const checkCAPLettersAndNumbers = (value) =>
  regCAPLettersAndNumbers.test(value) ? undefined : <div className="bbs_form_error_text text-error">{trb(i18.Validations.FieldOnlyCAPLettersAndNumbers)}</div>;

export const checkMaxLength = (max) => (value) => {
  if (value) {
    return value.length > max ? (
      <div className="bbs_form_error_text text-error">{trb(i18.Validations.InputIsTooLong) + ':' + trb(i18.Validations.MaxInputIs) + ' ' + max}</div>
    ) : undefined;
  }
};

export const checkMinLength = (min) => (value) => {
  if (value) {
    return value.length < min ? (
      <div className="bbs_form_error_text text-error">{trb(i18.Validations.InputIsTooShort) + ':' + trb(i18.Validations.MinInputIs) + ' ' + min}</div>
    ) : undefined;
  }
};

export const validatePaymentAmount = (accountAmount) => (amount) => {
  if (amount) {
    return amount > accountAmount ? <div className="bbs_form_error_text text-danger">{trb(i18.Validations.InsufficientFunds)}</div> : undefined;
  }
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const checkPersonCode = (code) => {
  if (code.length !== 11 || !isNumeric(code)) return false;
  let i, a, b, c, d, e;
  let s = String(code);
  d = 0;
  e = 0;
  b = 1;
  c = 3;
  for (i = 0; i < 10; i++) {
    a = s.charAt(i);
    d = d + b * a;
    e = e + c * a;
    b = b + 1;
    if (b === 10) b = 1;
    c = c + 1;
    if (c === 10) c = 1;
  }
  d = d % 11;
  e = e % 11;
  if (d === 10) {
    if (e === 10) {
      i = 0;
    } else {
      i = e;
    }
  } else i = d;
  return s.charAt(10) === i.toString();
};

export const checkFirmCode = (code) => {
  return !!((code.length === 7 || code.length === 9) && isNumeric(code));
};

export const ignitisElectricCodeIsValid = (code) => {
  code += '';
  if (code.length !== 8) {
    return false;
  }
  const z = parseInt(code[0]);
  const x1 = parseInt(code[1]);
  const x2 = parseInt(code[2]);
  const x3 = parseInt(code[3]);
  const x4 = parseInt(code[4]);
  const x5 = parseInt(code[5]);
  const x6 = parseInt(code[6]);
  const a = parseInt(code[7]);
  const sum = 2 * z + 7 * x1 + 6 * x2 + 5 * x3 + 4 * x4 + 3 * x5 + 2 * x6;
  let div = sum % 11;
  div = div === 10 ? 0 : div;
  return a === div;
};

export const ignitisGasCodeIsValid = (code) => {
  code += '';
  if (code.length !== 8) {
    return false;
  }

  const x0 = parseInt(code[0]);
  const x1 = parseInt(code[1]);
  const x2 = parseInt(code[2]);
  const x3 = parseInt(code[3]);
  const x4 = parseInt(code[4]);
  const x5 = parseInt(code[5]);
  const x6 = parseInt(code[6]);
  const a = parseInt(code[7]);
  const sum = 8 * x0 + 7 * x1 + 6 * x2 + 5 * x3 + 4 * x4 + 3 * x5 + 2 * x6;
  let div = sum % 11;
  div = div === 10 ? 0 : div;
  return a === div;
};

export const checkMax =
  (max, int = false) =>
  (value) => {
    return value > max ? (
      <div className="bbs_form_error_text text-danger">
        {trb(i18.Validations.NumberTooBig)}:{' '}
        {
          <Number
            value={max}
            format={int ? numberFormat().number : numberFormat().currencyWithMinus}
            text={1}
          />
        }
      </div>
    ) : undefined;
  };

export const checkMin =
  (min, int = false) =>
  (value) => {
    return min > value ? (
      <div className="bbs_form_error_text text-danger">
        {trb(i18.Validations.NumberTooSmall)}:{' '}
        {
          <Number
            value={min}
            format={int ? numberFormat().number : numberFormat().currencyWithMinus}
            text={1}
          />
        }
      </div>
    ) : undefined;
  };

export const checkForWhiteSpaces = (str) => {
  if (!str) {
    return str;
  }
  return /\s/.test(str) ? <div className="bbs_form_error_text text-error">{trb(i18.Validations.NoWhiteSpaceAllowed)}</div> : undefined;
};

export const checkForWhiteSpacesStartEndOnly = (str) => {
  if (!str) {
    return str;
  }
  return /^\s+|\s+$/.test(str) ? <div className="bbs_form_error_text text-error">{trb(i18.Validations.NoWhiteSpaceAllowedInFrontOrEnd)}</div> : undefined;
};

export const validateRegex = (regex, errorText) => (value) => {
  return regex.test(value) ? undefined : <div className="bbs_form_error_text text-error">{errorText}</div>;
};

export const validateRegexPasses = (regex, errorText) => (value) => {
  return regex.exec(value)?.[0] === value ? undefined : <div className="bbs_form_error_text text-error">{errorText}</div>;
};

export const checkStringIsFirstTwoNumbers = (value) => {
  return value?.length >= 2 && firstTwoNumbers.test(value.slice(0, 2));
};

const validationErrors = (errorsArray) => {
  return errorsArray.length ? (
    <div className="bbs_form_error_text text-error">
      {errorsArray.map(({ error, params = null }, index) => (
        <div key={index}>{trb(i18.Validations[error], params)}</div>
      ))}
    </div>
  ) : undefined;
};
export const validateTemplateIban = (value) => {
  const errorsArray = [];
  if (!value) {
    // IBAN cant be empty
    errorsArray.push({ error: REQUIRED });
  } else {
    const { checkIBANLength, checkIBANValid } = store.getState()?.PMCReducers ?? {};

    if (value.length === 1) {
      // IBAN length must be at least 2 symbols length
      errorsArray.push({ error: IBAN_TOO_SHORT });
    } else {
      if (value?.length >= 2 && firstTwoLetters.test(value.slice(0, 2)) && checkIBANLength?.ibanData?.iban === value.slice(0, 2) && !checkIBANLength.ibanLenght) {
        // wrong first two letters. IBAN lenght === 0
        errorsArray.push({ error: IBAN_NOT_VALID });
      }

      if (
        value?.length >= 2 &&
        firstTwoLetters.test(value.slice(0, 2)) &&
        checkIBANLength?.ibanLenght &&
        checkIBANLength?.ibanData?.iban === value.slice(0, 2) &&
        value?.length !== checkIBANLength.ibanLenght
      ) {
        // IBAN length must be appropriate length if first two symbols is letters
        errorsArray.push({
          error: WRONG_IBAN_LENGHT,
          params: { length: checkIBANLength.ibanLenght },
        });
      }

      if (firstTwoLetters.test(value.slice(0, 2)) && checkIBANValid?.ibanData?.iban === value && !checkIBANValid.ibanValid) {
        // wrong IBAN if first two symbols is letters
        errorsArray.push({ error: IBAN_NOT_VALID });
      }
    }
  }
  return validationErrors(errorsArray);
};

export const checkInternationalPaymentCurrencyConfigured = (error) => () => {
  return error ? <div className="bbs_form_error_text text-error">{trb(translationGroups.Validations + error) ?? error}</div> : undefined;
};

export const minAmount = (min, validationText) => (value) => {
  return +value < +min ? <div className="bbs_form_error_text text-error">{trb(i18.Validations[validationText], { min })}</div> : undefined;
};
