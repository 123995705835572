import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { aPIPaths, IFRAME_USER, iframeSave, methods, requestEBankJSON } from 'src/Redux';

const postVerifyJWE = createAsyncThunk('jwe/postVerifyJwe', async (user, thunkAPI) => {
  try {
    const response = await requestEBankJSON(aPIPaths.post_UAC_CheckLoginJWE, {
      method: methods.post,
      data: { jwe: user?.Jwe },
    });
    thunkAPI.dispatch(iframeSave({ key: IFRAME_USER, value: response.data }));
    return response.data;
  } catch (error) {
    return error?.response?.data ?? error;
  }
});

const initialState = {
  loading: false,
  Jwe: null,
  jweVerified: true, // set to false to trigger verification
  Bearer: null,
  isAuth: false,
  isError: false,
  LoginStatusCode: null,
  NeedChangePass: false,
  RefreshToken: null,
  isRemote: false, // Set to null to trigger verification
};

const JWESlice = createSlice({
  name: 'jwe',
  initialState,
  reducers: {
    setIsRemoteToken(state, action) {
      if (action?.payload) {
        state.isRemote = null;
        state.jweVerified = false;
      } else {
        state.isRemote = false;
        state.jweVerified = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postVerifyJWE.fulfilled, (state, action) => {
      return { ...state, loading: false, error: null, jweVerified: true, ...action.payload };
    });

    builder.addCase(postVerifyJWE.pending, (state, action) => {
      const isRemote = !action?.meta?.arg?.Bearer && !action?.meta?.arg?.RefreshToken;
      return { ...state, loading: true, error: null, Jwe: action?.meta?.arg, jweVerified: false, isRemote };
    });

    builder.addCase(postVerifyJWE.rejected, (state, action) => {
      return { ...state, loading: false, error: action?.error?.message, jweVerified: null };
    });

    builder.addMatcher(
      (action) => action?.type === 'GET_UAC_LOGOUT_REQUEST',
      (state) => {
        return { ...state, isRemote: false };
      }
    );
  },
});

const { reducer, actions } = JWESlice;

const JWEActions = {
  postVerifyJWE,
  setIsRemoteToken: actions.setIsRemoteToken,
};

export { reducer as JWEReducer, JWEActions };
