import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {
    formatCurrentDate,
    i18,
    translationGroups,
    checkJsonProp,
} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCClientCEO = (props) => {

    let ClientCeo = checkJsonProp(props.data, 'clientCeo') && checkJsonProp(props.data, 'clientCeo').length ? checkJsonProp(props.data, 'clientCeo') : [{clientCeoType: "CEO1", data:{}}],
        ClientRep = checkJsonProp(props.data, 'clientRep') && checkJsonProp(props.data, 'clientRep').length ? checkJsonProp(props.data, 'clientRep') : [{clientRepType: "REPC1", data:{}}],
        ClientProfile = checkJsonProp(props.data, 'clientProfile');

    ClientCeo[0].data.clientCeoPhoneStr = checkJsonProp(ClientCeo, '0.data.clientCeoPhone') ? checkJsonProp(ClientCeo, '0.data.clientCeoPhone').slice(4) : null;
    ClientCeo[0].data.clientCeoPhonePrefix = checkJsonProp(ClientCeo, '0.data.clientCeoPhone') ? checkJsonProp(ClientCeo, '0.data.clientCeoPhone').slice(0,4) : null;

    ClientRep[0].data.clientRepPhoneNoStr = checkJsonProp(ClientRep, '0.data.clientRepPhoneNo') ? checkJsonProp(ClientRep, '0.data.clientRepPhoneNo').slice(4) : null;
    ClientRep[0].data.clientRepPhoneNoPrefix = checkJsonProp(ClientRep, '0.data.clientRepPhoneNo') ? checkJsonProp(ClientRep, '0.data.clientRepPhoneNo').slice(0,4) : null;

    ClientProfile[0].data.whoOpenAccount = ClientRep && ClientRep.length && ClientRep[0].data && Object.keys(ClientRep[0].data).length > 2 ? '0' : '1';

    const initialData = {clientCeo: ClientCeo, clientRep: ClientRep, clientProfile: ClientProfile};

    const mapData = [{
        title: i18.Labels.ClientCeo,
        messageBefore: i18.NotificationMessages.KYCClientCEO,
        elements: [{
            title: i18.Labels.ClientCeoName,
            name: 'clientCeo.0.data.clientCeoName',
            component: FormFieldType.input,
            className: 'col-6 col-md-5 offset-md-1',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoSurname,
            name: 'clientCeo.0.data.clientCeoSurname',
            component: FormFieldType.input,
            className: 'col-6 col-md-5',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoId,
            name: 'clientCeo.0.data.clientCeoId',
            component: FormFieldType.input,
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoCitizien,
            name: 'clientCeo.0.data.clientCeoCitizien',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoPhoneNo,
            name: 'clientCeo.0.data.clientCeoPhone',
            component: FormFieldType.input,
            className: 'col-12 d-none',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoPhoneNo,
            name: 'clientCeo.0.data.clientCeoPhoneStr',
            component: FormFieldType.input,
            className: 'col-6 col-md-12 disabled',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            addonBeforeText: checkJsonProp(ClientCeo, '0.data.clientCeoPhonePrefix'),
            disabled: true,
        },{
            title: i18.Labels.ClientCeoIdDocType,
            name: 'clientCeo.0.data.clientCeoIdDocType',
            component: FormFieldType.select,
            options: props.classifikators.DOCS,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoDocNo,
            name: 'clientCeo.0.data.clientCeoDocNo',
            component: FormFieldType.input,
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoDocCountry,
            name: 'clientCeo.0.data.clientCeoDocCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoDocIssueDate,
            name: 'clientCeo.0.data.clientCeoDocIssueDate',
            component: FormFieldType.date,
            className: 'col-12 col-md-5 offset-md-1 py-1',
            classNameTitle: 'col-6 col-md-7 text-right',
            classNameField: 'col-6 col-md-5 pl-3',
            disabled: true,
        },{
            title: i18.Labels.ClientCeoDocValidDate,
            name: 'clientCeo.0.data.clientCeoDocValidDate',
            component: FormFieldType.date,
            className: 'col-12 col-md-5 py-1',
            classNameTitle: 'col-6 col-md-7 text-right',
            classNameField: 'col-6 col-md-5 pl-3',
            disabled: true,
        },{
            title: i18.Labels.WhoOpenAccount,
            name: 'clientProfile.0.data.whoOpenAccount',
            component: FormFieldType.radio,
            options: [{label: "Yes", value: "1"}, {label: "No", value: "0"}],
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-12 col-md-11 offset-md-1',
            translate: translationGroups.Labels,
        }],
    },{
        title: i18.Labels.ClientRep,
        showWhen: 'clientProfile.0.data.whoOpenAccount',
        showIs: "0",
        elements: [{
            title: i18.Labels.ClientRepName,
            name: 'clientRep.0.data.clientRepName',
            component: FormFieldType.input,
            className: 'col-6 col-md-5 offset-md-1',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
        },{
            title: i18.Labels.ClientRepSurname,
            name: 'clientRep.0.data.clientRepSurname',
            component: FormFieldType.input,
            className: 'col-6 col-md-5',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-8',
        },{
            title: i18.Labels.ClientRepId,
            name: 'clientRep.0.data.clientRepId',
            component: FormFieldType.input,
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-4',
        },{
            title: i18.Labels.ClientRepMandatValidDate,
            name: 'clientRep.0.data.clientRepMandatValidDate',
            component: FormFieldType.date,
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-4',
        },{
            title: i18.Labels.ClientRepRegCountry,
            name: 'clientRep.0.data.clientRepRegCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-4',
        },{
            title: i18.Labels.ClientRepPhoneNo,
            name: 'clientRep.0.data.clientRepPhoneNo',
            component: FormFieldType.input,
            className: 'col-12 d-none',
        },{
            title: i18.Labels.ClientRepPhoneNo,
            name: 'clientRep.0.data.clientRepPhoneNoStr',
            component: FormFieldType.input,
            className: 'col-6 col-md-12 addon-unclocked',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-4',
            addonBefore: {
                maxLength: 4,
                name: "clientRep.0.data.clientRepPhoneNoPrefix",
                className: "col-12 m-0 ml-2",
                component: FormFieldType.inputOnly,
                //validate: composeValidators(required, checkPhonePrefix, checkMinLength(4), checkMaxLength(4)),
            },
            maxLength: 8,
            //validate: composeValidators(required, checkPhoneNumber, checkMinLength(8), checkMaxLength(8)),
        },{
            title: i18.Labels.ClientRepIdDocType,
            name: 'clientRep.0.data.clientRepIdDocType',
            component: FormFieldType.select,
            options: props.classifikators.DOCS,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-4',
        },{
            title: i18.Labels.ClientRepDocNo,
            name: 'clientRep.0.data.clientRepDocNo',
            component: FormFieldType.input,
            className: 'col-6 col-md-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-4',
        },{
            title: i18.Labels.ClientCeoDocIssueDate,
            name: 'clientRep.0.data.clientRepDocIssueDate',
            component: FormFieldType.date,
            className: 'col-12 col-md-5 offset-md-1 py-1',
            classNameTitle: 'col-6 col-md-7 text-right',
            classNameField: 'col-6 col-md-5 pl-3',
            maxDate: formatCurrentDate(),
        },{
            title: i18.Labels.ClientCeoDocValidDate,
            name: 'clientRep.0.data.clientRepDocValidDate',
            component: FormFieldType.date,
            className: 'col-12 col-md-5 py-1',
            classNameTitle: 'col-6 col-md-7 text-right',
            classNameField: 'col-6 col-md-5 pl-3',
            minDate: formatCurrentDate(),
        },{
            title: i18.Labels.ClientRepDocCountry,
            name: 'clientRep.0.data.clientRepDocCountry',
            component: FormFieldType.selectSearch,
            options: props.classifikators.CISO,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-12',
            classNameTitle: 'col-12 col-md-4 text-left text-md-right',
            classNameField: 'col-12 col-md-4',
        }],
    }];

    const decorators = [
        createDecorator({
            field: /clientRep\.*.*clientRepPhoneNoStr/,
            updates: (value, name, allValues) => {
                const toField = name.replace('clientRepPhoneNoStr', 'clientRepPhoneNo')
                const prefixField = name.replace('clientRepPhoneNoStr', 'clientRepPhoneNoPrefix')
                const prefixValue = checkJsonProp(allValues, prefixField);
                return value && value !== '' && prefixValue && prefixValue !== '' ? {
                  [toField]: prefixValue+value
                } : ''
            }
        },{
            field: /clientRep\.*.*clientRepPhoneNoPrefix/,
            updates: (value, name, allValues) => {
                const toField = name.replace('clientRepPhoneNoPrefix', 'clientRepPhoneNo')
                const numberField = name.replace('clientRepPhoneNoPrefix', 'clientRepPhoneNoStr')
                const numberValue = checkJsonProp(allValues, numberField);
                return value && value !== '' && numberValue && numberValue !== '' ? {
                  [toField]: value+numberValue
                } : ''
            }
        })
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.InformationAboutClient} decorators={decorators} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};
