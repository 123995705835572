import { requestEBank, UACServices } from 'src/Redux';
import { history, routerPath } from 'src/Config';

const LogoutFn = async () => {
  await requestEBank(UACServices.getLogout());
  sessionStorage.clear();
  history.push(routerPath.index);
  localStorage.removeItem('SavedIBAN');
  localStorage.removeItem('SavedCurrency');
  location.reload();
};

export const Logout = () => {
  return LogoutFn();
};
