import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AMCActions, PMCActions, UACActions, UACConstants } from 'src/Redux';
import { routerPath } from 'src/Config';

import { PageLoader, RefreshToken } from 'src/Components';
import { checkJsonProp } from 'src/Utilities';
import jwtDecode from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import { useNavigate } from 'react-router-dom';

export const PrivateRoute = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userVerified, setUserVerified] = useState(false);

  const { user } = useSelector((state) => state.IframeStorageReducer);
  const { sendATR, ATR, sendMALRInitial } = useSelector((state) => state.UACReducers);
  const { sendPTRInitial } = useSelector((state) => state.PMCReducers);
  const { sendCOMPANYInitial } = useSelector((state) => state.AMCReducers);

  const handleComponentMounted = async () => {
    if (user === undefined) {
      return;
    }

    if (userVerified) {
      return;
    }

    if (!user?.Bearer) {
      navigate(routerPath.login);
    } else {
      const response = await dispatch(UACActions.postATR());
      if (response && response.type && response.type === UACConstants.POST_UAC_ATR_ERROR) {
        navigate(routerPath.login);
        return;
      }
      if (response && response.type && response.type === UACConstants.POST_UAC_ATR_SUCCESS && checkJsonProp(response, 'payload.IsSuccessfull') === false) {
        sessionStorage.clear();
        navigate(routerPath.login);
        return;
      }

      setUserVerified(true);
      if (response && response.type && response.type === UACConstants.POST_UAC_ATR_SUCCESS && checkJsonProp(response, 'payload.IsSuccessfull') === true) {
        await dispatch(AMCActions.getCompany());
        await dispatch(PMCActions.postPTR());
        await dispatch(UACActions.getMALR());
      }
      const { UserCode } = jwtDecode(user?.Bearer);
      Sentry.setUser({ username: UserCode });
    }
  };

  useEffect(() => {
    handleComponentMounted();
  }, [user]);

  let Com = props.component;

  if (!user?.Bearer || sendATR !== false || !ATR || sendPTRInitial || !userVerified) {
    return <PageLoader />;
  }

  if (sendPTRInitial || sendCOMPANYInitial || sendMALRInitial) {
    return <PageLoader />;
  }

  return (
    <>
      <RefreshToken />
      <Com {...props} />
    </>
  );
};
