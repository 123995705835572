export const PERMISSIONS_OPTIONS = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'Preview',
    value: 3,
  },
];

export const LIMITS_PERMISSIONS_OPTIONS = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'Preview',
    value: 1,
  },
  {
    label: 'Create',
    value: 2,
  },
  {
    label: 'Approve',
    value: 3,
  },
];
