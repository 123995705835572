export const SmartId = ({ size = 29 }) => {
  return (
    <svg
      width={(size * 91) / size}
      height={size}
      viewBox="0 0 91 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_25710)">
        <path
          d="M17.709 8.86732C17.709 9.43553 17.2671 9.87747 16.6989 9.87747C16.1307 9.87747 15.6887 9.43553 15.6887 8.86732C15.6887 8.29912 16.1307 7.85718 16.6989 7.85718C17.2671 7.85718 17.709 8.29912 17.709 8.86732Z"
          fill="#00AFAA"
        />
        <path
          d="M21.9392 25.5349C21.6866 25.5349 21.4341 25.4086 21.2763 25.2192C21.0237 24.872 21.0869 24.3669 21.4341 24.0828C24.6224 21.7468 26.5164 18.0219 26.5164 14.076C26.548 7.25755 20.9606 1.67017 14.079 1.67017C13.4792 1.67017 12.8794 1.70174 12.2797 1.79644V19.3477C12.2797 19.7897 11.9324 20.1369 11.4589 20.1369C11.017 20.1369 10.6382 19.7897 10.6382 19.3477V1.10197C10.6382 0.723162 10.9223 0.375924 11.3011 0.31279C12.2165 0.123388 13.132 0.0286865 14.079 0.0286865C21.8445 0.0286865 28.1579 6.31054 28.1579 14.0445C28.1579 18.4954 26.0113 22.7254 22.4127 25.3455C22.2548 25.5033 22.097 25.5349 21.9392 25.5349Z"
          fill="#00AFAA"
        />
        <path
          d="M14.0789 28.1234C6.3134 28.1234 0 21.81 0 14.1076C0 9.65664 2.14656 5.42665 5.7452 2.80659C6.124 2.52248 6.62907 2.61718 6.88161 2.96442C7.13414 3.31166 7.07101 3.81673 6.72377 4.10084C3.50394 6.40523 1.60992 10.1617 1.60992 14.1076C1.60992 20.9577 7.19728 26.5135 14.0789 26.5135C14.6787 26.5135 15.2784 26.4819 15.8782 26.3872V14.8652C15.8782 14.4233 16.2254 14.076 16.6989 14.076C17.1409 14.076 17.5197 14.4233 17.5197 14.8652V27.0501C17.5197 27.4289 17.2356 27.7761 16.8568 27.8393C15.9413 28.0287 15.0259 28.1234 14.0789 28.1234Z"
          fill="#00AFAA"
        />
      </g>
      <g clipPath="url(#clip1_2_25710)">
        <path
          d="M31.9773 15.5592H33.3662C33.4609 16.3484 33.7766 17.0113 35.1024 17.0113C35.9547 17.0113 36.5861 16.5378 36.5861 15.8118C36.5861 15.0857 36.2388 14.8332 34.9446 14.6122C33.0821 14.3597 32.1667 13.7915 32.1667 12.3394C32.1667 11.0767 33.2715 10.0981 34.8815 10.0981C36.5545 10.0981 37.5647 10.8558 37.7225 12.371H36.3967C36.2388 11.5502 35.7969 11.203 34.8815 11.203C33.9976 11.203 33.5241 11.6134 33.5241 12.2131C33.5241 12.8445 33.7766 13.1602 35.134 13.3496C36.9649 13.6021 37.9435 14.1387 37.9435 15.6855C37.9435 17.0429 36.807 18.0846 35.0709 18.0846C32.9874 18.0846 32.1036 17.0429 31.9773 15.5592Z"
          fill="#00AFAA"
        />
        <path
          d="M40.7529 10.1927H42.7101L44.7619 15.496L46.7822 10.1927H48.7078V17.9898H47.2557V12.0868L44.9198 17.9898H44.4463L42.0472 12.0868V17.9898H40.7529V10.1927Z"
          fill="#00AFAA"
        />
        <path
          d="M70.7416 11.3292H68.5635V10.1927H74.4034V11.3292H72.2252V17.9898H70.7416V11.3292Z"
          fill="#00AFAA"
        />
        <path
          d="M77.6232 13.8546H74.6875V14.991H77.6232V13.8546Z"
          fill="#00AFAA"
        />
        <path
          d="M58.3358 17.9898L55.7473 10.1927H53.8217L51.2017 17.9898H52.5906L54.7056 11.3607L55.7789 14.7384H54.8319L54.4846 15.8748H56.1261L56.8206 17.9898H58.3358Z"
          fill="#00AFAA"
        />
        <path
          d="M81.0957 10.1927H79.6121V17.9898H81.0957V10.1927Z"
          fill="#00AFAA"
        />
        <path
          d="M86.62 10.1927H84.2524V17.9898H85.7045V16.8534V11.3292H86.5568C88.3877 11.3292 89.1769 12.3393 89.1769 14.0124V14.1071C89.1769 15.5592 88.5771 16.5062 87.346 16.7587V17.9583C89.6188 17.7057 90.7237 16.1905 90.7237 14.1071V14.0124C90.6921 11.7395 89.3663 10.1927 86.62 10.1927Z"
          fill="#00AFAA"
        />
        <path
          d="M64.6492 14.6122C65.6278 14.3597 66.3854 13.7283 66.3854 12.5288V12.4657C66.3854 10.8873 65.1858 10.1613 63.4497 10.1613H60.8296V17.9583H62.2817V11.2977H63.4497C64.3967 11.2977 64.9333 11.6133 64.9333 12.4972V12.5288C64.9333 13.3495 64.4282 13.7599 63.4497 13.7599H63.1971V14.8016L65.249 17.9583H66.8273L64.6492 14.6122Z"
          fill="#00AFAA"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_25710">
          <rect
            width="28.1578"
            height="28"
            fill="white"
            transform="translate(0 0.0913086)"
          />
        </clipPath>
        <clipPath id="clip1_2_25710">
          <rect
            width="58.7778"
            height="7.8602"
            fill="white"
            transform="translate(31.9458 10.1613)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
