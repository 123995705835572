import React from 'react';
import PropTypes from 'prop-types';
import { CheckOutlined, DeleteOutlined, EditOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Tooltip } from 'antd';

import { i18, trb } from '../../../../../Utilities';

export class PaymentSmeGroupList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openRows: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;

    if (data !== prevProps.data) {
      let openRows = {};
      data.map((item) => {
        openRows[item.ID] = false;
      });
      this.setState({
        openRows,
      });
    }
  };

  toggleRow = (id) => {
    let { openRows } = this.state;
    openRows[id] = !openRows[id];

    this.setState({
      openRows,
    });
  };

  render() {
    const { data, clickEdit, clickExecute, clickDelete } = this.props,
      { openRows } = this.state;

    return (
      <div className="eb_table w-100">
        <div className="row eb_table_head d-none d-md-flex">
          <div className="col-1"></div>
          <div className="col-2">{trb(i18.Labels.Title)}</div>
          <div className="col-6">{trb(i18.Labels.PaymentGroupReceivers)}</div>
          <div className="col-3"></div>
        </div>
        {data.map((rowItem, rowIndex) => (
          <div
            className="row eb_table_row cursor-pointer"
            key={rowIndex}
            onClick={() => this.toggleRow(rowItem.ID)}
          >
            <div className="col-1">
              <span style={{ position: 'relative', top: 2 }}>{openRows[rowItem.ID] ? <UpOutlined /> : <DownOutlined />}</span>
            </div>
            <div className="col-12 col-md-2 eb_fw_400 eb_fs_16">
              <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Title)}</div>
              {rowItem.Name}
            </div>
            <div className="col-12 col-md-6 eb_fw_400 eb_fs_16">
              <div className="d-block d-md-none text-black-50">{trb(i18.Labels.PaymentGroupReceivers)}</div>
              {openRows[rowItem.ID] ? (
                <>
                  {rowItem.TmpltsInfs.map((item, index) => (
                    <React.Fragment key={index}>
                      <div className={index > 0 && 'pt-3'}>{item.RecipientName}</div>
                      <div className="text-gray-basic pt-1">{item.RecipientAccountNumber}</div>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <>
                  <div>{rowItem.TmpltsInfs[0].RecipientName}</div>
                  <div className="text-gray-basic pt-1">{rowItem.TmpltsInfs[0].RecipientAccountNumber}</div>
                </>
              )}
            </div>
            <div className="col-12 col-md-3 text-left text-md-right text-nowrap">
              <div className="d-block d-md-none text-black-50">{trb(i18.Labels.Actions)}</div>
              <Button.Group className="hover-action rounded-button">
                <Popconfirm
                  placement="bottomRight"
                  title={trb(i18.PopConfirms.DoYouWantDeletePaymentGroup)}
                  onConfirm={() => clickDelete(rowItem.ID)}
                  okText={trb(i18.Buttons.Yes)}
                  cancelText={trb(i18.Buttons.Cancel)}
                >
                  <Tooltip
                    placement="topRight"
                    title={trb(i18.Tooltips.PaymentGroupDelete)}
                  >
                    <Button d={`PaymentSmeGroupListPopconfirm${rowIndex}DeleteButton`}>
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Popconfirm>
                <Tooltip
                  placement="topRight"
                  title={trb(i18.Tooltips.PaymentGroupEdit)}
                >
                  <Button id={`PaymentSmeGroupListPopconfirm${rowIndex}EditButton`} onClick={() => clickEdit(rowItem.ID)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip
                  placement="topRight"
                  title={trb(i18.Tooltips.PaymentGroupUse)}
                >
                  <Button id={`PaymentSmeGroupListPopconfirm${rowIndex}UseButton`} onClick={() => clickExecute(rowItem.ID)}>
                    <CheckOutlined />
                  </Button>
                </Tooltip>
              </Button.Group>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

PaymentSmeGroupList.propTypes = {
  data: PropTypes.array.isRequired,
  clickDelete: PropTypes.func.isRequired,
  clickEdit: PropTypes.func.isRequired,
  clickExecute: PropTypes.func.isRequired,
};
