import { useEffect, useState } from 'react';
import { CustomIcon, useGetWindowSize, trb } from 'src/Utilities';
import { Button, Menu } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const ContentMenuMobile = () => {
  const windowSize = useGetWindowSize();
  const navigate = useNavigate();

  const [menuShow, setMenuShow] = useState(false);

  const { contentMenu, contentTitle } = useSelector((state) => state.MenuReducer);

  useEffect(() => {
    setMenuShow(false);
  }, [contentMenu]);

  const toggleMenu = () => {
    setMenuShow(!menuShow);
  };

  const clickMenu = (e) => {
    navigate(e.key);
    toggleMenu();
  };

  if (!contentMenu || !windowSize.tabletCheck) {
    return <></>;
  }

  const getMenuItem = (label, key) => {
    return {
      label,
      key,
      id: `SubMenuItem${key?.replace(/\//gi, '')}`,
    };
  };

  return (
    <div className={`eb_content_title ${window.location.pathname === '/' && 'eb_content_title_dashboard'} text-black eb_fw_400 d-flex flex-column`}>
      <div className="d-flex">
        {contentTitle}
        <Button
          id="MenuMobileButton"
          type="primary"
          size="small"
          className="eb_content_menu_mobile_btn"
          onClick={toggleMenu}
        >
          <CustomIcon
            type="custom"
            icon="caretLeft"
            className={menuShow ? 'rotate-270' : 'rotate-90'}
          />
        </Button>
      </div>
      <div className={`eb_content_menu_mobile_wrapper ${menuShow && 'eb_content_menu_mobile_wrapper_active'}`}>
        <Menu
          mode="inline"
          className="eb_content_menu_mobile"
          onClick={clickMenu}
          selectable={false}
          items={contentMenu.map((item) => getMenuItem(trb(item.title), item.path))}
        />
      </div>
    </div>
  );
};
