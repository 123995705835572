import { aPIPaths, handleResponse, methods, requestEBank, requestEBankFile } from 'src/Redux/Config';
import qs from 'qs';

export const PMCServices = {
  postPTR,
  postPVR,
  postAPR,
  postAPSMSR,
  postAPESCR,
  getPayPersonTypes,
  postTTG,
  postDTR,
  postDGR,
  postGGDR,
  postSGPVR,
  postSDPVR,
  postSPR,
  postUTR,
  postCGPSGNR,
  postUPR,
  postPUPR,
  postSUPR,
  postRTFGR,
  getHistoricalPayments,
  getHistoricalPaymentByID,
  postCheckIBANMember,
  postGetFxRate,
  GetCountryCurrencies,
  GetPaymentPurposeCode,
  GetBeneficiaryIdentificationCodeTypes,
  getLengthIBAN,
  checkIbanValid,
  getForexPayments,
};

function postPTR(data = null) {
  return requestEBank(aPIPaths.post_PMC_PTR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postPVR(data = null) {
  return requestEBank(aPIPaths.post_PMC_PVR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postAPR(data) {
  //fake approve esign
  //return requestEBank('https://run.mocky.io/v3/22e6c09b-20cd-491c-b11f-36a72b36f77b', {
  //fake approve sms
  //return requestEBank('https://run.mocky.io/v3/0e37c47d-3c04-4976-9e34-51f029d9b09a', {
  //real approve
  return requestEBank(aPIPaths.post_PMC_APR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postAPSMSR(data = null) {
  //fake sign with waiting_to_confirm
  //return requestEBank('https://run.mocky.io/v3/ec9f06d8-30a7-4e10-9cc4-eb6e526414a4', {
  //real sign
  return requestEBank(aPIPaths.post_PMC_APSMSR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postAPESCR(data = null) {
  //fake sign with waiting_to_confirm
  //return requestEBank('https://run.mocky.io/v3/ec9f06d8-30a7-4e10-9cc4-eb6e526414a4', {
  //real sign
  return requestEBank(aPIPaths.post_PMC_APESCR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function getPayPersonTypes(data) {
  const url = qs.stringify(data);
  return requestEBank(aPIPaths.get_PMC_GetPayPersonTypes + '?' + url).then(handleResponse);
}

function postTTG(data) {
  return requestEBank(aPIPaths.post_PMC_TTG, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postDTR(data) {
  return requestEBank(aPIPaths.post_PMC_DTR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postDGR(data) {
  return requestEBank(aPIPaths.post_PMC_DGR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postGGDR(data) {
  return requestEBank(aPIPaths.post_PMC_GGDR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postSGPVR(data) {
  return requestEBank(aPIPaths.post_PMC_SGPVR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postSDPVR(data) {
  return requestEBank(aPIPaths.post_PMC_SDPVR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postSPR(data) {
  return requestEBank(aPIPaths.post_PMC_SPR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postUTR(data) {
  return requestEBank(aPIPaths.post_PMC_UTR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postCGPSGNR(data) {
  return requestEBank(aPIPaths.post_PMC_CGPSGNR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postUPR(data) {
  return requestEBankFile(aPIPaths.post_PMC_UPR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postPUPR(data) {
  return requestEBank(aPIPaths.post_PMC_PUPR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postSUPR(data) {
  // return requestEBank('https://run.mocky.io/v3/dfaffb37-ed85-4f41-b0d0-1182252f6106', {
  //     method: methods.post,
  //     data: data,
  // }).then(handleResponse);
  return requestEBank(aPIPaths.post_PMC_SUPR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postRTFGR(data) {
  return requestEBank(aPIPaths.post_PMC_RTFGR, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function getHistoricalPayments(data) {
  //return requestEBank('https://run.mocky.io/v3/d7f8caf8-cdbe-4613-ae07-aa5257477dd6').then(handleResponse);
  return requestEBank(aPIPaths.get_PMC_HistoricalPayments, {
    method: methods.post,
    data: data,
  }).then(handleResponse); // + '?' + url).then(handleResponse);
}

function getHistoricalPaymentByID(data) {
  return requestEBank(aPIPaths.get_PMC_HistoricalPaymentById, {
    method: methods.post,
    data: data,
  }).then(handleResponse); // + '?' + url).then(handleResponse);
}

function postCheckIBANMember(data) {
  return requestEBank(aPIPaths.post_PMC_CHECK_IBAN, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function postGetFxRate(data) {
  return requestEBank(aPIPaths.post_GetFxRate, {
    method: methods.post,
    data: data,
  }).then(handleResponse);
}

function GetCountryCurrencies() {
  return requestEBank(aPIPaths.get_GetCountryCurrencies).then(handleResponse);
}

function GetPaymentPurposeCode() {
  return requestEBank(aPIPaths.get_GetPaymentPurposeCode).then(handleResponse);
}

function GetBeneficiaryIdentificationCodeTypes() {
  return requestEBank(aPIPaths.get_GetBeneficiaryIdentificationCodeTypes).then(handleResponse);
}

function getLengthIBAN(data) {
  return requestEBank(`${aPIPaths.get_LengthIBAN}/${data.iban}`).then(handleResponse);
}

function checkIbanValid(data) {
  return requestEBank(`${aPIPaths.get_CheckIbanValid}/${data.iban}`).then(handleResponse);
}

function getForexPayments() {
  return requestEBank(aPIPaths.get_PMC_ForexPayments).then(handleResponse);
}
