import { aPIPaths, handleResponse, methods, requestEBankJSON } from '../../Config';

export const FeeServices = {
  getFeeList,
  getFeePayment,
  postLastFee,
};

function getFeeList() {
  return requestEBankJSON(aPIPaths.get_Fee_List).then(handleResponse);
}

function getFeePayment(id) {
  return requestEBankJSON(aPIPaths.get_FeePayment + id).then(handleResponse);
}

function postLastFee(data) {
  return requestEBankJSON(aPIPaths.post_Fee_LastFee, {
    method: methods.post,
    data: data,
  }).then(handleResponse);

}