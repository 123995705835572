import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { aPIPaths, methods, requestEBankJSON } from 'src/Redux';
import { Notification } from 'src/Components';
import { i18 } from 'src/Utilities';

const getBulkPaymentsFileList = createAsyncThunk('bulkFile/getBulkFileList', async () => {
  try {
    const response = await requestEBankJSON(aPIPaths.get_OMC_BulkPaymentsFileList, {
      method: methods.get,
    });
    return response.data;
  } catch (error) {
    return error?.response?.data ?? error;
  }
});

const deleteBulkPaymentsFile = createAsyncThunk('bulkFile/deleteBulkPaymentsFile', async (FileGuid) => {
  try {
    const response = await requestEBankJSON(`${aPIPaths.delete_OMC_BulkPaymentsFile}/${FileGuid}`, {
      // method: methods.delete,
      method: methods.get,
    });
    return response.data;
  } catch (error) {
    return error?.response?.data ?? error;
  }
});

const getBulkPaymentsFileDetails = createAsyncThunk('bulkFile/getBulkPaymentsFileDetails', async (fileId) => {
  try {
    const response = await requestEBankJSON(`${aPIPaths.get_OMC_BulkPaymentsDetails}/${fileId}`, {
      method: methods.get,
    });
    return response.data;
  } catch (error) {
    return error?.response?.data ?? error;
  }
});

const getBulkPaymentsPreview = createAsyncThunk('bulkFile/getBulkPaymentsPreview', async (FileGuid) => {
  try {
    const response = await requestEBankJSON(`${aPIPaths.get_PMC_BulkPaymentsPreview}/${FileGuid}`, {
      method: methods.get,
    });
    return response.data;
  } catch (error) {
    return error?.response?.data ?? error;
  }
});

const postBulkPaymentsSign = createAsyncThunk('bulkFile/postBulkPaymentsSign', async ({ FileGuid, Language }) => {
  try {
    const response = await requestEBankJSON(`${aPIPaths.post_OMC_BulkPaymentsSign}`, {
      method: methods.post,
      data: { FileGuid, Language },
    });
    return response.data;
  } catch (error) {
    return error?.response?.data ?? error;
  }
});

const initialState = {
  FilesList: [],
  FileDetails: null,
  loading: false,
  error: null,
};

const BulkFileSlice = createSlice({
  name: 'bulkFile',
  initialState,
  reducers: {
    removeFromList(state, action) {
      state.FilesList = state.FilesList.filter((item) => item.FileGuid !== action.payload);
    },

    cancelLoading(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBulkPaymentsFileList.fulfilled, (state, { payload }) => {
      return { ...state, ...payload, loading: false, error: null };
    });

    builder.addCase(getBulkPaymentsFileList.pending, (state) => {
      return { ...state, loading: true, error: null };
    });

    builder.addCase(getBulkPaymentsFileList.rejected, (state, action) => {
      return { ...state, loading: false, error: action?.error?.message };
    });

    builder.addCase(deleteBulkPaymentsFile.fulfilled, (state, action) => {
      if (action.payload === true) {
        return {
          ...state,
          FilesList: state.FilesList.filter((item) => item.FileGuid !== action.meta.arg),
          loading: false,
          error: null,
        };
      }
      return {
        ...state,
        loading: false,
        error: null,
      };
    });

    builder.addCase(deleteBulkPaymentsFile.pending, (state) => {
      return { ...state, loading: true, error: null };
    });

    builder.addCase(deleteBulkPaymentsFile.rejected, (state, action) => {
      return { ...state, loading: false, error: action?.error?.message };
    });

    builder.addCase(getBulkPaymentsFileDetails.fulfilled, (state, { payload }) => {
      return {
        ...state,
        FilesList: state.FilesList.map((item) => (payload.FileGuid === item.FileGuid ? { ...item, ...payload } : item)),
        loading: false,
        error: null,
      };
    });

    builder.addCase(getBulkPaymentsFileDetails.pending, (state) => {
      return { ...state, loading: true, error: null };
    });

    builder.addCase(getBulkPaymentsFileDetails.rejected, (state, action) => {
      return { ...state, loading: false, error: action?.error?.message };
    });

    builder.addCase(getBulkPaymentsPreview.fulfilled, (state, { payload }) => {
      if (payload.ErrorCode) {
        payload.PaymentErrorsItems?.forEach((item) => {
          Notification({
            type: 'error',
            message: i18.ErrorTitles[payload.ErrorCode],
            description: item?.ErrorDescription,
          });
        });
        return { ...state, loading: false, error: payload.ErrorCode };
      }

      if (!state.FilesList?.length) {
        return {
          ...state,
          FilesList: [payload],
          loading: false,
          error: null,
        };
      }

      const FilesList =
        state.FilesList.findIndex((item) => item.FileGuid === payload.FileGuid) !== -1 // search for item
          ? state.FilesList.map((item) => (item.FileGuid === payload.FileGuid ? { ...item, ...payload } : item)) // edit if found
          : [...state.FilesList, payload]; // push if not found
      return {
        ...state,
        FilesList,
        loading: false,
        error: null,
      };
    });

    builder.addCase(getBulkPaymentsPreview.pending, (state, action) => {
      return {
        ...state,
        loading: true,
        error: null,
        FilesList: state.FilesList.map((item) =>
          item.FileGuid === action.meta.arg
            ? {
                ...item,
                Report: [],
              }
            : item
        ),
      };
    });

    builder.addCase(getBulkPaymentsPreview.rejected, (state, action) => {
      return { ...state, loading: false, error: action?.error?.message };
    });

    builder.addCase(postBulkPaymentsSign.fulfilled, (state, action) => {
      if (action.payload.ErrorCode) {
        action.payload.PaymentErrorsItems?.forEach((item) => {
          Notification({
            type: 'error',
            message: i18.ErrorTitles[action.payload.ErrorCode],
            description: item?.ErrorDescription,
          });
        });
        return { ...state, loading: false, error: action.payload.ErrorCode };
      }

      const FilesList = state.FilesList.map((item) =>
        item.FileGuid === action.meta.arg.FileGuid
          ? {
              ...item,
              ...action.payload,
              FileGuid: item.FileGuid,
            }
          : item
      );
      return {
        ...state,
        FilesList,
        loading: false,
        error: action.payload.ErrorCode,
      };
    });

    builder.addCase(postBulkPaymentsSign.pending, (state) => {
      return { ...state, loading: true, error: null };
    });

    builder.addCase(postBulkPaymentsSign.rejected, (state, action) => {
      return { ...state, loading: false, error: action?.error?.message };
    });
  },
});

// Create actions
const BulkFileActions = {
  getBulkPaymentsFileList,
  deleteBulkPaymentsFile,
  getBulkPaymentsFileDetails,
  getBulkPaymentsPreview,
  postBulkPaymentsSign,
  removeFromList: BulkFileSlice.actions.removeFromList,
  cancelLoading: BulkFileSlice.actions.cancelLoading,
};

// Extract the action creators object and the reducer
const { reducer } = BulkFileSlice;
// Extract and export each action creator by name
// Export the reducer, either as a default or named export
export { reducer as BulkFileReducer, BulkFileActions };
