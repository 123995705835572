import { AutoComplete as AutoCompleteANTD, AutoCompleteProps } from 'antd';
import { useField } from 'formik';
import { LoadingIndicator } from '../LoadingIndicator';

interface IProps extends AutoCompleteProps {
  name?: string;
  handleSelect?: (value: string) => void;
  handleSearch?: (value: string) => void;
  loading?: boolean;
}

export const AutoComplete = ({ name = '', handleSelect, handleSearch, loading, ...restProps }: IProps) => {
  const [field, _meta, helpers] = useField(name);

  return (
    <AutoCompleteANTD
      value={field.value}
      onChange={async (value) => await helpers.setValue(value)}
      onSelect={async (value) => {
        if (typeof handleSelect === 'function') {
          handleSelect(value);
        }
      }}
      onSearch={async (value) => {
        if (typeof handleSearch === 'function') {
          handleSearch(value);
        }
      }}
      suffixIcon={loading ? <LoadingIndicator size="small" /> : undefined}
      style={{ width: '100%' }}
      {...restProps}
    />
  );
};
