import { IDIN, MobileId, SmartId, FTN, MobileESign, Biometric } from 'src/Assets/svg';
import { LoginConfirmationSwitcher, LoginForm, LoginType } from './LoginSteps';
import { ILoginCountries } from '../interfaces';

export const loginInitialValues = {
  LoginCode: null,
  LoginCountry: 'LT' as ILoginCountries,
  LoginIdentity: null,
  LoginType: null,
  LoginControlCode: null,
  LoginSession: null,
  LoginRememberMe: false,
  isFullLogin: true,
};

export const loginSteps = [LoginForm, LoginType, LoginConfirmationSwitcher];

export const countriesList = ['LT', 'LV', 'EE', 'FI', 'NL', 'Other'];

export const loginTypesImages = {
  SmartId: SmartId,
  MSignature: MobileId,
  IDIN: IDIN,
  FTN: FTN,
  'lv-eparaksts': MobileESign,
  BioFace: Biometric,
};

export const LOGIN_TYPES = {
  MOBILEESIGN: 'lv-eparaksts',
  SMARTID: 'SmartId',
  IDIN: 'idin-customer',
  FTN: 'fi-id',
  BIOFACE: 'BioFace',
};
