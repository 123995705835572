import React from 'react';

import {FormFieldType} from "../../../../../Components/FormField";
import {i18, checkJsonProp} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCPersonDocuments = (props) => {

    const ClientDocs = checkJsonProp(props.data, 'clientDocs'),
          initialData = {clientDocs: ClientDocs};

    const mapData = [
        {
            messageBefore: i18.NotificationMessages.KYCPersonDocuments,
            elements: [{
                title: i18.Labels.ClientDocsType,
                name: 'clientDocs.0.clientDocsType',
                component: FormFieldType.select,
                options: props.classifikators.DOCS,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-6 col-md-4 text-left text-md-center',
                disabled: true,
                noInline: true,
            },{
                title: i18.Labels.ClientDocNo,
                name: 'clientDocs.0.data.clientDocNo',
                component: FormFieldType.input,
                className: 'col-6 col-md-4 text-left text-md-center',
                disabled: true,
                noInline: true,
            },{
                title: i18.Labels.ClientDocCountry,
                name: 'clientDocs.0.data.clientDocCountry',
                component: FormFieldType.selectSearch,
                options: props.classifikators.CISO,
                optionsLabel: 'label',
                optionsValue: 'value',
                className: 'col-6 col-md-4 text-left text-md-center',
                disabled: true,
                noInline: true,
            },{
                title: i18.Labels.ClientDocIssuePlace,
                name: 'clientDocs.0.data.clientDocIssuePlace',
                component: FormFieldType.input,
                className: 'col-6 col-md-4 text-left text-md-center',
                disabled: true,
                noInline: true,
            },{
                title: i18.Labels.ClientDocIssueDate,
                name: 'clientDocs.0.data.clientDocIssueDate',
                component: FormFieldType.date,
                className: 'col-6 col-md-4 p-md-0 text-left text-md-center',
                disabled: true,
                noInline: true,
            },{
                title: i18.Labels.ClientDocValidDate,
                name: 'clientDocs.0.data.clientDocValidDate',
                component: FormFieldType.date,
                className: 'col-6 col-md-4 p-md-0 text-left text-md-center',
                disabled: true,
                noInline: true,
            }
        ]}
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.PersonDocuments} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};