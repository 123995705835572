import { i18 } from 'src/Utilities';

export const CONFIRMATION_BY_TYPE = {
  SmartId: { title: i18.Labels.LoginWithSmartId, subTitle: i18.Labels.AuthenticationCodeHasBeenSentToYourSmartIdDevice, confirmationInfoText: i18.Labels.SmartIDConfirmationInfo },
  MSignature: {
    title: i18.Labels.LoginWithMobileId,
    subTitle: i18.Labels.AuthenticationCodeHasBeenSentToYourMobileIdDevice,
    confirmationInfoText: i18.Labels.MobileIDConfirmationInfo,
  },
  'idin-customer': { title: i18.Labels.LoginWithIDIN, subTitle: i18.Labels.LoginWithIDINInfoText, confirmationInfoText: i18.Labels.IDINConfirmationInfo },
  'lv-eparaksts': { title: i18.Labels.LoginWithMobileESign, subTitle: i18.Labels.LoginWithMobileESignInfoText, confirmationInfoText: i18.Labels.MobileESignConfirmationInfo },
  BioFace: { title: i18.Labels.LoginWithBiometric, subTitle: i18.Labels.LoginWithBiometricInfoText, confirmationInfoText: i18.Labels.BiometricConfirmationInfo },
};
