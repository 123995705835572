import PropTypes from 'prop-types';

import { i18, required } from '../../../Utilities';
import { FormFieldType } from '../../FormField';

export const FeePaymentForm = (props) => {
  return {
    parts: [
      {
        title: i18.PaymentParts.Payer,
        fields: [
          {
            title: i18.Labels.FromAccount,
            name: 'Payment.InitialAccountID',
            component: FormFieldType.select,
            options: props.InitialAccounts,
            optionsColor: 'color',
            optionsLabel: 'label',
            optionsValue: 'value',
            optionsDisabled: 'disabled',
            validate: required,
          },
        ],
      },
      {
        title: i18.PaymentParts.Contribution,
        fields: [
          {
            title: i18.Labels.FeeProvider,
            name: 'Payment.FeeProvider',
            component: FormFieldType.selectSearch,
            options: props.fees,
            optionsLabel: 'label',
            optionsValue: 'id',
            validate: required,
          },
          {
            title: i18.Labels.PayeeTitle,
            name: 'Payment.Recipient',
            component: FormFieldType.input,
            disabled: true,
            validate: required,
          },
          {
            title: i18.Labels.PayeeAccount,
            name: 'Payment.BeneficiaryAccountNumber',
            component: FormFieldType.input,
            disabled: true,
            validate: required,
          },
          {
            title: i18.Labels.PayeeBank,
            name: 'Payment.PayeeBank',
            component: FormFieldType.input,
            disabled: true,
            validate: required,
          },
        ],
      },
      {
        title: i18.PaymentParts.Contribution,
        module: true,
        moduleComponent: 'FeeModule',
      },
      {
        fields: [
          {
            title: i18.Labels.Amount,
            component: FormFieldType.number,
            name: 'Payment.AmountStr',
            //validate: required,
          },
          {
            name: 'Payment.IsSEPAPaymentTypeSelected',
            component: FormFieldType.checkbox,
            className: 'd-none',
          },
          {
            name: 'Payment.SEPAPaymentType',
            component: FormFieldType.input,
            className: 'd-none',
          },
        ],
      },
    ],
  };
};

FeePaymentForm.propTypes = {
  data: PropTypes.object.isRequired,
};
