import React from 'react';
import { Alert, Button } from 'antd';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';

import { FormField, FormFieldType, Notification, Container } from '../../../../Components';
import { i18, required, translationGroups, trb } from '../../../../Utilities';
import { UACActions } from '../../../../Redux';
import { history, routerPath } from '../../../../Config';

const ButtonGroup = Button.Group;

class SettingsChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      errors: null,
      inline: !window.matchMedia('(max-width: 600px)').matches,
    };

    window.addEventListener('resize', () => {
      this.setState({
        inline: !window.matchMedia('(max-width: 600px)').matches,
      });
    });

    this.submit = this.submit.bind(this);
  }

  submit = async (values) => {
    this.setState({
      submitting: true,
      errors: null,
    });

    await this.props.dispatch(UACActions.postCPRQ(values)).then((response) => {
      this.setState({
        submitting: false,
      });

      if (response.payload && response.payload.IsSuccessfull) {
        Notification({
          type: 'success',
          message: i18.NotificationTitles.PasswordChanged,
          description: i18.NotificationMessages.PasswordChangedSuccessfully,
        });
        // FIXME: use navigate
        history.push(routerPath.index);
      } else {
        this.setState({ errors: response.payload.ErrorCaptions });
      }
    });
  };

  render() {
    return (
      <>
        <div className="eb_content_subtitle">{trb(i18.ContentTitles.SettingsChangePassword)}</div>
        <Container>
          <Form
            onSubmit={this.submit}
            validate={(values) => {
              const errors = {},
                regex = new RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])');

              if (!values.NewPassword1) {
                return null;
              }

              if (/\s/.test(values.NewPassword1)) {
                errors.NewPassword1 = trb(i18.Validations.PasswordCantContainSpaces);
              }

              if (values.NewPassword1.length < 8) {
                errors.NewPassword1 = trb(i18.Validations.PasswordTooShort);
              }

              if (values.NewPassword1.length > 16) {
                errors.NewPassword1 = trb(i18.Validations.PasswordTooLong);
              }

              if (!regex.test(values.NewPassword1)) {
                errors.NewPassword1 = trb(i18.Validations.PasswordMustContainUpperLowerNumber);
              }

              if (!errors.NewPassword1 && values.NewPassword1 !== values.NewPassword2) {
                errors.NewPassword2 = trb(i18.Validations.PasswordNotSame);
              }
              return errors;
            }}
            render={({ handleSubmit, form, valid }) => {
              return (
                <form
                  id="ChangePasswordForm"
                  className="mb-3 mt-3"
                  onSubmit={(event) => {
                    valid ? handleSubmit(event).then(() => form.reset()) : handleSubmit(event);
                  }}
                  style={{
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '100%',
                    maxWidth: '600px',
                  }}
                >
                  <div className="form-part">
                    <FormField
                      name="OldPassword"
                      title={i18.Labels.PasswordCurrent}
                      component={FormFieldType.inputPassword}
                      inputType="password"
                      validate={required}
                    />
                    <FormField
                      name="NewPassword1"
                      title={i18.Labels.PasswordNew}
                      component={FormFieldType.inputPassword}
                      inputType="password"
                      validate={required}
                      hightlight
                    />
                    <FormField
                      name="NewPassword2"
                      title={i18.Labels.PasswordNewRepeat}
                      component={FormFieldType.inputPassword}
                      inputType="password"
                      validate={required}
                      hightlight
                    />
                  </div>
                  {this.state.errors && (
                    <Alert
                      className="mb-3"
                      message={trb(i18.ErrorTitles.PasswordChangeError)}
                      description={
                        <ul>
                          {this.state.errors.map((item, index) => (
                            <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
                          ))}
                        </ul>
                      }
                      type="error"
                    />
                  )}
                  <div className="mb-3 text-left">
                    <ButtonGroup>
                      <Button
                        id="ChangePasswordSubmitButton"
                        htmlType="submit"
                        type="primary"
                        loading={this.state.submitting}
                      >
                        {trb(i18.Buttons.ChangePassword)}
                      </Button>
                    </ButtonGroup>
                  </div>
                </form>
              );
            }}
          />
        </Container>
      </>
    );
  }
}

const connectedSettingsChangePassword = connect(null)(SettingsChangePassword);
export { connectedSettingsChangePassword as SettingsChangePassword };
