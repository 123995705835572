import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { routerPath } from 'src/Config';
import { UACActiveUser } from 'src/Redux';
import { i18, trb } from 'src/Utilities';
import { Button } from 'src/shared';

export const NewBeneficiaryButton = () => {
  const IsAdmin = useSelector(UACActiveUser)?.IsAdmin;
  const navigate = useNavigate();

  return (
    <>
      {IsAdmin && (
        <div className="text-right d-block col-12 px-0">
          <Button
            id="DepositsSignButton"
            onClick={() => navigate(`${routerPath.payments}/${routerPath.beneficiaries}/${routerPath.beneficiariesNew}`)}
            type="primary"
            htmlType="button"
            className="w-100 w-sm-auto"
          >
            {trb(i18.Buttons.AddNewBeneficiary)}
          </Button>
        </div>
      )}
    </>
  );
};

