import { Select as SelectANTD, SelectProps } from 'antd';
import { useField } from 'formik';

interface IProps extends SelectProps {
  name?: string;
  handleChange?: (value: string, option: any) => void;
}

export const Select = ({ name = '', handleChange, ...restProps }: IProps) => {
  const [field, _meta, helpers] = useField(name);

  return (
    <SelectANTD
      showSearch
      dropdownAlign={{
        overflow: { adjustY: 0 },
      }}
      filterOption={(input, option) => (typeof option?.label === 'string' ? option.label.toLowerCase() : option?.labelText?.toLowerCase() ?? '').includes(input.toLowerCase())}
      onChange={async (value, option) => {
        await helpers.setValue(value);

        if (typeof handleChange === 'function') {
          handleChange(value, option);
        }
      }}
      style={{ width: '100%' }}
      value={field.value}
      {...restProps}
    />
  );
};
