import React, {Component} from 'react';
import {connect} from "react-redux";
import {ContentLoader} from "../../../Components/ContentLoader";
import {ContentNoData} from "../../../Components/ContentNoData";
import {DepositsPreview} from "./DepositsPreview";

class DepositsPreviewContainer extends Component {
    render() {
        const {SCR, sendSCR, formValues} = this.props;

        if (sendSCR) {
            return <ContentLoader/>
        }
        if (!SCR) {
            return <ContentNoData id="DepositsPreview" />
        }

        return <DepositsPreview data={formValues}/>;
    }
}

function mapStateToProps(state) {
    const {SCR, sendSCR} = state.CMCReducers;
    return {
        SCR,
        sendSCR,
    };
}

const connectedDepositsPreviewContainer = connect(mapStateToProps)(DepositsPreviewContainer);
export {connectedDepositsPreviewContainer as DepositsPreviewContainer};