import { Input as InputANTD, InputProps } from 'antd';
import { useField } from 'formik';

interface IProps extends InputProps {
  name?: string;
  handleChange?: (value: string) => void;
  handleBlur?: (value: string) => void;
  handleFocus?: (value: string) => void;
}

export const Input = ({ name = '', handleChange, handleBlur, handleFocus, onBlur, onFocus, ...restProps }: IProps) => {
  const [field, _meta, helpers] = useField(name);

  return (
    <InputANTD
      value={field.value}
      onChange={async (e) => {
        await helpers.setValue(e.target.value);

        if (typeof handleChange === 'function') {
          handleChange(e.target.value);
        }
      }}
      onBlur={async (e) => {
        if (typeof onBlur === 'function') {
          onBlur(e);
        }

        if (typeof handleBlur === 'function') {
          handleBlur(e.target.value);
        }
      }}
      onFocus={async (e) => {
        if (typeof onFocus === 'function') {
          onFocus(e);
        }

        if (typeof handleFocus === 'function') {
          handleFocus(e.target.value);
        }
      }}
      {...restProps}
    />
  );
};
