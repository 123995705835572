import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from 'antd';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

import { ChatInput } from 'src/Components';
import { CustomIcon, requiredDraftJSField } from 'src/Utilities';

interface IChatData {
  message: string;
}

interface IProps {
  submit: (value: IChatData) => void;
}

export const NewChatMessage = ({ submit }: IProps) => {
  const [initialValues] = useState({
    chatInput: EditorState.createEmpty(),
  });

  const resetForm = (reset: any, resetFieldState: any) => {
    reset();
    resetFieldState('chatInput');
  };

  const submitForm = (values: { chatInput: any }, { resetFieldState, reset }: { resetFieldState: any; reset: any }) => {
    const contentState = values.chatInput.getCurrentContent();

    if (contentState.hasText()) {
      const htmlString = stateToHTML(contentState);
      submit({ message: htmlString });
    }
    resetForm(reset, resetFieldState);
  };

  return (
    <div className="form">
      <Form
        onSubmit={submitForm}
        initialValues={initialValues}
        render={({ handleSubmit, submitting, form: { reset, resetFieldState } }) => {
          return (
            <form
              className="mb-3"
              onSubmit={handleSubmit}
              id="MessagesDrawerNewMessageForm"
            >
              <div className="form-part">
                <Field
                  name="chatInput"
                  validate={requiredDraftJSField}
                >
                  {({ input, meta }) => (
                    <ChatInput
                      editorState={input.value}
                      setEditorState={(state: any) => input.onChange(state)}
                      meta={meta}
                      name="chatInput"
                    />
                  )}
                </Field>
              </div>
              <div className="new-message-wrapper mb-3">
                <Button
                  id="MessagesDrawerNewMessageSubmitButton"
                  className="mr-3 submit"
                  size="large"
                  shape="circle"
                  htmlType="submit"
                  disabled={submitting}
                >
                  <CustomIcon
                    type="custom"
                    icon="submit"
                  />
                </Button>
                <Button
                  id="MessagesDrawerNewMessageDeleteButton"
                  shape="circle"
                  size="large"
                  onClick={() => {
                    resetForm(reset, resetFieldState);
                  }}
                  type="link"
                >
                  <CustomIcon
                    type="custom"
                    icon="bin"
                  />
                </Button>
              </div>
            </form>
          );
        }}
      />
    </div>
  );
};
