import { Radio, RadioGroupProps } from 'antd';
import { useField } from 'formik';
import { formFieldId } from 'src/utils';

interface IProps extends RadioGroupProps {
  name?: string;
  placeholder?: string;
  handleChange?: (value: any) => void;
  options: {
    label: string;
    value: string | boolean;
    disabled?: boolean;
  }[];
}

export const ToggleButton = ({ name = '', handleChange, options = [], ...restProps }: IProps) => {
  const [field, _meta, helpers] = useField(name);

  return (
    <Radio.Group
      value={field.value}
      onChange={async (e) => {
        await helpers.setValue(e.target.value);

        if (typeof handleChange === 'function') {
          handleChange(e.target.value);
        }
      }}
      style={{ width: '100%' }}
      optionType="button"
      {...restProps}
    >
      {options.map((item, index, array) => (
        <Radio.Button
          key={index}
          style={{ width: 100 / array.length + '%', textAlign: 'center' }}
          value={item.value}
          disabled={item.disabled ?? false}
          id={formFieldId(`${name}_${item.value}`, 'RadioOption')}
        >
          {item.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};
