import React, {Component} from 'react';
import {connect} from 'react-redux';

import {PrintActions} from '../../Redux';

class PrintBrowser extends Component {
    componentDidUpdate() {
        if (this.props.content) {
            // window.print();
            // this.props.dispatch(PrintActions.setContent(null));
            setTimeout(()=> {
                window.print();
                if (!/safari/.test(navigator.userAgent.toLowerCase())) this.props.dispatch(PrintActions.setContent(null));
            }, 250);
        }
    }

    render() {
        if (!this.props.content) {
            return null;
        }

        return (
            <div className="eb_print_block">{this.props.content}</div>
        );
    }
}

function mapStateToProps(state) {
    const {show, content} = state.printReducer;
    return {
        show,
        content
    };
}

const connectedPrintBrowser = connect(mapStateToProps)(PrintBrowser);
export {connectedPrintBrowser as PrintBrowser};
