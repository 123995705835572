import { MessagingServices } from './services'
import { MessagingConstants } from './constants'

export const MessagingActions = {
  postCreate,
  resetMessageCreate,
  postResponse,
  getChats,
  getChatMessages,
  getNewMessagesCount,
  deleteMessage,
  getBroadCastList,
  getBroadCastMessage,
  deleteBroadCastMessage
}

function postCreate(data) {
  return dispatch => {
    dispatch(request())

    return MessagingServices.postCreate(data).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.POST_MESSAGING_CREATE_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.POST_MESSAGING_CREATE_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return { type: MessagingConstants.POST_MESSAGING_CREATE_ERROR, error }
  }
}

function resetMessageCreate() {
  return { type: MessagingConstants.RESET_MESSAGING_CREATE }
}

function postResponse(data) {
  return dispatch => {
    dispatch(request())

    return MessagingServices.postResponse(data).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.POST_MESSAGING_RESPONSE_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.POST_MESSAGING_RESPONSE_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return { type: MessagingConstants.POST_MESSAGING_RESPONSE_ERROR, error }
  }
}

function getChats() {
  return dispatch => {
    dispatch(request())

    return MessagingServices.getChats().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.GET_MESSAGING_CHATS_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.GET_MESSAGING_CHATS_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return { type: MessagingConstants.GET_MESSAGING_CHATS_ERROR, error }
  }
}

function getChatMessages(chatId) {
  return dispatch => {
    dispatch(request())

    return MessagingServices.getChatMessages(chatId).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.GET_MESSAGING_CHAT_MESSAGES_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.GET_MESSAGING_CHAT_MESSAGES_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return { type: MessagingConstants.GET_MESSAGING_CHAT_MESSAGES_ERROR, error }
  }
}

function getNewMessagesCount() {
  return dispatch => {
    dispatch(request())

    return MessagingServices.getNewMessagesCount().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.GET_MESSAGING_NEW_MESSAGES_COUNT_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.GET_MESSAGING_NEW_MESSAGES_COUNT_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return {
      type: MessagingConstants.GET_MESSAGING_NEW_MESSAGES_COUNT_ERROR,
      error
    }
  }
}

function deleteMessage(data) {
  return dispatch => {
    dispatch(request())

    return MessagingServices.deleteMessage(data).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.DELETE_MESSAGING_MESSAGE_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.DELETE_MESSAGING_MESSAGE_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return { type: MessagingConstants.DELETE_MESSAGING_MESSAGE_ERROR, error }
  }
}

function getBroadCastList() {
  return dispatch => {
    dispatch(request())

    return MessagingServices.getBroadCastList().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.GET_MESSAGING_BROADCAST_LIST_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.GET_MESSAGING_BROADCAST_LIST_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return {
      type: MessagingConstants.GET_MESSAGING_BROADCAST_LIST_ERROR,
      error
    }
  }
}

function getBroadCastMessage(id) {
  return dispatch => {
    dispatch(request())

    return MessagingServices.getBroadCastMessage(id).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return { type: MessagingConstants.GET_MESSAGING_BROADCAST_MESSAGE_REQUEST }
  }

  function success(data) {
    return {
      type: MessagingConstants.GET_MESSAGING_BROADCAST_MESSAGE_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return {
      type: MessagingConstants.GET_MESSAGING_BROADCAST_MESSAGE_ERROR,
      error
    }
  }
}

function deleteBroadCastMessage(id) {
  return dispatch => {
    dispatch(request())

    return MessagingServices.deleteBroadCastMessage(id).then(
      data => dispatch(success(data)),
      error => dispatch(failure(error))
    )
  }

  function request() {
    return {
      type: MessagingConstants.DELETE_MESSAGING_BROADCAST_MESSAGE_REQUEST
    }
  }

  function success(data) {
    return {
      type: MessagingConstants.DELETE_MESSAGING_BROADCAST_MESSAGE_SUCCESS,
      payload: data
    }
  }

  function failure(error) {
    return {
      type: MessagingConstants.DELETE_MESSAGING_BROADCAST_MESSAGE_ERROR,
      error
    }
  }
}
