export const translationGroups = {
  ContractStatus: 'ContractStatus.',
  PaymentSystemTypes: 'PaymentSystemTypes.',
  LoginErrors: 'LoginErrors.',
  AccountPerrmissions: 'AccountPerrmissions.',
  AccountDetails: 'AccountDetails.',
  AccountTypeCode: 'AccountTypeCode.',
  Buttons: 'Buttons.',
  Container: 'Container.',
  ContentMenu: 'ContentMenu.',
  ContentSubTitles: 'ContentSubTitles.',
  ContentTitles: 'ContentTitles.',
  Chat: 'Chat.',
  DepositRenew: 'DepositRenew.',
  DepositTypes: 'DepositTypes.',
  InterestPaymentTypes: 'InterestPaymentTypes.',
  ErrorDescriptions: 'ErrorDescriptions.',
  ErrorTitles: 'ErrorTitles.',
  ErrorCode: 'ErrorCode.',
  eServices: 'eServices.',
  Labels: 'Labels.',
  Menu: 'Menu.',
  MessageTitles: 'MessageTitles.',
  Messages: 'Messages.',
  ModalTitles: 'ModalTitles.',
  NotificationMessages: 'NotificationMessages.',
  NotificationTitles: 'NotificationTitles.',
  PayerPayeeTypes: 'PayerPayeeTypes.',
  PaymentParts: 'PaymentParts.',
  PaymentPurposeTypes: 'PaymentPurposeTypes.',
  PaymentStatuses: 'PaymentStatuses.',
  PaymentTypes: 'PaymentTypes.',
  PlaceHolders: 'PlaceHolders.',
  Period: 'Period.',
  PopConfirms: 'PopConfirms.',
  PrintTitles: 'PrintTitles.',
  PrintLabels: 'PrintLabels.',
  Settings: 'Settings.',
  Steps: 'Steps.',
  Tooltips: 'Tooltips.',
  Validations: 'Validations.',
  PaymentTypeSepa: 'PaymentTypeSepa.',
  StatusCode: 'StatusCode.',
  AccountType: 'AccountType.',
  PaymensValidations: 'PaymensValidations.',
  PaymentUpload: 'PaymentUpload.',
  HTML: 'HTML.',
  CreditType: 'CreditType.',
  ANTDPagination: 'ANTDPagination.',
  ANTDEmpty: 'ANTDEmpty.',
  OperationStatusCode: 'OperationStatusCode.',
  Countries: 'Countries.',
  Enums: 'Enums.',
  IdentificationType: 'IdentificationType.',
  SEPAPaymentTypes: 'SEPAPaymentTypes.',
};

export const i18 = {
  LoginErrors: {
    Canceled: translationGroups.LoginErrors + 'Canceled',
  },
  HTML: {
    IdentificationSuccess: translationGroups.HTML + 'IdentificationSuccess',
    IdentificationError: translationGroups.HTML + 'IdentificationError',
  },
  AccountPerrmissions: {
    None: translationGroups.AccountPerrmissions + 'None',
    Preview: translationGroups.AccountPerrmissions + 'Preview',
    Approve: translationGroups.AccountPerrmissions + 'Approve',
    Create: translationGroups.AccountPerrmissions + 'Create',
  },
  Buttons: {
    IdentificationSuccess: translationGroups.Buttons + 'IdentificationSuccess',
    IdentificationError: translationGroups.Buttons + 'IdentificationError',
    ApproveINSTPayment: translationGroups.Buttons + 'ApproveINSTPayment',
    Approve: translationGroups.Buttons + 'Approve',
    ApproveUploadedPayments: translationGroups.Buttons + 'ApproveUploadedPayments',
    Agree: translationGroups.Buttons + 'Agree',
    AgreeWithAll: translationGroups.Buttons + 'AgreeWithAll',
    ChangeSettings: translationGroups.Buttons + 'ChangeSettings',
    BlockCard: translationGroups.Buttons + 'BlockCard',
    Cancel: translationGroups.Buttons + 'Cancel',
    ChangeAccountLimits: translationGroups.Buttons + 'ChangeAccountLimits',
    ChangeAccountLimitsSave: translationGroups.Buttons + 'ChangeAccountLimitsSave',
    ChangeAccountPermissions: translationGroups.Buttons + 'ChangeAccountPermissions',
    ChangeAccountPermissionsSave: translationGroups.Buttons + 'ChangeAccountPermissionsSave',
    ChangePassword: translationGroups.Buttons + 'ChangePassword',
    Close: translationGroups.Buttons + 'Close',
    InoviceFilterDropdown: translationGroups.Buttons + 'InoviceFilterDropdown',
    Open: translationGroups.Buttons + 'Open',
    ContinueWork: translationGroups.Buttons + 'ContinueWork',
    Create: translationGroups.Buttons + 'Create',
    CreatePaymentTemplate: translationGroups.Buttons + 'CreatePaymentTemplate',
    Download: translationGroups.Buttons + 'Download',
    DownloadXML: translationGroups.Buttons + 'DownloadXML',
    DownloadExcel: translationGroups.Buttons + 'DownloadExcel',
    DownloadPDF: translationGroups.Buttons + 'DownloadPDF',
    DeleteSelectedPayments: translationGroups.Buttons + 'DeleteSelectedPayments',
    Edit: translationGroups.Buttons + 'Edit',
    Filter: translationGroups.Buttons + 'Filter',
    GetPassword: translationGroups.Buttons + 'GetPassword',
    Include: translationGroups.Buttons + 'Include',
    Logout: translationGroups.Buttons + 'Logout',
    NewPayment: translationGroups.Buttons + 'NewPayment',
    NewPaymentInstruction: translationGroups.Buttons + 'NewPaymentInstruction',
    PlaceHolders: translationGroups.Buttons + 'PlaceHolders',
    Preview: translationGroups.Buttons + 'Preview',
    PreviewAndPrint: translationGroups.Buttons + 'PreviewAndPrint',
    Print: translationGroups.Buttons + 'Print',
    Resend: translationGroups.Buttons + 'Resend',
    Reset: translationGroups.Buttons + 'Reset',
    SaveTemplate: translationGroups.Buttons + 'SaveTemplate',
    Search: translationGroups.Buttons + 'Search',
    Sign: translationGroups.Buttons + 'Sign',
    SignSelectedPayments: translationGroups.Buttons + 'SignSelectedPayments',
    SignIn: translationGroups.Buttons + 'SignIn',
    Submit: translationGroups.Buttons + 'Submit',
    SubmitTemplates: translationGroups.Buttons + 'SubmitTemplates',
    SaveToSign: translationGroups.Buttons + 'SaveToSign',
    SafeOperation: translationGroups.Buttons + 'SafeOperation',
    UpdateFile: translationGroups.Buttons + 'UpdateFile',
    Yes: translationGroups.Buttons + 'Yes',
    NextStep: translationGroups.Buttons + 'NextStep',
    PrevStep: translationGroups.Buttons + 'PrevStep',
    DuplicateBlocks: translationGroups.Buttons + 'DuplicateBlocks',
    RemoveBlocks: translationGroups.Buttons + 'RemoveBlocks',
    UpdateKyc: translationGroups.Buttons + 'UpdateKyc',
    Schedule: translationGroups.Buttons + 'Schedule',
    GoToChangePassword: translationGroups.Buttons + 'GoToChangePassword',
    Logs: translationGroups.Buttons + 'Logs',
    DepositTermination: translationGroups.Buttons + 'DepositTermination',
    DepositTerminationOk: translationGroups.Buttons + 'DepositTerminationOk',
    DepositTerminationCancel: translationGroups.Buttons + 'DepositTerminationCancel',
    SignOneInstPayment: translationGroups.Buttons + 'SignOneInstPayment',
    LoginPageSignUp: translationGroups.Buttons + 'LoginPageSignUp',
    ReviewAndSign: translationGroups.Buttons + 'ReviewAndSign',
    NewMessageApprove: translationGroups.Buttons + 'NewMessageApprove',
    AddNewBeneficiary: translationGroups.Buttons + 'AddNewBeneficiary',
    DeleteSelectedBeneficiaries: translationGroups.Buttons + 'DeleteSelectedBeneficiaries',
  },
  Container: {
    SummaryContainer: translationGroups.Container + 'SummaryContainer',
    AccountsContainer: translationGroups.Container + 'AccountsContainer',
    InvoiceContainer: translationGroups.Container + 'InvoiceContainer',
    PaymentsContainer: translationGroups.Container + 'PaymentsContainer',
    OperationsContainer: translationGroups.Container + 'OperationsContainer',
    DepositContainer: translationGroups.Container + 'DepositContainer',
    SettingsContainer: translationGroups.Container + 'SettingsContainer',
    InvoiceChooseAccount: translationGroups.Container + 'InvoiceChooseAccount',
  },
  ContentMenu: {
    AccountsLimits: translationGroups.Settings + 'AccountsLimits',
    AccountsManagement: translationGroups.Settings + 'AccountsManagement',
    BlockCard: translationGroups.Settings + 'BlockCard',
    ChangePassword: translationGroups.Settings + 'ChangePassword',
    GroupPayment: translationGroups.ContentMenu + 'GroupPayment',
    Group: translationGroups.ContentMenu + 'Group',
    NoSignetPayment: translationGroups.ContentMenu + 'NoSignetPayment',
    PaymentGroups: translationGroups.ContentMenu + 'PaymentGroups',
    PaymentTemplates: translationGroups.ContentMenu + 'PaymentTemplates',
    PaymentUploading: translationGroups.ContentMenu + 'PaymentUploading',
    KYCRenew: translationGroups.ContentMenu + 'KYCRenew',
    InternationalPayment: translationGroups.ContentMenu + 'InternationalPayment',
    ForexExchange: translationGroups.ContentMenu + 'ForexExchange',
    Beneficiaries: translationGroups.ContentMenu + 'Beneficiaries',
  },
  ContentSubTitles: {
    AccountsAndPermissions: translationGroups.ContentSubTitles + 'AccountsAndPermissions',
    GroupPaymentAddCreate: translationGroups.ContentSubTitles + 'GroupPaymentAddCreate',
    SMSLimits: translationGroups.ContentSubTitles + 'SMSLimits',
    MSignLimits: translationGroups.ContentSubTitles + 'MSignLimits',
    MainInformacion: translationGroups.ContentSubTitles + 'MainInformacion',
    PersonDocuments: translationGroups.ContentSubTitles + 'PersonDocuments',
    ContactInformacion: translationGroups.ContentSubTitles + 'ContactInformacion',
    TaxInformation: translationGroups.ContentSubTitles + 'TaxInformation',
    Income: translationGroups.ContentSubTitles + 'Income',
    PepLinks: translationGroups.ContentSubTitles + 'PepLinks',
    OtherInformation: translationGroups.ContentSubTitles + 'OtherInformation',
    AccountsPermissions: translationGroups.ContentSubTitles + 'AccountsPermissions',
  },
  ContentTitles: {
    IdentificationError: translationGroups.ContentTitles + 'IdentificationError',
    IdentificationSuccess: translationGroups.ContentTitles + 'IdentificationSuccess',
    FeePayment: translationGroups.ContentTitles + 'FeePayment',
    GroupPayment: translationGroups.ContentTitles + 'GroupPayment',
    NewPayment: translationGroups.ContentTitles + 'NewPayment',
    OwnAccounts: translationGroups.ContentTitles + 'OwnAccounts',
    Payment: translationGroups.ContentTitles + 'Payment',
    SettingsAccountsLimits: translationGroups.ContentTitles + 'SettingsAccountsLimits',
    SettingsAccountsManagement: translationGroups.ContentTitles + 'SettingsAccountsManagement',
    SettingsBlockCard: translationGroups.ContentTitles + 'SettingsBlockCard',
    SettingsChangePassword: translationGroups.ContentTitles + 'SettingsChangePassword',
    KYCRenew: translationGroups.ContentTitles + 'KYCRenew',
    NewDeposit: translationGroups.ContentTitles + 'SettingsContainer',
    MessagesNew: translationGroups.ContentTitles + 'MessagesNew',
    MessagesInbox: translationGroups.ContentTitles + 'MessagesInbox',
    MessagesBroadCast: translationGroups.ContentTitles + 'MessagesBroadCast',
    InternationalPayment: translationGroups.ContentTitles + 'InternationalPayment',
    ForexExchange: translationGroups.ContentTitles + 'ForexExchange',
    UnsignedPayments: translationGroups.ContentTitles + 'UnsignedPayments',
    UnsignedPaymentFiles: translationGroups.ContentTitles + 'UnsignedPaymentFiles',
  },
  Chat: {
    New: translationGroups.Chat + 'New',
    Inbox: translationGroups.Chat + 'Inbox',
    Send: translationGroups.Chat + 'Send',
    BroadCast: translationGroups.Chat + 'BroadCast',
    ChatContainer: translationGroups.Chat + 'ChatContainer',
    Subject: translationGroups.Chat + 'Subject',
    DateCreated: translationGroups.Chat + 'DateCreated',
    LastMessage: translationGroups.Chat + 'LastMessage',
    NewMessage: translationGroups.Chat + 'NewMessage',
    MessageText: translationGroups.Chat + 'MessageText',
    MessageSubject: translationGroups.Chat + 'MessageSubject',
  },
  DepositRenew: {
    WithInterest: translationGroups.DepositRenew + 'WithInterest',
    WithoutInterest: translationGroups.DepositRenew + 'WithoutInterest',
    NotApplicable: translationGroups.DepositRenew + 'NotApplicable',
  },
  DepositTypes: {
    TermDeposit: translationGroups.DepositTypes + 'TermDeposit',
    DepositAdditionalInformation1: translationGroups.DepositTypes + 'DepositAdditionalInformation1',
    DepositAdditionalInformation2: translationGroups.DepositTypes + 'DepositAdditionalInformation2',
    DepositAdditionalInformation3: translationGroups.DepositTypes + 'DepositAdditionalInformation3',
    DepositAdditionalInformation4: translationGroups.DepositTypes + 'DepositAdditionalInformation4',
    DepositAdditionalInformation5: translationGroups.DepositTypes + 'DepositAdditionalInformation5',
  },
  ErrorDescriptions: {
    RemainingAttempts: translationGroups.ErrorDescriptions + 'RemainingAttempts',
    CodeTimeout: translationGroups.ErrorDescriptions + 'CodeTimeout',
    GroupPaymentsListEmpty: translationGroups.ErrorDescriptions + 'GroupPaymentsListEmpty',
    NeedChangePassword: translationGroups.ErrorDescriptions + 'NeedChangePassword',
    OperationApprovalMobileNoCode: translationGroups.ErrorTitles + 'OperationApprovalMobileNoCode',
    CanNotUsedOverdraft: translationGroups.ErrorDescriptions + 'CanNotUsedOverdraft',
    ForexExchangeIsNotValid: translationGroups.ErrorDescriptions + 'ForexExchangeIsNotValid',
    CreditorAccountIBANInvalid: translationGroups.ErrorDescriptions + 'CreditorAccountIBANInvalid',
  },
  ErrorTitles: {
    AccountsLimitsChangeError: translationGroups.ErrorTitles + 'AccountsLimitsChangeError',
    CardBlockError: translationGroups.ErrorTitles + 'CardBlockError',
    GroupPayment: translationGroups.ErrorTitles + 'GroupPayment',
    PasswordChangeError: translationGroups.ErrorTitles + 'PasswordChangeError',
    PaymentErrors: translationGroups.ErrorTitles + 'PaymentErrors',
    SaveTemplateErrors: translationGroups.ErrorTitles + 'SaveTemplateErrors',
    Warning: translationGroups.ErrorTitles + 'Warning',
    NeedChangePassword: translationGroups.ErrorTitles + 'NeedChangePassword',
    OperationApprovalMobileNoCode: translationGroups.ErrorTitles + 'OperationApprovalMobileNoCode',
    PaymentValidationError: translationGroups.ErrorTitles + 'PaymentValidationError',
    PMC10000: translationGroups.ErrorTitles + 'PMC10000',
    FailedGetUserLimits: translationGroups.ErrorTitles + 'FailedGetUserLimits',
  },
  eServices: {
    eGates: translationGroups.eServices + 'eGates',
  },
  Labels: {
    LoginResendTimer: translationGroups.Labels + 'LoginResendTimer',
    LoginBlocked: translationGroups.Labels + 'LoginBlocked',
    LoginBlockedTimer: translationGroups.Labels + 'LoginBlockedTimer',
    LoginBiometricSMS: translationGroups.Labels + 'LoginBiometricSMS',
    LoginBiometricSMSSend: translationGroups.Labels + 'LoginBiometricSMSSend',
    LoginBiometricSMSError: translationGroups.Labels + 'LoginBiometricSMSError',
    Contacts: translationGroups.Labels + 'Contacts',
    PaymentSystem: translationGroups.Labels + 'PaymentSystem',
    SignDeposit: translationGroups.Labels + 'SignDeposit',
    depositStartDate: translationGroups.Labels + 'depositStartDate',
    CookiesText: translationGroups.Labels + 'CookiesText',
    MustCookies: translationGroups.Labels + 'MustCookies',
    OtherCookies: translationGroups.Labels + 'OtherCookies',
    CookiesSettings: translationGroups.Labels + 'CookiesSettings',
    PrivacyOverviewText: translationGroups.Labels + 'PrivacyOverviewText',
    Suggestions: translationGroups.Labels + 'Suggestions',
    depositValidTill: translationGroups.Labels + 'depositValidTill',
    depositInterest: translationGroups.Labels + 'depositInterest',
    depositRenew: translationGroups.Labels + 'depositRenew',
    depositAccruedInterest: translationGroups.Labels + 'depositAccruedInterest',
    delayDays: translationGroups.Labels + 'delayDays',
    contractDate: translationGroups.Labels + 'contractDate',
    validTill: translationGroups.Labels + 'validTill',
    contractValidTill: translationGroups.Labels + 'contractValidTill',
    interestPercent: translationGroups.Labels + 'interestPercent',
    autoRenew: translationGroups.Labels + 'autoRenew',
    delayInterest: translationGroups.Labels + 'delayInterest',
    contractAmount: translationGroups.Labels + 'contractAmount',
    totalLoan: translationGroups.Labels + 'totalLoan',
    interestPercentenge: translationGroups.Labels + 'interestPercentenge',
    delayLoanFine: translationGroups.Labels + 'delayLoanFine',
    delayLoanPrincipal: translationGroups.Labels + 'delayLoanPrincipal',
    delayTotal: translationGroups.Labels + 'delayTotal',
    deayInterest: translationGroups.Labels + 'deayInterest',
    Account: translationGroups.Labels + 'Account',
    DebSumTotal: translationGroups.Labels + 'DebSumTotal',
    PaymentSubmitModal: translationGroups.Labels + 'PaymentSubmitModal',
    PaymentSubmitModalInstant: translationGroups.Labels + 'PaymentSubmitModalInstant',
    PaymentsCount: translationGroups.Labels + 'PaymentsCount',
    PaymentsTotalCommFee: translationGroups.Labels + 'PaymentsTotalCommFee',
    PaymentsTotalAmount: translationGroups.Labels + 'PaymentsTotalAmount',
    AccountNumber: translationGroups.Labels + 'AccountNumber',
    Actions: translationGroups.Labels + 'Actions',
    AdditionalInformation: translationGroups.Labels + 'AdditionalInformation',
    AdditionalPaymentInformation: translationGroups.Labels + 'AdditionalPaymentInformation',
    AgreeWithTerms: translationGroups.Labels + 'AgreeWithTerms',
    Amount: translationGroups.Labels + 'Amount',
    AutoRenewDeposit: translationGroups.Labels + 'AutoRenewDeposit',
    BankText: translationGroups.Labels + 'BankText',
    NoBankText: translationGroups.Labels + 'NoBankText',
    BackToGroupList: translationGroups.Labels + 'BackToGroupList',
    BlocksPayment: translationGroups.Labels + 'BlocksPayment',
    ChoosePersonOrCompany: translationGroups.Labels + 'ChoosePersonOrCompany',
    CreditCardToBlock: translationGroups.Labels + 'CreditCardToBlock',
    Currency: translationGroups.Labels + 'Currency',
    ControlCode: translationGroups.Labels + 'ControlCode',
    Commission: translationGroups.Labels + 'Commission',
    Date: translationGroups.Labels + 'Date',
    SelectAll: translationGroups.Labels + 'SelectAll',
    DateFrom: translationGroups.Labels + 'DateFrom',
    DateTo: translationGroups.Labels + 'DateTo',
    DepositType: translationGroups.Labels + 'DepositType',
    DestinationsCode: translationGroups.Labels + 'DestinationsCode',
    ExistingGroup: translationGroups.Labels + 'ExistingGroup',
    ExpenditureRevenue: translationGroups.Labels + 'ExpenditureRevenue',
    FirstLogin: translationGroups.Labels + 'FirstLogin',
    RememberMe: translationGroups.Labels + 'RememberMe',
    LoginIdentity: translationGroups.Labels + 'LoginIdentity',
    Fee: translationGroups.Labels + 'Fee',
    FeeCode: translationGroups.Labels + 'FeeCode',
    FeeCurrency: translationGroups.Labels + 'FeeCurrency',
    FeeProvider: translationGroups.Labels + 'FeeProvider',
    FromAccount: translationGroups.Labels + 'FromAccount',
    FinalPayeeTitle: translationGroups.Labels + 'FinalPayeeTitle',
    FinalPayeeType: translationGroups.Labels + 'FinalPayeeType',
    FinalPayeeName: translationGroups.Labels + 'FinalPayeeName',
    FinalPayeeIdentTypeCode: translationGroups.Labels + 'FinalPayeeIdentTypeCode',
    FinalPayeeIdentTypeValue: translationGroups.Labels + 'FinalPayeeIdentTypeValue',
    IdentificationCode: translationGroups.Labels + 'IdentificationCode',
    IdentificationSMSCode: translationGroups.Labels + 'IdentificationSMSCode',
    IdentificationType: translationGroups.Labels + 'IdentificationType',
    IdentificationUID: translationGroups.Labels + 'IdentificationUID',
    Information: translationGroups.Labels + 'Information',
    Interest: translationGroups.Labels + 'Interest',
    InterestPay: translationGroups.Labels + 'InterestPay',
    InitPayerCode: translationGroups.Labels + 'InitPayerCode',
    InitPayerType: translationGroups.Labels + 'InitPayerType',
    InitPayerTitle: translationGroups.Labels + 'InitPayerTitle',
    InitPayerIdentTypeCode: translationGroups.Labels + 'InitPayerIdentTypeCode',
    InitPayerIdentTypeValue: translationGroups.Labels + 'InitPayerIdentTypeValue',
    Loading: translationGroups.Labels + 'Loading',
    OperationLimit: translationGroups.Labels + 'OperationLimit',
    OperationDayLimit: translationGroups.Labels + 'OperationDayLimit',
    OperationMonthLimit: translationGroups.Labels + 'OperationMonthLimit',
    Payer: translationGroups.Labels + 'Payer',
    PayerAccountNumber: translationGroups.Labels + 'PayerAccountNumber',
    PayerAddress: translationGroups.Labels + 'PayerAddress',
    PayerBank: translationGroups.Labels + 'PayerBank',
    PayerIdentityCode: translationGroups.Labels + 'PayerIdentityCode',
    PayerIdentityType: translationGroups.Labels + 'PayerIdentityType',
    PayerPayeePurpose: translationGroups.Labels + 'PayerPayeePurpose',
    PayerTitle: translationGroups.Labels + 'PayerTitle',
    PayerType: translationGroups.Labels + 'PayerType',
    PaymentGroups: translationGroups.Labels + 'PaymentGroups',
    Phone: translationGroups.Labels + 'Phone',
    Purpose: translationGroups.Labels + 'Purpose',
    PurposePayment: translationGroups.Labels + 'PurposePayment',
    PurposePaymentCode: translationGroups.Labels + 'PurposePaymentCode',
    PurposeNo: translationGroups.Labels + 'PurposeNo',
    MonthNameShort: translationGroups.Labels + 'MonthNameShort',
    NewGroup: translationGroups.Labels + 'NewGroup',
    Note: translationGroups.Labels + 'Note',
    Payee: translationGroups.Labels + 'Payee',
    PayeeAccount: translationGroups.Labels + 'PayeeAccount',
    PayeeAccountNumber: translationGroups.Labels + 'PayeeAccountNumber',
    PayeeAddress: translationGroups.Labels + 'PayeeAddress',
    PayeeBank: translationGroups.Labels + 'PayeeBank',
    PayeeIdentityCode: translationGroups.Labels + 'PayeeIdentityCode',
    PayeeIdentityType: translationGroups.Labels + 'PayeeIdentityType',
    PayeeTitle: translationGroups.Labels + 'PayeeTitle',
    PayeeType: translationGroups.Labels + 'PayeeType',
    PaymentDescription: translationGroups.Labels + 'PaymentDescription',
    PaymentTemplateDescription: translationGroups.Labels + 'PaymentTemplateDescription',
    PaymentTemplates: translationGroups.Labels + 'PaymentTemplates',
    PaymentTemplateName: translationGroups.Labels + 'PaymentTemplateName',
    PaymentGroupReceivers: translationGroups.Labels + 'PaymentGroupReceivers',
    PaymentType: translationGroups.Labels + 'PaymentType',
    Payments: translationGroups.Labels + 'Payments',
    PaymentUniqueCode: translationGroups.Labels + 'PaymentUniqueCode',
    Password: translationGroups.Labels + 'Password',
    PasswordCurrent: translationGroups.Labels + 'PasswordCurrent',
    PasswordNew: translationGroups.Labels + 'PasswordNew',
    PasswordNewRepeat: translationGroups.Labels + 'PasswordNewRepeat',
    Period: translationGroups.Labels + 'Period',
    PurposeType: translationGroups.Labels + 'PurposeType',
    RecipientBankName: translationGroups.Labels + 'RecipientBankName',
    SavePaymentTemplate: translationGroups.Labels + 'SavePaymentTemplate',
    SaveToSignSuccesseful: translationGroups.Labels + 'SaveToSignSuccesseful',
    SelectFile: translationGroups.Labels + 'SelectFile',
    SenderData: translationGroups.Labels + 'SenderData',
    Summary: translationGroups.Labels + 'Summary',
    ReceivedData: translationGroups.Labels + 'ReceivedData',
    Term: translationGroups.Labels + 'Term',
    Title: translationGroups.Labels + 'Title',
    Template: translationGroups.Labels + 'Template',
    ToAccount: translationGroups.Labels + 'ToAccount',
    UploadDateTime: translationGroups.Labels + 'UploadDateTime',
    FileName: translationGroups.Labels + 'FileName',
    PaymentCount: translationGroups.Labels + 'PaymentCount',
    PaymentSum: translationGroups.Labels + 'PaymentSum',
    OperationRejectedSevenDays: translationGroups.Labels + 'OperationRejectedSevenDays',
    unreadMessages: translationGroups.Labels + 'unreadMessages',
    Message: translationGroups.Labels + 'Message',
    ClientName: translationGroups.Labels + 'ClientName',
    ClientTitle: translationGroups.Labels + 'ClientTitle',
    ClientId: translationGroups.Labels + 'ClientId',
    ClientRegCountry: translationGroups.Labels + 'ClientRegCountry',
    ClientDocsType: translationGroups.Labels + 'ClientDocsType',
    ClientDocNo: translationGroups.Labels + 'ClientDocNo',
    ClientDocCountry: translationGroups.Labels + 'ClientDocCountry',
    ClientDocIssuePlace: translationGroups.Labels + 'ClientDocIssuePlace',
    ClientDocIssueDate: translationGroups.Labels + 'ClientDocIssueDate',
    ClientDocValidDate: translationGroups.Labels + 'ClientDocValidDate',
    AddressType: translationGroups.Labels + 'AddressType',
    AddressCountry: translationGroups.Labels + 'AddressCountry',
    AddressMunicipality: translationGroups.Labels + 'AddressMunicipality',
    AddressCity: translationGroups.Labels + 'AddressCity',
    AddressStreet: translationGroups.Labels + 'AddressStreet',
    AddressHouse: translationGroups.Labels + 'AddressHouse',
    AddressFlat: translationGroups.Labels + 'AddressFlat',
    AddressPostCode: translationGroups.Labels + 'AddressPostCode',
    ClientPhoneType: translationGroups.Labels + 'ClientPhoneType',
    ClientPhoneNo: translationGroups.Labels + 'ClientPhoneNo',
    ClientTaxPayerLt: translationGroups.Labels + 'ClientTaxPayerLt',
    ClientIncomeSource: translationGroups.Labels + 'ClientIncomeSource',
    ClientIncomeSalary: translationGroups.Labels + 'ClientIncomeSalary',
    ClientIncomeRe: translationGroups.Labels + 'ClientIncomeRe',
    ClientIncomeIdv: translationGroups.Labels + 'ClientIncomeIdv',
    ClientIncomeStip: translationGroups.Labels + 'ClientIncomeStip',
    ClientIncomeStock: translationGroups.Labels + 'ClientIncomeStock',
    ClientIncomeBenef: translationGroups.Labels + 'ClientIncomeBenef',
    ClientIncomeSav: translationGroups.Labels + 'ClientIncomeSav',
    ClientIncomeOtherT: translationGroups.Labels + 'ClientIncomeOtherT',
    ClientMonthIncome: translationGroups.Labels + 'ClientMonthIncome',
    ClientPoliticsMember: translationGroups.Labels + 'ClientPoliticsMember',
    ClientPoliticsMinist: translationGroups.Labels + 'ClientPoliticsMinist',
    ClientPoliticsParl: translationGroups.Labels + 'ClientPoliticsParl',
    ClientPoliticsJutge: translationGroups.Labels + 'ClientPoliticsJutge',
    ClientPoliticsMay: translationGroups.Labels + 'ClientPoliticsMay',
    ClientPoliticsAudit: translationGroups.Labels + 'ClientPoliticsAudit',
    ClientPoliticsAmb: translationGroups.Labels + 'ClientPoliticsAmb',
    ClientPoliticsJoint: translationGroups.Labels + 'ClientPoliticsJoint',
    ClientPoliticsMJoint: translationGroups.Labels + 'ClientPoliticsMJoint',
    clientPoliticsInternOrg: translationGroups.Labels + 'clientPoliticsInternOrg',
    ClientPoliticsPolPart: translationGroups.Labels + 'ClientPoliticsPolPart',
    ClientPoliticsList: translationGroups.Labels + 'ClientPoliticsList',
    ClientPoliticsName: translationGroups.Labels + 'ClientPoliticsName',
    ClientPoliticsCountry: translationGroups.Labels + 'ClientPoliticsCountry',
    ClientPoliticsLink: translationGroups.Labels + 'ClientPoliticsLink',
    ClientEmpComp: translationGroups.Labels + 'ClientEmpComp',
    ClientEmpCompType: translationGroups.Labels + 'ClientEmpCompType',
    ClientEmpCompName: translationGroups.Labels + 'ClientEmpCompName',
    ClientEmpCompCountry: translationGroups.Labels + 'ClientEmpCompCountry',
    ClientEmpPosition: translationGroups.Labels + 'ClientEmpPosition',
    ClientPcComp: translationGroups.Labels + 'ClientPcComp',
    ClientPcType: translationGroups.Labels + 'ClientPcType',
    ClientPcCompBusiness: translationGroups.Labels + 'ClientPcCompBusiness',
    ClientPension: translationGroups.Labels + 'ClientPension',
    ClientStudent: translationGroups.Labels + 'ClientStudent',
    ClientFarmer: translationGroups.Labels + 'ClientFarmer',
    ClientNonEmp: translationGroups.Labels + 'ClientNonEmp',
    ClientEmail: translationGroups.Labels + 'ClientEmail',
    ClientAddress: translationGroups.Labels + 'ClientAddress',
    OtherClientAddress: translationGroups.Labels + 'OtherClientAddress',
    ClientPhone: translationGroups.Labels + 'ClientPhone',
    OtherClientPhone: translationGroups.Labels + 'OtherClientPhone',
    IncomePerMonth: translationGroups.Labels + 'IncomePerMonth',
    ClientEmployment: translationGroups.Labels + 'ClientEmployment',
    ClientOther: translationGroups.Labels + 'ClientOther',
    KycUpdating: translationGroups.Labels + 'KycUpdating',
    KycMustUpdated: translationGroups.Labels + 'KycMustUpdated',
    KycNeedUpdated: translationGroups.Labels + 'KycNeedUpdated',
    True: translationGroups.Labels + 'True',
    False: translationGroups.Labels + 'False',
    Yes: translationGroups.Labels + 'Yes',
    No: translationGroups.Labels + 'No',
    ClientPoliticsMemberFirstNote: translationGroups.Labels + 'ClientPoliticsMemberFirstNote',
    ClientPoliticsMemberSecondNote: translationGroups.Labels + 'ClientPoliticsMemberSecondNote',
    ClientPlanCashTurn: translationGroups.Labels + 'ClientPlanCashTurn',
    clientAccountBank: translationGroups.Labels + 'clientAccountBank',
    clientAccountBankName: translationGroups.Labels + 'clientAccountBankName',
    clientAccountBankCountry: translationGroups.Labels + 'clientAccountBankCountry',
    InformationAboutClient: translationGroups.Labels + 'InformationAboutClient',
    ClientFullName: translationGroups.Labels + 'ClientFullName',
    ClientBirthDate: translationGroups.Labels + 'ClientBirthDate',
    ClientVatCode: translationGroups.Labels + 'ClientVatCode',
    ClientEmployeeQty: translationGroups.Labels + 'ClientEmployeeQty',
    ClientCeoName: translationGroups.Labels + 'ClientCeoName',
    ClientCeoSurname: translationGroups.Labels + 'ClientCeoSurname',
    ClientCeoId: translationGroups.Labels + 'ClientCeoId',
    ClientCeoCitizien: translationGroups.Labels + 'ClientCeoCitizien',
    ClientCeoPhoneNo: translationGroups.Labels + 'ClientCeoPhoneNo',
    ClientCeoIdDocType: translationGroups.Labels + 'ClientCeoIdDocType',
    ClientCeoDocNo: translationGroups.Labels + 'ClientCeoDocNo',
    ClientCeoDocIssueDate: translationGroups.Labels + 'ClientCeoDocIssueDate',
    ClientCeoDocValidDate: translationGroups.Labels + 'ClientCeoDocValidDate',
    ClientCeoDocCountry: translationGroups.Labels + 'ClientCeoDocCountry',
    ClientRepName: translationGroups.Labels + 'ClientRepName',
    ClientRepSurname: translationGroups.Labels + 'ClientRepSurname',
    ClientRepId: translationGroups.Labels + 'ClientRepId',
    ClientRepMandatValidDate: translationGroups.Labels + 'ClientRepMandatValidDate',
    ClientRepRegCountry: translationGroups.Labels + 'ClientRepRegCountry',
    ClientRepPhoneNo: translationGroups.Labels + 'ClientRepPhoneNo',
    ClientRepIdDocType: translationGroups.Labels + 'ClientRepIdDocType',
    ClientRepDocNo: translationGroups.Labels + 'ClientRepDocNo',
    ClientRepDocCountry: translationGroups.Labels + 'ClientRepDocCountry',
    ClientBasicBusiness: translationGroups.Labels + 'ClientBasicBusiness',
    ClientBasicBusinessType: translationGroups.Labels + 'ClientBasicBusinessType',
    ClientBasicRegion: translationGroups.Labels + 'ClientBasicRegion',
    ClientBasicRegionType: translationGroups.Labels + 'ClientBasicRegionType',
    ClientBusPartner: translationGroups.Labels + 'ClientBusPartner',
    ClientBusPartnerType: translationGroups.Labels + 'ClientBusPartnerType',
    ClientBusPartnerName: translationGroups.Labels + 'ClientBusPartnerName',
    ClientBusPartnerCountry: translationGroups.Labels + 'ClientBusPartnerCountry',
    ClientPlanTurn: translationGroups.Labels + 'ClientPlanTurn',
    ClientTax: translationGroups.Labels + 'ClientTax',
    ClientTaxPayerType: translationGroups.Labels + 'ClientTaxPayerType',
    ClientTaxPayerCountry: translationGroups.Labels + 'ClientTaxPayerCountry',
    ClientTaxPayerId: translationGroups.Labels + 'ClientTaxPayerId',
    ClientTaxPayerHid: translationGroups.Labels + 'ClientTaxPayerHid',
    ClientAccountBank: translationGroups.Labels + 'ClientAccountBank',
    ClientAccountBankName: translationGroups.Labels + 'ClientAccountBankName',
    ClientAccountBankCountry: translationGroups.Labels + 'ClientAccountBankCountry',
    ClientBeneficiaries: translationGroups.Labels + 'ClientBeneficiaries',
    ClientBenefType: translationGroups.Labels + 'ClientBenefType',
    ClientBenefName: translationGroups.Labels + 'ClientBenefName',
    ClientBenefSurname: translationGroups.Labels + 'ClientBenefSurname',
    ClientBenefBirthCountry: translationGroups.Labels + 'ClientBenefBirthCountry',
    ClientBenefRegCountry: translationGroups.Labels + 'ClientBenefRegCountry',
    ClientBenefCountry: translationGroups.Labels + 'ClientBenefCountry',
    ClientBenefAddress: translationGroups.Labels + 'ClientBenefAddress',
    ClientBenefTaxCountry: translationGroups.Labels + 'ClientBenefTaxCountry',
    ClientBenefTaxId: translationGroups.Labels + 'ClientBenefTaxId',
    ClientBenefShare: translationGroups.Labels + 'ClientBenefShare',
    TaxTrue: translationGroups.Labels + 'TaxTrue',
    TaxFalse: translationGroups.Labels + 'TaxFalse',
    ClientCeo: translationGroups.Labels + 'ClientCeo',
    ClientRep: translationGroups.Labels + 'ClientRep',
    ClientBeneficiariesNote: translationGroups.Labels + 'ClientBeneficiariesNote',
    Reservations: translationGroups.Labels + 'Reservations',
    AmountCurrency: translationGroups.Labels + 'AmountCurrency',

    OtherActivity: translationGroups.Labels + 'OtherActivity',
    ClientCivilServant: translationGroups.Labels + 'ClientCivilServant',
    DateDisplay: translationGroups.Labels + 'DateDisplay',
    LoanAmount: translationGroups.Labels + 'LoanAmount',
    InterestAmount: translationGroups.Labels + 'InterestAmount',
    Installment: translationGroups.Labels + 'Installment',
    LoanResidual: translationGroups.Labels + 'LoanResidual',
    LoanScheduleNote: translationGroups.Labels + 'LoanScheduleNote',
    ClientBenefNera: translationGroups.Labels + 'ClientBenefNera',
    ClientBenefReason: translationGroups.Labels + 'ClientBenefReason',
    ClientStructure: translationGroups.Labels + 'ClientStructure',
    ExplainAboutStructure: translationGroups.Labels + 'ExplainAboutStructure',
    ClientOrgStructureDesc: translationGroups.Labels + 'ClientOrgStructureDesc',
    ClientOrgStructureDirect: translationGroups.Labels + 'ClientOrgStructureDirect',
    WhoOpenAccount: translationGroups.Labels + 'WhoOpenAccount',
    ClientTaxPayerOther: translationGroups.Labels + 'ClientTaxPayerOther',
    InfomationAboutTaxesInOtherCountry: translationGroups.Labels + 'InfomationAboutTaxesInOtherCountry',
    СlientTaxPayerConfirm: translationGroups.Labels + 'СlientTaxPayerConfirm',
    TaxInformationFirstNote: translationGroups.Labels + 'TaxInformationFirstNote',
    LogsId: translationGroups.Labels + 'LogsId',
    LogsLevel: translationGroups.Labels + 'LogsLevel',
    LogsTimeStamp: translationGroups.Labels + 'LogsTimeStamp',
    LogsException: translationGroups.Labels + 'LogsException',
    LogsResponseCode: translationGroups.Labels + 'LogsResponseCode',
    LogsCreated: translationGroups.Labels + 'LogsCreated',
    LogsEnded: translationGroups.Labels + 'LogsEnded',
    LogsRequestUrl: translationGroups.Labels + 'LogsRequestUrl',
    LogsRequestExplanation: translationGroups.Labels + 'LogsRequestExplanation',
    LogsClientId: translationGroups.Labels + 'LogsClientId',
    LogsUserCode: translationGroups.Labels + 'LogsUserCode',
    LogsElapsedMilliseconds: translationGroups.Labels + 'LogsElapsedMilliseconds',
    LogsArguments: translationGroups.Labels + 'LogsArguments',
    LogsIp: translationGroups.Labels + 'LogsIp',
    LogsSession: translationGroups.Labels + 'LogsSession',
    LoginSme: translationGroups.Labels + 'LoginSme',
    LoginManoHeader: translationGroups.Labels + 'LoginManoHeader',
    LoginManoSubheader: translationGroups.Labels + 'LoginManoSubheader',
    LoginManoDescription: translationGroups.Labels + 'LoginManoDescription',
    LoginInfoMano1: translationGroups.Labels + 'LoginInfoMano1',
    LoginInfoMano2: translationGroups.Labels + 'LoginInfoMano2',
    LoginFooterMano1: translationGroups.Labels + 'LoginFooterMano1',
    LoginFooterMano2: translationGroups.Labels + 'LoginFooterMano2',
    DepositLoanEnd: translationGroups.Labels + 'DepositLoanEnd',
    DepositLoanBegin: translationGroups.Labels + 'DepositLoanBegin',
    DepositAmount: translationGroups.Labels + 'DepositAmount',
    DepositTransferingDate: translationGroups.Labels + 'DepositTransferingDate',
    CancelationFee: translationGroups.Labels + 'CancelationFee',
    PaymentSearchFromHistory: translationGroups.Labels + 'PaymentSearchFromHistory',
    BankName: translationGroups.Labels + 'BankName',
    ApprovalSum: translationGroups.Labels + 'ApprovalSum',
    ApprovalPaymentsCount: translationGroups.Labels + 'ApprovalPaymentsCount',
    Description: translationGroups.Labels + 'Description',
    Statement: translationGroups.Labels + 'Statement',
    OperationsData: translationGroups.Labels + 'OperationsData',
    OperationsDescription: translationGroups.Labels + 'OperationsDescription',
    OperationsPayer: translationGroups.Labels + 'OperationsPayer',
    OperationsListPayer: translationGroups.Labels + 'OperationsListPayer',
    OperationsPayee: translationGroups.Labels + 'OperationsPayee',
    OperationsSum: translationGroups.Labels + 'OperationsSum',
    AccountsAvailable: translationGroups.Labels + 'AccountsAvailable',
    AccountsBalance: translationGroups.Labels + 'AccountsBalance',
    AccountsBegin: translationGroups.Labels + 'AccountsBegin',
    AccountsBlocked: translationGroups.Labels + 'AccountsBlocked',
    AccountsContractNumber: translationGroups.Labels + 'AccountsContractNumber',
    AccountsDaysOf: translationGroups.Labels + 'AccountsDaysOf',
    AccountsEnd: translationGroups.Labels + 'AccountsEnd',
    AccountsInterest: translationGroups.Labels + 'AccountsInterest',
    AccountsLimits: translationGroups.Labels + 'AccountsLimits',
    AccountsLoanBalance: translationGroups.Labels + 'AccountsLoanBalance',
    AccountsNumber: translationGroups.Labels + 'AccountsNumber',
    AccountsReserved: translationGroups.Labels + 'AccountsReserved',
    AccountsSum: translationGroups.Labels + 'AccountsSum',
    OperationsPurpose: translationGroups.Labels + 'OperationsPurpose',
    OperationsCode: translationGroups.Labels + 'OperationsCode',
    OperationsCosts: translationGroups.Labels + 'OperationsCosts',
    OperationsRevenue: translationGroups.Labels + 'OperationsRevenue',
    OperationsExpenses: translationGroups.Labels + 'OperationsExpenses',
    OperationsTaxes: translationGroups.Labels + 'OperationsTaxes',
    Bills: translationGroups.Labels + 'Bills',
    RecentOperations: translationGroups.Labels + 'RecentOperations',
    OperationsInProgress: translationGroups.Labels + 'OperationsInProgress',
    InvoiceBlocked: translationGroups.Labels + 'InvoiceBlocked',
    InvoiceCode: translationGroups.Labels + 'InvoiceCode',
    InvoiceComissionFee: translationGroups.Labels + 'InvoiceComissionFee',
    InvoiceData: translationGroups.Labels + 'InvoiceData',
    InvoiceDescription: translationGroups.Labels + 'InvoiceDescription',
    InvoiceExpenses: translationGroups.Labels + 'InvoiceExpenses',
    InvoiceFinalBalance: translationGroups.Labels + 'InvoiceFinalBalance',
    EquivalentBalance: translationGroups.Labels + 'EquivalentBalance',
    InvoiceInitialBalance: translationGroups.Labels + 'InvoiceInitialBalance',
    InvoicePayFeeDesc: translationGroups.Labels + 'InvoicePayFeeDesc',
    InvoicePayeeName: translationGroups.Labels + 'InvoicePayeeName',
    InvoicePayeeTitle: translationGroups.Labels + 'InvoicePayeeTitle',
    InvoicePayer: translationGroups.Labels + 'InvoicePayer',
    InvoicePayerName: translationGroups.Labels + 'InvoicePayerName',
    InvoicePayerTitle: translationGroups.Labels + 'InvoicePayerTitle',
    InvoicePaymentType: translationGroups.Labels + 'InvoicePaymentType',
    InvoicePurpose: translationGroups.Labels + 'InvoicePurpose',
    InvoiceReserved: translationGroups.Labels + 'InvoiceReserved',
    DisposableBalance: translationGroups.Labels + 'DisposableBalance',
    InvoiceRevenue: translationGroups.Labels + 'InvoiceRevenue',
    InvoiceSum: translationGroups.Labels + 'InvoiceSum',
    InvoiceTaxes: translationGroups.Labels + 'InvoiceTaxes',
    InvoiceTotalCost: translationGroups.Labels + 'InvoiceTotalCost',
    InvoiceTotalRevenue: translationGroups.Labels + 'InvoiceTotalRevenue',
    InvoiceUniquePaymentCode: translationGroups.Labels + 'InvoiceUniquePaymentCode',
    InitTitle: translationGroups.Labels + 'InitTitle',
    QuickOperations: translationGroups.Labels + 'QuickOperations',
    Operations: translationGroups.Labels + 'Operations',
    Or: translationGroups.Labels + 'Or',
    AccountsAmount: translationGroups.Labels + 'AccountsAmount',
    InvoiceDate: translationGroups.Labels + 'InvoiceDate',
    InvoiceAmount: translationGroups.Labels + 'InvoiceAmount',
    LoginPageSignUpTitle: translationGroups.Labels + 'LoginPageSignUpTitle',
    FooterCopyright: translationGroups.Labels + 'FooterCopyright',
    MSignature: translationGroups.Labels + 'MSignature',
    'lv-eparaksts': translationGroups.Labels + 'eParaksts',
    SmartID: translationGroups.Labels + 'SmartID',
    DepositConditions: translationGroups.Labels + 'DepositConditions',
    DepositPreview: translationGroups.Labels + 'DepositPreview',
    LoginError: translationGroups.Labels + 'LoginError',
    LoginCanceled: translationGroups.Labels + 'LoginCanceled',
    PrivatePersons: translationGroups.Labels + 'PrivatePersons',
    Companies: translationGroups.Labels + 'Companies',
    LoginCountdownMessage: translationGroups.Labels + 'LoginCountdownMessage',
    LoginCountdownMeasure: translationGroups.Labels + 'LoginCountdownMeasure',
    Biometric: translationGroups.Labels + 'Biometric',
    RemitPurposeCode: translationGroups.Labels + 'RemitPurposeCode',
    BeneficiaryAddress: translationGroups.Labels + 'BeneficiaryAddress',
    BeneficiaryCity: translationGroups.Labels + 'BeneficiaryCity',
    BeneficiaryPostcode: translationGroups.Labels + 'BeneficiaryPostcode',
    BeneficiaryCountryCode: translationGroups.Labels + 'BeneficiaryCountryCode',
    BeneficiaryState: translationGroups.Labels + 'BeneficiaryState',
    BeneficiaryAccountType: translationGroups.Labels + 'BeneficiaryAccountType',
    BeneficiaryIdentificationType: translationGroups.Labels + 'BeneficiaryIdentificationType',
    BeneficiaryIdentificationValue: translationGroups.Labels + 'BeneficiaryIdentificationValue',
    BeneficiaryBankName: translationGroups.Labels + 'BeneficiaryBankName',
    TransactionNumber: translationGroups.Labels + 'TransactionNumber',
    SwiftFeePayer: translationGroups.Labels + 'SwiftFeePayer',
    FixRate: translationGroups.Labels + 'FixRate',
    WantBuy: translationGroups.Labels + 'WantBuy',
    WantSell: translationGroups.Labels + 'WantSell',
    AgreeWithFixRate: translationGroups.Labels + 'AgreeWithFixRate',
    Bought: translationGroups.Labels + 'Bought',
    Sold: translationGroups.Labels + 'Sold',
    BIC: translationGroups.Labels + 'BIC',
    CountryName: translationGroups.Labels + 'CountryName',
    RoutingCodeValue: translationGroups.Labels + 'RoutingCodeValue',
    PurposeCode: translationGroups.Labels + 'PurposeCode',
    BeneficiaryContactNumber: translationGroups.Labels + 'BeneficiaryContactNumber',
    BeneficiaryEmail: translationGroups.Labels + 'BeneficiaryEmail',
    payTill: translationGroups.Labels + 'payTill',
    OverdraftAgreementNumber: translationGroups.Labels + 'OverdraftAgreementNumber',
    OverdraftAvailableAmount: translationGroups.Labels + 'OverdraftAvailableAmount',
    CorrespondentBankBic: translationGroups.Labels + 'CorrespondentBankBic',
    CorrespondentBankBicInternational: translationGroups.Labels + 'CorrespondentBankBicInternational',
    CommissionFeeTitle: translationGroups.Labels + 'CommissionFeeTitle',
    CommissionFeeLabel: translationGroups.Labels + 'CommissionFeeLabel',
    ExecutionDate: translationGroups.Labels + 'ExecutionDate',
    ExecutionDateValue: translationGroups.Labels + 'ExecutionDateValue',
    ExecutionDateCutOff: translationGroups.Labels + 'ExecutionDateCutOff',
    BankTaxesPays: translationGroups.Labels + 'BankTaxesPays',
    RecipientBankCountry: translationGroups.Labels + 'RecipientBankCountry',
    RecipientInternationalBankCountry: translationGroups.Labels + 'RecipientInternationalBankCountry',
    AccountBalanceOD: translationGroups.Labels + 'AccountBalanceOD',
    AgreeWithTarget2PaymentRates: translationGroups.Labels + 'AgreeWithTarget2PaymentRates',
    payebleInterest: translationGroups.Labels + 'payebleInterest',
    payebleLoan: translationGroups.Labels + 'payebleLoan',
    mainAccount: translationGroups.Labels + 'mainAccount',
    AgreeWithForexExchange: translationGroups.Labels + 'AgreeWithForexExchange',
    CurrencyFrom: translationGroups.Labels + 'CurrencyFrom',
    CurrencyTo: translationGroups.Labels + 'CurrencyTo',
    OperationStatus: translationGroups.Labels + 'OperationStatus',
    PaymentPurpose: translationGroups.Labels + 'PaymentPurpose',
    PayeeBankName: translationGroups.Labels + 'PayeeBankName',
    StatusCode: translationGroups.Labels + 'StatusCode',
    MyDashboard: translationGroups.Labels + 'MyDashboard',
    SMEBank: translationGroups.Labels + 'SMEBank',
    SMEFinance: translationGroups.Labels + 'SMEFinance',
    FileId: translationGroups.Labels + 'FileId',
    Back: translationGroups.Labels + 'Back',
    Confirm: translationGroups.Labels + 'Confirm',
    Delete: translationGroups.Labels + 'Delete',
    SelectIban: translationGroups.Labels + 'SelectIban',
    CookiesNecessary: translationGroups.Labels + 'CookiesNecessary',
    CookiesNecessaryText: translationGroups.Labels + 'CookiesNecessaryText',
    CookiesFunctional: translationGroups.Labels + 'CookiesFunctional',
    CookiesFunctionalText: translationGroups.Labels + 'CookiesFunctionalText',
    CookiesPerformance: translationGroups.Labels + 'CookiesPerformance',
    CookiesPerformanceText: translationGroups.Labels + 'CookiesPerformanceText',
    CookiesAnalytics: translationGroups.Labels + 'CookiesAnalytics',
    CookiesAnalyticsText: translationGroups.Labels + 'CookiesAnalyticsText',
    CookiesAdvertisement: translationGroups.Labels + 'CookiesAdvertisement',
    CookiesAdvertisementText: translationGroups.Labels + 'CookiesAdvertisementText',
    CookiesOthers: translationGroups.Labels + 'CookiesOthers',
    CookiesOthersText: translationGroups.Labels + 'CookiesOthersText',
    NotOurClientYet: translationGroups.Labels + 'NotOurClientYet',
    LoginToYourAccount: translationGroups.Labels + 'LoginToYourAccount',
    LoginCode: translationGroups.Labels + 'LoginCode',
    MobileID: translationGroups.Labels + 'MobileID',
    IDIN: translationGroups.Labels + 'IDIN',
    FTN: translationGroups.Labels + 'FTN',
    MobileESign: translationGroups.Labels + 'MobileESign',
    StepBack: translationGroups.Labels + 'StepBack',
    LoginUsingSmartId: translationGroups.Labels + 'LoginUsingSmartId',
    LoginUsingMobileId: translationGroups.Labels + 'LoginUsingMobileId',
    PersonalCode: translationGroups.Labels + 'PersonalCode',
    MobileNumber: translationGroups.Labels + 'MobileNumber',
    LoginWithSmartId: translationGroups.Labels + 'LoginWithSmartId',
    LoginWithMobileId: translationGroups.Labels + 'LoginWithMobileId',
    AuthenticationCodeHasBeenSentToYourSmartIdDevice: translationGroups.Labels + 'AuthenticationCodeHasBeenSentToYourSmartIdDevice',
    AuthenticationCodeHasBeenSentToYourMobileIdDevice: translationGroups.Labels + 'AuthenticationCodeHasBeenSentToYourMobileIdDevice',
    SmartIDConfirmationInfo: translationGroups.Labels + 'SmartIDConfirmationInfo',
    MobileIDConfirmationInfo: translationGroups.Labels + 'MobileIDConfirmationInfo',
    AuthenticationCode: translationGroups.Labels + 'AuthenticationCode',
    WaitingForResponse: translationGroups.Labels + 'WaitingForResponse',
    TermsOfService: translationGroups.Labels + 'TermsOfService',
    Country: translationGroups.Labels + 'Country',
    LoginWithIDIN: translationGroups.Labels + 'LoginWithIDIN',
    LoginWithIDINInfoText: translationGroups.Labels + 'LoginWithIDINInfoText',
    IDINConfirmationInfo: translationGroups.Labels + 'IDINConfirmationInfo',
    LoginWithMobileESign: translationGroups.Labels + 'LoginWithMobileESign',
    LoginWithMobileESignInfoText: translationGroups.Labels + 'LoginWithMobileESignInfoText',
    MobileESignConfirmationInfo: translationGroups.Labels + 'MobileESignConfirmationInfo',
    LoginWithBiometric: translationGroups.Labels + 'LoginWithBiometric',
    LoginWithBiometricInfoText: translationGroups.Labels + 'LoginWithBiometricInfoText',
    BiometricConfirmationInfo: translationGroups.Labels + 'BiometricConfirmationInfo',
    SmartId: translationGroups.Labels + 'SmartId',
    BioFace: translationGroups.Labels + 'BioFace',
    LoginTypeSelectionNotOurClientYet: translationGroups.Labels + 'LoginTypeSelectionNotOurClientYet',
    eParaksts: translationGroups.Labels + 'eParaksts',
    TemplateNew: translationGroups.Labels + 'TemplateNew',
    TemplateInternational: translationGroups.Labels + 'TemplateInternational',
    SelectUser: translationGroups.Labels + 'SelectUser',
    OperationPermission: translationGroups.Labels + 'OperationPermission',
    SavingLimits: translationGroups.Labels + 'SavingLimits',
    ShowMore: translationGroups.Labels + 'ShowMore',
    ShowLess: translationGroups.Labels + 'ShowLess',
    SignType: translationGroups.Labels + 'SignType',
    MSign: translationGroups.Labels + 'MSign',
    SMS: translationGroups.Labels + 'SMS',
    MSignOperationLimit: translationGroups.Labels + 'MSignOperationLimit',
    MSignOperationDayLimit: translationGroups.Labels + 'MSignOperationDayLimit',
    MSignOperationMonthLimit: translationGroups.Labels + 'MSignOperationMonthLimit',
    SMSOperationLimit: translationGroups.Labels + 'SMSOperationLimit',
    SMSOperationDayLimit: translationGroups.Labels + 'SMSOperationDayLimit',
    SMSOperationMonthLimit: translationGroups.Labels + 'SMSOperationMonthLimit',
    CheckForexStatus: translationGroups.Labels + 'CheckForexStatus',

    PayerIdentificationType: translationGroups.Labels + 'PayerIdentificationType',
    PayeeIdentificationType: translationGroups.Labels + 'PayeeIdentificationType',
    InitPayerIdentificationType: translationGroups.Labels + 'InitPayerIdentificationType',
    FinalPayeeIdentificationType: translationGroups.Labels + 'FinalPayeeIdentificationType',

    PayerIdentificationCode: translationGroups.Labels + 'PayerIdentificationCode',
    PayeeIdentificationCode: translationGroups.Labels + 'PayeeIdentificationCode',
    InitPayerIdentificationCode: translationGroups.Labels + 'InitPayerIdentificationCode',
    FinalPayeeIdentificationCode: translationGroups.Labels + 'FinalPayeeIdentificationCode',
    SEPAPaymentType: translationGroups.Labels + 'SEPAPaymentType',
    SecondsShort: translationGroups.Labels + 'SecondsShort',

    administrationFee: translationGroups.Labels + 'AdministrationFee',
    interestType: translationGroups.Labels + 'InterestType',
    otherSums: translationGroups.Labels + 'OtherSums',
    unusedLoanSum: translationGroups.Labels + 'UnusedLoanSum',
    interestRateDateTill: translationGroups.Labels + 'InterestRateDateTill',
    ReceiverName: translationGroups.Labels + 'ReceiverName',
    OwnAccountsPayee: translationGroups.Labels + 'OwnAccountsPayee',
    OwnAccountsPayer: translationGroups.Labels + 'OwnAccountsPayer',
    PayeePaymentUploading: translationGroups.Labels + 'PayeePaymentUploading',
    UnsignedPaymentSubmitModal: translationGroups.Labels + 'UnsignedPaymentSubmitModal',
    UnsignedPaymentsFilesCount: translationGroups.Labels + 'UnsignedPaymentsFilesCount',
    PaymentTemplateListPurpose: translationGroups.Labels + 'PaymentTemplateListPurpose',
    LastOperationsPurpose: translationGroups.Labels + 'LastOperationsPurpose',
    OnGoingOperationsPurpose: translationGroups.Labels + 'OnGoingOperationsPurpose',
    OperationsListDescription: translationGroups.Labels + 'OperationsListDescription',
    NeedHelpContactUs: translationGroups.Labels + 'NeedHelpContactUs',
    CreditorTitle: translationGroups.Labels + 'CreditorTitle',
    CreditorAccountNumber: translationGroups.Labels + 'CreditorAccountNumber',
    NewBeneficiary: translationGroups.Labels + 'NewBeneficiary',
    DeleteBeneficiary: translationGroups.Labels + 'DeleteBeneficiary',
    CheckBeneficiariesNewSignStatus: translationGroups.Labels + 'CheckBeneficiariesNewSignStatus',
    CheckBeneficiariesDeleteSignStatus: translationGroups.Labels + 'CheckBeneficiariesDeleteSignStatus',
  },
  Menu: {
    Accounts: translationGroups.Menu + 'Accounts',
    Contacts: translationGroups.Menu + 'Contacts',
    Deposit: translationGroups.Menu + 'Deposit',
    Invoice: translationGroups.Menu + 'Invoice',
    Main: translationGroups.Menu + 'Main',
    Operations: translationGroups.Menu + 'Operations',
    Payments: translationGroups.Menu + 'Payments',
    Summary: translationGroups.Menu + 'Summary',
    Settings: translationGroups.Menu + 'Settings',
  },
  MessageTitles: {
    DepositCreateSuccess: translationGroups.MessageTitles + 'DepositCreateSuccess',
    PaymentSuccess: translationGroups.MessageTitles + 'PaymentSuccess',
    PaymentUnsuccess: translationGroups.MessageTitles + 'PaymentUnsuccess',
    PaymentWaitingConfirm: translationGroups.MessageTitles + 'PaymentWaitingConfirm',
    ImportantSMELoginMessage: translationGroups.MessageTitles + 'ImportantSMELoginMessage',
    OtherSMELoginMessage: translationGroups.MessageTitles + 'OtherSMELoginMessage',
    DeletedSuccessUnsignedPaymentsTitle: translationGroups.MessageTitles + 'DeletedSuccessUnsignedPaymentsTitle',
    DeletedSuccessUnsignedPaymentsFileTitle: translationGroups.MessageTitles + 'DeletedSuccessUnsignedPaymentsFileTitle',
  },
  Messages: {
    ApproveOperationOnYourDevice: translationGroups.Messages + 'ApproveOperationOnYourDevice',
    ControlCode: translationGroups.Messages + 'ControlCode',
    FillYourSMSCodeToApproveOperation: translationGroups.Messages + 'FillYourSMSCodeToApproveOperation',
    ListIsEmpty: translationGroups.Messages + 'ListIsEmpty',
    NoData: translationGroups.Messages + 'NoData',
    PaymentSuccess: translationGroups.Messages + 'PaymentSuccess',
    SessionGoesEndTimeLeft: translationGroups.Messages + 'SessionGoesEndTimeLeft',
    DeletedSuccess: translationGroups.Messages + 'DeletedSuccess',
    ResponseError: translationGroups.Messages + 'ResponseError',
    FaKycIsUpdating: translationGroups.Messages + 'FaKycIsUpdating',
    JaKycIsUpdating: translationGroups.Messages + 'JaKycIsUpdating',
    KycNeedUpdatedCompany: translationGroups.Messages + 'KycNeedUpdatedCompany',
    KycMustUpdatedCompany: translationGroups.Messages + 'KycMustUpdatedCompany',
    KycNeedUpdatedPerson: translationGroups.Messages + 'KycNeedUpdatedPerson',
    KycMustUpdatedPerson: translationGroups.Messages + 'KycMustUpdatedPerson',
    NoSignedPaymentErrorMuchMore: translationGroups.Messages + 'NoSignedPaymentErrorMuchMore',
    PaymentWaitingConfirm: translationGroups.Messages + 'PaymentWaitingConfirm',
    SHARecipientAndPayer: translationGroups.Messages + 'SHARecipientAndPayer',
    ForexTimerMessage: translationGroups.Messages + 'ForexTimerMessage',
    ForexExchangeIsSuccessfull: translationGroups.Messages + 'ForexExchangeIsSuccessfull',
    SessionGoesEndTimeLeft2: translationGroups.Messages + 'SessionGoesEndTimeLeft2',
    ForexExchangeFillInfoMessage: translationGroups.Messages + 'ForexExchangeFillInfoMessage',
    ForexExchangePreviewInfoMessage: translationGroups.Messages + 'ForexExchangePreviewInfoMessage',
    DeletedSuccessUnsignedPaymentsDesc: translationGroups.Messages + 'DeletedSuccessUnsignedPaymentsDesc',
    DeletedSuccessUnsignedPaymentsFileDesc: translationGroups.Messages + 'DeletedSuccessUnsignedPaymentsFileDesc',
    KYCIsExpiring: translationGroups.Messages + 'KYCIsExpiring',
    KYCExpired: translationGroups.Messages + 'KYCExpired',
    KYCExpiredDescription: translationGroups.Messages + 'KYCExpiredDescription',
    KYCAllowPayment: translationGroups.Messages + 'KYCAllowPayment',
    KYCIsExpiringDescription: translationGroups.Messages + 'KYCIsExpiringDescription',
    KYCExpiredError: translationGroups.Messages + 'KYCExpiredError',
    KYCExpiredErrorDescription: translationGroups.Messages + 'KYCExpiredErrorDescription',
    KYCExpiredInfo: translationGroups.Messages + 'KYCExpiredInfo',
    KYCExpiredInfoDescription: translationGroups.Messages + 'KYCExpiredInfoDescription',
    BeneficiariesInfoMessage: translationGroups.Messages + 'BeneficiariesInfoMessage',
  },
  ModalTitles: {
    SessionGoesEnd: translationGroups.ModalTitles + 'SessionGoesEnd',
    CreditLoanShedule: translationGroups.ModalTitles + 'CreditLoanShedule',
    DepositTerminationForm: translationGroups.ModalTitles + 'DepositTerminationForm',
  },
  NotificationMessages: {
    PaymentErrorValidation: translationGroups.NotificationMessages + 'PaymentErrorValidation',
    AccountsChangeFail: translationGroups.NotificationMessages + 'AccountsChangeFail',
    SignMandateError: translationGroups.NotificationMessages + 'SignMandateError',
    DepositSignCanceled: translationGroups.NotificationMessages + 'DepositSignCanceled',
    MandateDeletedCanceled: translationGroups.NotificationMessages + 'MandateDeletedCanceled',
    PaymentErrorCode: translationGroups.NotificationMessages + 'PaymentErrorCode',
    loginErrorX006: translationGroups.NotificationMessages + 'loginErrorX006',
    DepositSignSuccess: translationGroups.NotificationMessages + 'DepositSignSuccess',
    DepositSignFail: translationGroups.NotificationMessages + 'DepositSignFail',
    Timeout: translationGroups.NotificationMessages + 'Timeout',
    AutorizationFailed: translationGroups.NotificationMessages + 'AutorizationFailed',
    Canceled: translationGroups.NotificationMessages + 'Canceled',
    PermissionsChangeFailed: translationGroups.NotificationMessages + 'PermissionsChangeFailed',
    loginErrorcanceled: translationGroups.NotificationMessages + 'loginErrorcanceled',
    SuccessApprove: translationGroups.NotificationMessages + 'SuccessApprove',
    CardBlockedSuccessfully: translationGroups.NotificationMessages + 'CardBlockedSuccessfully',
    LimitsChangedSuccessfully: translationGroups.NotificationMessages + 'LimitsChangedSuccessfully',
    LimitsChangeFailed: translationGroups.NotificationMessages + 'LimitsChangeFailed',
    InvoiceListEmpty: translationGroups.NotificationMessages + 'InvoiceListEmpty',
    PasswordChangedSuccessfully: translationGroups.NotificationMessages + 'PasswordChangedSuccessfully',
    PermissionsChangedSuccessfully: translationGroups.NotificationMessages + 'PermissionsChangedSuccessfully',
    TemplatesNotSelectedError: translationGroups.NotificationMessages + 'TemplatesNotSelectedError',
    UnexpectedErrorReportAdmin: translationGroups.NotificationMessages + 'UnexpectedErrorReportAdmin',
    SavedSuccess: translationGroups.NotificationMessages + 'SavedSuccess',
    SavedError: translationGroups.NotificationMessages + 'SavedError',
    DeleteSuccess: translationGroups.NotificationMessages + 'DeleteSuccess',
    DeleteError: translationGroups.NotificationMessages + 'DeleteError',
    FileUploadError: translationGroups.NotificationMessages + 'FileUploadError',
    FileUploadSuccess: translationGroups.NotificationMessages + 'FileUploadSuccess',
    PaymentCanceled: translationGroups.NotificationMessages + 'PaymentCanceled',
    ApproveCanceled: translationGroups.NotificationMessages + 'ApproveCanceled',
    BadApproveCode: translationGroups.NotificationMessages + 'BadApproveCode',
    ChatCreatedSuccessful: translationGroups.NotificationMessages + 'ChatCreatedSuccessful',
    ChatCreatedError: translationGroups.NotificationMessages + 'ChatCreatedError',
    GroupCreateError: translationGroups.NotificationMessages + 'GroupCreateError',
    UpdateSuccess: translationGroups.NotificationMessages + 'UpdateSuccess',
    UpdateError: translationGroups.NotificationMessages + 'UpdateError',
    KYCMainInformacion: translationGroups.NotificationMessages + 'KYCMainInformacion',
    KYCPersonDocuments: translationGroups.NotificationMessages + 'KYCPersonDocuments',
    KYCContactInformacion: translationGroups.NotificationMessages + 'KYCContactInformacion',
    KYCTaxInformation: translationGroups.NotificationMessages + 'KYCTaxInformation',
    KYCIncome: translationGroups.NotificationMessages + 'KYCIncome',
    KYCPepLinks: translationGroups.NotificationMessages + 'KYCPepLinks',
    KYCOtherInformation: translationGroups.NotificationMessages + 'KYCOtherInformation',
    KYCApproveSubmit: translationGroups.NotificationMessages + 'KYCApproveSubmit',
    KYCInformationAboutClient: translationGroups.NotificationMessages + 'KYCInformationAboutClient',
    KYCClientCEO: translationGroups.NotificationMessages + 'KYCClientCEO',
    KYCBusinessType: translationGroups.NotificationMessages + 'KYCBusinessType',
    KYCClientPlanTurnover: translationGroups.NotificationMessages + 'KYCClientPlanTurnover',
    KYCClientTax: translationGroups.NotificationMessages + 'KYCClientTax',
    KYCAccountsInOtherBank: translationGroups.NotificationMessages + 'KYCAccountsInOtherBank',
    KYCClientBeneficiaries: translationGroups.NotificationMessages + 'KYCClientBeneficiaries',
    KycReniewSuccsessfullyAndWaitingFoAppruving: translationGroups.NotificationMessages + 'KycReniewSuccsessfullyAndWaitingFoAppruving',
    KycReniewSuccsessfullyAndValidTill: translationGroups.NotificationMessages + 'KycReniewSuccsessfullyAndValidTill',
    KYCApproveSubmitCompany: translationGroups.NotificationMessages + 'KYCApproveSubmitCompany',
    KycUpdateYouDontHavePermission: translationGroups.NotificationMessages + 'KycUpdateYouDontHavePermission',
    NoSignedPaymentErrorMuchMore: translationGroups.NotificationMessages + 'NoSignedPaymentErrorMuchMore',
    DepositTerminationSuccess: translationGroups.NotificationMessages + 'DepositTerminationSuccess',
    DepositTerminationFail: translationGroups.NotificationMessages + 'DepositTerminationFail',
    PaymentTypeCanChangeToInst: translationGroups.NotificationMessages + 'PaymentTypeCanChangeToInst',
    IsUnsuccessfull: translationGroups.NotificationMessages + 'IsUnsuccessfull',
    NeedCheckLater: translationGroups.NotificationMessages + 'NeedCheckLater',
    UploadPaymentErrorInPaymentList: translationGroups.NotificationMessages + 'UploadPaymentErrorInPaymentList',
    PaymentAmountIncorrect: translationGroups.NotificationMessages + 'PaymentAmountIncorrect',
    PleaseRefreshThePage: translationGroups.NotificationMessages + 'PleaseRefreshThePage',
    PaymentCanceledTimeout: translationGroups.NotificationMessages + 'PaymentCanceledTimeout',
    PaymentCanceledManual: translationGroups.NotificationMessages + 'PaymentCanceledManual',
    PaymentCanceledRejected: translationGroups.NotificationMessages + 'PaymentCanceledRejected',
    NewMandateCreated: translationGroups.NotificationMessages + 'NewMandateCreated',
  },
  NotificationTitles: {
    PaymentErrorValidation: translationGroups.NotificationTitles + 'PaymentErrorValidation',
    AccountsChangeFail: translationGroups.NotificationTitles + 'AccountsChangeFail',
    DepositSignCanceled: translationGroups.NotificationTitles + 'DepositSignCanceled',
    MandateDeletedCanceled: translationGroups.NotificationTitles + 'MandateDeletedCanceled',
    PaymentErrorCode: translationGroups.NotificationTitles + 'PaymentErrorCode',
    PaymentCanceledRejected: translationGroups.NotificationTitles + 'PaymentCanceledRejected',
    PaymentCanceledTimeout: translationGroups.NotificationTitles + 'PaymentCanceledTimeout',
    PaymentCanceledManual: translationGroups.NotificationTitles + 'PaymentCanceledManual',
    loginErrorX006: translationGroups.NotificationTitles + 'loginErrorX006',
    DepositSignSuccess: translationGroups.NotificationTitles + 'DepositSignSuccess',
    DepositSignFail: translationGroups.NotificationTitles + 'DepositSignFail',
    Timeout: translationGroups.NotificationTitles + 'Timeout',
    Canceled: translationGroups.NotificationTitles + 'Canceled',
    AutorizationFailed: translationGroups.NotificationTitles + 'AutorizationFailed',
    PermissionsChangeFailed: translationGroups.NotificationTitles + 'PermissionsChangeFailed',
    loginErrorcanceled: translationGroups.NotificationTitles + 'loginErrorcanceled',
    SuccessApprove: translationGroups.NotificationTitles + 'SuccessApprove',
    CardBlocked: translationGroups.NotificationTitles + 'CardBlocked',
    ListEmpty: translationGroups.NotificationTitles + 'ListEmpty',
    LimitsChanged: translationGroups.NotificationTitles + 'LimitsChanged',
    LimitsChangeFail: translationGroups.NotificationTitles + 'LimitsChangeFail',
    PasswordChanged: translationGroups.NotificationTitles + 'PasswordChanged',
    PermissionsChanged: translationGroups.NotificationTitles + 'PermissionsChanged',
    TemplatesNotSelected: translationGroups.NotificationTitles + 'TemplatesNotSelected',
    UnexpectedError: translationGroups.NotificationTitles + 'UnexpectedError',
    SavedSuccess: translationGroups.NotificationTitles + 'SavedSuccess',
    SavedError: translationGroups.NotificationTitles + 'SavedError',
    DeleteSuccess: translationGroups.NotificationTitles + 'DeleteSuccess',
    DeleteError: translationGroups.NotificationTitles + 'DeleteError',
    FileUploadError: translationGroups.NotificationTitles + 'FileUploadError',
    FileUploadSuccess: translationGroups.NotificationTitles + 'FileUploadSuccess',
    PaymentCanceled: translationGroups.NotificationTitles + 'PaymentCanceled',
    ApproveCanceled: translationGroups.NotificationTitles + 'ApproveCanceled',
    BadApproveCode: translationGroups.NotificationTitles + 'BadApproveCode',
    ChatCreatedSuccessful: translationGroups.NotificationTitles + 'ChatCreatedSuccessful',
    ChatCreatedError: translationGroups.NotificationTitles + 'ChatCreatedError',
    GroupCreateError: translationGroups.NotificationTitles + 'GroupCreateError',
    UpdateSuccess: translationGroups.NotificationTitles + 'UpdateSuccess',
    UpdateError: translationGroups.NotificationTitles + 'UpdateError',
    KycSuccess: translationGroups.NotificationTitles + 'KycSuccess',
    KycUpdateYouDontHavePermission: translationGroups.NotificationTitles + 'KycUpdateYouDontHavePermission',
    DepositTerminationSuccess: translationGroups.NotificationTitles + 'DepositTerminationSuccess',
    PaymentTypeCanChangeToInst: translationGroups.NotificationTitles + 'PaymentTypeCanChangeToInst',
    WriteOnlyNumbers: translationGroups.NotificationTitles + 'WriteOnlyNumbers',
    IsUnsuccessfull: translationGroups.NotificationTitles + 'IsUnsuccessfull',
    DepositAmountNotValid: translationGroups.NotificationTitles + 'DepositAmountNotValid',
    NeedCheckLater: translationGroups.NotificationTitles + 'NeedCheckLater',
    Copied: translationGroups.NotificationTitles + 'Copied',
    PaymentAmountIncorrect: translationGroups.NotificationTitles + 'PaymentAmountIncorrect',
    NewVersionAvailable: translationGroups.NotificationTitles + 'NewVersionAvailable',
    PostSignMandateError: translationGroups.NotificationTitles + 'PostSignMandateError',
    NewMandateCreated: translationGroups.NotificationTitles + 'NewMandateCreated',
    MandateDeleted: translationGroups.NotificationTitles + 'MandateDeleted',
  },
  PlaceHolders: {
    CreateGroup: translationGroups.PlaceHolders + 'CreateGroup',
    PaymentGroupSearch: translationGroups.PlaceHolders + 'PaymentGroupSearch',
    PaymentTemplateSearch: translationGroups.PlaceHolders + 'PaymentTemplateSearch',
    SelectGroup: translationGroups.PlaceHolders + 'SelectGroup',
    DateFormField: translationGroups.PlaceHolders + 'DateFormField',
  },
  PayerPayeeTypes: {
    None: translationGroups.PayerPayeeTypes + 'None',
    Individual: translationGroups.PayerPayeeTypes + 'Individual',
    Legal: translationGroups.PayerPayeeTypes + 'Legal',
  },
  PaymentParts: {
    AdditionalInformation: translationGroups.PaymentParts + 'AdditionalInformation',
    Contribution: translationGroups.PaymentParts + 'Contribution',
    InitialPayerInformation: translationGroups.PaymentParts + 'InitialPayerInformation',
    InitialPayeeInformation: translationGroups.PaymentParts + 'InitialPayeeInformation',
    Payee: translationGroups.PaymentParts + 'Payee',
    PayeeInformation: translationGroups.PaymentParts + 'PayeeInformation',
    Payer: translationGroups.PaymentParts + 'Payer',
    PayerInformation: translationGroups.PaymentParts + 'PayerInformation',
    FinalPayeeInformation: translationGroups.PaymentParts + 'FinalPayeeInformation',
    PaymentType: translationGroups.PaymentParts + 'PaymentType',
  },
  PaymentPurposeTypes: {
    Purpose: translationGroups.PaymentPurposeTypes + 'Purpose',
    Code: translationGroups.PaymentPurposeTypes + 'Code',
  },
  PaymentStatuses: {
    AllOperations: translationGroups.PaymentStatuses + 'AllOperations',
    Ongoing: translationGroups.PaymentStatuses + 'Ongoing',
    Completed: translationGroups.PaymentStatuses + 'Completed',
    Rejected: translationGroups.PaymentStatuses + 'Rejected',
    InsufficientBalance: translationGroups.PaymentStatuses + 'InsufficientBalance',
  },
  PaymentTypes: {
    FeePayment: translationGroups.PaymentTypes + 'FeePayment',
    NewPayment: translationGroups.PaymentTypes + 'NewPayment',
    OwnAccounts: translationGroups.PaymentTypes + 'OwnAccounts',
  },
  PopConfirms: {
    DoYouWantDeletePaymentGroup: translationGroups.PopConfirms + 'DoYouWantDeletePaymentGroup',
    DoYouWantDeletePaymentTemplate: translationGroups.PopConfirms + 'DoYouWantDeletePaymentTemplate',
  },
  Period: {
    Day: translationGroups.Period + 'Day',
    Week: translationGroups.Period + 'Week',
    Month: translationGroups.Period + 'Month',
    Quarter: translationGroups.Period + 'Quarter',
    TenLastOperations: translationGroups.Period + 'TenLastOperations',
    LastSevenDaysOperations: translationGroups.Period + 'LastSevenDaysOperations',
    LastThirtyDaysOperations: translationGroups.Period + 'LastThirtyDaysOperations',
    FromBeginningCurrentMonth: translationGroups.Period + 'FromBeginningCurrentMonth',
    LastMonth: translationGroups.Period + 'LastMonth',
    Days30: translationGroups.Period + 'Days30',
    Days60: translationGroups.Period + 'Days60',
    Days90: translationGroups.Period + 'Days90',
  },
  PrintTitles: {
    Invoice: translationGroups.PrintTitles + 'Invoice',
  },
  PrintLabels: {
    CompanyTitle: translationGroups.PrintLabels + 'CompanyTitle',
    CompanyAddress: translationGroups.PrintLabels + 'CompanyAddress',
    CompanyCode: translationGroups.PrintLabels + 'CompanyCode',
    CompanyEmail: translationGroups.PrintLabels + 'CompanyEmail',
    CompanyPhone: translationGroups.PrintLabels + 'CompanyPhone',
    ClientAccount: translationGroups.PrintLabels + 'ClientAccount',
    ClientAccountNumber: translationGroups.PrintLabels + 'ClientAccountNumber',
    ClientAddress: translationGroups.PrintLabels + 'ClientAddress',
    ClientTitle: translationGroups.PrintLabels + 'ClientTitle',
    Client: translationGroups.PrintLabels + 'Client',
    ClientCode: translationGroups.PrintLabels + 'ClientCode',
    PrintDate: translationGroups.PrintLabels + 'PrintDate',
    VatCode: translationGroups.PrintLabels + 'VatCode',
    FormattedDateAndTime: translationGroups.PrintLabels + 'FormattedDateAndTime',
    ClientIBAN: translationGroups.PrintLabels + 'ClientIBAN',
    PayerAccountNumber: translationGroups.PrintLabels + 'PayerAccountNumber',
    PayerName: translationGroups.PrintLabels + 'PayerName',
    PayerIdentCode: translationGroups.PrintLabels + 'PayerIdentCode',
    PayerAddress: translationGroups.PrintLabels + 'PayerAddress',
    PayerBank: translationGroups.PrintLabels + 'PayerBank',
    InitPayerIdentTypeValue: translationGroups.PrintLabels + 'InitPayerIdentTypeValue',
    InitPayerName: translationGroups.PrintLabels + 'InitPayerName',
    PayeeAccountNumber: translationGroups.PrintLabels + 'PayeeAccountNumber',
    PayeeName: translationGroups.PrintLabels + 'PayeeName',
    PayeeIdentCode: translationGroups.PrintLabels + 'PayeeIdentCode',
    PayeeAddress: translationGroups.PrintLabels + 'PayeeAddress',
    PayeeBank: translationGroups.PrintLabels + 'PayeeBank',
    FinalPayeeIdentTypeValue: translationGroups.PrintLabels + 'FinalPayeeIdentTypeValue',
    FinalPayeeName: translationGroups.PrintLabels + 'FinalPayeeName',
    PaymentAmount: translationGroups.PrintLabels + 'PaymentAmount',
    CommFeeFormatted: translationGroups.PrintLabels + 'CommFeeFormatted',
    PaymentPurpose: translationGroups.PrintLabels + 'PaymentPurpose',
    PaymentUniqueCode: translationGroups.PrintLabels + 'PaymentUniqueCode',
    PrintInterval: translationGroups.PrintLabels + 'PrintInterval',
    OperationDate: translationGroups.PrintLabels + 'OperationDate',
    DocumentNumber: translationGroups.PrintLabels + 'DocumentNumber',
    DateDisplay: translationGroups.PrintLabels + 'DateDisplay',
    LoanAmount: translationGroups.PrintLabels + 'LoanAmount',
    InterestAmount: translationGroups.PrintLabels + 'InterestAmount',
    Installment: translationGroups.PrintLabels + 'Installment',
    LoanResidual: translationGroups.PrintLabels + 'LoanResidual',
    DepositPDFName: translationGroups.PrintLabels + 'DepositPDFName',
  },
  Steps: {
    PaymentPreFill: translationGroups.Steps + 'PaymentPreFill',
    PaymentFill: translationGroups.Steps + 'PaymentFill',
    PaymentPreview: translationGroups.Steps + 'PaymentPreview',
    PaymentSuccess: translationGroups.Steps + 'PaymentSuccess',
    PaymentPreFillDescription: translationGroups.Steps + 'PaymentPreFillDescription',
    PaymentFillDescription: translationGroups.Steps + 'PaymentFillDescription',
    PaymentPreviewDescription: translationGroups.Steps + 'PaymentPreviewDescription',
    PaymentSuccessDescription: translationGroups.Steps + 'PaymentSuccessDescription',
    DepositFill: translationGroups.Steps + 'DepositFill',
    DepositPreview: translationGroups.Steps + 'DepositPreview',
    DepositSuccess: translationGroups.Steps + 'DepositSuccess',
    DepositFillDescription: translationGroups.Steps + 'DepositFillDescription',
    DepositPreviewDescription: translationGroups.Steps + 'DepositPreviewDescription',
    DepositSuccessDescription: translationGroups.Steps + 'DepositSuccessDescription',
    MainInformacion: translationGroups.Steps + 'MainInformacion',
    PersonDocuments: translationGroups.Steps + 'PersonDocuments',
    ContactInformacion: translationGroups.Steps + 'ContactInformacion',
    TaxInformation: translationGroups.Steps + 'TaxInformation',
    Income: translationGroups.Steps + 'Income',
    PepLinks: translationGroups.Steps + 'PepLinks',
    OtherInformation: translationGroups.Steps + 'OtherInformation',
    MainInformacionDescription: translationGroups.Steps + 'MainInformacionDescription',
    PersonDocumentsDescription: translationGroups.Steps + 'PersonDocumentsDescription',
    ContactInformacionDescription: translationGroups.Steps + 'ContactInformacionDescription',
    TaxInformationDescription: translationGroups.Steps + 'TaxInformationDescription',
    IncomeDescription: translationGroups.Steps + 'IncomeDescription',
    PepLinksDescription: translationGroups.Steps + 'PepLinksDescription',
    OtherInformationDescription: translationGroups.Steps + 'OtherInformationDescription',
    ClientCeo: translationGroups.Steps + 'ClientCeo',
    ClientCeoDescription: translationGroups.Steps + 'ClientCeoDescription',
    BusinessType: translationGroups.Steps + 'BusinessType',
    BusinessTypeDescription: translationGroups.Steps + 'BusinessTypeDescription',
    ClientPlanTurnover: translationGroups.Steps + 'ClientPlanTurnover',
    ClientPlanTurnoverDescription: translationGroups.Steps + 'ClientPlanTurnoverDescription',
    ClientTax: translationGroups.Steps + 'ClientTax',
    ClientTaxDescription: translationGroups.Steps + 'ClientTaxDescription',
    AccountsInOtherBank: translationGroups.Steps + 'AccountsInOtherBank',
    AccountsInOtherBankDescription: translationGroups.Steps + 'AccountsInOtherBankDescription',
    ClientBeneficiaries: translationGroups.Steps + 'ClientBeneficiaries',
    ClientBeneficiariesDescription: translationGroups.Steps + 'ClientBeneficiariesDescription',
  },
  Tooltips: {
    PaymentGroupUse: translationGroups.Tooltips + 'PaymentGroupUse',
    PaymentGroupEdit: translationGroups.Tooltips + 'PaymentGroupEdit',
    PaymentGroupDelete: translationGroups.Tooltips + 'PaymentGroupDelete',
    PaymentTemplateDelete: translationGroups.Tooltips + 'PaymentTemplateDelete',
    PaymentTemplateEdit: translationGroups.Tooltips + 'PaymentTemplateEdit',
    PaymentTemplateUse: translationGroups.Tooltips + 'PaymentTemplateUse',
  },
  Validations: {
    IBANSame: translationGroups.Validations + 'IBANSame',
    InsufficientFunds: translationGroups.Validations + 'InsufficientFunds',
    NoWhiteSpaceAllowed: translationGroups.Validations + 'NoWhiteSpaceAllowed',
    NoWhiteSpaceAllowedInFrontOrEnd: translationGroups.Validations + 'NoWhiteSpaceAllowedInFrontOrEnd',
    CountersRequiredFrom: translationGroups.Validations + 'CountersRequiredFrom',
    CountersRequiredTo: translationGroups.Validations + 'CountersRequiredTo',
    CountersValuesNotValid: translationGroups.Validations + 'CountersValuesNotValid',
    FieldRequired: translationGroups.Validations + 'FieldRequired',
    FieldRequiredAndApprove: translationGroups.Validations + 'FieldRequiredAndApprove',
    FieldRequiredApproveCode: translationGroups.Validations + 'FieldRequiredApproveCode',
    PasswordCantContainSpaces: translationGroups.Validations + 'PasswordCantContainSpaces',
    PasswordMustContainUpperLowerNumber: translationGroups.Validations + 'PasswordMustContainUpperLowerNumber',
    PasswordNotSame: translationGroups.Validations + 'PasswordNotSame',
    PasswordTooShort: translationGroups.Validations + 'PasswordTooShort',
    InputIsTooLong: translationGroups.Validations + 'InputIsTooLong',
    MaxInputIs: translationGroups.Validations + 'MaxInputIs',
    MustBeCodeSelected: translationGroups.Validations + 'MustBeCodeSelected',
    MustBePurposeSelected: translationGroups.Validations + 'MustBePurposeSelected',
    SumToBig: translationGroups.Validations + 'SumToBig',
    SameAccountID: translationGroups.Validations + 'SameAccountID',
    BadPersonalCode: translationGroups.Validations + 'BadPersonalCode',
    BadCompanyCode: translationGroups.Validations + 'BadCompanyCode',
    ChatMessageRequired: translationGroups.Validations + 'ChatMessageRequired',
    FieldEmail: translationGroups.Validations + 'FieldEmail',
    identificationCodeValueNotValid: translationGroups.Validations + 'identificationCodeValueNotValid',
    FieldsRequired: translationGroups.Validations + 'FieldsRequired',
    FieldPhoneNumber: translationGroups.Validations + 'FieldPhoneNumber',
    FieldPhonePrefix: translationGroups.Validations + 'FieldPhonePrefix',
    InputIsTooShort: translationGroups.Validations + 'InputIsTooShort',
    MinInputIs: translationGroups.Validations + 'MinInputIs',
    AllFieldsRequired: translationGroups.Validations + 'AllFieldsRequired',
    PasswordTooLong: translationGroups.Validations + 'PasswordTooLong',
    IBANNotValid: translationGroups.Validations + 'IBANNotValid',
    NumberTooBig: translationGroups.Validations + 'NumberTooBig',
    NumberTooSmall: translationGroups.Validations + 'NumberTooSmall',
    ToShortMin5: translationGroups.Validations + 'ToShortMin5',
    IncorrectCurrency: translationGroups.Validations + 'IncorrectCurrency',
    FieldOnlyLettersAndNumbers: translationGroups.Validations + 'FieldOnlyLettersAndNumbers',
    FieldOnlyCAPLettersAndNumbers: translationGroups.Validations + 'FieldOnlyCAPLettersAndNumbers',
    IBANLenghtMustBe: translationGroups.Validations + 'IBANLenghtMustBe',
    IBANIsTooShort: translationGroups.Validations + 'IBANIsTooShort',
    'MinNumberValue{min}': translationGroups.Validations + 'MinNumberValue{min}',
  },
  AccountType: {
    Company: translationGroups.AccountType + 'Company',
    Individual: translationGroups.AccountType + 'Individual',
  },
  PaymensValidations: {
    InvalidIBAN: translationGroups.PaymensValidations + 'InvalidIBAN',
    InvalidIBANLetters: translationGroups.PaymensValidations + 'InvalidIBANLetters',
    INRBeneficiaryAddressError: translationGroups.PaymensValidations + 'INRBeneficiaryAddressError',
    AUDBeneficiaryAddressError: translationGroups.PaymensValidations + 'AUDBeneficiaryAddressError',
    CNYBeneficiaryBankNameError: translationGroups.PaymensValidations + 'CNYBeneficiaryBankNameError',
    BeneficiaryBankCode3SymbolsError: translationGroups.PaymensValidations + 'BeneficiaryBankCode3SymbolsError',
    BeneficiaryBankCode4SymbolsError: translationGroups.PaymensValidations + 'BeneficiaryBankCode4SymbolsError',
    BeneficiaryBankCode45SymbolsError: translationGroups.PaymensValidations + 'BeneficiaryBankCode45SymbolsError',
    BeneficiaryIdentificationValueNumbersLetters255Error: translationGroups.PaymensValidations + 'BeneficiaryIdentificationValueNumbersLetters255Error',
    BeneficiaryIdentificationValueNumbersLettersSpecSymbols255Error: translationGroups.PaymensValidations + 'BeneficiaryIdentificationValueNumbersLettersSpecSymbols255Error',
    CNYBeneficiaryIdentificationValueError: translationGroups.PaymensValidations + 'CNYBeneficiaryIdentificationValueError',
    PurposeLetterNumbersSymbolsError: translationGroups.PaymensValidations + 'PurposeLetterNumbersSymbolsError',
    PurposeLetterNumbersError: translationGroups.PaymensValidations + 'PurposeLetterNumbersError',
    CNYPurposeSpecialCharacterError: translationGroups.PaymensValidations + 'CNYPurposeSpecialCharacterError',
    KRWBeneficiaryAccountNumberError: translationGroups.PaymensValidations + 'KRWBeneficiaryAccountNumberError',
    GBPAcountNumberValidationError: translationGroups.PaymensValidations + 'GBPAcountNumberValidationError',
    EURAcountNumberValidationError: translationGroups.PaymensValidations + 'EURAcountNumberValidationError',
    NOKAcountNumberValidationError: translationGroups.PaymensValidations + 'NOKAcountNumberValidationError',
    DKKAcountNumberValidationError: translationGroups.PaymensValidations + 'DKKAcountNumberValidationError',
    SEKAcountNumberValidationError: translationGroups.PaymensValidations + 'SEKAcountNumberValidationError',
    PLNHUFRONAcountNumberValidationError: translationGroups.PaymensValidations + 'PLNHUFRONAcountNumberValidationError',
    CNYContactNumberError: translationGroups.PaymensValidations + 'CNYContactNumberError',
    KRWContactNumberError: translationGroups.PaymensValidations + 'KRWContactNumberError',
    InvalidNameError: translationGroups.PaymensValidations + 'InvalidNameError',
    InvalidPurposeError: translationGroups.PaymensValidations + 'InvalidPurposeError',
    InvalidPostCodeError: translationGroups.PaymensValidations + 'InvalidPostCodeError',
    InvalidBICCodeError: translationGroups.PaymensValidations + 'InvalidBICCodeError',
    InvalidPaymentCodeCodeError: translationGroups.PaymensValidations + 'InvalidPaymentCodeCodeError',
    InvalidCustomersCodeError: translationGroups.PaymensValidations + 'InvalidCustomersCodeError',
  },
  PaymentUpload: {
    Z001: translationGroups.PaymentUpload + 'Z001',
    Z002: translationGroups.PaymentUpload + 'Z002',
    Z003: translationGroups.PaymentUpload + 'Z003',
    Z004: translationGroups.PaymentUpload + 'Z004',
    Z005: translationGroups.PaymentUpload + 'Z005',
    Z006: translationGroups.PaymentUpload + 'Z006',
    Z007: translationGroups.PaymentUpload + 'Z007',
    Z008: translationGroups.PaymentUpload + 'Z008',
    Z009: translationGroups.PaymentUpload + 'Z009',
    Z0010: translationGroups.PaymentUpload + 'Z0010',
    Z0011: translationGroups.PaymentUpload + 'Z0011',
    Z0012: translationGroups.PaymentUpload + 'Z0012',
    Z0013: translationGroups.PaymentUpload + 'Z0013',
  },
  ANTDPagination: {
    ItemsPerPage: translationGroups.ANTDPagination + 'ItemsPerPage',
    JumpTo: translationGroups.ANTDPagination + 'JumpTo',
    JumpToConfirm: translationGroups.ANTDPagination + 'JumpToConfirm',
    Page: translationGroups.ANTDPagination + 'Page',
    PrevPage: translationGroups.ANTDPagination + 'PrevPage',
    NextPage: translationGroups.ANTDPagination + 'NextPage',
    Prev5: translationGroups.ANTDPagination + 'Prev5',
    Next5: translationGroups.ANTDPagination + 'Next5',
    Prev3: translationGroups.ANTDPagination + 'Prev3',
    Next3: translationGroups.ANTDPagination + 'Next3',
  },
  ANTDEmpty: {
    Description: translationGroups.ANTDEmpty + 'Description',
  },
  Enums: {
    Countries: {
      LT: translationGroups.Countries + 'LT',
      LV: translationGroups.Countries + 'LV',
      EE: translationGroups.Countries + 'EE',
      NL: translationGroups.Countries + 'NL',
      FI: translationGroups.Countries + 'FI',
      Other: translationGroups.Countries + 'Other',
    },
  },
};
