import { CURRENCIES } from "./constants"

export const checkJsonProp = function(object, key) {
  try {
    let keys = key.split('.')
    object = object[keys[0]]
    for (let i = 1; i < keys.length; i++) {
      object = object[keys[i]]
    }
    if (object === undefined) {
      return null
    }
    return object
  } catch (e) {
    return null
  }
}

export const checkJsonExist = function(object, key) {
  try {
    let keys = key.split('.')
    object = object[keys[0]]
    for (let i = 1; i < keys.length; i++) {
      object = object[keys[i]]
    }
    return object !== undefined
  } catch (e) {
    return null
  }
}

export const checkJsonTranslateProp = function(object, key) {
  try {
    let keys = key.replace(/\./, '-i18-').split('-i18-')
    object = object[keys[0]]
    for (let i = 1; i < keys.length; i++) {
      object = object[keys[i]]
    }
    if (object === undefined) {
      return null
    }
    return object
  } catch (e) {
    return null
  }
}

export const isCurrencyEUR = (currency) => {
  const { EUR } = CURRENCIES;
  return currency === EUR || currency?.indexOf(`_${EUR}`) !== -1
}