/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Collapse, Card, List } from 'antd';
import { DownOutlined, RightOutlined } from '@ant-design/icons';

import { OnGoingOperationsContainer, OperationSevenDaysRejected, LastOperationContainer } from './Components';

import { routerPath } from 'src/Config';
import { AccountsContainer } from 'src/Containers';
import { i18, trb, checkJsonProp, CustomIcon, useGetWindowSize } from 'src/Utilities';
import { MenuActions } from 'src/Redux';
import { useNavigate } from 'react-router-dom';

export const SummaryContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [operationOpenKey, setOperationOpenKey] = useState([1, 2]);
  const { COMPANY, LLO } = useSelector((state) => state.AMCReducers);
  const { ROL } = useSelector((state) => state.OMCReducers);
  const { mobileCheck } = useGetWindowSize();

  useEffect(() => {
    if (!mobileCheck) dispatch(MenuActions.setTitle(checkJsonProp(COMPANY, 'OrgName')));
    else dispatch(MenuActions.setTitle(null));
  }, []);

  useEffect(() => {
    if (LLO && LLO.Operations && LLO.Operations.length > 0) toggleOperationOpenKey(1, true);
    if (LLO && LLO.Operations && LLO.Operations.length > 0) toggleOperationOpenKey(1, true);
    if (ROL && ROL.Operations && ROL.Operations.length === 0) toggleOperationOpenKey(2, false);
    if (ROL && ROL.Operations && ROL.Operations.length === 0) toggleOperationOpenKey(2, false);
  }, [LLO, ROL]);

  const toggleOperationOpenKey = (item, set = 0) => {
    if (set === 0) {
      setOperationOpenKey(item);
    } else {
      const intItem = parseInt(item);
      const cloned = [...operationOpenKey];
      const findIndex = cloned.indexOf(intItem);

      if (set) cloned.push(intItem);
      else cloned.splice(findIndex, 1);
      setOperationOpenKey(cloned);
    }
  };

  const quickOperations = [
      {
        title: i18.ContentMenu.PaymentTemplates,
        icon: 'dashboardPaymentTemplate',
        type: 'custom',
        path: `${routerPath.payments}/${routerPath.paymentTemplates}`,
      },
      {
        title: i18.PaymentTypes.NewPayment,
        icon: 'dashboardNewPayment',
        type: 'custom',
        path: `${routerPath.payments}/${routerPath.paymentNew}`,
      },
      {
        title: i18.Menu.Invoice,
        icon: 'dashboardInvoice',
        type: 'custom',
        path: routerPath.invoice,
      },
      {
        title: i18.Menu.Operations,
        icon: 'dashboardOperations',
        type: 'custom',
        path: routerPath.operations,
      },
    ],
    clickNewPayment = () => {
      navigate(`${routerPath.payments}/${routerPath.paymentNew}`);
    };

  return (
    <>
      <OperationSevenDaysRejected />
      <Collapse
        className="eb_collapse eb_quick_operations_panel"
        bordered={false}
        defaultActiveKey="1"
        expandIcon={() => null}
        expandIconPosition="end"
        items={[
          {
            key: '1',
            label: (
              <div className="eb_subtitle">
                {trb(i18.Labels.QuickOperations)}
                <Button
                  id="QuickOperationsPanelButton"
                  shape="circle"
                  ghost="true"
                  size="small"
                  className="ml-3"
                >
                  +
                </Button>
              </div>
            ),
            children: (
              <List
                grid={{ gutter: 8, column: 4 }}
                dataSource={quickOperations}
                className="eb_quick_operations_list"
                renderItem={(item) => (
                  <List.Item>
                    <Card
                      id={`QuickOperationsItem${item.path.replace(/\//gi, '')}`}
                      onClick={() => navigate(item.path)}
                      title={
                        <CustomIcon
                          size={50}
                          className="opacity-50"
                          icon={item.icon}
                          type={item.type}
                        />
                      }
                      bordered={false}
                      hoverable
                    >
                      <div className="w-100">{trb(item.title)}</div>
                      <div>
                        <RightOutlined />
                      </div>
                    </Card>
                  </List.Item>
                )}
              />
            ),
          },
        ]}
      />
      <Collapse
        className="eb_accounts_panel eb_collapse"
        bordered={false}
        defaultActiveKey="2"
        expandIcon={() => null}
        expandIconPosition="end"
        items={[
          {
            key: '2',
            label: (
              <div
                className="eb_subtitle"
                style={{ width: '100%', paddingRight: 20, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <div>
                  {trb(i18.Labels.Bills)}
                  <Button
                    id="BillsPanelButton"
                    shape="circle"
                    ghost="true"
                    size="small"
                    className="ml-3 d-none d-md-inline"
                  >
                    +
                  </Button>
                </div>
                <Button
                  id="NewPaymentPanelButton"
                  onClick={clickNewPayment}
                  className="eb_account_panel_button"
                >
                  <span>{trb(i18.PaymentTypes.NewPayment)}</span>
                  <span className="eb_account_panel_button_icon">+</span>
                </Button>
              </div>
            ),
            children: <AccountsContainer hideTitle={true} />,
          },
        ]}
      />
      <Collapse
        className="eb_collapse"
        bordered={false}
        activeKey={operationOpenKey}
        expandIcon={({ isActive, id }) => (
          <DownOutlined
            id={`${id}CollapseButton`}
            rotate={isActive ? 180 : 0}
          />
        )}
        expandIconPosition="end"
        onChange={toggleOperationOpenKey}
        items={[
          { key: 1, label: trb(i18.Labels.RecentOperations), children: <LastOperationContainer />, id: 'LastOperationContainer' },
          { key: 2, label: trb(i18.Labels.OperationsInProgress), children: <OnGoingOperationsContainer />, id: 'OnGoingOperationsContainer' },
        ]}
      />
    </>
  );
};

