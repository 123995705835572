import {FeeConstants} from './constants';

const initialState = {}

export const FeeReducers = function (state = initialState, action) {
    switch (action.type) {
        case FeeConstants.GET_FEE_LIST_REQUEST:
            return {
                ...state,
                send: true,
                sendFeeList: true,
                feeList: null,
            };
        case FeeConstants.GET_FEE_LIST_SUCCESS:
            return {
                ...state,
                send: false,
                sendFeeList: false,
                feeList: action.payload,
            };
        case FeeConstants.GET_FEE_LIST_ERROR:
            return {
                ...state,
                send: false,
                sendFeeList: false,
                error: action.error
            };
        case FeeConstants.GET_FEE_PAYMENT_REQUEST:
            return {
                ...state,
                send: true,
                sendFeePayment: true,
                feePayment: null
            };
        case FeeConstants.GET_FEE_PAYMENT_SUCCESS:
            return {
                ...state,
                send: false,
                sendFeePayment: false,
                feePayment: action.payload,
            };
        case FeeConstants.GET_FEE_PAYMENT_ERROR:
            return {
                ...state,
                send: false,
                sendFeePayment: false,
                errors: action.error
            };
        case FeeConstants.POST_FEE_LAST_FEE_REQUEST:
            return {
                ...state,
                send: true,
                sendLastFee: true,
                LastFee: null
            };
        case FeeConstants.POST_FEE_LAST_FEE_SUCCESS:
            return {
                ...state,
                send: false,
                sendLastFee: false,
                LastFee: action.payload,
            };
        case FeeConstants.POST_FEE_LAST_FEE_ERROR:
            return {
                ...state,
                send: false,
                sendLastFee: false,
                errors: action.error
            };
        default:
            return state;
    }
};

