import React from 'react';
import PropTypes from 'prop-types';

import { checkJsonProp, CURRENCIES, i18, Payment, translationGroups, trb } from 'src/Utilities';
import { FormPreviewSwitch, OperationApproval } from 'src/Components';
import { Alert } from 'antd';

export const PaymentPreview = (props) => {
  const InitialAccountData = checkJsonProp(props, 'PTR.InitialAccounts');
  const DestinationAccountData = checkJsonProp(props, 'PTR.DestinationAccounts');
  const InitialAccountID = checkJsonProp(props, 'data.Payment.InitialAccountID');
  const DestinationAccountID = checkJsonProp(props, 'data.Payment.DestinationAccountID');

  const InitialAccountOne =
    InitialAccountData?.find((x) => x.AccountID === InitialAccountID) ?? props?.forexPayments?.InitialAccounts?.find((x) => x.AccountID === InitialAccountID);
  const InitialAccount = InitialAccountOne?.AccountNumber;

  const DestinationAccountOne = DestinationAccountData ? DestinationAccountData.find((x) => x.AccountID === DestinationAccountID) : null;
  const DestinationAccount = DestinationAccountOne?.AccountNumber;

  const PaymentsTotalAmount = props.PVR?.Payment?.AmountFormatted;
  const PaymentsTotalCurrency =
    props?.data?.Payment?.Currency ||
    checkJsonProp(
      props.PTR.InitialAccounts.find((item) => item.AccountID === props.PVR?.Payment?.InitialAccountID),
      'CurrencyName'
    );

  const data = {
    ...props.PVR,
    InitialAccount,
    DestinationAccount,
    oldData: {
      ...props.data,
      Payment: {
        PaymentType: props.data.Payment.PaymentType ?? '2',
        IsAdditionalDataSelected: props.data.Payment.IsAdditionalDataSelected ?? false,
      },
    },
    forexDraftInfo: props.forexDraftInfo,
  };

  const buttons = [];
  if (props.success) {
    if (props.close) {
      buttons.push({
        title: i18.Buttons.Close,
        func: props.close,
      });
    }
    if (props.resetPayment) {
      buttons.push({
        title: i18.Buttons.NewPayment,
        func: props.resetPayment,
        props: {
          icon: null,
          type: 'primary',
        },
      });
    }
  } else {
    if (!props.approve && !props.saveSign) {
      buttons.push({
        title: i18.Buttons.Edit,
        func: props.edit,
        props: {
          icon: null,
          disabled: props.submitting || props.submittingSave || props.approve,
        },
      });
      if (
        props.paymentType !== Payment.OWN_ACCOUNTS &&
        props.paymentType !== Payment.FOREX_EXCHANGE &&
        (!data.oldData.Payment?.Currency || data.oldData.Payment.Currency === CURRENCIES.EUR)
      ) {
        buttons.push({
          title: i18.Buttons.SaveToSign,
          func: props.paymentSaveToSign,
          props: {
            icon: null,
            disabled: props.submitting,
            loading: props.submittingSave,
          },
        });
      }
      buttons.push({
        title: i18.Buttons.Approve,
        func: props.submit,
        props: {
          icon: null,
          type: 'primary',
          htmlType: 'submit',
          disabled: props.submittingSave,
          loading: props.submitting,
        },
      });
    } else {
      buttons.push({
        title: i18.Buttons.NewPayment,
        func: () => {
          props.resetPayment(true);
        },
        props: {
          icon: null,
          disabled: props.submitting || props.approve,
        },
      });
    }
  }
  return (
    <>
      {props.infoData && props.infoData}
      {props.saveSign && (
        <Alert
          type="success"
          className="mb-3"
          message={trb(i18.Labels.SaveToSignSuccesseful)}
        />
      )}
      {props.errors && (
        <Alert
          className="mb-3"
          message={trb(i18.ErrorTitles.PaymentErrors)}
          description={
            <ul>
              {props.errors.map((item, index) => (
                <li key={index}>{trb(translationGroups.ErrorCode + item.ErrorCode)}</li>
              ))}
            </ul>
          }
          type="error"
        />
      )}
      <FormPreviewSwitch
        name={props.paymentType}
        data={data}
        PayPersonTypes={props.PayPersonTypes}
        buttons={props.approve ? null : props.noButtons ? null : buttons}
        edit={props.edit}
        success={props.success}
        checkForexSubmit={props.checkForexSubmit}
        forexTimerValues={props.forexTimerValues}
      />

      {props.approve && (
        <OperationApproval
          RQ_ID={props.RQ_ID}
          AuthType={props.AuthType}
          code={props.Code}
          cancelApprove={props.cancelApprove}
          successApprove={props.successApprove}
          submitSmsApprove={props.submitSmsApprove}
          submitMobileApprove={props.submitMobileApprove}
          approveSum={{
            sum: PaymentsTotalAmount,
            currency: PaymentsTotalCurrency,
          }}
        />
      )}
    </>
  );
};

PaymentPreview.propTypes = {
  paymentType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  submit: PropTypes.func,
  submitting: PropTypes.bool,
  submittingSave: PropTypes.bool,
  approve: PropTypes.bool,
  cancelApprove: PropTypes.func,
  successApprove: PropTypes.func,
};
