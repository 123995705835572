import { useState, useEffect } from 'react'

export const MOBILE_CHECK_SCREEN_WIDTH = 426
export const TABLET_CHECK_SCREEN_WIDTH = 768

/**
 * @returns {{mobileCheck: undefined, width: undefined, height: undefined}} client window size: {width:x, height y, mobileCheck: bool}
 */
export const useGetWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    mobileCheck: undefined,
    tabletCheck: undefined
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        mobileCheck: window.screen.width < MOBILE_CHECK_SCREEN_WIDTH,
        tabletCheck: window.screen.width < TABLET_CHECK_SCREEN_WIDTH
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}
