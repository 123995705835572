export const IDIN = ({ size = 29 }) => {
  return (
    <svg
      width={(size * 32) / 29}
      height={size}
      viewBox="0 0 32 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2_25709)">
        <path
          d="M0 2.1669V26.0158C0 26.5662 0.218652 27.094 0.607855 27.4833C0.997058 27.8725 1.52493 28.0911 2.07535 28.0911H16.3247C27.0926 28.0911 31.7621 22.0601 31.7621 14.0638C31.7621 6.09865 27.0926 0.0915527 16.3247 0.0915527H2.07535C1.52493 0.0915527 0.997058 0.310205 0.607855 0.699408C0.218652 1.08861 0 1.61648 0 2.1669Z"
          fill="white"
        />
        <path
          d="M16.3215 26.1849H3.92861C3.40038 26.1844 2.89394 25.9743 2.52043 25.6008C2.14691 25.2273 1.93683 24.7208 1.93628 24.1926V3.99014C1.93683 3.46191 2.14691 2.95547 2.52043 2.58195C2.89394 2.20843 3.40038 1.99835 3.92861 1.9978H16.3215C28.0753 1.9978 29.831 9.55726 29.831 14.0639C29.831 21.8827 25.0328 26.1849 16.3215 26.1849ZM3.92861 2.66191C3.57633 2.66219 3.23855 2.80218 2.98935 3.05118C2.74016 3.30018 2.5999 3.63786 2.59935 3.99014V24.1926C2.5999 24.5449 2.74016 24.8826 2.98935 25.1316C3.23855 25.3806 3.57633 25.5206 3.92861 25.5208H16.3215C24.6043 25.5208 29.1669 21.4521 29.1669 14.0639C29.1669 4.14371 21.1187 2.66191 16.3215 2.66191H3.92861Z"
          fill="black"
        />
        <path
          d="M9.53418 5.94712V23.5637H17.2026C24.1695 23.5637 27.1902 19.6268 27.1902 14.0731C27.1902 8.75299 24.1695 4.6189 17.2026 4.6189H10.8624C10.5101 4.6189 10.1723 4.75883 9.92321 5.00792C9.67412 5.25701 9.53418 5.59485 9.53418 5.94712Z"
          fill="#00B0F0"
        />
        <path
          d="M17.4609 11.699L19.2177 14.5225H19.2291V11.699H20.0955V15.9067H19.1699L17.4194 13.0895H17.408V15.9067H16.5415V11.699H17.4609Z"
          fill="white"
        />
        <path
          d="M15.7134 11.699H14.7878V15.9067H15.7134V11.699Z"
          fill="white"
        />
        <path
          d="M13.9992 12.8663C13.9135 12.6238 13.7765 12.4026 13.5976 12.2178C13.427 12.0442 13.2196 11.9112 12.9906 11.8286C12.7482 11.7408 12.492 11.6968 12.2341 11.6989H10.4182V15.9067H12.2341C12.5223 15.9134 12.8086 15.8582 13.0736 15.7448C13.303 15.6429 13.5066 15.4908 13.6692 15.2997C13.8326 15.1026 13.9538 14.8741 14.0252 14.6283C14.1063 14.3527 14.1462 14.0667 14.1435 13.7795C14.1495 13.469 14.1006 13.1598 13.9992 12.8663ZM13.1297 14.4571C13.0797 14.603 12.9994 14.7367 12.8941 14.8493C12.8004 14.9463 12.6853 15.0199 12.5579 15.0641C12.4328 15.1076 12.3012 15.1297 12.1688 15.1295H11.3386V12.4772H11.9986C12.1947 12.4716 12.39 12.5044 12.5735 12.5737C12.7205 12.6331 12.8495 12.7296 12.9481 12.8539C13.0479 12.9836 13.1187 13.1333 13.1556 13.2928C13.1981 13.4814 13.2187 13.6743 13.2168 13.8677C13.2223 14.0677 13.1928 14.2672 13.1297 14.4571Z"
          fill="white"
        />
        <path
          d="M6.06834 16.8572C6.45831 16.8572 6.83231 17.0121 7.10806 17.2878C7.38381 17.5636 7.53873 17.9376 7.53873 18.3276V23.5637C6.75851 23.5637 6.01025 23.2537 5.45856 22.702C4.90686 22.1503 4.59692 21.4021 4.59692 20.6219V18.3276C4.59692 18.1344 4.63499 17.9431 4.70895 17.7646C4.78291 17.5862 4.89131 17.424 5.02796 17.2875C5.1646 17.1509 5.32682 17.0426 5.50533 16.9688C5.68385 16.895 5.87516 16.857 6.06834 16.8572Z"
          fill="black"
        />
        <path
          d="M6.0682 15.7408C7.14045 15.7408 8.00968 14.8715 8.00968 13.7993C8.00968 12.727 7.14045 11.8578 6.0682 11.8578C4.99594 11.8578 4.12671 12.727 4.12671 13.7993C4.12671 14.8715 4.99594 15.7408 6.0682 15.7408Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_25709">
          <rect
            width="31.7621"
            height="28"
            fill="white"
            transform="translate(0 0.0913086)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
