import {aPIPaths, handleResponse, methods, requestEBank, requestEBankJSON, responseTypes} from '../../Config';

export const DMCServices = {
    postADPDF,
    postSPPDF,
    postSDR
};

function postADPDF(data, language) {
    return requestEBankJSON(aPIPaths.post_DMC_ADPDR, {
        method: methods.post,
        data: {
            ...data,
            LanguageStatement: language
        },
        responseType: responseTypes.arrayBuffer,
    }).then(handleResponse);
}

function postSPPDF(data, language) {
    return requestEBankJSON(aPIPaths.post_DMC_SPPDF, {
        method: methods.post,
        data: {
            ...data,
            LanguageStatement: language
        },
        responseType: responseTypes.arrayBuffer,
    }).then(handleResponse);
}

function postSDR(data) {
    return requestEBank(aPIPaths.post_DMC_SDR, {
        method: methods.post,
        data: data
    }).then(handleResponse);
}
