import PropTypes from 'prop-types';
import {notification} from 'antd';

import {trb} from "../../Utilities";

export const Notification = (props) => {
    const nothingFunc = item => item,
    translateDescriptionFunc = props.noTranslateDescription ? nothingFunc : trb,
    translateMessagesFunc = props.noTranslateMessage ? nothingFunc : trb;

    notification.open({
        //getContainer: () => document.getElementById('eb_print_none'),
        description: props.description ? translateDescriptionFunc(props.description) : null,
        duration: props.duration ? props.duration : 5,
        icon: props.icon,
        message: props.message ? translateMessagesFunc(props.message) : null,
        placement: props.placement ? props.placement : 'bottomRight',
        type: props.type
    });
};

PropTypes.propTypes = {
    description: PropTypes.string,
    duration: PropTypes.number,
    icon: PropTypes.bool,
    message: PropTypes.string,
    placement: PropTypes.string,
    type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired
};

