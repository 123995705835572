import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

import { i18, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { SettingsAccountsLimitsV2, SettingsAccountsManagement, SettingsBlockCard, SettingsKYCRenewContainer } from './';
import { MenuActions, UACActions, UACActiveUser } from 'src/Redux';
import { SlidersOutlined, TeamOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

export const SettingsContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const IsAdmin = useSelector(UACActiveUser)?.IsAdmin;

  useEffect(() => {
    const menuArray = [
      {
        title: i18.ContentMenu.AccountsLimits,
        icon: <SlidersOutlined />,
        type: 'legacy',
        path: routerPath.settings + '/accounts-limits',
        active: true,
      },
      IsAdmin
        ? {
            title: i18.ContentMenu.AccountsManagement,
            icon: <TeamOutlined />,
            type: 'legacy',
            path: routerPath.settings + '/accounts-management',
          }
        : null,
    ].filter((menu) => menu);

    dispatch(MenuActions.setContentMenu(menuArray, routerPath.settings));
  }, [IsAdmin]);

  const { ATR, sendATR } = useSelector((state) => state.UACReducers);

  useEffect(() => {
    if (location.pathname === routerPath.settings) {
      navigate(`${routerPath.settings}/${routerPath.settingsAccountsLimits}`);
    }
    if (!ATR && sendATR !== true) {
      dispatch(UACActions.postATR());
    }

    dispatch(MenuActions.setTitle(trb(i18.Container.SettingsContainer)));

    return () => {
      dispatch(MenuActions.setContentMenu(null));
    };
  }, []);

  return (
    <Routes>
      <Route
        path={routerPath.settingsAccountsLimits}
        element={<SettingsAccountsLimitsV2 />}
      />
      <Route
        path={routerPath.settingsBlockCard}
        element={<SettingsBlockCard />}
      />
      <Route
        path={routerPath.settingsAccountsManagement}
        element={<SettingsAccountsManagement />}
      />
      <Route
        path={routerPath.settingsKYCRenew}
        element={<SettingsKYCRenewContainer />}
      />
    </Routes>
  );
};

