import { Route, Routes } from 'react-router-dom';
import { Container } from 'src/Components';
import { BeneficiariesList, BeneficiariesNew } from './pages';
import { routerPath } from 'src/Config';
import { i18, trb } from 'src/Utilities';

export const Beneficiaries = () => {
  return (
    <>
      <div className="eb_content_subtitle">{trb(i18.ContentMenu.Beneficiaries)}</div>
      <Container cardClassName="py-0">
        <Routes>
          <Route
            index
            element={<BeneficiariesList />}
          />
          <Route
            path={routerPath.beneficiariesNew}
            element={<BeneficiariesNew />}
          />
        </Routes>
      </Container>
    </>
  );
};
