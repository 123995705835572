import React, { useEffect } from 'react';
import { Card, Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { LoginImage } from 'src/Assets/svg/LoginImage';
import { PageLoader } from 'src/Components/PageLoader';
import { ILoginForm, loginSteps } from 'src/Containers/LoginPage';
import { loginInitialValues } from './configs';
import { LoginMessages } from './LoginMessages';
import { useLocation, useNavigate } from 'react-router-dom';

const useLoginForm = (): [ILoginForm, React.Dispatch<React.SetStateAction<ILoginForm>>, number, React.Dispatch<React.SetStateAction<number>>] => {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = React.useState(location.state?.step ?? 0);
  const [data, setData] = React.useState<ILoginForm>(loginInitialValues);

  useEffect(() => {
    const storedData = window.sessionStorage.getItem('loginData');

    if (storedData) {
      setData((prevState) => ({ ...prevState, ...JSON.parse(storedData) }));

      navigate(location.pathname, {
        replace: true,
        state: { ...location.state, step: 0 },
      });
      window.sessionStorage.removeItem('loginData');
    }
  }, []);

  return [data, setData, step, setStep];
};

export const LoginPageContent = () => {
  const { translations } = useSelector((state: any) => state.translationReducer);

  const [data, setData, step, setStep] = useLoginForm();

  useEffect(() => {
    window.sessionStorage.setItem('loginData', JSON.stringify(data));
  }, [data]);

  if (!translations || translations.length < 2) {
    return <PageLoader />;
  }

  return (
    <Row className="login_infoblock">
      <Col
        md={12}
        xs={24}
      >
        <Card className="d-flex w-100 h-100 justify-content-center align-items-center login_card">
          <LoginMessages />
          <div className="login_card_wrapper">{React.createElement(loginSteps[step], { data, setData, setStep })}</div>
        </Card>
      </Col>
      <Col
        md={12}
        xs={24}
        className="d-none d-md-flex w-100 justify-content-center align-items-center py-5"
      >
        <LoginImage />
      </Col>
    </Row>
  );
};
