import {PrintConstants} from './';

const initialState = {}

export function printReducer(state = initialState, action) {
    switch (action.type) {
        case PrintConstants.PRINT_SHOW:
            return {
                ...state,
                show: true,
            };
        case PrintConstants.PRINT_HIDE:
            return {
                ...state,
                show: false,
            };
        case PrintConstants.PRINT_SET_CONTENT:
            return {
                ...state,
                content: action.payload
            };
        default:
            return state;
    }
}