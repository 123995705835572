import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Layout } from 'antd';
import { useNavigate } from 'react-router-dom';

import { CustomIcon, useGetWindowSize, i18, Logout, trb } from 'src/Utilities';
import { UserMessages, UserSelectContainer } from 'src/Components';
import { routerPath } from 'src/Config';
import { LanguageSelect } from 'src/Components';
import { MenuActions } from 'src/Redux';

const Header = Layout.Header;

export const PartHeader = () => {
  const windowSize = useGetWindowSize();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isRemote } = useSelector((state) => state.JWEReducer);
  const { menuCollapsed } = useSelector((state) => state.MenuReducer);

  const toggle = () => {
    dispatch(MenuActions.toggle());
  };
  const mobileBackClick = () => {
    navigate(routerPath.index);
    if (windowSize.mobileCheck) toggle();
  };

  return (
    <Header
      className="eb_header"
      style={{ backgroundColor: isRemote ? 'white' : 'unset' }}
      id="main_header"
    >
      <div className="eb_inner d-flex justify-content-between">
        {!isRemote && <div className="d-none d-md-inline"></div>}
        {!!isRemote && (
          <div className="sme_external_link_container">
            <Button
              id="HeaderMyDashboardButton"
              type="link"
              className="sme_external_link left"
            >
              <span className="label">{trb(i18.Labels.MyDashboard)}</span>
            </Button>
            <Button
              id="HeaderSMEBankButton"
              type="link"
              className="sme_external_link active"
            >
              <span className="label active">{trb(i18.Labels.SMEBank)}</span>
            </Button>
            <Button
              id="HeaderSMEFinanceButton"
              type="link"
              className="sme_external_link right"
            >
              <a
                href={import.meta.env.VITE_SMEF_URL}
                className="label"
              >
                {trb(i18.Labels.SMEFinance)}
              </a>
            </Button>
          </div>
        )}

        <div
          className="eb_menu_logo d-md-none"
          onClick={mobileBackClick}
        >
          {window.location.pathname === '/' ? (
            <img
              src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGO}`}
              id={'logo'}
              alt={import.meta.env.VITE_TITLE}
              onClick={() => navigate(routerPath.index)}
              className="cursor-pointer"
            />
          ) : (
            <CustomIcon
              icon="caretLeft"
              type="custom"
            />
          )}
        </div>
        <div className="eb_header_right justify-content-between">
          <div>
            <LanguageSelect
              withoutLabels
              className="eb_sme_language"
            />
          </div>
          <UserMessages />
          <div>
            <UserSelectContainer />
          </div>
          <div>
            <Button
              id="HeaderSettings"
              className="text-click d-none d-md-inline-block"
              onClick={() => {
                navigate(`${routerPath.settings}/${routerPath.settingsAccountsLimits}`);
              }}
              type="link"
            >
              <CustomIcon
                type="custom"
                icon="settings"
              />
            </Button>
            <Button
              id="HeaderLogout"
              className="text-click"
              onClick={() => Logout()}
              type="link"
            >
              <CustomIcon
                type="custom"
                icon="logout"
              />
            </Button>
            <Button
              id="HeaderMenuCollapse"
              className="text-click d-md-none"
              onClick={toggle}
              type="link"
            >
              <CustomIcon
                type="custom"
                icon={menuCollapsed ? 'close' : 'toggle'}
              />
            </Button>
          </div>
        </div>
      </div>
    </Header>
  );
};
