import { useField, useFormikContext } from 'formik';
import React from 'react';
import { ConfigProvider } from 'antd';

import { FormFieldError, FormFieldLabel } from './components';

import './index.scss';
import classNames from 'classnames';
import { formFieldId } from 'src/utils';

interface IProps {
  children: React.ReactElement;
  label?: string;
  name: string;
  disabled?: boolean;
  floating?: boolean;
  wrapperClassNames?: string;
  labelClassNames?: string;
  fieldClassNames?: string;
  error?: string | null;
}

/**
 * Wrapper for form field that includes some design and children component
 * @param children input element
 * @param name input element name objects deep marking as dot user.name etc
 * @param label input element label-title
 * @param disabled input element disabled status manually
 * @param floating for radio, checkbox etc
 * @param wrapperClassNames wrapper classnames
 * @param labelClassNames custom input label classnames
 * @param fieldClassNames custom classnames for fields
 * @param error custom error
 */

export const FormField = ({ children, label, name, disabled = false, floating = true, wrapperClassNames, labelClassNames, fieldClassNames, error }: IProps) => {
  const [focused, setFocused] = React.useState(false);
  const [_field, meta, _helpers] = useField(name);
  const { isSubmitting } = useFormikContext();

  const className = classNames('field_wrapper', wrapperClassNames, {
    floating: floating,
    disabled: disabled,
  });

  return (
    <ConfigProvider
      theme={{
        token: {
          controlHeight: 54,
          controlPaddingHorizontal: 24,
          colorTextDisabled: '#343434',
        },
        components: {
          Radio: {
            borderRadius: 24,
            buttonSolidCheckedColor: '#0958d9',
          },
        },
      }}
    >
      <>
        <div
          className={className}
          id={formFieldId(name, 'Wrapper')}
        >
          <div className="field_inner">
            <FormFieldLabel
              name={name}
              label={label}
              focused={focused}
              labelClassNames={labelClassNames}
            />
            <div
              className="field_component"
              id={formFieldId(name, '')}
            >
              {React.cloneElement(children, {
                name,
                label,
                disabled: disabled || isSubmitting,
                variant: floating ? 'borderless' : undefined,
                onFocus: () => setFocused(true),
                onBlur: () => setFocused(false),
                className: fieldClassNames,
                autoComplete: 'off',
                id: formFieldId(name, 'FormField'),
              })}
            </div>
          </div>
          {error ? (
            <FormFieldError
              id={formFieldId(name, 'Error')}
              error={error}
            />
          ) : (
            meta.error &&
            Boolean(meta.touched) && (
              <FormFieldError
                id={formFieldId(name, 'Error')}
                error={error || (meta.error && meta.touched) ? meta.error : undefined}
              />
            )
          )}
        </div>
      </>
    </ConfigProvider>
  );
};
