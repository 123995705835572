import { i18, trb } from '../../../../../Utilities';
import { FormField, Input } from 'src/shared';

export const FieldBeneficiaryAddress = () => {
  return (
    <FormField
      name="Payment.BeneficiaryAddress"
      label={trb(i18.Labels.BeneficiaryAddress)}
    >
      <Input />
    </FormField>
  );
};
