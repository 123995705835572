import React, { useEffect } from 'react';
import { matchPath, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';

import { routerPath } from 'src/Config';
import { IdentificationError, IdentificationSuccess, Main, WaitingRoom, LoginPage, IdentificationRoom, Ondato } from 'src/Containers';
import { CookiePolicy, PageLoader, PrivateRoute } from 'src/Components';
import { LanguageActions } from 'src/Redux';
import { i18, trb } from 'src/Utilities';

import en_GB from 'antd/lib/locale/en_GB';

export const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { translations } = useSelector((state) => state.translationReducer);
  const { language } = useSelector((state) => state.languageReducer);
  const { jweVerified } = useSelector((state) => state.JWEReducer);

  useEffect(() => {
    const { search } = location;
    const params = new URLSearchParams(search);
    const lan = params.get('lan');

    if (lan) {
      dispatch(LanguageActions.setLanguage(lan));
    }

    if (
      jweVerified &&
      window.location.pathname !== routerPath.identificationRoom &&
      window.location.pathname !== routerPath.login &&
      window.location.pathname !== routerPath.verification &&
      window.location.pathname !== routerPath.identificationError &&
      window.location.pathname !== routerPath.identificationSuccess &&
      !window.location.pathname.includes('waitingRoom')
    ) {
      navigate(routerPath.index);
    }
  }, []);

  useEffect(() => {
    const match = matchPath({ path: routerPath.waitingRoom, exact: true }, location.pathname);

    if (
      window.location.pathname.indexOf('waitingRoom') !== -1 ||
      window.location.pathname === routerPath.identificationRoom ||
      window.location.pathname === routerPath.identificationError ||
      window.location.pathname === routerPath.verification ||
      window.location.pathname === routerPath.identificationSuccess
    ) {
      return;
    }

    if (jweVerified && match) {
      navigate(routerPath.index);
    }
  }, [jweVerified]);

  if (jweVerified === false) {
    return <PageLoader />;
  }

  const ANTDTranslation = {
    ...en_GB,
    locale: language === 'lt-LT' ? 'lt-LT' : 'en-GB',
    Pagination: {
      items_per_page: trb(i18.ANTDPagination.ItemsPerPage),
      jump_to: trb(i18.ANTDPagination.JumpTo),
      jump_to_confirm: trb(i18.ANTDPagination.JumpToConfirm),
      page: trb(i18.ANTDPagination.Page),
      prev_page: trb(i18.ANTDPagination.PrevPage),
      next_page: trb(i18.ANTDPagination.NextPage),
      prev_5: trb(i18.ANTDPagination.Prev5),
      next_5: trb(i18.ANTDPagination.Next5),
      prev_3: trb(i18.ANTDPagination.Prev3),
      next_3: trb(i18.ANTDPagination.Next3),
    },
    Empty: {
      description: trb(i18.ANTDEmpty.Description),
    },
  };

  return (
    <ConfigProvider
      locale={ANTDTranslation}
      theme={{
        token: {
          colorPrimary: '#7154D6',
          colorLink: '#7154D6',
        },
        components: {
          Button: {
            borderRadiusLG: 100,
            borderRadiusSM: 100,
            borderRadiusXS: 100,
          },
        },
      }}
    >
      {translations?.length > 1 && (
        <>
          <Routes>
            <Route
              path={routerPath.login}
              element={<LoginPage />}
            />
            <Route
              path={routerPath.verification}
              element={<Ondato />}
            />
            <Route
              exact
              path={routerPath.identificationError}
              element={<IdentificationError />}
            />
            <Route
              exact
              path={routerPath.identificationSuccess}
              element={<IdentificationSuccess />}
            />
            <Route
              path={routerPath.loginLink}
              element={<LoginPage />}
            />
            <Route
              path={routerPath.waitingRoom}
              element={<WaitingRoom />}
            />
            <Route
              path={routerPath.identificationRoom}
              element={<IdentificationRoom />}
            />
            <Route
              path={routerPath.index + '*'}
              element={<PrivateRoute component={Main} />}
            />
          </Routes>
          <CookiePolicy />
        </>
      )}
    </ConfigProvider>
  );
};

App.propTypes = {
  translations: PropTypes.array,
};
