import React from 'react';

export const ChatHeader = (props) => {
    const {data, Subject} = props;

    if (!data) {
        return null;
    }

    return <div className="bbs_chat_header">
        <div className="bbs_chat_header_purpose"><strong><i>{data.ClientName}</i></strong> - {Subject}</div>
    </div>;
};
