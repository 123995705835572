/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AMCActions } from 'src/Redux';
import { Container, ContentLoader, ContentNoData } from 'src/Components';
import { i18, trb } from 'src/Utilities';
import { UserLimits, UserSelect } from './Components';

export const SettingsAccountsLimitsV2 = () => {
  const dispatch = useDispatch();

  const { managedUsers, sendManagedUsers } = useSelector((state: any) => state.AMCReducers);

  useEffect(() => {
    if (!managedUsers && !sendManagedUsers) {
      dispatch((AMCActions as any).getManagedUsers());
    }
    return () => {
      dispatch((AMCActions as any).clearUserLimits());
    };
  }, []);

  if (sendManagedUsers) {
    return <ContentLoader />;
  }

  return (
    <div>
      <div className="eb_content_subtitle">{trb(i18.ContentTitles.SettingsAccountsLimits)}</div>

      {!managedUsers ? (
        <ContentNoData id="managedUsers" />
      ) : (
        <Container>
          <UserSelect />
          <UserLimits />
        </Container>
      )}
    </div>
  );
};
