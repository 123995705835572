import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { i18, trb } from 'src/Utilities';

export const ContentLoader = (props) => {
  let { className, hover, style } = props;

  if (hover) {
    className = 'position-absolute d-flex flex-column align-items-center justify-content-center';
    style = { top: -15, left: -15, right: -15, bottom: -15, backgroundColor: 'rgb(247 247 247 / 0.6)', zIndex: 100 };
  }

  return (
    <div
      className={className ? `${className} eb_content_loader` : 'eb_content_loader'}
      style={style}
    >
      <div className="eb_content_loader_icon">
        <Spin
          indicator={
            <LoadingOutlined
              style={{ fontSize: props.size ? props.size : 48 }}
              spin
            />
          }
        />
      </div>
      <div className="eb_content_loader_title">{props.title ? props.title : trb(i18.Labels.Loading)}</div>
    </div>
  );
};
