import React from 'react';
import { Card, Spin } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { i18, translationGroups, trb } from 'src/Utilities';
import { useSelector } from 'react-redux';

export const OperationsSmeFilter = ({ handleChangeFilters }) => {
  const { sendODLPaging, ODLPagination } = useSelector((state) => state.OMCReducers);

  const Periods = [
    {
      label: 'Days30',
      value: 30,
    },
    {
      label: 'Days60',
      value: 60,
    },
    {
      label: 'Days90',
      value: 90,
    },
  ];
  const PaymentStatuses = [
    {
      label: 'AllOperations',
      value: 0,
    },
    {
      label: 'Ongoing',
      value: 1,
    },
    {
      label: 'Completed',
      value: 2,
    },
    {
      label: 'Rejected',
      value: 3,
    },
    /* {
            label: 'ReturnProcess',
            value: 4,
        },
        {
            label: 'RejectedReturn',
            value: 5,
        },*/
  ];

  return (
    <Spin spinning={sendODLPaging}>
      <Card className="eb_content_card w-100 mb-5">
        <div className="row">
          <div className="col-12 col-md-12 overflow_x_auto">
            <div className="d-flex mb-2 align-items-center">
              <DownOutlined
                className="d-none d-md-block"
                style={{ color: '#b2b2b2' }}
              />
              <div className="ml-3 d-none d-md-block">{trb(i18.Labels.Operations)}:</div>
              {PaymentStatuses.map((item) => {
                const { label, value } = item;

                return (
                  <div
                    id={`PaymentStatusesFilter${label}`}
                    key={item.label}
                    onClick={() =>
                      ODLPagination.OperationStatus !== value &&
                      handleChangeFilters({
                        Days: ODLPagination.Days,
                        PageNumber: 1,
                        PageSize: ODLPagination.PageSize,
                        OperationStatus: value,
                      })
                    }
                    style={ODLPagination.OperationStatus === value ? { color: '#7154D6' } : { cursor: 'pointer' }}
                    className="ml-3 mobile font-size-13"
                  >
                    {trb(translationGroups.PaymentStatuses + label)}
                  </div>
                );
              })}
            </div>
            <div className="d-flex align-items-center">
              <DownOutlined
                className="d-none d-md-block"
                style={{ color: '#b2b2b2' }}
              />
              <div className="ml-3 d-none d-md-block">{trb(i18.Labels.Period)}:</div>
              {Periods.map((item) => {
                const { label, value } = item;

                return (
                  <div
                    id={`PeriodsFilter${label}`}
                    key={item.label}
                    onClick={() =>
                      ODLPagination.Days !== value &&
                      handleChangeFilters({
                        Days: value,
                        PageNumber: 1,
                        PageSize: ODLPagination.PageSize,
                        OperationStatus: ODLPagination.OperationStatus,
                      })
                    }
                    style={ODLPagination.Days === value ? { color: '#7154D6' } : { cursor: 'pointer' }}
                    className="ml-3 mobile font-size-13"
                  >
                    {trb(translationGroups.Period + label)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Card>
    </Spin>
  );
};
