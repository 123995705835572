import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { MainRouteContainer } from './';
import { FloatingButtons, IdleTimer, PartHeader, PartSwapMenu, PrintBrowser } from 'src/Components';
import { useGetWindowSize } from 'src/Utilities';

const { Content } = Layout;

export const Main = () => {
  const windowSize = useGetWindowSize();

  const { menuCollapsed } = useSelector((state) => state.MenuReducer);

  return (
    <>
      <IdleTimer />
      <Layout
        className="eb eb_print_none"
        id="eb_print_none"
      >
        <PartSwapMenu />
        <Layout className={`site-layout ${menuCollapsed ? 'site-layout-menuCollapsed' : ''}`}>
          <PartHeader />
          <Content className={`${!windowSize.mobileCheck || !menuCollapsed ? '' : 'd-none'}`}>
            <MainRouteContainer />
          </Content>
        </Layout>
        <FloatingButtons />
      </Layout>
      <PrintBrowser />
    </>
  );
};
