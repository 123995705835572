import PropTypes from 'prop-types';

import {PrintInvoice, PrintPayment, PrintLoanSchedule} from "./Models";
import {connect} from "react-redux";
import {PrintInvoiceDetail} from "./Models/PrintInvoiceDetail";

const Print = (props) => {
    let currentPrintObj = null;

    switch (props.type) {
        case 'Payment':
            currentPrintObj = PrintPayment({data: props.data, sending: props.sending, COMPANY: props.COMPANY});
            break;
        case 'Invoice':
            currentPrintObj = PrintInvoice({data: props.data, COMPANY: props.COMPANY});
            break;
        case 'InvoiceDetail':
            currentPrintObj = PrintInvoiceDetail({data: props.data, COMPANY: props.COMPANY});
            break;
        case 'LoanSchedule':
            currentPrintObj = PrintLoanSchedule({data: props.data, COMPANY: props.COMPANY});
            break;
        case '3':
            currentPrintObj = 'ccc';
            break;
        default:
            console.log('Print template not found');
    }

    return currentPrintObj;
};

Print.propTypes = {
    type: PropTypes.string.isRequired,
    sending: PropTypes.bool,
    data: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    const {COMPANY} = state.AMCReducers;
    return {
        COMPANY
    };
}

const connectedPrint = connect(mapStateToProps)(Print);
export {connectedPrint as Print};