import PropTypes from 'prop-types';

import {checkJsonExist, checkJsonProp, formatDate} from "../../../Utilities";

export const generateFeeCode = (values) => {
    if (!checkJsonExist(values, 'feePayment')) {
        return null;
    }

    const feePaymentData = values.feePaymentData;

    let paymentCode = '';

    if (checkJsonProp(values, 'Payment.FeeCode')) {
        paymentCode = paymentCode + 'K:' + values.Payment.FeeCode + ':';
    }

    if (checkJsonProp(values, 'feePayment.requiredFeeDate')) {
        paymentCode = paymentCode + 'D:' + (formatDate(values.feePayment.date, null, 'lt-LT').replace(/-/g, '').substr(2, 6)) + ':';
    }

    feePaymentData && feePaymentData.map((item, index) => {
        for (const [key, value] of Object.entries(item)) {
            if (key === 'T') {
                paymentCode = paymentCode + (key + (index + 1)) + ':' + value + ':';
            }
        }
        return item;
    });

    feePaymentData && feePaymentData.map((item, index) => {
        for (const [key, value] of Object.entries(item)) {
            if (key !== 'T' && key !== 'TA') {
                paymentCode = paymentCode + (key + (index + 1)) + ':' + (key === 'A' ? value.toFixed(2) : value) + ':';
            }
        }
        return item;
    });

    paymentCode = paymentCode.replace(/:\s*$/, '');

    return paymentCode;
};

generateFeeCode.propTypes = {
    values: PropTypes.object.isRequired
};