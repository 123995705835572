import { i18, trb } from 'src/Utilities';

import { Progress } from 'antd';
import { textColors } from 'src/Config';

export const ForexPaymentTimer = ({ forexTimerValues }) => {
  return forexTimerValues.forexTimerVisible ? (
    <div className={`mt-3 ${forexTimerValues.passedTime >= forexTimerValues.totalTime ? 'd-none' : 'visible'}`}>
      <div className="d-flex justify-content-center mb-2">
        {
          <Progress
            type="circle"
            percent={Math.min((100 / forexTimerValues.totalTime) * (forexTimerValues.totalTime - forexTimerValues.passedTime), 99.99)}
            format={() => `${Math.round(forexTimerValues.totalTime - forexTimerValues.passedTime)} ${trb(i18.Labels.SecondsShort)}`}
            size={100}
            strokeColor={textColors.primary}
          />
        }
      </div>
      <div className="d-flex justify-content-center mb-2">{trb(i18.Messages.ForexTimerMessage)}</div>
    </div>
  ) : null;
};
