import { useFormikContext } from 'formik';

import { FormField, Input, ToggleButton } from 'src/shared';
import { i18, translationGroups, trb } from '../../../../../Utilities';
import { PAYER_PAYEE_TYPES } from 'src/constants';
import { IPaymentNewValues } from '../../interfaces';
import { FieldIdentificationType } from '../FieldIdentificationType';

export const FinalPayeeInformation = () => {
  const { values }: { values: IPaymentNewValues } = useFormikContext();

  return (
    <div>
      <FormField
        name="Payment.FinalPayeeType"
        label={trb(i18.Labels.FinalPayeeType)}
        floating={false}
      >
        <ToggleButton
          options={PAYER_PAYEE_TYPES.map((item) => ({
            value: item.value,
            label: trb(translationGroups.PayerPayeeTypes + item.label),
          }))}
        />
      </FormField>
      {values.Payment.FinalPayeeType ? (
        <>
          <FormField
            name="Payment.FinalPayeeName"
            label={trb(i18.Labels.FinalPayeeName)}
          >
            <Input />
          </FormField>
          <FieldIdentificationType
            name="Payment.FinalPayeeIdentTypeCode"
            type={values.Payment.FinalPayeeType}
            label="FinalPayeeIdentificationType"
          />
          <FormField
            name="Payment.FinalPayeeIdentTypeValue"
            label={trb(i18.Labels.FinalPayeeIdentificationCode)}
          >
            <Input />
          </FormField>
        </>
      ) : null}
    </div>
  );
};
