import { PMCServices } from './services';
import { PMConstants } from './constants';

export const PMCActions = {
  postPTR,
  postPVR,
  postAPR,
  postAPSMSR,
  postAPESCR,
  getPayPersonTypes,
  postTTG,
  postDTR,
  postDGR,
  postSGPVR,
  postSDPVR,
  postGGDR,
  postSPR,
  postUTR,
  postCGPSGNR,
  postUPR,
  clearUPR,
  clearPUPR,
  clearSUPR,
  postPUPR,
  postSUPR,
  postRTFGR,
  getHistoricalPayments,
  getHistoricalPaymentByID,
  postCheckIBANMember,
  clearCheckIBANMember,
  clearHistoricalPayments,
  clearPVR,
  postGetFxRate,
  GetCountryCurrencies,
  GetPaymentPurposeCode,
  GetBeneficiaryIdentificationCodeTypes,
  setTarget2Payment,
  getLengthIBAN,
  checkIbanValid,
  setIbanValidValue,
  resetIbanMemberValues,
  getForexPayments,
};

function postPTR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postPTR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_PTR_REQUEST };
  }

  function success(data) {
    data.InitialAccounts = data.InitialAccounts.map((item) => {
      item.AccountTypeNameToView = item.AccountTypeNameToView.replace('Kreditinė kortelė', 'Mokėjimo kortelės sąskaita');
      return item;
    });
    return { type: PMConstants.POST_PMC_PTR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_PTR_ERROR, error };
  }
}

function postPVR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postPVR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_PVR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_PVR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_PVR_ERROR, error };
  }
}

function postAPR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postAPR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_APR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_APR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_APR_ERROR, error };
  }
}

function postAPSMSR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postAPSMSR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_APSMSR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_APSMSR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_APSMSR_ERROR, error };
  }
}

function postAPESCR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postAPESCR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_APESCR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_APESCR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_APESCR_ERROR, error };
  }
}

function getPayPersonTypes(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.getPayPersonTypes(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_PMC_PAY_PERSON_TYPES_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.GET_PMC_PAY_PERSON_TYPES_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.GET_PMC_PAY_PERSON_TYPES_ERROR, error };
  }
}

function postTTG(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postTTG(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_TTG_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_TTG_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_TTG_ERROR, error };
  }
}

function postDTR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postDTR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_DTR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_DTR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_DTR_ERROR, error };
  }
}

function postDGR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postDGR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_DGR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_DGR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_DGR_ERROR, error };
  }
}

function postGGDR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postGGDR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_GGDR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_GGDR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_GGDR_ERROR, error };
  }
}

function postSGPVR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postSGPVR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_SGPVR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_SGPVR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_SGPVR_ERROR, error };
  }
}

function postSDPVR(data, AccountID) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postSDPVR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_SDPVR_REQUEST };
  }

  function success(data) {
    return {
      type: PMConstants.POST_PMC_SDPVR_SUCCESS,
      payload: data,
      AccountID: AccountID,
    };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_SDPVR_ERROR, error };
  }
}

function postSPR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postSPR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_SPR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_SPR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_SPR_ERROR, error };
  }
}

function postUTR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postUTR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_UTR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_UTR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_UTR_ERROR, error };
  }
}

function postCGPSGNR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postCGPSGNR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_CGPSGNR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_CGPSGNR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_CGPSGNR_ERROR, error };
  }
}

function postUPR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postUPR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_UPR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_UPR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_UPR_ERROR, error };
  }
}

function clearUPR() {
  return (dispatch) => {
    return dispatch(request());
  };

  function request() {
    return { type: PMConstants.CLEAR_PMC_UPR_DATA };
  }
}
function clearPUPR() {
  return (dispatch) => {
    return dispatch(request());
  };

  function request() {
    return { type: PMConstants.CLEAR_PMC_PUPR_DATA };
  }
}

function clearSUPR() {
  return (dispatch) => {
    return dispatch(request());
  };

  function request() {
    return { type: PMConstants.CLEAR_PMC_SUPR_DATA };
  }
}

function postPUPR(data) {
  return (dispatch) => {
    dispatch(request());
    return PMCServices.postPUPR(data).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_PUPR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_PUPR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_PUPR_ERROR, error };
  }
}

function postRTFGR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postRTFGR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_RTFGR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_RTFGR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_RTFGR_ERROR, error };
  }
}

function postSUPR(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postSUPR(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_PMC_SUPR_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_PMC_SUPR_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_PMC_SUPR_ERROR, error };
  }
}

function getHistoricalPayments(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.getHistoricalPayments(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_HISTORICAL_PAYMENTS_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.GET_HISTORICAL_PAYMENTS_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.GET_HISTORICAL_PAYMENTS_ERROR, error };
  }
}

function getHistoricalPaymentByID(data) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.getHistoricalPaymentByID(data).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_HISTORICAL_PAYMENT_BY_ID_REQUEST };
  }

  function success(data) {
    return {
      type: PMConstants.GET_HISTORICAL_PAYMENT_BY_ID_SUCCESS,
      payload: data,
    };
  }

  function failure(error) {
    return { type: PMConstants.GET_HISTORICAL_PAYMENT_BY_ID_ERROR, error };
  }
}

function clearHistoricalPayments() {
  return (dispatch) => {
    dispatch(clearHistoricalPayments());
  };

  function clearHistoricalPayments() {
    return { type: PMConstants.CLEAR_HISTORICAL_PAYMENTS };
  }
}

function postCheckIBANMember(IBAN) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postCheckIBANMember({ iban: IBAN }).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_CHECK_IBAN_MEMBER_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_CHECK_IBAN_MEMBER_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_CHECK_IBAN_MEMBER_ERROR, error };
  }
}

function clearCheckIBANMember() {
  return (dispatch) => {
    dispatch(clearIBAN());
  };

  function clearIBAN() {
    return { type: PMConstants.CLEAR_CHECK_IBAN_MEMBER };
  }
}

function setTarget2Payment(data) {
  return (dispatch) => {
    dispatch(setTargetPayment(data));
    return 'ok';
  };

  function setTargetPayment(targetData) {
    return {
      type: PMConstants.SET_TARGET2_DATA,
      payload: targetData,
    };
  }
}

function clearPVR() {
  return (dispatch) => {
    dispatch(clearPVR());
  };

  function clearPVR() {
    return { type: PMConstants.CLEAR_PVR };
  }
}

function postGetFxRate(payload) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.postGetFxRate(payload).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.POST_FX_RATE_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.POST_FX_RATE_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.POST_FX_RATE_ERROR, error };
  }
}

function GetCountryCurrencies() {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.GetCountryCurrencies().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_COUNTRY_CURRENCIES_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.GET_COUNTRY_CURRENCIES_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.GET_COUNTRY_CURRENCIES_ERROR, error };
  }
}

function GetPaymentPurposeCode() {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.GetPaymentPurposeCode().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_PAYMENT_PURPOSE_CODE_REQUEST };
  }

  function success(data) {
    return { type: PMConstants.GET_PAYMENT_PURPOSE_CODE_SUCCESS, payload: data };
  }

  function failure(error) {
    return { type: PMConstants.GET_PAYMENT_PURPOSE_CODE_ERROR, error };
  }
}

function GetBeneficiaryIdentificationCodeTypes() {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.GetBeneficiaryIdentificationCodeTypes().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };
  function request() {
    return {
      type: PMConstants.GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_REQUEST,
    };
  }

  function success(data) {
    return {
      type: PMConstants.GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_SUCCESS,
      payload: data,
    };
  }

  function failure(error) {
    return {
      type: PMConstants.GET_BENEFICIARY_IDENTIFICATION_CODE_TYPES_ERROR,
      error,
    };
  }
}

function getLengthIBAN(ibanData) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.getLengthIBAN(ibanData).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_IBAN_LENGTH_REQUEST };
  }

  function success(data) {
    return {
      type: PMConstants.GET_IBAN_LENGTH_SUCCESS,
      payload: { ibanLenght: data, ibanData: ibanData },
    };
  }

  function failure(error) {
    return { type: PMConstants.GET_IBAN_LENGTH_ERROR, error };
  }
}

function checkIbanValid(ibanData) {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.checkIbanValid(ibanData).then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_CHECK_IBAN_VALID_REQUEST };
  }

  function success(data) {
    return {
      type: PMConstants.GET_CHECK_IBAN_VALID_SUCCESS,
      payload: { ibanValid: data, ibanData: ibanData },
    };
  }

  function failure(error) {
    return { type: PMConstants.GET_CHECK_IBAN_VALID_ERROR, error };
  }
}

function setIbanValidValue(data) {
  return (dispatch) => {
    dispatch(setIbanValidValue());
  };

  function setIbanValidValue() {
    return { type: PMConstants.SET_IBAN_VALID_VALUE, payload: data };
  }
}

function resetIbanMemberValues() {
  return (dispatch) => {
    dispatch(resetValues());
  };

  function resetValues() {
    return { type: PMConstants.POST_CHECK_IBAN_MEMBER_SUCCESS, payload: null };
  }
}

function getForexPayments() {
  return (dispatch) => {
    dispatch(request());

    return PMCServices.getForexPayments().then(
      (data) => dispatch(success(data)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: PMConstants.GET_FOREX_PAYMENTS_REQUEST };
  }

  function success(data) {
    return {
      type: PMConstants.GET_FOREX_PAYMENTS_SUCCESS,
      payload: data,
    };
  }

  function failure(error) {
    return { type: PMConstants.GET_FOREX_PAYMENTS_ERROR, error };
  }
}
