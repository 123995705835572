import { UACConstants } from './constants';
import { requestEBank, requestEBankFile, requestEBankJSON } from 'src/Redux/Config';
import { checkJsonProp } from 'src/Utilities';

export const getToken = (user) => {
  let access_token = checkJsonProp(user, 'Bearer') || checkJsonProp(user, 'Jwe');
  if (!access_token) {
    return null;
  }
  return 'Bearer ' + access_token;
};

const initialState = {
  sendMALRInitial: true,
};

export const UACReducers = function (state = initialState, action) {
  switch (action.type) {
    case UACConstants.POST_UAC_ESAR_REQUEST:
      return {
        ...state,
        send: true,
        sendESAR: true,
        ESAR: null,
      };
    case UACConstants.POST_UAC_ESAR_SUCCESS:
      return {
        ...state,
        send: false,
        sendESAR: false,
        ESAR: action.payload,
      };
    case UACConstants.POST_UAC_ESAR_ERROR:
      return {
        ...state,
        send: false,
        sendESARSM: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_ESARSM_REQUEST:
      return {
        ...state,
        send: true,
        sendESARSM: true,
        ESARSM: null,
      };
    case UACConstants.POST_UAC_ESARSM_SUCCESS:
      return {
        ...state,
        send: false,
        sendESARSM: false,
        ESAR: action.payload,
      };
    case UACConstants.POST_UAC_ESARSM_ERROR:
      return {
        ...state,
        send: false,
        sendESARSM: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_ESACR_REQUEST:
      return {
        ...state,
        send: true,
        sendESACR: true,
        ESACR: null,
      };
    case UACConstants.POST_UAC_ESACR_SUCCESS:
      requestEBank.defaults.headers.common['Authorization'] = getToken(action.payload);
      requestEBankJSON.defaults.headers.common['Authorization'] = getToken(action.payload);
      requestEBankFile.defaults.headers.common['Authorization'] = getToken(action.payload);
      return {
        ...state,
        send: false,
        sendESACR: false,
        ESACR: action.payload,
      };
    case UACConstants.POST_UAC_ESACR_ERROR:
      return {
        ...state,
        send: false,
        sendESACR: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_SMSR_REQUEST:
      return {
        ...state,
        send: true,
        sendSMSR: true,
        SMSR: null,
      };
    case UACConstants.POST_UAC_SMSR_SUCCESS:
      return {
        ...state,
        send: false,
        sendSMSR: false,
        SMSR: action.payload,
      };
    case UACConstants.POST_UAC_SMSR_ERROR:
      return {
        ...state,
        send: false,
        sendSMSR: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_FULLLOGIN_REQUEST:
      return {
        ...state,
        send: true,
        sendSMSR: true,
        SMSR: null,
      };
    case UACConstants.POST_UAC_FULLLOGIN_SUCCESS:
      return {
        ...state,
        send: false,
        sendSMSR: false,
        SMSR: action.payload,
      };
    case UACConstants.POST_UAC_FULLLOGIN_ERROR:
      return {
        ...state,
        send: false,
        sendSMSR: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_SIMPLELOGIN_REQUEST:
      return {
        ...state,
        send: true,
        sendSMSR: true,
        SMSR: null,
      };
    case UACConstants.POST_UAC_SIMPLELOGIN_SUCCESS:
      return {
        ...state,
        send: false,
        sendSMSR: false,
        SMSR: action.payload,
      };
    case UACConstants.POST_UAC_SIMPLELOGIN_ERROR:
      return {
        ...state,
        send: false,
        sendSMSR: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_SMSAR_REQUEST:
      return {
        ...state,
        send: true,
        sendSMSAR: true,
        SMSAR: null,
      };
    case UACConstants.POST_UAC_SMSAR_SUCCESS:
      requestEBank.defaults.headers.common['Authorization'] = getToken(action.payload);
      requestEBankJSON.defaults.headers.common['Authorization'] = getToken(action.payload); //reikia pasidaryti kad Bearer ideti darant checkLogin!!!!
      requestEBankFile.defaults.headers.common['Authorization'] = getToken(action.payload);
      return {
        ...state,
        send: false,
        sendSMSAR: false,
        SMSAR: action.payload,
      };
    case UACConstants.POST_UAC_SMSAR_ERROR:
      return {
        ...state,
        send: false,
        sendSMSAR: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_CHECK_LOGIN_REQUEST:
      return {
        ...state,
        send: true,
        sendSMSAR: true,
        SMSAR: null,
      };
    case UACConstants.POST_UAC_CHECK_LOGIN_SUCCESS:
      requestEBank.defaults.headers.common['Authorization'] = getToken(action.payload);
      requestEBankJSON.defaults.headers.common['Authorization'] = getToken(action.payload);
      requestEBankFile.defaults.headers.common['Authorization'] = getToken(action.payload);
      return {
        ...state,
        send: false,
        sendSMSAR: false,
        SMSAR: action.payload,
      };
    case UACConstants.POST_UAC_CHECK_LOGIN_ERROR:
      return {
        ...state,
        send: false,
        sendSMSAR: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_RESEND_TOKEN_REQUEST:
      return {
        ...state,
        send: true,
        sendUACResendToken: true,
        resendToken: action.payload,
      };
    case UACConstants.POST_UAC_RESEND_TOKEN_SUCCESS:
      requestEBank.defaults.headers.common['Authorization'] = getToken(action.payload);
      requestEBankJSON.defaults.headers.common['Authorization'] = getToken(action.payload);
      requestEBankFile.defaults.headers.common['Authorization'] = getToken(action.payload);

      return {
        ...state,
        send: false,
        sendUACResendToken: false,
        resendToken: action.payload,
      };
    case UACConstants.POST_UAC_RESEND_TOKEN_ERROR:
      return {
        ...state,
        send: false,
        sendUACResendToken: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_MACR_REQUEST:
      return {
        ...state,
        send: true,
        sendMACR: true,
        activeAccountId: action.payload?.accountId,
      };
    case UACConstants.GET_UAC_MACR_SUCCESS:
      return {
        ...state,
        send: false,
        sendMACR: false,
        MACR: action.payload,
      };
    case UACConstants.GET_UAC_MACR_ERROR:
      return {
        ...state,
        send: false,
        sendMACR: false,
        errors: action.error,
        activeAccountId: null,
      };
    case UACConstants.GET_UAC_MALR_REQUEST:
      return {
        ...state,
        send: true,
        sendMALR: true,
        //MALR: null,
      };
    case UACConstants.GET_UAC_MALR_SUCCESS:
      return {
        ...state,
        send: false,
        sendMALR: false,
        sendMALRInitial: false,
        MALR: action.payload,
      };
    case UACConstants.GET_UAC_MALR_ERROR:
      return {
        ...state,
        send: false,
        sendMALR: false,
        sendMALRInitial: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_MALR_UNMOUNT:
      return {
        ...state,
        send: false,
        sendMALR: false,
        MALR: null,
      };
    case UACConstants.POST_UAC_ATR_REQUEST:
      return {
        ...state,
        send: true,
        //sendATR: true,
        //ATR: null,
      };
    case UACConstants.POST_UAC_ATR_SUCCESS:
      return {
        ...state,
        send: false,
        sendATR: false,
        ATR: action.payload,
      };
    case UACConstants.POST_UAC_ATR_ERROR:
      return {
        ...state,
        send: false,
        sendATR: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_LOGOUT_REQUEST:
      return {
        ...state,
        send: true,
        sendLogout: true,
        Logout: null,
      };
    case UACConstants.GET_UAC_LOGOUT_SUCCESS:
      return {
        ...state,
        send: false,
        sendLogout: false,
        Logout: action.payload,
      };
    case UACConstants.GET_UAC_LOGOUT_ERROR:
      return {
        ...state,
        send: false,
        sendLogout: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_GPR_REQUEST:
      return {
        ...state,
        send: true,
        sendGPR: true,
        GPR: null,
      };
    case UACConstants.GET_UAC_GPR_SUCCESS:
      return {
        ...state,
        send: false,
        sendGPR: false,
        GPR: action.payload,
      };
    case UACConstants.GET_UAC_GPR_ERROR:
      return {
        ...state,
        send: false,
        sendGPR: false,
        errors: action.error,
      };
    case UACConstants.POST_UAC_SMSGETPWD_REQUEST:
      return {
        ...state,
        send: true,
        sendSMSGETPWD: true,
        SMSGETPWD: null,
      };
    case UACConstants.POST_UAC_SMSGETPWD_SUCCESS:
      return {
        ...state,
        send: false,
        sendSMSGETPWD: false,
        SMSGETPWD: action.payload,
      };
    case UACConstants.POST_UAC_SMSGETPWD_ERROR:
      return {
        ...state,
        send: false,
        sendSMSGETPWD: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_GGDATAMY_REQUEST:
      return {
        ...state,
        send: true,
        sendGGDataMy: true,
        GGDATAMY: null,
      };
    case UACConstants.GET_UAC_GGDATAMY_SUCCESS:
      return {
        ...state,
        send: false,
        sendGGDataMy: false,
        GGDATAMY: action.payload,
      };
    case UACConstants.GET_UAC_GGDATAMY_ERROR:
      return {
        ...state,
        send: false,
        sendGGDataMy: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_RFT_REQUEST:
      return {
        ...state,
        send: true,
        sendRFT: true,
        RFT: null,
      };
    case UACConstants.GET_UAC_RFT_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const auth = `Bearer ${action.payload?.AccessToken}`;

      requestEBank.defaults.headers.common['Authorization'] = auth;
      requestEBankJSON.defaults.headers.common['Authorization'] = auth;
      requestEBankFile.defaults.headers.common['Authorization'] = auth;
      return {
        ...state,
        send: false,
        sendRFT: false,
        RFT: action.payload,
      };
    case UACConstants.GET_UAC_RFT_ERROR:
      return {
        ...state,
        send: false,
        sendRFT: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_ESARGET_REQUEST:
      return {
        ...state,
        send: true,
        sendESARGET: true,
        ESARGET: null,
      };
    case UACConstants.GET_UAC_ESARGET_SUCCESS:
      return {
        ...state,
        send: false,
        sendESARGET: false,
        ESARGET: action.payload,
      };
    case UACConstants.GET_UAC_ESARGET_ERROR:
      return {
        ...state,
        send: false,
        sendRFT: false,
        errors: action.error,
      };
    case UACConstants.GET_UAC_CPRQ_REQUEST:
      return {
        ...state,
        send: true,
        sendCPRQ: true,
        CRPQ: null,
      };
    case UACConstants.GET_UAC_CPRQ_SUCCESS:
      return {
        ...state,
        send: false,
        sendCPRQ: false,
        CRPQ: action.payload,
      };
    case UACConstants.GET_UAC_CPRQ_ERROR:
      return {
        ...state,
        send: false,
        sendCPRQ: false,
        errors: action.error,
      };
    case UACConstants.GET_LOGIN_MESSAGE_REQUEST:
      return {
        ...state,
        send: true,
        sendLoginMessage: true,
        loginMessages: null,
      };
    case UACConstants.GET_LOGIN_MESSAGE_SUCCESS:
      return {
        ...state,
        send: false,
        sendLoginMessage: false,
        loginMessages: action.payload,
      };
    case UACConstants.GET_LOGIN_MESSAGE_ERROR:
      return {
        ...state,
        send: false,
        sendLoginMessage: false,
        errors: action.error,
      };
    case UACConstants.SET_IS_REMOTE_ORIGIN:
      return {
        ...state,
        isRemoteOrigin: action.payload,
      };
    case UACConstants.CLEAR_SMSR_INFO:
      return {
        ...state,
        errors: null,
        SMSR: null,
      };
    default:
      return state;
  }
};
