import React from 'react';
import {Steps} from "antd";

import {i18, trb} from '../../../../Utilities';

import {
    KYCInformationAboutClient,
    KYCClientCEO,
    KYCBusinessType,
    KYCClientPlanTurnover,
    KYCClientTax,
    KYCAccountsInOtherBank,
    KYCPepLinks,
    KYCClientBeneficiaries
} from './Components'

const Step = Steps.Step;

export const SettingsKYCRenewCompany = (props) => {
    const CurrentStep = props.currentStep;

    let classifikators = props.classifikators;
    classifikators.PEPLIST = classifikators.PEPLIST.filter((item) => item.value != 'PEPLIST1')

    return <div className="eb_kyc_content eb_kyc_content_company">
        <div className="eb_content_title">{trb(i18.ContentTitles.KYCRenew)}</div>
        <Steps size="small" current={CurrentStep}
               className="mb-5 d-none d-md-flex">
            <Step title={CurrentStep === 0 ? trb(i18.Steps.InformationAboutClient) : ''}
                  description={CurrentStep === 0 ? trb(i18.Steps.InformationAboutClientDescription) : ''}/>
            <Step title={CurrentStep === 1 ? trb(i18.Steps.ClientCeo) : ''}
                  description={CurrentStep === 1 ? trb(i18.Steps.ClientCeoDescription) : ''}/>
            <Step title={CurrentStep === 2 ? trb(i18.Steps.BusinessType) : ''}
                  description={CurrentStep === 2 ? trb(i18.Steps.BusinessTypeDescription) : ''}/>
            <Step title={CurrentStep === 3 ? trb(i18.Steps.ClientPlanTurnover) : ''}
                  description={CurrentStep === 3 ? trb(i18.Steps.ClientPlanTurnoverDescription) : ''}/>
            <Step title={CurrentStep === 4 ? trb(i18.Steps.ClientTax) : ''}
                  description={CurrentStep === 4 ? trb(i18.Steps.ClientTaxDescription) : ''}/>
            <Step title={CurrentStep === 5 ? trb(i18.Steps.AccountsInOtherBank) : ''}
                  description={CurrentStep === 5 ? trb(i18.Steps.AccountsInOtherBankDescription) : ''}/>
            <Step title={CurrentStep === 6 ? trb(i18.Steps.PepLinks) : ''}
                  description={CurrentStep === 6 ? trb(i18.Steps.PepLinksDescription) : ''}/>
            <Step title={CurrentStep === 7 ? trb(i18.Steps.ClientBeneficiaries) : ''}
                  description={CurrentStep === 7 ? trb(i18.Steps.ClientBeneficiariesDescription) : ''}/>
        </Steps>
        <div className="eb_kyc_container">
            {CurrentStep === 0 &&
            <KYCInformationAboutClient permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep}/>}
            {CurrentStep === 1 &&
            <KYCClientCEO permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>}
            {CurrentStep === 2 &&
            <KYCBusinessType permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>}
            {CurrentStep === 3 &&
            <KYCClientPlanTurnover permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>}
            {CurrentStep === 4 &&
            <KYCClientTax permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>}
            {CurrentStep === 5 &&
            <KYCAccountsInOtherBank permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>}
            {CurrentStep === 6 &&
            <KYCPepLinks permissons={props.permissons} classifikators={props.classifikators} data={props.data} nextStep={props.nextStep} prevStep={props.prevStep}/>}
            {CurrentStep === 7 &&
            <KYCClientBeneficiaries permissons={props.permissons} classifikators={props.classifikators} data={props.data} submit={props.submit} prevStep={props.prevStep}/>}
        </div>
    </div>
  }