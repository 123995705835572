import {aPIPaths, handleResponse,requestEBank, requestEBankJSON, methods} from '../../Config';

export const TranslationsService = {
    getTranslations,
    getCreateTranslation
};

function getTranslations(context, culture) {
    return requestEBank(
        aPIPaths.get_Translations + "?context=" + context + "&culture=" + culture,
        {method: methods.post}
        ).then(handleResponse);
}

function getCreateTranslation(context, culture, group, key) {
    return requestEBankJSON(
        aPIPaths.get_Translations_CreateTranslation + "?context=" + context + '&culture=' + culture + '&group=' + group + '&key=' + key,
        {method: methods.get}
        ).then(handleResponse);
}
