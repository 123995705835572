/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useSelector } from 'react-redux';

import { UserAccountsLimits } from './UserAccountsLimits';
import { IUserLimits } from '.';
import { ContentLoader } from 'src/Components';
import { Alert } from 'src/shared';
import { i18, trb } from 'src/Utilities';

export const UserLimits = () => {
  const { userLimits, sendUserLimits, sendUserLimitsErrors }: { userLimits: IUserLimits; sendUserLimits: boolean; sendUserLimitsErrors: boolean } = useSelector(
    (state: any) => state.AMCReducers
  );

  if (!userLimits?.userId || sendUserLimits) {
    return <ContentLoader />;
  }

  const { userId, isMyself } = userLimits ?? {};

  return (
    <>
      {userLimits?.LimitsModelLists?.map((item, index: number) => (
        <UserAccountsLimits
          key={`${userId}_${index}`}
          GrantLimits={item}
          userId={userId}
          isMyself={isMyself}
        />
      ))}
      {sendUserLimitsErrors && (
        <Alert
          className="mb-3 col-12"
          style={{ paddingLeft: '4rem' }}
          message={trb(i18.ErrorTitles.FailedGetUserLimits)}
          type="error"
          showIcon
        />
      )}
    </>
  );
};
