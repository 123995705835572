import React from 'react';
import createDecorator from "final-form-calculate";

import {FormFieldType} from "../../../../../Components/FormField";
import {
    i18,
    requiredNotUndefined,
    checkJsonProp
} from "../../../../../Utilities";
import {KYCFormSwitch} from './';

export const KYCClientPlanTurnover = (props) => {

    let ClientPlanTurn = checkJsonProp(props.data, 'clientPlanTurn') ? checkJsonProp(props.data, 'clientPlanTurn') : [{clientPlanTurn: "PEN1", data: {}}],
        ClientPlanCashTurn = checkJsonProp(props.data, 'clientPlanCashTurn') ? checkJsonProp(props.data, 'clientPlanCashTurn') : [{clientPlanCashTurn: "PEN1", data: {}}];

    ClientPlanTurn[0].clientPlanTurn = checkJsonProp(ClientPlanTurn, '0.clientPlanTurn') ? checkJsonProp(ClientPlanTurn, '0.clientPlanTurn') : "PEN1";
    ClientPlanCashTurn[0].clientPlanCashTurn = checkJsonProp(ClientPlanCashTurn, '0.clientPlanCashTurn') ? checkJsonProp(ClientPlanCashTurn, '0.clientPlanCashTurn') : "PEN1";

    const initialData = {clientPlanTurn: ClientPlanTurn, clientPlanCashTurn: ClientPlanCashTurn};

    const mapData = [{
        title: i18.Labels.ClientPlanTurn, //i18.Labels.ClientOtherAddress
        messageBefore: i18.NotificationMessages.KYCClientPlanTurnover,
        elements: [{
            name: 'clientPlanTurn.0.data.clientPlanTurnover',
            component: FormFieldType.radio,
            options: props.classifikators.PLTUR,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-10 offset-1',
            noInline: true,
            validate: requiredNotUndefined,
        }]
    },{
        title: i18.Labels.ClientPlanCashTurn, //i18.Labels.ClientOtherAddress
        elements: [{
            name: 'clientPlanCashTurn.0.data.clientPlanCashTurnover',
            component: FormFieldType.radio,
            options: props.classifikators.PLCASH,
            optionsLabel: 'label',
            optionsValue: 'value',
            className: 'col-10 offset-1',
            noInline: true,
            validate: requiredNotUndefined,
        }]
    }];

    const decorators = [
        createDecorator()
    ];

    return <KYCFormSwitch permissons={props.permissons} title={i18.ContentSubTitles.InformationAboutClient} decorators={decorators} mapData={mapData} initialData={initialData} nextStep={props.nextStep} prevStep={props.prevStep}/>
};