import { Typography as TypographyANTD } from 'antd';
import { LinkProps } from 'antd/lib/typography/Link';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { TextProps } from 'antd/lib/typography/Text';
import { TitleProps } from 'antd/lib/typography/Title';

const Title = (props: TitleProps) => {
    return <TypographyANTD.Title {...props} />;
};

const Paragraph = (props: ParagraphProps) => {
    return <TypographyANTD.Paragraph {...props} />;
};

const Text = (props: TextProps) => {
    return <TypographyANTD.Text {...props} />;
};

const Link = (props: LinkProps) => {
    return <TypographyANTD.Link {...props} />;
};

export const Typography = {
    Title: Title,
    Paragraph: Paragraph,
    Text: Text,
    Link: Link,
};
