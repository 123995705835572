export const paymentInternationalValues = {
  Payment: {
    PaymentType: 7,
    ReferenceNo: 'unset',
    PaymentSystem: 'X4',
    Template: '',
    InitialAccountID: '',
    OverdraftValue: null,
    Recipient: '',
    BeneficiaryAccountNumber: '',
    Amount: null,
    AmountAvailable: null,
    Currency: 'EUR',
    Purpose: '',
    BeneficiaryAddress: '',
    RecipientBankCountry: '',
    BeneficiaryCity: '',
    BeneficiaryPostCode: '',
    CorrespondentBankBic: '',
    SaveTemplate: false,
    TemplateName: '',
  },
};
