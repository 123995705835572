import React, { useEffect } from 'react';
import { OMCActions } from 'src/Redux';
import { i18, trb } from 'src/Utilities';
import { routerPath } from 'src/Config';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AlertNotification } from 'src/shared';

export const OperationSevenDaysRejected = () => {
  const { sendODL, ODL } = useSelector((state) => state.OMCReducers);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(OMCActions.postODL({ Days: 30 }));
  }, []);

  const clickGoToOperations = () => {
    navigate(routerPath.operations, {
      state: {
        OperationType: 2,
      },
    });
  };

  if (sendODL) {
    return null;
  }
  const operations = ODL?.Operations;
  if (!operations || operations.lenght <= 0) {
    return null;
  }
  const rejected = operations.filter((x) => x.Status === 2);
  const now = new Date(Date.now()).toLocalISODateString();
  const date = new Date(now);
  date.setDate(date.getDate() + -7);
  const minus7days = date.getTime();
  const filtered = rejected.filter((x) => new Date(x.DateFormatted).getTime() >= minus7days);
  if (filtered.length === 0) {
    return null;
  }
  return (
    <div
      onClick={clickGoToOperations}
      className="btn w-100 text-left operation-rejected-container px-0 pt-0"
    >
      <AlertNotification
        message={trb(i18.Labels.OperationRejectedSevenDays)}
        className="operation-alert"
        type="warning"
      />
    </div>
  );
};
