import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { OperationsFilter, OperationsListContainer } from 'src/Containers';
import { MenuActions, OMCActions } from 'src/Redux';
import { i18, trb } from 'src/Utilities';
import { PAGINATION_DEFAULTS } from 'src/Config';

export const OperationsContainer = () => {
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    Days: 30,
    PageNumber: 1,
    PageSize: PAGINATION_DEFAULTS.OPERATIONS.DEFAULT,
    OperationStatus: 0,
  });

  useEffect(() => {
    dispatch(MenuActions.setTitle(trb(i18.Container.OperationsContainer)));
  }, []);

  useEffect(() => {
    dispatch(OMCActions.postODLPaging(pagination));
  }, [pagination]);

  const handleFetch = (param) => {
    if (
      param.PageNumber !== pagination.PageNumber ||
      param.PageSize !== pagination.PageSize ||
      param.OperationStatus !== pagination.OperationStatus ||
      param.Days !== pagination.Days
    ) {
      setPagination(param);
    }
  };

  return (
    <>
      <OperationsFilter handleChangeFilters={handleFetch} />
      <OperationsListContainer handleChangePagination={handleFetch} />
    </>
  );
};
