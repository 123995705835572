import React from 'react';

import { checkJsonProp } from 'src/Utilities';
import { Amount } from 'src/Components';

export const Summary = (props) => {
  return (
    <table
      id="InvoiceSummaryTable"
      className={`eb_table_summary ${props.className}`}
      style={{ maxWidth: 1200, marginLeft: 'auto', marginRight: 'auto' }}
    >
      <tbody>
        {props.headers.map((item, index) => (
          <tr key={index}>
            <th className={item.classNameTitle}>{item.text}</th>
            <td className={`text-nowrap pr-0 ${item.classNameField}`}>
              <Amount
                value={checkJsonProp(props.data, item.name)}
                suffix={checkJsonProp(props.data, item.suffix) ?? item.suffixString}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
