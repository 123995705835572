import {aPIPaths, handleResponse, methods, requestEBankJSON, requestEBankFile} from "../../Config";


export const MessagingServices = {
    postCreate,
    postResponse,
    getChats,
    getChatMessages,
    getNewMessagesCount,
    deleteMessage,
    getBroadCastList,
    getBroadCastMessage,
    deleteBroadCastMessage,
};

function postCreate(data) {
    return requestEBankFile(aPIPaths.post_Messaging_Create, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function postResponse(data) {
    return requestEBankJSON(aPIPaths.post_Messaging_Response, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function getChats() {
    return requestEBankJSON(aPIPaths.get_Messaging_Chats).then(handleResponse);
}

function getChatMessages(chatId) {
    return requestEBankJSON(aPIPaths.get_Messaging_ChatMessages + chatId, {
        method: methods.get,
    }).then(handleResponse);
}

function getNewMessagesCount() {
    return requestEBankJSON(aPIPaths.get_Messaging_NewMessagesCount).then(handleResponse);
}

function deleteMessage(data) {
    return requestEBankJSON(aPIPaths.delete_Messaging_DeleteMessage, {
        method: methods.post,
        data: data,
    }).then(handleResponse);
}

function getBroadCastList() {
    return requestEBankJSON(aPIPaths.get_Messaging_GetBroadCastList).then(handleResponse);
}

function getBroadCastMessage(id) {
    return requestEBankJSON(aPIPaths.get_Messaging_GetBroadCastMessage+'/?id='+id).then(handleResponse);
}

function deleteBroadCastMessage(id) {
    return requestEBankJSON(aPIPaths.get_Messaging_DeleteBroadCastMessage+'/?id='+id).then(handleResponse);
}