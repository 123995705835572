import React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';

import { formatDate, useGetWindowSize, i18, translationGroups, trb } from 'src/Utilities';

import { PAGINATION_DEFAULTS } from 'src/Config';
import { Number } from 'src/Components';

export const OperationsListContainer = ({ handleChangePagination }) => {
  const windowSize = useGetWindowSize();
  const { sendODLPaging, ODLPaging, ODLPagination } = useSelector((state) => state.OMCReducers);

  const setStatus = (value) => {
    switch (value) {
      case 0:
        return trb(i18.PaymentStatuses.Ongoing);
      case 1:
        return trb(i18.PaymentStatuses.Completed);
      case 2:
        return trb(i18.PaymentStatuses.Rejected);
      case 3:
        return trb(i18.PaymentStatuses.InsufficientBalance);
      default:
        return trb(i18.PaymentStatuses.AllOperations);
    }
  };

  const isStatusCodeTranslatable = (statusCode) => {
    return ['AccountNotFound', 'NotEnoughFunds', 'AMLRejected', 'ValidationError'].includes(statusCode);
  };

  const columns = [
      {
        title: trb(i18.Labels.OperationsData),
        dataIndex: 'DateFormatted',
        render: (value) => <span className="text-nowrap">{formatDate(value)}</span>,
        width: 105,
      },
      {
        title: trb(i18.Labels.OperationsListPayer),
        dataIndex: 'SenderName',
        render: (value, item) => (
          <>
            <div>{value}</div>
            <div className="text-nowrap">{item.SenderAccount}</div>
          </>
        ),
        width: 215,
      },
      {
        title: trb(i18.Labels.OperationsPayee),
        dataIndex: 'ReceiverName',
        render: (value, item) => (
          <>
            <div>{value}</div>
            <div className="text-nowrap">{item.ReceiverAccount}</div>
          </>
        ),
        width: 275,
      },
      {
        title: trb(i18.Labels.OperationsListDescription),
        dataIndex: 'Description',
        className: 'word-wrap',
        width: 210,
      },
      {
        title: trb(i18.Labels.OperationStatus),
        dataIndex: 'Status',
        render: (value) => setStatus(value),
        width: 100,
      },
      {
        title: trb(i18.Labels.StatusCode),
        render: (item) => (isStatusCodeTranslatable(item.StatusCode) ? trb(translationGroups.OperationStatusCode + item.StatusCode) : item.StatusCode),
        width: 90,
      },
      {
        title: trb(i18.Labels.OperationsSum),
        render: (item) => (
          <Number
            className="text-nowrap"
            value={item.Amount[0].Value}
            suffix={` ${item.Amount[0].Currency}`}
          />
        ),
        className: 'text-right',
        width: 90,
      },
    ],
    columnsMobile = [
      {
        title: trb(i18.Labels.OperationsPayer),
        dataIndex: 'SenderName',
        render: (value, item) => (
          <span>
            {value}
            <br />
            {item.SenderAccount}
          </span>
        ),
      },
      {
        title: trb(i18.Labels.OperationsSum),
        render: (item) => (
          <Number
            className="text-nowrap"
            value={item.Amount[0].Value}
            suffix={` ${item.Amount[0].Currency}`}
          />
        ),
        className: 'text-right',
      },
    ];

  return (
    <Table
      id="OperationsListTable"
      loading={sendODLPaging}
      dataSource={ODLPaging.OperationList.map((item, index) => ({
        ...item,
        UIDForTableUpdate: index,
      }))}
      rowKey="UIDForTableUpdate"
      columns={windowSize.mobileCheck ? columnsMobile : columns}
      className="mb-5 mx-0 overflow_x_auto operations-list-table"
      tableLayout="fixed"
      pagination={{
        showSizeChanger: true,
        hideOnSinglePage: ODLPaging.RowsCount <= PAGINATION_DEFAULTS.OPERATIONS.SIZES[0],
        pageSizeOptions: PAGINATION_DEFAULTS.OPERATIONS.SIZES,
        current: ODLPagination.PageNumber,
        pageSize: ODLPagination.PageSize,
        total: ODLPaging.RowsCount,
        onChange: (page, size) =>
          handleChangePagination({
            Days: ODLPagination.Days,
            PageNumber: page,
            PageSize: size,
            OperationStatus: ODLPagination.OperationStatus,
          }),
        onShowSizeChange: (_current, size) =>
          handleChangePagination({
            Days: ODLPagination.Days,
            PageNumber: 1,
            PageSize: size,
            OperationStatus: ODLPagination.OperationStatus,
          }),
      }}
    />
  );
};

