import { yup } from 'src/utils';

export const loginFormValidations = yup.object().shape({
  LoginCountry: yup.string().nullable().required(),
  LoginCode: yup.string().nullable().required(),
  LoginIdentity: yup
    .string()
    .nullable()
    .when('isFullLogin', {
      is: true,
      then: () => yup.string().nullable().required(),
      otherwise: () => yup.string().nullable(),
    }),
});
