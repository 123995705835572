import { AMCConstants } from './constants';
import { UACConstants } from '../UAC';

const initialState = {
  sendCOMPANYInitial: true,
  countriesList: [],
};

export const AMCReducers = function (state = initialState, action) {
  switch (action.type) {
    case AMCConstants.GET_ALR_REQUEST:
      return {
        ...state,
        send: true,
        sendALR: true,
        ALR: null,
      };
    case AMCConstants.GET_ALR_SUCCESS:
      return {
        ...state,
        send: false,
        sendALR: false,
        ALR: action.payload,
      };
    case AMCConstants.GET_ALR_ERROR:
      return {
        ...state,
        send: false,
        sendALR: false,
        errors: action.error,
      };
    case AMCConstants.POST_LLO_REQUEST:
      return {
        ...state,
        send: true,
        sendLLO: true,
        LLO: null,
      };
    case AMCConstants.POST_LLO_SUCCESS:
      return {
        ...state,
        send: false,
        sendLLO: false,
        LLO: action.payload,
      };
    case AMCConstants.POST_LLO_ERROR:
      return {
        ...state,
        send: false,
        sendLLO: false,
        errors: action.error,
      };
    case AMCConstants.POST_ADR_REQUEST:
      return {
        ...state,
        send: true,
        sendADR: true,
        ADR: state.ADR,
        filterData: null,
      };
    case AMCConstants.POST_ADR_SUCCESS:
      return {
        ...state,
        send: false,
        sendADR: false,
        ADR: action.payload,
        filterData: action.filter,
      };
    case AMCConstants.POST_ADR_ERROR:
      return {
        ...state,
        send: false,
        sendADR: false,
        errors: action.error,
        filterData: null,
      };

    case AMCConstants.POST_ADROD_REQUEST:
      return {
        ...state,
        send: true,
        sendADROD: true,
        // filterData: null
      };
    case AMCConstants.POST_ADROD_SUCCESS:
      return {
        ...state,
        send: false,
        sendADROD: false,
        ADROD: action.payload,
        // filterData: action.filter
      };
    case AMCConstants.POST_ADROD_ERROR:
      return {
        ...state,
        send: false,
        sendADROD: false,
        errors: action.error,
        // filterData: null
      };

    case AMCConstants.POST_CAINF_REQUEST:
      return {
        ...state,
        send: true,
        sendCAINF: true,
        CAINF: null,
      };
    case AMCConstants.POST_CAINF_SUCCESS:
      return {
        ...state,
        send: false,
        sendCAINF: false,
        CAINF: action.payload,
      };
    case AMCConstants.POST_CAINF_ERROR:
      return {
        ...state,
        send: false,
        sendCAINF: false,
        errors: action.error,
      };
    case AMCConstants.POST_ACCLMTS_REQUEST:
      return {
        ...state,
        send: true,
        sendACCLMTS: true,
        ACCLMTS: null,
      };
    case AMCConstants.POST_ACCLMTS_SUCCESS:
      return {
        ...state,
        send: false,
        sendACCLMTS: false,
        ACCLMTS: action.payload,
      };
    case AMCConstants.POST_ACCLMTS_ERROR:
      return {
        ...state,
        send: false,
        sendACCLMTS: false,
        errors: action.error,
      };
    case AMCConstants.GET_COMPANY_REQUEST:
      return {
        ...state,
        send: true,
        sendCOMPANY: true,
        COMPANY: null,
      };
    case AMCConstants.GET_COMPANY_SUCCESS:
      return {
        ...state,
        send: false,
        sendCOMPANY: false,
        sendCOMPANYInitial: false,
        COMPANY: action.payload,
      };
    case AMCConstants.GET_COMPANY_ERROR:
      return {
        ...state,
        send: false,
        sendCOMPANY: false,
        sendCOMPANYInitial: false,
        errors: action.error,
      };
    case AMCConstants.POST_AMC_ACCSXLSX_REQUEST:
      return {
        ...state,
        sendACCSXLSX: true,
        ACCSXLSX: null,
      };
    case AMCConstants.POST_AMC_ACCSXLSX_SUCCESS:
      return {
        ...state,
        sendACCSXLSX: false,
        ACCSXLSX: action.payload,
      };
    case AMCConstants.POST_AMC_ACCSXLSX_ERROR:
      return {
        ...state,
        sendCOMPANY: false,
        errors: action.error,
      };
    case AMCConstants.GET_ACCOUNT_OVERDRAFT_REQUEST:
      return {
        ...state,
        accountOverdraftInfoLoader: true,
        accountOverdraftInfo: null,
      };
    case AMCConstants.GET_ACCOUNT_OVERDRAFT_SUCCESS:
      return {
        ...state,
        accountOverdraftInfoLoader: false,
        accountOverdraftInfo: action.payload,
      };
    case AMCConstants.GET_ACCOUNT_OVERDRAFT_ERROR:
      return {
        ...state,
        accountOverdraftInfoLoader: false,
        errors: action.error,
      };
    case AMCConstants.GET_FOREX_CURRENCY_REQUEST:
      return {
        ...state,
        forexCurrencyLoader: true,
        forexCurrency: null,
        fxAccountCurrency: null,
      };
    case AMCConstants.GET_FOREX_CURRENCY_SUCCESS:
      return {
        ...state,
        forexCurrencyLoader: false,
        forexCurrency: action.payload,
        fxAccountCurrency: action.currency,
      };
    case AMCConstants.GET_FOREX_CURRENCY_ERROR:
      return {
        ...state,
        forexCurrencyLoader: false,
        errors: action.error,
      };

    case AMCConstants.GET_FOREX_RATE_REQUEST:
      return {
        ...state,
        forexRateLoader: true,
        forexRate: null,
        forexRateRequestData: action.payload,
      };
    case AMCConstants.GET_FOREX_RATE_SUCCESS:
      return {
        ...state,
        forexRateLoader: false,
        forexRate: action.payload,
      };
    case AMCConstants.GET_FOREX_RATE_ERROR:
      return {
        ...state,
        forexRateLoader: false,
        errors: action.error,
      };
    case AMCConstants.CLEAR_FOREX_RATE:
      return {
        ...state,
        forexRate: null,
        forexRateRequestData: {},
        forexDraftErrors: null,
      };
    case AMCConstants.POST_FOREX_CONFIRM_REQUEST:
      return {
        ...state,
        forexConfirmLoader: true,
        forexConfirmation: null,
      };
    case AMCConstants.POST_FOREX_CONFIRM_SUCCESS:
      return {
        ...state,
        forexConfirmLoader: false,
        forexConfirmation: action.payload,
      };
    case AMCConstants.POST_FOREX_CONFIRM_ERROR:
      return {
        ...state,
        forexConfirmLoader: false,
        errors: action.error,
      };

    case AMCConstants.POST_FOREX_DRAFT_REQUEST:
      return {
        ...state,
        forexDraftLoader: true,
        forexDraftInfo: null,
        forexDraftErrors: null,
      };
    case AMCConstants.POST_FOREX_DRAFT_SUCCESS:
      return {
        ...state,
        forexDraftLoader: false,
        forexDraftInfo: action.payload,
      };
    case AMCConstants.POST_FOREX_DRAFT_ERROR:
      return {
        ...state,
        forexDraftLoader: false,
        forexDraftErrors: action.error,
      };
    case UACConstants.GET_UAC_LOGOUT_REQUEST:
      return initialState;
    case AMCConstants.GET_MANAGED_USERS_REQUEST:
      return {
        ...state,
        sendManagedUsers: true,
        managedUsers: null,
      };
    case AMCConstants.GET_MANAGED_USERS_SUCCESS:
      return {
        ...state,
        sendManagedUsers: false,
        managedUsers: action.payload,
      };
    case AMCConstants.GET_MANAGED_USERS_ERROR:
      return {
        ...state,
        sendManagedUsers: false,
        errors: action.error,
      };
    case AMCConstants.GET_USER_LIMITS_REQUEST:
      return {
        ...state,
        sendUserLimits: true,
        userLimits: null,
        sendUserLimitsErrors: null,
      };
    case AMCConstants.GET_USER_LIMITS_SUCCESS:
      return {
        ...state,
        sendUserLimits: false,
        userLimits: action.payload,
      };
    case AMCConstants.GET_USER_LIMITS_ERROR:
      return {
        ...state,
        sendUserLimits: false,
        sendUserLimitsErrors: action.error,
      };
    case AMCConstants.POST_USER_LIMITS_REQUEST:
      return {
        ...state,
        updatingUserLimits: true,
        updatedUserLimits: null,
      };
    case AMCConstants.POST_USER_LIMITS_SUCCESS:
      return {
        ...state,
        updatingUserLimits: false,
        updatedUserLimits: action.payload,
      };
    case AMCConstants.POST_USER_LIMITS_ERROR:
      return {
        ...state,
        updatingUserLimits: false,
        errors: action.error,
      };
    case AMCConstants.POST_UPDATING_LIMIT_STATUS_REQUEST:
      return {
        ...state,
        updatingLimitStatusSend: true,
        updatingLimitStatus: null,
      };
    case AMCConstants.POST_UPDATING_LIMIT_STATUS_SUCCESS:
      return {
        ...state,
        updatingLimitStatusSend: false,
        updatingLimitStatus: action.payload,
      };
    case AMCConstants.POST_UPDATING_LIMIT_STATUS_ERROR:
      return {
        ...state,
        updatingLimitStatusSend: false,
        errors: action.error,
      };
    case AMCConstants.CLEAR_AMC_REDUCERS:
      return {
        ...state,
        managedUsers: null,
      };
    case AMCConstants.CLEAR_USER_LIMITS:
      return {
        ...state,
        userLimits: null,
      };
    case AMCConstants.GET_COUNTRIES_LIST_REQUEST:
      return {
        ...state,
        countriesListLoanding: true,
      };
    case AMCConstants.GET_COUNTRIES_LIST_SUCCESS:
      return {
        ...state,
        countriesListLoanding: false,
        countriesList: action.payload,
      };
    case AMCConstants.GET_COUNTRIES_LIST_ERROR:
      return {
        ...state,
        countriesListLoanding: false,
        errors: action.error,
      };
    default:
      return state;
  }
};
