import {i18} from "../../../Utilities";


export const FeePaymentPreview = (props) => {
    if (!props) {
        return null;
    }
    return {
        parts: [
            {
                title: i18.PaymentParts.Payer,
                fields: [
                    {
                        title: i18.Labels.FromAccount,
                        value: props.InitialAccount,
                    },
                ]
            }, {
                title: i18.PaymentParts.Contribution,
                fields: [
                    {
                        title: i18.Labels.PayeeTitle,
                        value: props.Payment.Recipient,
                    }, {
                        title: i18.Labels.PayeeAccount,
                        value: props.Payment.BeneficiaryAccountNumber,
                    }, {
                        title: i18.Labels.PayeeBank,
                        value: props.Payment.RecipientBankName,
                    }
                ]
            }, {
                module: true,
                moduleComponent: 'FeeModule',
            }, {
                fields: [
                    {
                        title: i18.Labels.Amount,
                        value: props.Payment.AmountFormatted,
                    }, {
                        title: i18.Labels.Commission,
                        value: props.Payment.CommissionFeeFormatted,
                    }
                ]
            }
        ]
    };
};