import {DMCConstants} from './constants';

const initialState = {}

export const DMCReducers = function (state = initialState, action) {
    switch (action.type) {
        case DMCConstants.POST_DMC_ADPDF_REQUEST:
            return {
                ...state,
                send: true,
                sendADPDF: true,
                ADPDF: null,
            };
        case DMCConstants.POST_DMC_ADPDF_SUCCESS:
            return {
                ...state,
                send: false,
                sendADPDF: false,
                ADPDF: action.payload,
            };
        case DMCConstants.POST_DMC_ADPDF_ERROR:
            return {
                ...state,
                send: false,
                sendADPDF: false,
                errors: action.error
            };
        case DMCConstants.POST_DMC_SPPDF_REQUEST:
            return {
                ...state,
                send: true,
                sendSPPDF: true,
                SPPDF: null,
            };
        case DMCConstants.POST_DMC_SPPDF_SUCCESS:
            return {
                ...state,
                send: false,
                sendSPPDF: false,
                SPPDF: action.payload,
            };
        case DMCConstants.POST_DMC_SPPDF_ERROR:
            return {
                ...state,
                send: false,
                sendSPPDF: false,
                errors: action.error
            };
        case DMCConstants.POST_DMC_SDR_REQUEST:
            return {
                ...state,
                send: true,
                sendSDR: true,
                SDR: null,
            };
        case DMCConstants.POST_DMC_SDR_SUCCESS:
            return {
                ...state,
                send: false,
                sendSDR: false,
                SDR: action.payload,
            };
        case DMCConstants.POST_DMC_SDR_ERROR:
            return {
                ...state,
                send: false,
                sendSDR: false,
                errors: action.error
            };
        default:
            return state;
    }
};