import React from 'react';
import PropTypes from 'prop-types';

import { MessageResult } from 'src/Components';
import { i18 } from 'src/Utilities';
import { PMCActions } from 'src/Redux';
import { useDispatch } from 'react-redux';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

export const PaymentSuccess = ({ close, resetPayment }) => {
  const buttons = [];
  if (resetPayment) {
    buttons.push({
      title: i18.Buttons.NewPaymentInstruction,
      func: resetPayment,
      props: {
        icon: null,
      },
    });
  }

  if (close) {
    buttons.push({
      title: i18.Buttons.Close,
      func: close(),
      props: {
        type: 'primary',
        icon: <CloseCircleOutlined />,
      },
    });
  }

  return (
    <MessageResult
      status="success"
      title={i18.MessageTitles.PaymentSuccess}
      subTitle={i18.Messages.PaymentSuccess}
      buttons={buttons}
    />
  );
};

PaymentSuccess.propTypes = {
  download: PropTypes.func.isRequired,
  print: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  resetPayment: PropTypes.func.isRequired,
};
