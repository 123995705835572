import { Col, Row } from 'antd';

import { LanguageSelect } from 'src/Components';

export const LoginHeader = () => {
  return (
    <Row
      align="middle"
      className="login_header"
      id="header"
    >
      <Col
        span={12}
        className="login_header_left pl-5 py-3"
      >
        <div
          id="headerLogo"
          className="login_header_card login_logo col text-left pl-0"
        >
          <img
            src={`${import.meta.env.VITE_ROOT}images/logos/${import.meta.env.VITE_LOGIN_LOGO}`}
            alt={import.meta.env.VITE_TITLE}
            className="cursor-pointer"
            onClick={() => window.location.reload()}
          />
        </div>
      </Col>
      <Col
        span={12}
        className="login_header_right pr-5"
      >
        <div
          className="text-right pr-0"
          id="headerLanguageSelect"
        >
          <LanguageSelect className="ml-5 login-language-select" />
        </div>
      </Col>
    </Row>
  );
};
