import React from 'react';
import { FormEntrySwitch } from 'src/Components';
import { i18 } from 'src/Utilities';

export const DepositFill = (props) => {
  let { data } = props;
  const { CIPTR, PDR, depositOffer } = props,
    subProductsData = CIPTR?.InitialAccounts,
    subProducts = subProductsData.map((item) => {
      const data = {
        label: `${item.AccountNumber} (${item?.Amounts?.[0]?.Text})`,
        value: item.AccountID,
      };
      return data;
    }),
    InterestPaymentTypes = CIPTR?.InterestPaymentTypes.map((item) => ({
      ...item,
      disabled: item.Caption === 'EveryMonth',
      selected: item.Caption === 'ContractEndDate',
    }));
  const Currencies = CIPTR?.Currencies,
    Periods = PDR?.Periods,
    DepositAmountNotValid = !!depositOffer?.ErrorDescriptions?.DepositAmountNotValid;

  data.SubProductID = InterestPaymentTypes.find((item) => item.Caption === 'ContractEndDate')?.ID;

  return (
    <FormEntrySwitch
      name="deposits"
      data={data}
      errors={props.errors}
      accounts={subProducts}
      CIPTR={CIPTR}
      InterestPaymentTypes={InterestPaymentTypes}
      Currencies={Currencies}
      html={props.DepositsTerms}
      Periods={Periods}
      submit={props.submit}
      decorator={props.decorator}
      DepositAmountNotValid={DepositAmountNotValid ? { DepositAmountNotValid: DepositAmountNotValid, MinimumAmountDeposit: depositOffer.MinimumAmountDeposit } : null}
      buttons={[
        {
          title: i18.Buttons.Submit,
          props: {
            type: 'primary',
            htmlType: 'submit',
            disabled: props.submitting,
            loading: props.submitting,
            className: 'col-12 col-md-12',
          },
        },
      ]}
    />
  );
};
