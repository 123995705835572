export const IconClose = ({ size = 24, color = '#FFF' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="m3.96297,6.61378l10.60658,10.60664"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="m3.96297,17.28172l10.60658,-10.60657"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
