import PropTypes from 'prop-types';
import {checkJsonExist} from "../../../Utilities";


export const FeeSumCalculate = (values) => {
    if (!checkJsonExist(values, 'feePaymentData')) {
        return null;
    }

    const feePaymentData = values.feePaymentData;
    let paymentSum = 0;

    feePaymentData.map(item => {
        for (const [key, value] of Object.entries(item)) {
            if (key === 'A') {
                if (value) {
                    paymentSum = paymentSum + value;
                }
            }
        }
        return item;
    });

    return parseFloat(paymentSum.toFixed(2));
};

FeeSumCalculate.propTypes = {
    values: PropTypes.object.isRequired
};