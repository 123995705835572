import {DMCServices} from './services';
import {DMCConstants} from './constants';

export const DMCActions = {
    postADPDF,
    postSPPDF,
    postSDR
};


function postADPDF(data, language) {
    return dispatch => {
        dispatch(request());

        return DMCServices.postADPDF(data, language)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DMCConstants.POST_DMC_ADPDF_REQUEST};
    }

    function success(data) {
        return {type: DMCConstants.POST_DMC_ADPDF_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: DMCConstants.POST_DMC_ADPDF_ERROR, error}
    }
}

function postSPPDF(data, language) {
    const json = {
        ID: data.ID.toString(),
    };
    return dispatch => {
        dispatch(request());

        return DMCServices.postSPPDF(json, language)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DMCConstants.POST_DMC_SPPDF_REQUEST};
    }

    function success(data) {
        return {type: DMCConstants.POST_DMC_SPPDF_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: DMCConstants.POST_DMC_SPPDF_ERROR, error}
    }
}

function postSDR(data) {
    return dispatch => {
        dispatch(request());

        return DMCServices.postSDR(data)
            .then(
                data => dispatch(success(data)),
                error => dispatch(failure(error))
            )
    };

    function request() {
        return {type: DMCConstants.POST_DMC_SDR_REQUEST};
    }

    function success(data) {
        return {type: DMCConstants.POST_DMC_SDR_SUCCESS, payload: data}
    }

    function failure(error) {
        return {type: DMCConstants.POST_DMC_SDR_ERROR, error}
    }
}
