import { useEffect, useRef } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import { RollbackOutlined, SaveOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Button, Checkbox, FormField, Input, InputNumber, ScrollToFieldError, Select, ToggleButton } from 'src/shared';
import { CURRENCIES, PAYMENT_SYSTEMS, i18, trb } from 'src/Utilities';
import {
  FieldBeneficiaryAddress,
  FieldBeneficiaryCity,
  FieldBeneficiaryCountryCode,
  FieldBeneficiaryPostCode,
  FieldBIC,
  FieldTemplate,
  FieldCorrespondentBankBIC,
  FieldPayeeAccount,
  FieldPayerAccount,
  FieldRecipientBankCountry,
  FieldSEPAPaymentType,
  FinalPayeeInformation,
  InitialPayerInformation,
  PayeeInformation,
  PayerInformation,
  FieldConfirmTarget2,
  FieldPaymentSystem,
} from './components';
import { PAYMENT_CURRENCIES } from 'src/constants';
import { IPaymentNewValues } from './interfaces';
import { FieldPayee, IPaymentError, PaymentError } from '../components';
import { validation } from './validation';
import { FieldBankName } from './components/FieldBankName';
import { paymentNewValues } from './initialValues';
import { checkIsFirstTwoLetters, scrollToTop } from 'src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { PMCActions } from 'src/Redux';

interface IProps {
  data: IPaymentNewValues;
  submit: (values: IPaymentNewValues, actions: FormikHelpers<IPaymentNewValues>) => void;
  handleBackClick?: () => void;
  errorsPVR?: Array<IPaymentError> | null;
}

export const ViewPaymentsNew = ({ data, submit, handleBackClick, errorsPVR }: IProps) => {
  const dispatch: (dispatch: any) => Promise<any> = useDispatch();
  const { sendPVR } = useSelector((state: any) => state.PMCReducers);
  const formRef = useRef();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleValidationIBAN = async (value: string, setFieldError: (name: string, error: string | undefined) => void) => {
    const formattedValue = value.replaceAll(' ', '');

    if (checkIsFirstTwoLetters(formattedValue) && value) {
      await dispatch(PMCActions.setTarget2Payment(null) as any);
      const resValid = await dispatch(PMCActions.postCheckIBANMember(value.replaceAll(' ', '')));

      if (!resValid?.payload?.IsValid) {
        setFieldError('Payment.BeneficiaryAccountNumber', i18.PaymensValidations.InvalidIBAN);

        return false;
      } else {
        setFieldError('Payment.BeneficiaryAccountNumber', undefined);

        return true;
      }
    } else {
      await dispatch(PMCActions.setTarget2Payment({ currency: CURRENCIES.EUR, target2: true, value: value }) as any);
      setFieldError('Payment.BeneficiaryAccountNumber', undefined);

      return true;
    }
  };

  const handleSubmit = async (values: IPaymentNewValues, actions: FormikHelpers<IPaymentNewValues>) => {
    const isValid = await handleValidationIBAN(values.Payment.BeneficiaryAccountNumber, actions.setFieldError);

    if (isValid) {
      const noErrors = validation.validateSync((formRef.current as any).values);

      if (noErrors) {
        await submit(
          {
            ...values,
            Payment: {
              ...values.Payment,
              BeneficiaryAccountNumber: values.Payment?.BeneficiaryAccountNumber?.replaceAll(' ', ''),
            },
          },
          actions
        );
      }
    }
  };

  const isTemplate = data?.Payment.PaymentFormType === 'template';
  const gridStyle = isTemplate ? '' : 'col-md-6';

  return (
    <div>
      <PaymentError errors={errorsPVR} />
      <Formik
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        innerRef={formRef}
        validationSchema={validation}
        initialValues={{ ...paymentNewValues, ...data }}
        onSubmit={async (values, formikHelpers) => await handleSubmit(values, formikHelpers)}
      >
        {({ values, isSubmitting }) => (
          <Form
            noValidate={true}
            autoComplete="off"
          >
            <ScrollToFieldError />
            <div className="row">
              {!isTemplate && (
                <div className={`col-12 ${gridStyle} form-part`}>
                  <div className="form-part-title">{trb(i18.PaymentParts.Payer)}</div>
                  <FieldTemplate />
                  <FieldPayerAccount overdraft={true} />
                  {values.Payment.OverdraftValue !== null && (
                    <FormField
                      name="Payment.OverdraftValue"
                      label={trb(i18.Labels.OverdraftAvailableAmount)}
                      disabled={true}
                    >
                      <InputNumber
                        suffix={` ${CURRENCIES.EUR}`}
                        allowNegative={true}
                      />
                    </FormField>
                  )}
                </div>
              )}
              <div className={`col-12 ${gridStyle} form-part`}>
                <div className="form-part-title">{trb(isTemplate ? i18.Labels.TemplateNew : i18.PaymentParts.Payee)}</div>

                {isTemplate && (
                  <FormField
                    name="Payment.TemplateName"
                    label={trb(i18.Labels.PaymentTemplateName)}
                  >
                    <Input />
                  </FormField>
                )}
                <FieldPayee paymentType="new" />
                <FieldPayeeAccount errorsPVR={errorsPVR} />
                {!isTemplate && (
                  <>
                    <FormField
                      name="Payment.Currency"
                      label={trb(i18.Labels.Currency)}
                    >
                      <Select options={PAYMENT_CURRENCIES} />
                    </FormField>
                    <FormField
                      name="Payment.Amount"
                      label={trb(i18.Labels.Amount)}
                    >
                      <InputNumber />
                    </FormField>
                  </>
                )}
                {values.Payment.PaymentSystem !== PAYMENT_SYSTEMS.TARGET2 && (
                  <Row align="middle">
                    <Col
                      className="my-1"
                      span={24}
                      xl={10}
                    >
                      {trb(i18.Labels.PurposeType)}
                    </Col>
                    <Col
                      span={24}
                      xl={14}
                    >
                      <FormField
                        name="Payment.IsPurposeSelected"
                        floating={false}
                      >
                        <ToggleButton
                          options={[
                            {
                              label: trb(i18.PaymentPurposeTypes.Purpose),
                              value: true,
                            },
                            {
                              label: trb(i18.PaymentPurposeTypes.Code),
                              value: false,
                            },
                          ]}
                        />
                      </FormField>
                    </Col>
                  </Row>
                )}
                <FormField
                  name="Payment.Purpose"
                  label={trb(i18.Labels[values.Payment.IsPurposeSelected || values.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 ? 'PurposePayment' : 'PurposePaymentCode'])}
                >
                  <Input />
                </FormField>
                {values.Payment.PaymentSystem !== PAYMENT_SYSTEMS.TARGET2 && (
                  <FormField
                    name="Payment.CustomersCode"
                    label={trb(i18.Labels.PaymentUniqueCode)}
                  >
                    <Input />
                  </FormField>
                )}

                {values.Payment.PaymentSystem !== PAYMENT_SYSTEMS.TARGET2 && !isTemplate && <FieldPaymentSystem />}
                {values.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 && (
                  <>
                    <FieldBeneficiaryCountryCode />
                    <FieldBeneficiaryCity />
                    <FieldBeneficiaryAddress />
                    <FieldBeneficiaryPostCode />
                    <FieldBankName />
                    <FieldRecipientBankCountry />
                    <FieldBIC />
                    <FieldCorrespondentBankBIC />
                  </>
                )}
              </div>
            </div>

            <div className="row">
              <div className={isTemplate ? '' : `col-12 ${gridStyle} form-part`} />
              <div className={isTemplate ? 'col-12' : `col-12 ${gridStyle} form-part`}>
                {!isTemplate && values.Payment.PaymentSystem === PAYMENT_SYSTEMS.TARGET2 && <FieldConfirmTarget2 />}
                <div className="row">
                  {values.Payment.PaymentSystem !== PAYMENT_SYSTEMS.TARGET2 && (
                    <div className={isTemplate ? 'col-12 text-md-right' : `col-12 ${gridStyle} form-part`}>
                      <FormField
                        name="Payment.IsAdditionalDataSelected"
                        floating={false}
                        wrapperClassNames={isTemplate ? 'radiolike-checkbox' : 'custom-checkbox'}
                      >
                        <Checkbox customChecks={!isTemplate}>{trb(i18.Labels.AdditionalPaymentInformation)}</Checkbox>
                      </FormField>
                    </div>
                  )}
                  {!isTemplate && (
                    <div className={`${values.Payment.PaymentSystem !== PAYMENT_SYSTEMS.TARGET2 ? `col-12 ${gridStyle} form-part` : 'col-12'} text-left text-md-right`}>
                      <FormField
                        name="Payment.SaveTemplate"
                        floating={false}
                        wrapperClassNames="radiolike-checkbox"
                      >
                        <Checkbox>{trb(i18.Labels.SavePaymentTemplate)}</Checkbox>
                      </FormField>
                    </div>
                  )}
                </div>
                {!isTemplate && values.Payment.SaveTemplate ? (
                  <FormField
                    name="Payment.TemplateName"
                    label={trb(i18.Labels.PaymentTemplateName)}
                  >
                    <Input />
                  </FormField>
                ) : null}
              </div>
            </div>
            {values.Payment.IsAdditionalDataSelected && values.Payment.PaymentSystem !== PAYMENT_SYSTEMS.TARGET2 ? (
              <>
                <div className="row">
                  <div className={`col-12 ${gridStyle} form-part`}>
                    <div className="form-part-title">{trb(i18.PaymentParts.PayerInformation)}</div>
                    <PayerInformation />
                  </div>
                  <div className={`col-12 ${gridStyle} form-part`}>
                    <div className="form-part-title">{trb(i18.PaymentParts.InitialPayerInformation)}</div>
                    <InitialPayerInformation />
                  </div>
                </div>
                <div className="row">
                  <div className={`col-12 ${gridStyle} form-part`}>
                    <div className="form-part-title">{trb(i18.PaymentParts.PayeeInformation)}</div>
                    <PayeeInformation />
                  </div>
                  <div className={`col-12 ${gridStyle} form-part`}>
                    <div className="form-part-title">{trb(i18.PaymentParts.FinalPayeeInformation)}</div>
                    <FinalPayeeInformation />
                  </div>
                </div>
                <div className="row">
                  <div className={`col-12 ${gridStyle} form-part`}>
                    <FieldSEPAPaymentType />
                  </div>
                </div>
              </>
            ) : null}
            <div className="text-end">
              {isTemplate ? (
                <>
                  <Button
                    type="default"
                    size="large"
                    htmlType="button"
                    onClick={handleBackClick}
                    loading={sendPVR}
                  >
                    <RollbackOutlined />
                    {trb(i18.Buttons.Cancel)}
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={sendPVR || isSubmitting}
                    disabled={sendPVR || isSubmitting}
                  >
                    <SaveOutlined />
                    {trb(i18.Buttons.SubmitTemplates)}
                  </Button>
                </>
              ) : (
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={sendPVR || isSubmitting}
                  disabled={sendPVR || isSubmitting}
                >
                  {trb(i18.Buttons.Preview)}
                </Button>
              )}
            </div>
            {/* <pre>{JSON.stringify(values, undefined, 4)}</pre> */}
          </Form>
        )}
      </Formik>
    </div>
  );
};
